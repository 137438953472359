import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-2 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_item_group = _resolveComponent("v-list-item-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "justify-content-center mb-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { sm: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "blue",
                size: "sm",
                to: { name: 'DeviceList' },
                class: "mb-4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    theme: "dark",
                    start: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-arrow-left ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" Устройства ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                variant: "blue",
                block: "",
                to: { name: 'CreateIncrementGroup' },
                class: "mb-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Создать группу ")
                ]),
                _: 1
              }),
              (_ctx.incrementGroups.length > 0)
                ? (_openBlock(), _createBlock(_component_v_card, {
                    key: 0,
                    class: "mx-auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { "no-gutters": "" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incrementGroupList, (incrementGroup) => {
                            return (_openBlock(), _createBlock(_component_v_col, {
                              key: incrementGroup.id,
                              cols: "12",
                              sm: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_container, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, { dense: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card, {
                                              color: "#1B5E20",
                                              theme: "dark"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_row, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_col, { style: {"text-align":"left"} }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(incrementGroup.name), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_v_col, { style: {"text-align":"right"} }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_btn, {
                                                              class: "mx-2",
                                                              fab: "",
                                                              theme: "dark",
                                                              size: "small",
                                                              color: "primary",
                                                              onClick: ($event: any) => (_ctx.incrementGroupToggle(incrementGroup))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (!incrementGroup.visible)
                                                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                                                      key: 0,
                                                                      theme: "dark"
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(" mdi-arrow-down ")
                                                                      ]),
                                                                      _: 1
                                                                    }))
                                                                  : (_openBlock(), _createBlock(_component_v_icon, {
                                                                      key: 1,
                                                                      theme: "dark"
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(" mdi-arrow-up ")
                                                                      ]),
                                                                      _: 1
                                                                    }))
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_card_actions, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_btn, {
                                                      color: "orange dark",
                                                      variant: "text",
                                                      to: { name: 'EditIncrementGroup', params: { id: incrementGroup.id } },
                                                      action: ""
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(" Редактировать ")
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["to"]),
                                                    _createVNode(_component_v_spacer)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                (incrementGroup.visible)
                                                  ? (_openBlock(), _createBlock(_component_v_expand_transition, { key: 0 }, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("div", null, [
                                                          _createVNode(_component_v_divider),
                                                          _createVNode(_component_v_card_text, null, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_list, { lg: "6" }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_list_item_group, { color: "primary" }, {
                                                                    default: _withCtx(() => [
                                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(incrementGroup.incrementGroupTimes, (incrementGroupTime) => {
                                                                        return (_openBlock(), _createBlock(_component_v_list_item, {
                                                                          key: incrementGroupTime.id
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            _createElementVNode("div", null, _toDisplayString(incrementGroupTime.time), 1)
                                                                          ]),
                                                                          _: 2
                                                                        }, 1024))
                                                                      }), 128))
                                                                    ]),
                                                                    _: 2
                                                                  }, 1024)
                                                                ]),
                                                                _: 2
                                                              }, 1024)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ])
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_v_progress_circular, { label: "Spinning" })
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}