import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { RatePlan } from '@/store/ratePlan/types'
import { RatePlanFindPost } from './api-types'

const routeRatePlanFind = 'rateplans/find'

export const find = async (data: RatePlanFindPost): Promise<RatePlan[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: RatePlan[] ;
  }> = await axios.post(routeRatePlanFind, data)

  return result.data.data
}
