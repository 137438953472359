<template>
  <div>
    <v-navigation-drawer
      v-model="sidebar"
      app
    >
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          {{ item.title }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      theme="dark"
      class="bg-green-darken-4"
    >
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar" />
      </span>
      <v-toolbar-title>
        <router-link
          to="/"
          tag="span"
          style="cursor: pointer"
        >
          {{ appTitle }}
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-xs">
        <v-btn
          v-for="item in menuItems"
          :key="item.title"
          variant="text"
          :to="item.path"
        >
          <v-icon
            start
            theme="dark"
          >
            {{ item.icon }}
          </v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'NavBar',
  data () {
    return {
      appTitle: 'Глолайм',
      sidebar: false,
      menuItems: [
        { title: 'Главная', path: '/', icon: 'home' },
        { title: 'Возврат', path: '/oldsite/', icon: 'gavel' },
        { title: 'Вход', path: '/user/login', icon: 'face' },
        { title: 'Регистрация', path: '/user/signin', icon: 'lock_open' }
      ]
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    routePartialType (): string {
      return (this.$route.meta as any).partialType
    }
  },
  components: {
  }
})
</script>

<style lang="scss"></style>
