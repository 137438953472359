<template>
  <v-container>
    <v-row class="justify-content-left">
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <place-selector
          v-model="selectedUserAllowedPlace"
          :selected-item-props="propPlaceId"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          offset-y
          max-width="390px"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateFrom)"
              label="Дата"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="props"
              style="width: 155px"
            />
          </template>
          <v-date-picker
            v-model="dateFrom"
            hide-weekdays
            title="Дата"
            @date-select="changeDate"
          />
        </v-menu>
<!--        <simple-datepicker-->
<!--          label="Дата"-->
<!--          @date-select="changeDate"-->
<!--        />-->
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-checkbox
          v-model="separateClasses"
          label="Использовать настройки"
          @click="createReport"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <simple-selector
          v-model="selectedVariant"
          :items-prop="variants"
          label-prop="Вариант"
          value-prop="1"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          block
          @click="goToSettings()"
        >
          Настроить
        </v-btn>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="!isLoading && isPost && sales.length > 0"
      class="justify-content-center"
    >
      <v-col
        v-if="!isLoading && isPost"
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-checkbox
          v-model="splitReport"
          label="Разбить на 2 листа при печати"
        />
      </v-col>
      <v-col
        class="d-flex"
        sm="2"
        cols="2"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
      <v-col class="d-flex">
        <export-excel
          :table-data="tableData"
          :title="'Табель по ученикам и категориям'"
        />
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <template v-if="isPost && sales.length > 0">
        <div
          id="printable-area"
          :style="{ display: 'flex', justifyContent: 'center'}"
        >
          <template
            v-for="index in 2"
            :key="`table-${index}`"
          >
            <div
              :class="index === 1 ? 'only-for-print' : ''"
              :style="{display:'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center'}"
            >
              <div
                :style="{display: 'flex', justifyContent: 'start', width: '100%', margin: '20px 0px 10px'}"
                class="only-for-screen"
              >
                <string-editor-button
                  :prop-string="reportTitle.value"
                  @update-string="updateReportTitle"
                />
              </div>
              <h3
                class="text-center print-header"
                :style="{ maxWidth: '1200px', marginBottom: '20px'}"
              >
                <span v-html="reportTitle.value" />
                <br>
                <span> от {{ getTextDate() }} года</span>
              </h3>
              <base-simple-table
                :table-data="tableData"
                class="report-table"
                :style="{ maxWidth: '90vm', alignItems: 'center'}"
              >
                <template #signs>
                  <div
                    :style="{display: 'flex', textAlign: 'center', flexDirection: 'column'}"
                    class="only-for-print"
                  >
                    <p :style="{margin: '5px'}">
                      {{
                        `Директор школы __________________//${ customerPlaceInfo.directorName ? customerPlaceInfo.directorName : '' }`
                      }}
                    </p>
                    <p :style="{margin: '5px'}">
                      {{
                        `Ответственный по питанию __________________//${ customerPlaceInfo.ladminName ? customerPlaceInfo.ladminName : '' }`
                      }}
                    </p>
                    <p :style="{margin: '5px'}">
                      {{
                        `Зав производством __________________//${ customerPlaceInfo.zavprName ? customerPlaceInfo.zavprName : '' }`
                      }}
                    </p>
                  </div>
                </template>
              </base-simple-table>
            </div>
            <div
              v-if="index === 1"
              :key="`spletter-${index}`"
              class="non-visible"
              :style="{pageBreakAfter: `${splitReport ? 'always' : 'auto'}`}"
            >
              <hr v-if="!splitReport">
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="isPost">
        <h3 :style="{textAlign: 'center'}">
          По заданным фильтрам не найдено ни одной записи!
        </h3>
      </template>
    </template>
    <template v-else>
      <v-row>
        <v-col
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import SimpleSelector from '@/shared/uikit/selectors/SimpleSelector.vue'
import PlaceSelector from '@/shared/uikit/selectors/PlaceSelector.vue'
import SimpleDatepicker from '@/shared/uikit/datepickers/SimpleDatepicker.vue'
import { mapActions, mapGetters } from 'vuex'
import { SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost } from '@/shared/api/salelist/api-types'
import { findByDateAndPlaceCustomerProductsWithRatePlans } from '@/shared/api/salelist/api-requests'
import { find as findPlaceReportSettings } from '@/shared/api/placeReportSettings/api-requests'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'
import StringEditorButton from '@/widgets/modals/StringEditorButton.vue'
import { findPlaceReportParams, savePlaceReportParams } from '@/shared/api/placeReportParams/api-reqiests'
import { ReportParamTypes, ReportTypes } from '@/app/types/reportParams'
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import { printer } from '@/shared/lib/print'
import ExportExcel from '@/shared/uikit/ExportExcel.vue'

type BaseData = {
  reportTitle: any;
  menu: boolean;
  menu1: boolean;
  dateFrom: any;
  variants: any;
  selectedVariant: any;
  sales: any;
  monthes: any;
  ratePlans: any;
  headerParallels: any;
  productRatePlanSales: any;
  isLoading: boolean;
  isPost: boolean;
  userAllowedPlacesList: any;
  selectedUserAllowedPlaceName: string;
  selectedUserAllowedPlace: any;
  userAllowedPlacesCopy: any;
  searchTerm: string;
  separateClasses: boolean;
  splitReport: boolean;
  salesGetPost: SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost;
  customerPlacePost: CustomerPlaceFindPost;
  customerPlaceInfo: any;
  places: any;
  products: any;
  settings: any;
  notAcceptDates: any;
  errorDates: any;
  tableData: TableData;
}

export default defineComponent({
  name: 'DayReport',
  components: {
    BaseSimpleTable,
    ExportExcel,
    StringEditorButton,
    SimpleSelector,
    PlaceSelector,
    SimpleDatepicker
  },
  data (): BaseData {
    return {
      reportTitle: {},
      menu: false,
      menu1: false,
      dateFrom: moment().format('YYYY-MM-DD'),
      variants: [
        { id: 1, name: 'Вариант 1' },
        { id: 2, name: 'Вариант 2' }
      ],
      selectedVariant: 1,
      sales: [],
      monthes: [
        { id: 1, name: 'января' },
        { id: 2, name: 'февраля' },
        { id: 3, name: 'марта' },
        { id: 4, name: 'апреля' },
        { id: 5, name: 'мая' },
        { id: 6, name: 'июня' },
        { id: 7, name: 'июля' },
        { id: 8, name: 'августа' },
        { id: 9, name: 'сентября' },
        { id: 10, name: 'октября' },
        { id: 11, name: 'ноября' },
        { id: 12, name: 'декабря' }
      ],
      ratePlans: [],
      headerParallels: [],
      productRatePlanSales: [],
      isLoading: false,
      isPost: false,
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      selectedUserAllowedPlaceName: '',
      userAllowedPlacesCopy: [],
      searchTerm: '',
      separateClasses: false,
      splitReport: false,
      salesGetPost: {
        placeId: 0,
        timestampFrom: '',
        timestampTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      customerPlaceInfo: null,
      places: [],
      errorDates: [],
      notAcceptDates: [],
      products: [],
      settings: [],
      tableData: {} as TableData
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    changeDate (value: any) {
      this.dateFrom = moment(value).format('YYYY-MM-DD')
    },
    getAllSum () {
      let sum = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.ratePlanId !== null && x.productId !== null && x.classType >= 1) {
          sum = sum + Number(x.sum)
        }
      })
      return sum.toFixed(2)
    },
    getProductCount (product: any, classType: any) {
      let count = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.productId === product.id && x.classType === classType) {
          count = count + x.count
        }
      })
      return count
    },
    getProductCountByRatePlan (product: any, classType: any, ratePlan: any) {
      let count = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.ratePlanId === ratePlan.id && x.productId === product.id && x.classType === classType) {
          count = count + x.count
        }
      })
      return count
    },
    getProductSumByRatePlan (product: any, classType: any, ratePlan: any) {
      let sum = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.ratePlanId === ratePlan.id && x.productId === product.id && x.classType === classType) {
          sum = sum + Number(x.sum)
        }
      })
      return sum.toFixed(2)
    },
    getProductAllCountByProduct (product: any, classType: any) {
      let count = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.productId === product.id && x.classType === classType) {
          count = count + x.count
        }
      })
      return count
    },
    getProductAllSumByProduct (product: any, classType: any) {
      let sum = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.productId === product.id && x.classType === classType) {
          sum = sum + Number(x.sum)
        }
      })
      return sum.toFixed(2)
    },
    async loadReportTitle () {
      const res = await findPlaceReportParams({
        placeId: this.selectedUserAllowedPlace.id
      })

      const _reportTitle = res.find((paramType: any) => paramType.reportType === ReportTypes.ReportActsDay && paramType.paramType === ReportParamTypes.ReportTitle)

      if (_reportTitle) {
        this.reportTitle = _reportTitle
      } else {
        this.reportTitle = {
          id: null,
          value: `Акт реализации талонов на бесплатное питание учащихся ${this.customerPlaceInfo.fullName}`
        }
      }
    },
    async updateReportTitle (newTitle: string) {
      this.isLoading = true
      const updatedReportTitle = this.reportTitle.id ? {
        id: this.reportTitle.id,
        placeId: this.reportTitle.placeId,
        reportType: this.reportTitle.reportType,
        paramType: this.reportTitle.paramType,
        value: newTitle
      } : {
        id: null,
        placeId: this.selectedUserAllowedPlace.id,
        reportType: ReportTypes.ReportActsDay,
        paramType: ReportParamTypes.ReportTitle,
        value: newTitle
      }
      await savePlaceReportParams(updatedReportTitle)
      await this.loadReportTitle()
      this.isLoading = false
    },
    async loadSales () {
      try {
        this.selectedUserAllowedPlaceName = this.selectedUserAllowedPlace.name
        if (this.selectedUserAllowedPlace !== 0) {
          const reportSettingsFindPost = {
            placeId: this.selectedUserAllowedPlace.id,
            reportType: 1
          }
          const result1 = await findPlaceReportSettings(reportSettingsFindPost)
          const settingsResponse = JSON.parse(JSON.stringify(result1))
          this.getSettings(settingsResponse)

          this.salesGetPost.placeId = this.selectedUserAllowedPlace.id
          this.salesGetPost.timestampFrom = moment(this.dateFrom).format('YYYY-MM-DD')
          this.salesGetPost.timestampTo = moment(this.salesGetPost.timestampFrom).add('days', 1).format('YYYY-MM-DD')
          const result = await findByDateAndPlaceCustomerProductsWithRatePlans(this.salesGetPost)
          const sales = JSON.parse(JSON.stringify(result))
          if (Array.isArray(sales)) {
            sales.forEach((element: any) => {
              const i = this.ratePlans.findIndex((x: any) => x.id === element.user.currentUserRatePlan.ratePlan.id)
              if (i <= -1) {
                this.ratePlans.push(element.user.currentUserRatePlan.ratePlan)
              }
              if (element.product.currentPlaceCustomerProduct.id === '1' && Number(element.user.currentUserPlace) === 5) {
                console.log(element.user)
              }
              if (element.user.currentUserPlace !== null) {
                const j = this.productRatePlanSales.findIndex((x: any) => x.ratePlanId === element.user.currentUserRatePlan.ratePlan.id &&
                  x.productId === element.product.currentPlaceCustomerProduct.id && x.className === element.user.currentUserPlace)
                if (j <= -1) {
                  const productRatePlanSale = {
                    ratePlanId: element.user.currentUserRatePlan.ratePlan.id,
                    productId: element.product.currentPlaceCustomerProduct.id,
                    className: element.user.currentUserPlace,
                    count: 1,
                    sum: Number(element.price)
                  }
                  this.productRatePlanSales.push(productRatePlanSale)
                } else {
                  this.productRatePlanSales[j].count = this.productRatePlanSales[j].count + 1
                  this.productRatePlanSales[j].sum = this.productRatePlanSales[j].sum + Number(element.price)
                }
              }

              const k = this.products.findIndex((x: any) => x.id === element.product.currentPlaceCustomerProduct.id)
              if (k <= -1) {
                this.products.push(element.product.currentPlaceCustomerProduct)
              }
            })
            this.sales = sales
          }
          this.ratePlans.sort((a: any, b: any) => (a.ratePlanDiscountTypeId > b.ratePlanDiscountTypeId) ? 1 : ((b.ratePlanDiscountTypeId > a.ratePlanDiscountTypeId) ? -1 : (Number(a.code) > Number(b.code)) ? 1 : ((Number(b.code) > Number(a.code)) ? -1 : 0)))

          this.products.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

          if (this.sales.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.selectedUserAllowedPlace !== 0) {
          this.customerPlacePost.placeId = this.selectedUserAllowedPlace.id
          this.customerPlacePost.date = moment(this.dateFrom).format('YYYY-MM-DD')

          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    getParallels (type: any) {
      let parallels = ''
      if (!this.separateClasses) {
        if (type === 1) {
          parallels = 'Младшие классы'
        } else {
          parallels = 'Старшие классы'
        }
      } else {
        this.settings.forEach((element: any) => {
          if (element.classType === type) {
            parallels = element.startValue + '-' + element.endValue + ' классы'
          }
        })
      }
      return parallels
    },
    getSettingParallels () {
      if (this.separateClasses) {
        return JSON.parse(JSON.stringify(this.settings))
      }
      return [
        {
          id: 1,
          startValue: '1',
          endValue: '4',
          classType: 1
        },
        {
          id: 2,
          startValue: '5',
          endValue: '11',
          classType: 2
        }
      ]
    },
    getSettings (settingsResponse: any) {
      this.settings = []
      if (Array.isArray(settingsResponse)) {
        settingsResponse.forEach((element: any) => {
          const elementCopy = element
          const values = elementCopy.value.split(';')
          const setting = {
            id: element.id,
            startValue: values[0],
            endValue: values[1]
          }
          this.settings.push(setting)
        })
      }
      this.sortSettings()
      let i = 1
      this.settings.forEach((element: any) => {
        element.classType = i
        i = i + 1
      })
    },
    sortSettings () {
      this.settings.sort((a: any, b: any) => (a.startValue > b.startValue) ? 1 : ((b.startValue > a.startValue) ? -1 : 0))
    },
    createReport () {
      this.headerParallels = this.getSettingParallels()
      this.productRatePlanSales.forEach((element: any) => {
        let classType = 0
        if (!this.separateClasses) {
          if (element.className.match(/^[1-4][^0-9].*|^[1-4]$/)) classType = 1
          if (element.className.match(/^[5-9].*|^[5-9]$/)) classType = 2
          if (element.className.match(/^[1-4][0-3].*$|^[1-4][0-3]$/)) classType = 2
        } else {
          this.settings.forEach((el: any) => {
            if (element.className.match(/^[1-4][^0-9].*|^[1-4]|[5-9].*|^[5-9]|[1-4][0-3].*$|^[1-4][0-3]$/)) {
              if (parseInt(element.className) >= el.startValue && parseInt(element.className) <= el.endValue) {
                classType = el.classType
              }
            }
          })
        }
        element.classType = classType
      })
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }
      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    async load () {
      this.isLoading = true
      this.productRatePlanSales = []
      this.sales = []
      this.places = []
      this.products = []
      this.errorDates = []
      this.notAcceptDates = []
      this.settings = []
      this.customerPlaceInfo = null
      await this.loadCustomerPlaceInfo()
      await this.loadReportTitle()
      await this.loadSales()
      this.tableData = await this.convertToTableData()
      this.isPost = true
      this.isLoading = false
    },
    convertToTableData () {
      const resTable: TableData = {
        rowsCount: 0,
        columnsCount: this.ratePlans.length * 2 + 3,
        items: []
      }
      resTable.items.push(
        {
          text: 'Категории',
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 3,
          padding: ''
        },
        {
          text: '',
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 3,
          gridRowEnd: 4,
          padding: ''
        },
        {
          text: 'Итого',
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: this.ratePlans.length * 2 + 2,
          gridColumnEnd: this.ratePlans.length * 2 + 4,
          gridRowStart: 1,
          gridRowEnd: 3,
          padding: ''
        },
        {
          text: 'Кол-во',
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: this.ratePlans.length * 2 + 2,
          gridColumnEnd: this.ratePlans.length * 2 + 3,
          gridRowStart: 3,
          gridRowEnd: 4,
          padding: ''
        },
        {
          text: 'Сумма',
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: this.ratePlans.length * 2 + 3,
          gridColumnEnd: this.ratePlans.length * 2 + 4,
          gridRowStart: 3,
          gridRowEnd: 4,
          padding: ''
        }
      )
      let currentHeaderColumn = 2
      this.ratePlans.forEach((ratePlan: any) => {
        resTable.items.push(
          {
            text: `${ratePlan.name}`,
            background: '#faebd7',
            fontWeight: 'bold',
            fontSize: '14px',
            gridColumnStart: currentHeaderColumn,
            gridColumnEnd: currentHeaderColumn + 2,
            gridRowStart: 1,
            gridRowEnd: 2,
            padding: '',
            maxWidth: '145px'
          },
          {
            text: `${ratePlan.code} кат.`,
            background: '#faebd7',
            fontWeight: 'bold',
            fontSize: '14px',
            gridColumnStart: currentHeaderColumn,
            gridColumnEnd: currentHeaderColumn + 2,
            gridRowStart: 2,
            gridRowEnd: 3,
            padding: ''
          },
          {
            text: 'Кол-во',
            background: '#faebd7',
            fontWeight: 'bold',
            fontSize: '14px',
            gridColumnStart: currentHeaderColumn,
            gridColumnEnd: currentHeaderColumn + 1,
            gridRowStart: 3,
            gridRowEnd: 4,
            padding: ''
          },
          {
            text: 'Сумма',
            background: '#faebd7',
            fontWeight: 'bold',
            fontSize: '14px',
            gridColumnStart: currentHeaderColumn + 1,
            gridColumnEnd: currentHeaderColumn + 2,
            gridRowStart: 3,
            gridRowEnd: 4,
            padding: ''
          }
        )
        currentHeaderColumn += 2
      })
      let currentRow = 4
      this.headerParallels.forEach((headerParallel: any, parallelIdx: number) => {
        if (this.selectedVariant === 2 && parallelIdx > 0) {
          resTable.items.push(
            {
              text: 'Категории',
              background: '#faebd7',
              fontWeight: 'bold',
              fontSize: '14px',
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 2,
              padding: ''
            },
            {
              text: '',
              background: '#faebd7',
              fontWeight: 'bold',
              fontSize: '14px',
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: currentRow + 2,
              gridRowEnd: currentRow + 3,
              padding: ''
            },
            {
              text: 'Итого',
              background: '#faebd7',
              fontWeight: 'bold',
              fontSize: '14px',
              gridColumnStart: this.ratePlans.length * 2 + 2,
              gridColumnEnd: this.ratePlans.length * 2 + 4,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 2,
              padding: ''
            },
            {
              text: 'Кол-во',
              background: '#faebd7',
              fontWeight: 'bold',
              fontSize: '14px',
              gridColumnStart: this.ratePlans.length * 2 + 2,
              gridColumnEnd: this.ratePlans.length * 2 + 3,
              gridRowStart: currentRow + 2,
              gridRowEnd: currentRow + 3,
              padding: ''
            },
            {
              text: 'Сумма',
              background: '#faebd7',
              fontWeight: 'bold',
              fontSize: '14px',
              gridColumnStart: this.ratePlans.length * 2 + 3,
              gridColumnEnd: this.ratePlans.length * 2 + 4,
              gridRowStart: currentRow + 2,
              gridRowEnd: currentRow + 3,
              padding: ''
            }
          )
          let currentHeaderColumn = 2
          this.ratePlans.forEach((ratePlan: any) => {
            resTable.items.push(
              {
                text: `${ratePlan.name}`,
                background: '#faebd7',
                fontWeight: 'bold',
                fontSize: '14px',
                gridColumnStart: currentHeaderColumn,
                gridColumnEnd: currentHeaderColumn + 2,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                padding: '',
                maxWidth: '150px'
              },
              {
                text: `${ratePlan.code} кат.`,
                background: '#faebd7',
                fontWeight: 'bold',
                fontSize: '14px',
                gridColumnStart: currentHeaderColumn,
                gridColumnEnd: currentHeaderColumn + 2,
                gridRowStart: currentRow + 1,
                gridRowEnd: currentRow + 2,
                padding: ''
              },
              {
                text: 'Кол-во',
                background: '#faebd7',
                fontWeight: 'bold',
                fontSize: '14px',
                gridColumnStart: currentHeaderColumn,
                gridColumnEnd: currentHeaderColumn + 1,
                gridRowStart: currentRow + 2,
                gridRowEnd: currentRow + 3,
                padding: ''
              },
              {
                text: 'Сумма',
                background: '#faebd7',
                fontWeight: 'bold',
                fontSize: '14px',
                gridColumnStart: currentHeaderColumn + 1,
                gridColumnEnd: currentHeaderColumn + 2,
                gridRowStart: currentRow + 2,
                gridRowEnd: currentRow + 3,
                padding: ''
              }
            )
            currentHeaderColumn += 2
          })
          currentRow += 3
        }
        resTable.items.push(
          {
            text: `${this.getParallels(headerParallel.classType)}`,
            fontWeight: 'normal',
            fontSize: '14px',
            gridColumnStart: 1,
            gridColumnEnd: this.ratePlans.length * 2 + 4,
            gridRowStart: currentRow,
            gridRowEnd: currentRow + 1,
            padding: ''
          }
        )
        currentRow += 1
        this.products.forEach((product: any) => {
          if (this.getProductCount(product, headerParallel.classType) > 0) {
            resTable.items.push(
              {
                text: `${product.name}`,
                fontWeight: 'normal',
                fontSize: '14px',
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                padding: '',
                maxWidth: '110px'
              },
              {
                text: `${this.getProductAllCountByProduct(product, headerParallel.classType)}`,
                fontWeight: 'normal',
                fontSize: '14px',
                gridColumnStart: this.ratePlans.length * 2 + 2,
                gridColumnEnd: this.ratePlans.length * 2 + 3,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                padding: ''
              },
              {
                text: `${this.getProductAllSumByProduct(product, headerParallel.classType)}`,
                fontWeight: 'normal',
                fontSize: '14px',
                gridColumnStart: this.ratePlans.length * 2 + 3,
                gridColumnEnd: this.ratePlans.length * 2 + 4,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                padding: ''
              }
            )
            this.ratePlans.forEach((ratePlan: any, ratePlanIdx: number) => {
              resTable.items.push(
                {
                  text: `${this.getProductCountByRatePlan(product, headerParallel.classType, ratePlan)}`,
                  fontWeight: 'normal',
                  fontSize: '14px',
                  gridColumnStart: (ratePlanIdx + 1) * 2,
                  gridColumnEnd: (ratePlanIdx + 1) * 2 + 1,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  padding: ''
                },
                {
                  text: `${this.getProductSumByRatePlan(product, headerParallel.classType, ratePlan)}`,
                  fontWeight: 'normal',
                  fontSize: '14px',
                  gridColumnStart: (ratePlanIdx + 1) * 2 + 1,
                  gridColumnEnd: (ratePlanIdx + 1) * 2 + 2,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  padding: ''
                }
              )
            })
            currentRow += 1
          }
        })
      })
      resTable.items.push(
        {
          text: 'Итого',
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: 1,
          gridColumnEnd: this.ratePlans.length * 2 + 3,
          gridRowStart: currentRow,
          gridRowEnd: currentRow + 1,
          padding: ''
        },
        {
          text: `${this.getAllSum()}`,
          background: '#faebd7',
          fontWeight: 'bold',
          fontSize: '14px',
          gridColumnStart: this.ratePlans.length * 2 + 3,
          gridColumnEnd: this.ratePlans.length * 2 + 4,
          gridRowStart: currentRow,
          gridRowEnd: currentRow + 1,
          padding: ''
        }
      )
      resTable.rowsCount = currentRow
      return resTable
    },
    print () {
      printer('printable-area', 'print.css')
    },
    goToSettings () {
      this.$router.push({
        name: 'ReportSettings',
        query: {
          placeId: this.selectedUserAllowedPlace.id,
          report: 'ReportActsDay'
        }
      })
    },
    getTextDate () {
      const monthName = this.monthes.find((x: any) => x.id === (Number(moment(this.dateFrom).format('M')))).name
      return moment(this.dateFrom).format('D') + ' ' + monthName + ' ' + moment(this.dateFrom).format('Y')
    }
  }
})
</script>

<style lang="scss">
.non-visible {
  display: none;
}

.only-for-print {
  display: none !important;
}

.report-table {
  overflow-x: auto;
}
</style>
