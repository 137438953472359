<template>
  <div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="control_panel">
          <div class="balance_panel">
            <div>
              <v-select
                v-model="selectedOption"
                variant="solo"
                density="compact"
                :items="options"
                :hide-details="true"
                label="Школьное питание"
                class="first-select"
              />
            </div>
            <div style="display: flex; flex-direction: row; gap: 24px; margin-left: 6px; justify-content: end;">
              <h4 class="button-text-roboto-medium">
                Горячее питание: {{ accountsSum[0] }} р
              </h4>
              <h4 class="button-text-roboto-medium">
                Буфет: {{ accountsSum[1] }} р
              </h4>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; gap: 12px;">
            <button
              class="secondary-white-button money-accounting-btn"
              @click="goToSettingLimits"
            >
              <h5 class="button-text-roboto-medium">
                Установить ограничение
              </h5>
            </button>
            <button
              class="secondary-white-button money-accounting-btn"
              @click="goToAutoTransfer"
            >
              <h5 class="button-text-roboto-medium">
                Автоперевод
              </h5>
            </button>
          </div>
          <div style="display: flex; flex-direction: row; gap: 12px;">
            <button
              class="secondary-white-button money-accounting-btn"
              @click="goToTransferBetweenChildren"
            >
              <h5 class="button-text-roboto-medium">
                Перевод между детьми
              </h5>
            </button>
            <button
              class="secondary-white-button money-accounting-btn"
              @click="goToTransferBetweenAccounts"
            >
              <h5 class="button-text-roboto-medium">
                Перевод между счетами
              </h5>
            </button>
          </div>
        </div>
        <div
          class="report_panel"
          style="margin-top: 20px;"
        >
          <div class="table-select-row">
            <h5
              class="button-text-roboto-medium"
              style="padding-top: 10px"
            >
              Выписка за
            </h5>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              offset-y
              max-width="390px"
            >
              <template #activator="{props}">
                <v-text-field
                  v-model="dateText"
                  variant="solo"
                  density="compact"
                  label="Период"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details="true"

                  style="max-width: 170px"
                  v-bind="props"
                />
              </template>
              <v-date-picker
                v-model="date"
                hide-weekdays
                hide-header
                title="C"
                multiple="range"
                color="green-accent-2"
                :style="{alignItems: 'center'}"
              >
                <template #actions>
                  <div style="display: flex; flex-direction: row; gap: 10px">
                    <button
                      class="secondary-white-button"
                      style="justify-self: center"
                      @click="transactionsGetByUser(true)"
                    >
                      За весь период
                    </button>
                    <button
                      class="secondary-white-button"
                      style="justify-self: center"
                      @click="transactionsGetByUser(false)"
                    >
                      Ок
                    </button>
                  </div>
                </template>
              </v-date-picker>
            </v-menu>
          </div>
          <!--      <p>{{date[0]}}</p>-->
          <!--      <p>{{date[1]}}</p>-->
          <table class="small-text-roboto-medium custom-table">
            <thead>
              <tr style="background: #ECF2F6">
                <td style="width: 152px">
                  Дата обработки
                </td>
                <td>Факт. дата</td>
                <td>Приход</td>
                <td>Расход</td>
                <td>Счет</td>
              <!--          <td>Источник</td>-->
              </tr>
            </thead>
            <tr
              v-for="transaction in transactionTableData"
              :key="transaction.id"
              @click="goToTransaction(transaction)"
            >
              <td>{{ transaction.creationDate }}</td>
              <td>{{ transaction.factCreationDate }}</td>
              <td>{{ transaction.income }}</td>
              <td>{{ transaction.outlay }}</td>
              <td>{{ transaction.accountName }}</td>
            </tr>
            <tr>
              <td colspan="2">
                Итого :
              </td>
              <td>{{ incomeSum }}</td>
              <td>{{ outlaySum }}</td>
              <td />
            </tr>
          </table>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import {
  moneyAccountsFind,
  salesGetBySalesBatchId,
  transactionGetByUser
} from '@/shared/api/parents/moneyAccounting/api-requests'
import {
  AccountsFindItem,
  TransactionsGetByUserItem,
  TransactionsGetByUserRequest
} from '@/shared/api/parents/moneyAccounting/api-types'
import { Person } from '@/store/user/types'
import moment from 'moment'
import { defineComponent } from 'vue'

export type TransactionTableElem = {
  id: number,
  creationDate: string,
  factCreationDate: string,
  income: number | null,
  outlay: number | null,
  accountName: string,
  transactionTypeId: number,
  saleBatchId: number
}

export default defineComponent({
  name: 'ParentsMoneyAccounting',
  components: { },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      selectedOption: 1,
      options: [{ title: 'Школьное питание', value: 1 }], //, { text: 'Детский сад', value: 2 }, { text: 'Платные услуги', value: 3 }],
      menu: false,
      date: [],
      dateText: '',
      accountsSum: [] as number[],
      isTelegram: false,
      isLoading: false,
      transactions: [] as TransactionsGetByUserItem[],
      transactionTableData: [] as TransactionTableElem[],
      incomeSum: 0,
      outlaySum: 0,
      tempFactCreationDate: '',
      dateFrom: '',
      dateTo: ''
    }
  },
  async beforeMount () {
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    this.isLoading = true
    this.currentUser = this.user.person
    this.dateTo = moment().format('YYYY-MM-DD HH:mm:ss')
    this.dateFrom = moment().add(-14, 'days').format('YYYY-MM-DD HH:mm:ss')
    this.dateText = moment(this.dateFrom).format('DD.MM') + ' - ' + moment(this.dateTo).format('DD.MM')
    await this.postAccountsFind()
    await this.transactionsGetByUser(false)
    // this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async postAccountsFind () {
      try {
        if (this.selectedOption === 1) {
          const postAccountsFindReqObj = {
            userId: this.currentUser.id,
            accountTypeId: null,
            accountTypes: [1, 2]
          }
          const postAccountsFindRes = await moneyAccountsFind(postAccountsFindReqObj, this.isTelegram)
          postAccountsFindRes.data.forEach((account: AccountsFindItem) => {
            this.accountsSum.push(account.sum)
          })
          console.log(postAccountsFindRes.data)
          console.log(this.currentUser)
        } else {
          const postAccountsFindReqObj = {
            userId: this.currentUser,
            accountTypeId: null,
            accountTypes: null
          }
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить баланс счетов!',
          timeout: 3000
        })
      }
    },
    async transactionsGetByUser (wholePeriod: boolean) {
      this.isLoading = true
      try {
        this.transactionTableData = []
        this.incomeSum = 0
        this.outlaySum = 0
        let transactionsGetByUserReqObj: TransactionsGetByUserRequest = {
          userId: 0,
          timestampFrom: '',
          timestampTo: '',
          limit: null
        }
        if (this.date.length > 0 && this.date[0] !== null && this.date[1] !== null) {
          console.log(moment(this.date[0]).format('YYYY-MM-DD'))
          console.log(this.date[1])
          transactionsGetByUserReqObj = {
            userId: this.currentUser.id,
            timestampFrom: moment(this.date[0]).format('YYYY-MM-DD'),
            timestampTo: moment(this.date[this.date.length - 1]).format('YYYY-MM-DD'),
            limit: null
          }
          this.dateText = moment(this.date[0]).format('DD.MM') + ' - ' + moment(this.date[this.date.length - 1]).format('DD.MM')
        } else {
          transactionsGetByUserReqObj = {
            userId: this.currentUser.id,
            timestampFrom: (wholePeriod) ? '2020-01-01' : this.dateFrom,
            timestampTo: this.dateTo,
            limit: null
          }
        }
        const transactionsGetByUserRes = await transactionGetByUser(transactionsGetByUserReqObj, this.isTelegram)
        this.transactions = transactionsGetByUserRes.data
        this.transactions.forEach(transaction => {
          if (transaction.transactionTypeId !== 3 || transaction.saleBatchTransactions.length < 1) {
            const tableElem: TransactionTableElem = {
              id: transaction.id,
              creationDate: moment(transaction.creationDate).format('DD.MM.YYYY HH:mm:ss'),
              factCreationDate: moment(transaction.creationDate).format('DD.MM.YYYY HH:mm:ss'),
              // creationDate: transaction.creationDate,
              // factCreationDate: transaction.creationDate,
              income: transaction.sum > 0 ? transaction.sum : null,
              outlay: transaction.sum < 0 ? Math.abs(transaction.sum) : null,
              accountName: transaction.account.accountTypeId === 1 ? 'Горячее питание' : 'Буфет',
              transactionTypeId: transaction.transactionTypeId,
              saleBatchId: 0
            }
            if (tableElem.income !== null) {
              this.incomeSum = this.incomeSum + tableElem.income
            }
            if (tableElem.outlay !== null) {
              this.outlaySum = this.incomeSum + tableElem.outlay
            }
            this.transactionTableData.push(tableElem)
          } else {
            this.salesGetBySaleBatchId(transaction)
          }
        })
        this.menu = false
        this.isLoading = false
        // todo добавить сортировку по creationDate
        console.log(this.transactionTableData, 'tableData')
        console.log('res', transactionsGetByUserRes)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить транзакции пользователя!',
          timeout: 3000
        })
      }
    },
    async salesGetBySaleBatchId (transaction: TransactionsGetByUserItem) {
      try {
        const salesGetBySaleBatchIdReqObj = {
          userId: this.currentUser.id,
          saleBatchId: transaction.saleBatchTransactions[0].saleBatchId
        }
        const salesGetBySaleBatchIdRes = await salesGetBySalesBatchId(salesGetBySaleBatchIdReqObj, this.isTelegram)
        this.tempFactCreationDate = salesGetBySaleBatchIdRes.data[0].timestamp
        console.log(salesGetBySaleBatchIdRes, 'sale')
        console.log(this.tempFactCreationDate, 'temp')
        const tableElem: TransactionTableElem = {
          id: transaction.id,
          creationDate: moment(transaction.creationDate).format('DD.MM.YYYY HH:mm:ss'),
          // creationDate: transaction.creationDate,
          factCreationDate: this.tempFactCreationDate,
          income: transaction.sum > 0 ? transaction.sum : null,
          outlay: transaction.sum < 0 ? Math.abs(transaction.sum) : null,
          accountName: transaction.account.accountTypeId === 1 ? 'Горячее питание' : 'Буфет',
          transactionTypeId: transaction.transactionTypeId,
          saleBatchId: transaction.saleBatchTransactions[0].saleBatchId
        }
        if (tableElem.income !== null) {
          this.incomeSum = this.incomeSum + tableElem.income
        }
        if (tableElem.outlay !== null) {
          this.outlaySum = this.outlaySum + tableElem.outlay
        }
        this.transactionTableData.push(tableElem)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить информацию о продаже!',
          timeout: 3000
        })
      }
    },
    goToSettingLimits () {
      this.$router.push('./settingLimits')
    },
    goToTransferBetweenAccounts () {
      this.$router.push('./transferBetweenAccounts')
    },
    goToAutoTransfer () {
      this.$router.push('./autoTransfer')
    },
    goToTransferBetweenChildren () {
      this.$router.push('./transferBetweenChildren')
    },
    goToHome () {
      this.$router.push('./home')
    },
    goToTransaction (transaction: TransactionTableElem) {
      this.$router.push({
        name: 'ParentsTransaction',
        params: { saleBatchId: transaction.saleBatchId.toString() }
      })
    }
  }
}
)

</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.page_container {
  justify-content: center;
  display: grid;
  padding: 20px 16px
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.control_panel {
  width: 700px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.balance_panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 651px;
}
.report_panel {
  width: 700px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 9px 9px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
    vertical-align: top;
    text-align: center
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
.report-select {
  max-width: 110px;
}
.first-select {
  max-width: 320px;
}
.money-accounting-btn {
  text-align: center;
  width: 320px;
  padding: 13.5px
}
.table-select-row {
  justify-content: start;
  display: flex;
  flex-direction: row;
  gap: 8px
}
@media (max-aspect-ratio: 6/5) {
  .money-accounting-btn {
    text-align: left;
    padding: 8px;
    width: 160px;
  }
  .table-select-row {
    justify-content: end;
  }
  .control_panel {
    width: 370px;
  }
  .report_panel {
    width: 370px;
  }
  .balance_panel {
    flex-direction: column;
    gap: 16px;
    justify-content: unset;
    width: 330px;
  }
}
</style>
