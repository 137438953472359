<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
      >
        <v-select
          v-model="startValue"
          :items="places"
          item-title="name"
          item-value="id"
          label="с:"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-select
          v-model="endValue"
          :items="places"
          item-title="name"
          item-value="id"
          label="по:"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="save()"
        >
          Добавить
        </v-btn>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          class="button-wrap"
          elevation="2"
          :to="{
            name: $route.query.report,
            params: {
              propPlaceId: $route.query.placeId
            }
          }"
        >
          НАЗАД
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-row
        v-if="!isLoading"
        class="justify-content-center"
      >
        <v-col>
          <h3 class="text-left print-header">
            Настроенные диапазоны:
          </h3>
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && settings.length > 0"
          md="4"
        >
          <v-table class="report-rateplan-day">
            <tbody>
              <template v-for="(setting, i) in settings" :key="i">
                <tr>
                  <td>
                    {{ setting.startValue }} - {{ setting.endValue }}
                  </td>
                  <td>
                    <v-btn
                      :is-loading="isLoading"
                      block
                      @click="remove(setting)"
                    >
                      Удалить
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import {
  find as findPlaceReportSettings,
  remove as removePlaceReportSettings,
  save as savePlaceReportSettings
} from '@/shared/api/placeReportSettings/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  settings: any;
  places: any;
  startValue: any;
  endValue: any;
}

export default defineComponent({
  components: {
  },
  props: {
    placeId: {
      type: String,
      required: true
    },
    report: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      isLoading: false,
      isPost: false,
      settings: [],
      places: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' }
      ],
      startValue: 1,
      endValue: 4
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadSettings () {
      try {
        this.isLoading = true
        const reportSettingsFindPost = {
          placeId: Number(this.$route.query.placeId),
          reportType: 1
        }
        const result = await findPlaceReportSettings(reportSettingsFindPost)
        const settingsResponse = JSON.parse(JSON.stringify(result))
        this.getSettings(settingsResponse)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async save () {
      try {
        this.isLoading = true
        const value = this.startValue + ';' + this.endValue
        const reportSettingsSavePost = {
          placeId: Number(this.$route.query.placeId),
          reportType: 1,
          value: value
        }
        const result = await savePlaceReportSettings(reportSettingsSavePost)
        const settingsResponse = JSON.parse(JSON.stringify(result))
        this.pushSettings(settingsResponse)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async remove (setting: any) {
      try {
        this.isLoading = true
        const reportSettingsRemovePost = {
          id: setting.id,
          placeId: Number(this.$route.query.placeId)
        }
        const result = await removePlaceReportSettings(reportSettingsRemovePost)
        const settingsResponse = JSON.parse(JSON.stringify(result))
        this.getSettings(settingsResponse)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getSettings (settingsResponse: any) {
      this.settings = []
      if (Array.isArray(settingsResponse)) {
        settingsResponse.forEach((element: any) => {
          const elementCopy = element
          const values = elementCopy.value.split(';')
          const setting = {
            id: element.id,
            startValue: values[0],
            endValue: values[1]
          }
          this.settings.push(setting)
        })
      }
      this.sortSettings()
    },
    pushSettings (settingsResponse: any) {
      const elementCopy = settingsResponse
      const values = elementCopy.value.split(';')
      const setting = {
        id: elementCopy.id,
        startValue: values[0],
        endValue: values[1]
      }
      this.settings.push(setting)
      this.sortSettings()
    },
    sortSettings () {
      this.settings.sort((a: any, b: any) => (a.startValue > b.startValue) ? 1 : ((b.startValue > a.startValue) ? -1 : 0))
    }
  },
   mounted () {
     this.loadSettings()
  }
})
</script>

<style lang="scss"></style>
