<template>
  <v-footer
    absolute
    app
    inset
    color="rgba(255,255,255,0.01)"
    style="background-image: url('@/assets/images/background.png'); background-position: center center; background-size: cover"
    width="auto"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          class="text-center"
          cols="12"
        >
          Разработано <span class="green_part">GloL</span><span class="orange_part">ime</span>™
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  }
})
</script>

<style scoped lang="scss">
.green_part {
  color: #1b5e20;
}
.orange_part {
  color: #ff933c;
}
</style>
