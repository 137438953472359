<template>
  <div
    class="table-content"
    :style="{display: 'flex', flexDirection: 'column'}"
  >
    <div
      ref="tableGrid"
      class="table-grid"
      :style="getTableTemplateStyle(tableData)"
    >
      <template
        v-for="(item, itemIdx) in tableData.items"
        :key="itemIdx"
      >
        <div
          class="table-cell"
          :style="getCellTemplateStyle(item)"
        >
          <template v-if="item.slotBefore">
            <div @click="handleBeforeSlotClick(item)">
              <slot
                :name="`slot-before-${item.slotBefore.name}`"
                class="'testClassName'"
                :class="`${item.slotBefore.classNames}`"
              >
                <div />
              </slot>
            </div>
          </template>
          <p :style="{margin: 0}">
            {{ item.text }}
          </p>
          <template v-if="item.slotAfter">
            <div @click="handleAfterSlotClick(item)">
              <slot
                :name="`slot-after-${item.slotAfter.name}`"
                :class="`${item.slotAfter.classNames}`"
              />
            </div>
          </template>
        </div>
      </template>
      <div
        class="table-cell"
        :style="{
          paddingTop: '20px',
          breakInside: 'avoid',
          gridColumn: `${1} / ${tableData.columnsCount}`,
          gridRow: `${tableData.rowsCount + 3} / ${tableData.rowsCount + 4}`
        }"
      >
        <slot name="signs" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export interface Slot {
  name: string;
  classNames: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  afterHandler?: Function;
}

export interface TableDataCell {
  text: string;
  gridRowStart: number;
  gridRowEnd: number;
  gridColumnStart: number;
  gridColumnEnd: number;
  background?: string;
  fontWeight?: string;
  padding?: string;
  fontSize?: string;
  color?: string;
  border?: string;
  fontFamily?: string;
  slotBefore?: Slot;
  slotAfter?: Slot;
  maxWidth?: string;
  minWidth?: string;
  additionalInfo?: any;
}

export interface TableData {
  columnsCount: number;
  rowsCount: number;
  items: TableDataCell[];
}

export default defineComponent({
  name: 'BaseSimpleTable',
  props: {
    tableData: {
      required: true,
      type: Object as PropType<TableData>
    },
    onClickMethod: {
      required: false,
      type: Function
    }
  },
  mounted () {
    this.$emit('mountGridTable', this.$refs.tableGrid)
  },
  updated () {
    this.$emit('mountGridTable', this.$refs.tableGrid)
  },
  methods: {
    handleAfterSlotClick (item: TableDataCell) {
      this.$emit('clickOnSlotAfter', item)
    },
    handleBeforeSlotClick (item: TableDataCell) {
      this.$emit('clickOnSlotBefore', item)
    },
    getTableTemplateStyle (tableData: any) {
      return {
        width: 'fit-content',
        display: 'grid',
        gridTemplateColumns: `repeat(${tableData.columnsCount}, max-content)`,
        gridTemplateRows: `repeat(${tableData.rowsCount}, max-content)`
      }
    },
    getCellTemplateStyle (cell: TableDataCell) {
      return {
        background: `${cell.background ? cell.background : 'none'}`,
        fontWeight: `${cell.fontWeight ? cell.fontWeight : 'none'}`,
        placeContent: 'center',
        display: 'grid',
        textAlign: 'center',
        minWidth: `${cell.minWidth ? cell.minWidth : 'none'}`,
        border: `${cell.border ? cell.border : '1px solid black'}`,
        padding: `${cell.padding ? cell.padding : '1px'}`,
        gridColumn: `${cell.gridColumnStart} / ${cell.gridColumnEnd}`,
        gridRow: `${cell.gridRowStart} / ${cell.gridRowEnd}`,
        fontSize: `${cell.fontSize ? cell.fontSize : '12px'}`,
        color: `${cell.color ? cell.color : '#000000'}`,
        fontFamily: `${cell.fontFamily ? cell.fontFamily : '"Arial", sans-serif'}`,
        breakInside: 'avoid',
        maxWidth: `${cell.maxWidth ? cell.maxWidth : 'none'}`
      }
    }
  }
})
</script>

<style scoped lang="scss">
</style>
