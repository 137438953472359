<template>
  <div class="student-nutrition-settings">
    <template v-if="settingsData.id">
      <div class="student-nutrition-settings__table text-center">
        <div class="student-nutrition-settings__table-header">
          Настройки питания по ученику
        </div>
        <div class="student-nutrition-settings__header-cell">
          <p :style="{margin: 0}">
            Тип питания
          </p>
        </div>
        <div class="student-nutrition-settings__header-cell">
          <p :style="{margin: 0}">
            Дата с
          </p>
        </div>
        <div class="student-nutrition-settings__header-cell">
          <p :style="{margin: 0}">
            Настройка
          </p>
        </div>
        <div class="student-nutrition-settings__cell">
          <p :style="{margin: 0}">
            {{ settingsData.nutritionType }}
          </p>
        </div>
        <div class="student-nutrition-settings__cell">
          <p :style="{margin: 0}">
            {{ settingsData.dateFrom }}
          </p>
        </div>
        <div class="student-nutrition-settings__cell">
          <v-btn @click="openHistoryModal()">
            Настроить
          </v-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="student-nutrition-settings__table text-center">
        <div class="student-nutrition-settings__table-header">
          Настройки питания по ученику
        </div>
        <div class="student-nutrition-settings__header-cell">
          <p :style="{margin: 0}">
            Тип питания
          </p>
        </div>
        <div class="student-nutrition-settings__header-cell">
          <p :style="{margin: 0}">
            Дата
          </p>
        </div>
        <div class="student-nutrition-settings__header-cell">
          <p :style="{margin: 0}">
            Настройка
          </p>
        </div>
        <div
          class="student-nutrition-settings__cell"
          :style="{gridColumnStart: 1, gridColumnEnd: 3}"
        >
          <p :style="{margin: 0}">
            Не настроено
          </p>
        </div>
        <div class="student-nutrition-settings__cell">
          <v-btn @click="openHistoryModal()">
            Настроить
          </v-btn>
        </div>
      </div>
    </template>
    <v-dialog
      v-model="isHistoryOpen"
      width="max-content"
    >
      <v-card
        min-height="300"
        min-width="300"
      >
        <div
          class="wrapper"
          :style="{display: 'flex', flexDirection: 'column', alignItems: 'center'}"
        >
          <v-card-title>
            <div
              class="wrapper"
              :style="{display: 'flex', justifyContent: 'center'}"
            >
              <span class="text-h5 align-center">Настройка питания ученика</span>
            </div>
          </v-card-title>
          <template v-if="!isHistoryLoading">
            <v-card-text>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center'}"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      v-model:return-value="modalData.selectedDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ props }">
                        <v-text-field
                          :model-value="formatDate(modalData.selectedDate)"
                          label="Дата с"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="props"
                        />
                      </template>
                      <v-date-picker
                        hide-weekdays
                        v-model="modalData.selectedDate"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="menu = false"
                        >
                          Отмена
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="($refs.menu as any).save(modalData.selectedDate)"
                        >
                          ОК
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <v-select
                      v-model="modalData.selectedNutritionType"
                      label="Тип питания"
                      :items="modalData.mockNutritionTypes"
                      item-title="name"
                      item-value="id"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    align-self="center"
                  >
                    <v-btn
                      :style="{textAlign: 'end'}"
                      color="primary"
                      @click="startConfirm"
                    >
                      Сохранить
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-text>
              <template v-if="historyData.length > 0">
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'center'}"
                >
                  <div
                    class="student-nutrition-settings__table text-center"
                    :style="{display: 'grid',gridTemplateColumns: 'repeat(3, max-content)',gridGap: '1px'}"
                  >
                    <div
                      class="student-nutrition-settings__cell"
                      :style="{ boxShadow: '0 0 0 1px #808080', padding: '5px', fontWeight: 'bold', background: '#faebd7', fontSize: '16px'}"
                    >
                      <p :style="{margin: 0}">
                        Дата с
                      </p>
                    </div>
                    <div
                      class="student-nutrition-settings__cell"
                      :style="{ boxShadow: '0 0 0 1px #808080', padding: '5px', fontWeight: 'bold', background: '#faebd7', fontSize: '16px'}"
                    >
                      <p :style="{margin: 0}">
                        Тип питания
                      </p>
                    </div>
                    <div
                      class="student-nutrition-settings__cell"
                      :style="{ boxShadow: '0 0 0 1px #808080', padding: '5px', fontWeight: 'bold', background: '#faebd7', fontSize: '16px'}"
                    >
                      <p :style="{margin: 0}">
                        Создана
                      </p>
                    </div>
                    <template
                      v-for="(row, rowIdx) in historyData"
                      :key="`date-from-${rowIdx}`"
                    >
                      <div
                        class="student-nutrition-settings__cell"
                        :style="{ boxShadow: '0 0 0 1px #808080', padding: '5px', fontWeight: 'normal'}"
                      >
                        <p :style="{margin: 0, fontWeight: row.active ? 'bold' : 'normal'}">
                          {{ row.dateFrom }}
                        </p>
                      </div>
                      <div
                        :id="`nutrition-type-${rowIdx}`"
                        class="student-nutrition-settings__cell"
                        :style="{ boxShadow: '0 0 0 1px #808080', padding: '5px', fontWeight: 'normal'}"
                      >
                        <p :style="{margin: 0, fontWeight: row.active ? 'bold' : 'normal'}">
                          {{ row.nutritionType }}
                        </p>
                      </div>
                      <div
                        :id="`date-created-${rowIdx}`"
                        class="student-nutrition-settings__cell"
                        :style="{ boxShadow: '0 0 0 1px #808080', padding: '5px', fontWeight: 'normal'}"
                      >
                        <p :style="{margin: 0, fontWeight: row.active ? 'bold' : 'normal'}">
                          {{ row.dateCreated }}
                        </p>
                      </div>
                    </template>
                    <div
                      class="student-nutrition-settings__cell"
                      :style="{ boxShadow: '0 0 0 1px #808080', gridColumnStart: '1', gridColumnEnd: '4', padding: '5px', fontWeight: 'bold', background: '#faebd7'}"
                    >
                      <p :style="{margin: 0}">
                        Активный выделен жирным шрифтом
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </v-card-text>
            <div
              class="wrapper"
              :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
            >
              <v-card-actions>
                <v-btn
                  color="blue"
                  @click="isHistoryOpen = false"
                >
                  Отмена
                </v-btn>
              </v-card-actions>
            </div>
          </template>
          <template v-else>
            <div
              class="wrapper"
              :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}"
            >
              <v-col class="text-center">
                <v-progress-circular
                  :size="100"
                  :width="7"
                  color="green"
                  indeterminate
                />
              </v-col>
            </div>
          </template>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isConfirmedOpen"
      width="max-content"
    >
      <v-card>
        <v-card-title>
          <div
            class="wrapper"
            :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
          >
            <span class="text-h6 align-center"><strong>ВНИМАНИЕ!!!</strong><br>Существуюшие талоны будут перезаписаны и отмечены как ошибочные</span>
          </div>
        </v-card-title>
        <v-card-text>
          <div
            class="wrapper"
            :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
          >
            <div
              class="text--primary"
              :style="{display: 'grid', gridTemplateColumns: 'repeat(2, max-content)', gridGap: '1px'}"
            >
              <div
                class="student-nutrition-settings__cell"
                :style="{ boxShadow: '0 0 0 1px #808080', gridColumnStart: '1', gridColumnEnd: '3', padding: '5px', fontWeight: 'bold', background: '#faebd7'}"
              >
                <p :style="{margin: 0}">
                  Список талонов
                </p>
              </div>
              <div
                :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
              >
                <p :style="{margin: 0}">
                  Номер талона
                </p>
              </div>
              <div
                :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
              >
                <p :style="{margin: 0}">
                  Дата
                </p>
              </div>
              <template
                v-for="(talon, talonIdx) in modalConfirmData.talons"
                :key="`talon-number-${talonIdx}`"
              >
                <div
                  :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'normal'}"
                >
                  <p :style="{margin: 0}">
                    {{ talon.number }}
                  </p>
                </div>
                <!--                :key="`talon-number-${talonIdx}`"-->
                <div
                  :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'normal'}"
                >
                  <p :style="{margin: 0}">
                    с {{ talon.date }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <div
            class="wrapper"
            :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
          >
            <v-btn
              color="blue"
              @click="confirm()"
            >
              Продолжить изменения
            </v-btn>
            <v-btn
              color="lighten-2"
              @click="closeConfirmedModal"
            >
              Отказаться
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import { createUserFoodSettings, findUserFoodSettings } from '@/shared/api/nutritionSettings/api-requests'

export interface MockNutritionTypeItem {
  id: number;
  name: string;
}

export interface MockSettingsDataItem {
  id: number;
  name: string;
  nutritionType: string;
  dateFrom: string;
}

export interface MockHistoricalDataItem {
  id: number;
  name: string;
  data: {
    nutritionType: string;
    dateFrom: string;
    dateCreated: string;
    active: boolean;
  }[];
}

export default defineComponent({
  name: 'StudentNutritionSettings',
  props: {
    userId: {
      type: Number,
      required: true
    },
    currentUserFoodSetting: {
      type: Object as PropType<any>,
      required: true
    }
  },
  data () {
    return {
      menu: false,
      isHistoryOpen: false,
      isHistoryLoading: false,
      isConfirmedOpen: false,
      isConfirmationLoading: false,
      settingsData: {} as any,
      modalConfirmData: {
        title: '',
        talons: [] as any
      },
      modalData: {
        title: 'Настройки группы',
        mockNutritionTypes: [
          {
            id: 1,
            name: 'Комлексный обед'
          },
          {
            id: 2,
            name: 'Двухразовое'
          }
        ] as MockNutritionTypeItem[],
        selectedNutritionTypeError: false,
        selectedNutritionType: 1,
        selectedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      mockHistoricalData: {
        id: 1,
        name: 'Иванов Иван Иванович',
        data: [
          {
            nutritionType: 'Комплексный обед',
            dateFrom: '01.01.2022',
            dateCreated: '01.01.2022',
            active: false
          },
          {
            nutritionType: 'Двухразовое',
            dateFrom: '01.06.2022',
            dateCreated: '01.01.2022',
            active: false
          },
          {
            nutritionType: 'Комплексный обед',
            dateFrom: '01.10.2022',
            dateCreated: '01.01.2022',
            active: true
          }
        ]
      } as MockHistoricalDataItem,
      historyData: [] as any
    }
  },
  async beforeMount () {
    // Подгрузка данных с API
    // console.log('Первоначальная загрузка', this.currentUserFoodSetting)
    if (this.currentUserFoodSetting) {
      this.settingsData = {
        id: this.userId,
        nutritionType: this.currentUserFoodSetting.value === 1 ? 'Комлексный обед' : 'Двухразовое',
        userFoodSettingsTypeId: 1,
        nutritionTypeId: this.currentUserFoodSetting.value,
        dateFrom: moment(this.currentUserFoodSetting.timestamp).format('DD.MM.YYYY')
      }
    }
  },
  methods: {
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async startConfirm () {
      this.isHistoryLoading = true
      try {
        const res = await createUserFoodSettings({
          userId: this.userId,
          userFoodSettingTypeId: 1,
          timestamp: moment(this.modalData.selectedDate).format('YYYY-MM-DD'),
          value: this.modalData.selectedNutritionType
        })
        if (res.errorCode === 409) {
          this.isHistoryLoading = false
          this.isConfirmedOpen = true
          this.modalConfirmData.talons = res.data.talons
        } else if (res.errorCode === 0) {
          this.settingsData = await this.refreshUserSetting()
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.$emit('getSuccess', {
            code: '200',
            text: 'Запись успешно добавлена!'
          })
        } else {
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.$emit('getError', {
            code: '500',
            text: 'Произошла ошибка! Не удалось сохранить изменения!'
          })
        }
      } catch (e: any) {
        this.isHistoryLoading = false
        this.isHistoryOpen = false
        this.$emit('getError', {
          code: '500',
          text: `Произошла ошибка! Код ответа ${e.response.status} - ${e.response.statusText}`
        })
      }
    },
    closeConfirmedModal () {
      this.isConfirmedOpen = false
    },
    async confirm () {
      this.isConfirmationLoading = true
      try {
        const res = await createUserFoodSettings({
          userId: this.userId,
          userFoodSettingTypeId: 1,
          timestamp: this.modalData.selectedDate,
          value: this.modalData.selectedNutritionType,
          confirm: true
        })
        if (res.errorCode === 0) {
          this.settingsData = await this.refreshUserSetting()
          this.$emit('getSuccess', {
            code: '200',
            text: 'Запись успешно добавлена!'
          })
        } else {
          this.$emit('getError', {
            code: '500',
            text: 'Произошла ошибка! Не удалось сохранить изменения!'
          })
        }
        this.isConfirmationLoading = false
        this.isConfirmedOpen = false
        this.isHistoryOpen = false
      } catch (e: any) {
        this.isConfirmationLoading = false
        this.isConfirmedOpen = false
        this.isHistoryOpen = false
        this.$emit('getError', {
          code: '500',
          text: `Произошла ошибка! Код ответа ${e.response.status} - ${e.response.statusText}`
        })
      }
    },
    async refreshUserSetting () {
      const res: any = await findUserFoodSettings({
        userId: this.userId,
        current: true
      })
      return {
        id: this.userId,
        nutritionType: res[0].value === 1 ? 'Комлексный обед' : 'Двухразовое',
        userFoodSettingsTypeId: 1,
        nutritionTypeId: res[0].value,
        dateFrom: moment(res[0].timestamp).format('DD.MM.YYYY')
      }
    },
    async openHistoryModal () {
      this.historyData = []
      this.isHistoryOpen = true
      this.isHistoryLoading = true
      this.modalData.selectedNutritionType = this.settingsData.nutritionTypeId === 1 ? 2 : 1
      const res = await findUserFoodSettings({
        userId: this.userId
      })
      res.forEach((userHistoryItem: any) => {
        this.historyData.push({
          dateFrom: moment(userHistoryItem.timestamp).format('DD.MM.YYYY'),
          nutritionType: userHistoryItem.value === 1 ? 'Комлексный обед' : 'Двухразовое',
          nutritionTypeId: userHistoryItem.value,
          active: userHistoryItem.active === 1,
          dateCreated: moment(userHistoryItem.creationDate).format('DD.MM.YYYY')
        })
      })
      this.historyData.reverse().sort((prev: any, curr: any) => {
        return prev.dateFrom < curr.dateFrom ? 1 : -1
      })
      this.isHistoryLoading = false
    }
  }
})
</script>

<style scoped lang="scss">
.student-nutrition-settings {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .student-nutrition-settings__table {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    //grid-template-rows: repeat(5, max-content);
    width: 100%;
    grid-gap: 1px;

    .student-nutrition-settings__table-header {
      background: #1b5e20;
      color: white;
      font-size: 16px;
      grid-column-start: 1 !important;
      grid-column-end: 4 !important;
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
    }

    .student-nutrition-settings__header-cell {
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
      background: #faebd7;
      font-weight: bold;
      font-size: 16px;
    }

    .student-nutrition-settings__cell {
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
      font-weight: normal;
    }
  }
}
</style>
