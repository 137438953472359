import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c9a03364"),n=n(),_popScopeId(),n)
const _hoisted_1 = { colspan: "3" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_NutritionHeadChooseModal = _resolveComponent("NutritionHeadChooseModal")!
  const _component_NutritionBuildingUpdateModal = _resolveComponent("NutritionBuildingUpdateModal")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "mt-5 convertedRawApiData pt-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "d-flex justify-space-around" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildings, (item) => {
            return (_openBlock(), _createBlock(_component_v_btn, {
              key: item.id,
              class: _normalizeClass(item.id === _ctx.buildingFilterValue ? 'v-btn--active px-5 py-5' : 'px-5 py-5'),
              onClick: ($event: any) => (_ctx.assingBuildingFilterValue(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name) + " ", 1),
                _createVNode(_component_v_icon, {
                  end: "",
                  theme: "dark",
                  onClick: ($event: any) => (_ctx.openDialog2(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-lead-pencil ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1032, ["class", "onClick"]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_data_table, {
                headers: _ctx.headers,
                items: _ctx.filterAll(),
                class: "elevation-1"
              }, {
                [`body.prepend`]: _withCtx(() => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.classFilterValue,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.classFilterValue) = $event)),
                        clearable: "",
                        style: {"width":"100px"},
                        onKeyup: _ctx.filterAll
                      }, null, 8, ["modelValue", "onKeyup"])
                    ]),
                    _createElementVNode("td", _hoisted_1, [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.headFilterValue,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.headFilterValue) = $event)),
                        clearable: "",
                        style: {"width":"150px"},
                        onKeyup: _ctx.filterAll
                      }, null, 8, ["modelValue", "onKeyup"])
                    ])
                  ])
                ]),
                [`item.head`]: _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.head.name), 1)
                ]),
                [`item.actions`]: _withCtx(({ item }) => [
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.openDialog(item))
                  }, "Назначить", 8, _hoisted_2)
                ]),
                [`item.building`]: _withCtx(({ item }) => [
                  _createVNode(_component_v_select, {
                    items: _ctx.buildings,
                    density: "compact",
                    variant: "solo",
                    "item-title": "name",
                    "item-value": "id",
                    "model-value": item.building,
                    "hide-details": "",
                    "onUpdate:modelValue": ($event: any) => (_ctx.onChangeBuilding(item, $event))
                  }, null, 8, ["items", "model-value", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1032, ["headers", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_NutritionHeadChooseModal, {
        opened: _ctx.headChooseModalOpen,
        "onUpdate:opened": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.headChooseModalOpen) = $event)),
        data2: _ctx.headChooseModalData,
        onChangeFromChild: _cache[3] || (_cache[3] = ($event: any) => (_ctx.assingClassHead($event)))
      }, null, 8, ["opened", "data2"]),
      _createVNode(_component_NutritionBuildingUpdateModal, {
        opened: _ctx.buildingUpdateModalOpen,
        "onUpdate:opened": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.buildingUpdateModalOpen) = $event)),
        data1: _ctx.buildingUpdateModalData,
        data2: null,
        onChangeFromChild: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateBuilding($event)))
      }, null, 8, ["opened", "data1"])
    ]),
    _: 1
  }))
}