import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { FoodWarehousesFindPost, FoodWarehousesSaveReportPost } from './api-types'

const routeFoodWarehousesFind = '/foodWarehouses/find'
const routeFoodWarehousesSaveReport = '/foodWarehouses/savereport'

export const foodWarehousesFind = async (data: FoodWarehousesFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehousesFind, data)
  return result.data.data
}

export const foodWarehousesSaveReport = async (data: FoodWarehousesSaveReportPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehousesSaveReport, data)
  return result.data.data
}
