<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Главная</strong>
        </h1>
        <!--
        <div v-if="user.person">
          <p>Даные пользователя</p>
          <p>
            <strong>{{ JSON.stringify(user) }}</strong>
          </p>
        </div>
-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  }
})
</script>

<style lang="scss"></style>
