import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { DemandForUpdateEmailPost } from './api-types'

const routeDemandForUpdateEmail = 'temporyemails/demand'

export const demandForUpdateEmail = async (data: DemandForUpdateEmailPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeDemandForUpdateEmail, data)
  return result.data.data
}
