import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AcquiringGetBindingsRequest, AcquiringGetBindingsResponse } from '@/shared/api/parents/singlePayment/api-types'

enum SinglePaymentRoutes {
  GetBindings = '/person/acquiring/getBindings',
}

const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const getBindings = async (data: AcquiringGetBindingsRequest, telegram: boolean): Promise<any> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<any>(SinglePaymentRoutes.GetBindings, data, { headers })
    return result.data
  } else {
    const result = await axios.post<any>(SinglePaymentRoutes.GetBindings, data)
    return result.data
  }
}
