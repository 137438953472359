import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { Device, ConfigurationInfo } from '@/store/deviceList/types'
import { ConfigurationInfoUpdateTimestampFindPost, DeviceByPlaceFindPost, DeviceFindPost } from './api-types'

const routeDeviceList = '/devices/all'
const routeConfigurationInfoUpdateTimestampFind = '/devices/configurationinfo/findupdatetimestamp'
const routeDeviceByPlaceFind = '/devices/find/byplace'
const routeDeviceFind = '/devices/find'

export const all = async (): Promise<Device[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: Device[];
  }> = await axios.get(routeDeviceList)
  return result.data.data
}

export const configurationInfoFind = async (data: ConfigurationInfoUpdateTimestampFindPost): Promise<ConfigurationInfo> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: ConfigurationInfo;
  }> = await axios.post(routeConfigurationInfoUpdateTimestampFind, data)
  return result.data.data
}

export const findDeviceByPlace = async (data: DeviceByPlaceFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeDeviceByPlaceFind, data)
  return result.data.data
}

export const find = async (data: DeviceFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeDeviceFind, data)
  return result.data.data
}
