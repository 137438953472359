<template>
  <div class="whole_page">
    <!--  <button @click="goToHome" class="goBackButton"><img src="~@/assets/images/undo.png" height="20" width="20">Назад</button>-->
    <div class="desktop_wrapper">
      <div class="content_container">
        <v-row class="first_row">
          <h3
            class="button-text-roboto-medium"
            style="text-align: center"
          >
            Итого: 0 р
          </h3>
          <div class="control_panel">
            <button
              class="primary-green-button"
              style="width: 128px; height: 36px; padding: 6px 28px; "
            >
              Оплатить
            </button>
            <button
              class="primary-green-button"
              style="width: 36px; height: 36px; padding: 6px"
              @click="showSetting"
            >
              <v-icon>mdi-cog</v-icon>
            </button>
            <v-dialog
              v-model="showSettings"
              width="328px"
              light:true
              :retain-focus="false"
            >
              <v-card style="display: flex; flex-direction: column; gap: 24px">
                <h4
                  class="body-text-roboto-medium"
                  style="background: #84DAA7; padding: 16px"
                >
                  Здесь можно корректировать суммы шаблона пополнения баланса
                </h4>
                <div style="padding: 0px 10px; display: flex; justify-content: center; gap: 10px; ">
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details
                    label="100"
                    style="width: 92px; border-radius: 3px"
                  />
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details
                    label="250"
                    style="width: 92px; border-radius: 3px; "
                  />
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details
                    label="500"
                    style="width: 92px; border-radius: 3px"
                  />
                </div>
                <div style="justify-content: center; display: flex; gap: 12px; margin-bottom: 24px">
                  <button
                    class="secondary-white-button"
                    style="width: 142px"
                    @click="closeSetting"
                  >
                    Отмена
                  </button>
                  <button
                    class="primary-green-button"
                    style="width: 142px"
                  >
                    Сохранить
                  </button>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </v-row>
        <v-row class="content_row">
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <h3
              class="button-text-roboto-medium"
              style="text-align: center"
            >
              Андрей
            </h3>
            <v-select
              variant="solo"
              density="compact"
              hide-details="true"
              style="max-width: 145px"
            />
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
              <h5 class="quote-text-roboto-medium">
                Горячее
              </h5>
              <h5 class="quote-text-roboto-medium">
                99999.99р
              </h5>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: row; gap: 8px">
                <button class="add_sum_btn">
                  100
                </button>
                <button class="add_sum_btn">
                  250
                </button>
                <button class="add_sum_btn">
                  500
                </button>
              </div>
              <v-text-field
                variant="solo"
                clearable
                hide-details="true"
                class="telegram-input"
              />
            </div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
              <h5 class="quote-text-roboto-medium">
                Буфет
              </h5>
              <h5 class="quote-text-roboto-medium">
                99999.99р
              </h5>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: row; gap: 8px">
                <button class="add_sum_btn">
                  100
                </button>
                <button class="add_sum_btn">
                  250
                </button>
                <button class="add_sum_btn">
                  500
                </button>
              </div>
              <v-text-field
                variant="solo"
                clearable
                hide-details="true"
                class="telegram-input"
              />
            </div>
          </div>
        </v-row>
        <v-row class="content_row">
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <h3
              class="button-text-roboto-medium"
              style="text-align: center"
            >
              Алексей
            </h3>
            <v-select
              variant="solo"
              density="compact"
              hide-details="true"
              style="max-width: 145px"
            />
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
              <h5 class="quote-text-roboto-medium">
                Горячее
              </h5>
              <h5 class="quote-text-roboto-medium">
                99999.99р
              </h5>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: row; gap: 8px">
                <button class="add_sum_btn">
                  100
                </button>
                <button class="add_sum_btn">
                  250
                </button>
                <button class="add_sum_btn">
                  500
                </button>
              </div>
              <v-text-field
                variant="solo"
                clearable
                hide-details="true"
                class="telegram-input"
              />
            </div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
              <h5 class="quote-text-roboto-medium">
                Буфет
              </h5>
              <h5 class="quote-text-roboto-medium">
                99999.99р
              </h5>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: row; gap: 8px">
                <button class="add_sum_btn">
                  100
                </button>
                <button class="add_sum_btn">
                  250
                </button>
                <button class="add_sum_btn">
                  500
                </button>
              </div>
              <v-text-field
                variant="solo"
                clearable
                hide-details="true"
                class="telegram-input"
              />
            </div>
          </div>
        </v-row>
        <v-row class="content_row">
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <h3
              class="button-text-roboto-medium"
              style="text-align: center"
            >
              Антон
            </h3>
            <v-select
              variant="solo"
              density="compact"
              hide-details="true"
              style="max-width: 145px"
            />
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
              <h5 class="quote-text-roboto-medium">
                Горячее
              </h5>
              <h5 class="quote-text-roboto-medium">
                99999.99р
              </h5>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: row; gap: 8px">
                <button class="add_sum_btn">
                  100
                </button>
                <button class="add_sum_btn">
                  250
                </button>
                <button class="add_sum_btn">
                  500
                </button>
              </div>
              <v-text-field
                variant="solo"
                clearable
                hide-details="true"
                class="telegram-input"
              />
            </div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
              <h5 class="quote-text-roboto-medium">
                Буфет
              </h5>
              <h5 class="quote-text-roboto-medium">
                99999.99р
              </h5>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: row; gap: 8px">
                <button class="add_sum_btn">
                  100
                </button>
                <button class="add_sum_btn">
                  250
                </button>
                <button class="add_sum_btn">
                  500
                </button>
              </div>
              <v-text-field
                variant="solo"
                clearable
                hide-details="true"
                class="telegram-input"
              />
            </div>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsSinglePayment',
  data () {
    return {
      showSettings: false
    }
  },
  methods: {
    goToHome () {
      this.$router.push('./home')
    },
    showSetting () {
      this.showSettings = true
    },
    closeSetting () {
      this.showSettings = false
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.content_container {
  display: flex;
  width: 940px;
  flex-direction: column;
  padding: 24px 12px;
  margin: 20px 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.first_row {
  padding-bottom: 12px;
  margin: 0px 1px;
  display: flex;
  border-bottom: 2px solid $color-dark-50;
  justify-content: space-between;
  align-items: center;
}
.content_row {
  padding: 12px 0px;
  margin: 0px 1px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $color-dark-50;
  gap: 16px;
}
.control_panel {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.telegram-input {
  max-width: 75px;
  margin-left: 8px;
  background: #ffffff;
  border-radius: 8px;
}
::placeholder {
  padding-left: 5px;
}
.add_sum_btn {
  width: 46px;
  height: 46px;
  border-radius: 20px;
  border: 1px solid $color-dark-50;
  background: #FFFFFF;
  font-weight: 500;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);

}
.desktop_wrapper {
  display: grid;
  justify-content: center
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: calc(100% - 32px);
  }
  .desktop_wrapper {
    display: unset;
    justify-content: unset;
  }
}
</style>
