<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :offset="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ props }">
      <v-text-field
        v-model="date"
        :lable="label"
        prepend-icon="mdi-calendar"
        readonly
        :model-value="formatDate(date)"
        v-bind="props"
      />
    </template>
    <v-date-picker
      v-model="date"
      hide-weekdays
      @input="update"
    />
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: ['label'],
  data () {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false
    }
  },
  mounted () {
    this.update(this.date)
  },
  methods: {
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    update (value: any) {
      this.menu = false
      this.$emit('date-select', value)
    }
  }
})
</script>

<style lang="scss"></style>
