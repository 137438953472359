<template>
  <div class="whole_page">
    <div style="display: grid; justify-content: center">
      <div class="content_container">
        <h2 class="title-h2-roboto-medium">
          Электронные квитанции
        </h2>
        <div style="justify-content: space-between; display: flex; flex-direction: row; gap: 10px">
          <button
            class="secondary-white-button"
            @click="changeTab"
          >
            Неоплаченные
          </button>
          <button
            class="secondary-white-button"
            @click="changeTab"
          >
            Оплаченные
          </button>
        </div>
        <template v-if="showPaid">
          <table class="custom-table small-text-roboto-medium">
            <thead>
              <tr style="background: #ECF2F6">
                <td>№</td>
                <td>Услуга</td>
                <td>Дата</td>
                <td>Сумма</td>
              </tr>
            </thead>
            <tr>
              <td>1</td>
              <td>Продленка</td>
              <td>30.05.2024</td>
              <td>100</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Уборка</td>
              <td> 29.05.2024</td>
              <td>342</td>
            </tr>
          </table>
        </template>
        <template v-if="showUnpaid">
          <v-row
            v-for="(receipt, index) in unpaidReceipts"
            :key="receipt.id"
            style="border-bottom: 2px solid #ebeaec"
          >
            <v-col style="display: flex; flex-direction: row">
              <h4 class="body-text-roboto-medium">
                1
              </h4>
              <vue-qrcode
                v-if="unpaidReceipts[index].qrContent !== null"
                :value="unpaidReceipts[index].qrContent"
                style="width: 120px; height: 120px; align-self: center"
              />
            </v-col>
            <v-col>
              <v-row style="margin: 12px 0px ">
                <h4 class="small-text-roboto-medium">
                  Услуга:
                </h4>
              </v-row>
              <v-row style="margin: 12px 0px ">
                <h4 class="small-text-roboto-medium">
                  Дата:
                </h4>
              </v-row>
              <v-row style="margin: 12px 0px ">
                <h4 class="small-text-roboto-medium">
                  Сумма:
                </h4>
              </v-row>
            </v-col>
            <v-col>
              <v-row style="margin: 12px 0px">
                <h4 class="small-text-roboto-medium">
                  {{ receipt.purpose }}
                </h4>
              </v-row>
              <v-row style="margin: 12px 0px">
                <h4 class="small-text-roboto-medium">
                  {{ receipt.date }}
                </h4>
              </v-row>
              <v-row style="margin: 12px 0px">
                <h4 class="small-text-roboto-medium">
                  {{ receipt.sum }}
                </h4>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <div
          v-if="paidReceipts.length < 1 && unpaidReceipts.length < 1"
          class="attention_notification"
        >
          <div class="attention-notification-text-box">
            <h4 class="body-text-roboto-medium">
              Электронных квитанций нет
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { getByUserId } from '@/shared/api/parents/digitalReceipts/api-requests'
import { ReceiptGetByUserIdItem } from '@/shared/api/parents/digitalReceipts/api-types'
import VueQrcode from 'vue-qrious'
import { defineComponent } from 'vue'

export type receiptTableElem = {
  purpose: string | null,
  date: string,
  sum: string | null,
  id: number,
  qrContent: string
}

export default defineComponent({
  name: 'ParentsDigitalReceipts',
  components: { VueQrcode },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      isTelegram: false,
      paidReceipts: [] as receiptTableElem[],
      unpaidReceipts: [] as receiptTableElem[],
      unpaidReceiptsRaw: [] as ReceiptGetByUserIdItem[],
      showPaid: false,
      showUnpaid: true
    }
  },
  async beforeMount () {
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.receiptsGetByUserId()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async receiptsGetByUserId () {
      try {
        const paidReceiptsGetByUserIdReqObj = {
          userId: this.currentUser.id,
          id: null,
          status: 0,
          receiptTypeId: null,
          paymentPeriod: null
        }
        const unpaidReceiptsGetByUserIdReqObj = {
          userId: this.currentUser.id,
          id: null,
          status: 1,
          receiptTypeId: null,
          paymentPeriod: null
        }
        const paidReceiptsGetByUserIdRes = await getByUserId(paidReceiptsGetByUserIdReqObj, this.isTelegram)
        paidReceiptsGetByUserIdRes.data.forEach(receipt => {
          const paidReceiptItem: receiptTableElem = {
            purpose: this.getQrFieldValue(receipt.qrContent, 'Purpose'),
            date: receipt.creationDate,
            sum: this.getQrFieldValue(receipt.qrContent, 'Sum'),
            id: receipt.id,
            qrContent: receipt.qrContent
          }
          this.paidReceipts.push(paidReceiptItem)
        })
        const unpaidReceiptsGetByUserIdRes = await getByUserId(unpaidReceiptsGetByUserIdReqObj, this.isTelegram)
        this.unpaidReceiptsRaw = unpaidReceiptsGetByUserIdRes.data
        unpaidReceiptsGetByUserIdRes.data.forEach(receipt => {
          const unpaidReceiptItem: receiptTableElem = {
            purpose: this.getQrFieldValue(receipt.qrContent, 'Purpose'),
            date: receipt.creationDate,
            sum: this.getQrFieldValue(receipt.qrContent, 'Sum'),
            id: receipt.id,
            qrContent: receipt.qrContent
          }
          this.unpaidReceipts.push(unpaidReceiptItem)
        })
        console.log(this.unpaidReceipts, 'sd')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить данные об электронных квитанциях пользователя!',
          timeout: 3000
        })
      }
    },
    getQrFieldValue (str: string, fieldName: string) {
      const regex = new RegExp(`${fieldName}=([^|]+)`)
      const match = str.match(regex)
      return match ? match[1] : null
    },
    goToHome () {
      this.$router.push('./home')
    },
    changeTab () {
      this.showUnpaid = !this.showUnpaid
      this.showPaid = !this.showPaid
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  margin: 20px 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 700px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 10px 10px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: calc(100% - 32px);
  }
}
</style>
