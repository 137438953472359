<template>
  <v-app>
    <vue-progress-bar />
    <div v-if="routePartialType === 'full'">
      <div class="page-wrapper">
        <div v-if="user.person==null && !isBetaPage()">
          <navbar />
        </div>
        <div v-else-if="user.person">
          <navbar-user-type />
        </div>
        <v-main>
          <div
            class="page-container"
            role="main"
            aria-label="Main"
          >
            <router-view />
          </div>
        </v-main>
        <footer-main />
      </div>
    </div>

    <div v-if="routePartialType === 'account'">
      <div class="page-wrapper">
        <navbar-account />
        <v-main>
          <div
            class="main-content-wrapper main-content-wrapper--with-padding main-content-wrapper--grey-background"
            role="main"
            aria-label="Main"
          >
            <router-view />
          </div>
        </v-main>

        <footer-main />
      </div>
    </div>

    <div v-if="routePartialType === 'telegram'">
      <div class="page-wrapper">
        <router-view />
      </div>
    </div>

    <div v-if="routePartialType === 'parents'">
      <navbar-parents />
      <!--      <navbar-parents-desktop></navbar-parents-desktop>-->
      <v-main>
        <div class="whole_page">
          <router-view />
        </div>
      </v-main>
      <footer-parents />
    </div>

    <div
      v-if="routePartialType === 'meta'"
      role="main"
      aria-label="Main"
    >
      <div class="main-content-wrapper">
        <router-view />
      </div>
    </div>

    <snackbar />

    <back-to-top />
  </v-app>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex'
import Navbar from '@/widgets/navbars/Navbar.vue'
import NavbarAccount from '@/widgets/navbars/NavbarAccount.vue'
import NavbarUserType from '@/widgets/navbars/NavbarUserType.vue'
import FooterMain from '@/widgets/footers/FooterMain.vue'
import BackToTop from '@/widgets/navigation/BackToTop.vue'
import Snackbar from '@/widgets/snackbars/SnackBar.vue'
import { RouteMeta } from 'vue-router'
import store from '@/app/providers/store'
import FooterParents from '@/widgets/footers/FooterParents.vue'
import NavbarParents from '@/widgets/navbars/NavBarParents.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    NavbarParents,
    FooterParents,
    Navbar,
    NavbarAccount,
    NavbarUserType,
    FooterMain,
    BackToTop,
    Snackbar
  },
  computed: {
    ...mapState(['snackbar']),
    ...mapGetters({
      user: 'user/user'
    }),
    routePartialType (): string {
      return (this.$route.meta as RouteMeta).partialType
    }
  },
  async beforeCreate () {
    console.log('beforeCreate')
    const urlsWithoutGetPerson = ['TelegramLogin']
    if ((this.$route.meta as RouteMeta).betaComponent) {
      if (this.$route?.name && urlsWithoutGetPerson.includes(String(this.$route.name))) {
        // Скипаем получение пользователя, если заходим через телеграм
      } else {
        await store.dispatch('user/getPerson')
      }
    }
  },
  methods: {
    isBetaPage (): boolean | undefined {
      return (this.$route.meta as RouteMeta).betaComponent
    }
  }
})
</script>

<style lang="scss">
@import "~@/app/styles/variables.scss";

.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  height: 100%;
}
</style>
