<template>
  <div style="position: relative; justify-content: center; align-items: center">
    <base-simple-table
      :table-data="getTableDataFromAcceptedRation(acceptedRation)"
      :style="{padding: '10px', borderRadius: '12px'}"
    />
    <!--    <v-checkbox v-if="withCheckbox" class="rations_cards__checkbox" :value=checked :input-value="checked" @change="selectRationToSave(acceptedRation)"></v-checkbox>-->
    <table class="ration_table">
      <tr
        v-if="!checked && withCheckbox"
        class="head_row_dark button-text-roboto-medium"
      >
        <td>
          {{ rationItem.name }}
          <h4 class="small-text-roboto-medium">
            Obed
          </h4>
        </td>
        <td v-if="rationItem.accountTypeId === 4">
          Льготное
        </td>
        <td v-else-if="rationItem.accountTypeId === 1">
          Платное
        </td>
        <td v-else />
      </tr>
      <tr
        v-else
        class="head_row button-text-roboto-medium"
      >
        <td>
          {{ rationItem.name }}
          <h4 class="small-text-roboto-medium">
            Obed
          </h4>
        </td>
        <td v-if="rationItem.accountTypeId === 4">
          Льготное
        </td>
        <td v-else-if="rationItem.accountTypeId === 1">
          Платное
        </td>
        <td v-else />
      </tr>
      <tr
        v-for="(product, index) in rationItem.products"
        :key="index"
      >
        <td class="items_row body-text-roboto-medium">
          {{ product.name }}
          <!--            <img src="~@/assets/images/info.png" height="20" width="20" style="position:relative; top: 40%;  opacity: 0.5">-->
        </td>
        <td class="items_row">
          <div class="items_icon">
            <img
              src="~@/assets/images/info.png"
              height="20"
              width="20"
              style="position:relative; top: 40%; left: 75%; opacity: 0.5"
            >
          </div>
        </td>
      </tr>
      <tr v-if="withCheckbox">
        <td class="items_row" />
        <td
          class="last_row"
          colspan="2"
        >
          <div
            style="display: flex; flex-direction: row; gap: 5px"
          >
            <h4 v-if="checked" class="body-text-roboto-medium">
              Отменить
            </h4>
            <h4
              v-else
              class="body-text-roboto-medium"
              style="max-width: 75px"
            >
              Выбрать рацион
            </h4>
            <label class="toggle-switch">
              <input
                type="checkbox"
                v-model="checked"
                @change="selectRationToSave(acceptedRation)"
              >
              <div class="slider round" />
            </label>
          </div>
<!--          <div-->
<!--            v-else-->
<!--            style="display: flex; flex-direction: row; gap: 5px"-->
<!--          >-->
<!--            <h4-->
<!--              class="body-text-roboto-medium"-->
<!--              style="max-width: 75px"-->
<!--            >-->
<!--              Выбрать рацион-->
<!--            </h4>-->
<!--            <label class="toggle-switch">-->
<!--              <input-->
<!--                type="checkbox"-->
<!--                v-model="checked"-->
<!--                @change="selectRationToSave(acceptedRation)"-->
<!--              >-->
<!--              <div class="slider round" />-->
<!--            </label>-->
<!--          </div>-->
        </td>
      </tr>
    </table>
    <br>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { TableData, TableDataCell } from '@/shared/uikit/BaseSimpleTable.vue'
import { ConvertedApiTypeItem } from '@/views/parents/preorders/PreorderForTommorow.vue'

export default defineComponent({
  name: 'RationCardNew',
  components: { },
  props: {
    withCheckbox: {
      default: false,
      type: Boolean,
      required: false
    },
    checked: {
      default: false,
      type: Boolean,
      required: false
    },
    acceptedRation: {
      type: Object as PropType<ConvertedApiTypeItem>,
      required: true
    }
  },
  data () {
    return {
      rationItem: {} as ConvertedApiTypeItem,
      rationItemProduct: {}
    }
  },
  // beforeMount () {
  //   this.getTableDataFromAcceptedRation(this.acceptedRation)
  // },
  methods: {
    getTableDataFromAcceptedRation (rationItem: ConvertedApiTypeItem): TableData {
      this.rationItem = rationItem
      // console.log('getTableDataFromAcceptedRation rationItem: ', rationItem)
      // console.log(this.withCheckbox)
      const res = {
        rowsCount: 2,
        columnsCount: 1,
        items: [] as TableDataCell[]
      }
      return res
    },
    selectRationToSave (rationItem: ConvertedApiTypeItem) {
      this.$emit('selectedRationToSave', rationItem)
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.rations_cards__checkbox {
  position: absolute;
  top: -5px;
  left: 25px;
}
.ration_table {
  //border: 1px solid $color-dark-50;
  margin-left: 6px;
  width: 96%;
  align-self: center;
  border-spacing: 0px;
  border-collapse: collapse;
}
.toggle_box {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.head_row {
  background: $color-green-100;
  border-top-left-radius: 8px;
  td {
    &:first-child {
      border-top-left-radius: 12px;
    }
    &:last-child{
      border-top-right-radius: 12px;
    }
    padding: 16px 12px;
  }
}
.head_row_dark {
  background: $color-dark-50;
  border-top-left-radius: 8px;
  td {
    &:first-child {
      border-top-left-radius: 12px;
    }
    &:last-child{
      border-top-right-radius: 12px;
    }
    padding: 16px 12px;
  }
}
.items_row {
  background: #ffffff;
  border-spacing: 0px;
  //border: 1px solid $color-dark-50;
  padding: 10px 16px 7px 16px;
  td {
  }
}
.last_row {
  background: #ffffff;
  border-spacing: 0px;
  //border: 1px solid $color-dark-50;
  padding: 7px 2px 7px 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.items_icon {
  position: relative;
  color: $color-dark-200;
}

</style>
