import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    modelValue: _ctx.selectedItem,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItem) = $event)),
    items: _ctx.itemsFiltered,
    "item-title": "name",
    "item-value": "id",
    label: "Школа",
    click: _ctx.update(),
    loading: _ctx.isLoading,
    disabled: _ctx.isDisabled,
    "return-object": ""
  }, {
    "prepend-item": _withCtx(() => [
      _createVNode(_component_v_list_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.searchTerm,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItem()))
            ],
            placeholder: 'Поиск'
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "mt-2" })
    ]),
    _: 1
  }, 8, ["modelValue", "items", "click", "loading", "disabled"]))
}