<template>
  <div class="place-rate-plans">
    <h1 class="place-rate-plans__title">
      Льготные рационы
    </h1>
    <div
      class="wrapper"
      :style="{display: 'flex', justifyContent: 'center', width: '100%'}"
    >
      <template v-if="!isLoading">
        <template v-if="tableData.items.length > 0">
          <base-simple-table :table-data="tableData" />
        </template>
      </template>
      <template v-else>
        <v-row>
          <v-col
            v-if="isLoading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { findCustomerPlaceRatePlans } from '@/shared/api/placeCustomerProductList/api-requests'
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'

export default defineComponent({
  name: 'PlaceRatePlans',
  components: { BaseSimpleTable },
  data () {
    return {
      isLoading: false,
      tableData: {} as TableData
    }
  },
  async beforeMount () {
    this.isLoading = true
    const uniqueIds: any[] = []
    const res = await findCustomerPlaceRatePlans({
      placeId: 1023
    })
    const rawData: any = [
      {
        ageGroupName: 'Младшие классы (1-4 классы)',
        ageGroupId: 1051,
        rowsCount: 0,
        foodTypes: [
          {
            foodTypeName: 'Комплексное',
            foodTypeId: 0,
            items: []
          },
          {
            foodTypeName: 'Двухразовое',
            foodTypeId: 0,
            items: []
          }
        ]
      },
      {
        ageGroupName: 'Старшие классы (5-11 классы)',
        ageGroupId: 1052,
        rowsCount: 0,
        foodTypes: [
          {
            foodTypeName: 'Комплексное',
            foodTypeId: 0,
            items: []
          },
          {
            foodTypeName: 'Двухразовое',
            foodTypeId: 0,
            items: []
          }
        ]
      },
      {
        ageGroupName: 'Кадеты младшие (5-8 классы)',
        ageGroupId: 1053,
        rowsCount: 0,
        foodTypes: [
          {
            foodTypeName: 'Комплексное',
            foodTypeId: 0,
            items: []
          },
          {
            foodTypeName: 'Двухразовое',
            foodTypeId: 0,
            items: []
          }
        ]
      },
      {
        ageGroupName: 'Кадеты старшие (9-11 классы)',
        ageGroupId: 1054,
        rowsCount: 0,
        foodTypes: [
          {
            foodTypeName: 'Комплексное',
            foodTypeId: 0,
            items: []
          },
          {
            foodTypeName: 'Двухразовое',
            foodTypeId: 0,
            items: []
          }
        ]
      }
    ]
    let totalRows = 0
    res.forEach((placeCustomerProduct: any) => {
      if (!uniqueIds.includes(placeCustomerProduct.customerProduct.id)) {
        uniqueIds.push(placeCustomerProduct.customerProduct.id)
        if (placeCustomerProduct.groupId === 1051) {
          if (placeCustomerProduct.foodType === 1) {
            rawData[0].rowsCount += 1
            totalRows += 1
            rawData[0].foodTypes[0].items.push({
              id: placeCustomerProduct.customerProduct.id,
              name: placeCustomerProduct.customerProduct.name
            })
          } else if (placeCustomerProduct.foodType === 2) {
            rawData[0].rowsCount += 1
            totalRows += 1
            rawData[0].foodTypes[1].items.push({
              id: placeCustomerProduct.customerProduct.id,
              name: placeCustomerProduct.customerProduct.name
            })
          }
        } else if (placeCustomerProduct.groupId === 1052) {
          if (placeCustomerProduct.minorClassTypeId !== null) {
            if (placeCustomerProduct.foodType === 1) {
              rawData[2].rowsCount += 1
              totalRows += 1
              rawData[2].foodTypes[0].items.push({
                id: placeCustomerProduct.customerProduct.id,
                name: placeCustomerProduct.customerProduct.name
              })
            } else if (placeCustomerProduct.foodType === 2) {
              rawData[2].rowsCount += 1
              totalRows += 1
              rawData[2].foodTypes[1].items.push({
                id: placeCustomerProduct.customerProduct.id,
                name: placeCustomerProduct.customerProduct.name
              })
            }
          } else if (placeCustomerProduct.majorClassTypeId !== null) {
            if (placeCustomerProduct.foodType === 1) {
              rawData[3].rowsCount += 1
              totalRows += 1
              rawData[3].foodTypes[0].items.push({
                id: placeCustomerProduct.customerProduct.id,
                name: placeCustomerProduct.customerProduct.name
              })
            } else if (placeCustomerProduct.foodType === 2) {
              rawData[3].rowsCount += 1
              totalRows += 1
              rawData[3].foodTypes[1].items.push({
                id: placeCustomerProduct.customerProduct.id,
                name: placeCustomerProduct.customerProduct.name
              })
            }
          } else {
            if (placeCustomerProduct.foodType === 1) {
              rawData[1].rowsCount += 1
              totalRows += 1
              rawData[1].foodTypes[0].items.push({
                id: placeCustomerProduct.customerProduct.id,
                name: placeCustomerProduct.customerProduct.name
              })
            } else if (placeCustomerProduct.foodType === 2) {
              rawData[1].rowsCount += 1
              totalRows += 1
              rawData[1].foodTypes[1].items.push({
                id: placeCustomerProduct.customerProduct.id,
                name: placeCustomerProduct.customerProduct.name
              })
            }
          }
        }
      }
    })
    const tableData: TableData = {
      rowsCount: totalRows,
      columnsCount: 4,
      items: []
    }
    tableData.items.push(
      {
        text: 'Льготные рационы',
        gridRowStart: 1,
        gridRowEnd: 2,
        gridColumnStart: 1,
        gridColumnEnd: 5,
        background: '#1b5e20',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '16px',
        color: 'white'
      },
      {
        text: 'Возрастня группа',
        gridRowStart: 2,
        gridRowEnd: 3,
        gridColumnStart: 1,
        gridColumnEnd: 2,
        background: '#faebd7',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '16px'
      },
      {
        text: 'Тип питания',
        gridRowStart: 2,
        gridRowEnd: 3,
        gridColumnStart: 2,
        gridColumnEnd: 3,
        background: '#faebd7',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '16px'
      },
      {
        text: 'Название рациона',
        gridRowStart: 2,
        gridRowEnd: 3,
        gridColumnStart: 3,
        gridColumnEnd: 4,
        background: '#faebd7',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '16px'
      },
      {
        text: 'ID рациона',
        gridRowStart: 2,
        gridRowEnd: 3,
        gridColumnStart: 4,
        gridColumnEnd: 5,
        background: '#faebd7',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '16px'
      }
    )
    let currentRow = 3
    rawData.forEach((ageGroup: any) => {
      tableData.items.push({
        text: ageGroup.ageGroupName,
        gridRowStart: currentRow,
        gridRowEnd: currentRow + ageGroup.rowsCount,
        gridColumnStart: 1,
        gridColumnEnd: 2,
        background: 'null',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '14px'
      })
      ageGroup.foodTypes.forEach((foodType: any) => {
        tableData.items.push({
          text: foodType.foodTypeName,
          gridRowStart: currentRow,
          gridRowEnd: currentRow + foodType.items.length,
          gridColumnStart: 2,
          gridColumnEnd: 3,
          background: 'null',
          fontWeight: 'normal',
          padding: '5px',
          fontSize: '14px'
        })
        foodType.items.forEach((item: any) => {
          tableData.items.push(
            {
              text: item.name,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 3,
              gridColumnEnd: 4,
              background: 'null',
              fontWeight: 'normal',
              padding: '5px',
              fontSize: '14px'
            },
            {
              text: item.id,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 4,
              gridColumnEnd: 5,
              background: 'null',
              fontWeight: 'normal',
              padding: '5px',
              fontSize: '14px'
            }
          )
          currentRow += 1
        })
      })
    })
    this.tableData = tableData
    this.isLoading = false
  },
  methods: {}
})
</script>

<style scoped lang="scss">
.place-rate-plans {
  padding: 20px;
  margin-top: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;

  .place-rate-plans__title {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }

  .place-rate-plans__table {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(4, max-content);
    //grid-template-rows: repeat(5, max-content);
    grid-gap: 1px;

    .place-rate-plans__table-header {
      background: #1b5e20;
      color: white;
      font-size: 16px;
      grid-column-start: 1 !important;
      grid-column-end: 5 !important;
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
    }

    .place-rate-plans__header-cell {
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
      background: #faebd7;
      font-weight: bold;
      font-size: 16px;
    }

    .place-rate-plans__cell {
      box-shadow: 0 0 0 1px black;
      padding: 5px;
      font-weight: normal;
    }
  }
}

</style>
