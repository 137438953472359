<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Рационы</strong>
        </h1>
        <a style="cursor: pointer" @click="back">Назад</a>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="placeElectronTalon.placeElectronTalonProducts"
          item-key="id"
          class="elevation-1"
          :items-per-page="-1"
          :hide-default-footer="true"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
          :return-object="true"
        >
          <template #top>
            <span>
              <p
                v-if="placeElectronTalon.talonNumber != 0"
                align="center"
              >
                Талон № {{ placeElectronTalon.talonNumber }} от {{ placeElectronTalon.date }} - класс {{ className }}
              </p>
            </span>
          </template>
          <template #[`item.product`]="props">
            {{ props.item.product.name }}
          </template>
          <template #[`item.price`]="props">
            <v-menu
              v-model="menu[props.item.id]"
              :close-on-content-click="false"
              :offset="0"
              transition="scale-transition"
              max-width="390px"
              @update:model-value="`${tempPrice = props.item.price}`"
            >
              <template #activator="{ props: menuActivatorProps }">
                <v-btn
                  color="primary"
                  v-bind="menuActivatorProps"
                  style="cursor: pointer"
                >
                  {{ props.item.price }}
                </v-btn>
              </template>
              <v-card>
                <v-text-field
                  v-model="props.item.price"
                  :rules="rules"
                  label="Edit Price"
                  single-line
                  counter
                  @update:model-value="up(props.item.id)"
                />
                <v-btn @click="menu[props.item.id] = false, props.item.price = tempPrice">
                  Отмена
                </v-btn>
                <v-btn @click="save(props.item.id)"
                :disabled="isNaN(props.item.price) || !(props.item.price) || props.item.price < 0">
                  Ок
                </v-btn>
              </v-card>
            </v-menu>
          </template>
          <template #[`item.action`]="props">
            <span>
              <v-btn
                :disabled="disabled[props.item.id]"
                :color="color[props.item.id]"
                @click="savePrice(props.item.id)"
              >
                Сохранить
              </v-btn>
            </span>
            <span>
              <v-btn
                :loading="delButLoading[props.item.id]"
                @click="deleteProduct(props.item.id)"
              >
                Удалить
              </v-btn>
            </span>
          </template>
          <template #no-data>
            <v-alert
              v-if="loading == false && placeElectronTalon.length == 0"
              :value="true"
              icon="warning"
            >
              Нет данных
            </v-alert>
            <v-alert
              v-else
              :value="false"
              icon="warning"
            >
              Нет данных
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      v-show="addProductBlockVisible"
      align="center"
    >
      <v-col
        class="d-flex"
        cols="12"
        sm="2"
      >
        <v-subheader>Добавить :</v-subheader>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="4"
      >
        <v-select
          v-model="selectedPlaceCustomerProduct"
          :items="placeCustomerProducts"
          item-title="product.name"
          item-value="product.id"
          label="Продукт"
          @update:model-value="savePriceProductAdd()"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="1"
      >
        <v-subheader>Цена :</v-subheader>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="1"
      >
        <v-text-field
          v-model="addProductPrice"
          :rules="rules"
          @change="savePriceProductAdd()"
          style="width: 100px"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <span>
          <v-btn
            :disabled="addProductButtonDisabled"
            :color="addProductColor"
            :loading="addProductLoading"
            @click="addProduct()"
          >
            Добавить
          </v-btn>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { PlaceElectronTalonGetByIdPost } from '@/shared/api/placeElectronTalon/api-types'
import { PlaceElectronTalon } from '@/store/placeElectronTalonList/types'
import { PlaceCustomerProductSearchPost } from '@/shared/api/placeCustomerProductList/api-types'
import {
  PlaceCustomerProductRemovePost,
  PlaceCustomerProductSavePost
} from '@/shared/api/placeElectronTalonList/api-types'
import {
  remove as placeCustomerProductDelete,
  save as placeCustomerProductSave
} from '@/shared/api/placeElectronTalonList/api-requests'
import { parseAxiosError } from '@/shared/lib/api'
import Swal from 'sweetalert2'

type BaseData = {
  loading: boolean;
  className: string;
  classAgeGroup: string;
  placeElectronTalonGetByIdPost: PlaceElectronTalonGetByIdPost;
  placeCustomerProductSearchPost: PlaceCustomerProductSearchPost;
  placeCustomerProductSavePost: PlaceCustomerProductSavePost;
  placeElectronTalon: PlaceElectronTalon;
  placeCustomerProducts: any;
  rules: any;
  tempPrice: any;
  menu: boolean[];
  selectedPlaceCustomerProduct: any;
  selected: any;
  headers: any;
  snack: boolean;
  snackColor: string;
  snackText: string;
  disabled: any;
  delButLoading: any;
  color: any;
  addProductPrice: number;
  addProductButtonDisabled: boolean;
  addProductColor: string;
  addProductLoading: boolean;
  deleteProducts: PlaceCustomerProductRemovePost;
  productIdArray: any;
  addProductBlockVisible: boolean;
}
export default defineComponent({
  components: {},
  props: {
    id: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    placeId: {
      type: String,
      requred: true
    }
  },
  data (): BaseData {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      loading: true,
      className: '',
      classAgeGroup: '',
      addProductBlockVisible: false,
      deleteProducts: {
        id: 0,
        talonId: 0,
        productId: 0
      },
      tempPrice: '',
      menu: [],
      selectedPlaceCustomerProduct: '',
      selected: [],
      disabled: {},
      delButLoading: {},
      productIdArray: [],
      color: {},
      addProductButtonDisabled: true,
      addProductPrice: 0.00,
      addProductColor: 'normal',
      addProductLoading: false,
      headers: [
        { title: 'Наименование', align: 'start', sortable: false, value: 'product' },
        { title: 'Цена', align: 'start', sortable: false, value: 'price' },
        { title: 'Действие', value: 'action', sortable: false, filterable: false }
      ],
      rules: [
        (value: any) => !!value || 'Пустое поле',
        (value: any) => (`${value}` || '').length <= 15 || 'Максимум 15 символов',
        (value: any) => !isNaN(+`${value}`.replace(/,/g, '.')) || 'Должно быть число'
      ],
      placeElectronTalonGetByIdPost: {
        id: 0,
        salesInclude: false,
        historyInclude: false,
        productsInclude: true
      },
      placeCustomerProductSearchPost: {
        placeId: 0,
        date: new Date().toISOString().substr(0, 10)
      },
      placeCustomerProductSavePost: {
        talonId: 0,
        productId: 0,
        price: 0,
        useAgeGroup: false
      },
      placeCustomerProducts: [],
      placeElectronTalon: {
        id: 0,
        placeId: 0,
        parentPlaceId: 0,
        talonYear: 0,
        talonNumber: 0,
        creationDate: null,
        ownerUserId: 0,
        updateUserId: 0,
        updateTimestamp: null,
        date: null,
        status: 0,
        guid: null,
        controlUserId: 0,
        controlTimestamp: 0,
        ownerTimestamp: null,
        placeElectronTalonSales: [],
        placeElectronTalonHistoryItems: [],
        placeElectronTalonProducts: [],
        place: {
          id: 0,
          parentId: 0,
          name: '',
          typeId: 0,
          latitude: null,
          longitude: null,
          creationDate: null,
          name2: '',
          updateTimestamp: null,
          removed: null,
          shortName: '',
          ownerUserId: 0,
          placeTempAttributes: []
        }
      }
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('placeElectronTalon', { placeElectronTalonFind: 'getById' }),
    ...mapActions('placeCustomerProducts', { placeCustomerProductFind: 'find' }),
    async loadPlaceCustomerProducts (placeCustomerProductSearchPost: PlaceCustomerProductSearchPost) {
      this.loading = true
      try {
        const placeCustomerProductsGet = await this.placeCustomerProductFind(placeCustomerProductSearchPost)
        const temp: any = []
        placeCustomerProductsGet.map((element: any) => {
          if (!this.productIdArray.find((el: any) => el === element.productId)) {
            temp.push(element)
          }
        })
        return temp
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.loading = false
      }
    },
    async deleteProduct (id: number) {
      this.placeElectronTalon.placeElectronTalonProducts.forEach(async element => {
        if (element.id === id) {
          this.deleteProducts.id = id
          this.deleteProducts.productId = element.productId
          this.deleteProducts.talonId = element.placeElectronTalonId
          this.buttonChangeState2(id, true)
          try {
            const result = await placeCustomerProductDelete(this.deleteProducts)
            await this.getTalonRationForEdit(this.placeElectronTalonGetByIdPost)
            this.buttonChangeState2(id, false)
          } catch (error: any) {
            this.setSnackbar({
              showing: true,
              text: error.response.data.errorMessage,
              timeout: 3000
            })
            this.buttonChangeState2(id, false)
            this.buttonChangeColor(id, 'error')

            return Promise.reject(parseAxiosError(error))
          } finally {
            this.loading = false
          }
        }
      })
    },
    up (id: any) {
      this.placeElectronTalon.placeElectronTalonProducts.forEach(element => {
        if (element.id === id) {
          if (!isNaN(Number(String(element.price).replace(/,/g, '.')))) {
            element.price = Number(String(element.price).replace(/,/g, '.'))
          }
        }
      })
    },
    addProduct (id?: number) {
      Swal.fire({
        title: '',
        html: '<h2>Добавить рацион во все талоны <br>' + this.classAgeGroup + ' классов?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет, только в этот талон'
      }).then((result) => {
        if (result.isConfirmed) {
          this.addProductPriceToTalons(true)
        } else if (result.isDenied) {
          this.addProductPriceToTalons(false)
        }
      })
    },
    savePrice (id: number) {
      Swal.fire({
        title: '',
        html: '<h2>Изменить цену во всех талонах <br>' + this.classAgeGroup + ' классов?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет, только в этом талоне'
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeProductPriceInTalons(id, true)
        } else if (result.isDenied) {
          this.changeProductPriceInTalons(id, false)
        }
      })
    },
    async addProductPriceToTalons (useAgeGroup: boolean) {
      if (isNaN(this.addProductPrice) && this.selectedPlaceCustomerProduct) {
        this.addProductButtonDisabled = true
      } else {
        this.addProductLoading = true
        this.placeCustomerProductSavePost.productId = this.selectedPlaceCustomerProduct
        this.placeCustomerProductSavePost.price = this.addProductPrice
        this.placeCustomerProductSavePost.useAgeGroup = useAgeGroup
        try {
          const result = await placeCustomerProductSave(this.placeCustomerProductSavePost)
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка добавления продукта в рацион.',
            timeout: 3000
          })
          this.addProductColor = 'error'
          return Promise.reject(parseAxiosError(error))
        } finally {
          this.addProductLoading = false
          this.addProductBlockVisible = false
          this.addProductPrice = 0
        }
        this.addProductButtonDisabled = true
        await this.getTalonRationForEdit(this.placeElectronTalonGetByIdPost)
      }
    },
    async changeProductPriceInTalons (id: number, useAgeGroup: boolean) {
      this.loading = true
      this.placeElectronTalon.placeElectronTalonProducts.forEach(async element => {
        if (element.id === id) {
          this.placeCustomerProductSavePost.talonId = element.placeElectronTalonId
          this.placeCustomerProductSavePost.productId = element.productId
          this.placeCustomerProductSavePost.price = element.price
          this.placeCustomerProductSavePost.useAgeGroup = useAgeGroup
          try {
            const result = await placeCustomerProductSave(this.placeCustomerProductSavePost)
          } catch (error: any) {
            this.setSnackbar({
              showing: true,
              text: 'Ошибка обновления цены.',
              timeout: 3000
            })
            this.buttonChangeColor(id, 'error')
            return Promise.reject(parseAxiosError(error))
          } finally {
            this.loading = false
          }
          this.buttonChangeState(id, true)
        }
      })
    },
    async getTalonRationForEdit (placeElectronTalonGetByIdPost: PlaceElectronTalonGetByIdPost) {
      this.productIdArray = []
      this.loading = true
      try {
        const n: PlaceElectronTalon = await this.placeElectronTalonFind(placeElectronTalonGetByIdPost)
        if (n) {
          this.placeElectronTalon = JSON.parse(JSON.stringify(n))
        }
      } catch (error: any) {
        console.log(error)
      }
      this.placeElectronTalon.placeElectronTalonProducts = this.placeElectronTalon.placeElectronTalonProducts.sort(this.sortById)
      this.placeElectronTalon.placeElectronTalonProducts.forEach(element => {
        element.price = ((element.price * 100) / 100)
        this.disabled[element.id] = true
        this.delButLoading[element.id] = false
        this.color[element.id] = 'green'
        this.productIdArray.push(element.productId)
      })
      if (this.placeElectronTalon.place.placeTempAttributes.length > 0) {
        this.className = this.placeElectronTalon.place.placeTempAttributes.sort(this.sortByDate)[0].name
      } else {
        this.className = this.placeElectronTalon.place.name
      }
      if (this.className) {
        if (this.className.match(/^[1-4][^0-9].*|^[1-4]$/)) {
          this.classAgeGroup = 'начальных'
        } else {
          this.classAgeGroup = 'старших'
        }
      }
      this.placeCustomerProductSearchPost.placeId = this.placeElectronTalon.parentPlaceId
      this.placeCustomerProductSearchPost.date = this.placeElectronTalon.creationDate
      this.placeCustomerProducts = await this.loadPlaceCustomerProducts(this.placeCustomerProductSearchPost)
      if (this.placeCustomerProducts.length > 0) {
        this.addProductBlockVisible = true
      }
      this.loading = false
    },
    sortByDate (a: any, b: any) {
      if (a.timestampFrom < b.timestampFrom) return 1
      else return -1
    },
    sortById (a: any, b: any) {
      if (a.productId < b.productId) return -1
      else return 1
    },
    savePriceProductAdd () {
      if (!isNaN(this.addProductPrice) && this.selectedPlaceCustomerProduct) {
        this.addProductButtonDisabled = false
      }
    },
    buttonChangeState (id: number, state: boolean) {
      const arr: any = []
      Object.assign(arr, this.disabled)
      arr[id] = state
      this.disabled = arr
    },
    buttonChangeColor (id: number, color: string) {
      const arr: any = []
      Object.assign(arr, this.color)
      arr[id] = color
      this.color = arr
    },
    buttonChangeState2 (id: number, state: boolean) {
      const arr: any = []
      Object.assign(arr, this.delButLoading)
      arr[id] = state
      this.delButLoading = arr
    },
    save (id: number) {
      let check = false
      this.placeElectronTalon.placeElectronTalonProducts.forEach(element => {
        if (element.id === id) {
          if (isNaN(Number(String(element.price).replace(/,/g, '.')))) {
            check = true
          } else {
            element.price = Number(String(element.price).replace(/,/g, '.'))
          }
        }
      })
      this.menu[id] = false
      this.buttonChangeState(id, check)
    },
    back () {
      const date = this.date
      this.$router.push({ name: 'TalonRegister', query: { back: 'true', date: date, placeId: this.placeId } })
    }
  },
  async mounted () {
    if (!this.id) {
      this.$router.push({ name: 'dashboard' })
    }
    this.placeCustomerProductSavePost.talonId = Number(this.id)
    this.placeElectronTalonGetByIdPost.id = Number(this.id)
    console.log('disabledField', this.disabled)
    await this.getTalonRationForEdit(this.placeElectronTalonGetByIdPost)
  }
})
</script>

<style lang='scss'></style>
