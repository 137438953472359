import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PreorderProductsFindPost, PreorderProductsCreatePost, PreorderProductsRemovePost } from './api-types'

const preorderProductFind = 'preorderproducts/find'
const preorderProductCreate = 'preorderproducts/create'
const preorderProductUpdate = 'preorderproducts/update'

export const find = async (data: PreorderProductsFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderProductFind, data)
  return result.data.data
}

export const create = async (data: PreorderProductsCreatePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderProductCreate, data)
  return result.data.data
}

export const remove = async (data: PreorderProductsRemovePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderProductUpdate, data)
  return result.data.data
}
