<!-- todo подумать про логику свитча и сохранить. Валидацию сделать-->
<template>
  <div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="content_container">
          <h1 class="title-h1-roboto-medium">
            Ограничение суммы на "Буфет"
          </h1>
          <div>
            <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
              <h4
                class="body-text-roboto-medium"
                style="width: 273px"
              >
                Использовать лимит
              </h4>
              <div style="align-self: center">
                <label class="toggle-switch">
                  <input
                    v-model="limitSetting"
                    type="checkbox"
                    @change="resetLimitBuffet"
                  >
                  <div class="slider round" />
                </label>
              </div>
            </v-row>
            <v-row style="justify-content: space-between; padding: 12px;">
              <div style="display: flex; flex-direction: row; text-align: right; gap: 12px">
                <h4
                  class="body-text-roboto-medium"
                  style="width: 95px"
                >
                  Максимум в день
                </h4>
                <v-text-field
                  v-model="currentLimit"
                  variant="solo"
                  hide-details="true"
                  style="max-width: 70px; max-height: 46px; border-radius: 7px;"
                />
              </div>
              <button
                class="primary-green-button"
                @click="setLimitBuffet"
              >
                Сохранить
              </button>
            </v-row>
          </div>
        </div>
        <div
          class="attention_notification"
          style="margin: 0px 16px;"
        >
          <div class="attention-notification-text-box">
            <h4 class="body-text-roboto-medium">
              Для отключения лимита уберите соответствующую галочку
            </h4>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { getLimitBuffet, setLimitBuffet } from '@/shared/api/parents/moneyAccounting/api-requests'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsSettingLimits',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      currentLimit: 0,
      limitSetting: false as boolean,
      isTelegram: false,
      isLoading: false
    }
  },
  async beforeMount () {
    this.isLoading = true
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.getLimitBuffet()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getLimitBuffet () {
      try {
        const getLimitBuffetReqObj = {
          userId: this.currentUser.id
        }
        const getLimitBuffetRes = await getLimitBuffet(getLimitBuffetReqObj, this.isTelegram)
        this.currentLimit = getLimitBuffetRes.data[0].limitDaily
        if (getLimitBuffetRes.data[0].limitDaily === null) {
          this.limitSetting = false
        } else {
          this.limitSetting = true
        }
        console.log(getLimitBuffetRes.data[0].limitDaily)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить лимит счета!',
          timeout: 3000
        })
      }
    },
    async setLimitBuffet () {
      try {
        const setLimitBuffetReqObj = {
          userId: this.currentUser.id,
          sum: this.currentLimit,
          resetLimit: null
        }
        await setLimitBuffet(setLimitBuffetReqObj, this.isTelegram)
        await this.getLimitBuffet()
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось установить лимит счета!',
          timeout: 3000
        })
      }
    },
    async resetLimitBuffet () {
      try {
        let resetLimitBuffetReqObj
        if (!this.limitSetting) {
          resetLimitBuffetReqObj = {
            userId: this.currentUser.id,
            sum: this.currentLimit,
            resetLimit: 1
          }
        } else {
          resetLimitBuffetReqObj = {
            userId: this.currentUser.id,
            sum: this.currentLimit,
            resetLimit: null
          }
        }
        await setLimitBuffet(resetLimitBuffetReqObj, this.isTelegram)
        await this.getLimitBuffet()
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось установить лимит счета!',
          timeout: 3000
        })
      }
    },
    goToMoneyAccounting () {
      this.$router.push('./moneyAccounting')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.page_container {
  justify-content: center;
  display: grid;
  padding: 20px 0px;
  gap: 20px
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  margin: 0px 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 480px;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: calc(100% - 32px);
  }
}
</style>
