<template>
  <v-select
    v-model="selectedYear"
    :items="years"
    item-title="name"
    item-value="id"
    label="Год"
    @update:model-value="update"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

type BaseData = {
  selectedYear: number;
  years: [{ id: number; name: string }];
}

export default defineComponent({
  model: {
    event: 'on-change'
  },
  props: ['addDeepYearsProp'],
  data (): BaseData {
    return {
      selectedYear: 0,
      years: [{ id: 2021, name: '2021' }]
    }
  },
  mounted () {
    this.selectedYear = new Date().getFullYear()
    let startIndex = -1
    if (!isNaN(this.addDeepYearsProp)) startIndex = startIndex - this.addDeepYearsProp
    for (let index = startIndex; index < 2; index++) {
      if (index < startIndex + 1) this.years = [{ id: this.selectedYear + index, name: (this.selectedYear + index).toString() }]
      else this.years.push({ id: this.selectedYear + index, name: (this.selectedYear + index).toString() })
    }
    this.update(this.selectedYear)
  },
  methods: {
    update (value: any) {
      this.$emit('on-change', value)
    }
  }
})
</script>

<style lang="scss"></style>
