import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import {
  UserLoginPost,
  UserSignupPost,
  UserForgotPost,
  UserResetPost,
  UserDetailedFindPost,
  UserUpdateFullNamePost,
  UserUpdateDataPost,
  UserUpdateTypePost,
  UserSetUpdateTimestampPost,
  UserSetPhoneAndTariffPlansPost
} from './api-types'
import { UserTokens, Person, DetailedPerson } from '../../../store/user/types'

const routeLogin = '/login'
const routeSignup = '/signup'
const routeForgot = '/forgot'
const routerReset = '/reset'
const routePerson = '/user'
const routeDetailedPerson = '/user/getdetailedbyid'
const routeUpdatePersonFullName = '/user/updatefullname'
const routeUpdatePersonData = '/user/updatedata'
const routeUpdatePersonType = '/user/updatetype'
const routeSetPersonUpdateTimestamp = '/user/setupdatetimestamp'
const routeSetPhoneAndTariffPlans = '/user/addphoneandtariffplans'
const routeFindRKS = '/rks/find'

export const login = async (data: UserLoginPost): Promise<UserTokens> => {
  const result: AxiosResponse<{
      access_token: UserTokens['access_token'];
      refresh_token: UserTokens['refresh_token'];
  }> = await axios.post(routeLogin, data)
  return result.data
}

export const getPerson = async (): Promise<Person> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      id: Person['id'];
      lastName: Person['lastName'];
      firstName: Person['firstName'];
      middleName: Person['middleName'];
      userType: Person['userType'];
  }> = await axios.get(routePerson)
  return result.data
}

export const getDetailedPerson = async (data: UserDetailedFindPost): Promise<DetailedPerson> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: DetailedPerson;
  }> = await axios.post(routeDetailedPerson, data)
  return result.data.data
}

export const updatePersonFullName = async (data: UserUpdateFullNamePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeUpdatePersonFullName, data)
  return result.data.data
}

export const updatePersonData = async (data: UserUpdateDataPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeUpdatePersonData, data)
  return result.data.data
}

export const updatePersonType = async (data: UserUpdateTypePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeUpdatePersonType, data)
  return result.data.data
}

export const setPersonUpdateTimestamp = async (data: UserSetUpdateTimestampPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeSetPersonUpdateTimestamp, data)
  return result.data.data
}

export const setPhoneAndTariffPlans = async (data: UserSetPhoneAndTariffPlansPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeSetPhoneAndTariffPlans, data)
  return result.data.data
}

export const signup = async (data: UserSignupPost): Promise<number> => {
  const result: AxiosResponse<{
    id: number ;
  }> = await axios.post(routeSignup, data)
  return result.data.id
}

export const forgot = async (data: UserForgotPost): Promise<string> => {
  const result: AxiosResponse<{
    passwordResetToken: string;
  }> = await axios.post(routeForgot, data)
  return result.data.passwordResetToken
}

export const reset = async (data: UserResetPost): Promise<void> => {
  await axios.post(routerReset, data)
  return Promise.resolve()
}

export const userFindRKS = async (data: {regId: string}): Promise<string> => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeFindRKS, data)
  return result.data.data
}
