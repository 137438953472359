import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import {
  AccountGetLimitBuffetRequest,
  AccountGetLimitBuffetResponse,
  AccountSetLimitBuffetRequest,
  AccountSetLimitBuffetResponse,
  AccountSettingsFindRequest,
  AccountSettingsFindResponse,
  AccountSettingsSaveRequest,
  AccountSettingsSaveResponse,
  AccountsFindRequest,
  AccountsFindResponse,
  PersonTransactionGetInfoRequest,
  PersonTransactionGetInfoResponse,
  SalesGetBySaleBatchIdRequest,
  SalesGetBySaleBatchIdResponse,
  TransactionsGetByUserRequest,
  TransactionsGetByUserResponse,
  TransferBetweenAccountsRequest,
  TransferBetweenAccountsResponse,
  TransferBetweenChildrenRequest,
  TransferBetweenChildrenResponse,
  UserLicencesSaveResponse,
  UserLicensesGetByUserIdRequest,
  UserLicensesGetByUserIdResponse,
  UserLicensesSaveRequest
} from '@/shared/api/parents/moneyAccounting/api-types'

enum MoneyAccountingRoutes {
  MoneyAccountingFind = '/person/accounts/find',
  MoneyAccountingGetLimitBuffet = '/person/account/getLimitBuffet',
  MoneyAccountingSetLimitBuffet = '/person/account/setLimitBuffet',
  MoneyAccountingSettingsFind = '/person/accountSettings/find',
  MoneyAccountingSettingsSave = '/person/accountSettings/save',
  MoneyAccountingUserLicensesGetByUserId = '/person/userLicenses/getByUserId',
  MoneyAccountingUserLicensesSave = '/person/userLicenses/save',
  MoneyAccountingTransactionsGetByUser = '/person/transactions/getByUser',
  MoneyAccountingTransactionGetInfo = '/person/transaction/getInfo',
  MoneyAccountingSalesGetBySleBatchId = '/person/sales/getBySaleBatch',
  MoneyAccountingTransferBetweenAccounts = '/person/accountTransfer/forSingleUser',
  MoneyAccountingTransferBetweenChildren = 'person/accountTransfer/forDifferentUsers'
}

const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const moneyAccountsFind = async (data: AccountsFindRequest, telegram: boolean): Promise<AccountsFindResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AccountsFindResponse>(MoneyAccountingRoutes.MoneyAccountingFind, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AccountsFindResponse>(MoneyAccountingRoutes.MoneyAccountingFind, data)
    return result.data
  }
}

export const getLimitBuffet = async (data: AccountGetLimitBuffetRequest, telegram: boolean): Promise<AccountGetLimitBuffetResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AccountGetLimitBuffetResponse>(MoneyAccountingRoutes.MoneyAccountingGetLimitBuffet, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AccountGetLimitBuffetResponse>(MoneyAccountingRoutes.MoneyAccountingGetLimitBuffet, data)
    return result.data
  }
}

export const setLimitBuffet = async (data: AccountSetLimitBuffetRequest, telegram: boolean): Promise<AccountSetLimitBuffetResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AccountSetLimitBuffetResponse>(MoneyAccountingRoutes.MoneyAccountingSetLimitBuffet, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AccountSetLimitBuffetResponse>(MoneyAccountingRoutes.MoneyAccountingSetLimitBuffet, data)
    return result.data
  }
}

export const accountSettingsFind = async (data: AccountSettingsFindRequest, telegram: boolean): Promise<AccountSettingsFindResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AccountSettingsFindResponse>(MoneyAccountingRoutes.MoneyAccountingSettingsFind, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AccountSettingsFindResponse>(MoneyAccountingRoutes.MoneyAccountingSettingsFind, data)
    return result.data
  }
}

export const accountSettingsSave = async (data: AccountSettingsSaveRequest, telegram: boolean): Promise<AccountSettingsSaveResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AccountSettingsSaveResponse>(MoneyAccountingRoutes.MoneyAccountingSettingsSave, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AccountSettingsSaveResponse>(MoneyAccountingRoutes.MoneyAccountingSettingsSave, data)
    return result.data
  }
}

export const userLicensesGetByUserId = async (data: UserLicensesGetByUserIdRequest, telegram: boolean): Promise<UserLicensesGetByUserIdResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<UserLicensesGetByUserIdResponse>(MoneyAccountingRoutes.MoneyAccountingUserLicensesGetByUserId, data, { headers })
    return result.data
  } else {
    const result = await axios.post<UserLicensesGetByUserIdResponse>(MoneyAccountingRoutes.MoneyAccountingUserLicensesGetByUserId, data)
    return result.data
  }
}

export const userLicensesSave = async (data: UserLicensesSaveRequest, telegram: boolean): Promise<UserLicencesSaveResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<UserLicencesSaveResponse>(MoneyAccountingRoutes.MoneyAccountingUserLicensesSave, data, { headers })
    return result.data
  } else {
    const result = await axios.post<UserLicencesSaveResponse>(MoneyAccountingRoutes.MoneyAccountingUserLicensesSave, data)
    return result.data
  }
}

export const transactionGetByUser = async (data: TransactionsGetByUserRequest, telegram: boolean): Promise<TransactionsGetByUserResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<TransactionsGetByUserResponse>(MoneyAccountingRoutes.MoneyAccountingTransactionsGetByUser, data, { headers })
    return result.data
  } else {
    const result = await axios.post<TransactionsGetByUserResponse>(MoneyAccountingRoutes.MoneyAccountingTransactionsGetByUser, data)
    return result.data
  }
}

export const transactionGetInfo = async (data: PersonTransactionGetInfoRequest, telegram: boolean): Promise<PersonTransactionGetInfoResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<PersonTransactionGetInfoResponse>(MoneyAccountingRoutes.MoneyAccountingTransactionGetInfo, data, { headers })
    return result.data
  } else {
    const result = await axios.post<PersonTransactionGetInfoResponse>(MoneyAccountingRoutes.MoneyAccountingTransactionGetInfo, data)
    return result.data
  }
}

export const salesGetBySalesBatchId = async (data: SalesGetBySaleBatchIdRequest, telegram: boolean): Promise<SalesGetBySaleBatchIdResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<SalesGetBySaleBatchIdResponse>(MoneyAccountingRoutes.MoneyAccountingSalesGetBySleBatchId, data, { headers })
    return result.data
  } else {
    const result = await axios.post<SalesGetBySaleBatchIdResponse>(MoneyAccountingRoutes.MoneyAccountingSalesGetBySleBatchId, data)
    return result.data
  }
}

export const transferBetweenAccounts = async (data: TransferBetweenAccountsRequest, telegram: boolean): Promise<TransferBetweenAccountsResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<TransferBetweenAccountsResponse>(MoneyAccountingRoutes.MoneyAccountingTransferBetweenAccounts, data, { headers })
    return result.data
  } else {
    const result = await axios.post<TransferBetweenAccountsResponse>(MoneyAccountingRoutes.MoneyAccountingTransferBetweenAccounts, data)
    return result.data
  }
}

export const transferBetweenChildren = async (data: TransferBetweenChildrenRequest, telegram: boolean): Promise<TransferBetweenChildrenResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<TransferBetweenChildrenResponse>(MoneyAccountingRoutes.MoneyAccountingTransferBetweenChildren, data, { headers })
    return result.data
  } else {
    const result = await axios.post<TransferBetweenChildrenResponse>(MoneyAccountingRoutes.MoneyAccountingTransferBetweenChildren, data)
    return result.data
  }
}
