/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_USERRATEPLANS_TO_STACK } from '@/store/userRatePlan/mutations'
import { RootState } from '@/store/state'
import { UserRatePlansState, User } from '@/store/userRatePlan/types'
import { UserRatePlanFindPost } from '../../shared/api/userRateplan/api-types'
import { find as UserRatePlanFind } from '../../shared/api/userRateplan/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type UserPlaceListContext = ActionContext<UserRatePlansState, RootState>

const initialState: UserRatePlansState = {
  users: [{
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    typeId: 0
  }]
}

const getters: GetterTree<UserRatePlansState, RootState> = {
  userPlaces (state: UserRatePlansState): User[] {
    return state.users
  }
}

const mutations: MutationTree<UserRatePlansState> = {
  [ADD_USERRATEPLANS_TO_STACK] (state: UserRatePlansState, users: User[]) {
    state.users = users
  }
}

const actions: ActionTree<UserRatePlansState, RootState> = {
  async find (
    { commit, dispatch, state }: UserPlaceListContext,
    data: UserRatePlanFindPost
  ): Promise<User[]> {
    try {
      const result = await UserRatePlanFind(data)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const userRatePlans = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
