<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row class="justify-content-center">
      <v-col v-if="loading">
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
      </v-col>
      <v-col v-else>
        <h1>ЧЕК ПО ОПЕРАЦИИ</h1>
        <h3>Информация о платеже</h3>
        <p>Время операции: {{ telegramGlolimeCheckGetResponse.timestamp }}</p>
        <p>Номер операции: {{ telegramGlolimeCheckGetResponse.id }}</p>
        <p>Услуга: {{ telegramGlolimeCheckGetResponse.accountTypeName }}</p>
        <p>СУИП: {{ telegramGlolimeCheckGetResponse.suip }}</p>
        <p>Номер заказа: {{ telegramGlolimeCheckGetResponse.orderId }}</p>
        <p>Сумма: {{ telegramGlolimeCheckGetResponse.sum }} р.</p>
        <p>Комиссия: {{ telegramGlolimeCheckGetResponse.comission }} р.</p>
        <p>Итого: {{ telegramGlolimeCheckGetResponse.itog }} р.</p>
        <h3 style="margin-bottom: 10px;" />
        <h3>Реквизиты платежа</h3>
        <p>Лицевой счет: {{ telegramGlolimeCheckGetResponse.paymentKey }}</p>
        <h3 style="margin-bottom: 10px;" />
        <h3>Получатель платежа</h3>
        <p>Наименование: {{ telegramGlolimeCheckGetResponse.accountName }}</p>
        <h3>Реквизиты получателя</h3>
        <p>ИНН: {{ telegramGlolimeCheckGetResponse.inn }}</p>
        <p>Счет: {{ telegramGlolimeCheckGetResponse.account }}</p>
        <p>Банк получателя: {{ telegramGlolimeCheckGetResponse.bank }}</p>
        <p>БИК: {{ telegramGlolimeCheckGetResponse.bic }}</p>
        <p>Корр.Счет: {{ telegramGlolimeCheckGetResponse.corAccount }}</p>
        <img
          v-if="telegramGlolimeCheckGetResponse.status === 2"
          style="position: relative; top: -20px; left: 150px"
          src="~@/assets/images/payeed.png"
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import moment from 'moment'
import { AxiosError } from 'axios'
import { telegramGlolimeCheckGet } from '@/shared/api/telegram/api-requests'
import { TelegramGlolimeCheckGetPost, TelegramGlolimeCheckGetResponse } from '@/shared/api/telegram/api-types'

type BaseData = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  errorMessageUnknown: string;
  chatId: number;
  WebApp: any;
  telegramGlolimeCheckGetPost: TelegramGlolimeCheckGetPost;
  telegramGlolimeCheckGetResponse: TelegramGlolimeCheckGetResponse;
}

export default defineComponent({
  props: ['idProp', 'typeProp'],
  data (): BaseData {
    return {
      loading: true,
      error: false,
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Произошла ошибка, попробуйте снова позже',
      chatId: 0,
      WebApp: null,
      telegramGlolimeCheckGetPost: {
        id: '0',
        type: 0
      },
      telegramGlolimeCheckGetResponse: {
        id: 0,
        timestamp: '',
        accountTypeName: '',
        suip: null,
        orderId: '',
        sum: 0,
        comission: 0,
        itog: 0,
        paymentKey: '',
        accountName: '',
        inn: '',
        account: '',
        bank: '',
        bic: '',
        corAccount: '',
        status: 0
      }
    }
  },
  beforeCreate () {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-web-app.js'
    script.defer = true
    document.head.appendChild(script)
  },
  mounted () {
    if (this.idProp && this.typeProp) {
      let timerCount = 0
      const timer = setInterval(() => {
        timerCount++
        if (window.Telegram !== undefined) {
          const WebApp = window.Telegram.WebApp
          if (WebApp.initDataUnsafe !== undefined) {
            if (WebApp.initDataUnsafe.user !== undefined) {
              this.chatId = WebApp.initDataUnsafe.user.id
              this.WebApp = WebApp
              this.chatId = 1
            }
          }
          this.chatId = 1
          if (this.chatId !== 0) {
            this.loading = false
            clearInterval(timer)
            this.telegramGlolimeCheckGetPost.id = this.idProp
            this.telegramGlolimeCheckGetPost.type = +this.typeProp
            this.telegramGlolimeCheckGet()
          }
        }
        if (timerCount > 10) {
          this.error = true
          this.loading = false
          clearInterval(timer)
        }
      }, 1000)
    } else {
      this.error = true
      this.errorMessage = 'Нет параметров'
    }
  },
  methods: {
    async telegramGlolimeCheckGet () {
      try {
        this.loading = true
        await telegramGlolimeCheckGet(this.telegramGlolimeCheckGetPost)
          .then((result: TelegramGlolimeCheckGetResponse) => {
            this.telegramGlolimeCheckGetResponse = result
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    dateText (date: any) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    catchError (error: any) {
      const err = error as AxiosError
      this.errorMessage = this.errorMessageUnknown
      if (err.response && err.response.data.errorMessage !== '') {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    }
  }
})
</script>

<style>
p {
  margin: 0 !important;
  z-index: 2;
  position: relative;
}
</style>
