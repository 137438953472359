import axios from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import {
  BankBinding, PaymentKeyDoPaymentForSinglePayment,
  PaymentServicePayAquaringBySubResponse,
  PaymentServicePayAquaringResponse,
  PaymentServicePaySbpResponse,
  PaymentServiceSettings,
  SbpSubscription,
  TelegramAutoPaymentError,
  TelegramAutoPaymentServiceSettings,
  TelegramPaymentKeyForSinglePayment,
  TelegramPaymentServiceSettingResponse,
  TelegramSaveSettingForSinglePayment,
  TelegramSupportDelailsResponse,
  TelegramSupportHistoryResponse,
  TelegramSupportOperator,
  TelegramSupportTicketResponse
} from '../../../store/telegram/types'
import {
  TelegramAutoPaymentServiceSettingsGetPost,
  TelegramPaymentServiceCreateAquaringOrderPost,
  TelegramPaymentServiceCreateSbpOrderBySubPost,
  TelegramPaymentServiceCreateSbpOrderPost,
  TelegramPaymentServiceSettingGetPost,
  TelegramPaymentServiceSettingSavePost,
  TelegramPaymentServiceUnbindCardPost,
  TelegramPaymentServiceUnbindSbpSubscriptionPost,
  TelegramPaymentServiceuCreateAquaringOrderBySubscriptionPost,
  TelegramSupportGetOperatorListPost,
  TelegramSupportGetOperatorStatisticPost,
  TelegramSupportGetDetailsByTicketPost,
  TelegramSupportGetHistoryByTicketPost,
  TelegramSupportGetFileHistoryByTicketPost,
  TelegramGetPaymentKeysForSinglePaymentPost,
  TelegramSaveSettingForSinglePaymentPost,
  TelegramDoPaymentForSinglePaymentPost,
  TelegramGetPaymentKeysForSinglePaymentPostApiResponse, TelegramDoPaymentForSinglePaymentResponse,
  TelegramSupportServiceUnbindTicketPost,
  TelegramGlolimeCheckGetPost,
  TelegramGlolimeCheckGetResponse
} from './api-types'

enum TelegramRoutes {
  telegramTest = '/telegramtest/test',
  telegramPaymentServiceSettingGet = 'telegramservice/paymentServiceGetSettings',
  telegramPaymentServiceSettingSave = 'telegramservice/paymentServiceSaveSettings',
  telegramPaymentServiceUnbindCard = 'telegramservice/unbindCard',
  telegramPaymentServiceCreateSbpOrder = 'telegramservice/createSbpOrder',
  telegramPaymentServiceCreateSbpOrderBySub = 'telegramservice/createSbpOrderBySubscription',
  telegramPaymentServiceCreateAquaringOrder = 'telegramservice/createAquaringOrder',
  telegramPaymentServiceUbindSbpSubscription = 'telegramservice/unbindSbpSubscription',
  telegramPaymentServiceCreateAquaringOrderBySubscription = 'telegramservice/createAquaringOrderBySubscription',
  telegramAutoPaymentServiceGetSettings = 'telegramAutopaymentService/autoPaymentServiceGetSettings',
  telegramAutoPaymentServiceSaveSettings = 'telegramAutopaymentService/autoPaymentServiceSaveSettings',
  telegramAutoPaymentSendError = 'telegramAutopayment/sendError',
  telegramSupportGetOperatorList = 'telegramSupportService/getOperatorList',
  telegramSupportGetOperatorStatistic = 'telegramSupportService/getOperatorStatistic',
  telegramSupportGetDetailsByTicket = 'telegramSupportService/getDetailsByTicket',
  telegramSupportGetHistoryByTicket = 'telegramSupportService/getHistoryByTicket',
  telegramSupportGetFileHistoryByTicket = '/telegramSupportService/getFileHistoryByTicket',
  telegramSupportServiceUnbindTicket = '/telegramSupportService/unbindTicket',
  telegramGlolimeCheckGet = '/glolimecheck/get',
  telegramGetPaymentKeysForSinglePayment = '/telegramsinglepaymentservice/getPaymentKeysForSinglePayment',
  telegramSaveSettingForSinglePayment = '/telegramsinglepaymentservice/saveSetting',
  telegramDoPaymentForSinglePayment = '/telegramsinglepaymentservice/pay'
}

export const telegramTest = async (data: any): Promise<any> => {
  const result: AxiosResponse<{
    data: AnalyserNode;
  }> = await axios.post(TelegramRoutes.telegramTest, data)
  return result.data.data
}

export const telegramPaymentServiceSettingGet = async (data: TelegramPaymentServiceSettingGetPost): Promise<TelegramPaymentServiceSettingResponse> => {
  const result: AxiosResponse<{
    data: TelegramPaymentServiceSettingResponse;
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceSettingGet, data)
  return result.data.data
}

export const telegramPaymentServiceSettingSave = async (data: TelegramPaymentServiceSettingSavePost): Promise<PaymentServiceSettings> => {
  const result: AxiosResponse<{
    data: PaymentServiceSettings;
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceSettingSave, data)
  return result.data.data
}

export const telegramPaymentServiceUnbindCard = async (data: TelegramPaymentServiceUnbindCardPost): Promise<BankBinding[]> => {
  const result: AxiosResponse<{
    data: BankBinding[];
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceUnbindCard, data)
  return result.data.data
}

export const telegramPaymentServiceuUbindSbpSubscription = async (data: TelegramPaymentServiceUnbindSbpSubscriptionPost): Promise<SbpSubscription[]> => {
  const result: AxiosResponse<{
    data: SbpSubscription[];
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceUbindSbpSubscription, data)
  return result.data.data
}

export const telegramPaymentServiceCreateSbpOrder = async (data: TelegramPaymentServiceCreateSbpOrderPost): Promise<PaymentServicePaySbpResponse> => {
  const result: AxiosResponse<{
    data: PaymentServicePaySbpResponse;
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceCreateSbpOrder, data)
  return result.data.data
}

export const telegramPaymentServiceCreateSbpOrderBySub = async (data: TelegramPaymentServiceCreateSbpOrderBySubPost): Promise<any> => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceCreateSbpOrderBySub, data)
  return result.data.data
}

export const telegramPaymentServiceCreateAquaringOrder = async (data: TelegramPaymentServiceCreateAquaringOrderPost): Promise<PaymentServicePayAquaringResponse> => {
  const result: AxiosResponse<{
    data: PaymentServicePayAquaringResponse;
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceCreateAquaringOrder, data)
  return result.data.data
}

export const telegramPaymentServiceuCreateAquaringOrderBySubscription = async (data: TelegramPaymentServiceuCreateAquaringOrderBySubscriptionPost): Promise<PaymentServicePayAquaringBySubResponse> => {
  const result: AxiosResponse<{
    data: PaymentServicePayAquaringBySubResponse;
  }> = await axios.post(TelegramRoutes.telegramPaymentServiceCreateAquaringOrderBySubscription, data)
  return result.data.data
}

export const telegramAutoPaymentServiceGetSettings = async (data: TelegramAutoPaymentServiceSettingsGetPost): Promise<TelegramAutoPaymentServiceSettings> => {
  const result: AxiosResponse<{
    data: TelegramAutoPaymentServiceSettings;
  }> = await axios.post(TelegramRoutes.telegramAutoPaymentServiceGetSettings, data)
  return result.data.data
}

export const telegramAutoPaymentServiceSaveSettings = async (data: TelegramAutoPaymentServiceSettings): Promise<TelegramAutoPaymentServiceSettings> => {
  const result: AxiosResponse<{
    data: TelegramAutoPaymentServiceSettings;
  }> = await axios.post(TelegramRoutes.telegramAutoPaymentServiceSaveSettings, data)
  return result.data.data
}

export const telegramAutoPaymentSendError = async (data: TelegramAutoPaymentError): Promise<TelegramAutoPaymentError> => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(TelegramRoutes.telegramAutoPaymentSendError, data)
  return result.data.data
}

export const telegramSupportGetOperatorList = async (data: TelegramSupportGetOperatorListPost): Promise<TelegramSupportOperator[]> => {
  const result: AxiosResponse<{
    data: TelegramSupportOperator[];
  }> = await axios.post(TelegramRoutes.telegramSupportGetOperatorList, data)
  return result.data.data
}

export const telegramSupportGetOperatorStatistic = async (data: TelegramSupportGetOperatorStatisticPost): Promise<TelegramSupportTicketResponse> => {
  const result: AxiosResponse<{
    data: TelegramSupportTicketResponse;
  }> = await axios.post(TelegramRoutes.telegramSupportGetOperatorStatistic, data)
  return result.data.data
}

export const telegramSupportGetDetailsByTicket = async (data: TelegramSupportGetDetailsByTicketPost): Promise<TelegramSupportDelailsResponse> => {
  const result: AxiosResponse<{
    data: TelegramSupportDelailsResponse;
  }> = await axios.post(TelegramRoutes.telegramSupportGetDetailsByTicket, data)
  return result.data.data
}

export const telegramSupportGetHistoryByTicket = async (data: TelegramSupportGetHistoryByTicketPost): Promise<TelegramSupportHistoryResponse> => {
  const result: AxiosResponse<{
    data: TelegramSupportHistoryResponse;
  }> = await axios.post(TelegramRoutes.telegramSupportGetHistoryByTicket, data)
  return result.data.data
}

export const telegramSupportGetFileHistoryByTicket = async (data: TelegramSupportGetFileHistoryByTicketPost): Promise<boolean> => {
  const result: AxiosResponse<{
    data: boolean;
  }> = await axios.post(TelegramRoutes.telegramSupportGetFileHistoryByTicket, data)
  return result.data.data
}

export const telegramSupportServiceUnbindTicket = async (data: TelegramSupportServiceUnbindTicketPost): Promise<boolean> => {
  const result: AxiosResponse<{
    data: boolean;
  }> = await axios.post(TelegramRoutes.telegramSupportServiceUnbindTicket, data)
  return result.data.data
}

/**
 * Метод получения чека
 * @param TelegramGlolimeCheckGetPost data
 * @returns Promise<TelegramGlolimeCheckGetResponse>
 */
export const telegramGlolimeCheckGet = async (data: TelegramGlolimeCheckGetPost): Promise<TelegramGlolimeCheckGetResponse> => {
  const result: AxiosResponse<{
    data: TelegramGlolimeCheckGetResponse;
  }> = await axios.post(TelegramRoutes.telegramGlolimeCheckGet, data)
  return result.data.data
}

/**
 * Метод получения детей и лицевых счетов для оплаты в TelegramSinglePayment
 * @param data
 * @returns Promise<TelegramPaymentKeyForSinglePayment[]>
 */
export const telegramGetPaymentKeysForSinglePayment = async (data: TelegramGetPaymentKeysForSinglePaymentPost): Promise<TelegramGetPaymentKeysForSinglePaymentPostApiResponse> => {
  const result = await axios.post<TelegramGetPaymentKeysForSinglePaymentPostApiResponse>(TelegramRoutes.telegramGetPaymentKeysForSinglePayment, data)
  return result.data
}
/**
 * Метод для сохранения значений кнопок пополнения баланса в TelegramSinglePayment
 * @param data
 * @returns Promise<TelegramSaveSettingForSinglePayment[]>
 */
export const telegramSaveSettingForSinglePayment = async (data: TelegramSaveSettingForSinglePaymentPost): Promise<TelegramSaveSettingForSinglePayment[]> => {
  const result: AxiosResponse<{
    data: TelegramSaveSettingForSinglePayment[];
  }> = await axios.post(TelegramRoutes.telegramSaveSettingForSinglePayment, data)
  return result.data.data
}
/**
 * Метод для оплаты в TelegramSinglePayment
 * @param data
 * @returns Promise<any>
 */
export const telegramDoPaymentForSinglePayment = async (data: TelegramDoPaymentForSinglePaymentPost): Promise<any> => {
  const result = await axios.post<TelegramDoPaymentForSinglePaymentResponse>(TelegramRoutes.telegramDoPaymentForSinglePayment, data)
  return result.data
}
