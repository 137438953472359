<template>
  <div class="whole_page">
    <div style="justify-content: center; display: grid">
      <div class="news_block">
        <div
          v-for="(newsItem, index) in newsList"
          :key="index"
          class="news-list-item"
        >
          <h4 class="title-h4-roboto-medium">
            {{ moment(newsItem.publishedDate).format('DD.MM.YYYY') }}
          </h4>
          <div v-html="newsItem.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { findByUserType } from '@/shared/api/parents/news/api-requests'
import { NewsFindByUserItem } from '@/shared/api/parents/news/api-types'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsNews',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      newsList: [] as NewsFindByUserItem[],
      isTelegram: false
    }
  },
  async beforeMount () {
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    await this.postNewsFindByUserType()
  },
  methods: {
    moment,
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async postNewsFindByUserType () {
      try {
        const postNewsFindByUserTypeReqObj = {
          id: null,
          published: null,
          publishedOnMain: null,
          publishedDate: null
        }
        const postNewsFindByUserTypeRes = await findByUserType(postNewsFindByUserTypeReqObj, this.isTelegram)
        postNewsFindByUserTypeRes.data.forEach((news: NewsFindByUserItem) => {
          console.log(news.text)
          this.newsList.push(news)
        })
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить новости!',
          timeout: 3000
        })
      }
    }
    //     if (window.Telegram !== undefined) {
    //   const WebApp = window.Telegram.WebApp
    //   if (WebApp.initDataUnsafe !== undefined) {
    //     if (WebApp.initDataUnsafe.user !== undefined) {
    //       this.chatId = WebApp.initDataUnsafe.user.id.toString()
    //     }
    //   }
    //   clearInterval(timer)
    //   this.telegramSupportGetDetailsByTicket()
    // }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.news_block {
  width: 700px;
  margin-top: 20px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  padding: 40px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.news-list-item {
  display: flex;
  flex-direction: column;
  gap: 9px;
  //justify-content: center;
  text-align: left;
  align-items: start;
  padding: 12px 0px;
  border-bottom: 1px solid #ebeaec
}
@media (max-aspect-ratio: 6/5) {
  .news_block {
    width: calc(100% - 32px)
  }
}
</style>
