<template>
  <div class="whole_page">
    <div style="justify-content: center; display: grid">
      <div class="information-block">
        <div style="padding-bottom: 12px; border-bottom: 1px solid #ebeaec; display: flex; flex-direction: row; justify-content: space-between">
          <h2 class="title-h2-roboto-medium">
            Информация
          </h2>
          <h3
            class="title-h4-roboto-medium"
            style="align-self: end"
          >
            Комбинат Акула
          </h3>
        </div>
        <div class="info-list-item">
          <div style="height: 20px; width: 20px; justify-content: center">
            <img
              src="@/assets/images/article.png"
              height="19px"
              width="19px"
            >
          </div>
          <h4
            class="button-text-roboto-medium"
            style="text-decoration: underline"
          >
            Заявление на возврат денежных средств со счетов комбинатов питания
          </h4>
        </div>
        <div class="info-list-item">
          <div style="height: 20px; width: 20px; justify-content: center">
            <img
              src="@/assets/images/article.png"
              height="19px"
              width="19px"
            >
          </div>
          <h4
            class="button-text-roboto-medium"
            style="text-decoration: underline"
          >
            Заявление на возврат средств перечисленных на счет ООО "Глолайм"
          </h4>
        </div>
        <div class="info-list-item">
          <div style="height: 20px; width: 20px; justify-content: center">
            <img
              src="@/assets/images/info.png"
              height="19px"
              width="19px"
            >
          </div>
          <h4
            class="button-text-roboto-medium"
            style="text-decoration: underline"
          >
            Заявление на возврат средств перечисленных на счет ООО "Глолайм"
          </h4>
        </div>
        <div class="info-list-item">
          <div style="height: 20px; width: 20px; justify-content: center">
            <img
              src="@/assets/images/info.png"
              height="19px"
              width="19px"
            >
          </div>
          <h4
            class="button-text-roboto-medium"
            style="text-decoration: underline"
          >
            Инструкция о подписке
          </h4>
        </div>
        <div class="info-list-item">
          <div style="height: 20px; width: 20px; justify-content: center">
            <img
              src="@/assets/images/info.png"
              height="19px"
              width="19px"
            >
          </div>
          <h4
            class="button-text-roboto-medium"
            style="text-decoration: underline"
          >
            Телеграм бот
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsInfo',
  methods: {
    goToHome () {
      this.$router.push('./home')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.information-block {
  width: 700px;
  margin-top: 20px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  padding: 40px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.info-list-item {
  display: flex;
  flex-direction: row;
  gap: 9px;
  //justify-content: center;
  text-align: left;
  align-items: center;
  padding: 12px 0px;
  border-bottom: 1px solid #ebeaec
}
@media (max-aspect-ratio: 6/5) {
.information-block {
  width: calc(100% - 32px)
}
}
</style>
