import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    modelValue: _ctx.selectedItem,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
      _ctx.update
    ],
    items: _ctx.items,
    "item-title": "name",
    "item-value": "id",
    label: "Классы",
    loading: _ctx.isLoading,
    disabled: _ctx.isDisabled
  }, null, 8, ["modelValue", "items", "loading", "disabled", "onUpdate:modelValue"]))
}