<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <place-selector
          v-model="selectedUserAllowedPlace"
          :selected-item-props="propPlaceId"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedMonth"
          :items="months"
          item-title="name"
          item-value="id"
          label="Месяц"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <year-selector
          v-model="selectedYear"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <div id="printableArea">
      <v-row>
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <v-col>
          <h3 class="text-center print-header">
            Проверка талонов<br>
            за {{ selectedMonthName }} {{ selectedYearName }} года<br>
            {{ selectedUserAllowedPlaceName }}
          </h3>
        </v-col>
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <span v-if="errorCount == 0">Ошибки в талонах: {{ errorCount }}</span>
        <span
          v-else
          class="text-warning"
        >Ошибки в талонах: {{ errorCount }}</span>
        <!--<template v-for="(errorDate, i) in errorDates"><span class="span-with-space" v-bind:key="i"><i> {{ errorDate }} </i></span></template>-->
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <span v-if="notAcceptCount == 0">Неучтенных талонов: {{ notAcceptCount }}</span>
        <span
          v-else
          class="text-warning"
        >Неучтенных талонов: {{ notAcceptCount }}</span>
        <!--<template v-for="(notAcceptDate, i) in notAcceptDates"><span class="span-with-space" v-bind:key="i"><i> {{ notAcceptDate }} </i></span></template>-->
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <span v-if="notTeacherHistoryCount == 0">Не подписаны классным руководителем: {{
          notTeacherHistoryCount
        }}</span>
        <span
          v-else
          class="text-warning"
        >Не подписаны классным руководителем: {{ notTeacherHistoryCount }}</span>
        <!--<template v-for="(notTeacherHistoryDate, i) in notTeacherHistoryDates"><span class="span-with-space" v-bind:key="i"><i> {{ notTeacherHistoryDate }} </i></span></template>-->
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <span v-if="notLadminHistoryCount == 0">Не подписаны ответственным за питание: {{
          notLadminHistoryCount
        }}</span>
        <span
          v-else
          class="text-warning"
        >Не подписаны ответственным за питание: {{ notLadminHistoryCount }}</span>
        <!--<template v-for="(notLadminHistoryDate, i) in notLadminHistoryDates"><span class="span-with-space" v-bind:key="i"><i> {{ notLadminHistoryDate }} </i></span></template>-->
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <span v-if="talonProductCount == allSalesCount">Количество фактов по данным талонов: {{ talonProductCount }} - Количество фактов по данным учета: {{
          allSalesCount
        }}</span>
        <span
          v-else
          class="text-warning"
        >Количество фактов по данным талонов: {{ talonProductCount }} - Количество фактов по данным учета: {{
          allSalesCount
        }}</span>
      </v-row>
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <span v-if="talonProductSum.toFixed(2) == allSalesSum.toFixed(2)">Сумма по данным талонов: {{
          talonProductSum.toFixed(2)
        }} - Сумма по данным учета: {{ allSalesSum.toFixed(2) }}</span>
        <span
          v-else
          class="text-warning"
        >Сумма по данным талонов: {{ talonProductSum.toFixed(2) }} - Сумма по данным учета: {{
          allSalesSum.toFixed(2)
        }}</span>
      </v-row>
      <br v-if="!isLoading && isPost">
      <v-row v-if="!isLoading && isPost">
        <v-table class="table-preorder">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Ошибки в талонах</th>
              <th>Неучтенных талонов</th>
              <th>Не подписаны классным руководителем</th>
              <th>Не подписаны ответственным за питание</th>
              <th>Количество фактов по данным талонов</th>
              <th>Количество фактов по данным учета</th>
              <th>Сумма по данным талонов</th>
              <th>Сумма по данным учета</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(reportDate, i) in reportDates"
              :key="i"
            >
              <td>{{ reportDate.date }}</td>
              <td v-if="reportDate.errors == 0">
                {{ reportDate.errors }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.errors }}
              </td>
              <td v-if="reportDate.accepts == 0">
                {{ reportDate.accepts }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.accepts }}
              </td>
              <td v-if="reportDate.teachers == 0">
                {{ reportDate.teachers }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.teachers }}
              </td>
              <td v-if="reportDate.ladmins == 0">
                {{ reportDate.ladmins }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.ladmins }}
              </td>
              <td v-if="reportDate.taloncounts == reportDate.salecounts">
                {{ reportDate.taloncounts }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.taloncounts }}
              </td>
              <td v-if="reportDate.taloncounts == reportDate.salecounts">
                {{ reportDate.salecounts }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.salecounts }}
              </td>
              <td v-if="reportDate.talonsums.toFixed(2) == reportDate.salesums.toFixed(2)">
                {{ reportDate.talonsums.toFixed(2) }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.talonsums.toFixed(2) }}
              </td>
              <td v-if="reportDate.talonsums.toFixed(2) == reportDate.salesums.toFixed(2)">
                {{ reportDate.salesums.toFixed(2) }}
              </td>
              <td
                v-else
                class="text-warning"
              >
                {{ reportDate.salesums.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import PlaceSelector from '@/shared/uikit/selectors/PlaceSelector.vue'
import YearSelector from '@/shared/uikit/selectors/YearSelector.vue'
import { mapActions, mapGetters } from 'vuex'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { find } from '@/shared/api/customerPlace/api-requests'
import { PlaceElectronTalonFindWithItemsPost } from '@/shared/api/placeElectronTalon/api-types'
import { findWithItemsAndHistory } from '@/shared/api/placeElectronTalon/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlaceName: string;
  selectedUserAllowedPlace: any;
  selectedMonth: any;
  selectedMonthName: string;
  selectedYearName: string;
  selectedYear: number;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsPost;
  customerPlacePost: CustomerPlaceFindPost;
  placeElectronTalonsAnswere: any;
  customerPlaceInfo: any;
  placeElectronTalons: any;
  sales: any;
  reportDates: any;
  notAcceptDates: any;
  errorDates: any;
  notTeacherHistoryDates: any;
  notLadminHistoryDates: any;
  notAcceptCount: number;
  errorCount: number;
  notTeacherHistoryCount: number;
  notLadminHistoryCount: number;
  talonProductCount: number;
  talonProductSum: number;
  allSalesCount: number;
  allSalesSum: number;
  months: any[];
}

export default defineComponent({
  components: {
    YearSelector,
    PlaceSelector
  },
  data (): BaseData {
    return {
      isLoading: false,
      isPost: false,
      selectedUserAllowedPlace: [],
      selectedUserAllowedPlaceName: '',
      selectedMonth: moment().month(),
      selectedMonthName: 'Неизвестно',
      selectedYearName: 'Неизвестно',
      selectedYear: moment().year(),
      placeElectronTalonGetByIdPost: {
        parentPlaceId: 0,
        dateFrom: '',
        dateTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      placeElectronTalonsAnswere: [],
      customerPlaceInfo: null,
      placeElectronTalons: [],
      sales: [],
      reportDates: [],
      errorDates: [],
      notAcceptDates: [],
      notTeacherHistoryDates: [],
      notLadminHistoryDates: [],
      notAcceptCount: 0,
      errorCount: 0,
      notTeacherHistoryCount: 0,
      notLadminHistoryCount: 0,
      talonProductCount: 0,
      talonProductSum: 0,
      allSalesCount: 0,
      allSalesSum: 0,
      months: [
        { id: 0, name: 'Январь' },
        { id: 1, name: 'Февраль' },
        { id: 2, name: 'Март' },
        { id: 3, name: 'Апрель' },
        { id: 4, name: 'Май' },
        { id: 5, name: 'Июнь' },
        { id: 6, name: 'Июль' },
        { id: 7, name: 'Август' },
        { id: 8, name: 'Сентябрь' },
        { id: 9, name: 'Октябрь' },
        { id: 10, name: 'Ноябрь' },
        { id: 11, name: 'Декабрь' }
      ]
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadPlaceElectronTalons () {
      try {
        this.isLoading = true
        if (this.selectedUserAllowedPlace !== 0) {
          this.selectedUserAllowedPlaceName = this.selectedUserAllowedPlace.name
          this.placeElectronTalonGetByIdPost.parentPlaceId = this.selectedUserAllowedPlace.id
          this.placeElectronTalonGetByIdPost.dateFrom = moment({year: this.selectedYear, month: this.selectedMonth}).format('YYYY-MM-DD')
          this.placeElectronTalonGetByIdPost.dateTo = moment(this.placeElectronTalonGetByIdPost.dateFrom).add(1, 'M').startOf('month').format('YYYY-MM-DD')
          const result = await findWithItemsAndHistory(this.placeElectronTalonGetByIdPost)
          this.placeElectronTalonsAnswere = JSON.parse(JSON.stringify(result))
          if (Array.isArray(this.placeElectronTalonsAnswere.placeEletronTalons)) {
            this.placeElectronTalons = this.placeElectronTalonsAnswere.placeEletronTalons
            this.sales = this.placeElectronTalonsAnswere.sales
            this.sales.count.forEach((count: any) => {
              this.allSalesCount += Number(count.quantity)
              const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(count.date).format('DD')))
              if (index > -1) {
                this.reportDates[index].salecounts += Number(count.quantity)
              } else {
                this.reportDates.push({
                  date: Number(moment(count.date).format('DD')),
                  errors: 0,
                  accepts: 0,
                  teachers: 0,
                  ladmins: 0,
                  taloncounts: 0,
                  salecounts: Number(count.quantity),
                  talonsums: 0,
                  salesums: 0
                })
              }
            })
            this.sales.sum.forEach((sum: any) => {
              this.allSalesSum += Number(Number(sum.sum).toFixed(2))
              const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(sum.date).format('DD')))
              if (index > -1) {
                this.reportDates[index].salesums += Number(Number(sum.sum).toFixed(2))
              } else {
                this.reportDates.push({
                  date: Number(moment(sum.date).format('DD')),
                  errors: 0,
                  accepts: 0,
                  teachers: 0,
                  ladmins: 0,
                  taloncounts: 0,
                  salecounts: 0,
                  talonsums: 0,
                  salesums: Number(Number(sum.sum).toFixed(2))
                })
              }
            })
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.selectedUserAllowedPlace !== 0) {
          this.customerPlacePost.placeId = this.selectedUserAllowedPlace.id
          this.customerPlacePost.date = moment(new Date(this.selectedYear, this.selectedMonth, 1)).endOf('month').format('YYYY-MM-DD')
          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
      }
    },
    createReport () {
      this.placeElectronTalons.forEach((placeElectronTalon: any) => {
        placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
          if (placeElectronTalonItem.removed == null) {
            placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
              if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                this.notAcceptCount++
                if (!this.reportDates.find((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))) {
                  this.reportDates.push({
                    date: Number(moment(placeElectronTalon.date).format('DD')),
                    errors: 0,
                    accepts: 0,
                    teachers: 0,
                    ladmins: 0,
                    taloncounts: 0,
                    salecounts: 0,
                    talonsums: 0,
                    salesums: 0
                  })
                }
                const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))
                if (index > -1) {
                  this.reportDates[index].accepts += 1
                }
                if (!this.notAcceptDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notAcceptDates.push(moment(placeElectronTalon.date).format('DD'))
              }
              if (placeElectronTalonItemSale.removed == null) {
                this.talonProductCount++
                let talonsum = 0
                placeElectronTalon.placeElectronTalonProducts.forEach((placeElectronTalonProduct: any) => {
                  if (placeElectronTalonProduct.removed == null && Number(placeElectronTalonProduct.productId) === Number(placeElectronTalonItemSale.productId)) {
                    this.talonProductSum = Number(this.talonProductSum.toFixed(2)) + Number(Number(placeElectronTalonProduct.price).toFixed(2))
                    talonsum = Number(talonsum.toFixed(2)) + Number(Number(placeElectronTalonProduct.price).toFixed(2))
                  }
                })
                const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))
                if (index > -1) {
                  this.reportDates[index].taloncounts += 1
                  this.reportDates[index].talonsums += Number(talonsum.toFixed(2))
                } else {
                  this.reportDates.push({
                    date: Number(moment(placeElectronTalon.date).format('DD')),
                    errors: 0,
                    accepts: 0,
                    teachers: 0,
                    ladmins: 0,
                    taloncounts: 1,
                    salecounts: 0,
                    talonsums: talonsum,
                    salesums: 0
                  })
                }
              }
            })
          }
          if (placeElectronTalonItem.error != null) {
            if (String(placeElectronTalonItem.error) !== '15') {
              this.errorCount++
              if (!this.reportDates.find((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))) {
                this.reportDates.push({
                  date: Number(moment(placeElectronTalon.date).format('DD')),
                  errors: 0,
                  accepts: 0,
                  teachers: 0,
                  ladmins: 0,
                  taloncounts: 0,
                  salecounts: 0,
                  talonsums: 0,
                  salesums: 0
                })
              }
              const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))
              if (index > -1) {
                this.reportDates[index].errors += 1
              }
              if (!this.errorDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.errorDates.push(moment(placeElectronTalon.date).format('DD'))
            }
          }
        })
        let teacherExists = 0
        let ladminExists = 0
        placeElectronTalon.placeElectronTalonHistoryItems.forEach((placeElectronTalonHistoryItem: any) => {
          if (placeElectronTalonHistoryItem.status === 1) {
            if (Number(placeElectronTalonHistoryItem.userTypeId) === 3) {
              teacherExists = 1
            }
            if (Number(placeElectronTalonHistoryItem.userTypeId) === 7) {
              ladminExists = 1
            }
          }
        })
        if (teacherExists === 0) {
          this.notTeacherHistoryCount++
          if (!this.reportDates.find((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))) {
            this.reportDates.push({
              date: Number(moment(placeElectronTalon.date).format('DD')),
              errors: 0,
              accepts: 0,
              teachers: 0,
              ladmins: 0,
              taloncounts: 0,
              salecounts: 0,
              talonsums: 0,
              salesums: 0
            })
          }
          const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))
          if (index > -1) {
            this.reportDates[index].teachers += 1
          }
          if (!this.notTeacherHistoryDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notTeacherHistoryDates.push(moment(placeElectronTalon.date).format('DD'))
        }
        if (ladminExists === 0) {
          this.notLadminHistoryCount++
          if (!this.reportDates.find((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))) {
            this.reportDates.push({
              date: Number(moment(placeElectronTalon.date).format('DD')),
              errors: 0,
              accepts: 0,
              teachers: 0,
              ladmins: 0,
              taloncounts: 0,
              salecounts: 0,
              talonsums: 0,
              salesums: 0
            })
          }
          const index = this.reportDates.findIndex((el: any) => el.date === Number(moment(placeElectronTalon.date).format('DD')))
          if (index > -1) {
            this.reportDates[index].ladmins += 1
          }
          if (!this.notLadminHistoryDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notLadminHistoryDates.push(moment(placeElectronTalon.date).format('DD'))
        }
      })
      this.reportDates.sort((a: any, b: any) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
    },
    load () {
      this.allSalesCount = 0
      this.allSalesSum = 0
      this.talonProductCount = 0
      this.talonProductSum = 0
      this.notTeacherHistoryCount = 0
      this.notLadminHistoryCount = 0
      this.placeElectronTalons = []
      this.reportDates = []
      this.errorDates = []
      this.notAcceptDates = []
      this.notTeacherHistoryDates = []
      this.notLadminHistoryDates = []
      this.placeElectronTalonsAnswere = []
      this.customerPlaceInfo = null
      this.loadPlaceElectronTalons()
      this.loadCustomerPlaceInfo()
      this.isPost = true
      this.selectedMonthName = this.months.find((month: any) => month.id === this.selectedMonth).name
      this.selectedYearName = this.selectedYear.toString()
    }
  }
})
</script>

<style lang="scss"></style>
