<template>
  <v-container sm="12">
    <v-row>
      <v-col>
        <edit-increment-group-form
          v-if="incrementGroup.id"
          :increment-group="incrementGroup"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EditIncrementGroupForm from '@/widgets/forms/EditIncrementGroupForm.vue'
import { IncrementGroup } from '@/store/incrementGroup/types'
import { mapActions } from 'vuex'

type BaseData = {
  incrementGroup: IncrementGroup;
}
export default defineComponent({
  components: {
    EditIncrementGroupForm
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      incrementGroup: {
        id: 0,
        name: ''
      }
    }
  },
  methods: {
    ...mapActions('incrementGroups', { incrementGroupFind: 'find' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getIncrementGroupForEdit (incrementGroupId: number) {
      const n: IncrementGroup = await this.incrementGroupFind(incrementGroupId)
      if (!n.id) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Группа не найдена.',
          timeout: 3000
        })
        this.$router.push({ name: 'CreateIncrementGroup' })
        return
      }
      this.incrementGroup = n
    }
  },
  async mounted () {
    if (!this.id) {
      this.$router.push({ name: 'dashboard' })
    }
    await this.getIncrementGroupForEdit(Number(this.id))
  }
})
</script>

<style lang='scss'></style>
