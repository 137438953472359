import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { RatePlan } from '@/store/placeCustomerRateplans/types'
import { PlaceCustomerRatePlanFindPost } from './api-types'

const routePlaceCustomerRatePlanFind = 'placecustomerrateplans/find'

export const find = async (data: PlaceCustomerRatePlanFindPost): Promise<RatePlan[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: RatePlan[] ;
  }> = await axios.post(routePlaceCustomerRatePlanFind, data)

  return result.data.data
}
