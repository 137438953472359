import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { UserPlace } from '@/store/userPlaceList/types'
import {
  UserPlaceCreatePost,
  UserPlaceEditPut,
  UserPlaceRefinePost,
  UserPlaceChangePost,
  UserPlaceChangeBySekshPost
} from './api-types'

const routeUserPlaceList = '/userplaces/all'
const routeUserPlaceFind = '/userplaces/find'
const routeUserPlaceCreate = '/userplaces/create'
const routeUserPlaceUpdate = '/userplaces/update'
const routeUserPlaceDelete = '/userplaces/delete'
const routeRefineUserPlace = '/userplaces/refine'
const routeChangeUserPlace = '/userplaces/change'
const routeChangeUserPlaceBySeksh = '/userplaces/changeByEksh'


export const all = async (): Promise<UserPlace[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: UserPlace[];
  }> = await axios.get(routeUserPlaceList)
  return result.data.data
}

export const find = async (data: number): Promise<UserPlace> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserPlace ;
  }> = await axios.get(`${routeUserPlaceFind}/${data}`)

  return result.data.data
}

export const create = async (data: UserPlaceCreatePost): Promise<UserPlace> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserPlace ;
  }> = await axios.post(routeUserPlaceCreate, data)

  return result.data.data
}

export const update = async (data: UserPlace): Promise<UserPlace> => {
  const putRequest: UserPlaceEditPut = {
    name: data.name
  }

  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserPlace;
  }> = await axios.put(`${routeUserPlaceUpdate}/${data.id}`, putRequest)

  return result.data.data
}

export const del = async (data: UserPlace): Promise<UserPlace> => {
  setAuthorizationHeader(axios)
  await axios.delete(`${routeUserPlaceDelete}/${data.id}`)
  return data
}

export const refineUserPlace = async (data: UserPlaceRefinePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeRefineUserPlace, data)
  return result.data.data
}

export const changeUserPlace = async (data: UserPlaceChangePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeChangeUserPlace, data)
  return result.data.data
}

export const changeUserPlaceBySeksh = async (data: UserPlaceChangeBySekshPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeChangeUserPlaceBySeksh, data)
  return result.data.data
}
