<template>
  <v-select
    v-model="selectedValue"
    :items="items"
    item-value="id"
    item-title="name"
    :label="label"
    :loading="isLoading"
    :disabled="isDisabled"
    @update:model-value="update"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  model: {
    event: 'on-change'
  },
  props: ['itemsProp', 'value', 'labelProp', 'loadingProp', 'disabledProp', 'valueProp'],
  data () {
    return {
      selectedValue: 0,
      isLoading: false,
      isDisabled: false,
      label: '',
      items: [{ id: 0, name: 'Загружаем' }]
    }
  },
  mounted () {
    if (this.itemsProp) this.items = this.itemsProp
    if (this.labelProp) this.label = this.labelProp
    if (this.loadingProp) this.isLoading = this.loadingProp
    if (this.disabledProp) this.isDisabled = this.disabledProp
    if (this.valueProp) this.selectedValue = +this.valueProp
  },
  methods: {
    update (value: any) {
      console.log(value)
      this.$emit('on-change', value)
    }
  }
})
</script>

<style lang="scss"></style>
