import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { blockCesCardPost, activateCesCardPost } from './api-types'

const routeCesCardsActivate = 'cards/cescards/activate'
const routeCesCardsBlock = 'cards/cescards/block'

export const activateCesCard = async (data: activateCesCardPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeCesCardsActivate, data)
  return result.data.data
}

export const blockCesCard = async (data: blockCesCardPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeCesCardsBlock, data)
  return result.data.data
}
