import axios, { setCardReaderUrl } from '@/shared/lib/axiosLocal'
import { AxiosResponse } from 'axios'
import { Card } from './types'

const routeGetCard = '/v1/GetCard'

export const getCard = async (): Promise<Card> => {
  setCardReaderUrl(axios)
  const result: AxiosResponse<{
      card: Card;
  }> = await axios.get(routeGetCard)
  return result.data.card
}
