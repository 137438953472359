<template>
  <v-container class="convertedRawApiData">
    <v-row class="justify-content-center">
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="isLoading"
            cols="10"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
        <v-row v-if="getAccess([4, 10, 26, 66])">
          <a
            style="text-decoration: underline; color: blue"
            @click="getOldSite()"
          >Старая версия</a>
        </v-row>
        <v-row v-if="user != null && !isLoading">
          <v-col>
            <v-row dense>
              <v-col>
                <v-row>
                  <v-col>
                    <v-card-text
                      v-if="getAccess([4, 7, 10, 26, 66, 33])"
                      dense
                      style="padding: 0"
                    >
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-if="!hideFio"
                            v-model="user.lastName"
                            label="Фамилия"
                            density="compact"
                          />
                          <v-text-field
                            v-if="!hideFio"
                            v-model="user.firstName"
                            label="Имя"
                            density="compact"
                          />
                          <v-text-field
                            v-if="!hideFio"
                            v-model="user.middleName"
                            label="Отчество"
                            density="compact"
                          />
                          <v-text-field
                            v-if="getAccess([4, 10, 26, 66]) && hideFio"
                            v-model="changedLastName"
                            label="Фамилия"
                            density="compact"
                          />
                          <v-text-field
                            v-if="getAccess([4, 10, 26, 66]) && hideFio"
                            v-model="changedFirstName"
                            label="Имя"
                            density="compact"
                          />
                          <v-text-field
                            v-if="getAccess([4, 10, 26, 66]) && hideFio"
                            v-model="changedMiddleName"
                            label="Отчество"
                            density="compact"
                          />
                        </v-col>
                        <v-col>
                          <v-checkbox
                            v-if="getAccess([4, 10, 26, 66])"
                            v-model="hideFio"
                            label="Скрыть перс данные"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="getAccess([4, 7, 10, 33, 26, 66])">
                        <v-col>
                          <v-select
                            v-if="isAccessToUserType(user.typeId)"
                            v-model="selectedUserType"
                            label="Тип"
                            :items="userTypes"
                            item-value="id"
                            item-title="name"
                            density="compact"
                          />
                          <v-select
                            v-else
                            v-model="selectedUserType"
                            label="Тип"
                            :items="userTypes"
                            item-value="id"
                            item-title="name"
                            density="compact"
                            disabled
                          />
                        </v-col>
                        <v-col>
                          <v-btn
                            v-if="getAccess([7])"
                            @click="updateUserType()"
                          >
                            Изменить
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="getAccess([4, 10, 26, 66, 33])">
                        <v-col v-if="!checkChangeFullName() && getAccess([33])">
                          <v-btn
                            class="btn"
                            variant="green"
                            block
                            disabled
                            @click="saveUser()"
                          >
                            Сохранить
                          </v-btn>
                        </v-col>
                        <v-col v-else>
                          <v-btn
                            class="btn"
                            block
                            @click="saveUser()"
                          >
                            Сохранить
                          </v-btn>
                        </v-col>
                        <v-col v-if="getAccess([33])">
                          <v-btn
                            class="btn"
                            block
                            @click="setUpdateTimestamp()"
                          >
                            Обновить
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          СУИД: {{ user.suid }}
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="getAccess([4, 7, 10, 26, 66]) && (townCard == 'true')"
                        dense
                      >
                        <v-col>
                          Регистрационный номер: {{ user.regId }}
                        </v-col>
                        <template v-if="[4,10].includes(+owner.person.userType)">
                          <v-col
                            cols="4"
                            sm="4"
                          >
                            <v-btn
                              style="font-size: 12px; height: 24px;"
                              @click="clickCheckRKHandler"
                            >
                              Проверить в РК
                            </v-btn>
                          </v-col>
                        </template>
                      </v-row>
                      <v-row
                        v-if="getAccess([4, 10])"
                        dense
                      >
                        <v-col>
                          Логин: {{ user.login }}
                        </v-col>
                        <v-col>
                          Email:
                        </v-col>
                        <v-col>
                          {{ user.email }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-row
                      v-if="getAccess([4, 7, 10, 26, 66, 33])"
                      dense
                    >
                      <v-col>
                        Карты:
                        <v-table
                          class="green-header-table"
                          dense
                        >
                          <tbody>
                          <template v-for="(userCard, i) in userCards">
                            <tr
                              v-if="userCard.cardTypeId != 3"
                              :key="i"
                            >
                              <td>
                                {{ i + 1 }}.
                              </td>
                              <td>
                                {{ userCard.rfidHex.toUpperCase() }}
                              </td>
                              <td>
                                {{ getFlagStr(userCard.flags) }} - {{ getUpdateDiffStr(userCard.updateDiff) }}
                              </td>
                              <td v-if="getAccess([4, 10]) || allowAddCardNumber == true">
                                <v-btn
                                  v-if="userCard.flags != null && userCard.flags != 0"
                                  @click="activateUserCard(userCard.id)"
                                >
                                  Активировать
                                </v-btn>
                                <v-btn
                                  v-else
                                  @click="blockUserCard(userCard.id)"
                                >
                                  Заблокировать
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                          <tr v-if="getAccess([4, 10]) || allowAddCardNumber == true">
                            <td>
                              {{ userCards.length + 1 }}.
                            </td>
                            <td>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="newCard"
                                    label="Новая карта"
                                    density="compact"
                                  />
                                  <v-btn @click="readCard()">
                                    <v-icon
                                      size="medium"
                                      color="red-darken-2"
                                    >
                                      mdi-eye
                                    </v-icon>
                                  </v-btn>
                                  <v-btn @click="changeReaderSettings()">
                                    <v-icon
                                      size="medium"
                                      color="red-darken-2"
                                    >
                                      mdi-wrench
                                    </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </td>
                            <td>
                              <v-select
                                v-model="selectedNewCardType"
                                :items="cardActiveTypes"
                                item-value="id"
                                item-title="name"
                                density="compact"
                              />
                            </td>
                            <td>
                              <v-btn @click="addNewCard()">
                                Добавить
                              </v-btn>
                            </td>
                          </tr>
                          </tbody>
                        </v-table>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="getAccess([4, 7, 10, 26, 66, 33])"
                      dense
                    >
                      <v-col>
                        <v-table
                          class="green-header-table"
                          dense
                        >
                          <thead>
                          <tr>
                            <th colspan="100%">
                              Карты нового образца
                            </th>
                          </tr>
                          <tr>
                            <th style="background: antiquewhite; color: black !important;">
                              Код карты
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              Статус
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              Номер карты
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              с:
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              по:
                            </th>
                            <th
                              v-if="getAccess([4, 10, 26, 66])"
                              style="background: antiquewhite; color: black !important;"
                            />
                          </tr>
                          </thead>
                          <tbody>
                          <template
                            v-for="(cesUserCard, i) in user.cesUserCards"
                            :key="i"
                          >
                            <tr>
                              <td>{{ cesUserCard.card.rfid }}</td>
                              <td v-if="cesUserCard.active == 1">
                                Активна
                              </td>
                              <td v-else>
                                Заблокирована
                              </td>
                              <td>{{ cesUserCard.card.cardNumber }}</td>
                              <td>{{ formatDateTime(cesUserCard.timestampFrom) }}</td>
                              <td>{{ formatDateTime(cesUserCard.timestampTo) }}</td>
                              <td v-if="getAccess([4, 10, 26, 66])">
                                <a
                                  v-if="cesUserCard.active == 1"
                                  style="text-decoration: underline"
                                  @click="blockCesCard(cesUserCard)"
                                >Заблокировать</a>
                                <a
                                  v-else
                                  style="text-decoration: underline"
                                  @click="activateCesCard(cesUserCard)"
                                >Активировать</a>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </v-table>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="getAccess([4, 7, 10, 26, 66, 33])"
                      dense
                    >
                      <v-col>
                        <v-table
                          class="green-header-table"
                          dense
                        >
                          <thead>
                          <tr>
                            <th colspan="100%">
                              История временных карт
                            </th>
                          </tr>
                          <tr>
                            <th style="background: antiquewhite; color: black !important;">
                              №
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              Карта
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              Код карты
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              с:
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              по:
                            </th>
                            <th style="background: antiquewhite; color: black !important;">
                              Причина выдачи
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <template
                            v-for="(userInterimCard, i) in user.userInterimCards"
                            :key="i"
                          >
                            <tr
                              v-if="Number(userInterimCard.attached) == 1"
                              style="font-weight: bold;"
                            >
                              <td>{{ userInterimCard.interimCard.order }}</td>
                              <td>{{ userInterimCard.interimCard.name }}</td>
                              <td>{{ userInterimCard.interimCard.rfidHex }}</td>
                              <td>{{ formatDateTime(userInterimCard.timestampFrom) }}</td>
                              <td>{{ formatDateTime(userInterimCard.timestampTo) }}</td>
                              <td>
                                {{
                                  interimCardDescriptionTypeDate(userInterimCard.interimCardDescriptionTypeId)
                                }}
                              </td>
                            </tr>
                            <tr v-else>
                              <td>{{ userInterimCard.interimCard.order }}</td>
                              <td>{{ userInterimCard.interimCard.name }}</td>
                              <td>{{ userInterimCard.interimCard.rfidHex }}</td>
                              <td>{{ formatDateTime(userInterimCard.timestampFrom) }}</td>
                              <td>{{ formatDateTime(userInterimCard.timestampTo) }}</td>
                              <td>
                                {{
                                  interimCardDescriptionTypeDate(userInterimCard.interimCardDescriptionTypeId)
                                }}
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </v-table>
                        <br>
                        <div v-if="getAccess([7]) && (townCard == 'false')">
                          <div v-if="isActiveInterimCard(user.userInterimCards)">
                            <v-btn @click="blockInterimCard()">
                              вернуть временную карту
                            </v-btn>
                            <v-btn @click="renewalInterimCard()">
                              продлить срок действия временной карты
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-row>
                              <v-col>
                                <v-select
                                  v-model="selectedInterimCard"
                                  label="Выдать временную карту:"
                                  :items="interimCards"
                                  item-value="id"
                                  item-title="order"
                                  density="compact"
                                />
                              </v-col>
                              <v-col>
                                <v-btn
                                  @click="interimCardDialog = true"
                                >
                                  Выдать
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col style="text-align: center;">
                    <v-row>
                      <v-col>
                        <div
                          v-if="townCard == 'true'"
                          ref="print"
                          style="height:280px;"
                        >
                          <div style="box-sizing: content-box;display: inline-block;">
                            <div
                              id="business-card"
                              :style="{ backgroundImage: 'url(' + require('@/assets/images/plashkauid.png') + ')', height: '220px', width: '355px', position: 'relative' }"
                            >
                              <div>
                                <div
                                  id="combine"
                                  style="float: left; position: relative; width: 200px; left: 10px; top: 4px; font-size: 11px; text-align: left;"
                                >
                                  {{ getCustomerShortName(user.currentCustomerDescription) }}
                                </div>
                                <div
                                  id="inn"
                                  style="float: left; position: relative; width: 120px; font-weight: bolder; left: 0px; top: 4px; font-size: 11px;"
                                >
                                  ИНН: {{ getCustomerInn(user.currentCustomerDescription) }}
                                </div>
                              </div>
                              <div
                                id="secondName"
                                style="left: 10px; top: 74px; font-size: 20px;"
                              >
                                {{ realLastName }}
                              </div>
                              <div
                                id="firstName"
                                style="left: 10px; top: 99px; font-size: 20px;"
                              >
                                {{ realFirstName }} {{ realMiddleName }}
                              </div>
                              <div
                                id="accountNumber2"
                                style="left: 125px; top: 134px; font-size: 25px;"
                              >
                                {{ getFormatedUserPaymentKey(user, 3) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          ref="print"
                          style="height:238px;"
                        >
                          <div style="box-sizing: content-box;display: inline-block;">
                            <div
                              id="business-card"
                              :style="{ backgroundImage: 'url(' + require('@/assets/images/plashka.jpg') + ')', height: '197px', width: '355px', position: 'relative' }"
                            >
                              <div>
                                <div
                                  id="combine"
                                  style="float: left; position: relative; width: 200px; left: 10px; top: 4px; font-size: 11px; text-align: left;"
                                >
                                  {{ getCustomerShortName(user.currentCustomerDescription) }}
                                </div>
                                <div
                                  id="inn"
                                  style="float: left; position: relative; width: 120px; font-weight: bolder; left: 0px; top: 4px; font-size: 11px;"
                                >
                                  ИНН: {{ getCustomerInn(user.currentCustomerDescription) }}
                                </div>
                              </div>
                              <div
                                id="school"
                                style="left: 10px; top: 21px; font-size: 14px;"
                              >
                                {{ getCurrentSchoolName(user.currentSchool) }}
                              </div>
                              <div
                                id="secondName"
                                style="left: 10px; top: 44px; font-size: 20px;"
                              >
                                {{ realLastName }}
                              </div>
                              <div
                                id="firstName"
                                style="left: 10px; top: 69px; font-size: 20px;"
                              >
                                {{ realFirstName }} {{ realMiddleName }}
                              </div>
                              <div
                                id="loginCode"
                                style="font-size: 12px; left: 270px; top: 65px;"
                              >
                                {{ user.otp }}
                              </div>
                              <div
                                id="accountNumber"
                                style="left: 255px; top: 106px; font-size: 12px;"
                              >
                                {{ getFormatedUserPaymentKey(user, 1) }}
                              </div>
                              <div id="qr">
                                <div
                                  style="position: relative; left: -10px; top: 30px; padding: 0; margin: 0; width: 120px; height: 120px;"
                                >
                                  <qrcode-vue
                                    :value="user.qrCode"
                                    :level="'L'"
                                    :size="85"
                                    :margin="5"
                                  ></qrcode-vue>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="getAccess([4, 10])"
                          style="display: inline-block;"
                        >
                          <v-radio-group v-model="townCard">
                            <v-radio
                              value="true"
                              label="Визитка город"
                            />
                            <v-radio
                              value="false"
                              label="Визитка Глолайм"
                            />
                          </v-radio-group>
                        </div>
                        <br>
                        <v-btn @click="printCard()">
                          Распечатать визитку
                        </v-btn>
                        <v-btn @click="saveCard()">
                          Сохранить
                        </v-btn>
                        <br><br>
                        <b>ИД Глолайм: {{ getUserPaymentKey(user, 1) }}</b>
                        <br>
                        <a
                          v-if="getAccess([4, 10])"
                          style="text-decoration: underline; color: blue"
                          @click="getPortal()"
                        >Просмотр на портале</a>
                        <br>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="getAccess([7])">
                  <v-col>
                    <div>
                      <v-form>
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-select
                                v-model="selectedSpravkaMonthCount"
                                label="Выдать справку за:"
                                :items="spravkaMonthCount"
                                item-value="id"
                                item-title="name"
                                density="compact"
                              />
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="selectedSpravkaMonth"
                                label="Последний месяц в справке:"
                                :items="spravkaMonthes"
                                item-value="id"
                                item-title="name"
                                density="compact"
                              />
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="selectedSpravkaYear"
                                label="Год:"
                                :items="spravkaYears"
                                item-value="id"
                                item-title="name"
                                density="compact"
                              />
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="selectedSpravkaType"
                                label="Вид справки:"
                                :items="spravkaTypes"
                                item-value="id"
                                item-title="name"
                                density="compact"
                              />
                            </v-col>
                            <v-col>
                              <v-btn @click="getSpravka()">
                                Выдать
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-text
                      v-if="getAccess([4, 10, 33])"
                      dense
                      style="padding: 0"
                    >
                      <v-row
                        v-if="getAccess([4, 10])"
                        dense
                      >
                        <v-col>
                          <v-text-field
                            v-model="newEmail"
                            label="Запросить изменение Email на:"
                            density="compact"
                          />
                        </v-col>
                        <v-col>
                          <v-btn @click="demandForUpdateEmail()">
                            Запросить
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="getAccess([4, 10])"
                        dense
                      >
                        <v-col>
                          <v-textarea
                            v-model="user.description"
                            variant="outlined"
                            density="compact"
                            name="input-7-1"
                            label="Примечание"
                            rows="3"
                          />
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="getAccess([10])"
                        dense
                      >
                        <v-col>
                          <v-text-field
                            v-model="user.login"
                            label="Логин"
                            density="compact"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            label="Пароль"
                            density="compact"
                          />
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="getAccess([4, 10])"
                        dense
                      >
                        <v-col dense>
                          <v-text-field
                            v-model="user.phone"
                            label="Телефон"
                            density="compact"
                          />
                        </v-col>
                        <v-col dense/>
                        <v-col dense>
                          <v-text-field
                            v-model="user.pin"
                            label="PIN-код"
                            density="compact"
                          />
                        </v-col>
                        <v-col dense/>
                      </v-row>
                      <v-row v-if="getAccess([4, 10])">
                        <v-col>
                          <v-btn
                            class="btn"
                            block
                            @click="saveUserData()"
                          >
                            Сохранить
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-table
                      v-if="getAccess([4, 10])"
                      class="green-header-table"
                      dense
                    >
                      <thead>
                      <tr>
                        <th colspan="100%">
                          Операции со счетом:
                        </th>
                      </tr>
                      <tr>
                        <th
                          colspan="100%"
                          style="background: antiquewhite; color: black !important;"
                        >
                          Установить дневной лимит на буфет (в рублях)
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="useLimit"
                            label="Использовать лимит"
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-if="useLimit"
                            v-model="accountLimit"
                            density="compact"
                          />
                          <v-text-field
                            v-else
                            density="compact"
                            disabled
                          />
                        </td>
                        <td>
                          <v-btn
                            class="btn"
                            block
                            @click="setBuffetLimit()"
                          >
                            Применить
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <th colspan="100%">
                          Для отключения лимита уберите соответствующую галочку.
                        </th>
                      </tr>
                      </tfoot>
                    </v-table>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col dense>
                    <v-card-text
                      dense
                      style="padding: 0"
                    >
                      <v-table
                        class="green-header-table"
                        dense
                      >
                        <thead>
                        <tr>
                          <th>
                            Класс
                          </th>
                          <th>
                            с:
                          </th>
                          <th>
                            до:
                          </th>
                          <th v-if="getAccess([4, 7, 10])"/>
                        </tr>
                        </thead>
                        <tbody>
                        <template
                          v-for="(userPlace, i) in user.userPlaces"
                          :key="i"
                        >
                          <tr>
                            <td>
                              {{ getUserPlace(userPlace) }}
                            </td>
                            <td>
                              {{ formatDateTime(userPlace.timestampFrom) }}
                            </td>
                            <td>
                              {{ formatDateTime(userPlace.timestampTo) }}
                            </td>
                            <td v-if="getAccess([7])">
                              <a @click="placeRefine(userPlace)">Уточнить</a>
                            </td>
                            <td v-if="getAccess([4, 10])"/>
                          </tr>
                        </template>
                        <tr v-if="getAccess([4, 7, 10])">
                          <td>
                            <v-row>
                              <v-col>
                                <v-select
                                  v-model="selectedUserAllowedPlace"
                                  style="position: inherit;"
                                  :items="userAllowedPlacesList"
                                  item-title="place.name"
                                  item-value="place.id"
                                  label="Школа"
                                  @update:model-value="findPlacesByParentId()"
                                >
                                  <template #prepend-item>
                                    <v-list-item>
                                      <v-text-field
                                        v-model="searchTerm"
                                        :placeholder="'Поиск'"
                                        @update:model-value="searchUserAllowedPlaces"
                                      />
                                    </v-list-item>
                                    <v-divider class="mt-2"/>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col>
                                <v-select
                                  v-if="classList.length > 0"
                                  v-model="selectedClass"
                                  style="position: inherit;"
                                  :items="classList"
                                  item-title="name"
                                  item-value="id"
                                  label="Класс"
                                />
                              </v-col>
                            </v-row>
                          </td>
                          <td>
                            <!--
                          <simple-datepicker
                            label="Дата"
                            v-on:date-select='changeTimestamp' v-if="getAccess([4, 10])">
                          </simple-datepicker>
                          -->
                            <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :offset="40"
                              transition="scale-transition"
                              min-width="290px"
                            >
                              <template #activator="{ props }">
                                <v-text-field
                                  :model-value="formatDate(classTimestamp)"
                                  label="Дата"
                                  prepend-icon="mdi-calendar"
                                  readonly

                                  v-bind="props"
                                />
                              </template>
                              <v-date-picker
                                v-model="classTimestamp"
                                hide-weekdays
                                title="Дата"
                              />
                            </v-menu>
                          </td>
                          <td/>
                          <td>
                            <v-btn
                              class="btn"
                              block
                              @click="addUserPlace()"
                            >
                              Перевести
                            </v-btn>
                          </td>
                        </tr>
                        </tbody>
                      </v-table>
                    </v-card-text>
                    <br>
                    <v-card-text style="padding: 0">
                      <v-table
                        class="green-header-table"
                        dense
                      >
                        <thead>
                        <tr>
                          <th>
                            Счет
                          </th>
                          <th>
                            Сумма
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(account, i) in accounts">
                          <tr
                            v-if="checkUserTypeBlockedAccountTypes(account.accountType.id)"
                            :key="i"
                          >
                            <td>
                              {{ account.accountType.name }}
                            </td>
                            <td>
                              {{ Number(account.sum).toFixed(2) }}
                              <v-btn
                                v-if="getAccess([4, 7, 10]) && account.accountTypeId == 14"
                                @click="getDeponent(account.id)"
                              >
                                Показать
                              </v-btn>
                              <v-table
                                v-if="account.accountTypeId == 14 && deponentVisible"
                                dense
                              >
                                <template
                                  v-for="(deponent, j) in deponents"
                                  :key="j"
                                >
                                  <tr>
                                    <td>{{ deponent.customer.name }}</td>
                                    <td>{{ deponent.sum }}</td>
                                  </tr>
                                </template>
                              </v-table>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td
                            colspan="2"
                            dense
                            style="text-align: center"
                          >
                            <router-link
                              style="color: white !important;"
                              :to="{ name: 'UserTransactions', params: { userId: id } }"
                            >
                              Выписка
                            </router-link>
                          </td>
                        </tr>
                        </tfoot>
                      </v-table>
                    </v-card-text>
                  </v-col>
                  <v-col v-if="getAccess([4, 7, 10])">
                    <v-table
                      class="green-header-table"
                      dense
                    >
                      <thead>
                      <tr>
                        <th colspan="100%">
                          Установка тарифного плана
                        </th>
                      </tr>
                      <tr>
                        <th style="background: antiquewhite; color: black !important;">
                          Телефон (10 цифр, в формате: 9119999999)
                        </th>
                        <th style="background: antiquewhite; color: black !important;">
                          Тарифный план
                        </th>
                        <th style="background: antiquewhite; color: black !important;">
                          Мобильное приложение
                        </th>
                        <th style="background: antiquewhite; color: black !important;"/>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <v-text-field
                            v-model="tariffPlanPhone"
                            type="number"
                            maxlength="10"
                            :rules="[rules.counter]"
                            variant="outlined"
                            density="compact"
                          />
                        </td>
                        <td>
                          <v-radio-group
                            v-model="selectedTariffPlan"
                            disabled
                          >
                            <v-radio
                              label="Базовый"
                              value="5"
                            />
                            <v-radio
                              label="Расширенный"
                              value="9"
                            />
                          </v-radio-group>
                        </td>
                        <td style="text-align: center;">
                          <v-checkbox
                            v-model="mobileTariffPlan"
                            disabled
                          />
                        </td>
                        <td
                          rowspan="2"
                          style="text-align: center;"
                        >
                          <v-btn @click="addUserPhoneNumber()">
                            Применить
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center;">
                          активировать при пополнении баланса
                        </td>
                        <td style="text-align: center;">
                          <v-checkbox disabled/>
                        </td>
                        <td style="text-align: center;">
                          <v-checkbox disabled/>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                    <v-card-text
                      v-if="getAccess([4, 10])"
                      dense
                      style="padding: 0"
                    >
                      <v-table
                        class="green-header-table"
                        dense
                      >
                        <thead>
                        <tr>
                          <th colspan="100%">
                            Тарифный план пользователя
                          </th>
                        </tr>
                        <tr>
                          <th style="background: antiquewhite; color: black !important;">
                            Тарифный план
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            с
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            до
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Назначен
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Документ
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Примечание
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template
                          v-for="(userTariffPlan, i) in user.userTariffPlans"
                          :key="i"
                        >
                          <tr
                            v-if="userTariffPlan.endTime == null || isEndTimeIsAfterNow(userTariffPlan)"
                            style="font-weight: bold;"
                          >
                            <td>{{ userTariffPlan.tariffPlan.name }}</td>
                            <td>{{ formatDateTime(userTariffPlan.startTime) }}</td>
                            <td>{{ formatDateTime(userTariffPlan.endTime) }}</td>
                            <td>{{ formatDateTime(userTariffPlan.creationDate) }}</td>
                            <td>
                              <a @click="getDocument(userTariffPlan.documentId)">{{ userTariffPlan.documentId }}</a>
                            </td>
                            <td v-if="userTariffPlan.blocked != null">
                              Заблокирован
                            </td>
                            <td v-else/>
                          </tr>
                          <tr v-else>
                            <td>{{ userTariffPlan.tariffPlan.name }}</td>
                            <td>{{ formatDateTime(userTariffPlan.startTime) }}</td>
                            <td>{{ formatDateTime(userTariffPlan.endTime) }}</td>
                            <td>{{ formatDateTime(userTariffPlan.creationDate) }}</td>
                            <td>
                              <a @click="getDocument(userTariffPlan.documentId)">{{ userTariffPlan.documentId }}</a>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                        <tfoot>
                        <tr>
                          <th colspan="100%">
                            Активный выделен жирным шрифтом
                          </th>
                        </tr>
                        </tfoot>
                      </v-table>
                    </v-card-text>
                  </v-col>
                  <v-col v-else/>
                </v-row>
                <v-row dense>
                  <v-col dense>
                    <v-card-text
                      dense
                      style="padding: 0"
                    >
                      <v-table
                        class="green-header-table"
                        dense
                      >
                        <thead>
                        <tr>
                          <th colspan="100%">
                            Приказ на льготную категорию
                          </th>
                        </tr>
                        <tr>
                          <th style="background: antiquewhite; color: black !important;">
                            Категория льгот
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            с
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            до
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Назначен
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Приказ
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template
                          v-for="(userRatePlan, i) in user.userRatePlans"
                          :key="i"
                        >
                          <tr
                            v-if="user.currentUserRatePlan != null && Number(userRatePlan.id) == Number(user.currentUserRatePlan.id)"
                            style="font-weight: bold;"
                          >
                            <td>{{ userRatePlan.ratePlan.code }}</td>
                            <td>{{ formatDateTime(userRatePlan.startTime) }}</td>
                            <td>{{ formatDateTime(userRatePlan.endTime) }}</td>
                            <td>{{ formatDateTime(userRatePlan.creationDate) }}</td>
                            <td><a @click="getDocument(userRatePlan.documentId)">{{ userRatePlan.documentId }}</a></td>
                          </tr>
                          <tr v-else>
                            <td>{{ userRatePlan.ratePlan.code }}</td>
                            <td>{{ formatDateTime(userRatePlan.startTime) }}</td>
                            <td>{{ formatDateTime(userRatePlan.endTime) }}</td>
                            <td>{{ formatDateTime(userRatePlan.creationDate) }}</td>
                            <td><a @click="getDocument(userRatePlan.documentId)">{{ userRatePlan.documentId }}</a></td>
                          </tr>
                        </template>
                        </tbody>
                        <tfoot>
                        <tr>
                          <th
                            colspan="100%"
                            style="background: antiquewhite; color: black !important;"
                          >
                            Активный выделен жирным шрифтом.
                          </th>
                        </tr>
                        </tfoot>
                      </v-table>
                    </v-card-text>
                  </v-col>
                  <!--                  <template v-if="user.currentSchool.parentId !== 1023">-->
                  <v-col>
                    <v-card-text
                      dense
                      style="padding: 0"
                    >
                      <v-table
                        class="green-header-table"
                        dense
                      >
                        <thead>
                        <tr>
                          <th colspan="100%">
                            Дополнение к приказу
                          </th>
                        </tr>
                        <tr>
                          <th style="background: antiquewhite; color: black !important;">
                            Вид дополнения
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            с
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            до
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Назначен
                          </th>
                          <th style="background: antiquewhite; color: black !important;">
                            Приказ
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template
                          v-for="(userDonation, i) in user.userDonations"
                          :key="user.id"
                        >
                          <template
                            v-if="![3, 6, 7].includes(userDonation.donationType.id) && ![3, 6, 7].includes(userDonation.donationTypeId)"
                          >
                            <tr
                              v-if="user.currentUserDonation != null && Number(userDonation.id) == Number(user.currentUserDonation.id)"
                              style="font-weight: bold;"
                            >
                              <td>{{ userDonation.donationType.name }}</td>
                              <td>{{ formatDateTime(userDonation.startTime) }}</td>
                              <td>{{ formatDateTime(userDonation.endTime) }}</td>
                              <td>{{ formatDateTime(userDonation.creationDate) }}</td>
                              <td>
                                <a @click="getDocument(userDonation.documentId)">{{ userDonation.documentId }}</a>
                              </td>
                            </tr>
                            <tr v-else>
                              <td>{{ userDonation.donationType.name }}</td>
                              <td>{{ formatDateTime(userDonation.startTime) }}</td>
                              <td>{{ formatDateTime(userDonation.endTime) }}</td>
                              <td>{{ formatDateTime(userDonation.creationDate) }}</td>
                              <td>
                                <a @click="getDocument(userDonation.documentId)">{{ userDonation.documentId }}</a>
                              </td>
                            </tr>
                          </template>
                        </template>
                        </tbody>
                        <tfoot>
                        <tr>
                          <th
                            colspan="100%"
                            style="background: antiquewhite; color: black !important;"
                          >
                            Активный выделен жирным шрифтом.
                          </th>
                        </tr>
                        </tfoot>
                      </v-table>
                    </v-card-text>

                    <v-card-text
                      dense
                      style="padding: 0"
                    >
                      <student-nutrition-settings
                        :style="{marginTop: '8px'}"
                        :user-id="+user.id"
                        :current-user-food-setting="user.currentFoodSetting"
                        @get-error="showError"
                        @get-success="showSuccess"
                      />
                    </v-card-text>
                  </v-col>
                  <!--                  </template>-->
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="interimCardDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="interimCardDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Выдача временной карты</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <h3>Причина выдачи временной карты</h3>
              <v-radio-group v-model="selectedInterimCardReason">
                <v-radio
                  value="1"
                  label="Испорчена"
                />
                <v-radio
                  value="2"
                  label="Утеряна"
                />
                <v-radio
                  value="3"
                  label="Забыл дома"
                />
                <v-radio
                  value="4"
                  label="Новичок"
                />
              </v-radio-group>
              <hr>
              <h3>Заказать новую карту?</h3>
              <v-radio-group v-model="selectedInterimCardDoOrder">
                <v-radio
                  value="1"
                  label="Да"
                />
                <v-radio
                  value="2"
                  label="Нет"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn
                class="justify-center"
                @click="setInterimCard()"
              >
                Выдать
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="user != null"
      v-model="activateCardDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="activateCardDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Активация карты</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <h3>Временная карта откреплена.</h3>
              <h3>Активировать карту?</h3>
              <v-radio-group v-model="selectedActivateCard">
                <template v-for="(card, i) in user.cards">
                  <v-radio
                    v-if="card.cardTypeId != 3"
                    :key="i"
                    :value="card.id"
                    :label="card.rfidHex.toUpperCase()"
                  />
                </template>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn @click="activateCard()">
                Да
              </v-btn>
              <v-btn @click="activateCardDialog = false">
                Нет
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="user != null && dorefineUserPlace != null"
      v-model="refineUserPlaceDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="refineUserPlaceDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Уточнить время пребывания в группе</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-row v-if="dorefineUserPlace.timestampFrom != null">
                <v-col>Изменить дату начала пребывания в группе на {{ dorefineUserPlace.timestampFrom }}</v-col>
                <v-col>
                  <v-btn @click="refineUserPlaces(dorefineUserPlace.id, 1, dorefineUserPlace.timestampFrom)">
                    Применить
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="dorefineUserPlace.timestampTo != null">
                <v-col>Изменить окончания пребывания в группе на {{ dorefineUserPlace.timestampTo }}</v-col>
                <v-btn @click="refineUserPlaces(dorefineUserPlace.id, 2, dorefineUserPlace.timestampTo)">
                  Применить
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="refineCardReaderSettingsDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="refineCardReaderSettingsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Настройка считывателя карт</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-form>
                <v-container>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="cardReaderServerHost"
                        label="Адрес считывателя:"
                        density="compact"
                      />
                    </v-col>
                    <v-col style="text-align: left;">
                      <v-btn
                        dense
                        @click="changeCardReaderSettings()"
                      >
                        Применить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addUserPhoneDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="addUserPhoneDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Согласие на обработку персональных данных</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="3"
            />
            <v-col
              class="text-left"
              cols="6"
            >
              <div id="userPronePrintableArea">
                <p>Я,______________________________________________________________________</p><br>
                Подписанием настоящего Заявления даю свое добровольное согласие:<br>
                1) Оператору ООО ""ГЛОЛАЙМ"", на обработку, в том числе автоматизированную, своих персональных данных и
                данных моего ребенка в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ "О персональных данных"
                (под обработкой персональных данных моего ребенка в названном Законе понимаются действия (операции) с
                персональными данными физических лиц, включая сбор, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), использование, распространение (в том числе передачу), обезличивание,
                блокирование и уничтожение). Указанные мною персональные данные предоставляются исключительно для
                использования в целях проекта "Школьная карта".<br>
                2) Комбинату Питания_______________________ и ГБОУ №_____________________, имеющим договорные отношения,
                привлекаемые ООО "ГЛОЛАЙМ" в процессы передачи настоящего Заявления в адрес ООО "ГЛОЛАЙМ", а также
                получения и выдачу идентификатора для Пользователя, с учетом обязательств названных лиц по обеспечению
                конфиденциальности и нераспространению ставшей им доступной информации."
                Согласие на обработку персональных данных, содержащееся в настоящем Заявлении, действует в соответствии
                с законодательством с даты его подписания и до окончания проекта, либо до момента окончания учебы
                школьника в школьном учреждении.<br>
                С условиями использования идентификатора ознакомлен (-на). Условия использования идентификатора
                (Школьная карта/Браслет), услуги sms-информирования мною получены.<br>
                Дата:_______________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Подпись__________________<br>
                <hr>
                * ООО "ГЛОЛАЙМ" - организация осуществляющая изготовление идентификатора и сопровождение операций с
                использованием идентификатора.
                * Школьная карта - идентификатор первоначально выпускается бесплатно, в случае утраты/поломки
                идентификатора стоимость перевыпуска производится в соответствии с тарифами ООО "ГЛОЛАЙМ". На 01.09.2015
                стоимость перевыпуска карты составляет 100 рублей.
              </div>
              <v-form>
                <v-container>
                  <v-row dense>
                    <v-col style="text-align: left;">
                      <v-btn
                        dense
                        @click="printUserPhoneDialog()"
                      >
                        Печать
                      </v-btn>
                    </v-col>
                    <v-col style="text-align: left;">
                      <v-btn
                        dense
                        @click="setTariffPlans()"
                      >
                        Подписать
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isRKModalOpen"
      max-width="700px"
    >
      <v-card>
        <v-card-title :style="{width: '100%', display: 'flex', justifyContent: 'center'}">
          <span class="text-h5 align-center">Информация в РК</span>
        </v-card-title>
        <template v-if="!isRKModalLoading">
          <v-card-text>
            <div
              class="wrapper"
              :style="{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', textAlign: 'center'}"
            >
              <p :style="{marginTop: '16px', marginBottom: '8px'}">
                {{ checkRKModalData.text }}
              </p>
              <p :style="{marginBottom: '0'}">
                {{ checkRKModalData.masterUuid }}
              </p>
            </div>
          </v-card-text>
          <v-card-actions>
            <div
              class="wrapper"
              :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
            >
              <v-btn
                color="lighten-2"
                variant="text"
                :disabled="isRKModalLoading"
                @click="closeRKModal"
              >
                Закрыть
              </v-btn>
            </div>
          </v-card-actions>
        </template>
        <template v-else>
          <div
            class="wrapper"
            :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}"
          >
            <v-col class="text-center">
              <v-progress-circular
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </div>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addUserPlaceDialog"
      persistent
      width="700"
    >
      <v-card>
        <v-card-text>
          <div
            class="wrapper"
            :style="{display: 'flex', justifyContent: 'center'}"
          >
            <span class="text-h5 align-center">Перевести в группу</span>
          </div>
        </v-card-text>
        <template v-if="!addUserPlaceDialogLoading">
          <v-card-text>
            <div
              class="wrapper"
              :style="{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', textAlign: 'center'}"
            >
              <v-select
                v-model="updateType"
                label="Тип"
                :items="updateTypeOptions"
                item-title="name"
                item-value="value"
                @update:model-value="changeUpdateTypeHandler"
              />
              <template v-if="updateType === 1">
                <p style="text-align: start">Укажите лицевой счет, с которым происходит совпадение:</p>
                <v-text-field
                  v-model="anotherPaymentKey"
                  :placeholder="'Лицевой счет'"
                />
              </template>
              <template v-else>
                <p style="text-align: start">Укажите причину:</p>
                <v-text-field
                  v-model="comment"
                  :placeholder="'Причина'"
                />
              </template>
            </div>
          </v-card-text>
          <v-card-actions>
            <div
              class="wrapper"
              :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
            >
              <v-btn
                :disabled="addUserPlaceDialogLoading"
                @click="addUserPlaceBySeksh"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="lighten-2"
                :disabled="addUserPlaceDialogLoading"
                @click="() => {addUserPlaceDialog = false
                comment= ''
                anotherPaymentKey=''}"
              >
                Закрыть
              </v-btn>
            </div>
          </v-card-actions>
        </template>
        <template v-else>
          <div
            class="wrapper"
            :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}"
          >
            <v-col class="text-center">
              <v-progress-circular
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import QrcodeVue from 'qrcode.vue'
import * as html2canvas from 'html2canvas'
import { mapActions, mapGetters } from 'vuex'
import { UserType, userTypes } from '@/app/types/vueEnums'
import moment from 'moment/moment'
import { AxiosError } from 'axios'
import { parseAxiosError } from '@/shared/lib/api'

import { activateCesCardPost, blockCesCardPost } from '@/shared/api/cescards/api-types'
import { activateCesCard, blockCesCard } from '@/shared/api/cescards/api-requests'
import { PlaceFindByParentIdPost } from '@/shared/api/places/api-types'
import { placeFindByParentIdWithName } from '@/shared/api/places/api-requests'
import {
  UserPlaceChangeBySekshPost,
  UserPlaceChangePost,
  UserPlaceRefinePost
} from '@/shared/api/userPlaceList/api-types'
import { changeUserPlace, changeUserPlaceBySeksh, refineUserPlace } from '@/shared/api/userPlaceList/api-requests'
import { AccountBuffetLimitSetPost, GetDeponentPost } from '@/shared/api/accounts/api-types'
import { accountBuffetLimitSet, getDeponent } from '@/shared/api/accounts/api-requests'
import { DemandForUpdateEmailPost } from '@/shared/api/temporaryEmails/api-types'
import { demandForUpdateEmail } from '@/shared/api/temporaryEmails/api-requests'
import { FreeInterimCardsFindPost } from '@/shared/api/interimCards/api-types'
import { freeInterimCardsFind } from '@/shared/api/interimCards/api-requests'
import { findByUser } from '@/shared/api/placeSettings/api-requests'
import { UserCardStatusUpdatePost, UserNewCardAddPost } from '@/shared/api/cards/api-types'
import { addCardForUser, updateCardStatus } from '@/shared/api/cards/api-requests'
import { getCard as getCardFromCardReader } from '@/shared/api/cardReader/api-requests'
import {
  UserInterimCardBlockPost,
  UserInterimCardRenewalPost,
  UserInterimCardSetPost
} from '@/shared/api/userInterimCards/api-types'
import {
  blockUserInterimCard,
  renewalUserInterimCard,
  setUserInterimCard
} from '@/shared/api/userInterimCards/api-requests'
import {
  UserDetailedFindPost,
  UserSetPhoneAndTariffPlansPost,
  UserUpdateDataPost,
  UserUpdateTypePost
} from '@/shared/api/user/api-types'
import {
  getDetailedPerson,
  setPersonUpdateTimestamp,
  setPhoneAndTariffPlans,
  updatePersonData,
  updatePersonFullName,
  updatePersonType,
  userFindRKS
} from '@/shared/api/user/api-requests'

import Swal from 'sweetalert2'
import StudentNutritionSettings from '@/views/settings/StudentNutritionSettings.vue'
import { th } from 'vuetify/locale'

type BaseData = {
  anotherPaymentKey: string;
  comment: string;
  updateType: number;
  updateTypeOptions: { name: string; value: number }[]
  addUserPlaceDialog: boolean;
  addUserPlaceDialogLoading: boolean;
  tariffPlanPhone: any;
  allowAddCardNumber: boolean;
  menu: boolean;
  classTimestamp: any;
  cardReaderServerHost: string;
  refineCardReaderSettingsDialog: boolean;
  addUserPhoneDialog: boolean;
  changedLastName: any;
  changedFirstName: any;
  changedMiddleName: any;
  hideFio: boolean;
  selectedClass: any;
  classList: any;
  dorefineUserPlace: any;
  refineUserPlaceDialog: boolean;
  deponents: any;
  deponentVisible: boolean;
  mobileTariffPlan: boolean;
  selectedTariffPlan: any;
  searchTerm: string;
  userAllowedPlacesCopy: any;
  userAllowedPlacesList: any;
  selectedUserAllowedPlace: any;
  newCard: any;
  selectedNewCardType: any;
  cardActiveTypes: any;
  useLimit: any;
  accountLimit: number;
  newEmail: any;
  selectedActivateCard: any;
  activateCardDialog: boolean;
  selectedInterimCardReason: any;
  selectedInterimCardDoOrder: any;
  interimCardDialog: boolean;
  userTypeBlockedAccountTypes: any;
  selectedInterimCard: any;
  interimCards: any;
  selectedSpravkaMonthCount: any;
  spravkaMonthCount: any;
  selectedSpravkaMonth: any;
  spravkaMonthes: any;
  selectedSpravkaYear: any;
  spravkaYears: any;
  selectedSpravkaType: any;
  spravkaTypes: any;
  townCard: any;
  qrcode: any;
  realLastName: any;
  realFirstName: any;
  realMiddleName: any;
  userTypes: any;
  selectedUserType: any;
  snack: boolean;
  snackColor: string;
  snackText: string;
  isLoading: boolean;
  user: any;
  accounts: any;
  interimCardDescriptionTypes: any;
  userCards: any;
  rules: any;
  isRKModalOpen: boolean;
  isRKModalLoading: boolean;
  checkRKModalData: any;
}
export default defineComponent({
  name: 'UserEdit',
  components: {
    StudentNutritionSettings,
    QrcodeVue
    /*,
    SimpleDatepicker
    */
  },
  props: {
    id: {
      required: true
    }
  },
  data (): BaseData {
    return {
      anotherPaymentKey: '',
      comment: '',
      updateType: 1,
      updateTypeOptions: [
        {
          name: 'Совпадает с лицевым счетом',
          value: 1
        },
        {
          name: 'Другое',
          value: 2
        }
      ],
      addUserPlaceDialog: false,
      addUserPlaceDialogLoading: false,
      rules: {
        counter: (value: any) => value?.length <= 10 || 'Максимально 10 цифр'
      },
      tariffPlanPhone: null,
      allowAddCardNumber: false,
      menu: false,
      classTimestamp: null,
      cardReaderServerHost: '',
      refineCardReaderSettingsDialog: false,
      addUserPhoneDialog: false,
      changedLastName: null,
      changedFirstName: null,
      changedMiddleName: null,
      hideFio: false,
      selectedClass: null,
      classList: [],
      dorefineUserPlace: null,
      refineUserPlaceDialog: false,
      deponents: [],
      deponentVisible: false,
      mobileTariffPlan: false,
      selectedTariffPlan: null,
      searchTerm: '',
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: null,
      newCard: '',
      selectedNewCardType: 0,
      cardActiveTypes: [
        { id: 0, name: 'Активна' },
        { id: 40, name: 'Заблокирована' }
      ],
      useLimit: false,
      accountLimit: 0,
      newEmail: null,
      selectedActivateCard: null,
      activateCardDialog: false,
      selectedInterimCardReason: '1',
      selectedInterimCardDoOrder: '1',
      interimCardDialog: false,
      userTypeBlockedAccountTypes: [
        {
          userType: 7,
          blockedAccountTypes: [
            { id: 4 }
          ]
        },
        {
          userType: 26,
          blockedAccountTypes: [
            { id: 3 },
            { id: 4 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12 },
            { id: 13 },
            { id: 14 },
            { id: 15 }
          ]
        },
        {
          userType: 66,
          blockedAccountTypes: [
            { id: 3 },
            { id: 4 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12 },
            { id: 13 },
            { id: 14 },
            { id: 15 }
          ]
        }
      ],
      selectedInterimCard: null,
      interimCards: [],
      selectedSpravkaMonthCount: 1,
      spravkaMonthCount: [
        { id: 1, name: '1 месяц' },
        { id: 2, name: '2 месяца' },
        { id: 3, name: '3 месяца' },
        { id: 6, name: '6 месяцев' },
        { id: 12, name: '12 месяцев' }
      ],
      selectedSpravkaMonth: 1,
      spravkaMonthes: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' }
      ],
      selectedSpravkaYear: null,
      spravkaYears: [],
      selectedSpravkaType: 1,
      spravkaTypes: [
        { id: 1, name: 'сводная' },
        { id: 2, name: 'детальная' }
      ],
      townCard: 'false',
      qrcode: '1',
      realLastName: null,
      realFirstName: null,
      realMiddleName: null,
      userTypes: [],
      selectedUserType: null,
      snack: false,
      snackColor: '',
      snackText: '',
      isLoading: false,
      user: null,
      accounts: [],
      interimCardDescriptionTypes: [
        { id: 0, value: 'Неизвестно' },
        { id: 1, value: 'Испорчена' },
        { id: 2, value: 'Утеряна' },
        { id: 3, value: 'Забыл дома' },
        { id: 4, value: 'Новичок' }
      ],
      userCards: [],
      isRKModalOpen: false,
      checkRKModalData: {
        text: '',
        masterUuid: ''
      },
      isRKModalLoading: false
    }
  },
  beforeMount () {
    console.log(this.owner)
    console.log(this.user)
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    changeUpdateTypeHandler () {
      if (this.updateType === 1) {
        this.comment = ''
      } else {
        this.anotherPaymentKey = ''
      }
    },
    closeRKModal () {
      this.isRKModalOpen = false
      this.isRKModalLoading = false
    },
    async clickCheckRKHandler () {
      this.isRKModalOpen = true
      this.isRKModalLoading = true
      try {
        if (this.user.regId) {
          const res: any = await userFindRKS(
            {
              regId: this.user.regId
            }
          )
          if (res.length === 0) {
            this.checkRKModalData.text = 'По данному РЕГИД информация в РК не найдена!'
          } else if (res.length > 0) {
            if (res[0].master_uuid) {
              this.checkRKModalData.text = 'По данным РК данная учетная запись является дубликатом записи с РЕГИД:'
              this.checkRKModalData.masterUuid = `${res[0].master_uuid}`
            } else {
              this.checkRKModalData.text = 'По данным РК данная учетная запись является основной!'
              this.checkRKModalData.masterUuid = ''
            }
          }
        } else {
          this.closeRKModal()
          this.setSnackbar({
            showing: true,
            text: 'Отсутствует РЕГИД!',
            timeout: 3000
          })
        }
        this.isRKModalLoading = false
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
          console.log(err.response.data)
        }
        this.closeRKModal()
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async loadUserAllowedPlaces () {
      try {
        const result = await this.userAllowedPlaceAll()
        this.userAllowedPlacesList = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlacesList.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlacesList.filter((obj: any) => {
          return obj.removed == null
        })
      }
    },
    async findPlacesByParentId () {
      try {
        this.classList = []
        if (this.selectedUserAllowedPlace !== null) {
          const findPlace: PlaceFindByParentIdPost = {
            parentId: this.selectedUserAllowedPlace
          }
          const result = await placeFindByParentIdWithName(findPlace)
          this.classList = JSON.parse(JSON.stringify(result))
          this.classList.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlacesList.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlacesList.filter((obj: any) => {
          return obj.removed == null
        })
      }
    },
    placeRefine (userPlace: any) {
      this.dorefineUserPlace = {
        id: userPlace.id,
        timestampFrom: userPlace.timestampFrom,
        timestampTo: userPlace.timestampTo
      }
      if (this.dorefineUserPlace.timestampFrom != null) {
        this.dorefineUserPlace.timestampFrom = moment(this.dorefineUserPlace.timestampFrom).format('DD.MM.YYYY')
      }
      if (this.dorefineUserPlace.timestampTo != null) {
        this.dorefineUserPlace.timestampTo = moment(this.dorefineUserPlace.timestampTo).add(1, 'days').add(-1, 'seconds').format('DD.MM.YYYY HH:mm:ss')
      }
      this.refineUserPlaceDialog = true
    },
    getAccess (rights: any) {
      let result = false
      rights.forEach((element: any) => {
        if (Number(element) === Number(this.owner.person.userType) || Number(element) === UserType.ForAll) result = true
      })
      return result
    },
    isAccessToUserType (userTypeId: any) {
      if (Number(this.owner.person.userType) !== 7 && Number(this.owner.person.userType) !== 10 && Number(this.owner.person.userType) !== 4) {
        return false
      }
      if (Number(this.owner.person.userType) === 7) {
        if ((Number(userTypeId) !== 2) && (Number(userTypeId) !== 3) && (Number(userTypeId) !== 25)) {
          return false
        }
      }
      return true
    },
    async setTariffPlans () {
      this.addUserPhoneDialog = false
      this.isLoading = true
      try {
        const userSetPhoneAndTariffPlansPost: UserSetPhoneAndTariffPlansPost = {
          userId: this.user.id,
          phone: this.tariffPlanPhone
        }
        const result = await setPhoneAndTariffPlans(userSetPhoneAndTariffPlansPost)
        if (result === true) {
          Swal.fire('Успешно сохранено!', '', 'success')
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error))
      } finally {
        this.isLoading = false
      }
    },
    showError (error: any) {
      this.setSnackbar({
        showing: true,
        text: error.text,
        timeout: 3000
      })
    },
    showSuccess (success: any) {
      this.setSnackbar({
        showing: true,
        text: success.text,
        timeout: 3000
      })
    },
    async activateCard () {
      await this.activateUserCard(this.selectedActivateCard)
      this.activateCardDialog = false
    },
    async activateUserCard (cardId: number) {
      try {
        const userCardStatusUpdatePost: UserCardStatusUpdatePost = {
          id: cardId,
          userId: this.user.id,
          flag: 0
        }
        const result = await updateCardStatus(userCardStatusUpdatePost)
        // const httpResult = JSON.parse(JSON.stringify(result))
        this.getDetailedUser()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async blockUserCard (cardId: number) {
      try {
        const userCardStatusUpdatePost: UserCardStatusUpdatePost = {
          id: cardId,
          userId: this.user.id,
          flag: 40
        }
        const result = await updateCardStatus(userCardStatusUpdatePost)
        // const httpResult = JSON.parse(JSON.stringify(result))
        this.getDetailedUser()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async activateCesCard (userCard: any) {
      try {
        const cesCardActivatePost: activateCesCardPost = {
          userId: this.user.id,
          cesUserCardId: userCard.id
        }
        const result = await activateCesCard(cesCardActivatePost)
        if (result === true) {
          Swal.fire('Карта успешно активирована, и появится в списке карт через некоторое время.', '', 'success')
          this.getDetailedUser()
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      }
    },
    async blockCesCard (userCard: any) {
      try {
        const cesCardBlockPost: blockCesCardPost = {
          id: userCard.id,
          userId: this.user.id,
          cardId: userCard.card.id
        }
        const result = await blockCesCard(cesCardBlockPost)
        Swal.fire('Успешно сохранено!', '', 'success')
        this.getDetailedUser()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      }
    },
    async addNewCard () {
      try {
        const userNewCardAddPost: UserNewCardAddPost = {
          userId: this.user.id,
          cardNumber: this.newCard,
          flag: this.selectedNewCardType,
          cardTypeId: 1
        }
        const result = await addCardForUser(userNewCardAddPost)
        // const httpResult = JSON.parse(JSON.stringify(result))
        this.getDetailedUser()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async readCard () {
      try {
        this.newCard = null
        const result = await getCardFromCardReader()
        const card = JSON.parse(JSON.stringify(result))
        if (card === null) {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось прочитать данные карты'
          })
          return
        }
        if (card.rfid === null) {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось прочитать данные карты'
          })
          return
        }
        this.newCard = card.rfid
      } catch (error: any) {
        let errorText = 'Ошибка. Проверьте настройки считывателя и повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async setInterimCard () {
      try {
        const userInterimCardSetPost: UserInterimCardSetPost = {
          userId: this.user.id,
          interimCardId: this.selectedInterimCard,
          doOrder: this.selectedInterimCardDoOrder,
          interimCardDescriptionTypeId: this.selectedInterimCardReason
        }
        const result = await setUserInterimCard(userInterimCardSetPost)
        // const httpResult = JSON.parse(JSON.stringify(result))
        this.getDetailedUser()
        this.interimCardDialog = false
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async blockInterimCard () {
      try {
        const attachedInterimCard = this.user.userInterimCards.find((element: any) => (element.attached !== null) && (Number(element.attached) === 1))
        const userInterimCardBlockPost: UserInterimCardBlockPost = {
          id: attachedInterimCard.id,
          userId: this.user.id,
          interimCardId: this.selectedInterimCard
        }
        const result = await blockUserInterimCard(userInterimCardBlockPost)
        // const httpResult = JSON.parse(JSON.stringify(result))
        this.getInterimCards()
        this.getDetailedUser()
        this.activateCardDialog = true
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async renewalInterimCard () {
      try {
        const attachedInterimCard = this.user.userInterimCards.find((element: any) => (element.attached !== null) && (Number(element.attached) === 1))
        const userInterimCardRenewalPost: UserInterimCardRenewalPost = {
          id: attachedInterimCard.id,
          userId: this.user.id
        }
        const result = await renewalUserInterimCard(userInterimCardRenewalPost)
        // const httpResult = JSON.parse(JSON.stringify(result))
        this.getInterimCards()
        this.getDetailedUser()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async getInterimCards () {
      try {
        const interimCardFindPost: FreeInterimCardsFindPost = {}
        const result = await freeInterimCardsFind(interimCardFindPost)
        this.interimCards = JSON.parse(JSON.stringify(result))
        if (this.interimCards.length > 0) {
          this.selectedInterimCard = this.interimCards[0].id
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async getPlaceSettingsByUser () {
      try {
        const result = await findByUser()
        const userPlaceSettings = JSON.parse(JSON.stringify(result))
        if (userPlaceSettings.length > 0) {
          userPlaceSettings.forEach((element: any) => {
            if (Number(element.placeSettingTypeId) === 28) {
              if (element.value === 'on') {
                this.allowAddCardNumber = true
              }
            }
          })
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async getDetailedUser () {
      try {
        this.isLoading = true
        this.user = null
        const userDetailedFindPost: UserDetailedFindPost = {
          id: Number(this.id)
        }
        const result = await getDetailedPerson(userDetailedFindPost)
        console.log(result)
        this.user = JSON.parse(JSON.stringify(result))
        if (this.user !== null) {
          if (this.user.phone !== null) {
            this.tariffPlanPhone = this.user.phone.replace('+7', '')
          }
          const currentTariffPlan = this.getCurrentTariifPlan()
          if (currentTariffPlan !== null) {
            this.selectedTariffPlan = String(currentTariffPlan)
            if (currentTariffPlan === 14) {
              this.mobileTariffPlan = true
            }
          }
          if (Number(this.owner.person.userType) === 7) {
            if (Number(this.user.typeId) === 2 || Number(this.user.typeId) === 3 || Number(this.user.typeId) === 25) {
              this.userTypes = [
                { id: 2, name: 'Ученик' },
                { id: 3, name: 'Учитель' },
                { id: 25, name: 'Компенсация' }
              ]
            }
          }

          if (Number(this.owner.person.userType) === 10) {
            this.userTypes = [
              { id: 2, name: 'Ученик' },
              { id: 3, name: 'Учитель' },
              { id: 4, name: 'Администратор' },
              { id: 6, name: 'Повар' },
              { id: 7, name: 'Локальный администратор' },
              { id: 8, name: 'Инкассатор' },
              { id: 10, name: 'Оператор Глолайм' },
              { id: 14, name: 'Директор комбината' },
              { id: 15, name: 'Учитель группы продленного дня' },
              { id: 16, name: 'Бухгалтер комбината' },
              { id: 20, name: 'Бухгалтер школы' },
              { id: 25, name: 'Компенсация' },
              { id: 57, name: 'Системный администратор школы' }
            ]
          }

          if (this.user.cards.length > 0) {
            const tempUserCard = this.user.cards.find((element: any) => Number(element.cardTypeId) !== 3)
            if (tempUserCard !== undefined) {
              this.selectedActivateCard = tempUserCard.id
            }
          }

          if (this.user.currentSchool !== null) {
            if (this.user.currentSchool.customerPlaces[0].placeUid !== null) {
              this.townCard = 'true'
            }
          }

          if (this.user.userRestrictions.length > 0) {
            const userRestriction = this.user.userRestrictions.find((element: any) => Number(element.restrictionTypeId) === 2)
            if (userRestriction !== undefined) {
              if (userRestriction.value === 'on') {
                this.hideFio = true
              }
            }
          }

          if (this.user.userAliases.length > 0) {
            const userAlias = this.user.userAliases[0]
            const aliases = userAlias.alias.split(' ')

            this.changedLastName = aliases[0]
            if (aliases.length > 1) {
              this.changedFirstName = aliases[1]
            }
            if (aliases.length > 2) {
              for (let i = 2; i < aliases.length; i++) {
                if (this.changedMiddleName === null) {
                  this.changedMiddleName = aliases[i]
                } else {
                  this.changedMiddleName = this.changedMiddleName + aliases[i]
                }
              }
            }
          } else {
            if (this.townCard === 'true') {
              let userPaymentKey = this.user.userPaymentKeys.find((element: any) => Number(element.paymentKeyTypeId) === 3)
              if (userPaymentKey !== undefined) {
                this.changedLastName = userPaymentKey.paymentKey
              } else {
                userPaymentKey = this.user.userPaymentKeys.find((element: any) => Number(element.paymentKeyTypeId) === 1)
                if (userPaymentKey !== undefined) {
                  this.changedLastName = userPaymentKey.paymentKey
                }
              }
            } else {
              const userPaymentKey = this.user.userPaymentKeys.find((element: any) => Number(element.paymentKeyTypeId) === 1)
              if (userPaymentKey !== undefined) {
                this.changedLastName = userPaymentKey.paymentKey
              }
            }
            this.changedFirstName = this.user.firstName
            this.changedMiddleName = this.user.middleName
          }

          this.accounts = JSON.parse(JSON.stringify(this.user.accounts))
          const buffetAccount = this.accounts.find((element: any) => Number(element.accountTypeId) === 2)
          if (buffetAccount !== undefined) {
            this.accountLimit = Number(buffetAccount.limitDaily)
            if (this.accountLimit !== null) {
              if (Number(this.accountLimit) > 0) {
                this.useLimit = true
              }
            }
          }

          this.accounts.sort((a: any, b: any) => (Number(a.accountTypeId) > Number(b.accountTypeId)) ? 1 : ((Number(b.name) > Number(a.name)) ? -1 : 0))
          this.userCards = JSON.parse(JSON.stringify(this.user.cards))
          this.userCards.sort((a: any, b: any) => (Number(a.id) > Number(b.id)) ? 1 : ((Number(b.id) > Number(a.id)) ? -1 : 0))
          this.selectedUserType = Number(this.user.typeId)
          this.realLastName = this.user.lastName
          this.realFirstName = this.user.firstName
          this.realMiddleName = this.user.middleName
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async setUpdateTimestamp () {
      this.isLoading = true
      try {
        const userSetUpdateTimestampPost = {
          userId: this.user.id
        }
        const result = await setPersonUpdateTimestamp(userSetUpdateTimestampPost)
        if (result === true) {
          Swal.fire('Успешно сохранено!', '', 'success')
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error))
      } finally {
        this.isLoading = false
      }
    },
    async setBuffetLimit () {
      this.isLoading = true
      try {
        if (this.useLimit === false) {
          this.accountLimit = 0
        }
        const accountBuffetLimitSetPost: AccountBuffetLimitSetPost = {
          userId: this.user.id,
          sum: this.accountLimit
        }
        const result = await accountBuffetLimitSet(accountBuffetLimitSetPost)
        if (result === true) {
          Swal.fire('Успешно сохранено!', '', 'success')
          this.getDetailedUser()
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      } finally {
        this.isLoading = false
      }
    },
    async saveUser () {
      this.isLoading = true
      try {
        const alias = this.changedLastName + ' ' + this.changedFirstName + ' ' + this.changedMiddleName
        const userUpdateFullNamePost = {
          userId: this.user.id,
          lastName: this.user.lastName,
          firstName: this.user.firstName,
          middleName: this.user.middleName,
          hideFullName: this.hideFio,
          alias: alias
        }
        const result = await updatePersonFullName(userUpdateFullNamePost)
        if (result === true) {
          this.realLastName = this.user.lastName
          this.realFirstName = this.user.firstName
          this.realMiddleName = this.user.middleName
          Swal.fire('Успешно сохранено!', '', 'success')
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      } finally {
        this.isLoading = false
      }
    },
    async saveUserData () {
      this.isLoading = true
      try {
        let pin = null
        if (this.user.pin !== null && this.user.pin.length > 0) {
          pin = this.user.pin
        }
        let phone = null
        if (this.user.phone !== null && this.user.phone.length > 0) {
          phone = this.user.phone
        }
        let login = null
        if (this.user.login !== null && this.user.login.length > 0) {
          login = this.user.login
        }
        let password = null
        if (this.user.password !== undefined && this.user.password !== null && this.user.password.length > 0) {
          password = this.user.password
        }
        let typeId = null
        if (this.user.selectedUserType !== null) {
          typeId = this.selectedUserType
        }
        const userUpdateDataPost: UserUpdateDataPost = {
          userId: this.user.id,
          lastName: this.user.lastName,
          firstName: this.user.firstName,
          middleName: this.user.middleName,
          description: this.user.description,
          typeId: typeId,
          phone: phone,
          pin: pin,
          login: login,
          password: password
        }
        const result = await updatePersonData(userUpdateDataPost)
        if (result === true) {
          this.realLastName = this.user.lastName
          this.realFirstName = this.user.firstName
          this.realMiddleName = this.user.middleName
          Swal.fire('Успешно сохранено!', '', 'success')
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      } finally {
        this.isLoading = false
      }
    },
    async updateUserType () {
      this.isLoading = true
      try {
        const userUpdateTypePost: UserUpdateTypePost = {
          userId: this.user.id,
          typeId: this.selectedUserType
        }
        const result = await updatePersonType(userUpdateTypePost)
        if (result === true) {
          Swal.fire('Успешно сохранено!', '', 'success')
          this.user.typeId = this.selectedUserType
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      } finally {
        this.isLoading = false
      }
    },
    async addUserPlace () {
      this.isLoading = true
      try {
        const userPlaceChangePost: UserPlaceChangePost = {
          userId: this.user.id,
          placeId: this.selectedClass,
          timestampFrom: moment(this.classTimestamp).format('YYYY-MM-DD HH:mm:ss')
        }
        if (this.user?.regId) {
          this.addUserPlaceDialog = true
        } else {
          const result = await changeUserPlace(userPlaceChangePost)
          this.getDetailedUser()
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async addUserPlaceBySeksh () {
      this.isLoading = true
      this.addUserPlaceDialogLoading = true
      try {
        const userPlaceChangeBySekshPost: UserPlaceChangeBySekshPost = {
          userId: this.user.id,
          placeId: this.selectedClass,
          updateType: this.updateType,
          comment: this.updateType === 1 ? '' : this.comment,
          anotherPaymentKey: this.updateType === 1 ? this.anotherPaymentKey : ''
        }
        const result = await changeUserPlaceBySeksh(userPlaceChangeBySekshPost)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.addUserPlaceDialogLoading = false
        this.addUserPlaceDialog = false
        this.isLoading = false
        this.getDetailedUser()
      }
    },
    async refineUserPlaces (userPlaceId: any, changeTypeId: any, timestamp: any) {
      this.isLoading = true
      try {
        const userPlaceRefinePost: UserPlaceRefinePost = {
          userId: this.user.id,
          userPlaceId: userPlaceId,
          typeId: changeTypeId,
          timestamp: timestamp
        }
        const result = await refineUserPlace(userPlaceRefinePost)
        if (result === true) {
          Swal.fire('Успешно сохранено!', '', 'success')
          this.getDetailedUser()
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      } finally {
        this.refineUserPlaceDialog = false
        this.isLoading = false
      }
    },
    async demandForUpdateEmail () {
      this.isLoading = true
      try {
        const demandForUpdateEmailPost: DemandForUpdateEmailPost = {
          userId: this.user.id,
          email: this.newEmail
        }
        const result = await demandForUpdateEmail(demandForUpdateEmailPost)
        if (result !== null) {
          Swal.fire('Запрос на изменение Email успешно отправлен.', '', 'success')
          this.newEmail = null
        } else {
          Swal.fire({
            title: 'Ошибка',
            html: 'Не удалось сохранить данные'
          })
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Не удалось сохранить изменения.',
          timeout: 3000
        })
        return Promise.reject(parseAxiosError(error as AxiosError))
      } finally {
        this.isLoading = false
      }
    },
    async getDeponent (accountId: any) {
      try {
        const getDeponentPost: GetDeponentPost = {
          accountId: accountId
        }
        const result = await getDeponent(getDeponentPost)
        this.deponents = JSON.parse(JSON.stringify(result))
        this.deponentVisible = true
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    formatDateTime (dateTime: any) {
      if (dateTime === null) {
        return null
      }
      if (dateTime.length === 0) {
        return null
      }
      return moment(dateTime).format('DD.MM.YYYY HH:mm:ss')
    },
    interimCardDescriptionTypeDate (interimCardDescriptionType: any) {
      const interimCardDescription = this.interimCardDescriptionTypes.find((el: any) => {
        return Number(el.id) === Number(interimCardDescriptionType)
      })
      if (interimCardDescription === undefined) {
        return null
      }
      return interimCardDescription.value
    },
    getUpdateDiffStr (diffHour: any) {
      if (diffHour > 23) {
        return 'более дня назад'
      }
      return diffHour + ' ч.'
    },
    getFlagStr (flag: any) {
      if (flag === null) {
        return 'Активна'
      }
      if (flag === 0) {
        return 'Активна'
      }
      return 'Заблокирована'
    },
    getUserPlace (userPlace: any) {
      if (userPlace.place === undefined) {
        return null
      }
      if (userPlace.place === null) {
        return null
      }
      if (userPlace.place.parentPlace === undefined) {
        return userPlace.place.name
      }
      if (userPlace.parentPlace === null) {
        return userPlace.place.name
      }
      return userPlace.place.parentPlace.name + ' - ' + userPlace.place.name
    },
    checkChangeFullName () {
      if (this.realLastName !== this.user.lastName) {
        return true
      }
      if (this.realFirstName !== this.user.firstName) {
        return true
      }
      if (this.realMiddleName !== this.user.middleName) {
        return true
      }
      return false
    },
    addUserPhoneNumber () {
      this.addUserPhoneDialog = true
    },
    printUserPhoneDialog () {
      const printContent = document.getElementById('userPronePrintableArea')
      const WinPrint = window.open('')
      if (WinPrint && printContent) {
        WinPrint.document.write(`
          <html>
            <head>
              <title></title>
            </head>
            <body>
            <div class="bodyprint">
            <input class="print-button" type="button" value="Печать" onclick="print()">
              ${printContent.innerHTML}
            </div>
            </body>
          </html>
        `)
        const link = WinPrint.document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        link.setAttribute('href', '/css/print.css')
        WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
        WinPrint.focus()
      }
    },
    saveCard () {
      html2canvas.default(this.$refs.print as any, {})
        .then((canvas: any) => {
          const imageUrl = canvas.toDataURL('image/png') // Преобразование холста в формат изображения base64
          this.saveAs(canvas.toDataURL(), 'business-card.png')
        })
        .catch(() => {
          alert('Не удалось преобразовать изображение')
        })
    },
    saveAs (uri: any, filename: any) {
      const link = document.createElement('a')
      if (typeof link.download === 'string') {
        link.href = uri
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        window.open(uri)
      }
    },
    printCard () {
      html2canvas.default(this.$refs.print as any, {})
        .then((canvas: any) => {
          const nWindow = window.open('')
          if (nWindow !== null) {
            nWindow.document.body.appendChild(canvas)
            nWindow.focus()
            nWindow.print()
          }
        })
        .catch(() => {
          alert('Не удалось преобразовать изображение')
        })
    },
    isActiveInterimCard (userInterimCards: any) {
      const userInterimCard = userInterimCards.find((element: any) => Number(element.attached) === 1)
      if (userInterimCard !== undefined) {
        return true
      }
      return false
    },
    getOldSite () {
      const oldSite = localStorage.getItem('oldSite')
      if ((Number(this.owner.person.userType) === 4) || (Number(this.owner.person.userType) === 10)) {
        window.open(`${oldSite}/users/edit/old/?userid=` + this.id, '_blank')
      }
      if ((Number(this.owner.person.userType) === 26) || (Number(this.owner.person.userType) === 66)) {
        window.open(`${oldSite}/rosohrana/users/list/edit/old/?userid=` + this.id, '_blank')
      }
    },
    getSpravka () {
      const oldSite = localStorage.getItem('oldSite')
      window.open(`${oldSite}/reports/food/newreports/spravka/?userid=` + this.id + '&spravkamonthcount=' + this.selectedSpravkaMonthCount +
        '&spmonth=' + this.selectedSpravkaMonth + '&spyear=' + this.selectedSpravkaYear + '&spravkatype=' + this.selectedSpravkaType, '_blank')
    },
    getDocument (documentId: any) {
      const oldSite = localStorage.getItem('oldSite')
      window.open(`${oldSite}/ladmin/documents/orders/list/?documentid=` + documentId, '_blank')
    },
    getPortal () {
      const oldSite = localStorage.getItem('oldSite')
      window.open(`${oldSite}/api/netrika/userinfo/?` + this.user.n3kToken, '_blank')
    },
    checkUserTypeBlockedAccountTypes (accountType: any) {
      const blockedAccountTypes = this.userTypeBlockedAccountTypes.find((element: any) => Number(element.userType) === Number(this.owner.person.userType))
      if (blockedAccountTypes !== undefined) {
        const blocked = blockedAccountTypes.blockedAccountTypes.find((element: any) => Number(element.id) === Number(accountType))
        if (blocked !== undefined) {
          return false
        }
      }
      return true
    },
    getFormatedUserPaymentKey (user: any, paymentKeyType: any) {
      let paymentKey = this.getUserPaymentKey(user, paymentKeyType)
      if (paymentKey === null) {
        return null
      }
      if (paymentKeyType === 1) {
        paymentKey = paymentKey.substring(0, 4) + ' ' + paymentKey.substring(4, 8)
      }
      return paymentKey
    },
    getUserPaymentKey (user: any, paymentKeyType: any) {
      let userPaymentKey = user.userPaymentKeys.find((element: any) => Number(element.paymentKeyTypeId) === Number(paymentKeyType))
      if (userPaymentKey === undefined) {
        if (Number(paymentKeyType) === 3) {
          userPaymentKey = user.userPaymentKeys.find((element: any) => Number(element.paymentKeyTypeId) === 2)
        }
        if (userPaymentKey === undefined) {
          return null
        }
      }
      let paymentKey = userPaymentKey.paymentKey
      const paymentKeyLen = paymentKey.length
      if (paymentKeyLen < 8) {
        for (let i = paymentKeyLen; i < 8; i++) {
          paymentKey = '0' + paymentKey
        }
      }
      return paymentKey
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }

      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    getCurrentTariifPlan () {
      const userTariffPlan = this.user.userTariffPlans.find((element: any) => ((element.endTime === null) || (moment(element.endTime).isAfter(moment()))))
      if (userTariffPlan === undefined) {
        return null
      }
      return Number(userTariffPlan.tariffPlanId)
    },
    getCustomerShortName (customerDescription: any) {
      if (customerDescription === null) {
        return null
      }
      return customerDescription.shortName
    },
    getCustomerInn (customerDescription: any) {
      if (customerDescription === null) {
        return null
      }
      return customerDescription.inn
    },
    getCurrentSchoolName (currentSchool: any) {
      if (currentSchool === null) {
        return null
      }
      return currentSchool.name
    },
    changeReaderSettings () {
      this.cardReaderServerHost = localStorage.getItem('cardReaderUrl') ?? ''
      this.refineCardReaderSettingsDialog = true
    },
    changeCardReaderSettings () {
      try {
        localStorage.setItem('cardReaderUrl', this.cardReaderServerHost)
        Swal.fire('Настройки успешно сохранены.', '', 'success')
      } catch (error: any) {
        Swal.fire({
          title: 'Ошибка',
          html: 'Не удалось сохранить данные'
        })
      }
    },
    changeTimestamp (value: any) {
      this.classTimestamp = value
    },
    isEndTimeIsAfterNow (userTariffPlan: any): boolean {
      console.log('isEndTimeIsAfterNow', userTariffPlan)
      if (userTariffPlan) {
        return moment(userTariffPlan.endTime).isAfter(moment())
      }
      return false
    },
    utf16to8 (str: string) {
      let out, i, c
      out = ''
      const len = str.length
      for (i = 0; i < len; i++) {
        c = str.charCodeAt(i)
        if ((c >= 0x0001) && (c <= 0x007F)) {
          out += str.charAt(i)
        } else if (c > 0x07FF) {
          out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F))
          out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F))
          out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F))
        } else {
          out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F))
          out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F))
        }
      }
      return out
    }

  },
  mounted () {
    for (let i = 5; i >= 0; i--) {
      this.spravkaYears.push({ id: Number(moment().year()) - i, name: String(Number(moment().year()) - i) })
    }
    this.selectedSpravkaYear = Number(moment().year())
    this.classTimestamp = moment().add(1, 'days').format('YYYY-MM-DD')
    this.getDetailedUser()
    this.getInterimCards()
    if (Number(this.owner.person.userType) === 7) {
      this.getPlaceSettingsByUser()
    }
    this.loadUserAllowedPlaces()
    this.userTypes = userTypes
  },
  computed: {
    ...mapGetters({ owner: 'user/user' }),
    getQrCode () {
      console.log(this.utf16to8(this.user.qrCode))
      return this.utf16to8(this.user.qrCode)
    }
  }
})
</script>

<style lang='scss'>
.green-header-table {
  border-collapse: collapse;
}

#qr {
  width: fit-content;
  height: fit-content;
  bottom: 0;
  left: 13px;
  position: absolute;
  z-index: 1;
}

#combine {
  font-family: Arial, Helvetica, sans-serif;
  height: 14px;
  line-height: 14px;
  width: 290px;
}

#combine, #school, #secondName, #firstName, #loginCode, #accountNumber {
  height: auto;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

#combine, #secondName, #firstName {
  font-weight: bold;
}

#accountNumber {
  width: 76px;
}

#accountNumber2 {
  height: auto;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

#accountNumber2 {
  font-family: Courier, monospace;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
  text-align: center;
}

#loginCode, #accountNumber {
  font-family: Courier, monospace;
  font-weight: bold;
  height: 16px;
  line-height: 16px;
  text-align: center;
}
</style>
