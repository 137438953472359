<template>
  <v-dialog
    v-model="isOpen"
    max-width="650"
  >
    <template #activator="{ props }">
      <v-btn

        v-bind="props"
      >
        Изменить заголовок
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Обновление заголовка</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="dataString"
                class="editable-textarea"
                label="Заголовок отчета"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="isOpen = false"
        >
          Отмена
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="emitUpdatedString"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StringEditorButton',
  props: {
    propString: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dataString: '',
      isOpen: false
    }
  },
  beforeMount () {
    const updatedText = this.prepareRawText(this.propString)
    this.dataString = updatedText
  },
  methods: {
    prepareRawText (rawText) {
      const updatedText = JSON.parse(JSON.stringify(rawText))
      return updatedText.replaceAll('<br/>', '\n')
    },
    async emitUpdatedString () {
      const rawInput = this.dataString.replace(/\n/g, '<br/>')
      this.$emit('updateString', rawInput)
      this.isOpen = false
    }
  }
})
</script>

<style lang="scss" scoped>
textarea{
  text-align: center;
}
</style>
