import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { IncrementGroup } from '@/store/incrementGroup/types'
import { IncrementGroupCreatePost, IncrementGroupEditPut } from './api-types'

const routeIncrementGroupList = '/incrementgroups/all'
const routeIncrementGroupFind = '/incrementgroups/find'
const routeIncrementGroupCreate = '/incrementgroups/create'
const routeIncrementGroupUpdate = '/incrementgroups/update'
const routeIncrementGroupDelete = '/incrementgroups/delete'

export const all = async (): Promise<IncrementGroup[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: IncrementGroup[];
  }> = await axios.get(routeIncrementGroupList)
  return result.data.data
}

export const find = async (data: number): Promise<IncrementGroup> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: IncrementGroup ;
  }> = await axios.get(`${routeIncrementGroupFind}/${data}`)

  return result.data.data
}

export const create = async (data: IncrementGroupCreatePost): Promise<IncrementGroup> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: IncrementGroup ;
  }> = await axios.post(routeIncrementGroupCreate, data)

  return result.data.data
}

export const update = async (data: IncrementGroup): Promise<IncrementGroup> => {
  const putRequest: IncrementGroupEditPut = {
    name: data.name
  }

  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: IncrementGroup;
  }> = await axios.put(`${routeIncrementGroupUpdate}/${data.id}`, putRequest)

  return result.data.data
}

export const del = async (data: IncrementGroup): Promise<IncrementGroup> => {
  setAuthorizationHeader(axios)
  await axios.delete(`${routeIncrementGroupDelete}/${data.id}`)
  return data
}
