export enum ReportTypes {
  ReportActsDay = 1,
  ReportActsMonth = 2,
  ReportRatePlanDay = 3,
  ReportRatePlanDayRatePlan = 4,
  ReportTalonByRatePlan = 5,
}

export enum ReportParamTypes {
  ReportTitle = 1
}
