<template>
  <div class="whole_page">
    <!--  <button @click="goToMoneyAccounting" class="goBackButton"><img src="~@/assets/images/undo.png" height="20" width="20">Назад</button>-->
    <div class="desktop_wrapper">
      <div class="content_container">
        <h1 class="title-h1-roboto-medium">
          Перевод между счетами
        </h1>
        <div>
          <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
            <h4
              class="body-text-roboto-medium"
              style="margin-top: 10px"
            >
              Счет списания
            </h4>
            <div>
              <v-select
                v-model="selectedFrom"
                variant="solo"
                density="compact"
                label="Горячее питание"
                :style="{width: '178px'}"
                :items="accountsOptions"
              />
            </div>
          </v-row>
          <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
            <h4
              class="body-text-roboto-medium"
              style="margin-top: 10px"
            >
              Счет зачисления
            </h4>
            <div>
              <v-select
                v-model="selectedTo"
                variant="solo"
                density="compact"
                label="Буфет"
                :style="{width: '178px'}"
                :items="accountsOptions"
              />
            </div>
          </v-row>
          <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: end; gap: 16px">
            <h4
              class="body-text-roboto-medium"
              style="align-self: center"
            >
              Сумма перевода
            </h4>
            <!--        <div style="width: 66px; padding: 12px 8.5px; background: #FFFFFF; border-radius: 7px; gap: 16px">-->
            <!--          <h3 class="button-text-roboto-medium">250 Р</h3>-->
            <!--        </div>-->
            <v-text-field
              v-model="transferSum"
              variant="solo"
              density="compact"
              hide-details="true"
              style="max-width: 66px; min-height: 36px; border-radius: 7px;"
            />
          </v-row>
          <v-row style="justify-content: space-between; padding: 16px 12px; text-align: center">
            <h4
              class="small-text-roboto-medium"
              style="text-decoration: underline"
            >
              Вы согласны с условиями перевода
            </h4>
            <div style="align-self: center">
              <label class="toggle-switch">
                <input
                  v-model="transferSetting"
                  type="checkbox"
                  :disabled="transferSetting"
                  @change="saveUserLicence"
                >
                <div class="slider round" />
              </label>
            </div>
          </v-row>
          <div style="display: grid; margin-top: 16px">
            <button
              class="primary-green-button"
              :disabled="!transferSetting"
              @click="transferMoneyBetweenAccounts"
            >
              Выполнить перевод
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import {
  transferBetweenAccounts,
  userLicensesGetByUserId,
  userLicensesSave
} from '@/shared/api/parents/moneyAccounting/api-requests'
import { defineComponent } from 'vue'
// todo добавить валидацию и отображение баланса
export default defineComponent({
  name: 'ParentsTransferBetweenAccounts',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      transferSum: 0,
      accountsOptions: [{ title: 'Горячее питание', value: 1 }, { title: 'Буфет', value: 2 }],
      transferSetting: false as boolean,
      selectedFrom: 1,
      selectedTo: 2,
      isTelegram: false
    }
  },
  async beforeMount () {
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.getUserLicencesByUserId()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getUserLicencesByUserId () {
      try {
        const getUserLicencesByUserIdReqObj = {
          userId: this.currentUser.id,
          typeId: 1,
          id: null
        }
        const getUserLicencesByUserIdRes = await userLicensesGetByUserId(getUserLicencesByUserIdReqObj, this.isTelegram)
        console.log(getUserLicencesByUserIdRes.data, 'get')
        if (getUserLicencesByUserIdRes.data[0].removed === null) {
          this.transferSetting = true
        } else {
          this.transferSetting = false
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить настройку разрешения перевода между счетами!',
          timeout: 3000
        })
      }
    },
    async saveUserLicence () {
      try {
        const saveUserLicenceReqObj = {
          userId: this.currentUser.id,
          typeId: 1
        }
        const saveUserLicenceRes = await userLicensesSave(saveUserLicenceReqObj, this.isTelegram)
        await this.getUserLicencesByUserId()
        console.log(saveUserLicenceRes, 'save')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось сохранить настройку разрешения перевода между счетами!',
          timeout: 3000
        })
      }
    },
    async transferMoneyBetweenAccounts () {
      try {
        const transferMoneyBetweenAccountsReqObj = {
          userId: this.currentUser.id,
          sum: this.transferSum,
          fromAccountTypeId: this.selectedFrom,
          toAccountTypeId: this.selectedTo
        }
        const transferMoneyBetweenAccountsRes = await transferBetweenAccounts(transferMoneyBetweenAccountsReqObj, this.isTelegram)
        console.log(transferMoneyBetweenAccountsRes, 'transfer')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось ыполнить перевод между счетами!',
          timeout: 3000
        })
      }
    },
    goToMoneyAccounting () {
      this.$router.push('./moneyAccounting')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  margin: 20px 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 700px;
}
.desktop_wrapper {
  display: grid;
  justify-content: center
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: calc(100% - 32px);
  }
  .desktop_wrapper {
    //display: unset;
    justify-content: unset;
  }
}
</style>
