<template>
  <v-container>
    <v-row>
      <v-radio-group
        v-model="row"
        inline
      >
        <v-radio
          label="По устройству"
          value="radio-1"
        />
        <v-radio
          label="По карте"
          value="radio-2"
        />
      </v-radio-group>
    </v-row>
    <div v-if="row=='radio-1'">
      <v-row v-if="((Number(owner.person.userType) === 26) || (Number(owner.person.userType) === 66))">
        <v-col
          class="d-flex"
        >
          <v-btn
            :is-loading="isLoading"
            block
            @click="loadOrganizationsByUserType()"
          >
            Показать
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-select
            v-model="selectedTown"
            :items="towns"
            item-title="name"
            item-value="id"
            label="Регион"
          />
        </v-col>
        <v-col
          class="d-flex"
        >
          <v-btn
            :is-loading="isLoading"
            block
            @click="loadOrganizationsByTown()"
          >
            Показать
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col>
          <h1>
            <strong>Список школ</strong>
          </h1>
          <v-data-table
            v-model="selected"
            v-model:expanded="expanded"
            :headers="headers"
            :items="organizationListFiltered"
            :expand-on-click="singleExpand"
            item-key="id"
            class="elevation-1"
            :search="search"
            search-text="Поиск"
            :items-per-page="100"
            :footer-props="footerProps"
            :loading="loading"
            show-expand
            loading-text="Загрузка... Пожалуйста подождите"
            @update-expanded="loadDetails"

          >
            <template #[`top`]>
              <v-row align="center">
                <v-col
                  class="d-flex"
                  cols="1"
                  sm="3"
                >
                  <v-select
                    v-model="selectedDistrictFilter"
                    :items="districtFilter"
                    item-title="name"
                    item-value="id"
                    label="Район"
                    variant="outlined"
                    @update:model-value="applyFilter()"
                  />
                </v-col>
                <v-col
                  class="d-flex"
                  cols="1"
                  sm="3"
                >
                  <v-text-field
                    v-model="search"
                    label="Поиск"
                    append-icon="search"
                    class="mx-4"
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.data-table-expand="{isExpanded, item}">
              <v-icon
                @click="toggleExpand(item)"
              >
                {{ isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
            <template #expanded-row="{ item }">
              <td />
              <td />
              <td colspan="2">
                <table style="width: 100%">
                  <tr
                    v-for="(device, index) in item.devices"
                    :key="index"
                  >
                    <th style="padding: 5px;">
                      {{ device.login }}
                    </th>
                    <td style="padding: 5px;">
                      {{ device.description }}
                    </td>
                    <td style="padding: 5px; cursor: pointer; display: flex; justify-content: center; align-items: center">
                      <a @click="showLogDialog(device.id)">Посмотреть логи</a>
                    </td>
                  </tr>
                </table>
              </td>
            </template>
            <template #no-data>
              <v-alert
                v-if="loading==false && devices.length==0"
                :value="true"
                icon="warning"
              >
                Нет данных
              </v-alert>
              <v-alert
                v-else
                :value="false"
                icon="warning"
              >
                Нет данных
              </v-alert>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <div v-if="row=='radio-2'">
      <v-row>
        <v-col>
          <v-text-field
            v-model="cardNumber"
            label="Номер карты:"
          />
        </v-col>
        <v-col
          class="d-flex"
        >
          <v-btn
            block
            @click="showLogDialogByCardNumber()"
          >
            Поиск
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="logDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="logDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Список ошибок</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-divider />
        <br>
        <v-card-text>
          <v-row>
            <v-col
              v-if="row=='radio-2'"
              class="d-flex"
            >
              <v-text-field
                v-model="dialogCardNumber"
                label="Номер карты:"
              />
            </v-col>
            <v-col
              class="d-flex"
            >
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :offset="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ props }">
                  <v-text-field
                    :model-value="formatDate(dateFrom)"
                    label="Дата с"
                    prepend-icon="mdi-calendar"
                    readonly

                    v-bind="props"
                  />
                </template>
                <v-date-picker
                  hide-weekdays
                  title="Дата С"
                  v-model="dateFrom"
                  @input="menu1 = false"
                />
              </v-menu>
            </v-col>
            <v-col
              class="d-flex"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :offset="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ props }">
                  <v-text-field
                    :model-value="formatDate(dateTo)"
                    label="Дата по"
                    prepend-icon="mdi-calendar"
                    readonly

                    v-bind="props"
                  />
                </template>
                <v-date-picker
                  hide-weekdays
                  title="Дата По"
                  v-model="dateTo"
                  @input="menu2 = false"
                />
              </v-menu>
            </v-col>
            <v-col
              class="d-flex"
            >
              <v-btn
                v-if="row=='radio-1'"
                :is-loading="isLoading"
                block
                @click="loadCardErrorLogs()"
              >
                Показать
              </v-btn>
              <v-btn
                v-if="row=='radio-2'"
                :is-loading="isLoading"
                block
                @click="loadCardErrorLogsByCardNumber()"
              >
                Показать
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-table
                class="report-rateplan-day"
                style="width:100%;"
              >
                <thead>
                  <tr>
                    <th
                      colspan="8"
                      class="bg-green-darken-4 text-center text-white"
                    >
                      Список ошибок
                    </th>
                  </tr>
                  <tr>
                    <th>№</th>
                    <th>Логин</th>
                    <th>Дата/время</th>
                    <th>Ошибка</th>
                    <th>RfId</th>
                    <th>СУИД</th>
                    <th>Протокол</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cardErrorLog, i) in cardErrorLogs"
                    :key="i"
                  >
                    <td class="pa-1">
                      {{ i + 1 }}
                    </td>
                    <td class="pa-1 text-left">
                      {{ cardErrorLog.login }}
                    </td>
                    <td class="pa-1 text-left">
                      {{ cardErrorLog.timestamp }}
                    </td>
                    <td class="pa-1 text-left">
                      {{ cardErrorLog.description }}
                    </td>
                    <td class="pa-1 text-left">
                      {{ cardErrorLog.rfid }}
                    </td>
                    <td class="pa-1 text-left">
                      {{ cardErrorLog.suid }}
                    </td>
                    <td class="pa-1 text-left">
                      {{ cardErrorLog.protocol }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-progress-circular
                v-if="logDialogLoading"
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { configurationInfoFind, find } from '@/shared/api/deviceList/api-requests'
import { AxiosError } from 'axios'
import moment from 'moment'
import {
  placeFindByParentIdWithDistricts,
  placeFindByTypeId,
  placeFindByUserTypeWithDistricts
} from '@/shared/api/places/api-requests'
import { PlaceFindByParentIdPost, PlaceFindByUserTypePost, PlaceFindPost } from '@/shared/api/places/api-types'
import { DeviceFindPost } from '@/shared/api/deviceList/api-types'
import { CardErrorLogFindByCardNumberPost, CardErrorLogFindPost } from '@/shared/api/cardErrorLogs/api-types'
import { find as findCardErrorLog, findByCardNumber } from '@/shared/api/cardErrorLogs/api-requests'

type BaseData = {
  menu1: any;
  menu2: any;
  row: any;
  isLoading: boolean;
  footerProps: any;
  search: string;
  searchTerm: string;
  loading: boolean;
  singleSelect: boolean;
  selected: any;
  valid: boolean;
  deviceList: any;
  organizations: any;
  towns: any;
  selectedTown: any;
  organizationList: any;
  selectedDistrictFilter: any;
  districtFilter: any;
  organizationListFiltered: any;
  expanded: any;
  singleExpand: boolean;
  logDialog: boolean;
  logDialogLoading: boolean;
  cardErrorLogs: any;
  dateFrom: any;
  dateTo: any;
  dialogDeviceId: any;
  cardNumber: any;
  dialogCardNumber: any;
}
export default defineComponent({
  data (): BaseData {
    return {
      menu1: false,
      menu2: false,
      row: 'radio-1',
      expanded: [],
      singleExpand: false,
      footerProps: {
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Количество на странице',
        itemsPerPageOptions: [10, 20, 30, 100, 1000, -1]
      },
      search: '',
      searchTerm: '',
      loading: false,
      singleSelect: false,
      selected: [],
      valid: true,
      isLoading: false,
      deviceList: [],
      organizations: [],
      towns: [],
      selectedTown: null,
      organizationList: [],
      selectedDistrictFilter: 0,
      districtFilter: [{
        id: 0,
        name: 'Все'
      }],
      organizationListFiltered: [],
      logDialog: false,
      logDialogLoading: false,
      cardErrorLogs: [],
      dateFrom: null,
      dateTo: null,
      dialogDeviceId: null,
      cardNumber: null,
      dialogCardNumber: null
    }
  },
  computed: {
    ...mapGetters({ owner: 'user/user' }),
    ...mapGetters({ devices: 'devices/devices' }),
    headers () {
      return [
        {
          title: 'Район',
          align: 'start',
          sortable: false,
          value: 'districtName'
        },
        { title: 'ОУ', value: 'name' },
        { title: 'Оборудование', value: '', sortable: false, filterable: false }
      ]
    }
  },
  methods: {
    ...mapActions('devices', { deviceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    toggleExpand( item : any) {
      const index = this.expanded.indexOf(item?.id)
      if (index >= 0) {
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item?.id)
        this.loadDetails(item)
      }
    },
    async loadTowns () {
      this.loading = true
      try {
        const placeFindPost: PlaceFindPost = {
          typeId: 3
        }
        const result = await placeFindByTypeId(placeFindPost)
        this.towns = JSON.parse(JSON.stringify(result))
        this.selectedTown = this.towns[0].id
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.loading = false
      }
    },
    async loadOrganizationsByUserType () {
      this.expanded = []
      this.loading = true
      try {
        this.districtFilter = [{
          id: 0,
          name: 'Все'
        }]
        this.organizationList = []
        this.organizationListFiltered = this.organizationList
        const placeFindByUserTypePost: PlaceFindByUserTypePost = {
        }
        const result = await placeFindByUserTypeWithDistricts(placeFindByUserTypePost)
        this.organizations = JSON.parse(JSON.stringify(result))
        this.organizations.forEach((organization: any) => {
          let disrictName = 'Не указан'
          let districtId = -1
          this.districtFilter.push({
            id: -1,
            name: 'Не указан'
          })
          organization.districtPlaces.forEach((districtPlace: any) => {
            if (districtPlace.timestampTo === null) {
              if (districtPlace.district.typeId === 1) {
                disrictName = districtPlace.district.name
                districtId = Number(districtPlace.districtId)
                const i = this.districtFilter.findIndex((el: any) => {
                  return Number(el.id) === Number(districtPlace.districtId)
                })
                if (i < 0) {
                  this.districtFilter.push({
                    id: districtPlace.districtId,
                    name: districtPlace.district.name
                  })
                }
              }
            }
          })
          this.organizationList.push(
            {
              id: organization.id,
              name: organization.name,
              districtName: disrictName,
              districtId: districtId,
              devices: []
            }
          )
        })
        this.organizationListFiltered = this.organizationList
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.loading = false
      }
    },
    async loadOrganizationsByTown () {
      this.expanded = []
      this.loading = true
      try {
        this.districtFilter = [{
          id: 0,
          name: 'Все'
        }]
        this.organizationList = []
        this.organizationListFiltered = this.organizationList
        const placeFindByParentIdPost: PlaceFindByParentIdPost = {
          parentId: this.selectedTown
        }
        const result = await placeFindByParentIdWithDistricts(placeFindByParentIdPost)
        this.organizations = JSON.parse(JSON.stringify(result))
        this.organizations.forEach((organization: any) => {
          let disrictName = 'Не указан'
          let districtId = -1
          this.districtFilter.push({
            id: -1,
            name: 'Не указан'
          })
          organization.districtPlaces.forEach((districtPlace: any) => {
            if (districtPlace.timestampTo === null) {
              if (districtPlace.district.typeId === 1) {
                disrictName = districtPlace.district.name
                districtId = Number(districtPlace.districtId)
                const i = this.districtFilter.findIndex((el: any) => {
                  return Number(el.id) === Number(districtPlace.districtId)
                })
                if (i < 0) {
                  this.districtFilter.push({
                    id: districtPlace.districtId,
                    name: districtPlace.district.name
                  })
                }
              }
            }
          })
          this.organizationList.push(
            {
              id: organization.id,
              name: organization.name,
              districtName: disrictName,
              districtId: districtId,
              devices: []
            }
          )
        })
        this.organizationListFiltered = this.organizationList
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.loading = false
      }
    },
    applyFilter () {
      this.organizationListFiltered = this.organizationList
      if (this.selectedDistrictFilter !== 0) {
        this.organizationListFiltered = this.organizationListFiltered.filter((element: any) => Number(element.districtId) === Number(this.selectedDistrictFilter))
      }
    },
    async loadDetails ( item : any) {
      try {
        const deviceFindPost: DeviceFindPost = {
          id: null,
          placeId: item.id
        }
        const result = await find(deviceFindPost)
        item.devices = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.loading = false
      }
    },
    async getUpdateTime (item: any) {
      try {
        let updateTime = null
        const searchUpdateTime = {
          userId: item.userId,
          updateTypeId: 1,
          updateStatus: 0
        }
        const result = await configurationInfoFind(searchUpdateTime)
        updateTime = JSON.parse(JSON.stringify(result))
        if (updateTime != null) {
          item.updateTime = moment(updateTime.lastUpdateTimestamp).format('DD.MM.YYYY hh:mm:ss')
        } else {
          item.updateTime = 'Информация не найдена'
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async showLogDialog (deviceId: number) {
      this.dateFrom = moment().add('days', -14).format('YYYY-MM-DD')
      this.dateTo = moment().format('YYYY-MM-DD')
      this.logDialog = true
      this.dialogDeviceId = deviceId
      this.dialogCardNumber = null
      await this.loadCardErrorLogs()
    },
    async showLogDialogByCardNumber () {
      this.dateFrom = moment().add('days', -14).format('YYYY-MM-DD')
      this.dateTo = moment().format('YYYY-MM-DD')
      this.logDialog = true
      this.dialogDeviceId = null
      this.dialogCardNumber = this.cardNumber
      await this.loadCardErrorLogsByCardNumber()
    },
    async loadCardErrorLogs () {
      this.cardErrorLogs = []
      this.logDialogLoading = true
      try {
        const cardErrorLogFindPost: CardErrorLogFindPost = {
          deviceId: this.dialogDeviceId,
          timestampFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
          timestampTo: moment(this.dateTo).add('days', 1).format('YYYY-MM-DD')
        }

        const result = await findCardErrorLog(cardErrorLogFindPost)
        this.cardErrorLogs = JSON.parse(JSON.stringify(result))
        if (this.cardErrorLogs.length > 0) {
          this.cardErrorLogs.sort((a: any, b: any) => (b.timestamp > a.timestamp) ? 1 : ((a.timestamp > b.timestamp) ? -1 : 0))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.logDialogLoading = false
      }
    },
    async loadCardErrorLogsByCardNumber () {
      this.cardErrorLogs = []
      this.logDialogLoading = true
      try {
        const cardErrorLogFindPost: CardErrorLogFindByCardNumberPost = {
          cardNumber: this.dialogCardNumber,
          timestampFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
          timestampTo: moment(this.dateTo).add('days', 1).format('YYYY-MM-DD')
        }

        const result = await findByCardNumber(cardErrorLogFindPost)
        this.cardErrorLogs = JSON.parse(JSON.stringify(result))
        if (this.cardErrorLogs.length > 0) {
          this.cardErrorLogs.sort((a: any, b: any) => (b.timestamp > a.timestamp) ? 1 : ((a.timestamp > b.timestamp) ? -1 : 0))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.logDialogLoading = false
      }
    }
  },
  mounted () {
    if ((Number(this.owner.person.userType) !== 26) && (Number(this.owner.person.userType) !== 66)) {
      this.loadTowns()
    }
  }
})
</script>

<style lang='scss'></style>
