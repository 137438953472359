<template>
  <v-container class="convertedRawApiData">
    <v-row class="justify-content-center">
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="isLoading"
            cols="10"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
        <v-row
          v-if="user != null && !isLoading"
          dense
        >
          <v-col>
            <v-card-text dense>
              <v-table
                class="green-header-table"
                dense
              >
                <thead>
                  <tr>
                    <th colspan="100%">
                      Информация по учащемуся
                    </th>
                  </tr>
                  <tr><th>Ф.И.О</th><th>Зачислен</th><th>с:</th><th>по:</th></tr>
                </thead>
                <tbody>
                  <template
                    v-for="(userPlace, i) in user.userPlaces"
                    :key="i"
                  >
                    <tr>
                      <td>{{ user.lastName }} {{ user.firstName }} {{ user.middleName }}</td>
                      <td>{{ getUserPlace(userPlace) }}</td>
                      <td>{{ formatDateTime(userPlace.timestampFrom) }}</td>
                      <td>{{ formatDateTime(userPlace.timestampTo) }}</td>
                    </tr>
                  </template>
                </tbody>
              </v-table>
            </v-card-text>
          </v-col>
          <v-col />
        </v-row>
        <v-row v-if="user != null && !isLoading">
          <v-col>
            <v-card-text dense>
              <v-table
                class="green-header-table"
                dense
              >
                <thead>
                  <tr>
                    <th>
                      Счет
                    </th>
                    <th>
                      Сумма
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(account, i) in accounts">
                    <tr
                      v-if="checkUserTypeBlockedAccountTypes(account.accountType.id)"
                      :key="i"
                    >
                      <td>
                        {{ account.accountType.name }}
                      </td>
                      <td>
                        {{ Number(account.sum).toFixed(2) }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-table>
            </v-card-text>
          </v-col>
          <v-col />
        </v-row>
        <v-row v-if="!isLoading">
          <v-col
            cols="2"
            class="d-flex"
          >
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ props }">
                <v-text-field
                  :model-value="formatDate(dateFrom)"
                  label="Дата с"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="props"
                />
              </template>
              <v-date-picker
                hide-weekdays
                title="Дата С"
                v-model="dateFrom"
                @input="menu1 = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="2"
            class="d-flex"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ props }">
                <v-text-field
                  :model-value="formatDate(dateTo)"
                  label="Дата по"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="props"
                />
              </template>
              <v-date-picker
                hide-weekdays
                title="Дата С"
                v-model="dateTo"
                @input="menu2 = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="limit"
              label="Строк"
            />
          </v-col>
          <v-col
            cols="1"
            class="d-flex"
          >
            <v-btn
              :is-loading="isLoading"
              block
              @click="isLoading = true; getTransactions()"
            >
              Показать
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="transactions.length > 0 && !isLoading">
          <v-col cols="10">
            <v-card-text dense>
              <v-table
                class="green-header-table"
                dense
              >
                <thead>
                  <tr>
                    <th colspan="100%">
                      Список транзакций
                    </th>
                  </tr>
                  <tr>
                    <th style="background: antiquewhite; color: black !important;">
                      Дата операции
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Приход
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Расход
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Счет
                      <v-select
                        v-model="selectedTransactionAccount"
                        :items="transactionAccounts"
                        item-title="name"
                        item-value="id"
                        @update:model-value="updateTransactionList"
                      />
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      № транзакции
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Дополнительно
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Устройство
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(transaction, i) in transactionList">
                    <tr
                      v-if="transaction.visible"
                      :key="i"
                    >
                      <td>
                        {{ transaction.creationDate }}
                      </td>
                      <td>
                        {{ getIncoming(transaction) }}
                      </td>
                      <td>
                        {{ getOutlay(transaction) }}
                      </td>
                      <td>
                        {{ getAccountType(transaction) }}
                      </td>
                      <td>
                        {{ transaction.id }}
                      </td>
                      <td v-if="transaction.saleBatchTransactions.length > 0">
                        <a @click="loadSales(transaction.saleBatchTransactions[0].saleBatchId, transaction); saleDialog = true">
                          Продажа {{ getCardOrdinal(transaction.saleBatchTransactions[0].saleBatch.saleBatchSales[0].sale) }}
                        </a>
                      </td>
                      <td v-else-if="transaction.documentTransactions.length > 0">
                        {{ getDocumentTypeName(transaction.documentTransactions[0].document.documentTypeId) }}: {{ transaction.tag }}
                      </td>
                      <td v-else />
                      <td>
                        {{ transaction.ownerUserId }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <template v-for="(accountTransactionSum, i) in accountTransactionSums">
                    <tr
                      v-if="selectedTransactionAccount == 0 || accountTransactionSum.accountTypeId == selectedTransactionAccount"
                      :key="i"
                    >
                      <th style="background: antiquewhite; color: black !important;text-align:left !important;;">
                        Итого за период {{ accountTransactionSum.accountTypeName }}:
                      </th>
                      <th style="background: antiquewhite; color: black !important;">
                        {{ accountTransactionSum.incoming.toFixed(2) }}
                      </th>
                      <th style="background: antiquewhite; color: black !important;">
                        {{ Math.abs(accountTransactionSum.outlay).toFixed(2) }}
                      </th>
                      <th style="background: antiquewhite; color: black !important;text-align:left !important;;">
                        {{ accountTransactionSum.accountTypeName }}
                      </th>
                      <th style="background: antiquewhite; color: black !important;" />
                      <th style="background: antiquewhite; color: black !important;" />
                      <th style="background: antiquewhite; color: black !important;" />
                    </tr>
                  </template>
                  <tr>
                    <th style="background: antiquewhite; color: black !important;text-align:left !important;;">
                      Итого за период:
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      {{ getIncomingByPeriod() }}
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      {{ getOutlayByPeriod() }}
                    </th>
                    <th style="background: antiquewhite; color: black !important;text-align:left !important;;">
                      Итого
                    </th>
                    <th style="background: antiquewhite; color: black !important;" />
                    <th style="background: antiquewhite; color: black !important;" />
                    <th style="background: antiquewhite; color: black !important;" />
                  </tr>
                </tfoot>
              </v-table>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row v-if="!isLoading">
          <v-col cols="6">
            <v-card-text dense>
              <v-table
                class="green-header-table"
                dense
              >
                <thead>
                  <tr>
                    <td colspan="100%">
                      Баланс на начало периода
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Горячее питание</td>
                    <td>{{ getStartAccountSum(1) }}</td>
                  </tr>
                  <tr>
                    <td>Буфет</td>
                    <td>{{ getStartAccountSum(2) }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="saleDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="saleDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Информация по транзакции</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-divider />
        <br>
        <v-card-text>
          <v-row
            v-if="isSaleDialogLoading"
            class="justify-content-center"
          >
            <v-col
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </v-row>
          <v-row v-if="!isSaleDialogLoading">
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-table class="green-header-table">
                <thead>
                  <tr>
                    <th colspan="100%">
                      Информация по транзакции
                      <b
                        v-if="selectedTransaction != null && selectedTransaction.tag != null && selectedTransaction.tag.length > 0"
                        style="color:#FFCC00"
                      >{{ selectedTransaction.tag }}</b>
                    </th>
                  </tr>
                  <tr>
                    <th style="background: antiquewhite; color: black !important;">
                      № продажи
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Дата продажи
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Дата обработки
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Продукт
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Цена
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Количество
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Дотации, скидки
                    </th>
                    <th style="background: antiquewhite; color: black !important;">
                      Сумма
                    </th>
                    <th style="background: antiquewhite; color: black !important;" />
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(sale, i) in sales"
                    :key="i"
                  >
                    <tr v-if="compareAccounts(sale, selectedTransaction)">
                      <td>
                        {{ sale.id }}
                      </td>
                      <td>
                        {{ formatDateTime(sale.timestamp) }}
                      </td>
                      <td>
                        {{ formatDateTime(sale.creationDate) }}
                      </td>
                      <td>
                        {{ sale.product.name }}
                      </td>
                      <td>
                        {{ Number(sale.price).toFixed(2) }}
                      </td>
                      <td>
                        {{ sale.quantity }}
                      </td>
                      <td>
                        <v-table v-if="sale.saleRatePlanProductDiscounts.length > 0">
                          <template
                            v-for="(saleRatePlanProductDiscount, j) in sale.saleRatePlanProductDiscounts"
                            :key="j"
                          >
                            <tr>
                              <td> {{ saleRatePlanProductDiscount.ratePlanProductDiscount.discount.name }}</td><td style="padding: 5px;">
                                {{ Number(saleRatePlanProductDiscount.sum).toFixed(2) }}
                              </td>
                            </tr>
                          </template>
                        </v-table>
                      </td>
                      <td>
                        {{ getSaleSum(sale) }}
                      </td>
                      <td />
                    </tr>
                    <tr
                      v-else
                      style="background-color:#ccc !important;"
                    >
                      <td>
                        {{ sale.id }}
                      </td>
                      <td>
                        {{ formatDateTime(sale.timestamp) }}
                      </td>
                      <td>
                        {{ formatDateTime(sale.creationDate) }}
                      </td>
                      <td>
                        {{ sale.product.name }}
                      </td>
                      <td>
                        {{ Number(sale.price).toFixed(2) }}
                      </td>
                      <td>
                        {{ sale.quantity }}
                      </td>
                      <td>
                        <v-table v-if="sale.saleRatePlanProductDiscounts.length > 0">
                          <template
                            v-for="(saleRatePlanProductDiscount, j) in sale.saleRatePlanProductDiscounts"
                            :key="j"
                          >
                            <tr>
                              <td> {{ saleRatePlanProductDiscount.ratePlanProductDiscount.discount.name }}</td><td style="padding: 5px;">
                                {{ Number(saleRatePlanProductDiscount.sum).toFixed(2) }}
                              </td>
                            </tr>
                          </template>
                        </v-table>
                      </td>
                      <td>
                        {{ getSaleSum(sale) }}
                      </td>
                      <td>
                        Продажа с другого баланса
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="7">
                      <b>Итого к оплате</b>
                    </td>
                    <td>{{ Number(saleSum).toFixed(2) }}</td>
                    <td />
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { accountTypes, documentTypes, transactionTypes } from '@/app/types/vueEnums'
import moment from 'moment'
import { AxiosError } from 'axios'

import { UserDetailedFindPost } from '@/shared/api/user/api-types'
import { getDetailedPerson } from '@/shared/api/user/api-requests'
import { TransactionsByUserFindPost } from '@/shared/api/transaction/api-types'
import { findByUser } from '@/shared/api/transaction/api-requests'
import { SalesBySaleBatchFindPost } from '@/shared/api/salelist/api-types'
import { findBySaleBatch } from '@/shared/api/salelist/api-requests'

type BaseData = {
  transactionAccounts: any;
  accountTransactionSums: any;
  selectedTransactionAccount: any;
  selectedTransaction: any;
  userTypeBlockedAccountTypes: any;
  saleSum: any;
  isSaleDialogLoading: boolean;
  sales: any;
  saleDialog: boolean;
  menu1: boolean;
  menu2: boolean;
  dateFrom: any;
  dateTo: any;
  limit: any;
  snack: boolean;
  snackColor: string;
  snackText: string;
  isLoading: boolean;
  user: any;
  accounts: any;
  userCards: any;
  transactions: any;
  transactionList: any;
  timestampFrom: any;
  timestampTo: any;
  selectedUserType: any;
  realLastName: any;
  realFirstName: any;
  realMiddleName: any;
}
export default defineComponent({
  components: {

  },
  props: {
    userId: {
      required: true
    }
  },
  data (): BaseData {
    return {
      transactionAccounts: [{
        id: 0,
        name: 'Все'
      }],
      accountTransactionSums: [
        {
          accountTypeId: 1,
          accountTypeName: 'Горячее питание',
          sum: 0,
          incoming: 0,
          outlay: 0
        },
        {
          accountTypeId: 2,
          accountTypeName: 'Буфет',
          sum: 0,
          incoming: 0,
          outlay: 0
        }
      ],
      selectedTransactionAccount: 0,
      selectedTransaction: null,
      userTypeBlockedAccountTypes: [
        {
          userType: 7,
          blockedAccountTypes: [
            { id: 4 }
          ]
        },
        {
          userType: 26,
          blockedAccountTypes: [
            { id: 3 },
            { id: 4 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12 },
            { id: 13 },
            { id: 14 },
            { id: 15 }
          ]
        },
        {
          userType: 66,
          blockedAccountTypes: [
            { id: 3 },
            { id: 4 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12 },
            { id: 13 },
            { id: 14 },
            { id: 15 }
          ]
        }
      ],
      saleSum: 0,
      isSaleDialogLoading: false,
      sales: [],
      saleDialog: false,
      menu1: false,
      menu2: false,
      dateFrom: null,
      dateTo: null,
      limit: null,
      snack: false,
      snackColor: '',
      snackText: '',
      isLoading: false,
      user: null,
      accounts: [],
      userCards: [],
      transactions: [],
      transactionList: [],
      timestampFrom: null,
      timestampTo: null,
      selectedUserType: null,
      realLastName: null,
      realFirstName: null,
      realMiddleName: null
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async getDetailedUser () {
      try {
        this.isLoading = true
        const userDetailedFindPost: UserDetailedFindPost = {
          id: Number(this.userId)
        }
        const result = await getDetailedPerson(userDetailedFindPost)
        this.user = JSON.parse(JSON.stringify(result))
        if (this.user !== null) {
          this.accounts = JSON.parse(JSON.stringify(this.user.accounts))
          this.accounts.sort((a: any, b: any) => (Number(a.accountTypeId) > Number(b.accountTypeId)) ? 1 : ((Number(b.name) > Number(a.name)) ? -1 : 0))
          this.userCards = JSON.parse(JSON.stringify(this.user.cards))
          this.userCards.sort((a: any, b: any) => (Number(a.id) > Number(b.id)) ? 1 : ((Number(b.id) > Number(a.id)) ? -1 : 0))
          this.selectedUserType = Number(this.user.typeId)
          this.realLastName = this.user.lastName
          this.realFirstName = this.user.firstName
          this.realMiddleName = this.user.middleName
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.getTransactions()
      }
    },
    async getTransactions () {
      try {
        this.selectedTransactionAccount = 0
        this.timestampFrom = moment(this.dateFrom).format('DD.MM.YYYY')
        this.timestampTo = moment(this.dateTo).add(1, 'days').format('DD.MM.YYYY')
        this.transactions = []
        const transactionsByUserFindPost: TransactionsByUserFindPost = {
          userId: Number(this.userId),
          timestampFrom: this.timestampFrom,
          timestampTo: this.timestampTo,
          limit: this.limit
        }
        const result = await findByUser(transactionsByUserFindPost)
        this.transactions = JSON.parse(JSON.stringify(result))
        this.transactionList = JSON.parse(JSON.stringify(this.transactions))
        this.transactionList.forEach((transaction: any) => {
          const trIndex = this.accountTransactionSums.findIndex((element: any) => {
            return Number(transaction.account.accountTypeId) === Number(element.accountTypeId)
          })
          if (trIndex > -1) {
            this.accountTransactionSums[trIndex].sum = Number(this.accountTransactionSums[trIndex].sum) + Number(transaction.sum)
            if (Number(transaction.transactionTypeId) === 2) {
              this.accountTransactionSums[trIndex].incoming = Number(this.accountTransactionSums[trIndex].incoming) + Number(transaction.sum)
            }
            if (Number(transaction.transactionTypeId) === 3) {
              this.accountTransactionSums[trIndex].outlay = Number(this.accountTransactionSums[trIndex].outlay) + Number(transaction.sum)
            }
          }
          transaction.visible = true
          const index = this.transactionAccounts.find((element: any) => element.id === Number(transaction.account.accountTypeId))
          if (index === undefined) {
            const accountType = accountTypes.find((el: any) => el.id === Number(transaction.account.accountTypeId))
            if (accountType !== undefined) {
              this.transactionAccounts.push({
                id: accountType.id,
                name: accountType.name
              })
            }
          }
        })
        if (this.limit === null) {
          this.limit = 200
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadSales (saleBatchId: any, transaction: any) {
      this.selectedTransaction = transaction
      this.isSaleDialogLoading = true
      this.saleSum = 0
      this.sales = []
      try {
        const salesBySaleBatchFindPost: SalesBySaleBatchFindPost = {
          saleBatchId: saleBatchId
        }
        const result = await findBySaleBatch(salesBySaleBatchFindPost)
        this.sales = JSON.parse(JSON.stringify(result))
        this.sales.forEach((sale: any) => {
          this.saleSum = this.saleSum + (Number(sale.price) * Number(sale.quantity) - Number(sale.discount))
        })
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isSaleDialogLoading = false
      }
    },
    formatDateTime (dateTime: any) {
      if (dateTime === null) {
        return null
      }
      if (dateTime.length === 0) {
        return null
      }
      return moment(dateTime).format('DD.MM.YYYY HH:mm:ss')
    },
    getUserPlace (userPlace: any) {
      if (userPlace.place === undefined) {
        return null
      }
      if (userPlace.place === null) {
        return null
      }
      if (userPlace.place.parentPlace === undefined) {
        return userPlace.place.name
      }
      if (userPlace.parentPlace === null) {
        return userPlace.place.name
      }
      return userPlace.place.parentPlace.name + ' - ' + userPlace.place.name
    },
    getIncoming (transaction: any) {
      if (Number(transaction.transactionTypeId) !== 3) {
        return Math.abs(Number(transaction.sum)).toFixed(2)
      }
      return null
    },
    getIncomingByPeriod () {
      let sum = 0
      this.transactions.forEach((transaction: any) => {
        if (Number(transaction.transactionTypeId) !== 3) {
          sum = sum + Number(transaction.sum)
        }
      })
      return Math.abs(sum).toFixed(2)
    },
    getOutlay (transaction: any) {
      if (Number(transaction.transactionTypeId) === 3) {
        return Math.abs(Number(transaction.sum)).toFixed(2)
      }
      return null
    },
    getOutlayByPeriod () {
      let sum = 0
      this.transactions.forEach((transaction: any) => {
        if (Number(transaction.transactionTypeId) === 3) {
          sum = sum + Number(transaction.sum)
        }
      })
      return Math.abs(sum).toFixed(2)
    },
    getTransactionType (transaction: any) {
      const transactionType = transactionTypes.find((el: any) => el.id === Number(transaction.transactionTypeId))
      if (transactionType === undefined) {
        return null
      }
      return transactionType.name
    },
    getAccountType (transaction: any) {
      const accountType = accountTypes.find((el: any) => el.id === Number(transaction.account.accountTypeId))
      if (accountType === undefined) {
        return null
      }
      return accountType.name
    },
    getStartAccountSum (accountType: any) {
      let sum = 0
      const account = this.accounts.find((el: any) => Number(el.accountTypeId) === accountType)
      if (account !== undefined) {
        sum = account.sum
      }
      this.transactions.forEach((transaction: any) => {
        if (Number(transaction.account.accountTypeId) === accountType) {
          sum = sum - Number(transaction.sum)
        }
      })
      return Number(sum).toFixed(2)
    },
    getCardOrdinal (sale: any) {
      if (sale.rfid === null) {
        return null
      }
      return this.getCardNumber(sale.rfid)
    },
    getCardNumber (rfid: any) {
      let i = 0
      let cardNumber = null
      this.userCards.forEach((card: any) => {
        i = i + 1
        if (Number(card.rfid) === Number(rfid)) {
          cardNumber = 'Карта №' + i
        }
      })
      if (cardNumber === null) {
        this.user.cesUserCards.forEach((card: any) => {
          if (Number(rfid) === Number(card.card.rfidInt)) {
            cardNumber = 'Карта №' + card.card.cardNumber
          }
        })
      }
      if (cardNumber === null) {
        this.user.userInterimCards.forEach((card: any) => {
          if (Number(rfid) === Number(card.interimCard.rfid)) {
            cardNumber = 'Временная карта'
          }
        })
      }
      return cardNumber
    },
    getDocumentTypeName (documentTypeId: any) {
      const documentType = documentTypes.find((el: any) => el.id === Number(documentTypeId))
      if (documentType === undefined) {
        return null
      }
      return documentType.name
    },
    getSaleSum (sale: any) {
      const sum = Number(sale.price) * Number(sale.quantity) - Number(sale.discount)
      return Number(sum).toFixed(2)
    },
    checkUserTypeBlockedAccountTypes (accountType: any) {
      const blockedAccountTypes = this.userTypeBlockedAccountTypes.find((element: any) => Number(element.userType) === Number(this.owner.person.userType))
      if (blockedAccountTypes !== undefined) {
        const blocked = blockedAccountTypes.blockedAccountTypes.find((element: any) => Number(element.id) === Number(accountType))
        if (blocked !== undefined) {
          return false
        }
      }
      return true
    },
    compareAccounts (sale: any, selectedTransaction: any) {
      if (selectedTransaction === null) {
        return true
      }
      if (sale === null) {
        return true
      }
      let result = true
      let accountType = sale.product.accountTypeId
      if (Number(accountType) === 4 && Number(selectedTransaction.account.accountTypeId) === 1) {
        accountType = 1
      }
      if (Number(accountType) !== Number(selectedTransaction.account.accountTypeId)) {
        result = false
      }
      return result
    },
    updateTransactionList () {
      this.transactionList.forEach((transaction: any) => {
        if (Number(this.selectedTransactionAccount) === 0) {
          transaction.visible = true
        } else {
          if (Number(this.selectedTransactionAccount) === Number(transaction.account.accountTypeId)) {
            transaction.visible = true
          } else {
            transaction.visible = false
          }
        }
      })
    }
  },
  mounted () {
    this.dateFrom = moment().add(-14, 'days').format('YYYY-MM-DD')
    this.dateTo = moment().format('YYYY-MM-DD')
    this.getDetailedUser()
  },
  computed: {
    ...mapGetters({ owner: 'user/user' })
  }
})
</script>

<style lang='scss'>
  .green-header-table {
    border-collapse: collapse;
  }
</style>
