<template>
  <div>
    <template>
      <div class="whole_page">
        <div style="justify-content: center; display: grid">
          <div class="report_panel">
            <h4 class="title-h4-roboto-medium">
              Информация о транзакции
            </h4>
            <table class="small-text-roboto-medium custom-table">
              <thead>
                <tr style="background: #ECF2F6">
                  <td>Продукт</td>
                  <td>Цена</td>
                  <td>Количество</td>
                  <td>Дотации, скидки</td>
                  <td>Сумма</td>
                </tr>
              </thead>
              <tr>
                <td>{{ sale.product?.name }}</td>
                <td>{{ sale.price }}</td>
                <td>{{ sale.quantity }}</td>
                <td>{{ sale.discount }}</td>
                <td>{{ sale.quantity * sale.price }}</td>
              </tr>
              <tr>
                <td colspan="4">
                  Итого к оплате
                </td>
                <td>15</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template />
  </div>
</template>
<script lang="ts">
import { SalesGetBySaleBatchIdItem } from '@/shared/api/parents/moneyAccounting/api-types'
import { salesGetBySalesBatchId } from '@/shared/api/parents/moneyAccounting/api-requests'
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsTransaction',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      isTelegram: false,
      sale: {} as SalesGetBySaleBatchIdItem
    }
  },
  async beforeMount () {
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.salesGetBySaleBatchId()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async salesGetBySaleBatchId () {
      try {
        const salesGetBySaleBatchIdReqObj = {
          userId: this.currentUser.id,
          saleBatchId: Number(this.$route.params.saleBatchId)
        }
        const salesGetBySaleBatchIdRes = await salesGetBySalesBatchId(salesGetBySaleBatchIdReqObj, this.isTelegram)
        console.log(salesGetBySaleBatchIdRes, 'sale2')
        this.sale = salesGetBySaleBatchIdRes.data[0]
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить информацию о продаже!',
          timeout: 3000
        })
      }
    },
    goToHome () {
      this.$router.push('./home')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.report_panel {
  width: 700px;
  margin-top: 20px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 9px 9px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
    vertical-align: top;
    text-align: center
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
@media (max-aspect-ratio: 6/5) {
  .report_panel {
    width: 370px;
  }
}
</style>
