<template>
  <v-select
    v-model="selectedItem"
    :items="itemsFiltered"
    item-title="name"
    item-value="id"
    label="Школа"
    :click="update()"
    :loading="isLoading"
    :disabled="isDisabled"
    return-object
  >
    <template #prepend-item>
      <v-list-item>
        <v-text-field
          v-model="searchTerm"
          :placeholder="'Поиск'"
          @update:model-value="searchItem()"
        />
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
  </v-select>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  model: {
    prop: 'itemsProps',
    event: 'on-change'
  },
  props: ['selectedItemProps'],
  data () {
    return {
      isLoading: false,
      isDisabled: true,
      selectedItem: 0,
      items: [{ id: 0, name: 'Ошибка загрузки' }],
      itemsFiltered: [{ id: 0, name: 'Загружаем' }],
      searchTerm: ''
    }
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    async loadUserAllowedPlaces () {
      try {
        this.isLoading = true
        const userAllowedPlaces = await this.userAllowedPlaceAll()
        this.items = []
        userAllowedPlaces.forEach((obj: any) => {
          if (obj.removed == null) {
            if (this.items.length > 0) {
              this.items.push({ id: obj.place.id, name: obj.place.name })
            } else {
              this.items = [{ id: obj.place.id, name: obj.place.name }]
            }
          }
        })
        if (this.selectedItemProps) this.selectedItem = this.selectedItemProps
        if (this.selectedItem === 0) this.selectedItem = this.items[0].id
        this.isDisabled = false
      } catch (error: any) {
        this.items = [{ id: 0, name: 'Ошибка загрузки' }]
      } finally {
        this.isLoading = false
        this.searchItem()
      }
    },
    update (value: any) {
      this.$emit('on-change', this.items.find((obj: any) => obj.id === this.selectedItem))
      if (this.selectedItem !== 0) this.$emit('place-select')
    },
    searchItem () {
      if (!this.searchTerm) {
        this.itemsFiltered = this.items
      }
      if (Array.isArray(this.items)) {
        this.itemsFiltered = this.items.filter((obj: any) => {
          return obj.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        })
      }
    }
  },
  mounted () {
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss"></style>
