import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { UserNewCardAddPost, UserCardStatusUpdatePost } from './api-types'

const routeCardAddForUserPost = 'cards/addforuser'
const routeCardStatusUpdatePost = 'cards/updatestatus'

export const addCardForUser = async (data: UserNewCardAddPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeCardAddForUserPost, data)
  return result.data.data
}

export const updateCardStatus = async (data: UserCardStatusUpdatePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeCardStatusUpdatePost, data)
  return result.data.data
}
