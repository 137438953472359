<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row
      justify="center"
      align="center"
      style="margin-top: 0px;"
    >
      <v-col v-if="loading">
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
      </v-col>
      <v-col v-else>
        <p class="text-center">
          {{ "Информация по заявке №" + telegramSupportDelailsResponse.supportTicketId }}
        </p>
        <v-table class="green-header-table">
          <thead>
            <tr>
              <th style="background: antiquewhite; color: black !important">
                Лицевые счета
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in telegramSupportDelailsResponse.paymentKeys">
              <tr
                v-if="true"
                :key="index"
              >
                <td>
                  <b>Номер лицевого счёта</b>: {{ item.uls }}<br>
                  <b>Имя ребёнка</b>: {{ item.userName }}<br>
                  <b>Статус</b>: {{ (( item.status == 1)? "Привязан" : "На данный момент отвязан") }}<br>
                  <b>Комбинат</b>: {{ item.customerName + " (" + item.customerId + ")" }}<br>
                  <br>
                  <div v-if="item.accounts.length == 0">
                    Оплат не было
                  </div>
                  <div v-else>
                    <b>Последние успешные оплаты:</b>
                    <template v-for="(item2, index2) in item.accounts">
                      <div
                        v-if="true"
                        :key="index2"
                      >
                        <b>Аккаунт: {{ item2.name + ((item2.lastSuccess == null) ? 'Оплат не было' : '') }}</b><br>
                        <template v-if="item2.lastSuccess !== null">
                          Время: {{ dateText(item2.lastSuccess.creationTime) }}<br>
                          Сумма: {{ item2.lastSuccess.sum }}<br>
                          Тип: {{ getPayType(item2.lastSuccess.payType) }}<br>
                          Откуда: {{ getType(item2.lastSuccess.type) }}<br>
                        </template>
                      </div>
                    </template>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { telegramSupportGetDetailsByTicket } from '@/shared/api/telegram/api-requests'
import { TelegramSupportGetDetailsByTicketPost } from '@/shared/api/telegram/api-types'
import { TelegramSupportDelailsResponse } from '@/store/telegram/types'
import moment from 'moment'
import { AxiosError } from 'axios'

type BaseData = {
  token: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  errorMessageUnknown: string;
  telegramSupportDelailsResponse: TelegramSupportDelailsResponse;
  telegramSupportGetDetailsByTicketPost: TelegramSupportGetDetailsByTicketPost;
}

export default defineComponent({
  props: {
    tokenProp: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      token: '',
      loading: true,
      error: false,
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Неизвестная',
      telegramSupportGetDetailsByTicketPost: {
        token: ''
      },
      telegramSupportDelailsResponse: {
        supportTicketId: null,
        paymentKeys: []
      }
    }
  },
  mounted () {
    if (this.tokenProp) {
      this.token = this.tokenProp
      this.telegramSupportGetDetailsByTicket()
    }
  },
  methods: {
    async telegramSupportGetDetailsByTicket () {
      try {
        this.loading = true
        this.telegramSupportGetDetailsByTicketPost.token = this.token
        await telegramSupportGetDetailsByTicket(this.telegramSupportGetDetailsByTicketPost)
          .then((result: TelegramSupportDelailsResponse) => {
            this.telegramSupportDelailsResponse = result
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    getType (value: any) {
      let type = 'Неизвестно'
      switch (value) {
        case 1:
          type = 'Через сайт'
          break
        case 2:
          type = 'Через мобильное приложение'
          break
        case 3:
          type = 'Через телеграмбот'
          break

        default:
          break
      }
      return type
    },
    getPayType (value: any) {
      let type = 'Неизвестно'
      switch (value) {
        case 1:
          type = 'Интернет-экваринг'
          break
        case 2:
          type = 'СБП'
          break

        default:
          break
      }
      return type
    },
    dateText (date: any) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    catchError (error: any) {
      const err = error as AxiosError
      this.errorMessage = this.errorMessageUnknown
      if (err.response) {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    }
  }
})
</script>
