import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { AccountBuffetLimitSetPost, GetDeponentPost } from './api-types'

const routeAccountBuffetLimitSet = 'accounts/setbuffetlimit'
const routeDeponentGet = 'customeraccounts/find'

export const accountBuffetLimitSet = async (data: AccountBuffetLimitSetPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeAccountBuffetLimitSet, data)
  return result.data.data
}

export const getDeponent = async (data: GetDeponentPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeDeponentGet, data)
  return result.data.data
}
