/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_PLACEELECTRONTALON, CLEAR_PLACEELECTRONTALON } from '@/store/placeElectronTalon/mutations'
import { RootState } from '@/store/state'
import {
  PlaceElectronTalonState, PlaceElectronTalon
} from '@/store/placeElectronTalon/types'
import {
  getById as placeElectronTalongetById
} from '@/shared/api/placeElectronTalon/api-requests'
import { parseAxiosError } from '@/shared/lib/api'
import { PlaceElectronTalonGetByIdPost } from '../../shared/api/placeElectronTalon/api-types'

type PlaceElectronTalonListContext = ActionContext<PlaceElectronTalonState, RootState>

const initialState: PlaceElectronTalonState = {
  placeElectronTalon: {
    id: 0,
    placeId: 0,
    parentPlaceId: 0,
    talonYear: 0,
    talonNumber: 0,
    creationDate: null,
    ownerUserId: 0,
    updateUserId: 0,
    updateTimestamp: null,
    date: null,
    status: 0,
    controlUserId: 0,
    controlTimestamp: 0,
    ownerTimestamp: null,
    placeElectronTalonSales: [],
    placeElectronTalonHistoryItems: [],
    place: {
      id: 0,
      parentId: 0,
      name: '',
      typeId: 0,
      latitude: null,
      longitude: null,
      creationDate: null,
      name2: '',
      updateTimestamp: null,
      removed: null,
      shortName: '',
      ownerUserId: 0,
      placeTempAttributes: []
    }
  }
}

const getters: GetterTree<PlaceElectronTalonState, RootState> = {
  placeElectronTalons (state: PlaceElectronTalonState): PlaceElectronTalon {
    return state.placeElectronTalon
  }
}

const mutations: MutationTree<PlaceElectronTalonState> = {
  [SET_PLACEELECTRONTALON] (state: PlaceElectronTalonState, placeElectronTalons: PlaceElectronTalon) {
    state.placeElectronTalon = placeElectronTalons
  },
  [CLEAR_PLACEELECTRONTALON] (state: PlaceElectronTalonState) {
    state.placeElectronTalon = {
      id: 0,
      placeId: 0,
      parentPlaceId: 0,
      talonYear: 0,
      talonNumber: 0,
      creationDate: null,
      ownerUserId: 0,
      updateUserId: 0,
      updateTimestamp: null,
      date: null,
      status: 0,
      controlUserId: 0,
      controlTimestamp: 0,
      ownerTimestamp: null,
      placeElectronTalonSales: [],
      placeElectronTalonHistoryItems: [],
      place: {
        id: 0,
        parentId: 0,
        name: '',
        typeId: 0,
        latitude: null,
        longitude: null,
        creationDate: null,
        name2: '',
        updateTimestamp: null,
        removed: null,
        shortName: '',
        ownerUserId: 0,
        placeTempAttributes: []
      }
    }
  }
}

const actions: ActionTree<PlaceElectronTalonState, RootState> = {
  async getById (
    { commit, dispatch, state }: PlaceElectronTalonListContext,
    data: PlaceElectronTalonGetByIdPost
  ): Promise<PlaceElectronTalon> {
    try {
      commit(CLEAR_PLACEELECTRONTALON)
      const result = await placeElectronTalongetById(data)
      commit(SET_PLACEELECTRONTALON, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const placeElectronTalon = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
