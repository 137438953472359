function sortInAlphabetOrder (rawArray: { [filedName: string]: string }[], filedName: string) {
  rawArray.sort((a: { [filedName: string]: string }, b: { [filedName: string]: string }) => {
    if (a[filedName].toLowerCase() > b[filedName].toLowerCase()) {
      return 1
    } else {
      return -1
    }
  })
  return rawArray
}

export {
  sortInAlphabetOrder
}
