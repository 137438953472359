<template>
  <form
    autocomplete="off"
    @submit.prevent
  >
    <slot />
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormContainer'
})
</script>

<style lang="scss"></style>
