import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { CustomerPlace } from '@/store/customerPlace/types'
import { CustomerPlaceFindPost, CustomerPlaceFindByPlacePost } from './api-types'

enum CustomerPlaceRoutes {
  routeCustomerPlaceFind = '/customerplaces/find',
  routeCustomerPlaceFindAll = '/customerplaces/findbyplace',
  routeCustomersFind = '/customers/find'
}

export const find = async (data: CustomerPlaceFindPost): Promise<any> => {
  console.log(data)
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(CustomerPlaceRoutes.routeCustomerPlaceFind, data)
  return result.data.data
}

export const findByPlace = async (data: CustomerPlaceFindByPlacePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(CustomerPlaceRoutes.routeCustomerPlaceFindAll, data)
  return result.data.data
}

export const customersFind = async (data: CustomerPlaceFindByPlacePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(CustomerPlaceRoutes.routeCustomersFind, data)
  return result.data.data
}
