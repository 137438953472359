<template>
  <v-container>
    <v-row>
      <v-col style="padding:0;">
        <v-btn
          class="button-wrap"
          elevation="2"
          @click="back()"
        >
          НАЗАД
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="text-center">
        <h1>Детально</h1>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="text-center">
        <h3>{{ placeName }} Дата {{ propDate }} Рацион {{ rationName }}</h3>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="text-center">
        <h3>По заявке - вечер : {{ preorderItog.value }} , Коррекция : {{ preorderItog.correction }} , Факт : {{ preorderItog.fact }} , Расхождение : {{ (preorderItog.fact - preorderItog.value - preorderItog.correction) }}</h3>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col
        v-if="isLoading"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
      <v-col
        v-if="!isLoading"
        class="text-center"
      >
        <v-table class="report-rateplan-day">
          <template #default>
            <thead>
              <tr>
                <th class="text-center">
                  Класс
                </th>
                <th class="text-center">
                  Вечер
                </th>
                <th class="text-center">
                  Корректировка
                </th>
                <th class="text-center">
                  Факт
                </th>
                <th class="text-center">
                  Расхождение
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(preorderItem, index) in preordersFiltered"
                :key="index"
              >
                <tr>
                  <td>{{ preorderItem.place.name }}</td>
                  <td>{{ preorderItem.count }}</td>
                  <td>{{ preorderItem.correctionCount }}</td>
                  <td>{{ preorderItem.placeElectronTalonCount }}</td>
                  <template v-if="((preorderItem.placeElectronTalonCount - preorderItem.count - preorderItem.correctionCount) < 0)">
                    <td class="td-red">
                      {{ (preorderItem.placeElectronTalonCount - preorderItem.count - preorderItem.correctionCount) }}
                    </td>
                  </template>
                  <template v-else-if="((preorderItem.placeElectronTalonCount - preorderItem.count - preorderItem.correctionCount) > 0)">
                    <td class="td-green">
                      {{ (preorderItem.placeElectronTalonCount - preorderItem.count - preorderItem.correctionCount) }}
                    </td>
                  </template>
                  <template v-else>
                    <td> {{ (preorderItem.placeElectronTalonCount - preorderItem.count - preorderItem.correctionCount) }} </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { PreordersGetPost } from '@/shared/api/preorders/api-types'
import { get as preordersGet } from '@/shared/api/preorders/api-requests'
import { AxiosError } from 'axios'

type ProductItog = {
  value: number;
  correction: number;
  fact: number;
}

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  placeName: string;
  rationName: string;
  preordersGetPost: PreordersGetPost;
  preorders: any;
  preordersFiltered: any;
  preorderItog: ProductItog;
}

export default defineComponent({
  components: {
  },
  props: {
    propPlaceId: {
      type: String,
      required: true
    },
    propDate: {
      type: String,
      required: true
    },
    propProductId: {
      type: String,
      required: true
    },
    propPlaces: {
      type: String,
      required: true
    },
    propDateFrom: {
      type: String,
      required: true
    },
    propDateTo: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      isLoading: false,
      isPost: false,
      placeName: '',
      rationName: '',
      preordersGetPost: {
        placeId: 0,
        productId: 0,
        date: ''
      },
      preorders: [],
      preordersFiltered: [],
      preorderItog: {
        value: 0,
        correction: 0,
        fact: 0
      }
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getPreorders (preordersGetPost: PreordersGetPost) {
      this.isLoading = true
      this.isPost = false
      try {
        this.preorders = []
        const result = await preordersGet(preordersGetPost)
        this.preorders = JSON.parse(JSON.stringify(result))
        if (this.preorders.preorder.preorderItems) {
          this.preordersFiltered = this.preorders.preorder.preorderItems
          this.rationName = this.preordersFiltered[0].product.code
          this.placeName = this.preorders.place.name
          this.filterClasses()
        }
        this.getItog()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getItog () {
      this.preorderItog = {
        value: 0,
        correction: 0,
        fact: 0
      }
      this.preordersFiltered.forEach((element: any) => {
        this.preorderItog.value = this.preorderItog.value + element.count
        this.preorderItog.correction = this.preorderItog.correction + element.correctionCount
        this.preorderItog.fact = this.preorderItog.fact + element.placeElectronTalonCount
      })
    },
    back () {
      this.$router.push({ name: 'PreorderReestr', params: { propPlaces: this.propPlaces, propDateFrom: this.propDateFrom, propDateTo: this.propDateTo } })
    },
    filterClasses () {
      this.preordersFiltered.sort((a: any, b: any) => (parseInt(a.place.name) > parseInt(b.place.name)) ? 1 : ((parseInt(a.place.name) > parseInt(b.place.name)) ? -1 : 0))
    }
  },
  mounted () {
    this.isLoading = false
    if (!this.propPlaceId || !this.propDate || !this.propProductId || !this.propPlaces || !this.propDateFrom || !this.propDateTo) {
      this.$router.push({ name: 'PreorderReestr' })
    } else {
      this.preordersGetPost.placeId = parseInt(this.propPlaceId)
      this.preordersGetPost.productId = parseInt(this.propProductId)
      this.preordersGetPost.date = this.propDate
      this.getPreorders(this.preordersGetPost)
    }
  }
})
</script>

<style lang="scss"></style>
