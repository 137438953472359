<template>
  <div>
    <v-container>
      <form-container>
        <v-card
          width="400"
          class="mx-auto mt-5"
        >
          <v-card-title class="pb-0">
            <h1>Вход</h1>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                v-model="userLoginPost.username"
                :has-error="v$.userLoginPost.username.$error"
                error-message="Введите логин."
                name="username"
                label="Логин"
                type="text"
                :rules="usernameRules"
                prepend-icon="mdi-account-circle"
                @keyup.enter="handleSubmit"
              />
              <v-text-field
                v-model="userLoginPost.password"
                :has-error="v$.userLoginPost.password.$error"
                error-message="Введите пароль."
                :type="showPassword ? 'text' : 'password'"
                label="Пароль"
                name="password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                @click:append="showPassword = !showPassword"
                @keyup.enter="handleSubmit"
              />
            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <submit-button
              color="success"
              name="login-submit-button"
              text="Войти"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Вход..."
              @submit-clicked="handleSubmit"
            />
          </v-card-actions>
          <v-divider/>
          <v-card-actions class="justify-center">
            <v-btn
              variant="text"
              to="/user/forgot"
              :disabled="isLoading"
            >
              Забыли пароль?
            </v-btn>
            |
            <v-btn
              variant="text"
              to="/user/signup"
              :disabled="isLoading"
            >
              Регистрация
            </v-btn>
          </v-card-actions>
        </v-card>
      </form-container>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { required } from '@vuelidate/validators'
import { UserLoginPost } from '@/shared/api/user/api-types'
import { ServerError } from '@/shared/lib/api'
import FormContainer from '@/shared/uikit/inputs/FormContainer.vue'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import { mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'

type BaseData = {
  userLoginPost: UserLoginPost;
  isLoading: boolean;
  showPassword: boolean;
  valid: boolean;
  usernameRules: any;
  passwordRules: any;
};
export default defineComponent({
  components: {
    FormContainer,
    SubmitButton
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data (): BaseData {
    return {
      userLoginPost: {
        username: '',
        password: ''
      },
      isLoading: false,
      showPassword: false,
      valid: false,
      usernameRules: [
        (v: any) => !!v || 'Введите логин',
        (v: any) => v.length >= 3 || 'Логин должен быть не менее 3 символов'
      ],
      passwordRules: [
        (v: any) => !!v || 'Введите пароль',
        (v: any) => v.length >= 3 || 'Пароль должен быть не менее 3 символов'
      ]
    }
  },
  methods: {
    ...mapActions('user', { userLogin: 'login' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async handleSubmit () {
      if (!this.valid) {
        return
      }
      this.isLoading = true
      try {
        await this.userLogin(this.userLoginPost)

        // Clear inputs
        this.userLoginPost = {
          username: '',
          password: ''
        }
        // Push to home
        this.$router.push({ name: 'Home' })
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  validations: {
    userLoginPost: {
      username: {
        required
      },
      password: {
        required
      }
    }
  }
})
</script>

<style lang="scss"></style>
