<template>
  <v-container class="mt-5 convertedRawApiData pt-2">
    <v-row class="d-flex justify-space-around">
      <v-btn
        v-for="item in buildings"
        :key="item.id"
        :class="item.id === buildingFilterValue ? 'v-btn--active px-5 py-5' : 'px-5 py-5'"
        @click="assingBuildingFilterValue(item)"
      >
        {{ item.name }}
        <v-icon
          end
          theme="dark"
          @click="openDialog2(item)"
        >
          mdi-lead-pencil
        </v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filterAll()"
          class="elevation-1"
        >
          <template #[`body.prepend`]>
            <tr>
              <td>
                <v-text-field
                  v-model="classFilterValue"
                  clearable
                  style="width: 100px;"
                  @keyup="filterAll"
                />
              </td>
              <td colspan="3">
                <v-text-field
                  v-model="headFilterValue"
                  clearable
                  style="width: 150px;"
                  @keyup="filterAll"
                />
              </td>
            </tr>
          </template>
          <template #[`item.head`]="{ item }">
            {{ item.head.name }}
          </template>
          <template #[`item.actions`]="{ item }">
            <a @click="openDialog(item)">Назначить</a>
          </template>

          <template #[`item.building`]="{ item }">
            <v-select
              :items="buildings"
              density="compact"
              variant="solo"
              item-title="name"
              item-value="id"
              :model-value="item.building"
              hide-details
              @update:model-value="onChangeBuilding(item, $event)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <NutritionHeadChooseModal
      v-model:opened="headChooseModalOpen"
      :data2="headChooseModalData"
      @change-from-child="assingClassHead($event)"
    />
    <NutritionBuildingUpdateModal
      v-model:opened="buildingUpdateModalOpen"
      :data1="buildingUpdateModalData"
      :data2="null"
      @change-from-child="updateBuilding($event)"
    />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import NutritionHeadChooseModal from '../../widgets/modals/nutrition/NutritionHeadChooseModal.vue'
import NutritionBuildingUpdateModal from '../../widgets/modals/nutrition/NutritionBuildingUpdateModal.vue'
import Swal from 'sweetalert2'

type BaseData = {
  buildings: any;
  schoolClasses: any;
  filteredSchoolClasses: any;
  classFilterValue: string | null;
  headFilterValue: string | null;
  buildingFilterValue: number | null;
  headers: any;
  data: any;
  headChooseModalData: any;
  headChooseModalOpen: boolean;
  buildingUpdateModalData: any;
  buildingUpdateModalOpen: boolean;
}

export default defineComponent({
  components: {
    NutritionHeadChooseModal,
    NutritionBuildingUpdateModal
  },
  data (): BaseData {
    return {
      data: [],
      buildings: [],
      schoolClasses: [
        {
          id: 1,
          name: '1а',
          building: { id: 1, name: 'Корпус 1', address: 'Mira, 5', head: { id: 5, name: 'Куприн Степан Эдуардович' } },
          head: { id: 2, name: 'Сергеев Иван Иванович' }
        },
        {
          id: 2,
          name: '1б',
          building: { id: 1, name: 'Корпус 1', address: 'Mira, 5', head: { id: 5, name: 'Куприн Степан Эдуардович' } },
          head: { id: 3, name: 'Затевахин Иван Иванович' }
        },
        {
          id: 3,
          name: '1в',
          building: { id: 1, name: 'Корпус 1', address: 'Mira, 5', head: { id: 5, name: 'Куприн Степан Эдуардович' } },
          head: { id: 4, name: 'Путин Иван Иванович' }
        },
        {
          id: 4,
          name: '2в',
          building: { id: 2, name: 'Корпус 2', address: 'Mira, 5', head: { id: 5, name: 'Куприн Степан Эдуардович' } },
          head: { id: 5, name: 'Шарин Иван Иванович' }
        },
        {
          id: 5,
          name: '3в',
          building: { id: 3, name: 'Корпус 3', address: 'Mira, 5', head: { id: 5, name: 'Куприн Степан Эдуардович' } },
          head: { id: 6, name: 'Бенедиктов Иван Иванович' }
        }
      ],
      filteredSchoolClasses: [],
      classFilterValue: null,
      headFilterValue: null,
      buildingFilterValue: null,
      headers: [
        { text: 'Класс', value: 'name' },
        { text: 'Ф.И.О.', value: 'head' },
        { text: 'Действие', value: 'actions', sortable: false },
        { text: 'Корпус', value: 'building', sortable: false, width: '200px' }
      ],
      headChooseModalData: [],
      headChooseModalOpen: false,
      buildingUpdateModalData: null,
      buildingUpdateModalOpen: false
    }
  },
  mounted () {
    this.buildings = this.computedBuildings()
    this.headChooseModalData = this.computedClassHeads()
    this.buildingFilterValue = this.buildings[0].id
  },
  methods: {
    filterAll () {
      let filteredItems = JSON.parse(JSON.stringify(this.schoolClasses))
      if (this.classFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.name.includes(this.classFilterValue))
      }
      if (this.headFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.head.name.toLowerCase().includes(this.headFilterValue))
      }
      if (this.buildingFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.building.id === this.buildingFilterValue)
      }
      return filteredItems
    },
    assingBuildingFilterValue (item: any) {
      this.buildingFilterValue = Number(item.id)
      this.classFilterValue = null
      this.headFilterValue = null
    },
    updateBuilding (data: any) {
      this.updateBuildingsList(data)
    },
    updateBuildingsList (building: any) {
      let data = JSON.parse(JSON.stringify(this.schoolClasses))
      data = data.map((el: any) => {
        if (el.building.id === building.id) {
          el.building = building
        }
        return el
      })
      this.schoolClasses = data
      this.buildings = this.computedBuildings()
    },
    onChangeBuilding (classItem: any, buildingId: number) {
      const previousBuildingState = JSON.parse(JSON.stringify(classItem.building))
      const building = JSON.parse(JSON.stringify(this.buildings.find((el: any) => el.id === buildingId)))
      Swal.fire({
        title: '',
        html: '<h2>Изменить корпус на <br>' + building.name + '?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Успешно!', '', 'success')
          console.log('send on backend classId: ' + classItem.id + ', buildingId: ' + buildingId)
          this.fakeGetUpdatedData(classItem, 'building', building)
        } else if (result.isDenied) {
          Swal.fire('Отмена!', '', 'info')
          this.fakeGetUpdatedData(classItem, 'building', previousBuildingState)
        }
      })
    },
    fakeGetUpdatedData (item: any, prop: string, value: any) {
      const newData = JSON.parse(JSON.stringify(this.schoolClasses))
      const newItem = newData.find((el: any) => el.id === item.id)
      newItem[prop] = value
      this.schoolClasses = newData
    },
    openDialog () {
      this.headChooseModalOpen = true
    },
    assingClassHead (classId: number) {
      console.log('отправить на бэк обновленный класс с id=' + classId)
    },
    computedBuildings (): any {
      let all = JSON.parse(JSON.stringify(this.schoolClasses))
      all = all.map((el: any) => el.building)
      const unique = all.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), []).filter(Boolean)
      return unique
    },
    computedClassHeads (): any {
      const all = this.schoolClasses.map((el: any) => el.head)
      const unique = all.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), []).filter(Boolean)
      return unique
    },
    openDialog2 (item: any) {
      this.buildingUpdateModalOpen = true
      item.allBuildingHeads = this.computedClassHeads()
      this.buildingUpdateModalData = item
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>

<style scoped lang="scss">
  .block{
    background: #e4e4e4!important;
    text-transform: uppercase!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    width: 24%!important;
    font-size: 20px;
    height:80px!important;
    text-align:center;
    @media (max-width: 980px) {
      font-size: 20px;
      height:80px!important;
    }
    @media (max-width: 680px) {
      font-size: 16px;
      height:60px!important;
    }
    @media (max-width: 610px) {
      font-size: 14px;
      height:40px!important;
    }
  }
</style>
