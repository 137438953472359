<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-card-text>
          <v-container fluid>
            <h2>Редактирование данных по:</h2>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="buildingName"
                  class="mt-5"
                  label="Название"
                  hide-details
                />
                <v-text-field
                  v-model="buildingAddress"
                  class="mt-5"
                  label="Адрес"
                  hide-details
                />
                <v-btn
                  class="mt-5"
                  @click="saveBuilding"
                >
                  Сохранить
                </v-btn>
              </v-col>
              <v-col>
                <v-text-field
                  class="mt-5"
                  readonly
                  label="Ответственный за питание"
                  :model-value="buildingHead.name"
                  append-icon="mdi-lead-pencil"
                  @click:append="toggleSearch"
                />
                <!-- Поиск -->
                <v-row
                  v-if="searchVisible"
                  align="center"
                >
                  <v-col>
                    <v-data-table
                      ref="ordersTable"
                      dense
                      :headers="headers"
                      :items="tableData"
                      :search="search"
                      item-key="id"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      @click:row="rowClick($event)"
                    >
                      <template #[`body.prepend`]>
                        <tr>
                          <td>
                            <v-text-field
                              v-model="search"
                              prepend-icon="search"
                              style="width: 100%;"
                            />
                          </td>
                        </tr>
                      </template>
                      <template #[`item`]="{ item }">
                        <tr
                          :class="selected === item.id ? 'hov selectedItem' : 'hov'"
                          @click="rowClick(item)"
                        >
                          <td>{{ item.name }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <!-- Поиск end-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Swal from 'sweetalert2'
export default {
  props: {
    data1: {
      type: Object
    },
    data2: {
      type: Array
    },
    multipleSelect2: {
      type: Boolean
    },
    opened: {
      type: Boolean
    }
  },
  data: () => ({
    tableData: [],
    searchVisible: false,
    buildingId: null,
    buildingName: '',
    buildingAddress: '',
    buildingHead: '',
    selected: null,
    search: '',
    dialog: false,
    items1: [],
    headers: [
      { text: '', align: 'center', value: 'name', sortable: false }
    ],
    nameFilterValue: ''
  }),
  watch: {
    data1: function (val) {
      this.items1 = val
      this.buildingId = this.data1.id
      this.buildingName = this.data1.name
      this.buildingAddress = this.data1.address
      this.buildingHead = this.data1.head
      this.tableData = this.data1.allBuildingHeads
    },
    data2: function (val) {
      this.items2 = val
    },
    opened: function (val) {
      this.dialog = val
    },
    dialog: function (val) {
      if (!val) {
        this.$emit('update:opened', val)
      }
    }
  },
  mounted () {
    this.searchVisible = false
  },
  methods: {
    toggleSearch () {
      this.searchVisible = !this.searchVisible
    },
    rowClick (evnt) {
      this.selected = evnt.id
      this.question(evnt)
    },
    question (evnt) {
      Swal.fire({
        title: '',
        html: '<h2>Сменить ответственного за питание ' + this.buildingName + ' на ' + evnt.name + '?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Успешно!', '', 'success') // todo wait for response
          this.buildingHead = evnt // todo use whole evnt
          this.toggleSearch()
        } else if (result.isDenied) {
          Swal.fire('Отмена!', '', 'info')
        }
        this.selected = null
      })
    },
    saveBuilding () {
      this.$emit('update:opened', false)
      this.$emit('changeFromChild', { id: this.buildingId, name: this.buildingName, address: this.buildingAddress, head: this.buildingHead })
    }
  }
}
</script>
<style scoped lang="scss">
.inputLabel{
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
  .hov{
    cursor: pointer;
  }
  .selectedItem{
    background: #dddddd;
  }
</style>
