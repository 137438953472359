import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { GetPersonInfoRequest, GetPersonInfoResponse } from '@/shared/api/parents/myChildren/api-types'

enum MyChildrenRoutes {
  GetPersonInfo = '/person/getInfo'
}
const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const getPersonInfo = async (data: GetPersonInfoRequest, telegram: boolean): Promise<GetPersonInfoResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<GetPersonInfoResponse>(MyChildrenRoutes.GetPersonInfo, data, { headers })
    return result.data
  } else {
    const result = await axios.post<GetPersonInfoResponse>(MyChildrenRoutes.GetPersonInfo, data)
    return result.data
  }
}
