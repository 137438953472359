export interface MockByProductPerMonthTableItem {
  className: string;
  studentCount: number;
  productType: number;
  productItems: {
    day: number;
    count: number;
  }[];
}

export function getMockByProductPerMonthTableData () {
  return [
    {
      className: '10а',
      studentCount: 35,
      productType: 1,
      productItems: [
        {
          day: 1,
          count: 25
        },
        {
          day: 2,
          count: 26
        },
        {
          day: 3,
          count: 25
        },
        {
          day: 4,
          count: 26
        },
        {
          day: 5,
          count: 25
        },
        {
          day: 6,
          count: 26
        },
        {
          day: 7,
          count: 25
        },
        {
          day: 8,
          count: 26
        },
        {
          day: 9,
          count: 25
        },
        {
          day: 10,
          count: 26
        }
      ]
    },
    {
      className: '10б',
      studentCount: 32,
      productType: 1,
      teacherFullName: 'Иванова И.И.',
      productItems: [
        {
          day: 1,
          count: 23
        },
        {
          day: 2,
          count: 25
        },
        {
          day: 3,
          count: 23
        },
        {
          day: 4,
          count: 24
        },
        {
          day: 5,
          count: 24
        },
        {
          day: 6,
          count: 23
        },
        {
          day: 7,
          count: 25
        },
        {
          day: 8,
          count: 24
        },
        {
          day: 9,
          count: 24
        },
        {
          day: 10,
          count: 23
        }
      ]
    },
    {
      className: '10в',
      studentCount: 37,
      productType: 1,
      teacherFullName: 'Иванова И.И.',
      productItems: [
        {
          day: 1,
          count: 20
        },
        {
          day: 2,
          count: 21
        },
        {
          day: 3,
          count: 23
        },
        {
          day: 4,
          count: 18
        },
        {
          day: 5,
          count: 28
        },
        {
          day: 6,
          count: 25
        },
        {
          day: 7,
          count: 21
        },
        {
          day: 8,
          count: 26
        },
        {
          day: 9,
          count: 25
        },
        {
          day: 10,
          count: 23
        }
      ]
    },
    {
      className: '10а',
      studentCount: 35,
      productType: 2,
      teacherFullName: 'Иванова И.И.',
      productItems: [
        {
          day: 1,
          count: 10
        },
        {
          day: 2,
          count: 9
        },
        {
          day: 3,
          count: 11
        },
        {
          day: 4,
          count: 11
        },
        {
          day: 5,
          count: 11
        },
        {
          day: 6,
          count: 12
        },
        {
          day: 7,
          count: 15
        },
        {
          day: 8,
          count: 13
        },
        {
          day: 9,
          count: 12
        },
        {
          day: 10,
          count: 15
        }
      ]
    },
    {
      className: '10б',
      studentCount: 32,
      productType: 2,
      teacherFullName: 'Иванова И.И.',
      productItems: [
        {
          day: 1,
          count: 10
        },
        {
          day: 2,
          count: 12
        },
        {
          day: 3,
          count: 11
        },
        {
          day: 4,
          count: 11
        },
        {
          day: 5,
          count: 11
        },
        {
          day: 6,
          count: 13
        },
        {
          day: 7,
          count: 15
        },
        {
          day: 8,
          count: 13
        },
        {
          day: 9,
          count: 12
        },
        {
          day: 10,
          count: 15
        }
      ]
    },
    {
      className: '10в',
      studentCount: 37,
      productType: 2,
      teacherFullName: 'Иванова И.И.',
      productItems: [
        {
          day: 1,
          count: 10
        },
        {
          day: 2,
          count: 20
        },
        {
          day: 3,
          count: 21
        },
        {
          day: 4,
          count: 22
        },
        {
          day: 5,
          count: 20
        },
        {
          day: 6,
          count: 19
        },
        {
          day: 7,
          count: 18
        },
        {
          day: 8,
          count: 20
        },
        {
          day: 9,
          count: 21
        },
        {
          day: 10,
          count: 19
        }
      ]
    }
  ] as MockByProductPerMonthTableItem[]
}

export function getMockConsolidatedPerMonthTable () {
  return [
    {
      studentFullName: 'Арбузов Арбуз Арбузович',
      className: '10а',
      studentRatePlan: 1,
      productType: 1,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Борисов Борис Борисович',
      className: '10а',
      studentRatePlan: 1,
      productType: 1,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Васильев Василий Васильевич',
      className: '10б',
      studentRatePlan: 2,
      productType: 2,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Гордеев Гордей Гордеевич',
      className: '10б',
      studentRatePlan: 2,
      productType: 2,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Данилов Данил Данилович',
      className: '10а',
      studentRatePlan: 1,
      productType: 2,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Евгеньев Евгени Евгенеьевич',
      className: '10б',
      studentRatePlan: 1,
      productType: 2,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Кириллов Кирилл Кириллович',
      className: '10а',
      studentRatePlan: 2,
      productType: 1,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Яковлев Яков Яковович',
      className: '10б',
      studentRatePlan: 2,
      productType: 2,
      absenceMarks: [
        { day: 1, mark: 'В' },
        { day: 2, mark: 'В' },
        { day: 3, mark: ' ' },
        { day: 4, mark: ' ' },
        { day: 5, mark: ' ' },
        { day: 6, mark: ' ' },
        { day: 7, mark: ' ' },
        { day: 8, mark: 'В' },
        { day: 9, mark: 'В' },
        { day: 10, mark: ' ' },
        { day: 11, mark: ' ' },
        { day: 12, mark: ' ' },
        { day: 13, mark: ' ' },
        { day: 14, mark: ' ' },
        { day: 15, mark: 'В' },
        { day: 16, mark: 'В' },
        { day: 17, mark: ' ' },
        { day: 18, mark: ' ' },
        { day: 19, mark: ' ' },
        { day: 20, mark: ' ' },
        { day: 21, mark: ' ' },
        { day: 22, mark: 'В' },
        { day: 23, mark: 'В' },
        { day: 24, mark: ' ' },
        { day: 25, mark: ' ' },
        { day: 26, mark: ' ' },
        { day: 27, mark: ' ' },
        { day: 28, mark: ' ' },
        { day: 29, mark: 'В' },
        { day: 30, mark: 'В' }
      ],
      teacherFullName: 'Иванова М.И.'
    }
  ]
}

export function getMockByStudentAndCategoryPerMonthTable () {
  return [
    {
      studentFullName: 'Арбузов Арбуз Арбузович',
      className: '10а',
      studentRatePlan: 1,
      absenceMarks: [
        {
          productType: 1,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        },
        {
          productType: 2,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Борисов Борис Борисович',
      className: '10а',
      studentRatePlan: 2,
      absenceMarks: [
        {
          productType: 1,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        },
        {
          productType: 2,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Васильев Василий Васильевич',
      className: '10б',
      studentRatePlan: 1,
      absenceMarks: [
        {
          productType: 1,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        },
        {
          productType: 2,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        }
      ],
      teacherFullName: 'Иванова М.И.'
    },
    {
      studentFullName: 'Данилов Данил Данилович',
      className: '10б',
      studentRatePlan: 2,
      absenceMarks: [
        {
          productType: 1,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        },
        {
          productType: 2,
          marks: [
            { day: 1, mark: ' ' },
            { day: 2, mark: ' ' },
            { day: 3, mark: '+' },
            { day: 4, mark: '+' },
            { day: 5, mark: '+' },
            { day: 6, mark: '+' },
            { day: 7, mark: '+' },
            { day: 8, mark: ' ' },
            { day: 9, mark: ' ' },
            { day: 10, mark: '+' },
            { day: 11, mark: '+' },
            { day: 12, mark: '+' },
            { day: 13, mark: '+' },
            { day: 14, mark: '+' },
            { day: 15, mark: ' ' },
            { day: 16, mark: ' ' },
            { day: 17, mark: '+' },
            { day: 18, mark: '+' },
            { day: 19, mark: '+' },
            { day: 20, mark: '+' },
            { day: 21, mark: '+' },
            { day: 22, mark: ' ' },
            { day: 23, mark: ' ' },
            { day: 24, mark: '+' },
            { day: 25, mark: '+' },
            { day: 26, mark: '+' },
            { day: 27, mark: '+' },
            { day: 28, mark: '+' },
            { day: 29, mark: ' ' },
            { day: 30, mark: ' ' }
          ]
        }
      ],
      teacherFullName: 'Иванова М.И.'
    }
  ]
}

export interface ByProductPerMonthTableItem {
  className: string;
  studentRatePlans: {
    ratePlan: number;
    students: {
      studentFullName: string;
      presenceMarks: {
        productType: number;
        marks: {
          day: number;
          mark: string;
        }[];
      }[];
    }[];
  }[];
}

export function getMockByStudentAndCategoryPerMonthTableV2 () {
  return [
    {
      className: '10а',
      studentRatePlans: [
        {
          ratePlan: 1,
          students: [
            {
              studentFullName: 'Арбузов Арбуз',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Борисов Борис',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Васильев Василий',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            }
          ]
        },
        {
          ratePlan: 2,
          students: [
            {
              studentFullName: 'Данилов Данил',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Гордеев Гордей',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Яковлев Яков',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      className: '10б',
      studentRatePlans: [
        {
          ratePlan: 1,
          students: [
            {
              studentFullName: 'Арбузов Арбуз',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Борисов Борис',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Васильев Василий',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            }
          ]
        },
        {
          ratePlan: 2,
          students: [
            {
              studentFullName: 'Данилов Данил',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Гордеев Гордей',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            },
            {
              studentFullName: 'Яковлев Яков',
              presenceMarks: [
                {
                  productType: 1,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                },
                {
                  productType: 2,
                  marks: [
                    { day: 1, mark: ' ' },
                    { day: 2, mark: ' ' },
                    { day: 3, mark: '+' },
                    { day: 4, mark: '+' },
                    { day: 5, mark: '+' },
                    { day: 6, mark: '+' },
                    { day: 7, mark: '+' },
                    { day: 8, mark: ' ' },
                    { day: 9, mark: ' ' },
                    { day: 10, mark: '+' },
                    { day: 11, mark: '+' },
                    { day: 12, mark: '+' },
                    { day: 13, mark: '+' },
                    { day: 14, mark: '+' },
                    { day: 15, mark: ' ' },
                    { day: 16, mark: ' ' },
                    { day: 17, mark: '+' },
                    { day: 18, mark: '+' },
                    { day: 19, mark: '+' },
                    { day: 20, mark: '+' },
                    { day: 21, mark: '+' },
                    { day: 22, mark: ' ' },
                    { day: 23, mark: ' ' },
                    { day: 24, mark: '+' },
                    { day: 25, mark: '+' },
                    { day: 26, mark: '+' },
                    { day: 27, mark: '+' },
                    { day: 28, mark: '+' },
                    { day: 29, mark: ' ' },
                    { day: 30, mark: ' ' }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ] as ByProductPerMonthTableItem[]
}
