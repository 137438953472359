<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col v-if="items1.length > 0">
                <v-select
                  v-model="chosenClass"
                  :items="items1"
                  label="Выберите класс"
                  density="compact"
                  item-title="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <!-- поиск ответственного или классного -->
              <v-col>
                <v-data-table
                  ref="ordersTable"
                  v-model="selectedRows"
                  :show-select="items1.length > 0"
                  dense
                  :headers="headers"
                  :items="items2"
                  :search="search"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  hide-default-header
                >
                  <template #[`body.prepend`]>
                    <tr>
                      <td colspan="2">
                        <v-text-field
                          v-model="search"
                          prepend-icon="search"
                          style="width: 100%;"
                        />
                      </td>
                    </tr>
                  </template>
                  <template
                    v-if="items1.length === 0"
                    #[`item`]="{ item }"
                  >
                    <tr
                      :class="selected === item.id ? 'hov selectedItem' : 'hov'"
                      @click="rowClick(item)"
                    >
                      <td>{{ item.name }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-if="items1.length > 0">
              <v-col align="center">
                <v-btn @click="addSubHeads()">
                  Добавить
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Swal from 'sweetalert2'
export default {
  props: {
    data1: {
      type: Array
    },
    data2: {
      type: Array
    },
    multipleSelect2: {
      type: Boolean
    },
    opened: {
      type: Boolean
    }
  },
  data: () => ({
    chosenClass: null,
    selectedRows: [], // altclassheads
    selected: null, // classheads
    search: '',
    dialog: false,
    openedModal: false,
    filteredItems2: [],
    items1: [],
    items2: [],
    headers: [
      { text: '', align: 'center', value: 'name', sortable: false }
    ],
    nameFilterValue: ''
  }),
  watch: {
    data1: function (val) {
      console.log(val)
      this.items1 = val
    },
    data2: function (val) {
      this.items2 = val
    },
    opened: function (val) {
      this.dialog = val
    },
    dialog: function (val) {
      if (!val) {
        this.$emit('update:opened', val)
      }
    }
  },
  mounted () {
    //
  },
  methods: {
    // nutrition/classheads only:
    rowClick (evnt) {
      this.selected = evnt.id
      this.question(evnt)
    },
    question (evnt) {
      Swal.fire({
        title: '',
        html: '<h2>Сменить классного руководителя на <br>' + evnt.name + '?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Успешно!', '', 'success')
          this.$emit('changeFromChild', evnt.id)
          this.$emit('update:opened', false)
        } else if (result.isDenied) {
          Swal.fire('Отмена!', '', 'info')
        }
        this.selected = null
      })
    },
    // nutrition/altclassheads only:
    addSubHeads () {
      if (!this.chosenClass) {
        Swal.fire('Выберите класс!', '', 'info')
      } else {
        this.$emit('changeFromChild', { id: this.chosenClass, subHeads: JSON.parse(JSON.stringify(this.selectedRows)) })
        this.selectedRows = []
        this.$emit('update:opened', false)
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .hov{
    cursor: pointer;
  }
  .selectedItem{
    background: #dddddd;
  }
</style>
