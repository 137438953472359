<template>
  <div>
    <filters-panel
      :filter-options="filterOptions"
      :filter-state="filterState"
      @filter-changed-state="filtersChangeHandler"
    />
    <v-btn
      class="button-load"
      elevation="2"
      :disabled="isLoading"
      @click="loadTable"
    >
      Показать
    </v-btn>
    <template v-if="!isLoading">
      <template v-if="tableData.items">
        <v-btn
          class="button-print"
          elevation="2"
          @click="print"
        >
          ПЕЧАТЬ
        </v-btn>
        <export-excel
          :table-data="tableData"
          :title="'Табель по ученикам и категориям'"
        />
        <div class="justify-content-center">
          <span :class="errorCount == 0 ? '' : 'text-warning'">
            Ошибки в талонах: {{ errorCount }}
          </span>
        </div>
        <div class="justify-content-center">
          <span :class="notAcceptCount == 0 ? '' : 'text-warning'">
            Неучтенных талонов: {{ notAcceptCount }}
          </span>
        </div>
        <div class="justify-content-center">
          <span :class="notTeacherHistoryCount == 0 ? '' : 'text-warning'">
            Не подписаны классным руководителем: {{ notTeacherHistoryCount }}
          </span>
        </div>
        <div class="justify-content-center">
          <span :class="notLadminHistoryCount == 0 ? '' : 'text-warning'">
            Не подписаны ответственным за питание: {{ notLadminHistoryCount }}
          </span>
        </div>
        <div class="justify-content-center">
          <span :class="talonProductCount == allSalesCount ? '' : 'text-warning'">
            Количество фактов по данным талонов: {{
              talonProductCount
            }} - Количество фактов по данным учета: {{ allSalesCount }}
          </span>
        </div>
        <div class="justify-content-center">
          <span :class="talonProductSum.toFixed(2) == allSalesSum.toFixed(2) ? '' : 'text-warning'">
            Сумма по данным талонов: {{ talonProductSum.toFixed(2) }} - Сумма по данным учета: {{
              allSalesSum.toFixed(2)
            }}
          </span>
        </div>
        <div
          id="printable-area"
          :style="{marginTop: '40px'}"
        >
          <div
            class="print-table"
            :style="{ display: 'flex', justifyContent: 'center'}"
          >
            <div
              :style="{display:'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', width: 'max-content'}"
            >
              <div
                :style="{alignSelf: 'end'}"
                class="table_header only-for-print"
              >
                <p :style="{textAlign: 'right', width: '350px'}">
                  Утверждаю <br>
                  {{ placeInfo.directorPosition ? placeInfo.directorPosition : 'Директор' }} <br>
                  {{ `${ placeInfo.directorName ? placeInfo.directorName : '' }` }} <br> __________________
                </p>
              </div>
              <div
                :style="{textAlign: 'center'}"
                class="only-for-print"
                v-html="title"
              />
              <h3
                :style="{textAlign: 'center'}"
                class="only-for-screen"
              >
                Табель по ученикам и категориям
              </h3>
              <base-simple-table :table-data="tableData">
                <template #slot-after-fullName>
                  <div
                    class="slot-after only-for-screen"
                    @click="fullNameSortChangeHandler"
                  >
                    <v-icon :class="`slot-after__icon ${fullNameInAlphabet ? '' : 'slot-after__icon_reverse'}`">
                      arrow_drop_down
                    </v-icon>
                  </div>
                </template>
                <template #signs>
                  <div :style="{display: 'flex',alignSelf: 'start', flexDirection: 'column'}">
                    <p>
                      {{
                        `Ответственный за питание __________________//${ placeInfo.ladminName ? placeInfo.ladminName : '' }`
                      }}
                    </p>
                    <template v-if="filterState.selectedClass !== 0">
                      <p>
                        {{ `Классный руководитель __________________//` }}
                      </p>
                    </template>
                  </div>
                </template>
              </base-simple-table>
            </div>
          </div>
        </div>
      </template>
      <template
        v-else-if="convertedRawApiData.length > 0 || (convertedRawApiData.length === 0 && (dataIsLoaded)) || notFirstRender"
      >
        <h3
          :style="{textAlign: 'center'}"
          class="only-for-screen"
        >
          По заданным фильтрам не найдено ни одной записи!
        </h3>
      </template>
    </template>
    <template v-else>
      <v-row>
        <v-col
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import { defineComponent } from 'vue'

import FiltersPanel, { FilterNames, FilterOptions } from '@/shared/uikit/FiltersPanel.vue'
import moment from 'moment/moment'
import { AxiosError } from 'axios'
import { mapActions } from 'vuex'
import { findReportSalesByTalons } from '@/shared/api/salelist/api-requests'
import { findWithItemsAndHistory } from '@/shared/api/placeElectronTalon/api-requests'
import { printer } from '@/shared/lib/print'
import { find } from '@/shared/api/customerPlace/api-requests'
import { placeFindByDateWithName } from '@/shared/api/places/api-requests'
import compareClasses from '@/shared/lib/compareClasses'
import { sortInAlphabetOrder } from '@/shared/lib/sorter'
import ExportExcel from '@/shared/uikit/ExportExcel.vue'

export default defineComponent({
  name: 'ByStudentAndCategoryPerMonthTable',
  components: { ExportExcel, FiltersPanel, BaseSimpleTable },
  data () {
    return {
      errorCount: 0,
      notAcceptCount: 0,
      notTeacherHistoryCount: 0,
      notLadminHistoryCount: 0,
      talonProductCount: 0,
      allSalesCount: 0,
      talonProductSum: 0,
      allSalesSum: 0,
      placeInfo: {} as any,
      isLoading: false,
      renderFilterUpdated: false, // флаг, отвечающий за перерисовку таблицы при изменении фильтров, не требующих подгрузки данных с API (классы, льготные категории и т.д.)
      apiFilterUpdated: true, // флаг, отвечающий за перерисовку таблицы при изменении фильтров, требующих подгрузки данных с API (школа, дата и т.п.)
      notFirstRender: false,
      filterOptions: {
        classFilter: true,
        productTypeFilter: true,
        ratePlanFilter: true,
        monthFilter: true,
        yearFilter: true,
        placeFilter: true,
        classItems: [],
        productTypeItems: [],
        ratePlanItems: [],
        placeItems: []
      } as FilterOptions,
      filterState: {
        placeFilter: 0,
        classFilter: 0,
        productTypeFilter: 0,
        ratePlanFilter: 0,
        monthFilter: moment().month(),
        yearFilter: moment().year(),
        initialState: false
      } as any,
      title: '',
      tableData: {} as TableData,
      convertedRawApiData: [] as any,
      fullNameInAlphabet: true // Сортировка по ФИО в алфавитном порядке (false - в обратном)
    }
  },
  computed: {
    dataIsLoaded (): boolean {
      return Boolean(this.filterOptions.classItems.length > 1 && this.filterOptions.ratePlanItems.length > 1 && this.filterOptions.productTypeItems.length > 1)
    }
  },
  async beforeMount () {
    this.isLoading = true
    const lastDayOfSelectedMonth = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
    const userAllowedPlaces = await this.userAllowedPlaceAll()
    this.filterOptions.placeItems = userAllowedPlaces
    this.filterState[FilterNames.PLACE_FILTER] = userAllowedPlaces[0].placeId
    this.placeInfo = await this.loadCustomerPlaceInfo(this.filterState[FilterNames.PLACE_FILTER], lastDayOfSelectedMonth)
    await this.updateClassItems()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar ', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    fullNameSortChangeHandler (): void {
      this.fullNameInAlphabet = !this.fullNameInAlphabet
      this.resortConvertedRawAPiDate()
    },
    resortConvertedRawAPiDate () {
      // const tempData = JSON.parse(JSON.stringify(this.convertedRawApiData))
      this.convertedRawApiData.forEach((classItem: any) => {
        classItem.studentRatePlans.forEach((ratePlanItem: any) => {
          const sortedStudents = sortInAlphabetOrder(ratePlanItem.students, 'studentFullName')
          if (!this.fullNameInAlphabet) {
            sortedStudents.reverse()
          }
          ratePlanItem.students = sortedStudents
        })
      })
      const filteredData = this.filterRawApiDate(this.convertedRawApiData)
      this.renderTableData(filteredData)
    },
    async filtersChangeHandler (filterName: FilterNames, oldValue: any, newValue: any) {
      // Подгрузка обновленного стейта фильтров и дальнейшая установка флагов о неободимости получения данных с API/перерендоре таблицы
      this.filterState[filterName] = newValue
      if ([FilterNames.YEAR_FILTER, FilterNames.MONTH_FILTER, FilterNames.PLACE_FILTER].includes(filterName)) {
        this.apiFilterUpdated = true
      } else if ([FilterNames.CLASS_FILTER, FilterNames.RATE_PLAN_FILTER, FilterNames.PRODUCT_TYPE_FILTER].includes(filterName)) {
        this.renderFilterUpdated = true
      }
    },
    async loadTable () {
      try {
        if (this.apiFilterUpdated) {
          const lastDayOfSelectedMonth = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
          const userAllowedPlaces = await this.userAllowedPlaceAll()
          this.placeInfo = await this.loadCustomerPlaceInfo(this.filterState[FilterNames.PLACE_FILTER], lastDayOfSelectedMonth)
          const dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).startOf('month').format('YYYY-MM-DD')
          const dateTo = moment(`${dateFrom}`).add(1, 'M').startOf('month').format('YYYY-MM-DD')
          // запускаем подгрузку данных по самому табелю и ошибкам параллельно
          const dataFetched = await Promise.allSettled([
            this.getHistoryData(this.placeInfo.placeId.toString(), dateFrom, dateTo),
            findReportSalesByTalons({
              placeId: this.placeInfo.placeId.toString(),
              dateFrom: dateFrom,
              dateTo: dateTo
            })
          ]) as any
          const dataFromApi = dataFetched[1].value
          this.convertedRawApiData = this.convertFromRawApi(dataFromApi, this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER])
        }

        // Фильтрация данных
        const filteredData = this.filterRawApiDate(this.convertedRawApiData)
        // Отрисовка таблицы
        if (this.apiFilterUpdated || this.renderFilterUpdated) {
          this.notFirstRender = true
          this.isLoading = true
          this.renderTableData(filteredData)
          this.apiFilterUpdated = false
          this.renderFilterUpdated = false
          this.isLoading = false
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    renderTableData (filteredData: any) {
      // Конвертация данных в ячейки таблицы

      this.tableData = this.convertToTableData(filteredData)
      this.title =
        `
            <div style="width: 100%">
            <div>
            <h3>Табель питания</h3>
            <p style="font-size: 12px; margin: 0;"> за ${moment().locale('ru').month(this.filterState[FilterNames.MONTH_FILTER]).format('MMMM')} ${this.filterState[FilterNames.YEAR_FILTER]} г.</p>
            </div>
            <div style="width: 100%; display: flex; margin-bottom: 20px;">
                <div style="width: fit-content"><p style="font-size: 12px; margin: 0;">Учреждение</p></div>
                <div style="width: 100%; border-bottom: 1px solid black; margin-left: 5px;"><p style="font-size: 12px; margin: 0; width: max-content;/*! width: 100px; */word-wrap: break-word;text-decoration: underline;text-underline-offset: 5px;max-width: 750px;text-align: left;min-width: 600px;  line-height: 18px;}">${this.placeInfo.fullName ? this.placeInfo.fullName : ''}</p></div>
            </div>
            </div>
       `
    },
    filterRawApiDate (rawApiDate: any) {
      let tempFilteredData = JSON.parse(JSON.stringify(rawApiDate))
      // console.log('Фильтрация по классу!')
      if (this.renderFilterUpdated) {
        if (this.filterState[FilterNames.CLASS_FILTER] !== 0) {
          const classObj = this.filterOptions.classItems.find((el: any) => el.id === this.filterState[FilterNames.CLASS_FILTER]) ?? {
            id: 0,
            name: 'Все'
          }
          tempFilteredData = tempFilteredData.filter((el: any) => el.className === classObj.name)
        }
        // console.log('Фильтрация по типу питания!')
        if (this.filterState[FilterNames.PRODUCT_TYPE_FILTER] !== 0) {
          tempFilteredData = tempFilteredData.map((el: any) => {
            const item = JSON.parse(JSON.stringify(el))
            item.studentRatePlans = el.studentRatePlans.map((ratePlanObj: any) => {
              const item2 = JSON.parse(JSON.stringify(ratePlanObj))
              item2.students = ratePlanObj.students.map((studentObj: any) => {
                const item3 = JSON.parse(JSON.stringify(studentObj))
                const filteredPresenceMarks = studentObj.presenceMarks.filter((presenceMarkObj: any) => {
                  return presenceMarkObj.productType === this.filterState[FilterNames.PRODUCT_TYPE_FILTER]
                })
                if (filteredPresenceMarks.length > 0) {
                  item3.presenceMarks = filteredPresenceMarks
                  return item3
                }
              }).filter((student: any) => student !== undefined)
              return item2
            }).filter((studentRatePlan: any) => studentRatePlan.students.length > 0)
            return item
          }).filter((el: any) => el.studentRatePlans.length > 0)
        }
        // console.log('Фильтрация по льготной категории!')
        if (this.filterState[FilterNames.RATE_PLAN_FILTER] !== 0) {
          tempFilteredData = tempFilteredData.map((el: any) => {
            const item = JSON.parse(JSON.stringify(el))
            const filteredRatePlans = el.studentRatePlans.filter((ratePlanObj: any) => {
              return ratePlanObj.ratePlanCode.toString() === this.filterState[FilterNames.RATE_PLAN_FILTER].toString()
            })
            if (filteredRatePlans.length > 0) {
              item.studentRatePlans = filteredRatePlans
              return item
            }
          }).filter((el: any) => {
            return el !== undefined
          })
        }
      }
      return tempFilteredData
    },
    async getHistoryData (placeId: string, dateFrom: string, dateTo: string) {
      try {
        this.errorCount = 0
        this.notAcceptCount = 0
        this.notTeacherHistoryCount = 0
        this.notLadminHistoryCount = 0
        this.talonProductCount = 0
        this.allSalesCount = 0
        this.talonProductSum = 0
        this.allSalesSum = 0
        this.isLoading = true
        if (placeId && dateFrom && dateTo) {
          const result = await findWithItemsAndHistory({
            parentPlaceId: +placeId,
            dateTo: dateTo,
            dateFrom: dateFrom
          })
          if (Array.isArray(result.placeEletronTalons)) {
            const placeElectronTalons = result.placeEletronTalons
            const sales = result.sales
            sales.count.forEach((count: any) => {
              this.allSalesCount += Number(count.quantity)
            })
            sales.sum.forEach((sum: any) => {
              this.allSalesSum += Number(Number(sum.sum).toFixed(2))
            })
            placeElectronTalons.forEach((placeElectronTalon: any) => {
              placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
                if (placeElectronTalonItem.removed == null) {
                  placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
                    if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                      this.notAcceptCount++
                    }
                    if (placeElectronTalonItemSale.removed == null) {
                      this.talonProductCount++
                      let talonsum = 0
                      placeElectronTalon.placeElectronTalonProducts.forEach((placeElectronTalonProduct: any) => {
                        if (placeElectronTalonProduct.removed == null && Number(placeElectronTalonProduct.productId) === Number(placeElectronTalonItemSale.productId)) {
                          this.talonProductSum = Number(this.talonProductSum.toFixed(2)) + Number(Number(placeElectronTalonProduct.price).toFixed(2))
                          talonsum = Number(talonsum.toFixed(2)) + Number(Number(placeElectronTalonProduct.price).toFixed(2))
                        }
                      })
                    }
                  })
                }
                if (placeElectronTalonItem.error != null) {
                  if (String(placeElectronTalonItem.error) !== '15') {
                    this.errorCount++
                  }
                }
              })
              let teacherExists = 0
              let ladminExists = 0
              placeElectronTalon.placeElectronTalonHistoryItems.forEach((placeElectronTalonHistoryItem: any) => {
                if (placeElectronTalonHistoryItem.status === 1) {
                  if (Number(placeElectronTalonHistoryItem.userTypeId) === 3) {
                    teacherExists = 1
                  }
                  if (Number(placeElectronTalonHistoryItem.userTypeId) === 7) {
                    ladminExists = 1
                  }
                }
              })
              if (teacherExists === 0) {
                this.notTeacherHistoryCount++
              }
              if (ladminExists === 0) {
                this.notLadminHistoryCount++
              }
            })
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo (placeId: any, date: any) {
      try {
        if (placeId !== 0) {
          const result = await find(
            {
              placeId: placeId,
              date: date
            }
          )
          return result
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },

    checkRatePlanAgreeMonthFilter (ratePlanDateFrom: string, ratePlanDateTo: string): boolean {
      let res = false
      const dateFrom = moment({year: this.filterState[FilterNames.YEAR_FILTER], month: this.filterState[FilterNames.MONTH_FILTER]}).startOf('month').format('YYYY-MM-DD')
      const dateTo = moment(`${dateFrom}`).add(1, 'M').startOf('month').format('YYYY-MM-DD')
      // console.log('checkRatePlanAgreeMonthFilter', dateFrom, dateTo, ratePlanDateFrom, ratePlanDateTo)
      if (moment(dateFrom).isBetween(ratePlanDateFrom, ratePlanDateTo) || moment(dateTo).isBetween(ratePlanDateFrom, ratePlanDateTo) ||
        (moment(dateFrom).isSameOrBefore(ratePlanDateTo) && moment(dateTo).isSameOrAfter(ratePlanDateTo))
      ) {
        res = true
      }
      return res
    },

    convertFromRawApi (data: any, selectedYear: any, selectedMonth: any) {
      // console.log('convertFromRawApi')
      const res: any[] = []
      const days: any[] = []
      const ratePlans: any[] = []
      const studentsFullNames: any[] = []
      const productTypes: any[] = []
      const monthDate = moment({year: selectedYear, month: selectedMonth})
      const daysInMonth = monthDate.daysInMonth()
      const arrDays: any[] = []
      for (let day = 1; day <= daysInMonth; day++) {
        const current = monthDate.date(day)
        arrDays.push(current.format('YYYY-MM-DD'))
      }
      data.placeElectronTalons.forEach((placeElectronTalon: any) => {
        if (!days.includes(placeElectronTalon.talonDate)) {
          days.push(placeElectronTalon.talonDate)
        }
        const fullName = `${placeElectronTalon.lastName} ${placeElectronTalon.firstName} ${placeElectronTalon.userId}`
        if (studentsFullNames.findIndex((student: any) => student.fullName === fullName) === -1) {
          const ratePlans = data.userRatePlans.filter((userRatePlan: any) => {
            return userRatePlan.userId.toString() === placeElectronTalon.userId.toString()
          })
          // console.log(ratePlans)
          // Иногда прилетают дети без записи по своему userId в userRatePlans
          if (ratePlans.length > 0) {
            let maxDate = moment('1970-1-1')
            let userRatePlan = ratePlans[ratePlans.length - 1].ratePlanId
            ratePlans.forEach((ratePlan: any) => {
              if (moment(ratePlan.creationDate).isAfter(moment(maxDate)) && this.checkRatePlanAgreeMonthFilter(ratePlan.startTime, ratePlan.endTime)) {
                userRatePlan = ratePlan
                maxDate = moment(ratePlan.creationDate)
              }
            })
            if (ratePlans.length > 0) {
              studentsFullNames.push({
                fullName: fullName,
                userId: placeElectronTalon.userId,
                ratePlan: userRatePlan.ratePlanId,
                placeId: placeElectronTalon.talonPlaceId
              })
            }
          }
        }
        // Делаем список всех видов питания, при этом сразу далаем те виды питания, по которым нет записей в placeElectronTalon (productType.id === placeElectronTalon.talonProductId)
        if (productTypes.findIndex((productType: any) => productType.id === placeElectronTalon.talonProductId) === -1) {
          const productItem = data.placeElectronTalonProducts.find((placeElectronTalonProduct: any) => placeElectronTalonProduct.productId === placeElectronTalon.talonProductId)
          productTypes.push({
            id: placeElectronTalon.talonProductId,
            name: productItem.product.name,
            code: productItem.product.code
          })
        }
      })
      data.ratePlans.forEach((ratePlan: any) => {
        if (ratePlans.findIndex((ratePlanItem: any) => ratePlanItem.id === ratePlan.code) === -1) {
          ratePlans.push({
            id: ratePlan.code,
            ratePlanId: ratePlan.id,
            name: ratePlan.name
          })
        }
      })
      data.places.forEach((place: any) => {
        const studentRatePlans: { ratePlan: any; ratePlanName: any; students: any[] }[] = []
        data.ratePlans.forEach((ratePlan: any) => {
          const students: any[] = []
          const studentFullNamesArr = studentsFullNames.filter((studentFullNameObj: any) => studentFullNameObj.ratePlan === ratePlan.id && studentFullNameObj.placeId === place.id)
          if (studentFullNamesArr.length > 0) {
            studentFullNamesArr.forEach((studentFullNameObj: any) => {
              const presenceMarksArr: any[] = []
              productTypes.forEach((productType: any) => {
                const baseMarksObj = data.placeElectronTalons.filter((placeElectronTalon: any) => {
                  return placeElectronTalon.userId === studentFullNameObj.userId && placeElectronTalon.talonProductId === productType.id
                })
                const marks: any[] = []
                let hasPresence = false
                arrDays.forEach((dayOfMonth: any) => {
                  if (baseMarksObj.findIndex((baseDayMark: any) => baseDayMark.talonDate === dayOfMonth) === -1) {
                    marks.push({
                      day: dayOfMonth.split('-')[2],
                      mark: ' '
                    })
                  } else {
                    hasPresence = true
                    marks.push({
                      day: dayOfMonth.split('-')[2],
                      mark: '+'
                    })
                  }
                })
                if (marks.length > 0 && hasPresence) {
                  presenceMarksArr.push(
                    {
                      productType: productType.id,
                      productName: productType.name, // TODO: поправил на вывод конкретного имени продукта, заменить ли просто на завтрак/обед (name => code)
                      marks: marks
                    }
                  )
                }
              })
              students.push({
                studentFullName: studentFullNameObj.fullName,
                presenceMarks: presenceMarksArr
              })
            })
            const sortedStudents = sortInAlphabetOrder(students, 'studentFullName')
            if (!this.fullNameInAlphabet) {
              sortedStudents.reverse()
            }
            const ratePlanObj = {
              ratePlan: ratePlan.id,
              ratePlanName: ratePlan.name,
              ratePlanCode: ratePlan.code,
              students: sortedStudents
            }
            studentRatePlans.push(ratePlanObj)
          }
        })
        const placeItem = {
          className: place.name,
          studentRatePlans: studentRatePlans
        }
        res.push(placeItem)
      })
      productTypes.unshift({
        id: 0,
        name: 'Все'
      })
      this.updateClassItems()
      this.filterOptions.productTypeItems = productTypes
      ratePlans.unshift({
        id: 0,
        name: 'Все'
      })
      this.filterOptions.ratePlanItems = ratePlans
      res.sort((a: any, b: any) => compareClasses(a.className, b.className))
      return res
    },
    convertToTableData (rawData: any) {
      // Перевод сырых табличных данных, полученных с API, в формат, который понимает GRID-таблиица
      if (rawData.length > 0) {
        const res = {
          columnsCount: rawData[0].studentRatePlans[0].students[0].presenceMarks[0].marks.length + 6,
          rowsCount: 0,
          items: []
        } as TableData
        res.items.push(
          {
            text: '№ п.п.',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 1,
            gridColumnEnd: 2
          },
          {
            text: 'ФИО',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            slotAfter: {
              name: 'fullName',
              classNames: 'slot-after'
            }
          },
          {
            text: 'Класс',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 3,
            gridColumnEnd: 4
          },
          {
            text: 'Вид питания',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 4,
            gridColumnEnd: 5
          },
          {
            text: 'Дата',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 5,
            gridColumnEnd: rawData[0].studentRatePlans[0].students[0].presenceMarks[0].marks.length + 5
          },
          {
            text: 'Всего',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: rawData[0].studentRatePlans[0].students[0].presenceMarks[0].marks.length + 5,
            gridColumnEnd: rawData[0].studentRatePlans[0].students[0].presenceMarks[0].marks.length + 6
          }
        )
        let totalRowCount = 0
        const totalColumnCount = rawData[0].studentRatePlans[0].students[0].presenceMarks[0].marks.length + 6
        let currentRow = 3
        let studentIdx = 1
        const totalDayCounts = {} as any
        rawData.forEach((studentClass: any, rowIdx: number) => {
          // let totalCount = 0
          const studentClassTotal = {} as any
          res.items.push({
            text: `Класс: ${studentClass.className}`,
            background: '#C0C0C0',
            gridRowStart: currentRow,
            gridRowEnd: currentRow + 1,
            gridColumnStart: 1,
            gridColumnEnd: totalColumnCount
          })
          totalRowCount += 1
          currentRow += 1
          studentClass.studentRatePlans.forEach((studentRatePlan: any) => {
            res.items.push({
              text: `Категория льгот: ${studentRatePlan.ratePlanCode}`,
              background: '#91e38f',
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 1,
              gridColumnEnd: totalColumnCount
            })
            totalRowCount += 1
            currentRow += 1
            studentRatePlan.students.forEach((student: any) => {
              res.items.push(
                {
                  text: `${studentIdx}`,
                  background: 'none',
                  fontWeight: 'normal',
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + student.presenceMarks.length,
                  gridColumnStart: 1,
                  gridColumnEnd: 2
                },
                {
                  text: `${student.studentFullName.split(' ').slice(0, 2).join(' ')}`,
                  background: 'none',
                  fontWeight: 'normal',
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + student.presenceMarks.length,
                  gridColumnStart: 2,
                  gridColumnEnd: 3
                },
                {
                  text: `${studentClass.className}`,
                  background: 'none',
                  fontWeight: 'normal',
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + student.presenceMarks.length,
                  gridColumnStart: 3,
                  gridColumnEnd: 4
                }
              )
              student.presenceMarks.forEach((presenceMark: any) => {
                const productTypeName = presenceMark.productName.toLowerCase()
                res.items.push(
                  {
                    text: `${productTypeName}`,
                    background: 'none',
                    fontWeight: 'normal',
                    gridRowStart: currentRow,
                    gridRowEnd: currentRow + 1,
                    gridColumnStart: 4,
                    gridColumnEnd: 5
                  }
                )
                let totalPresenceMarks = 0
                presenceMark.marks.forEach((mark: any, markIdx: number) => {
                  if (currentRow === 5) {
                    res.items.push(
                      {
                        text: `${mark.day}`,
                        background: 'none',
                        fontWeight: 'normal',
                        gridRowStart: 2,
                        gridRowEnd: 3,
                        gridColumnStart: markIdx + 5,
                        gridColumnEnd: markIdx + 6
                      }
                    )
                  }
                  if (mark.mark === '+') {
                    totalPresenceMarks += 1
                    if (studentClassTotal[productTypeName]) {
                      if (studentClassTotal[productTypeName][markIdx]) {
                        studentClassTotal[productTypeName][markIdx] += 1
                      } else {
                        studentClassTotal[productTypeName][markIdx] = 1
                      }
                    } else {
                      studentClassTotal[productTypeName] = {
                        [markIdx]: 1
                      }
                    }
                  } else {
                    if (studentClassTotal[productTypeName]) {
                      if (studentClassTotal[productTypeName][markIdx]) {
                        studentClassTotal[productTypeName][markIdx] += 0
                      } else {
                        studentClassTotal[productTypeName][markIdx] = 0
                      }
                    } else {
                      studentClassTotal[productTypeName] = {
                        [markIdx]: 0
                      }
                    }
                  }
                  res.items.push(
                    {
                      text: `${mark.mark}`,
                      background: 'none',
                      fontWeight: 'normal',
                      gridRowStart: currentRow,
                      gridRowEnd: currentRow + 1,
                      gridColumnStart: markIdx + 5,
                      gridColumnEnd: markIdx + 6
                    }
                  )
                })
                res.items.push(
                  {
                    text: `${totalPresenceMarks}`,
                    background: 'none',
                    fontWeight: 'normal',
                    gridRowStart: currentRow,
                    gridRowEnd: currentRow + 1,
                    gridColumnStart: totalColumnCount - 1,
                    gridColumnEnd: totalColumnCount
                  }
                )
                if (studentClassTotal[productTypeName].total) {
                  studentClassTotal[productTypeName].total += totalPresenceMarks
                } else {
                  studentClassTotal[productTypeName].total = totalPresenceMarks
                }
                currentRow += 1
                totalRowCount += 1
              })
              studentIdx += 1
            })
          })
          const studentClassTotalProducts = Object.keys(studentClassTotal)
          res.items.push(
            {
              text: `Итого по классу "${studentClass.className}":`,
              background: '#faebd7',
              fontWeight: 'bold',
              gridRowStart: currentRow,
              gridRowEnd: currentRow + studentClassTotalProducts.length,
              gridColumnStart: 1,
              gridColumnEnd: 4
            }
          )
          studentClassTotalProducts.forEach((product: any) => {
            res.items.push(
              {
                text: `${product}`,
                background: '#faebd7',
                fontWeight: 'bold',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 4,
                gridColumnEnd: 5
              }
            )
            const productDayCounts = Object.keys(studentClassTotal[product])
            productDayCounts.forEach((dayCount: any, dayCountIdx: number) => {
              if (totalDayCounts[product]) {
                if (totalDayCounts[product][dayCount]) {
                  totalDayCounts[product][dayCount] += studentClassTotal[product][dayCount]
                } else {
                  totalDayCounts[product][dayCount] = studentClassTotal[product][dayCount]
                }
              } else {
                totalDayCounts[product] = {
                  [dayCount]: studentClassTotal[product][dayCount]
                }
              }
              res.items.push(
                {
                  text: `${studentClassTotal[product][dayCount]}`,
                  background: '#faebd7',
                  fontWeight: 'bold',
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: dayCountIdx + 5,
                  gridColumnEnd: dayCountIdx + 6
                }
              )
            })
            totalRowCount += 1
            currentRow += 1
          })
        })
        const totalDayCountsProducts = Object.keys(totalDayCounts)
        res.items.push(
          {
            text: 'Общий итог:',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: currentRow,
            gridRowEnd: currentRow + totalDayCountsProducts.length,
            gridColumnStart: 1,
            gridColumnEnd: 4
          }
        )
        totalDayCountsProducts.forEach((product: any) => {
          res.items.push(
            {
              text: `${product}`,
              background: '#faebd7',
              fontWeight: 'bold',
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 4,
              gridColumnEnd: 5
            }
          )
          const productDayCounts = Object.keys(totalDayCounts[product])
          productDayCounts.forEach((dayCount: any, dayCountIdx: number) => {
            res.items.push(
              {
                text: `${totalDayCounts[product][dayCount]}`,
                gridRowStart: currentRow,
                background: '#faebd7',
                fontWeight: 'bold',
                gridRowEnd: currentRow + 1,
                gridColumnStart: dayCountIdx + 5,
                gridColumnEnd: dayCountIdx + 6
              }
            )
          })
          totalRowCount += 1
          currentRow += 1
        })
        res.rowsCount = totalRowCount
        return res
      } else {
        console.log('Невозможно конвертировать пустые данные!')
        return {} as TableData
      }
    },
    async updateClassItems () {
      const dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).startOf('month').format('YYYY-MM-DD')
      const dateTo = moment(`${dateFrom}`).add(1, 'M').startOf('month').format('YYYY-MM-DD')
      const res = await placeFindByDateWithName({
        parentId: this.filterState[FilterNames.PLACE_FILTER],
        dateFrom: dateFrom,
        dateTo: dateTo
      })
      const places: Array<{ id: number; name: string }> = res.filter((place: any) => !['СОТР', 'Выбывшие'].includes(place.name))
      places.sort((a: any, b: any) => compareClasses(a.name, b.name))
      places.unshift({
        id: 0,
        name: 'Все'
      })
      this.filterOptions.classItems = places
    },
    print () {
      printer('printable-area', 'print.css')
    }
  }
})
</script>
<style scoped lang="scss">
.slot-after {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .slot-after__icon {
    position: absolute;
    left: 40px;
    top: -15px;
    width: 12px;
    height: 12px;
  }

  .slot-after__icon_reverse {
    transform: rotate(180deg);
  }
}

#printable-area {
  display: flex;
  justify-content: center;
}

.only-for-print {
  display: none;
}

.filters {
  display: flex;
  padding: 0 5px;

  & div {
    margin: 0 5px;
  }
}

@media print {
  .print-table {
    padding: 10px;
  }
}
</style>
