import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import {
  findAcquiringOrderRegisterByIdResponseData,
  findAcquiringOrderRegistersResponseData
} from '@/shared/api/acquiring/api-types'

enum AcquiringRoutes {
  routeAcquiringorderpackGetregister = 'acquiringorderpack/getregister',
  routeAcquiringOrderRegisters = 'acquiringOrderRegisters/findByRows',
  routeAcquiringOrderRegister = 'acquiringOrderRegisters/findByRow'
}

export const findAcquiringorderpackGetregister = async (data: { timestampFrom: string; timestampTo: string; byDescTranOrd: boolean }): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: { timestampFrom: string; timestampTo: string };
  }> = await axios.post(AcquiringRoutes.routeAcquiringorderpackGetregister, data)
  return result.data.data
}

export const findAcquiringOrderRegisters = async (data: { dateFrom: string; dateTo: string }): Promise<findAcquiringOrderRegistersResponseData[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: findAcquiringOrderRegistersResponseData[];
  }> = await axios.post(AcquiringRoutes.routeAcquiringOrderRegisters, data)
  return result.data.data
}

export const findAcquiringOrderRegisterById = async (data: { id: number }): Promise<findAcquiringOrderRegisterByIdResponseData[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: findAcquiringOrderRegisterByIdResponseData[];
  }> = await axios.post(AcquiringRoutes.routeAcquiringOrderRegister, data)
  return result.data.data
}
