<template>
  <v-container>
    <div>
      <v-row>
        <v-col
          class="d-flex"
        >
          <v-select
            v-model="selectedMonth"
            :items="months"
            item-title="name"
            item-value="id"
            label="Месяц"
          />
        </v-col>
        <v-col
          class="d-flex"
        >
          <v-select
            v-model="selectedYear"
            :items="years"
            item-title="name"
            item-value="id"
            label="Год"
          />
        </v-col>
        <v-col
          class="d-flex"
        >
          <v-select
            v-model="selectedTableColor"
            :items="selectedColors"
            item-value="id"
            label="Метка"
          >
            <template #selection="{ item }">
              <img
                :src="item.image"
                style="max-height: 30px;"
              >{{ item.name }}
            </template>
            <template #item="{ item }">
              <img
                :src="item.image"
                style="max-height: 30px;"
              >{{ item.name }}
            </template>
          </v-select>
        </v-col>
        <v-col
          class="d-flex"
        >
          <v-checkbox
            v-model="hiddenProduсts"
            label="Показывать исключенные"
          />
        </v-col>
        <v-col
          class="d-flex"
        >
          <v-btn
            :is-loading="isLoading"
            block
            @click="load()"
          >
            Показать
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && products.length > 0"
        >
          <v-table class="report-rateplan-day">
            <template #default>
              <thead>
                <tr>
                  <th
                    class="bg-green-darken-4 text-center text-white"
                    colspan="100%"
                  >
                    Реестр блюд
                  </th>
                </tr>
                <tr>
                  <th>
                    Группа
                    <v-select
                      v-model="searchDescriptionTerm"
                      :items="descriptions"
                      item-value="id"
                      item-title="name"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th>
                    Наименование
                    <v-text-field
                      v-model="searchNameTerm"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th>
                    Метка
                    <v-select
                      v-model="tableColor"
                      :items="colors"
                      item-value="id"
                      density="compact"
                      @update:model-value="tableChange"
                    >
                      <template #selection="{ item }">
                        <img :src="item.image">{{ item.name }}
                      </template>
                      <template #item="{ item }">
                        <img :src="item.image">{{ item.name }}
                      </template>
                    </v-select>
                  </th>
                  <th>
                    Исключить
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(product, i) in products">
                  <tr
                    v-if="product.visible == 1"
                    :key="i"
                  >
                    <td style="vertical-align: top; padding: 0;">
                      {{ product.description }}
                    </td>
                    <td style="vertical-align: top; padding: 0;" class="gogoToDetailRow">
                      <a
                        @click="byProductDetail(product.id, selectedMonth, selectedYear)">
                        {{ product.name }}
                      </a>
                    </td>
                    <td
                      style="vertical-align: top; padding: 0;"
                      @click="changeProductSetting(product, 1)"
                    >
                      <div :class="productSettingGet(product)" />
                    </td>
                    <td class="text-center">
                      <span
                        v-if="!product.hidden"
                        color="red-darken-2"
                        style="cursor: pointer;font-size: 1.2em;"
                        alt="Исключить"
                        @click="changeProductSetting(product, 2)"
                      >X</span>
                      <span
                        v-else
                        color="green-darken-2"
                        style="cursor: pointer;font-size: 1.2em;"
                        alt="Включить"
                        @click="changeProductSetting(product, 2)"
                      >+</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-table>
        </v-col>
        <v-col v-else-if="!isLoading">
          <p>Нет продуктов с указанными параметрами</p>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialog"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar
            theme="dark"
            class="bg-green-darken-4"
            style="max-height: 64px"
          >
            <v-btn
              icon
              theme="dark"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Отчет по блюду</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col
                class="d-flex"
              >
                <v-select
                  v-model="selectedDetailedMonth"
                  :items="months"
                  item-title="name"
                  item-value="id"
                  label="Месяц"
                />
              </v-col>
              <v-col
                class="d-flex"
              >
                <v-select
                  v-model="selectedDetailedYear"
                  :items="years"
                  item-title="name"
                  item-value="id"
                  label="Год"
                />
              </v-col>
              <v-col
                class="d-flex"
              >
                <v-btn
                  :is-loading="isDetailLoading"
                  block
                  @click="refreshProductDetail()"
                >
                  Показать
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="justify-content-center">
              <v-col
                v-if="isDetailLoading"
                class="text-center"
              >
                <v-progress-circular
                  :size="100"
                  :width="7"
                  color="green"
                  indeterminate
                />
              </v-col>
            </v-row>
            <v-row
              v-if="!isDetailLoading && productSales.length > 0"
              class="justify-content-center"
            >
              <v-col
                cols="2"
              />
              <v-col
                cols="8"
              >
                <v-table class="report-rateplan-day">
                  <template #default>
                    <thead>
                      <tr>
                        <th
                          theme="dark"
                          class="bg-green-darken-4 text-center text-white"
                          colspan="100%"
                        >
                          Отчет по блюду {{ productSales[0].productName }}
                        </th>
                      </tr>
                      <template v-if="cashDiscountQuantity > 0 || discountQuantity > 0">
                        <tr>
                          <th
                            rowspan="2"
                            class="text-center"
                          >
                            Школа
                          </th>
                          <th
                            v-if="cashDiscountQuantity > 0 && discountQuantity > 0"
                            class="text-center"
                            colspan="3"
                          >
                            Количество
                          </th>
                          <th
                            v-else
                            class="text-center"
                            colspan="2"
                          >
                            Количество
                          </th>
                          <th
                            class="text-center"
                            rowspan="2"
                          >
                            Цена
                          </th>
                          <th
                            class="text-center"
                            rowspan="2"
                          >
                            Сумма
                          </th>
                        </tr>
                        <tr>
                          <th
                            v-if="discountQuantity > 0"
                            class="text-center"
                          >
                            Льготно
                          </th>
                          <th
                            v-if="cashDiscountQuantity > 0"
                            class="text-center"
                          >
                            Наличные
                          </th>
                          <th>Всего</th>
                        </tr>
                      </template>
                      <tr v-else>
                        <th class="text-center">
                          Школа
                        </th>
                        <th class="text-center">
                          Количество
                        </th>
                        <th class="text-center">
                          Цена
                        </th>
                        <th class="text-center">
                          Сумма
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(productSale, i) in productSales"
                        :key="i"
                      >
                        <td>{{ productSale.placeName }}</td>
                        <td v-if="discountQuantity > 0">
                          {{ productSale.discountQuantity }}
                        </td>
                        <td v-if="cashDiscountQuantity > 0">
                          {{ productSale.cashDiscountQuantity }}
                        </td>
                        <td>{{ productSale.quantity }}</td>
                        <td>{{ productSale.price }}</td>
                        <td>{{ productSale.total }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>
                          Итого:
                        </th>
                        <th v-if="discountQuantity > 0">
                          {{ discountQuantity.toFixed(0) }}
                        </th>
                        <th v-if="cashDiscountQuantity > 0">
                          {{ cashDiscountQuantity.toFixed(0) }}
                        </th>
                        <th>
                          {{ countSum.toFixed(0) }}
                        </th>
                        <th />
                        <th>
                          {{ totalSum.toFixed(2) }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                </v-table>
              </v-col>
            </v-row>
            <v-row
              v-else-if="!isDetailLoading"
              class="justify-content-center"
            >
              <v-col>
                <p>Нет продаж за выбранный период</p>
              </v-col>
            </v-row>
          </v-card-text>

          <div style="flex: 1 1 auto;" />
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { changeCustomerProductSettings, findCustomerProducts } from '@/shared/api/customerProductList/api-requests'
import { findByProduct } from '@/shared/api/salelist/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  discountQuantity: any;
  cashDiscountQuantity: any;
  dialog: boolean;
  hiddenProduсts: boolean;
  searchNameTerm: string;
  searchDescriptionTerm: any;
  searchTerm: string;
  isDetailLoading: boolean;
  isLoading: boolean;
  isPost: boolean;
  products: any;
  customerProducts: any;
  saleByPlacePost: any;
  colors: any;
  tableColor: any;
  months: any;
  selectedMonth: any;
  years: any;
  selectedYear: any;
  selectedDetailedMonth: any;
  selectedDetailedYear: any;
  selectedProduct: any;
  selectedColors: any;
  selectedTableColor: any;
  descriptions: any;
  places: any;
  sales: any;
  detailedProducts: any;
  saleSum: number;
  countSum: number;
  discountSum: number;
  totalSum: number;
  productSales: any;
  saleGroupByPlacesPost: any;
  saleGroupByPlaces: any;
}

export default defineComponent({
  components: {
  },
  props: {
    propSearchNameTerm: {
      type: String,
      required: false
    },
    propSearchDescriptionTerm: {
      type: Number || null,
      required: false
    },
    propTableColor: {
      type: String,
      required: false
    },
    propSelectedMonth: {
      type: Number,
      required: false
    },
    propSelectedYear: {
      type: Number,
      required: false
    },
    propSelectedTableColor: {
      type: String,
      required: false
    }
  },
  data (): BaseData {
    return {
      discountQuantity: 0,
      cashDiscountQuantity: 0,
      dialog: false,
      hiddenProduсts: false,
      searchNameTerm: '',
      searchDescriptionTerm: null,
      searchTerm: '',
      isDetailLoading: false,
      isLoading: false,
      isPost: false,
      products: [],
      customerProducts: [],
      saleByPlacePost: {
      },
      selectedColors: [
        { id: '0', image: '', name: 'Все' },
        { id: 'off', image: '', name: 'Не указана' },
        { id: '1', image: require('@/assets/images/lime.png'), name: '' },
        { id: '2', image: require('@/assets/images/warning_lime.png'), name: '' },
        { id: '3', image: require('@/assets/images/error_lime.png'), name: '' }
      ],
      selectedTableColor: '0',
      colors: [
        { id: '0', image: '', name: 'Все' },
        { id: 'off', image: '', name: 'Не указана' },
        { id: '1', image: require('@/assets/images/lime.png'), name: '' },
        { id: '2', image: require('@/assets/images/warning_lime.png'), name: '' },
        { id: '3', image: require('@/assets/images/error_lime.png'), name: '' }
      ],
      tableColor: '0',
      months: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' }
      ],
      selectedMonth: moment().month() + 1,
      years: [
      ],
      selectedYear: moment().year(),
      selectedDetailedMonth: null,
      selectedDetailedYear: null,
      selectedProduct: null,
      descriptions: [],
      places: [],
      sales: [],
      detailedProducts: [],
      saleSum: 0,
      countSum: 0,
      discountSum: 0,
      totalSum: 0,
      productSales: [],
      saleGroupByPlacesPost: {
        productId: null,
        discountId: null,
        timestampFrom: null,
        timestampTo: null
      },
      saleGroupByPlaces: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadCustomerProducts () {
      try {
        this.isLoading = true
        let idescription = 0
        this.descriptions.push({
          id: null,
          name: 'Все'
        })
        this.products = []
        this.customerProducts = []
        this.saleByPlacePost.settingTypeId = null
        this.saleByPlacePost.value = null
        this.saleByPlacePost.hidden = this.hiddenProduсts
        if (this.selectedTableColor !== '0') {
          this.saleByPlacePost.settingTypeId = 1
          this.saleByPlacePost.value = this.selectedTableColor
        }
        const result = await findCustomerProducts(this.saleByPlacePost)
        this.customerProducts = JSON.parse(JSON.stringify(result))
        this.customerProducts.forEach((element: any) => {
          let hidden = false
          element.productSettings.forEach((productSetting: any) => {
            if (productSetting.settingTypeId === 2) {
              if (productSetting.value === 'on') {
                hidden = true
              }
            }
          })
          const product = {
            id: element.id,
            description: element.description,
            name: element.name,
            productSettings: element.productSettings,
            visible: true,
            hidden: hidden
          }
          this.products.push(product)
          const description = this.descriptions.find((desc: any) => desc.name === element.description)
          if (description === undefined) {
            idescription = idescription + 1
            this.descriptions.push({
              id: idescription,
              name: element.description
            })
          }
        })
        this.tableChange()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadSales (productId: any) {
      try {
        this.cashDiscountQuantity = 0
        this.discountQuantity = 0
        this.isDetailLoading = true
        this.saleGroupByPlacesPost.productId = productId
        this.saleGroupByPlacesPost.timestampFrom = moment(this.selectedDetailedYear + '-' + this.selectedDetailedMonth + '-01').format('YYYY-MM-DD')
        this.saleGroupByPlacesPost.timestampTo = moment(this.selectedDetailedYear + '-' + this.selectedDetailedMonth + '-01').add('months', 1).format('YYYY-MM-DD')
        const result = await findByProduct(this.saleGroupByPlacesPost)
        this.saleGroupByPlaces = JSON.parse(JSON.stringify(result))
        if (Array.isArray(this.saleGroupByPlaces.sales)) {
          this.sales = this.saleGroupByPlaces.sales
        }
        if (Array.isArray(this.saleGroupByPlaces.products)) {
          this.detailedProducts = this.saleGroupByPlaces.products
        }
        if (Array.isArray(this.saleGroupByPlaces.places)) {
          this.places = this.saleGroupByPlaces.places
        }
        if (this.sales.length > 0) {
          this.sales.forEach((element: any) => {
            const product = this.detailedProducts.find((el: any) => el.id === element.productId)
            const place = this.places.find((el: any) => el.id === element.placeId)
            let discountId = 0
            if (element.discountId !== null) {
              discountId = element.discountId
            }

            let cashDiscountQuantity = 0
            let discountQuantity = 0
            if (Number(discountId) !== 0) {
              if (Number(discountId) === 28) {
                cashDiscountQuantity = Number(element.quantity)
              } else {
                discountQuantity = Number(element.quantity)
              }
            }
            const j = this.productSales.findIndex((x: any) =>
              x.placeId === element.placeId &&
              x.productId === element.productId &&
              Number(x.price) === Number(element.price))

            if (j <= -1) {
              const sale = {
                productName: product.name,
                productId: element.productId,
                placeId: element.placeId,
                placeName: place.name,
                price: Number(element.price).toFixed(2),
                quantity: Number(element.quantity).toFixed(0),
                discount: Number(element.discount).toFixed(2),
                sum: Number(element.sum).toFixed(2),
                total: Number(element.total).toFixed(2),
                cashDiscountQuantity: cashDiscountQuantity.toFixed(0),
                discountQuantity: discountQuantity.toFixed(0)
              }
              this.productSales.push(sale)
            } else {
              this.productSales[j].quantity = (Number(this.productSales[j].quantity) + Number(element.quantity)).toFixed(0)
              this.productSales[j].discount = (Number(this.productSales[j].discount) + Number(element.discount)).toFixed(2)
              this.productSales[j].sum = (Number(this.productSales[j].sum) + Number(element.sum)).toFixed(2)
              this.productSales[j].total = (Number(this.productSales[j].total) + Number(element.total)).toFixed(2)
              this.productSales[j].cashDiscountQuantity = (Number(this.productSales[j].cashDiscountQuantity) + Number(cashDiscountQuantity)).toFixed(0)
              this.productSales[j].discountQuantity = (Number(this.productSales[j].discountQuantity) + Number(discountQuantity)).toFixed(0)
            }

            this.saleSum = this.saleSum + Number(element.sum)
            this.countSum = this.countSum + Number(element.quantity)
            this.discountSum = this.discountSum + Number(element.discount)
            this.totalSum = this.totalSum + Number(element.total)
            this.cashDiscountQuantity = this.cashDiscountQuantity + Number(cashDiscountQuantity)
            this.discountQuantity = this.discountQuantity + Number(discountQuantity)
          })
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isDetailLoading = false
      }
    },
    refreshProductDetail () {
      this.byProductDetail(this.selectedProduct, this.selectedDetailedMonth, this.selectedDetailedYear)
    },
    byProductDetail (productId: string, month: any, year: any) {
      this.selectedProduct = productId
      this.selectedDetailedMonth = month
      this.selectedDetailedYear = year
      this.places = []
      this.detailedProducts = []
      this.sales = []
      this.saleSum = 0
      this.countSum = 0
      this.discountSum = 0
      this.totalSum = 0
      this.productSales = []
      this.isDetailLoading = true
      this.dialog = true
      this.loadSales(productId)
      /*
      this.$router.push({
        name: 'ReportSalesByCustomerProductsDetail',
        params: {
          productId: productId,
          searchNameTerm: this.searchNameTerm,
          searchDescriptionTerm: this.searchDescriptionTerm,
          tableColor: this.tableColor,
          propSelectedYear: this.selectedYear,
          propSelectedMonth: this.selectedMonth,
          selectedTableColor: this.selectedTableColor
        }
      })
      */
    },
    load () {
      this.searchNameTerm = ''
      this.searchDescriptionTerm = null
      this.tableColor = '0'
      this.loadCustomerProducts()
    },
    tableChange () {
      this.products.forEach((element: any) => {
        let visible = 0
        let searchVisible = 0
        let productClass = 'off'
        let searchDescription = ''

        element.productSettings.forEach((element: any) => {
          if (element.settingTypeId === 1) {
            productClass = element.value
          }
        })

        if (this.searchDescriptionTerm !== null) {
          searchDescription = this.descriptions.find((desc: any) => desc.id === this.searchDescriptionTerm).name
        }

        if (this.searchNameTerm === '' && searchDescription === '') {
          searchVisible = 1
        } else {
          if (this.searchNameTerm === '' && searchDescription !== '') {
            if (element.description === searchDescription) {
              searchVisible = 1
            }
          } else if (this.searchNameTerm !== '' && searchDescription === '') {
            if (element.name.toLowerCase().indexOf(this.searchNameTerm.toLowerCase()) >= 0) {
              searchVisible = 1
            }
          } else {
            if (element.description === searchDescription && element.name.toLowerCase().indexOf(this.searchNameTerm.toLowerCase()) >= 0) {
              searchVisible = 1
            }
          }
        }

        if (searchVisible === 1 && this.tableColor === '0') {
          visible = 1
        }

        if (searchVisible === 1 && this.tableColor === productClass) {
          visible = 1
        }

        element.visible = visible
      })
    },
    productSettingGet (product: any) {
      let productClass = 'img_none'
      product.productSettings.forEach((element: any) => {
        if (element.settingTypeId === 1) {
          if (element.value === '1') {
            productClass = 'img_major'
          } else if (element.value === '2') {
            productClass = 'img_avg'
          } else if (element.value === '3') {
            productClass = 'img_minor'
          }
        }
      })
      return productClass
    },
    async changeProductSetting (product: any, settingTypeId: number) {
      let settingValue = 'off'
      if (settingTypeId === 1) {
        settingValue = '1'
        product.productSettings.forEach((element: any) => {
          if (element.settingTypeId === 1) {
            if (element.value === '1') {
              settingValue = '2'
            } else if (element.value === '2') {
              settingValue = '3'
            } else if (element.value === '3') {
              settingValue = 'off'
            }
          }
        })
      } else if (settingTypeId === 2) {
        settingValue = 'on'
        product.productSettings.forEach((element: any) => {
          if (element.settingTypeId === 2) {
            if (element.value === 'on') {
              settingValue = 'off'
            } else if (element.value === 'off') {
              settingValue = 'on'
            }
          }
        })
      }
      try {
        // this.isLoading = true
        let productSettings = []
        const customerProductSettings = { productId: product.id, settingTypeId: settingTypeId, value: settingValue }
        const result = await changeCustomerProductSettings(customerProductSettings)
        productSettings = JSON.parse(JSON.stringify(result))
        product.productSettings = productSettings

        let hidden = false
        product.productSettings.forEach((productSetting: any) => {
          if (productSetting.settingTypeId === 2) {
            if (productSetting.value === 'on') {
              hidden = true
            }
          }
        })
        product.hidden = hidden
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    for (let i = 5; i >= 0; i--) {
      this.years.push({ id: Number(moment().year()) - i, name: String(Number(moment().year()) - i) })
    }
    if (this.propSearchNameTerm) {
      this.searchNameTerm = this.propSearchNameTerm
    }
    if (this.propSearchDescriptionTerm) {
      this.searchDescriptionTerm = this.propSearchDescriptionTerm
    }
    if (this.propTableColor) {
      this.tableColor = this.propTableColor
    }
    if (this.propSelectedYear) {
      this.selectedYear = this.propSelectedYear
    }
    if (this.propSelectedMonth) {
      this.selectedMonth = this.propSelectedMonth
    }
    if (this.propSelectedTableColor) {
      this.selectedTableColor = this.propSelectedTableColor
    }

    this.products = []
    if (this.propSelectedMonth && this.propSelectedYear && this.propSelectedTableColor) {
      this.loadCustomerProducts()
    }
  }
})
</script>

<style lang="scss">
  .img_major {
    background-position: center center;
    background-image: url('~@/assets/images/lime.png');
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .img_avg {
    background-position: center center;
    background-image: url('~@/assets/images/warning_lime.png');
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .img_minor {
    background-position: center center;
    background-image: url('~@/assets/images/error_lime.png');
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .img_none {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: none;
  }
  .gogoToDetailRow {
    &:hover {
      background-color: lightgray;
    }
  }
</style>
