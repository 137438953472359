import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PreorderGetPost, PreorderSignPost, PreorderUnsignPost, PreorderGetByFactPost } from './api-types'

const preorderGet = 'preorder/get'
const preorderSign = 'preorder/sign'
const preorderUnsign = 'preorder/unsign'
const preorderGetByFact = 'preorder/createbyfact'
const preorderSave = 'preorder/save'

export const get = async (data: PreorderGetPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderGet, data)
  return result.data.data
}

export const sign = async (data: PreorderSignPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderSign, data)
  return result.data.data
}

export const unsign = async (data: PreorderUnsignPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderUnsign, data)
  return result.data.data
}

export const getByFact = async (data: PreorderGetByFactPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderGetByFact, data)
  return result.data.data
}

export const save = async (data: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(preorderSave, data)
  return result.data.data
}
