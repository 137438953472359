<template>
  <div class="bid-settings">
    <h1>Настройки заявок</h1>
    <div class="class-items">
      <template v-for="classItem in classItems" :key="classItem.id">
        <class-bid-card
          :class-item="classItem"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ClassBidCard from '@/components/settings/bidsettings/ClassBidCard.vue'

export default defineComponent({
  name: 'BidSettings',
  components: { ClassBidCard },
  data () {
    return {
      classItems: [
        {
          id: 1,
          name: '1б',
          gpd: false,
          breakfastNutritious: [
            {
              id: 1,
              name: 'Завтрак',
              nutritious: [
                {
                  id: 1,
                  name: 'Рацион 1',
                  description: 'Для платников'
                },
                {
                  id: 2,
                  name: 'Рацион 2',
                  description: 'Для льготников'
                }
              ]
            }
          ]
        }
      ]
    }
  }
})
</script>

<style scoped>

</style>
