<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex align-center"
        cols="3"
        sm="3"
      >
        <v-btn
          class="button-wrap"
          elevation="2"
          :to="{ name: 'PreorderAdd', params: { } }"
        >
          НАЗАД
        </v-btn>
      </v-col>
      <v-col
        class="d-flex align-center justify-center"
      >
        <h3>Заявка № {{ preorderNumber }} </h3>
      </v-col>
      <v-col
        class="d-flex align-center"
      >
        <h3>На дату {{ preorderDate.substring(0, 10) }} </h3>
      </v-col>
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <v-select
          v-model="selectedUserAllowedPlace"
          :items="userAllowedPlacesList"
          item-title="place.name"
          item-value="place.id"
          label="Школа"
          @update:model-value="changePlace()"
        />
      </v-col>
    </v-row>
    <template v-if="preorder != null">
      <v-row
        v-if="preorder.preorder.acceptTimestamp"
        class="justify-content-end"
      >
        <v-col class="text-right without-padding">
          <span style="color:red"><strong>* Режим коррекции</strong></span>
        </v-col>
      </v-row>
    </template>
    <v-row class="justify-content-center">
      <v-col
        class="align-center justify-end"
        cols="2"
        sm="2"
      >
        <v-radio-group
          v-model="radioGroup"
          @update:model-value="filterClasses()"
        >
          <v-radio
            key="1"
            label="Все"
            value="1"
          />
          <v-radio
            key="2"
            label="Начальные"
            value="2"
          />
          <v-radio
            key="3"
            label="Старшие"
            value="3"
          />
        </v-radio-group>
        <v-btn
          class="button-wrap"
          @click="saveOrder()"
        >
          СОХРАНИТЬ
        </v-btn>
      </v-col>
      <v-col
        v-if="isLoading || isLoadingPlaces || isLoadingPreorder || isLoadingPreorderProduct"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
      <v-col
        v-else
        class="text-center d-flex  justify-center"
      >
        <template v-if="preorder != null">
          `<v-table class="table-preorder">
            <template #default>
              <thead>
                <tr>
                  <th>Класс</th>
                  <th
                    v-for="(preorderProduct, index) in preorderProducts"
                    :key="index"
                    class="text-center"
                    :colspan="preorder.preorder.acceptTimestamp ? 2 : 1"
                  >
                    {{ preorderProduct.product.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(class1, indexcl) in preorderPlaces"
                  :key="indexcl"
                >
                  <td>{{ class1.name }}</td>
                  <template
                    v-for="(preorderProduct, index) in preorderProducts"
                    :key="index"
                  >
                    <td
                      class="text-center"
                      style="min-width: 70px"
                    >
                      <v-text-field
                        class="preorder-text-field"
                        :model-value="getItem(class1.id, preorderProduct.productId, 1)"
                        @change="changeItemValue($event, class1.id, preorderProduct.productId, 1)"
                      />
                    </td>
                    <td
                      v-if="preorder.preorder.acceptTimestamp"
                      :key="index+100"
                      class="text-center"
                      style="min-width: 130px; width: 160px"
                    >
                      <div class="d-flex">
                        <v-select
                          class="preorder-select"
                          :items="listIcons"
                          item-value="id"
                          item-title="icon"
                          :model-value="getItem(class1.id, preorderProduct.productId, 3)"
                          @update:model-value="changeSignValue($event, class1.id, preorderProduct.productId)"
                        >
                          <template #selection="data">
                            <v-icon :color="data.item.color">
                              {{ data.item.icon }}
                            </v-icon>
                          </template>
                          <template #item="data">
                            <v-icon :color="data.item.color">
                              {{ data.item.icon }}
                            </v-icon>
                          </template>
                        </v-select>
                        <v-text-field
                          :rules="rules"
                          class="preorder-text-field"
                          :model-value="getItem(class1.id, preorderProduct.productId, 2)"
                          @change="changeItemValue($event, class1.id, preorderProduct.productId, 2)"
                        />
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-table>
        </template>
        <template v-else>
          Ошибка данных, перезагрузите таблицу
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { PreorderGetPost } from '@/shared/api/preorder/api-types'
import { get as preorderGet, save as preorderSave } from '@/shared/api/preorder/api-requests'
import { PreorderProductsFindPost } from '@/shared/api/preorderProducts/api-types'
import { find as preorderProductsFind } from '@/shared/api/preorderProducts/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  radioGroup: any;
  isLoading: boolean;
  isLoadingPlaces: boolean;
  isLoadingPreorder: boolean;
  isLoadingPreorderProduct: boolean;
  isSigned: boolean;
  placeId: any;
  userAllowedPlacesCopy: any;
  userAllowedPlacesList: any;
  selectedUserAllowedPlace: any;
  preorderGetPost: PreorderGetPost;
  preorder: any;
  preorderPlaces: any;
  preorderNumber: string;
  preorderDate: string;
  preorderProductsFindPost: PreorderProductsFindPost;
  preorderProducts: any;
  signeds: any;
  listIcons: any;
  rules: any;
}

export default defineComponent({
  components: {
  },
  data (): BaseData {
    return {
      radioGroup: '1',
      isLoading: false,
      isLoadingPlaces: false,
      isLoadingPreorder: false,
      isLoadingPreorderProduct: false,
      isSigned: false,
      placeId: null,
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      preorderGetPost: {
        placeId: 0
      },
      preorder: null,
      preorderPlaces: [],
      preorderNumber: '',
      preorderDate: '',
      preorderProductsFindPost: {
        placeId: 0
      },
      preorderProducts: [],
      signeds: [],
      listIcons: [
        { id: 1, icon: 'mdi-plus', color: 'green' },
        { id: 2, icon: 'mdi-minus', color: 'red' }
      ],
      rules: [
        (v: any) => !isNaN(v) || 'Не число !']
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadUserAllowedPlaces () {
      this.isLoadingPlaces = true
      try {
        await this.userAllowedPlaceAll()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        if (this.userAllowedPlacesList.length > 0) this.selectedUserAllowedPlace = this.userAllowedPlacesList[0].place.id
        if (this.selectedUserAllowedPlace) {
          this.changePlace()
        }
        this.isLoadingPlaces = false
      }
    },
    async loadPreorder (preorderGetPost: PreorderGetPost) {
      this.isLoadingPreorder = true
      try {
        const result = await preorderGet(preorderGetPost)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder.preorder) {
          this.preorderNumber = this.preorder.preorder.number
          this.preorderDate = this.preorder.preorder.date
          // this.preorder.preorder.acceptTimestamp = 1 //for test
          if (this.preorder.places) {
            this.filterClasses()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoadingPreorder = false
      }
    },
    async loadPreorderProduct (preorderProductsFindPost: PreorderProductsFindPost) {
      this.isLoadingPreorderProduct = true
      try {
        const result = await preorderProductsFind(preorderProductsFindPost)
        this.preorderProducts = JSON.parse(JSON.stringify(result))
        this.loadPreorder(this.preorderGetPost)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoadingPreorderProduct = false
      }
    },
    async savePreorder (preorder: any) {
      this.isLoading = true
      try {
        const result = await preorderSave(preorder)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder.places) {
          this.filterClasses()
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    saveOrder () {
      this.preorder.preorder.preorderItems.forEach((item: any) => {
        if (item.correctionCount) {
          if (item.sign === 1) {
            item.correctionCount = Math.abs(item.correctionCount)
          } else if (item.sign === 2) {
            item.correctionCount = -Math.abs(item.correctionCount)
          }
        }
      })
      this.savePreorder(this.preorder.preorder)
    },
    changePlace () {
      this.preorderGetPost.placeId = this.selectedUserAllowedPlace
      this.preorderProductsFindPost.placeId = this.selectedUserAllowedPlace
      this.loadPreorderProduct(this.preorderProductsFindPost)
    },
    signedMatch (classId: any, productId: any, color: any) {
      const temp = this.preorder.preorder.preorderItems.find((el: any) => parseInt(el.placeId) === parseInt(classId) && parseInt(el.productId) === parseInt(productId))
      if (temp.correctionCount) {
        if (temp.correctionCount > 0) {
          return color
        }
      }
      return 'grey'
    },
    getItem (classId: any, productId: any, type: number) {
      const temp = this.preorder.preorder.preorderItems.find((el: any) => parseInt(el.placeId) === parseInt(classId) && parseInt(el.productId) === parseInt(productId))
      if (temp) {
        if (type === 1) {
          return temp.count
        } else if (type === 2) {
          if (temp.correctionCount) {
            if (isNaN(temp.correctionCount)) {
              temp.correctionCount = null
            }
            return Math.abs(temp.correctionCount)
          }
        } else if (type === 3) {
          if (temp.correctionCount != null) console.log(temp)
          return temp.sign
        }
      }
      return null
    },
    changeItemValue (value: any, classId: any, productId: any, type: number) {
      const temp = this.preorder.preorder.preorderItems.find((el: any) => parseInt(el.placeId) === parseInt(classId) && parseInt(el.productId) === parseInt(productId))
      if (temp) {
        if (type === 1) {
          temp.count = value
        } else {
          temp.correctionCount = value
        }
      }
    },
    changeSignValue (value: any, classId: any, productId: any) {
      const temp = this.preorder.preorder.preorderItems.find((el: any) => parseInt(el.placeId) === parseInt(classId) && parseInt(el.productId) === parseInt(productId))
      if (temp) {
        temp.sign = value
      }
    },
    filterClasses () {
      if (this.radioGroup === '2') {
        this.preorderPlaces = this.preorder.places.filter((el: any) => el.name.match(/^[1-4][^0-9].*|^[1-4]$/))
      } else if (this.radioGroup === '3') {
        this.preorderPlaces = this.preorder.places.filter((el: any) => el.name.match(/^[5-9].*|^[5-9]|^[1-4][0-3].*$|^[1-4][0-3]$/))
      } else {
        this.preorderPlaces = this.preorder.places
      }
      this.preorderPlaces.sort((a: any, b: any) => (parseInt(a.name) > parseInt(b.name)) ? 1 : ((parseInt(a.name) > parseInt(b.name)) ? -1 : 0))
      this.calculation()
    },
    calculation () {
      if (this.preorder.preorder.preorderItems) {
        this.preorderPlaces.forEach((element: any) => {
          this.preorderProducts.forEach((elementProduct: any) => {
            const tempPreorderItem = this.preorder.preorder.preorderItems.find((el: any) => parseInt(el.placeId) === parseInt(element.id) && parseInt(el.productId) === parseInt(elementProduct.productId))
            if (!tempPreorderItem) {
              this.preorder.preorder.preorderItems.push({
                id: null,
                placeId: element.id,
                preorderId: null,
                productId: elementProduct.productId,
                count: 0,
                correctionCount: 0,
                sign: 1
              })
            } else {
              if (tempPreorderItem.correctionCount) {
                if (tempPreorderItem.correctionCount < 0) {
                  tempPreorderItem.sign = 2
                } else {
                  tempPreorderItem.sign = 1
                }
              } else {
                tempPreorderItem.sign = 1
              }
            }
          })
        })
      }
    }
  },
  mounted () {
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss"></style>
