import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  class: "page-container",
  role: "main",
  "aria-label": "Main"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "page-wrapper" }
const _hoisted_8 = {
  class: "main-content-wrapper main-content-wrapper--with-padding main-content-wrapper--grey-background",
  role: "main",
  "aria-label": "Main"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "page-wrapper" }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { class: "whole_page" }
const _hoisted_13 = {
  key: 4,
  role: "main",
  "aria-label": "Main"
}
const _hoisted_14 = { class: "main-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_progress_bar = _resolveComponent("vue-progress-bar")!
  const _component_navbar = _resolveComponent("navbar")!
  const _component_navbar_user_type = _resolveComponent("navbar-user-type")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_footer_main = _resolveComponent("footer-main")!
  const _component_navbar_account = _resolveComponent("navbar-account")!
  const _component_navbar_parents = _resolveComponent("navbar-parents")!
  const _component_footer_parents = _resolveComponent("footer-parents")!
  const _component_snackbar = _resolveComponent("snackbar")!
  const _component_back_to_top = _resolveComponent("back-to-top")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_vue_progress_bar),
      (_ctx.routePartialType === 'full')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.user.person==null && !_ctx.isBetaPage())
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_navbar)
                  ]))
                : (_ctx.user.person)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_navbar_user_type)
                    ]))
                  : _createCommentVNode("", true),
              _createVNode(_component_v_main, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_router_view)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_footer_main)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.routePartialType === 'account')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_navbar_account),
              _createVNode(_component_v_main, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_router_view)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_footer_main)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.routePartialType === 'telegram')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_router_view)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.routePartialType === 'parents')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_navbar_parents),
            _createVNode(_component_v_main, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_router_view)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_footer_parents)
          ]))
        : _createCommentVNode("", true),
      (_ctx.routePartialType === 'meta')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_router_view)
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_snackbar),
      _createVNode(_component_back_to_top)
    ]),
    _: 1
  }))
}