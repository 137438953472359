<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex align-center"
        cols="3"
        sm="3"
      >
        <v-menu
          v-model="menu1"
          class="menu-preorder"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(preorderDate)"
              label="На дату"
              prepend-icon="mdi-calendar"
              readonly

              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="На дату"
            v-model="preorderDate"
            @input="menu1 = false;changeDate()"
          />
        </v-menu>
        <v-btn
          style="left:10px; top: -5px"
          elevation="2"
          @click="savePreorder"
        >
          CОХРАНИТЬ
        </v-btn>
        <!--<h3>На дату {{ preorderDate.substring(0, 10) }} </h3>-->
      </v-col>
      <v-col
        class="d-flex align-center justify-center"
      >
        <h3>Заявка № {{ preorderNumber }} </h3>
      </v-col>
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <v-select
          v-model="selectedUserAllowedPlace"
          :items="userAllowedPlacesList"
          item-title="place.name"
          item-value="place.id"
          label="Школа"
          @update:model-value="changePlace()"
        />
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="justify-center">
        <div id="printableArea">
          <v-row class="justify-content-center hidden-screen">
            <v-col>
              <h3 class="print-header">
                Заявка № {{ preorderNumber }} на {{ printPreorderDate }}
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="isLoading || !isGet"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
            <v-col v-if="!isLoading && isGet">
              <v-table class="table-preorder">
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        Рацион
                      </th>
                      <th class="text-center">
                        План
                      </th>
                      <th class="text-center">
                        Коррекция
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(preorderProduct, index) in preorderProducts">
                      <tr
                        v-if="preorderProduct.status == 1"
                        :key="index"
                      >
                        <td> {{ preorderProduct.product.name }} </td>
                        <td> {{ getPreorderCountByProductId(preorderProduct.productId) }} </td>
                        <td> {{ getPreorderCorrectionCountByProductId(preorderProduct.productId) }} </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-table>
            </v-col>
          </v-row>
          <v-row class="justify-content-center hidden-screen">
            <v-col>
              <p>
                Время печати: {{ serverTimestamp }} <br><br>
                Время подписи: {{ signTimestamp }}
              </p>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col
            class="justify-center text-center"
          >
            <v-btn
              class="button-preorder"
              elevation="2"
              :disabled="!isDateSave"
              :to="{ name: 'PreorderAddDetail', params: { propIsSigned: isSigned } }"
            >
              ИЗМЕНИТЬ
            </v-btn>
          </v-col>
          <v-col class="justify-center text-center">
            <v-btn
              v-if="!isSigned"
              class="button-preorder"
              :color="''"
              elevation="2"
              @click="signPreorder"
            >
              ПОДПИСАТЬ
            </v-btn>
            <v-btn
              v-else
              class="button-preorder"
              :color="'success'"
              elevation="2"
              @click="unsignPreorder"
            >
              ОТМЕНИТЬ ПОДПИСЬ
            </v-btn>
          </v-col>
          <v-col class="justify-center text-center">
            <v-btn
              class="button-preorder"
              elevation="2"
              @click="print"
            >
              ПЕЧАТЬ
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="align-center justify-end"
        cols="3"
        sm="3"
      >
        <v-row class="justify-content-center">
          <v-col class="justify-center text-center">
            <v-btn
              class="button-preorder"
              elevation="2"
              :to="{ name: 'PreorderProduct', params: { propPlaceId: selectedUserAllowedPlace } }"
            >
              НАСТРОИТЬ РАЦИОНЫ
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-content-center">
          <v-col class="justify-center text-center">
            <v-btn
              v-if="!isSigned"
              :disabled="!isDateSave"
              class="button-preorder button-preorder-fact"
              elevation="2"
              @click="getPreorderByFact"
            >
              ЗАПОЛНИТЬ ЗАЯВКУ <br> ПО ФАКТАМ <br> ПИТАНИЯ СЕГОДНЯ
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'
import {
  PreorderGetByFactPost,
  PreorderGetPost,
  PreorderSignPost,
  PreorderUnsignPost
} from '@/shared/api/preorder/api-types'
import {
  get as preorderGet,
  getByFact as preorderGetByFactPost,
  save as preorderSave,
  sign as preorderSign,
  unsign as preorderUnsign
} from '@/shared/api/preorder/api-requests'
import { PreorderProductsFindPost } from '@/shared/api/preorderProducts/api-types'
import { find as preorderProductsFind } from '@/shared/api/preorderProducts/api-requests'
import { AxiosError } from 'axios'
import moment from 'moment'

type BaseData = {
  isLoading: boolean;
  isGet: boolean;
  isDateSave: boolean;
  isSigned: boolean;
  placeId: any;
  userAllowedPlacesCopy: any;
  userAllowedPlacesList: any;
  selectedUserAllowedPlace: any;
  preorderGetPost: PreorderGetPost;
  preorderSignPost: PreorderSignPost;
  preorderUnsignPost: PreorderUnsignPost;
  preorderGetByFactPost: PreorderGetByFactPost;
  preorder: any;
  preorderNumber: string;
  preorderDate: string;
  oldPreorderDate: string;
  preorderProductsFindPost: PreorderProductsFindPost;
  preorderProducts: any;
  menu1: boolean;
  serverTimestamp: any;
  signTimestamp: any;
  printPreorderDate: any;
}

export default defineComponent({
  components: {
  },
  props: {
    propIsSigned: {
      type: String
    }
  },
  data (): BaseData {
    return {
      isLoading: false,
      isGet: false,
      isDateSave: true,
      isSigned: false,
      placeId: null,
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      preorderGetPost: {
        placeId: 0
      },
      preorderSignPost: {
        id: null,
        placeId: 0,
        date: null
      },
      preorderUnsignPost: {
        id: null,
        placeId: 0,
        date: null
      },
      preorderGetByFactPost: {
        id: null,
        placeId: 0,
        date: null
      },
      preorder: null,
      preorderNumber: '',
      preorderDate: '',
      oldPreorderDate: '',
      preorderProductsFindPost: {
        placeId: 0
      },
      preorderProducts: [],
      menu1: false,
      serverTimestamp: '',
      signTimestamp: '',
      printPreorderDate: ''
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async loadUserAllowedPlaces () {
      try {
        await this.userAllowedPlaceAll()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        if (this.userAllowedPlacesList.length > 0) this.selectedUserAllowedPlace = this.userAllowedPlacesList[0].place.id
        if (this.selectedUserAllowedPlace) {
          this.changePlace()
        }
        this.isLoading = false
      }
    },
    async loadPreorder (preorderGetPost: PreorderGetPost) {
      this.isLoading = true
      try {
        const result = await preorderGet(preorderGetPost)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder) {
          if (this.preorder.serverTimestamp) {
            this.serverTimestamp = moment(this.preorder.serverTimestamp).format('DD.MM.YYYY HH:mm:ss')
          }
          if (this.preorder.preorder) {
            this.preorderNumber = this.preorder.preorder.number
            this.preorderDate = this.preorder.preorder.date.substring(0, 10)
            this.printPreorderDate = moment(this.preorder.preorder.date).format('DD.MM.YYYY')
            this.oldPreorderDate = this.preorderDate
            this.isSigned = this.preorder.preorder.acceptTimestamp
            if (this.preorder.preorder.acceptTimestamp) {
              this.signTimestamp = moment(this.preorder.preorder.acceptTimestamp).format('DD.MM.YYYY HH:mm:ss')
            }
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadPreorderProduct (preorderProductsFindPost: PreorderProductsFindPost) {
      this.isLoading = true
      try {
        const result = await preorderProductsFind(preorderProductsFindPost)
        this.preorderProducts = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.isGet = true
      }
    },
    async signPreorder () {
      this.preorderSignPost.id = this.preorder.preorder.id
      this.preorderSignPost.placeId = this.selectedUserAllowedPlace
      this.preorderSignPost.date = this.preorderDate
      this.isLoading = true
      try {
        const result = await preorderSign(this.preorderSignPost)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder) {
          if (this.preorder.preorder) {
            this.preorderNumber = this.preorder.preorder.number
            this.preorderDate = this.preorder.preorder.date.substring(0, 10)
            this.oldPreorderDate = this.preorderDate
            this.isSigned = this.preorder.preorder.acceptTimestamp
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async unsignPreorder () {
      this.preorderUnsignPost.id = this.preorder.preorder.id
      this.preorderUnsignPost.placeId = this.selectedUserAllowedPlace
      this.preorderUnsignPost.date = this.preorderDate
      this.isLoading = true
      try {
        const result = await preorderUnsign(this.preorderUnsignPost)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder) {
          if (this.preorder.preorder) {
            this.preorderNumber = this.preorder.preorder.number
            this.preorderDate = this.preorder.preorder.date.substring(0, 10)
            this.oldPreorderDate = this.preorderDate
            this.isSigned = this.preorder.preorder.acceptTimestamp
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async savePreorder () {
      this.preorder.preorder.date = this.preorderDate
      this.isLoading = true
      try {
        const result = await preorderSave(this.preorder.preorder)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder) {
          if (this.preorder.preorder) {
            this.preorderNumber = this.preorder.preorder.number
            this.preorderDate = this.preorder.preorder.date.substring(0, 10)
            this.oldPreorderDate = this.preorderDate
            this.isSigned = this.preorder.preorder.acceptTimestamp
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isDateSave = true
        this.isLoading = false
      }
    },
    async getPreorderByFact () {
      this.preorderGetByFactPost.id = this.preorder.preorder.id
      this.preorderGetByFactPost.placeId = this.selectedUserAllowedPlace
      this.preorderGetByFactPost.date = this.preorderDate
      this.isLoading = true
      try {
        const result = await preorderGetByFactPost(this.preorderGetByFactPost)
        this.preorder = JSON.parse(JSON.stringify(result))
        if (this.preorder) {
          if (this.preorder.preorder) {
            this.preorderNumber = this.preorder.preorder.number
            this.preorderDate = this.preorder.preorder.date.substring(0, 10)
            this.oldPreorderDate = this.preorderDate
            this.isSigned = this.preorder.preorder.acceptTimestamp
          }
        }
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getPreorderCountByProductId (productId: any) {
      let count = 0
      if (this.preorder) {
        if (this.preorder.preorder) {
          if (this.preorder.preorder.preorderItems.length > 0) {
            const preorderProducts = this.preorder.preorder.preorderItems.filter((preorderItem: any) =>
              parseInt(preorderItem.productId) === parseInt(productId)
            )
            preorderProducts.forEach((preorderProduct: any) => {
              count = count + preorderProduct.count
            })
          }
        }
      }
      return count
    },
    getPreorderCorrectionCountByProductId (productId: any) {
      let count = 0
      if (this.preorder) {
        if (this.preorder.preorder) {
          if (this.preorder.preorder.preorderItems.length > 0) {
            const preorderProducts = this.preorder.preorder.preorderItems.filter((preorderItem: any) =>
              parseInt(preorderItem.productId) === parseInt(productId)
            )
            preorderProducts.forEach((preorderProduct: any) => {
              count = count + preorderProduct.correctionCount
            })
          }
        }
      }
      return count
    },
    changePlace () {
      this.preorderGetPost.placeId = this.selectedUserAllowedPlace
      this.preorderProductsFindPost.placeId = this.selectedUserAllowedPlace
      this.loadPreorder(this.preorderGetPost)
      this.loadPreorderProduct(this.preorderProductsFindPost)
    },
    changeDate () {
      if (new Date(this.preorderDate) < (new Date(new Date(Date.now()).toISOString().substr(0, 10)))) {
        Swal.fire({
          title: 'Нельзя именить дату на предыдущие дни',
          confirmButtonText: 'Понятно'
        }).then(() => {
          this.preorderDate = this.oldPreorderDate
        })
      } else {
        this.oldPreorderDate = this.preorderDate
        this.isDateSave = false
      }
    },
    print () {
      const printContent = document.getElementById('printableArea')
      const WinPrint = window.open('')
      if (WinPrint && printContent) {
        WinPrint.document.write(`
          <html>
            <head>
              <title></title>
            </head>
            <body>
            <div class="bodyprint">
            <input class="print-button" type="button" value="Печать" onclick="print()">
              ${printContent.innerHTML}
            </div>
            </body>
          </html>
        `)
        const link = WinPrint.document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        link.setAttribute('href', '/css/print.css')
        WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
        WinPrint.focus()
      }
    }
  },
  mounted () {
    if (this.propIsSigned) {
      this.isSigned = true
    }
    this.isLoading = false
    this.isLoading = true
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss"></style>
