/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_INCREMENTGROUPDEVICES } from '@/store/incrementGroupDevice/mutations'
import { RootState } from '@/store/state'
import {
  IncrementGroupDevice, IncrementGroupDeviceListState
} from '@/store/incrementGroupDevice/types'
import { IncrementGroupDeviceCreatePost } from '@/shared/api/incrementGroupDevice/api-types'
import {
  all as incrementGroupDeviceAll,
  create as incrementGroupDeviceCreate
} from '@/shared/api/incrementGroupDevice/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type IncrementGroupDeviceListContext = ActionContext<IncrementGroupDeviceListState, RootState>

const initialState: IncrementGroupDeviceListState = {
  incrementGroupDevices: [{
    id: 0,
    incrementGroupId: 0,
    devices: []
  }]
}

const getters: GetterTree<IncrementGroupDeviceListState, RootState> = {
  incrementGroupDevices (state: IncrementGroupDeviceListState): IncrementGroupDevice[] {
    return state.incrementGroupDevices
  }
}

const mutations: MutationTree<IncrementGroupDeviceListState> = {
  [SET_INCREMENTGROUPDEVICES] (state: IncrementGroupDeviceListState, incrementGroupDevices: IncrementGroupDevice[]) {
    state.incrementGroupDevices = incrementGroupDevices
  }
}

const actions: ActionTree<IncrementGroupDeviceListState, RootState> = {
  async all (
    { commit, dispatch, state }: IncrementGroupDeviceListContext
  ): Promise<IncrementGroupDevice[]> {
    try {
      const result = await incrementGroupDeviceAll()
      commit(SET_INCREMENTGROUPDEVICES, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async create (
    { commit, dispatch, state }: IncrementGroupDeviceListContext,
    data: IncrementGroupDeviceCreatePost
  ): Promise<IncrementGroupDevice[]> {
    try {
      const result = await incrementGroupDeviceCreate(data)
      commit(SET_INCREMENTGROUPDEVICES, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const incrementGroupDevices = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
