import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceFoodSettings } from '../../../store/nutritionSettings/types'
import {
  PlaceFoodSettingsCreate,
  PlaceFoodSettingsFind,
  UserFoodSettingsCreate,
  UserFoodSettingsFind
} from './api-types'

enum NutritionSettingsRoutes {
  routePlaceFoodSettingsFind = '/placefoodsettings/find',
  routePlaceFoodSettingsCreate = '/placefoodsettings/create',
  routeUserFoodSettingsFind = '/userfoodsettings/find',
  routeUserFoodSettingsCreate = '/userfoodsettings/create'
}

export const findPlaceFoodSettings = async (data: PlaceFoodSettingsFind): Promise<PlaceFoodSettings[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: PlaceFoodSettings[];
  }> = await axios.post(NutritionSettingsRoutes.routePlaceFoodSettingsFind, data)
  return result.data.data
}

export const createPlaceFoodSettings = async (data: PlaceFoodSettingsCreate): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse = await axios.post(NutritionSettingsRoutes.routePlaceFoodSettingsCreate, data)
  return result.data
}

export const findUserFoodSettings = async (data: UserFoodSettingsFind): Promise<PlaceFoodSettings[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: PlaceFoodSettings[];
  }> = await axios.post(NutritionSettingsRoutes.routeUserFoodSettingsFind, data)
  return result.data.data
}

export const createUserFoodSettings = async (data: UserFoodSettingsCreate): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse = await axios.post(NutritionSettingsRoutes.routeUserFoodSettingsCreate, data)
  return result.data
}
