import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { UserInterimCardSetPost, UserInterimCardBlockPost, UserInterimCardRenewalPost } from './api-types'

const routeSetUserInterimCard = 'cards/userinterimcards/set'
const routeBlockUserInterimCard = 'cards/userinterimcards/block'
const routeRenewalUserInterimCard = 'cards/userinterimcards/renewal'

export const setUserInterimCard = async (data: UserInterimCardSetPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeSetUserInterimCard, data)
  return result.data.data
}

export const blockUserInterimCard = async (data: UserInterimCardBlockPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeBlockUserInterimCard, data)
  return result.data.data
}

export const renewalUserInterimCard = async (data: UserInterimCardRenewalPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeRenewalUserInterimCard, data)
  return result.data.data
}
