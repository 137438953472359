import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceCustomerProduct } from '@/store/placeCustomerProductList/types'
import {
  PlaceCustomerProductSearchPost,
  PlaceCustomerProductsAllPost,
  PlaceCustomerProductSearchByPlacesPost,
  PlaceCustomerProductSearchByPlacesWithPricesPost,
  PlaceCustomerRatePlansFind
} from './api-types'

export enum PlaceCustomerProductRoutes {
  routePlaceCustomerProductFind = '/placecustomerproducts/find',
  routePlaceCustomerProductFindByPlacesWithPrices = '/placecustomerproducts/findbyplaceswithprices',
  routePlaceCustomerProductAll = 'placecustomerproducts/all',
  routePlaceCustomerProductFindByPlaces = '/placecustomerproducts/findbyplaces',
  routeCustomerPlaceRatePlansFind = 'placecustomerproducts/search'
}

export const find = async (data: PlaceCustomerProductSearchPost): Promise<PlaceCustomerProduct[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: PlaceCustomerProduct[];
  }> = await axios.post(PlaceCustomerProductRoutes.routePlaceCustomerProductFind, data)
  return result.data.data
}

export const all = async (data: PlaceCustomerProductsAllPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceCustomerProductRoutes.routePlaceCustomerProductAll, data)
  return result.data.data
}

export const findbyplaces = async (data: PlaceCustomerProductSearchByPlacesPost): Promise<any[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceCustomerProductRoutes.routePlaceCustomerProductFindByPlaces, data)
  return result.data.data
}

export const findbyplaceswithprices = async (data: PlaceCustomerProductSearchByPlacesWithPricesPost): Promise<any[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceCustomerProductRoutes.routePlaceCustomerProductFindByPlacesWithPrices, data)
  return result.data.data
}

export const findCustomerPlaceRatePlans = async (data: PlaceCustomerRatePlansFind): Promise<any[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceCustomerProductRoutes.routeCustomerPlaceRatePlansFind, data)
  return result.data.data
}
