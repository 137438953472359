import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { Place } from '@/store/places/types'
import {
  PlaceFindPost,
  PlaceWeekTypeSetPost,
  PlaceFindByParentIdPost,
  PlaceFindByUserTypePost,
  PlaceFindByDateWithNamePost
} from './api-types'

export enum PlaceRoutes {
  routePlaceFindByTypeId = '/places/findbytypeid',
  routePlaceFindByParentId = '/places/find',
  routePlaceFindByParentIdWithName = '/places/findwithname',
  routePlaceFindByParentIdWithDistricts = '/places/findwithdistricts',
  routePlaceFindByUserTypeWithDistricts = '/places/findbyusertypewithdistricts',
  routePlaceWorkTypeSet = '/places/setweektype',
  routePlaceFindByDateWithName = 'places/findbydateswithname',
}

const routePlaceFindByTypeId = '/places/findbytypeid'
const routePlaceFindByParentId = '/places/find'
const routePlaceFindByParentIdWithName = '/places/findwithname'
const routePlaceFindByParentIdWithDistricts = '/places/findwithdistricts'
const routePlaceFindByUserTypeWithDistricts = '/places/findbyusertypewithdistricts'
const routePlaceWorkTypeSet = '/places/setweektype'
const routePlaceFindByDateWithName = 'places/findbydateswithname'

export const placeFindByTypeId = async (data: PlaceFindPost): Promise<Place[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Place[];
  }> = await axios.post(PlaceRoutes.routePlaceFindByTypeId, data)
  return result.data.data
}

export const placeWorkTypeSet = async (data: PlaceWeekTypeSetPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceRoutes.routePlaceWorkTypeSet, data)
  return result.data.data
}

export const placeFindByParentId = async (data: PlaceFindByParentIdPost): Promise<Place[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Place[];
  }> = await axios.post(PlaceRoutes.routePlaceFindByParentId, data)
  return result.data.data
}

export const placeFindByParentIdWithDistricts = async (data: PlaceFindByParentIdPost): Promise<Place[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Place[];
  }> = await axios.post(PlaceRoutes.routePlaceFindByParentIdWithDistricts, data)
  return result.data.data
}

export const placeFindByUserTypeWithDistricts = async (data: PlaceFindByUserTypePost): Promise<Place[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Place[];
  }> = await axios.post(PlaceRoutes.routePlaceFindByUserTypeWithDistricts, data)
  return result.data.data
}

export const placeFindByParentIdWithName = async (data: PlaceFindByParentIdPost): Promise<Place[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Place[];
  }> = await axios.post(PlaceRoutes.routePlaceFindByParentIdWithName, data)
  return result.data.data
}

export const placeFindByDateWithName = async (data: PlaceFindByDateWithNamePost): Promise<Place[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Place[];
  }> = await axios.post(PlaceRoutes.routePlaceFindByDateWithName, data)
  return result.data.data
}
