import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import {
  AcquiringDeleteBindingRequest,
  AcquiringDeleteBindingResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  GetTelegramChatAccountUserPaymentsRequest,
  GetTelegramChatAccountUserPaymentsResponse,
  UpdateAccessTelegramChatAccountUserPaymentsRequest, UpdateAccessTelegramChatAccountUserPaymentsResponse
} from '@/shared/api/parents/settings/api-types'

enum SettingsRoutes {
  ChangePassword = '/user/changeLoginAndPassword',
  DeleteBinding = '/person/acquiring/deleteBinding',
  TelegramChatUpdateAccess = '/person/telegramChatAccountUserPayments/updateAccess',
  TelegramChatGetByUser = '/person/telegramChatAccountUserPayments/getByUser'
}

const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const changePassword = async (data: ChangePasswordRequest, telegram: boolean): Promise<ChangePasswordResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<ChangePasswordResponse>(SettingsRoutes.ChangePassword, data, { headers })
    return result.data
  } else {
    const result = await axios.post<ChangePasswordResponse>(SettingsRoutes.ChangePassword, data)
    return result.data
  }
}

export const deleteBinding = async (data: AcquiringDeleteBindingRequest, telegram: boolean): Promise<AcquiringDeleteBindingResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<any>(SettingsRoutes.DeleteBinding, data, { headers })
    return result.data
  } else {
    const result = await axios.post<any>(SettingsRoutes.DeleteBinding, data)
    return result.data
  }
}

export const getTelegramChatAccountUserPayments = async (data: GetTelegramChatAccountUserPaymentsRequest, telegram: boolean): Promise<GetTelegramChatAccountUserPaymentsResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<GetTelegramChatAccountUserPaymentsResponse>(SettingsRoutes.TelegramChatGetByUser, data, { headers })
    return result.data
  } else {
    const result = await axios.post<GetTelegramChatAccountUserPaymentsResponse>(SettingsRoutes.TelegramChatGetByUser, data)
    return result.data
  }
}

export const updateAccessTelegramChatAccountUserPayments = async (data: UpdateAccessTelegramChatAccountUserPaymentsRequest, telegram: boolean): Promise<UpdateAccessTelegramChatAccountUserPaymentsResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<UpdateAccessTelegramChatAccountUserPaymentsResponse>(SettingsRoutes.TelegramChatUpdateAccess, data, { headers })
    return result.data
  } else {
    const result = await axios.post<UpdateAccessTelegramChatAccountUserPaymentsResponse>(SettingsRoutes.TelegramChatUpdateAccess, data)
    return result.data
  }
}
