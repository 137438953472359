<template>
  <div class="wholee_page">
    <div v-if="!showEditOrder">
      <div class="wrapper">
        <div class="goBackBtn">
          <div>
            <v-btn
              class="goBackButton"
              @click="goBacktoPreoderForTommorowPage"
            >
              Назад
            </v-btn>
          </div>
        </div>
        <div class="choose_date_panel">
          <div class="choose_week">
            <v-btn
              v-for="week in weeks"
              :id="week.id"
              :key="week.id"
              :style="{marginLeft: '7px', backgroundColor: week.id === currentWeek ? '#90ee90' : '#f5f5f5'}"
              @click="selectWeek(week)"
            >
              {{ week.name }}
            </v-btn>
          </div>
          <div class="choose_day">
            <v-btn
              v-for="day in days"
              :key="day.id"
              :style="{marginLeft: '7px', backgroundColor: day.id === currentDay ? '#90ee90' : '#f5f5f5'}"
              @click="selectDay(day)"
            >
              {{ day.name }}
            </v-btn>
          </div>
        </div>
        <template v-if="!isLoading">
          <div
            v-if="currentOrder"
            class="chosen_ration"
          >
            <div style="display: flex; flex-direction: column; align-items: center">
              <h3
                class="text-center"
                style="margin-bottom: 10px"
              >
                Выбранный заказ:
              </h3>
              <template v-if="checkAvailableToEditOrCreateOrder()">
                <v-btn
                  class="text-center"
                  style="width:130px"
                  @click="showEditRation"
                >
                  Изменить
                </v-btn>
              </template>
              <template v-else>
                <p>Рацион можно будет редактировать с {{ getNearestAvailableDateToEditOrCreateOrder() }}</p>
              </template>
              <ration-card :accepted-ration="currentOrder" />
            </div>
          </div>
          <div
            v-if="!currentOrder"
            class="add_ration"
          >
            <template v-if="!showCreateOrder">
              <h3 class="text-center">
                На этот день заказов нет
              </h3><br>
              <template v-if="checkAvailableToEditOrCreateOrder()">
                <v-btn @click="goToCreatePreorderPage">
                  Добавить заказ
                </v-btn>
              </template>
              <template v-else>
                <p>Рацион можно будет создать с {{ getNearestAvailableDateToEditOrCreateOrder() }}</p>
              </template>
            </template>
            <template v-else>
              <div style="display: flex; flex-direction: column; align-items: center">
                <h3
                  class="text-center"
                  style="margin-top: 5px"
                >
                  Выберите рацион:
                </h3>
                <div style="display: flex; flex-direction: row; align-items: center">
                  <v-btn
                    class="text-center"
                    style="width: 100px"
                    @click="goBackFromCreateOrderPage"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                    class="text-center"
                    :disabled="!selectedRationIdToSave"
                    style="width:100px; margin-left: 30px"
                    @click="saveRation"
                  >
                    Сохранить
                  </v-btn>
                </div>
              </div>
              <div class="rations_cards">
                <template v-for="ration in getRationsForSelectedDate()">
                  <template v-if="ration.rationAspectId === 1">
                    <div
                      :key="ration.rationId"
                      style="position: relative"
                    >
                      <ration-card
                        :accepted-ration="ration"
                        :with-checkbox="true"
                        :checked="ration.rationId === selectedRationIdToSave"
                        @selected-ration-to-save="selectRationToSaveHandler($event)"
                      />
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div
            style="margin-top: 60px;"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="showEditOrder"
      class="changePreorder"
    >
      <div
        class="wrapper"
        :style="{display: 'flex', justifyContent: 'center'}"
      >
        <span class="text-h5 align-center">Изменение меню</span>
      </div>
      <div class="some">
        <template v-if="!isLoading">
          <div
            align="center"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            <v-btn
              class="test_btn"
              style="width: 100px"
              @click="closeEditRation"
            >
              Отмена
            </v-btn>
            <!--          todo  Метод сохранения предзаказа по пользователю POST /rationUserPreorders/save/-->
            <v-btn
              :disabled="isEditBtnDisabled()"
              style="margin-left: 30px; width:130px"
              @click="editRation"
            >
              Сохранить
            </v-btn>
          </div>
          <div class="menu_box">
            <h3 class="text-center">
              Выберите рацион:
            </h3>
            <div class="rations_cards">
              <template
                v-for="ration in getRationsForSelectedDate()"
                :key="ration.rationId"
                >
                <!--              TODO: пока отключить доп питание (rationAspectId === 2) v-if="ration.rationAspectId === 1"-->
                    <ration-card
                      :accepted-ration="ration"
                      :with-checkbox="true"
                      :checked="ration.rationId === selectedRationIdToSave"
                      @selected-ration-to-save="selectRationToSaveHandler($event)"
                    />
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            style="margin-top: 60px;"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  rationClassPurposesFindByUserWithProducts,
  rationPlaceSettingsFindByUser,
  rationUserPreordersFind,
  rationUserPreordersSave,
  userRationSettingFindByUser
} from '@/shared/api/preorders/api-requests'
import {
  RationClassPurposesFindByUserWithProductsApiRequest,
  RationUserPreordersFindApiRequest
} from '@/shared/api/preorders/api-types'
import { Person } from '@/store/user/types'
import { mapActions, mapGetters } from 'vuex'
import RationCard from '@/components/parents/preorders/RationCard.vue'
import moment from 'moment/moment'
import { defineComponent } from 'vue'

export type RationsAndProductDataTree = RationsAndProductDataTreeItem[]
export type RationsAndProductDataTreeItem = {
  week: number;
  days: {
    day: number;
    rations: {
      id: number | null;
      rationId: number;
      accountTypeId: number | null;
      rationAspectId: number | null;
      staticPrice: number | null;
      productPrice: number | null;
      name: string;
      products: {
        name: string;
        productId: number;
        meelTypeId: number;
        price: number | null;
      }[];
    }[];
  }[];
}
export type RationDataType = RationDataTypeItem[]
export type RationDataTypeItem = {
  id: number | null;
  rationId: number;
  accountTypeId: number | null;
  rationAspectId: number | null;
  staticPrice: number | null;
  productPrice: number | null;
  name: string;
  productId: number | null;
  products: ProductDataType;
}
export type ProductDataType = ProductDataTypeItem[]
export type ProductDataTypeItem = {
  name: string;
  productId: number;
  meelTypeId: number;
  price: number | null;
}
export default defineComponent({
  name: 'PreordersChooseRation',
  components: { RationCard },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      weeks: [{
        id: 1,
        name: 'Нед.1'
      }, {
        id: 2,
        name: 'Нед.2'
      }],
      days: [{
        id: 1,
        name: 'пн'
      }, {
        id: 2,
        name: 'вт'
      }, {
        id: 3,
        name: 'ср'
      }, {
        id: 4,
        name: 'чт'
      }, {
        id: 5,
        name: 'пт'
      }],
      currentUser: {} as Person,
      rationsAndProductData: [{}] as any,
      rationAndProductsTree: [] as RationsAndProductDataTree,
      rations: [] as any,
      products: [] as any,
      currentOrder: null as null | RationDataTypeItem,
      showEditOrder: false,
      startDate: '',
      isLoading: false,
      currentWeek: null as null | number,
      currentDay: null as null | number,
      showCreateOrder: false,
      selectedRationIdToSave: null as null | number,
      selectedRationToSave: null as null | RationDataTypeItem,
      rationUserPreorders: null as any,
      daysDisabledSetting: null as null | number,
      todayWeek: null as null | number,
      todayDay: null as null | number,
      todayFulLDate: ''
    }
  },
  async beforeMount () {
    this.currentUser = this.user.person
    this.isLoading = true
    await this.loadCurrentDateSettings()
    await this.getRationsAndProductsForEveryDay()
    await this.getRationUserPreordersFind()
    this.currentOrder = this.getCurrentOrder()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadCurrentDateSettings () {
      try {
        const rationPlaceSettingsFindByUserReqObj = { userId: this.currentUser?.id }
        const rationPlaceSettingsFindByUserRes = await rationPlaceSettingsFindByUser(rationPlaceSettingsFindByUserReqObj)
        const userRationSettingFindByUserReqObj = {
          userId: this.currentUser?.id,
          rationSettingTypeId: 2
        }
        const userRationSettingFindByUserRes = await userRationSettingFindByUser(userRationSettingFindByUserReqObj)
        if (userRationSettingFindByUserRes.data?.length > 0 && userRationSettingFindByUserRes.data?.[0]?.status === 1 && userRationSettingFindByUserRes.data?.[0]?.value > 0) {
          this.daysDisabledSetting = userRationSettingFindByUserRes.data?.[0]?.value
        }
        const preorderDateSettings = rationPlaceSettingsFindByUserRes.data
        if (preorderDateSettings) {
          if (preorderDateSettings.date) {
            let offsetStartDate: number
            this.startDate = preorderDateSettings.date
            if (preorderDateSettings.weekValue === 1) {
              offsetStartDate = preorderDateSettings.weekDay
            } else if (preorderDateSettings.weekValue >= 2 && preorderDateSettings.weekValue <= 4) {
              // console.log(preorderDateSettings.weekday)
              offsetStartDate = (preorderDateSettings.weekValue - 1) * 7 + preorderDateSettings.weekDay
            } else {
              offsetStartDate = 0
            }
            // console.log(this.startDate)
            // console.log(offsetStartDate)
            // todo добавить получение текущей даты по серверу
            const currDate = moment()
            this.todayFulLDate = moment(currDate).format('YYYY-MM-DD')
            // console.log('currDate', currDate)
            const startDate = moment(this.startDate)
            let days = currDate.diff(startDate, 'days')
            days = (days + offsetStartDate) % 14
            let weekNumber: number
            if (days >= 1 && days <= 7) {
              weekNumber = 1
            } else {
              weekNumber = 2
            }
            // else if (days >= 5 && days <= 21) {
            //   weekNumber = 3
            // } else {
            //   weekNumber = 4
            // }
            // console.log(days, 'days')
            const weekDay = [0, 6].includes(days % 7) ? 5 : days % 7
            // console.log(weekNumber, weekDay, 'неделя, день')
            this.currentWeek = weekNumber
            this.currentDay = weekDay
            this.todayWeek = weekNumber
            this.todayDay = weekDay
          }
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить настройки пользователя!',
          timeout: 3000
        })
      }
    },
    async getRationsAndProductsForEveryDay () {
      try {
        const res = [
          {
            week: 1,
            days: [
              {
                day: 1,
                rations: []
              },
              {
                day: 2,
                rations: []
              },
              {
                day: 3,
                rations: []
              },
              {
                day: 4,
                rations: []
              },
              {
                day: 5,
                rations: []
              },
              {
                day: 6,
                rations: []
              }
            ]
          },
          {
            week: 2,
            days: [
              {
                day: 1,
                rations: []
              },
              {
                day: 2,
                rations: []
              },
              {
                day: 3,
                rations: []
              },
              {
                day: 4,
                rations: []
              },
              {
                day: 5,
                rations: []
              },
              {
                day: 6,
                rations: []
              }
            ]
          }
        ] as RationsAndProductDataTree
        const getAllRationsData: RationClassPurposesFindByUserWithProductsApiRequest = {
          userId: this.currentUser.id
        }
        const allRationsData = await rationClassPurposesFindByUserWithProducts(getAllRationsData)
        this.rations = allRationsData?.rationClassPurposes
        this.products = allRationsData?.rationProducts
        allRationsData.data.rationProducts.forEach((product: any) => {
          // console.log(product)
          const existWeekIdx = res.findIndex(weekitem => weekitem.week === product.weekNumber)
          // console.log('existWeekIdx', existWeekIdx)
          if (existWeekIdx !== -1) {
            const existDayIdx = res[existWeekIdx].days.findIndex(dayItem => dayItem.day === product.weekDay)
            if (existDayIdx !== -1) {
              // console.log('existDayIdx', existDayIdx)
              const existRationIdx = res[existWeekIdx].days[existDayIdx].rations.findIndex(ration => ration.rationId === product.rationId)
              // console.log('existRationIdx', existRationIdx)
              if (existRationIdx === -1) {
                const rationItem: RationDataTypeItem = {
                  id: null,
                  rationId: product.rationId,
                  accountTypeId: null,
                  rationAspectId: null,
                  staticPrice: null,
                  productPrice: null,
                  productId: null,
                  name: '',
                  products: [
                    {
                      name: product?.product?.name,
                      productId: product?.productId,
                      meelTypeId: product?.meelTypeId,
                      price: product?.price || null
                    }
                  ]
                }
                const rationInfo = allRationsData.data?.rationClassPurposes.find((ration: any) => ration.rationId === product.rationId)
                if (rationInfo) {
                  rationItem.accountTypeId = rationInfo.ration?.accountTypeId
                  rationItem.rationAspectId = rationInfo.ration?.rationAspectId
                  rationItem.staticPrice = rationInfo.ration?.staticPrice
                  rationItem.productPrice = rationInfo.ration?.price
                  rationItem.productId = rationInfo.ration?.productId
                  rationItem.name = rationInfo.ration?.product?.name
                }
                res[existWeekIdx].days[existDayIdx].rations.push(rationItem)
              } else {
                const productExistIdx = res[existWeekIdx].days[existDayIdx].rations[existRationIdx].products.findIndex(existProduct => existProduct.productId === product.productId)
                if (productExistIdx === -1) {
                  res[existWeekIdx].days[existDayIdx].rations[existRationIdx].products.push(
                    {
                      name: product?.product?.name,
                      productId: product?.productId,
                      meelTypeId: product?.meelTypeId,
                      price: product?.price || null
                    }
                  )
                }
              }
            }
          }
        })
        console.log('resWeeksRations', res)
        this.rationAndProductsTree = res
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список рационов!',
          timeout: 3000
        })
      }
    },
    async getRationUserPreordersFind () {
      const userPreordersGet: RationUserPreordersFindApiRequest = {
        userId: this.currentUser.id
      }
      const res = await rationUserPreordersFind(userPreordersGet)
      // console.log(res, 'res')
      this.rationUserPreorders = res.data
      const userPreordersData = JSON.parse(JSON.stringify(res))
      // console.log(userPreordersData, 'data')
    },
    async saveRation () {
      console.log('saveRation', this.selectedRationToSave)
      if (this.daysDisabledSetting) {
        if (this.currentWeek && this.todayWeek && this.currentDay && this.todayDay) {
          let daysDiff = Math.abs(this.currentWeek - this.todayWeek) * 7 + (this.currentDay - this.todayDay)
          if (daysDiff < 0) {
            daysDiff += 14
          }
          // if (daysDiff === 0) {
          //   daysDiff = 14
          // }
          if (daysDiff < this.daysDisabledSetting) {
            console.log('Запрещено редактирование')
            this.setSnackbar({
              showing: true,
              text: `Ошибка. Нельзя редактировать рацион на дату ближе ${this.daysDisabledSetting} от сегодняшней!`,
              timeout: 3000
            })
          } else {
            try {
              if (this.selectedRationIdToSave && this.selectedRationToSave?.rationId && this.selectedRationToSave?.productId && this.currentWeek && this.currentDay) {
                const rationUserPreordersSaveReqObj = {
                  userId: this.currentUser.id,
                  items: [{
                    id: null,
                    rationId: this.selectedRationToSave.rationId,
                    productId: this.selectedRationToSave.productId,
                    week: this.currentWeek,
                    day: this.currentDay,
                    meelTypeId: 0, // TODO: обговорить с Саней откуда брать
                    type: 0, // TODO: обговорить с Саней откуда брать
                    status: 1
                  }]
                }
                console.log('rationUserPreordersSaveReqObj', rationUserPreordersSaveReqObj)
                this.isLoading = true
                const res = await rationUserPreordersSave(rationUserPreordersSaveReqObj)
                console.log(res)
              }
            } catch (e) {
              this.setSnackbar({
                showing: true,
                text: 'Ошибка. Не удалось сохранить рацион!',
                timeout: 3000
              })
            } finally {
              await this.refreshData()
              this.isLoading = false
            }
          }
        }
      } else {
        try {
          if (this.selectedRationIdToSave && this.selectedRationToSave?.rationId && this.selectedRationToSave?.productId && this.currentWeek && this.currentDay) {
            const rationUserPreordersSaveReqObj = {
              userId: this.currentUser.id,
              items: [{
                id: null,
                rationId: this.selectedRationToSave.rationId,
                productId: this.selectedRationToSave.productId,
                week: this.currentWeek,
                day: this.currentDay,
                meelTypeId: 0, // TODO: обговорить с Саней откуда брать
                type: 0, // TODO: обговорить с Саней откуда брать
                status: 1
              }]
            }
            console.log('rationUserPreordersSaveReqObj', rationUserPreordersSaveReqObj)
            this.isLoading = true
            const res = await rationUserPreordersSave(rationUserPreordersSaveReqObj)
            console.log(res)
          }
        } catch (e) {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка. Не удалось сохранить рацион!',
            timeout: 3000
          })
        } finally {
          await this.refreshData()
          this.isLoading = false
        }
      }
    },
    getDaysDiffBetweenCurrentAndToday (): number | null {
      if (this.currentWeek && this.todayWeek && this.currentDay && this.todayDay) {
        let daysDiff = Math.abs(this.currentWeek - this.todayWeek) * 7 + (this.currentDay - this.todayDay)
        if (daysDiff < 0) {
          daysDiff += 14
        }
        return daysDiff
      }
      return null
    },
    getNearestAvailableDateToEditOrCreateOrder () {
      const daysDiff = this.getDaysDiffBetweenCurrentAndToday()
      if (daysDiff !== null && this.daysDisabledSetting !== null && daysDiff < this.daysDisabledSetting) {
        return moment(this.todayFulLDate).add(daysDiff + 1, 'd').format('DD.MM.YYYY')
      } else {
        return moment(this.todayFulLDate).format('DD.MM.YYYY')
      }
    },
    checkAvailableToEditOrCreateOrder () {
      // todo добавить получение текущей даты по серверу
      const currDate = moment()
      const daysDiff = this.getDaysDiffBetweenCurrentAndToday()
      if (daysDiff && this.daysDisabledSetting) {
        if (daysDiff < this.daysDisabledSetting) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    async editRation () {
      console.log('editRation', this.selectedRationToSave, this.currentOrder)
      console.log(this.daysDisabledSetting, this.todayFulLDate, this.todayDay, this.todayWeek, this.currentDay, this.currentWeek)
      if (this.daysDisabledSetting) {
        if (this.currentWeek && this.todayWeek && this.currentDay && this.todayDay) {
          let daysDiff = Math.abs(this.currentWeek - this.todayWeek) * 7 + (this.currentDay - this.todayDay)
          if (daysDiff < 0) {
            daysDiff += 14
          }
          // if (daysDiff === 0) {
          //   daysDiff = 14
          // }
          if (daysDiff < this.daysDisabledSetting) {
            console.log('Запрещено редактирование')
            this.setSnackbar({
              showing: true,
              text: `Ошибка. Нельзя редактировать рацион на дату ближе ${this.daysDisabledSetting} от сегодняшней!`,
              timeout: 3000
            })
          } else {
            console.log('Редактировать можно')
            try {
              if (this.selectedRationIdToSave && this.selectedRationToSave?.rationId && this.selectedRationToSave?.productId &&
                this.currentWeek && this.currentDay && this.currentOrder?.id && this.currentOrder?.productId) {
                const rationUserPreordersSaveReqObj = {
                  userId: this.currentUser.id,
                  items: [
                    {
                      id: null,
                      rationId: this.selectedRationToSave.rationId,
                      productId: this.selectedRationToSave.productId,
                      week: this.currentWeek,
                      day: this.currentDay,
                      meelTypeId: 0, // TODO: обговорить с Саней откуда брать
                      type: 0, // TODO: обговорить с Саней откуда брать
                      status: 1
                    },
                    {
                      id: this.currentOrder.id,
                      rationId: this.currentOrder.rationId,
                      productId: this.currentOrder.productId,
                      week: this.currentWeek,
                      day: this.currentDay,
                      meelTypeId: 0, // TODO: обговорить с Саней откуда брать
                      type: 0, // TODO: обговорить с Саней откуда брать
                      status: 2
                    }
                  ]
                }
                console.log('rationUserPreordersSaveReqObj', rationUserPreordersSaveReqObj)
                this.isLoading = true
                const res = await rationUserPreordersSave(rationUserPreordersSaveReqObj)
                console.log(res)
              }
            } catch (e) {
              this.setSnackbar({
                showing: true,
                text: 'Ошибка. Не удалось обновить рацион!',
                timeout: 3000
              })
            } finally {
              await this.refreshData()
              this.isLoading = false
            }
          }
        } else {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка. Не удалось получить текущую дату!',
            timeout: 3000
          })
        }
      } else {
        try {
          if (this.selectedRationIdToSave && this.selectedRationToSave?.rationId && this.selectedRationToSave?.productId &&
            this.currentWeek && this.currentDay && this.currentOrder?.id && this.currentOrder?.productId) {
            const rationUserPreordersSaveReqObj = {
              userId: this.currentUser.id,
              items: [
                {
                  id: null,
                  rationId: this.selectedRationToSave.rationId,
                  productId: this.selectedRationToSave.productId,
                  week: this.currentWeek,
                  day: this.currentDay,
                  meelTypeId: 0, // TODO: обговорить с Саней откуда брать
                  type: 0, // TODO: обговорить с Саней откуда брать
                  status: 1
                },
                {
                  id: this.currentOrder.id,
                  rationId: this.currentOrder.rationId,
                  productId: this.currentOrder.productId,
                  week: this.currentWeek,
                  day: this.currentDay,
                  meelTypeId: 0, // TODO: обговорить с Саней откуда брать
                  type: 0, // TODO: обговорить с Саней откуда брать
                  status: 2
                }
              ]
            }
            console.log('rationUserPreordersSaveReqObj', rationUserPreordersSaveReqObj)
            this.isLoading = true
            const res = await rationUserPreordersSave(rationUserPreordersSaveReqObj)
            console.log(res)
          }
        } catch (e) {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка. Не удалось обновить рацион!',
            timeout: 3000
          })
        } finally {
          await this.refreshData()
          this.isLoading = false
        }
      }
    },
    isEditBtnDisabled (): boolean {
      if (this.selectedRationIdToSave && (this.selectedRationIdToSave !== this.currentOrder?.rationId)) {
        return false
      }
      return true
    },
    async refreshData () {
      this.isLoading = true
      console.log(this.currentUser)
      // await this.loadCurrentDateSettings({ userId: this.currentUser?.id })
      await this.getRationUserPreordersFind()
      this.currentOrder = this.getCurrentOrder()
      this.isLoading = false
      this.showEditOrder = false
      this.showCreateOrder = false
    },
    showEditRation () {
      console.log('showEditRation', this.currentOrder)
      if (this.currentOrder?.rationId) {
        this.selectedRationIdToSave = this.currentOrder.rationId
        this.selectedRationToSave = this.currentOrder
        this.showEditOrder = true
      }
    },
    closeEditRation () {
      console.log('closeEditRation')
      this.showEditOrder = false
    },
    selectRationToSaveHandler (ration: any) {
      if (this.selectedRationIdToSave === ration.rationId) {
        this.selectedRationIdToSave = null
        this.selectedRationToSave = null
      } else {
        this.selectedRationIdToSave = ration.rationId
        this.selectedRationToSave = ration
      }
    },
    getRationsForSelectedDate () {
      console.log('getRationsForSelectedDate', this.currentWeek, this.currentDay)
      const weekIdx = this.rationAndProductsTree.findIndex(weekItem => weekItem.week === this.currentWeek)
      if (weekIdx !== -1) {
        const dayIdx = this.rationAndProductsTree[weekIdx].days.findIndex(dayItem => dayItem.day === this.currentDay)
        if (dayIdx !== -1) {
          console.log(this.rationAndProductsTree[weekIdx].days[dayIdx].rations)
          return this.rationAndProductsTree[weekIdx].days[dayIdx].rations
        }
      }
      return []
    },
    getCurrentOrder (): RationDataTypeItem | null {
      // console.log(this.rationUserPreorders)
      // console.log(this.currentOrder, this.currentWeek, this.currentDay)
      const existUserRation = this.rationUserPreorders.find((userPreorder: any) => userPreorder.day === this.currentDay && userPreorder.week === this.currentWeek)
      // console.log('existUserRation', existUserRation)
      // TODO: не показывать, если rationAspectId === 2
      if (existUserRation?.rationId) {
        const currentOrder = this.rationAndProductsTree.find(weekItem => weekItem.week === this.currentWeek)?.days.find(dayItem => dayItem.day === this.currentDay)?.rations.find(rationItem => rationItem.rationId === existUserRation.rationId)
        if (currentOrder && currentOrder?.rationId) {
          currentOrder.id = existUserRation.id
          return currentOrder as RationDataTypeItem
        }
      }
      return null
    },
    selectWeek (week: any) {
      this.showCreateOrder = false
      this.selectedRationIdToSave = null
      this.selectedRationToSave = null
      this.currentWeek = week.id
      this.currentOrder = this.getCurrentOrder()
    },
    selectDay (day: any) {
      this.showCreateOrder = false
      this.selectedRationIdToSave = null
      this.selectedRationToSave = null
      this.currentDay = day.id
      this.currentOrder = this.getCurrentOrder()
    },
    goBacktoPreoderForTommorowPage () {
      this.$router.push('./5')
    },
    goToCreatePreorderPage () {
      this.showCreateOrder = true
      // this.$router.push('./8')
    },
    goBackFromCreateOrderPage () {
      this.showCreateOrder = false
    }
  }
})
</script>

<style scoped lang="scss">
.wholee_page {
  display: grid;
}

.wrapper {
  height: 100%
}

.choose_day {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.choose_date_panel {
  display: grid;
  justify-content: center;
  margin-top: 18px;
}

.table-grid {
  justify-content: center;
  margin-top: 15px;
}

.chosen_ration {
  display: grid;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.add_ration {
  display: grid;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  align-content: center;
}

.goBackButton {
  font-size: xx-small;
  width: 150px;
  margin: 10px;
}

.changePreorder {
  justify-content: center;
  display: grid;
}

v-btn:focus {
  background-color: #1976d2;
}

test_btn:hover {
  background-color: #1976d2 !important;
}

.rations_cards {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  //width: 60%;
  //border: 5px #101010;
  margin-top: 30px;
  //position: relative;
}

@media (max-aspect-ratio: 6/5) {
  .wholee_page {
    display: grid;
    padding: 3%;
  }
  .add_ration {
    margin-top: 15px;
  }
  .goBackButton {
    font-size: xx-small;
    margin: 0px;
    width: 120px;
    margin-left: 1.5%;
  }
  .rations_cards {
    display: grid;
    justify-content: center;
    margin-top: 5px;
    width: 96%;
    position: relative;
  }
}
</style>
