import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { User } from '@/store/userRatePlan/types'
import { UserRatePlanFindPost } from './api-types'

const routeUserRatePlanFind = 'userrateplans/find'

export const find = async (data: UserRatePlanFindPost): Promise<User[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: User[] ;
  }> = await axios.post(routeUserRatePlanFind, data)

  return result.data.data
}
