import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { FoodWarehousePlacesFindPost, FoodWarehousePlacesAddPost, FoodWarehousePlaceRemovePost, FoodWarehousePlaceFindFreePost } from './api-types'

const routeFoodWarehousePlacesFind = '/foodWarehousePlaces/find'
const routeFoodWarehousePlacesFindFree = '/foodWarehousePlaces/findfree'
const routeFoodWarehousePlacesAdd = '/foodWarehousePlaces/create'
const routeFoodWarehousePlaceRemove = '/foodWarehousePlaces/update'

export const foodWarehousePlacesFind = async (data: FoodWarehousePlacesFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehousePlacesFind, data)
  return result.data.data
}

export const foodWarehousePlacesFindFree = async (data: FoodWarehousePlaceFindFreePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehousePlacesFindFree, data)
  return result.data.data
}

export const foodWarehousePlacesAdd = async (data: FoodWarehousePlacesAddPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehousePlacesAdd, data)
  return result.data.data
}

export const foodWarehousePlaceRemove = async (data: FoodWarehousePlaceRemovePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehousePlaceRemove, data)
  return result.data.data
}
