<template>
  <div class="bank-transaction-summary">
    <div class="filters-panel">
      <div
        class="mr-5"
        style="width: 150px;"
      >
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateFrom)"
              label="с:"
              prepend-icon="mdi-calendar"
              readonly

              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата С"
            v-model="dateFrom"
            @input="menu1 = false"
          />
        </v-menu>
      </div>
      <div style="width: 150px;">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateTo)"
              label="по:"
              prepend-icon="mdi-calendar"
              readonly

              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата По"
            v-model="dateTo"
            @input="menu2 = false"
          />
        </v-menu>
      </div>
      <div style="width: 100px; margin-left: auto">
        <v-btn
          :is-loading="isLoading"
          :disabled="isLoading || disableBtn"
          block
          @click="loadTable()"
        >
          Показать
        </v-btn>
      </div>
    </div>
    <h1 class="bank-transaction-summary__title">
      Реестр платежей СБП
    </h1>
    <div
      class="wrapper"
      :style="{display: 'flex', justifyContent: 'center', width: '100%'}"
    >
      <template v-if="!isLoading">
        <template v-if="tableData.items.length > 0">
          <base-simple-table
            :table-data="tableData"
            @click-on-slot-after="goToDetail"
          >
            <template #slot-after-action>
              <v-btn>Перейти к деталям</v-btn>
            </template>
          </base-simple-table>
        </template>
        <template v-else-if="tableData.rowsCount === 0 & tableData.columnsCount === 0">
          <h3>Данных не найдено, попробуйте изменить период поиска данных...</h3>
        </template>
      </template>
      <template v-else>
        <v-row>
          <v-col
            v-if="isLoading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
      </template>
    </div>
    <!--    <div v-if="readytoCount && !isLoading" class="Itogi" :style="{display: 'flex', justifyContent: 'center', width: '100%', padding: 5}">-->

    <!--      <h5> <h3>Итого :</h3> Полученная сумма за период: {{this.incomingSum}} руб, Перечисленная сумма за период: {{this.transactionSum}} руб,  Сумма комиссий за период: {{this.comissionSumm}} руб</h5>-->
    <!--    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import moment from 'moment'
import { AxiosError } from 'axios'
import { mapActions } from 'vuex'
import { findAcquiringOrderRegisters } from '@/shared/api/acquiring/api-requests'

export default defineComponent({
  name: 'BankTransactionsSummary',
  components: { BaseSimpleTable },
  data () {
    return {
      isLoading: false,
      tableData: {} as TableData,
      menu1: false,
      menu2: false,
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().add('days', 1).format('YYYY-MM-DD'),
      disableBtn: false,
      comissionSumm: 0,
      incomingSum: 0,
      transactionSum: 0,
      readytoCount: false
    }
  },
  async beforeMount () {
    this.tableData.items = []
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    goToDetail (item: any) {
      this.$router.push({
        name: 'BankTransactionDetail',
        params: { id: `${item.additionalInfo.id}`, date: `${item.additionalInfo.date}`, number: `${item.additionalInfo.number}` }
      })
    },
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async loadTable () {
      if (moment(this.dateTo).diff(this.dateFrom, 'months', true) > 3) {
        this.setSnackbar({
          showing: true,
          text: 'Максимальный период 3 месяца!',
          timeout: 3000
        })
      } else if (!this.dateFrom) {
        this.setSnackbar({
          showing: true,
          text: 'Введите Дату С',
          timeout: 3000
        })
      } else if (!this.dateTo) {
        this.setSnackbar({
          showing: true,
          text: 'Введите Дату По',
          timeout: 3000
        })
      } else if (moment(this.dateTo).diff(this.dateFrom, 'months', true) < 0) {
        this.setSnackbar({
          showing: true,
          text: 'Дата По не может быть меньше чем Дата С!',
          timeout: 3000
        })
      } else {
        this.isLoading = true
        this.tableData.rowsCount = 4
        this.tableData.columnsCount = 7
        this.tableData.items = []
        this.comissionSumm = 0
        this.transactionSum = 0
        this.incomingSum = 0
        try {
          const findAcquiringOrderRegistersReqObj = {
            dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.dateTo).format('YYYY-MM-DD')
          }
          const res = await findAcquiringOrderRegisters(findAcquiringOrderRegistersReqObj)
          if (res.length > 0) {
            this.tableData.rowsCount = 4
            this.tableData.items.push(
              {
                text: '№ реестра',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 2,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              },
              {
                text: 'Дата реестра',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 2,
                gridColumnEnd: 3,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              },
              {
                text: 'Получено, руб',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 3,
                gridColumnEnd: 4,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              },
              {
                text: 'Перечислено, руб',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 4,
                gridColumnEnd: 5,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              },
              {
                text: 'Сумма комиссии, руб',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 5,
                gridColumnEnd: 6,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              },
              {
                text: 'Период поступлений',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 6,
                gridColumnEnd: 7,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              },
              {
                text: '',
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 7,
                gridColumnEnd: 8,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '16px'
              }
            )
            let currentRow = 2
            res.forEach(resItem => {
              this.tableData.items.push(
                {
                  text: `${resItem.acquiringOrderRegisterId}`,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 1,
                  gridColumnEnd: 2,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px'
                },
                {
                  text: `${resItem.date}`,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 2,
                  gridColumnEnd: 3,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px'
                },
                {
                  text: `${resItem.incomingSum}`,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 3,
                  gridColumnEnd: 4,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px'
                },
                {
                  text: `${+resItem.incomingSum - +resItem.comission}`,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 4,
                  gridColumnEnd: 5,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px'
                },
                {
                  text: `${resItem.comission}`,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 5,
                  gridColumnEnd: 6,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px'
                },
                {
                  text: `${resItem.minDate} - ${resItem.maxDate}`,
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 6,
                  gridColumnEnd: 7,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px'
                },
                {
                  text: '',
                  gridRowStart: currentRow,
                  gridRowEnd: currentRow + 1,
                  gridColumnStart: 7,
                  gridColumnEnd: 8,
                  background: 'null',
                  fontWeight: 'bold',
                  padding: '5px',
                  fontSize: '14px',
                  slotAfter: {
                    name: 'action',
                    classNames: 'slot-after-action'
                  },
                  additionalInfo: {
                    id: resItem.id,
                    date: resItem.date,
                    number: resItem.acquiringOrderRegisterId
                  }
                }
              )
              this.comissionSumm = this.comissionSumm + parseFloat(resItem.comission)
              this.comissionSumm.toFixed(2)
              this.incomingSum = this.incomingSum + parseFloat(resItem.incomingSum)
              const a = (parseFloat(resItem.incomingSum) - parseFloat(resItem.comission)).toFixed(2)
              this.transactionSum = this.transactionSum + parseFloat(a)
              this.transactionSum.toFixed(2)
              currentRow += 1
            })
            this.tableData.items.push(
              {
                text: 'Итого :',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 1,
                gridColumnEnd: 2,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              },
              {
                text: '',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 2,
                gridColumnEnd: 3,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              },
              {
                text: `${this.incomingSum}`,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 3,
                gridColumnEnd: 4,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              },
              {
                text: `${this.transactionSum}`,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 4,
                gridColumnEnd: 5,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              },
              {
                text: `${this.comissionSumm}`,
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 5,
                gridColumnEnd: 6,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              },
              {
                text: '',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 6,
                gridColumnEnd: 7,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              },
              {
                text: '',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 7,
                gridColumnEnd: 8,
                background: '#faebd7',
                fontWeight: 'bold',
                padding: '5px',
                fontSize: '14px'
              }
            )
            this.tableData.rowsCount = currentRow
          } else {
            this.tableData.columnsCount = 0
            this.tableData.rowsCount = 0
          }
        } catch (error: any) {
          let errorText = 'Ошибка. Повторите попытку.'
          const err = error as AxiosError
          if (err.response) {
            errorText = err.response.data.errorMessage
          }
          this.setSnackbar({
            showing: true,
            text: errorText,
            timeout: 3000
          })
        } finally {
          this.isLoading = false
          this.readytoCount = true
        }
      }
    }
  }
})
</script>

<style scoped lang="scss">
.filters-panel {
  display: flex;
  width: 100%;
  justify-content: center;
}

.bank-transaction-summary {
  padding: 20px;
  margin-top: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;

  .bank-transaction-summary__title {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
}
</style>
