import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import {
  AccountsFindByUserIdWithTypesApiRequest,
  PreordersFindPost,
  PreordersGetPost,
  RationClassPurposesFindByUserWithProductsApiRequest,
  RationOrdersCancelByIdAndUserApiRequest,
  RationOrdersFindByUserAndDatesApiRequest,
  RationOrdersFindByUserOnDateApiRequest,
  RationOrdersFindByUserOnDateApiResponse,
  RationPlaceSettingsFindByUserApiRequest,
  RationSettingFindByUserApiRequest,
  RationSettingFindByUserApiResponse, RationsFindApiResponse,
  RationUserPreordersFindApiRequest,
  RationUserPreordersReportByDaysApiRequest,
  RationUserPreordersReportByDaysApiResponse,
  RationUserPreordersSaveApiRequest,
  TelegramAuthUserLoginApiRequest,
  TelegramAuthUserLoginApiResponse,
  TelegramAuthUsersApiRequest,
  TelegramAuthUsersApiResponse,
  UserRationSettingsFindApiRequest,
  UserRationSettingsFindApiResponse,
  UserRationSettingsSaveApiRequest,
  UserRationSettingsSaveApiResponse
} from './api-types'

enum PreordersRoutes {
  PreordersFind = 'preorders/find',
  PreordersGet = 'preorders/get',
  UserRationSettingsFind = 'userRationSettings/find',
  UserRationSettingsSave = 'userRationSettings/save',
  RationClassPurposesFindByUserWithProducts = 'rationClassPurposes/findByUserWithProducts',
  RationUserPreordersFind = 'rationUserPreorders/find',
  RationUserPreordersReportByDays = 'rationUserPreorders/reportByDays',
  RationPlaceSettingsFindByUser = 'rationPlaceSettings/findByUser',
  RationUserPreordersSave = 'rationUserPreorders/save',
  RationOrdersFindByUserAndDates = 'rationOrders/findByUserAndDates',
  RationsFind = 'rations/find',
  AccountsFindByUserIdWithTypes = 'accounts/findByUserIdWithTypes',
  RationOrdersFindByUserOnDate = 'rationOrders/findByUserOnDate',
  RationOrdersCancelByIdAndUser = 'rationOrders/cancelByIdAndUser',
  RationSettingsFindByUser = 'rationSettings/findByUser',
  TelegramAuthUsers = 'telegram/authUsers',
  TelegramAuthUserLogin = 'telegram/authUser/login'
}

const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const find = async (data: PreordersFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PreordersRoutes.PreordersFind, data)
  return result.data.data
}

export const get = async (data: PreordersGetPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PreordersRoutes.PreordersGet, data)
  return result.data.data
}

/**
 * Метод получения флага “Автоматическое назначение”
 * @param data
 * @return {Promise<T>}
 */
export const userRationSettingsFind = async (data: UserRationSettingsFindApiRequest): Promise<UserRationSettingsFindApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<UserRationSettingsFindApiResponse>(PreordersRoutes.UserRationSettingsFind, data)
  return result.data
}
/**
 * Метод получения настроек организации по пользователю
 * @param data
 */
export const userRationSettingFindByUser = async (data: RationSettingFindByUserApiRequest): Promise<RationSettingFindByUserApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<RationSettingFindByUserApiResponse>(PreordersRoutes.RationSettingsFindByUser, data)
  return result.data
}

/**
 * Метод устанавливающий флаг “Автоматическое назначение”
 * @param data
 * @returns {Promise<T>}
 */
export const userRationSettingsSave = async (data: UserRationSettingsSaveApiRequest): Promise<UserRationSettingsSaveApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<UserRationSettingsSaveApiResponse>(PreordersRoutes.UserRationSettingsSave, data)
  return result.data
}

/**
 * Метод получения набора доступных рационов и продуктов с ценами в рационе по всем дням и неделям
 * @param data
 * @returns {Promise<any>}
 */
export const rationClassPurposesFindByUserWithProducts = async (data: RationClassPurposesFindByUserWithProductsApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.RationClassPurposesFindByUserWithProducts, data)
  return result.data
}

/**
 * Метод получения конкретных позиций выбранных персоне на какие-либо дни недели
 * @param data
 * @returns {Promise<any>}
 */
export const rationUserPreordersFind = async (data: RationUserPreordersFindApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.RationUserPreordersFind, data)
  return result.data
}

/**
 * Метод получения настроек недели по текущей дате
 * @param data
 * @returns {Promise<any>}
 */
export const rationPlaceSettingsFindByUser = async (data: RationPlaceSettingsFindByUserApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.RationPlaceSettingsFindByUser, data)
  return result.data
}

/**
 * Метод сохранения предзаказа по пользователю
 * @param data
 */
export const rationUserPreordersSave = async (data: RationUserPreordersSaveApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.RationUserPreordersSave, data)
  return result.data
}

/**
 * Метод получения плана заказов
 * @param data
 * @returns {Promise<any>}
 */
export const rationOrdersFindByUserAndDates = async (data: RationOrdersFindByUserAndDatesApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.RationOrdersFindByUserAndDates, data)
  return result.data
}

/**
 * Метод получения данных для отчета по количеству предзаказов за указанный период по дням
 * @param data
 * @returns {Promise<T>}
 */
export const rationUserPreordersReportByDays = async (data: RationUserPreordersReportByDaysApiRequest): Promise<RationUserPreordersReportByDaysApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<RationUserPreordersReportByDaysApiResponse>(PreordersRoutes.RationUserPreordersReportByDays, data)
  return result.data
}

/**
 * Метод получения списка рационов и продуктов организации
 * @returns {Promise<T>}
 */
export const rationsFind = async (): Promise<RationsFindApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<RationsFindApiResponse>(PreordersRoutes.RationsFind)
  return result.data
}

/**
 * Метод получения баланса на счете ребенка
 * @param data
 * @returns {Promise<any>}
 */
export const accountsFindByUserIdWithTypes = async (data: AccountsFindByUserIdWithTypesApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.AccountsFindByUserIdWithTypes, data)
  return result.data
}

/**
 * Метод получения заказа на ближайшую дату
 * @param data
 * @returns {Promise<T>}
 */
export const rationOrdersFindByUserOnDate = async (data: RationOrdersFindByUserOnDateApiRequest): Promise<RationOrdersFindByUserOnDateApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<RationOrdersFindByUserOnDateApiResponse>(PreordersRoutes.RationOrdersFindByUserOnDate, data)
  return result.data
}

/**
 * Метод отмены заказа
 * @param data
 * @returns {Promise<any>}
 */
export const rationOrdersCancelByIdAndUser = async (data: RationOrdersCancelByIdAndUserApiRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<any>(PreordersRoutes.RationOrdersCancelByIdAndUser, data)
  return result.data
}

export const telegramAuthUsers = async (data: TelegramAuthUsersApiRequest): Promise<TelegramAuthUsersApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<TelegramAuthUsersApiResponse>(PreordersRoutes.TelegramAuthUsers, data)
  return result.data
}

export const telegramAuthUserLogin = async (data: TelegramAuthUserLoginApiRequest): Promise<TelegramAuthUserLoginApiResponse> => {
  setAuthorizationHeader(axios)
  const result = await axios.post<TelegramAuthUserLoginApiResponse>(PreordersRoutes.TelegramAuthUserLogin, data)
  return result.data
}
