import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "hidden-sm-and-up" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.sidebar,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sidebar) = $event)),
      app: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: item.title,
                to: item.path
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.icon), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createTextVNode(" " + _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_toolbar, {
      theme: "dark",
      class: "bg-green-darken-4"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_v_app_bar_nav_icon, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sidebar = !_ctx.sidebar))
          })
        ]),
        _createVNode(_component_v_toolbar_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: "/",
              tag: "span",
              style: {"cursor":"pointer"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.appTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_toolbar_items, { class: "hidden-xs" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
              return (_openBlock(), _createBlock(_component_v_btn, {
                key: item.title,
                variant: "text",
                to: item.path
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    start: "",
                    theme: "dark"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.icon), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createTextVNode(" " + _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}