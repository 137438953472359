<template>
  <v-btn @click="exportXlsx">
    Скачать Excel
  </v-btn>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { TableData, TableDataCell } from '@/shared/uikit/BaseSimpleTable.vue'
import XLSX from 'xlsx-js-style'

export default defineComponent({
  name: 'ExportExcel',
  props: {
    tableData: {
      type: Object as PropType<TableData>,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      titleRows: 1
    }
  },
  methods: {
    async exportXlsx () {
      const wb = await XLSX.utils.book_new()
      const ws: any = await XLSX.utils.json_to_sheet([])
      const cols: { wch: number }[] = new Array(this.tableData.columnsCount).fill({ wch: 0 })
      ws['!merges'] = []
      try {
        // Добавляем заголовок
        const address = XLSX.utils.encode_cell({
          r: 1, c: 1
        })
        XLSX.utils.sheet_add_aoa(ws, [
          [`${this.title}`]
        ], { origin: 'A1' })
        ws['!merges'].push({
          s: { r: 0, c: 0 },
          e: { r: 0, c: this.tableData.columnsCount - 2 }
        })
        ws.A1.s = {
          font: {
            color: { rgb: '000000' }, // Наследование от стилей ячейки - { rgb: tableCell.color ? tableCell.color.replace('#', '') : '000000' },
            bold: 'bold',
            sz: '20'
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          },
          border: {
            left: {
              color: { rgba: '000000' },
              style: 'thin'
            },
            top: {
              color: { rgba: '000000' },
              style: 'thin'
            },
            right: {
              color: { rgba: '000000' },
              style: 'thin'
            },
            bottom: {
              color: { rgba: '000000' },
              style: 'thin'
            }
          }
        }

        // Заполняем саму таблицу
        this.tableData.items.forEach((tableCell: TableDataCell, idx: number) => {
          // Заполняем текст ячейки по нужному адресу
          XLSX.utils.sheet_add_aoa(ws, [
            [`${tableCell.text}`]
          ], { origin: { r: tableCell.gridRowStart - 1 + this.titleRows, c: tableCell.gridColumnStart - 1 } })

          // Соединяем ячейки
          ws['!merges'].push({
            s: { r: tableCell.gridRowStart - 1 + this.titleRows, c: tableCell.gridColumnStart - 1 },
            e: { r: tableCell.gridRowEnd - 2 + this.titleRows, c: tableCell.gridColumnEnd - 2 }
          })

          // Находим масимальную ширину ячейки в каждой колонке
          if (`${tableCell.text}`.length > cols[tableCell.gridColumnStart - 1].wch) {
            cols[tableCell.gridColumnStart - 1] = { wch: `${tableCell.text}`.length + 2 }
          }

          if ((tableCell.gridRowEnd - tableCell.gridRowStart > 1 || tableCell.gridColumnEnd - tableCell.gridColumnStart > 1) && !(tableCell.gridRowEnd - tableCell.gridRowStart === 1 && tableCell.gridColumnEnd - tableCell.gridColumnStart === 1)) {
            // Добавляем стили для соедииненной ячейки
            // console.log(tableCell)
            this.addStylesToMergedCell(tableCell, ws)
          } else {
            // Добавлям стили для ячейки 1x1
            const address = XLSX.utils.encode_cell({
              r: tableCell.gridRowStart - 1 + this.titleRows, c: tableCell.gridColumnStart - 1
            })
            ws[address].s = {
              font: {
                color: { rgb: '000000' }, // Наследование от стилей ячейки - { rgb: tableCell.color ? tableCell.color.replace('#', '') : '000000' },
                bold: tableCell.fontWeight === 'bold',
                sz: tableCell.fontSize ? tableCell.fontSize.replace('px', '') : '12'
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center'
              },
              border: {
                left: {
                  color: { rgba: '000000' },
                  style: 'thin'
                },
                top: {
                  color: { rgba: '000000' },
                  style: 'thin'
                },
                right: {
                  color: { rgba: '000000' },
                  style: 'thin'
                },
                bottom: {
                  color: { rgba: '000000' },
                  style: 'thin'
                }
              }
            }
          }
        })
        ws['!cols'] = cols
        // console.log(ws)
      } catch (e) {
        console.log(e)
      }
      XLSX.utils.book_append_sheet(wb, ws, `${this.title}`)
      XLSX.writeFile(wb, `${this.title}.xlsx`)
    },
    addStylesToMergedCell (tableCell: TableDataCell, ws: any) {
      for (let row = tableCell.gridRowStart - 1 + this.titleRows; row <= tableCell.gridRowEnd - 2 + this.titleRows; row++) {
        for (let col = tableCell.gridColumnStart - 1; col <= tableCell.gridColumnEnd - 2; col++) {
          const address = XLSX.utils.encode_cell({
            r: row, c: col
          })
          XLSX.utils.sheet_add_aoa(ws, [
            [`${tableCell.text}`]
          ], { origin: { r: row, c: col } })
          ws[address].s = {
            font: {
              color: { rgb: '000000' }, // Наследование от стилей ячейки - { rgb: tableCell.color ? tableCell.color.replace('#', '') : '000000' },
              bold: tableCell.fontWeight === 'bold',
              sz: tableCell.fontSize ? tableCell.fontSize.replace('px', '') : '12'
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center'
            },
            // fill: {
            //   fgColor: { rgba: 'FFFFFF' } // Наследование от стилей ячейки - { rgb: tableCell.background && tableCell.background !== 'none' ? tableCell.background.replace('#', '') : 'FFFFFF' }
            // },
            border: {
              left: {
                color: { rgba: '000000' },
                style: 'thin'
              },
              top: {
                color: { rgba: '000000' },
                style: 'thin'
              },
              right: {
                color: { rgba: '000000' },
                style: 'thin'
              },
              bottom: {
                color: { rgba: '000000' },
                style: 'thin'
              }
            }
          }
        }
      }
    }
  }
})
</script>

<style scoped>

</style>
