<template>
  <div
    id="printable-area"
    class="page-container"
  >
    <h2
      class="report-title"
      style="font-size: 22px"
    >
      Отчет по продажам с {{ params.timestampFrom }} по {{ params.timestampTo }}
    </h2>
    <h3
      v-if="currentSchoolObj"
      class="report-title"
    >
      {{ currentSchoolObj.place.name }}
    </h3>
    <div id="printable-area">
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && (sales.length > 0 || cashSales.length > 0)"
          class="d-flex flex-row justify-center"
        >
          <table class="report-fulltable">
            <tbody>
              <tr>
                <td
                  class="report-subtable--header"
                  colspan="100%"
                >
                  Отчет по продажам
                </td>
              </tr>
              <tr>
                <td
                  style="vertical-align: top; padding: 0;"
                  :style="{width: cashlessSalesBuffet.length > 0 || cashSalesHotMeals.length > 0 ? '50%' : '100%'}"
                >
                  <table
                    class="report-subtable"
                    style="width:100%;"
                  >
                    <tbody>
                      <tr>
                        <td class="report-subtable--header">
                          Наименование
                        </td>
                        <td class="report-subtable--header">
                          Количество
                        </td>
                        <td class="report-subtable--header">
                          Цена
                        </td>
                        <td class="report-subtable--header">
                          Сумма
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="4"
                          class="report-subtable--header"
                        >
                          Школьная карта
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="cashlessSalesBuffet.length > 0">
                      <tr>
                        <td
                          colspan="4"
                          class="report-subtable--header"
                        >
                          Буфет
                        </td>
                      </tr>
                      <tr
                        v-for="(buffetSale, i) in cashlessSalesBuffet"
                        :key="i"
                      >
                        <td class="pa-1">
                          {{ buffetSale.productName }}
                        </td>
                        <td class="pa-1">
                          {{ buffetSale.quantity }}
                        </td>
                        <td class="pa-1">
                          {{ buffetSale.price }}
                        </td>
                        <td class="pa-1">
                          {{ buffetSale.sum }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-if="params.showSumByFlags === 'on'">
                        <td class="report-subtable--total-row">
                          Итого собственное производство:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ getSumByProductFlag(cashlessSalesBuffet, 0) }}
                        </td>
                      </tr>
                      <tr v-if="params.showSumByFlags === 'on'">
                        <td class="report-subtable--total-row">
                          Итого покупное:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ getSumByProductFlag(cashlessSalesBuffet, 32768) }}
                        </td>
                      </tr>
                      <!--                    <tr v-if="showSumByGroups">-->
                      <!--                      <th>-->
                      <!--                        Итого сотр:-->
                      <!--                      </th>-->
                      <!--                      <th colspan="3">-->
                      <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 3, false, true) }}-->
                      <!--                      </th>-->
                      <!--                    </tr>-->
                      <!--                    <tr v-if="showSumByGroups">-->
                      <!--                      <th>-->
                      <!--                        Итого остальные:-->
                      <!--                      </th>-->
                      <!--                      <th colspan="3">-->
                      <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 2, false, true) }}-->
                      <!--                      </th>-->
                      <!--                    </tr>-->
                      <tr>
                        <td class="report-subtable--total-row">
                          Итого:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ cashlessSalesBuffetSum.toFixed(2) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="cashlessSalesHotMeals.length > 0">
                      <tr>
                        <td
                          colspan="4"
                          class="report-subtable--header"
                        >
                          Горячее питание
                        </td>
                      </tr>
                      <tr
                        v-for="(hotMeal, i) in cashlessSalesHotMeals"
                        :key="i"
                      >
                        <td class="pa-1">
                          {{ hotMeal.productName }}
                        </td>
                        <td class="pa-1">
                          {{ hotMeal.quantity }}
                        </td>
                        <td class="pa-1">
                          {{ hotMeal.price }}
                        </td>
                        <td class="pa-1">
                          {{ hotMeal.sum }}
                        </td>
                      </tr>
                      <tr v-if="params.showSumByFlags === 'on'">
                        <td class="report-subtable--total-row">
                          Итого собственное производство:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ getSumByProductFlag(cashlessSalesHotMeals, 0) }}
                        </td>
                      </tr>
                      <tr v-if="params.showSumByFlags === 'on'">
                        <td class="report-subtable--total-row">
                          Итого покупное:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ getSumByProductFlag(cashlessSalesHotMeals, 32768) }}
                        </td>
                      </tr>
                      <!--                    <tr v-if="showSumByGroups">-->
                      <!--                      <th>-->
                      <!--                        Итого сотр:-->
                      <!--                      </th>-->
                      <!--                      <th colspan="3">-->
                      <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 3, false, true) }}-->
                      <!--                      </th>-->
                      <!--                    </tr>-->
                      <!--                    <tr v-if="showSumByGroups">-->
                      <!--                      <th>-->
                      <!--                        Итого остальные:-->
                      <!--                      </th>-->
                      <!--                      <th colspan="3">-->
                      <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 2, false, true) }}-->
                      <!--                      </th>-->
                      <!--                    </tr>-->
                      <tr>
                        <td class="report-subtable--total-row">
                          Итого:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ cashlessSalesHotMealsSum.toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="report-subtable--total-row">
                          ВСЕГО школьная карта:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ (cashlessSalesBuffetSum + cashlessSalesHotMealsSum).toFixed(2) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="params.showSBP === 'on' && sbpSales.length > 0">
                      <tr>
                        <td
                          colspan="4"
                          class="report-subtable--header"
                        >
                          СБП
                        </td>
                      </tr>
                      <tr
                        v-for="(sbpSale, i) in sbpSales"
                        :key="i"
                      >
                        <td class="pa-1">
                          {{ sbpSale.productName }}
                        </td>
                        <td class="pa-1">
                          {{ sbpSale.quantity }}
                        </td>
                        <td class="pa-1">
                          {{ sbpSale.price }}
                        </td>
                        <td class="pa-1">
                          {{ sbpSale.sum }}
                        </td>
                      </tr>
                      <tr v-if="params.showSumByFlags === 'on'">
                        <td class="report-subtable--total-row">
                          Итого собственное производство:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ getSumByProductFlag(sbpSales, 0) }}
                        </td>
                      </tr>
                      <tr v-if="params.showSumByFlags === 'on'">
                        <td class="report-subtable--total-row">
                          Итого покупное:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ getSumByProductFlag(sbpSales, 32768) }}
                        </td>
                      </tr>
                      <!--                    <tr v-if="showSumByGroups">-->
                      <!--                      <th>-->
                      <!--                        Итого сотр:-->
                      <!--                      </th>-->
                      <!--                      <th colspan="3">-->
                      <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 3, false, true) }}-->
                      <!--                      </th>-->
                      <!--                    </tr>-->
                      <!--                    <tr v-if="showSumByGroups">-->
                      <!--                      <th>-->
                      <!--                        Итого остальные:-->
                      <!--                      </th>-->
                      <!--                      <th colspan="3">-->
                      <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 2, false, true) }}-->
                      <!--                      </th>-->
                      <!--                    </tr>-->
                      <tr>
                        <td class="report-subtable--total-row">
                          ВСЕГО СБП:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ sbpSalesSum.toFixed(2) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="discountSales.length > 0">
                      <tr>
                        <td
                          colspan="4"
                          class="report-subtable--header"
                        >
                          Льготное питание
                        </td>
                      </tr>
                      <tr
                        v-for="(discountSale, i) in discountSales"
                        :key="i"
                      >
                        <td class="pa-1">
                          {{ discountSale.productName }}
                        </td>
                        <td class="pa-1">
                          {{ discountSale.quantity }}
                        </td>
                        <td class="pa-1">
                          {{ discountSale.price }}
                        </td>
                        <td class="pa-1">
                          {{ discountSale.sum }}
                        </td>
                      </tr>
                      <tr>
                        <td class="report-subtable--total-row">
                          ВСЕГО льготное питание:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ discountSalesSum.toFixed(2) }}
                        </td>
                      </tr>
                    </tbody>
                    <template v-if="cashlessSalesBuffet.length === 0 && cashSalesHotMeals.length === 0">
                      <tbody v-if="vendingSales.length > 0">
                        <tr>
                          <td
                            colspan="4"
                            class="report-subtable--header"
                          >
                            Вендинг
                          </td>
                        </tr>
                        <tr
                          v-for="(vendingSale, i) in vendingSales"
                          :key="i"
                        >
                          <td class="pa-1">
                            {{ vendingSale.productName }}
                          </td>
                          <td class="pa-1">
                            {{ vendingSale.quantity }}
                          </td>
                          <td class="pa-1">
                            {{ vendingSale.price }}
                          </td>
                          <td class="pa-1">
                            {{ vendingSale.sum }}
                          </td>
                        </tr>
                        <tr>
                          <td class="report-subtable--total-row">
                            ВСЕГО вендинг:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ vendingSalesSum.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                    <tbody>
                      <tr>
                        <td
                          colspan="4"
                          class="report-subtable--header"
                        >
                          Финансовые итоги
                        </td>
                      </tr>
                      <tr>
                        <td class="report-subtable--total-row">
                          ВСЕГО школьная карта:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ (cashlessSalesBuffetSum + cashlessSalesHotMealsSum).toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="report-subtable--total-row">
                          ВСЕГО наличные:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ (cashSalesBuffetSum + cashSalesHotMealsSum).toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="report-subtable--total-row">
                          ВСЕГО СБП:
                        </td>
                        <td
                          colspan="3"
                          class="report-subtable--total-row"
                        >
                          {{ sbpSalesSum.toFixed(2) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <template v-if="cashSalesBuffet.length > 0 || cashSalesHotMeals.length > 0">
                  <td style="vertical-align: top; padding: 0; width:50%;">
                    <table
                      class="report-subtable"
                      style="width:100%;"
                    >
                      <tbody>
                        <tr>
                          <td class="report-subtable--header">
                            Наименование
                          </td>
                          <td class="report-subtable--header">
                            Количество
                          </td>
                          <td class="report-subtable--header">
                            Цена
                          </td>
                          <td class="report-subtable--header">
                            Сумма
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="4"
                            class="report-subtable--header"
                          >
                            Наличные
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-if="cashSalesBuffet.length > 0">
                        <tr>
                          <td
                            colspan="4"
                            class="report-subtable--header"
                          >
                            Буфет
                          </td>
                        </tr>
                        <tr
                          v-for="(buffetSale, i) in cashSalesBuffet"
                          :key="i"
                        >
                          <td class="pa-1">
                            {{ buffetSale.productName }}
                          </td>
                          <td class="pa-1">
                            {{ buffetSale.quantity }}
                          </td>
                          <td class="pa-1">
                            {{ buffetSale.price }}
                          </td>
                          <td class="pa-1">
                            {{ buffetSale.sum }}
                          </td>
                        </tr>
                        <tr v-if="params.showSumByFlags === 'on'">
                          <td class="report-subtable--total-row">
                            Итого собственное производство:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ getSumByProductFlag(cashSalesBuffet, 0) }}
                          </td>
                        </tr>
                        <tr v-if="params.showSumByFlags === 'on'">
                          <td class="report-subtable--total-row">
                            Итого покупное:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ getSumByProductFlag(cashSalesBuffet, 32768) }}
                          </td>
                        </tr>
                        <!--                    <tr v-if="showSumByGroups">-->
                        <!--                      <th>-->
                        <!--                        Итого сотр:-->
                        <!--                      </th>-->
                        <!--                      <th colspan="3">-->
                        <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 3, false, true) }}-->
                        <!--                      </th>-->
                        <!--                    </tr>-->
                        <!--                    <tr v-if="showSumByGroups">-->
                        <!--                      <th>-->
                        <!--                        Итого остальные:-->
                        <!--                      </th>-->
                        <!--                      <th colspan="3">-->
                        <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 2, false, true) }}-->
                        <!--                      </th>-->
                        <!--                    </tr>-->
                        <tr>
                          <td class="report-subtable--total-row">
                            Итого:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ cashSalesBuffetSum.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-if="cashSalesHotMeals.length > 0">
                        <tr>
                          <td
                            colspan="4"
                            class="report-subtable--header"
                          >
                            Горячее питание
                          </td>
                        </tr>
                        <tr
                          v-for="(hotMeal, i) in cashSalesHotMeals"
                          :key="i"
                        >
                          <td class="pa-1">
                            {{ hotMeal.productName }}
                          </td>
                          <td class="pa-1">
                            {{ hotMeal.quantity }}
                          </td>
                          <td class="pa-1">
                            {{ hotMeal.price }}
                          </td>
                          <td class="pa-1">
                            {{ hotMeal.sum }}
                          </td>
                        </tr>
                        <tr v-if="params.showSumByFlags === 'on'">
                          <td class="report-subtable--total-row">
                            Итого собственное производство:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ getSumByProductFlag(cashSalesHotMeals, 0) }}
                          </td>
                        </tr>
                        <tr v-if="params.showSumByFlags === 'on'">
                          <td class="report-subtable--total-row">
                            Итого покупное:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ getSumByProductFlag(cashSalesHotMeals, 32768) }}
                          </td>
                        </tr>
                        <!--                    <tr v-if="showSumByGroups">-->
                        <!--                      <th>-->
                        <!--                        Итого сотр:-->
                        <!--                      </th>-->
                        <!--                      <th colspan="3">-->
                        <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 3, false, true) }}-->
                        <!--                      </th>-->
                        <!--                    </tr>-->
                        <!--                    <tr v-if="showSumByGroups">-->
                        <!--                      <th>-->
                        <!--                        Итого остальные:-->
                        <!--                      </th>-->
                        <!--                      <th colspan="3">-->
                        <!--                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 2, false, true) }}-->
                        <!--                      </th>-->
                        <!--                    </tr>-->
                        <tr>
                          <td class="report-subtable--total-row">
                            Итого:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ cashSalesHotMealsSum.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td class="report-subtable--total-row">
                            ВСЕГО наличные:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ (cashSalesBuffetSum + cashSalesHotMealsSum).toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-if="vendingSales.length > 0">
                        <tr>
                          <td
                            colspan="4"
                            class="report-subtable--header"
                          >
                            Вендинг
                          </td>
                        </tr>
                        <tr
                          v-for="(discountSale, i) in vendingSales"
                          :key="i"
                        >
                          <td class="pa-1">
                            {{ discountSale.vendingSales }}
                          </td>
                          <td class="pa-1">
                            {{ discountSale.vendingSales }}
                          </td>
                          <td class="pa-1">
                            {{ discountSale.vendingSales }}
                          </td>
                          <td class="pa-1">
                            {{ discountSale.vendingSales }}
                          </td>
                        </tr>
                        <tr>
                          <td class="report-subtable--total-row">
                            ВСЕГО вендинг:
                          </td>
                          <td
                            colspan="3"
                            class="report-subtable--total-row"
                          >
                            {{ discountSalesSum.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { findByPlace } from '@/shared/api/salelist/api-requests'
import { AxiosError } from 'axios'
import { SaleByPlaceFindPost } from '@/shared/api/salelist/api-types'
import { transactionTypes } from '@/app/types/vueEnums'
import { th } from 'vuetify/locale'

type BaseData = {
  currentSchoolObj: any
  reportByUserPlaceSales: any;
  reportByUserPlaceCashSales: any;
  checkCache: boolean;
  checkProductId: any;
  checkForUser: any;
  saleGroupByUsers: any;
  productSaleDialogLoading: boolean;
  checksDialogLoading: boolean;
  saleProductName: any;
  productSales: any;
  checks: any;
  saleByProductDialog: boolean;
  checksDialog: boolean;
  selectedDevice: any;
  devices: any;
  showSumByFlags: boolean;
  showSumByGroups: boolean;
  showSBP: boolean;
  searchTerm: string;
  userAllowedPlacesCopy: any;
  selectedUserAllowedPlace: any;
  userAllowedPlacesList: any;
  isLoading: boolean;
  isPost: boolean;
  places: any;
  products: any;
  sales: any;
  buffetSalesSum: number;
  hotfoodSalesSum: number;
  discountSalesSum: number;
  vendingSalesSum: number;
  cashSalesBuffetSum: number;
  cashSalesHotMealsSum: number;
  discountCashSalesSum: number;
  vendingCashSalesSum: number;
  cashlessSalesBuffetSum: number;
  cashlessSalesHotMealsSum: number;
  sbpSalesSum: number;
  sbpSales: any;
  cashlessSalesBuffet: any;
  cashlessSalesHotMeals: any;
  discountSales: any;
  vendingSales: any;
  rationProducts: any;
  cashSalesBuffet: any;
  cashSalesHotMeals: any;
  discountCashSales: any;
  vendingCashSales: any;
  cashSales: any;
  dateFrom: any;
  dateTo: any;
  menu1: boolean;
  menu2: boolean;
  saleByPlacePost: any;
  saleGroupByProducts: any;
  params: any;
}

export default defineComponent({
  name: 'ByProductPrint',
  props: {
    propDateFrom: {
      type: String,
      required: false
    },
    propPlaceId: {
      type: String,
      required: false
    }
  },
  data (): BaseData {
    return {
      currentSchoolObj: null,
      reportByUserPlaceSales: [],
      reportByUserPlaceCashSales: [],
      checkCache: false,
      checkProductId: null,
      checkForUser: null,
      saleGroupByUsers: [],
      productSaleDialogLoading: false,
      checksDialogLoading: false,
      saleProductName: null,
      productSales: [],
      checks: [],
      saleByProductDialog: false,
      checksDialog: false,
      selectedDevice: null,
      devices: [],
      showSumByFlags: false, // - выводить подытоги
      showSumByGroups: false,
      showSBP: false, // - выделять СБП в отдельную подтаблицу (если false, то складываем со школьной картой)
      searchTerm: '',
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: null,
      isLoading: false,
      isPost: false,
      places: [],
      products: [],
      sales: [],
      buffetSalesSum: 0,
      hotfoodSalesSum: 0,
      discountSalesSum: 0,
      vendingSalesSum: 0,
      cashSalesBuffetSum: 0,
      cashSalesHotMealsSum: 0,
      discountCashSalesSum: 0,
      vendingCashSalesSum: 0,
      cashlessSalesBuffetSum: 0,
      cashlessSalesHotMealsSum: 0,
      sbpSalesSum: 0,
      cashlessSalesBuffet: [],
      cashlessSalesHotMeals: [],
      sbpSales: [],
      discountSales: [],
      vendingSales: [],
      rationProducts: [],
      cashSalesBuffet: [],
      cashSalesHotMeals: [],
      discountCashSales: [],
      vendingCashSales: [],
      cashSales: [],
      dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      saleByPlacePost: {
        placeId: null,
        timestampFrom: null,
        timestampTo: null
      },
      saleGroupByProducts: null,
      params: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  // components: { Table },
  async created () {
    this.params = JSON.parse(`${this.$route.redirectedFrom?.query?.urlParams}`)
    // this.params = {
    //   placeId: 15625,
    //   timestampFrom: '15.05.2024 00:00',
    //   timestampTo: '15.05.2024 23:59',
    //   ownerId: null,
    //   showSBP: 'off',
    //   showSumByFlags: 'on',
    //   showSumByGroups: 'on'
    // }
    // this.params = {
    //   placeId: 3404, // '5635'
    //   timestampFrom: '08.05.2024 00:00',
    //   timestampTo: '08.05.2024 23:59',
    //   ownerId: null,
    //   showSBP: 'off',
    //   showSumByFlags: 'on',
    //   showSumByGroups: 'on'
    // }
    // console.log(this.params)
    await this.loadUserAllowedPlaces()
  },
  async mounted () {
    await this.loadSales(this.params)
    print()
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadUserAllowedPlaces () {
      try {
        await this.userAllowedPlaceAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        this.currentSchoolObj = this.userAllowedPlacesList.find((obj: any) => {
          return `${obj.placeId}` === `${this.params.placeId}`
        })
        console.log('params.placeId', this.params.placeId)
        console.log('currentSchoolObj', this.currentSchoolObj)
      }
    },
    async loadSales (params: any) {
      this.isLoading = true
      try {
        this.vendingSales = []
        this.rationProducts = []
        this.cashSalesHotMeals = []
        this.cashSalesBuffet = []
        this.vendingCashSales = []
        const findByPlaceReqObj: SaleByPlaceFindPost = {
          placeId: params.placeId,
          timestampFrom: params.timestampFrom,
          timestampTo: params.timestampTo,
          ownerId: params.ownerId
        }
        const result = await findByPlace(findByPlaceReqObj)
        this.saleGroupByProducts = JSON.parse(JSON.stringify(result))
        if (Array.isArray(this.saleGroupByProducts.sales)) {
          this.sales = this.saleGroupByProducts.sales
        }
        if (Array.isArray(this.saleGroupByProducts.cashSales)) {
          this.cashSales = this.saleGroupByProducts.cashSales
        }
        if (Array.isArray(this.saleGroupByProducts.rationProducts)) {
          this.rationProducts = this.saleGroupByProducts.rationProducts
        }
        if (Array.isArray(this.saleGroupByProducts.products)) {
          this.products = this.saleGroupByProducts.products
        }
        if (Array.isArray(this.saleGroupByProducts.reportByUserPlaceSales)) {
          this.reportByUserPlaceSales = JSON.parse(JSON.stringify(this.saleGroupByProducts.reportByUserPlaceSales))
        }
        if (Array.isArray(this.saleGroupByProducts.reportByUserPlaceCashSales)) {
          this.reportByUserPlaceCashSales = JSON.parse(JSON.stringify(this.saleGroupByProducts.reportByUserPlaceCashSales))
        }
        // TODO: починить вендинг, он только за безнал и без скидки, все что скидкой - это льгота
        if (this.sales.length > 0) {
          let tempSales = this.sales.filter((el: any) => Number(el.discount) === 0)
          tempSales.forEach((element: any) => {
            const product = this.products.find((el: any) => el.id === element.productId)
            const sale = {
              productName: product.name,
              productId: element.productId,
              price: Number(element.price).toFixed(2),
              quantity: Number(element.quantity).toFixed(0),
              discount: Number(element.discount).toFixed(2),
              sum: Number(element.sum).toFixed(2),
              discountAvg: Number(element.discountAvg)
            }
            if (product.flags !== 1) {
              if (product.accountTypeId === '2') {
                this.cashlessSalesBuffet.push(sale)
                this.cashlessSalesBuffetSum += Number(sale.sum)
              } else {
                this.cashlessSalesHotMeals.push(sale)
                this.cashlessSalesHotMealsSum += Number(sale.sum)
              }
            } else {
              this.vendingSales.push(sale)
              this.vendingSalesSum += Number(sale.sum)
            }
          })
          tempSales = this.sales.filter((el: any) => Number(el.discount) !== 0)
          tempSales.forEach((element: any) => {
            const product = this.products.find((product: any) => product.id === element.productId)
            const sale = {
              productName: product.name,
              productId: element.productId,
              price: Number(element.price).toFixed(2),
              quantity: Number(element.quantity).toFixed(0),
              discount: Number(element.discount).toFixed(2),
              sum: Number(element.sum).toFixed(2),
              discountAvg: Number(element.discountAvg)
            }
            // if (product.flags !== '1') {
            this.discountSales.push(sale)
            this.discountSalesSum = this.discountSalesSum + Number(sale.sum)
            // } else {
            //   this.vendingSales.push(sale)
            //   this.vendingSalesSum = this.vendingSalesSum + Number(sale.sum)
            // }
          })
        }
        // TODO: Убрать вендинг за наличку
        if (this.cashSales.length > 0) {
          const tempSales = this.cashSales
          tempSales.forEach((element: any) => {
            const product = this.products.find((product: any) => product.id === element.productId)
            const sale = {
              productName: product.name,
              productId: element.productId,
              price: Number(element.price).toFixed(2),
              quantity: Number(element.quantity).toFixed(0),
              discount: Number(element.discount).toFixed(2),
              sum: (Number(element.price) * Number(element.quantity)).toFixed(2),
              discountAvg: Number(element.discountAvg),
              paymentType: Number(element.paymentType)
            }
            // if (product.flags !== '1') {
            if (sale.paymentType !== 3) {
              if (product.accountTypeId === '2') {
                this.cashSalesBuffet.push(sale)
                this.cashSalesBuffetSum = this.cashSalesBuffetSum + (Number(sale.price) * Number(sale.quantity))
              } else {
                this.cashSalesHotMeals.push(sale)
                this.cashSalesHotMealsSum = this.cashSalesHotMealsSum + (Number(sale.price) * Number(sale.quantity))
              }
            } else {
              if (this.params?.showSBP === 'on') {
                this.sbpSales.push(sale)
                this.sbpSalesSum += Number(sale.sum)
              } else {
                if (product.accountTypeId === '2') {
                  const foundInCashlessBuffet = this.cashlessSalesBuffet.find((cashlessBuffetSale: any) => cashlessBuffetSale.productId === sale.productId)
                  if (foundInCashlessBuffet) {
                    foundInCashlessBuffet.quantity = (Number(foundInCashlessBuffet.quantity) + Number(sale.quantity)).toFixed(2)
                    foundInCashlessBuffet.sum = (Number(foundInCashlessBuffet.sum) + Number(sale.sum)).toFixed(2)
                  } else {
                    this.cashlessSalesBuffet.push(sale)
                  }
                  this.cashlessSalesBuffetSum += Number(sale.sum)
                } else {
                  const foundInCashlessHotMeals = this.cashlessSalesHotMeals.find((cashlessHotMealsSale: any) => cashlessHotMealsSale.productId === sale.productId)
                  if (foundInCashlessHotMeals) {
                    foundInCashlessHotMeals.quantity = (Number(foundInCashlessHotMeals.quantity) + Number(sale.quantity)).toFixed(2)
                    foundInCashlessHotMeals.sum = (Number(foundInCashlessHotMeals.sum) + Number(sale.sum)).toFixed(2)
                  } else {
                    this.cashlessSalesHotMeals.push(sale)
                  }
                  this.cashlessSalesHotMealsSum += Number(sale.sum)
                }
              }
            }
            // } else {
            //
            //   this.vendingCashSales.push(sale)
            //   this.vendingCashSalesSum = this.vendingCashSalesSum + (Number(sale.price) * Number(sale.quantity))
            // }
          })
        }
        this.vendingSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
        this.cashSalesHotMeals.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
        this.cashSalesBuffet.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
        this.vendingCashSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
        this.rationProducts.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getTransactionTypeName (transactionTypeId: any) {
      const transactionType = transactionTypes.find((element: any) => {
        return Number(element.id) === Number(transactionTypeId)
      })
      if (transactionType === undefined) {
        return null
      }
      return transactionType.name
    },
    getStyleForSale (productId: any) {
      if (Number(this.checkProductId) !== Number(productId)) {
        return ''
      }
      return 'font-weight:bold;'
    },
    getSumByProductFlag (sales: any, productFlag: any) {
      let sum = 0
      sales.forEach((sale: any) => {
        const product = this.products.find((el: any) => el.id === sale.productId)
        if (product !== undefined) {
          let flag = product.flags
          if (flag === null) {
            flag = 0
          }
          if (Number(productFlag) === Number(flag)) {
            sum = sum + Number(sale.sum)
          }
        }
      })
      return sum.toFixed(2)
    },
    getSumByPlaceName (sales: any, accountType: number, placeNameType: number, isVending: boolean, checkDiscount: boolean) {
      let sum = 0
      sales.forEach((element: any) => {
        if (element.placeName === null) {
          if (placeNameType === 3) {
            return
          }
        } else {
          if (placeNameType === 2 && element.placeName.toLowerCase() === 'сотр') {
            return
          }
        }
        if (placeNameType === 3 && !element.placeName.toLowerCase().match(/сотр.*/)) {
          return
        }
        const product = this.products.find((product: any) => product.id === element.productId)
        // const sale = { productName: product.name, productId: element.productId, price: Number(element.price).toFixed(2), quantity: Number(element.quantity).toFixed(0), discount: Number(element.discount).toFixed(2), sum: (Number(element.price) * Number(element.quantity)).toFixed(2), discountAvg: Number(element.discountAvg) }

        if (isVending === true && product.flags !== '1') {
          return
        }

        if (Number(element.discountSum) > 0 && isVending !== true) {
          if (checkDiscount) {
            return
          }
        }

        let productAccountType = product.accountTypeId
        if (Number(productAccountType) > 2) {
          productAccountType = 1
        }
        if (Number(accountType) !== Number(productAccountType)) {
          return
        }
        if (checkDiscount) {
          sum = sum + (Number(element.sum))
        } else {
          sum = sum + (Number(element.price) * Number(element.quantity))
        }
      })
      return sum.toFixed(2)
    }
  }
})
</script>

<style lang="scss">
.report-title {
  text-align: center;
  margin: 10px;
}

//.report-fulltable {
// max-width: 1800px;
//}
.report-fulltable {
  //page-break-inside: auto !important;
  border-collapse: collapse;

  table {
    border-collapse: collapse;
  }

  thead {
    background: antiquewhite;

    th {
      //border: 1px solid grey;
    }
  }

  tfoot {
    background: antiquewhite;

    td {
      border: 1px solid grey;
    }
  }

  tbody {
    td {
      font-size: 11px !important;
      border: 1px solid grey;
      padding-bottom: 0px;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 0px;
    }

    tr {
      background-color: white !important;
    }

    th {
      font-size: 11px !important;
      background: antiquewhite;
      border: 1px solid grey;
      border-collapse: separate;
      border-image-outset: 0;
      border-image-repeat: stretch;
      border-image-slice: 100%;
      border-image-source: none;
      border-image-width: 1;
      border-spacing: 0px 0px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.6);
      font-family: "Roboto", sans-serif;
      //height: 98px;
      line-height: 4px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      padding-bottom: 0px;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 0px;
      tab-size: 4;
      text-align: center;
    }
  }
}

.report-subtable--header {
  height: fit-content !important;
  text-align: center !important;
  font-weight: bold !important;
}

.report-subtable--total-row {
  height: fit-content !important;
  font-weight: bold !important;
  text-align: center !important;
}

//.report-subtable {
//  width: 1000px;
//}
</style>
