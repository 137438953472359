import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceCountersFindPost } from './api-types'

const routePlaceCountersFind = '/placecounters/findbyplaceids'

export const placeCountersFind = async (data: PlaceCountersFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceCountersFind, data)
  return result.data.data
}
