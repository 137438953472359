<template>
  <div>
    <!--    <p>{{ JSON.parse(JSON.stringify(menuItems)) }}</p>-->
  </div>
  <div class="navbar-test-css">
    <v-navigation-drawer
      v-model="sidebar"
      temporary
      disable-resize-watcher
    >
      <v-list>
        <template v-for="item in menuItems">
          <template v-if="!item.subitems">
            <v-list-item
              v-if="!item.subitems && getAccess(item.rights)"
              :key="item.title"
              :to="item.path"
            >
              <v-list-item-action>
                <v-icon
                  :icon="item.icon"
                />
              </v-list-item-action>
              {{ item.title }}
            </v-list-item>
          </template>
          <template v-else>
            <v-menu
              :key="item.title"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-if="getAccessList(item.subitems)"
                  :class="getNavigationClass(item)"
                  v-bind="props"
                >
                  {{ item.title }}
                </v-list-item>
              </template>
              <v-list class="navbutton">
                <template v-for="(item, index) in item.subitems">
                  <v-list-item
                    v-if="!item.subitems"
                    v-show="getAccess(item.rights)"
                    :key="index"
                    router
                    :to="item.path"
                  >
                    <span>
                      <v-icon
                        :icon="item.icon"
                        start
                      />
                      {{ item.title }}
                    </span>
                  </v-list-item>
                  <template v-else>
                    <v-menu
                      :key="item.title"
                    >
                      <template #activator="{ props }">
                        <v-list-item
                          v-if="getAccessList(item.subitems)"
                          :class="getNavigationClass(item)"
                          v-bind="props"
                        >
                          {{ item.title }}
                        </v-list-item>
                      </template>
                      <v-list class="navbutton">
                        <v-list-item
                          v-for="(item, index) in item.subitems"
                          v-show="getAccess(item.rights)"
                          :key="index"
                          router
                          :to="item.path"
                        >
                          <span>
                            <v-icon
                              :icon="item.icon"
                              start
                            />
                            {{ item.title }}
                          </span>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
        <v-list-item @click.prevent="logout">
          <v-list-item-action>
            <v-icon
              :icon="'mdi-face-man'"
              start
            />
          </v-list-item-action>
          Выход
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      theme="dark"
      class="bg-green-darken-4"
      disable-resize-watcher
    >
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click.stop="sidebar = !sidebar" />
      </span>
      <v-toolbar-title>
        <router-link
          to="/"
          style="cursor: pointer"
        >
          {{ appTitle }}
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-xs">
        <template v-for="item in menuItems">
          <v-btn
            v-if="!item.subitems && getAccess(item.rights)"
            :key="item.title"
            :to="item.path"
          >
            <v-icon
              :icon="item.icon"
              :start="!!item.title"
            />
            {{ item.title }}
          </v-btn>
          <template v-if="item.subitems">
            <v-menu
              :key="item.title"
              transition="slide-x-transition"
              :is-sub-menu="true"
              :model-value="showNestedMenu"
            >
              <template #activator="{ props }">
                <v-btn
                  v-if="getAccessList(item.subitems)"
                  :class="getNavigationClass(item)"
                  v-bind="props"
                >
                  <v-icon
                    :icon="item.icon"
                    start
                  />
                  {{ item.title }}
                </v-btn>
              </template>
              <v-list class="navbutton">
                <template v-for="(item2, index) in item.subitems">
                  <v-list-item
                    v-if="!item2.subitems"
                    v-show="getAccess(item2.rights)"
                    :key="index"
                    router
                    :to="item2.path"
                  >
                    <span>
                      <v-icon
                        :icon="item2.icon"
                        start
                      />
                      {{ item2.title }}
                    </span>
                  </v-list-item>
                  <template v-else>
                    <v-menu
                      :key="item2.title"
                      transition="slide-x-transition"
                      :is-sub-menu="true"
                      :model-value="showNestedMenu"
                    >
                      <template #activator="{ props }">
                        <v-list-item
                          v-if="getAccessList(item2.subitems)"
                          :class="getNavigationClass(item2)"
                          v-bind="props"
                          class="w-100"
                        >
                          <span>
                            <v-icon
                              :icon="item2.icon"
                              start
                            />
                            {{ item2.title }}
                          </span>
                        </v-list-item>
                      </template>
                      <v-list class="navbutton">
                        <template v-for="(item3, index) in item2.subitems">
                          <v-list-item
                            v-if="!item3.subitems"
                            v-show="getAccess(item2.rights)"
                            :key="index"
                            router
                            :to="item3.path"
                            @click="closeNestedMenu"
                          >
                            <span>
                              <v-icon
                                :icon="item3.icon"
                                start
                              />
                              {{ item3.title }}
                            </span>
                          </v-list-item>
                          <template v-else>
                            <v-menu
                              :key="item3.title"
                              transition="slide-x-transition"
                              :is-sub-menu="true"
                              :model-value="showNestedMenu"
                            >
                              <template #activator="{ props }">
                                <v-list-item
                                  v-if="getAccessList(item3.subitems)"
                                  :class="getNavigationClass(item3)"
                                  v-bind="props"
                                  class="w-100"
                                >
                                  <span>
                                    <v-icon
                                      :icon="item3.icon"
                                      start
                                    />
                                    {{ item3.title }}
                                  </span>
                                </v-list-item>
                              </template>
                              <v-list class="navbutton">
                                <v-list-item
                                  v-for="(item4, index) in item3.subitems"
                                  v-show="getAccess(item4.rights)"
                                  :key="index"
                                  router
                                  :to="item4.path"
                                  @click="closeNestedMenu"
                                >
                                  <span>
                                    <v-icon
                                      :icon="item4.icon"
                                      start
                                    />
                                    {{ item4.title }}
                                  </span>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </template>
                      </v-list>
                    </v-menu>
                  </template>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
        <v-btn
          variant="text"
          @click.prevent="logout"
        >
          Выход
          <v-icon
            :icon="'mdi-logout'"
          />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { UserType } from '@/app/types/vueEnums'
import { RouteMeta, useRouter } from 'vue-router'

export default defineComponent({
  compatConfig: {
    COMPONENT_V_MODEL: false
    // or, for full vue 3 compat in this component:
    // MODE: 3,
  },
  name: 'NavBar',
  components: {},
  data () {
    return {
      router: useRouter(),
      appTitle: '',
      sidebar: null,
      menuItems: [
        { title: 'Главная', path: '/', icon: 'home', rights: [UserType.ForAll] },
        { title: 'Возврат', path: '/oldsite/', icon: 'gavel', rights: [UserType.ForAll] },
        { title: 'Предзаказ', path: '/parents/preorders/desktop/5', icon: 'gavel', rights: [UserType.Parent] },
        {
          title: 'Настройка',
          icon: 'mdi-wrench',
          subitems: [
            {
              title: 'Список устройств',
              path: '/devices/',
              icon: 'list',
              rights: [UserType.Admin, UserType.Rosohrana, UserType.RosohranaAdmin]
            },
            {
              title: 'Настройка инкрементального обновления',
              path: '/devices/incrementgroups/',
              icon: 'mdi-alarm',
              rights: [UserType.Admin]
            },
            {
              title: 'Логи с приборов',
              path: '/devices/cardlogs/',
              icon: 'mdi-harddisk',
              rights: [UserType.Admin, UserType.Rosohrana, UserType.RosohranaAdmin]
            }
            // ,
            // {
            //   title: 'Отчеты',
            //   icon: 'mdi-information',
            //   rights: [UserType.Admin],
            //   subitems: [
            //     {
            //       title: 'Остатки услуг',
            //       path: '/reports/balanceofservices',
            //       icon: 'mdi-information',
            //       rights: [UserType.Admin]
            //     }
            //   ]
            // }
          ]
        },
        // {
        //   title: 'По ученикам и категориям',
        //   path: '/table/bystudentandcategorypermonth/',
        //   icon: 'list',
        //   rights: [UserType.Admin]
        // },
        {
          title: 'Документы',
          icon: 'mdi-text-box-multiple',
          rights: [UserType.LAdmin, UserType.Admin90],
          subitems: [
            {
              title: 'Предварительные заявки',
              icon: 'home',
              rights: [UserType.LAdmin, UserType.Admin90],
              subitems: [
                {
                  title: 'Реестр',
                  path: '/preorder/reestr/',
                  icon: 'mdi-information',
                  rights: [UserType.LAdmin, UserType.Admin90]
                },
                { title: 'Создание', path: '/preorder/add/', icon: 'mdi-information', rights: [UserType.LAdmin] }
              ]
            },
            { title: 'Электронные талоны', path: '/talons/', icon: 'mdi-domain', rights: [UserType.LAdmin] }
          ]
        },
        {
          title: 'Отчёты',
          icon: 'mdi-information',
          subitems: [
            {
              title: 'Акт реализации талонов',
              icon: 'mdi-information',
              rights: [UserType.LAdmin],
              subitems: [
                { title: 'За день', path: '/report/acts/day/', icon: 'mdi-information', rights: [UserType.LAdmin] },
                { title: 'За период', path: '/report/acts/month/', icon: 'mdi-information', rights: [UserType.LAdmin] },
                {
                  title: 'За месяц',
                  icon: 'mdi-information',
                  rights: [UserType.LAdmin],
                  subitems: [
                    {
                      title: 'По дням',
                      path: '/report/ratePlan/day/',
                      icon: 'mdi-information',
                      rights: [UserType.LAdmin]
                    },
                    {
                      title: 'По дням и льготным категориям',
                      path: '/report/ratePlan/dayrateplan/',
                      icon: 'mdi-information',
                      rights: [UserType.LAdmin]
                    },
                    {
                      title: 'Сводный нач. классы',
                      path: '/report/ratePlan/talonbyrateplan/',
                      icon: 'mdi-information',
                      rights: [UserType.LAdmin]
                    }
                  ]
                }
              ]
            },
            {
              title: 'Проверка талонов',
              path: '/report/talonerrors/',
              icon: 'mdi-information',
              rights: [UserType.LAdmin]
            },
            {
              title: 'Расчет оборота',
              path: '/report/salesturnover/',
              icon: 'mdi-information',
              rights: [UserType.RosohranaManager]
            },
            {
              title: 'Питание льготников',
              icon: 'mdi-information',
              rights: [UserType.LAdmin],
              subitems: [
                {
                  title: 'По возрастным категориям (новый)',
                  path: '/report/ratePlan/dayrateplanbyagev2/',
                  icon: 'mdi-information',
                  rights: [UserType.LAdmin]
                }
              ]
            },
            {
              title: 'Охват питания УСП',
              path: '/report/foodWarehouses/',
              icon: 'mdi-information',
              rights: [UserType.LAdmin]
            },
            {
              title: 'Табели',
              icon: 'mdi-information',
              rights: [UserType.LAdmin],
              subitems: [
                {
                  title: 'По ученикам и категориям',
                  path: '/table/bystudentandcategorypermonth/',
                  icon: 'list',
                  rights: [UserType.LAdmin]
                },
                {
                  title: 'По продукту',
                  path: '/table/byproductpermonth/',
                  icon: 'list',
                  rights: [UserType.LAdmin]
                },
                // {
                //   title: 'Сводный',
                //   path: '/table/consolidatedpermonth/',
                //   icon: 'list',
                //   rights: [UserType.LAdmin]
                // },
                {
                  title: 'По ОКУД',
                  path: '/table/okud/minor/',
                  icon: 'mdi-information',
                  rights: [UserType.LAdmin]
                }
              ]
            },
            {
              title: 'Сводный по видам компенсации',
              path: '/report/consolidatedbycompensationtype/',
              icon: 'mdi-information',
              rights: [UserType.LAdmin]
            },
            {
              title: 'По блюдам',
              icon: 'mdi-information',
              rights: [UserType.Admin90],
              subitems: [
                {
                  title: 'Продажи за период',
                  path: '/report/sales/byproducts/',
                  icon: 'mdi-information',
                  rights: [UserType.Admin90]
                },
                {
                  title: 'Свернутый',
                  path: '/report/sales/byplaces/',
                  icon: 'mdi-information',
                  rights: [UserType.Admin90]
                },
                {
                  title: 'По прейскуранту',
                  path: '/report/sales/bycustomerproducts/',
                  icon: 'mdi-information',
                  rights: [UserType.Admin90]
                }
              ]
            },
            {
              title: 'Реестр платежей',
              path: '/report/salesregister/',
              icon: 'mdi-information',
              rights: [UserType.Admin90]
            },
            {
              title: 'Реестр платежей СБП',
              path: '/banktransactionssummary',
              icon: 'mdi-information',
              rights: [UserType.Admin90]
            },
            // {
            //   title: 'Предзаказы',
            //   path: '/report/preorders/bydate/',
            //   icon: 'mdi-information',
            //   rights: [UserType.Admin90]
            // },
            {
              title: 'Предзаказы по дням',
              path: '/report/preorders/bydays',
              icon: 'mdi-information',
              rights: [UserType.Admin90, UserType.Cook]
            },
            {
              title: 'Выписка СБП',
              path: '/report/sbpincomingregister/',
              icon: 'mdi-information',
              rights: [UserType.Admin, UserType.Operator]
            },
            {
              title: 'Платежи СБП',
              path: '/report/sbporders/',
              icon: 'mdi-information',
              rights: [UserType.Admin, UserType.Operator]
            }
          ]
        },
        {
          title: 'Мониторинг',
          icon: 'mdi-information',
          subitems: [
            {
              title: 'Электронные талоны',
              path: '/report/talons/bydistricts/',
              icon: 'mdi-information',
              rights: [UserType.Admin110, UserType.Control]
            }
          ]
        },
        {
          title: 'Доска контроля',
          icon: 'mdi-information',
          subitems: [
            {
              title: 'Использование карт',
              path: '/report/placeCounters/',
              icon: 'mdi-information',
              rights: [UserType.Cobradmin]
            }
          ]
        },
        { title: 'Цены', path: '/prices/', icon: 'mdi-domain', rights: [UserType.Operator] },
        { title: 'Льготные цены', path: '/rateplanprices/', icon: 'mdi-domain', rights: [UserType.Operator] },
        { title: 'Пользователи', path: '/users/', icon: 'mdi-domain', rights: [UserType.Operator] },
        {
          title: 'Настройки',
          icon: 'mdi-cog',
          rights: [UserType.LAdmin, UserType.Admin90],
          subitems: [
            {
              title: 'Схема питания',
              path: '/schoolnutritionsettings/',
              icon: 'mdi-information',
              rights: [UserType.LAdmin]
            },
            {
              title: 'Льготные рационы',
              path: '/placerateplans/',
              icon: 'mdi-information',
              rights: [UserType.LAdmin, UserType.Admin90]
            }
          ]
        }
      ],
      showNestedMenu: null as any
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    routePartialType (): string {
      return (this.$route.meta as RouteMeta).partialType
    }
  },
  methods: {
    ...mapActions('user', { userLogout: 'logout' }),
    closeNestedMenu () {
      console.log('closeNestedMenu')
      this.showNestedMenu = !this.showNestedMenu
      setTimeout(() => this.resetNestedMenu(), 0.1)
    },
    resetNestedMenu () {
      this.showNestedMenu = null
    },
    async logout () {
      console.log('logout')
      // As you can see, with Vuex we we need to fire logout methods
      // for each of our modules.
      await this.userLogout()
      await this.$router.push({ name: 'Home' })
      // After logging the user out we can optionally reload the page, to make
      // sure everything is reset nicely.
      document.location.href = '/'
    },
    getNavigationClass (item: any) {
      let result = false
      item.subitems.forEach((element: any) => {
        if (this.$route.path === element.path) {
          result = true
        }
      })
      if (result) {
        return 'navtopbutton'
      } else {
        return ''
      }
    },
    getAccess (rights: any) {
      console.log('getAccess')
      let result = false
      rights.forEach((element: any) => {
        if (Number(element) === Number(this.user.person.userMenuType) || Number(element) === UserType.ForAll) result = true
      })
      return result
    },
    getAccessList (subitems: any) {
      let result = false
      subitems.forEach((element: any) => {
        if (this.getAccess(element.rights)) result = true
      })
      return result
    }
  }
})
</script>

<style lang="scss">
.navbar-test-css {
  background: red;
}
</style>
