<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <v-select
          v-model="selectedFoodWarehouse"
          :items="foodWarehouses"
          item-title="name"
          item-value="id"
          label="Пищеблок:"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          :disabled="isLoading"
          block
          @click="save()"
        >
          Добавить
        </v-btn>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          class="button-wrap"
          elevation="2"
          :to="{
            name: report,
            params: {
              propPlaceId: placeId
            }
          }"
        >
          НАЗАД
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-row
        v-if="!isLoading"
        class="justify-content-center"
      >
        <v-col>
          <h3 class="text-left print-header">
            Настроенные пищеблоки:
          </h3>
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && placeFoodWarehouses.length > 0"
          md="12"
        >
          <v-table class="foodwarehouse-settings-table">
            <thead>
              <tr>
                <th>
                  Пищеблок
                </th>
                <th />
                <th>
                  Оборудование в пищеблоке
                </th>
                <th>
                  Накрытие столов по классам в пищеблоке
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(placeFoodWarehouse, i) in placeFoodWarehouses" :key="i">
                <tr>
                  <td>
                    {{ placeFoodWarehouse.foodWarehouse.name }}
                  </td>
                  <td>
                    <v-btn
                      :is-loading="isLoading"
                      block
                      @click="remove(placeFoodWarehouse)"
                    >
                      <v-icon
                        size="medium"
                        color="red-darken-2"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      block
                      @click="addDevice(placeFoodWarehouse.foodWarehouse.id)"
                    >
                      <v-icon
                        size="medium"
                        color="red-darken-2"
                      >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                    <v-table>
                      <tbody>
                        <template
                          v-for="(foodWarehouseDevice, j) in placeFoodWarehouse.foodWarehouse.foodWarehouseDevices"
                          :key="j">
                          <tr>
                            <td>
                              {{ foodWarehouseDevice.device.login }}
                            </td>
                            <td>
                              {{ foodWarehouseDevice.device.lastName }}
                            </td>
                            <td>
                              {{ foodWarehouseDevice.device.description }}
                            </td>
                            <td>
                              <v-btn
                                :is-loading="isLoading"
                                block
                                @click="removeDevice(foodWarehouseDevice.id)"
                              >
                                <v-icon
                                  size="medium"
                                  color="red-darken-2"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </v-table>
                  </td>
                  <td>
                    <v-btn
                      block
                      @click="addPlace(placeFoodWarehouse.foodWarehouse.id)"
                    >
                      <v-icon
                        size="medium"
                        color="red-darken-2"
                      >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                    <v-table>
                      <tbody>
                        <template
                          v-for="(foodWarehousePlace, k) in placeFoodWarehouse.foodWarehouse.foodWarehousePlaces"
                          :key="k">
                          <tr>
                            <td>
                              {{ foodWarehousePlace.place.name }}
                            </td>
                            <td>
                              <v-btn
                                :is-loading="isLoading"
                                block
                                @click="removePlace(foodWarehousePlace.id)"
                              >
                                <v-icon
                                  size="medium"
                                  color="red-darken-2"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </v-table>
                  </td>
                </tr>
              </template>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="deviceDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="deviceDialogLoading"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-card>
      <v-card v-if="!deviceDialogLoading">
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            @click="closeDeviceDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Добавление устройства на пищеблок</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-divider />
        <br>
        <v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn
                class="justify-center"
                @click="setDevices()"
              >
                Добавить
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-data-table
                v-model="selectedDevices"
                :headers="deviceHeaders"
                :items="devices"
                item-key="id"
                show-select
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="placeDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="placeDialogLoading"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-card>
      <v-card v-if="!placeDialogLoading">
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            @click="closePlaceDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Добавление классов на пищеблок</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-divider />
        <br>
        <v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn
                class="justify-center"
                @click="setPlaces()"
              >
                Добавить
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-data-table
                v-model="selectedPlaces"
                :headers="placeHeaders"
                :items="places"
                item-key="id"
                show-select
                :items-per-page="-1"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import {
  placeFoodWarehouseCreate,
  placeFoodWarehouseRemove,
  placeFoodWarehousesFind
} from '@/shared/api/placefoodwarehouses/api-requests'
import {
  PlaceFoodWarehouseCreatePost,
  PlaceFoodWarehouseRemovePost,
  PlaceFoodWarehousesFindPost
} from '@/shared/api/placefoodwarehouses/api-types'
import { find } from '@/shared/api/customerPlace/api-requests'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { foodWarehousesFind } from '@/shared/api/foodWarehouses/api-requests'
import { FoodWarehousesFindPost } from '@/shared/api/foodWarehouses/api-types'
import { AxiosError } from 'axios'
import {
  FindFreeDevicesByPlaceFindPost,
  FoodWarehouseDevicesAddPost,
  FoodWarehouseDevicesRemovePost
} from '@/shared/api/foodWarehouseDevices/api-types'
import {
  foodWarehouseDevicesAdd,
  foodWarehouseDevicesFindFree,
  foodWarehouseDevicesRemove
} from '@/shared/api/foodWarehouseDevices/api-requests'
import {
  FoodWarehousePlaceFindFreePost,
  FoodWarehousePlaceRemovePost,
  FoodWarehousePlacesAddPost
} from '@/shared/api/foodWarehousePlaces/api-types'
import {
  foodWarehousePlaceRemove,
  foodWarehousePlacesAdd,
  foodWarehousePlacesFindFree
} from '@/shared/api/foodWarehousePlaces/api-requests'

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  settings: any;
  placeDialogLoading: boolean;
  placeDialog: boolean;
  places: any;
  selectedPlaces: any;
  selectedAddPlaceFoodWarehouse: any;
  placeHeaders: any;
  startValue: any;
  endValue: any;
  ogrn: any;
  customerPlacePost: CustomerPlaceFindPost;
  foodWarehousesFindPost: FoodWarehousesFindPost;
  foodWarehouses: any;
  selectedFoodWarehouse: any;
  placeFoodWarehouses: any;
  deviceDialogLoading: boolean;
  deviceDialog: boolean;
  devices: any;
  selectedAddDeviceFoodWarehouse: any;
  deviceHeaders: any;
  selectedDevices: any;
}

export default defineComponent({
  components: {
  },
  data (): BaseData {
    return {
      isLoading: false,
      isPost: false,
      settings: [],
      placeDialogLoading: false,
      placeDialog: false,
      places: [],
      selectedAddPlaceFoodWarehouse: null,
      placeHeaders: [
        { text: 'Название', value: 'name' }
      ],
      selectedPlaces: [],
      startValue: 1,
      endValue: 4,
      ogrn: '',
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      foodWarehousesFindPost: {
        id: null,
        typeId: null,
        egissoId: null,
        organizationOgrn: null,
        status: 1
      },
      foodWarehouses: [],
      selectedFoodWarehouse: null,
      placeFoodWarehouses: [],
      deviceDialogLoading: false,
      deviceDialog: false,
      devices: [],
      selectedAddDeviceFoodWarehouse: null,
      deviceHeaders: [
        { text: 'Логин', value: 'login' },
        { text: 'Название', value: 'firstName' },
        { text: 'Описание', value: 'description' }
      ],
      selectedDevices: []
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  props: {
    placeId: {
      type: String,
      required: true
    },
    report: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadCustomerPlaceInfo () {
      try {
        this.isLoading = true
        this.customerPlacePost.placeId = Number(this.placeId)
        this.customerPlacePost.date = moment().format('YYYY-MM-DD')
        const result = await find(this.customerPlacePost)
        const customerPlaceInfo = JSON.parse(JSON.stringify(result))
        if (customerPlaceInfo !== null) {
          this.ogrn = customerPlaceInfo.placeOgrn
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.loadFoodWarehousesByOgrn()
      }
    },
    async loadFoodWarehousesByOgrn () {
      try {
        this.isLoading = true
        this.foodWarehousesFindPost.organizationOgrn = this.ogrn
        console.log(this.foodWarehousesFindPost)
        const result = await foodWarehousesFind(this.foodWarehousesFindPost)
        this.foodWarehouses = JSON.parse(JSON.stringify(result))
        if (this.foodWarehouses.length > 0) {
          this.selectedFoodWarehouse = this.foodWarehouses[0]
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.loadPlaceFoodWarehouses()
      }
    },
    async loadPlaceFoodWarehouses () {
      try {
        this.isLoading = true
        const placeFoodWarehousesFindPost: PlaceFoodWarehousesFindPost = {
          id: null,
          placeId: Number(this.placeId),
          foodWarehouseId: null,
          status: 1,
          includePmpkLevels: 0
        }
        const result = await placeFoodWarehousesFind(placeFoodWarehousesFindPost)
        this.placeFoodWarehouses = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async save () {
      try {
        this.isLoading = true
        const placeFoodWarehouseSavePost: PlaceFoodWarehouseCreatePost = {
          placeId: Number(this.placeId),
          foodWarehouseId: this.selectedFoodWarehouse.id,
          typeId: 1,
          status: 1
        }
        const result = await placeFoodWarehouseCreate(placeFoodWarehouseSavePost)
        const settingsResponse = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async remove (placeFoodWarehouse: any) {
      try {
        this.isLoading = true
        const placeFoodWarehouseRemovePost: PlaceFoodWarehouseRemovePost = {
          id: placeFoodWarehouse.id,
          status: 0
        }
        const result = await placeFoodWarehouseRemove(placeFoodWarehouseRemovePost)
        const settingsResponse = JSON.parse(JSON.stringify(result))
        this.placeFoodWarehouses = this.removeFromArray(this.placeFoodWarehouses, placeFoodWarehouse)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    removeFromArray (array: any, value: any) {
      const idx = array.indexOf(value)
      if (idx !== -1) {
        array.splice(idx, 1)
      }
      return array
    },
    async addDevice (placeFoodWarehouseId: any) {
      try {
        this.selectedAddDeviceFoodWarehouse = placeFoodWarehouseId
        this.deviceDialog = true
        this.deviceDialogLoading = true
        const deviceByPlaceFindPost: FindFreeDevicesByPlaceFindPost = {
          placeId: Number(this.placeId)
        }
        const result = await foodWarehouseDevicesFindFree(deviceByPlaceFindPost)
        this.devices = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.deviceDialogLoading = false
      }
    },
    async setDevices () {
      try {
        this.deviceDialogLoading = true
        const devices: any = []
        this.selectedDevices.forEach((selectedDevice: any) => {
          devices.push({
            id: selectedDevice.id
          })
        })
        const foodWarehouseDevicesAddPost: FoodWarehouseDevicesAddPost = {
          foodWarehouseId: Number(this.selectedAddDeviceFoodWarehouse),
          devices: devices
        }
        const result = await foodWarehouseDevicesAdd(foodWarehouseDevicesAddPost)
        this.devices = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.closeDeviceDialog()
        this.loadPlaceFoodWarehouses()
      }
    },
    async removeDevice (itemId: number) {
      try {
        this.isLoading = true
        const foodWarehouseDevicesRemovePost: FoodWarehouseDevicesRemovePost = {
          id: itemId,
          status: 2
        }
        const result = await foodWarehouseDevicesRemove(foodWarehouseDevicesRemovePost)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.loadPlaceFoodWarehouses()
      }
    },
    closeDeviceDialog () {
      this.deviceDialogLoading = false
      this.deviceDialog = false
      this.selectedAddDeviceFoodWarehouse = null
      this.selectedDevices = []
    },
    async addPlace (placeFoodWarehouseId: any) {
      try {
        this.selectedAddPlaceFoodWarehouse = placeFoodWarehouseId
        this.placeDialog = true
        this.placeDialogLoading = true
        const placeFindByParentIdPost: FoodWarehousePlaceFindFreePost = {
          parentId: Number(this.placeId)
        }
        const result = await foodWarehousePlacesFindFree(placeFindByParentIdPost)
        this.places = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.placeDialogLoading = false
      }
    },
    async setPlaces () {
      try {
        this.placeDialogLoading = true
        const places: any = []
        this.selectedPlaces.forEach((selectedPlace: any) => {
          places.push({
            id: selectedPlace.id
          })
        })
        const foodWarehouseDevicesAddPost: FoodWarehousePlacesAddPost = {
          foodWarehouseId: Number(this.selectedAddPlaceFoodWarehouse),
          places: places
        }
        const result = await foodWarehousePlacesAdd(foodWarehouseDevicesAddPost)
        this.devices = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.closePlaceDialog()
        this.loadPlaceFoodWarehouses()
      }
    },
    async removePlace (itemId: number) {
      try {
        this.isLoading = true
        const foodWarehousePlaceRemovePost: FoodWarehousePlaceRemovePost = {
          id: itemId,
          status: 2
        }
        const result = await foodWarehousePlaceRemove(foodWarehousePlaceRemovePost)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.loadPlaceFoodWarehouses()
      }
    },
    closePlaceDialog () {
      this.placeDialogLoading = false
      this.placeDialog = false
      this.selectedAddPlaceFoodWarehouse = null
      this.selectedPlaces = []
    }
  },
  mounted () {
    this.loadCustomerPlaceInfo()
  }
})
</script>

<style lang="scss"></style>
