import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-09507a85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filters-panel" }
const _hoisted_2 = { class: "filters-panel__row" }
const _hoisted_3 = {
  key: 1,
  class: "filters-panel__filter"
}
const _hoisted_4 = {
  key: 0,
  style: {"display":"flex","position":"relative","max-width":"160px","top":"-5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.filterOptions.placeFilter)
        ? (_openBlock(), _createBlock(_component_v_select, {
            key: 0,
            label: "Школа",
            class: "filters-panel__filter",
            items: _ctx.filterOptions.placeItems,
            "item-title": "place.name",
            "item-value": "placeId",
            loading: _ctx.placeFilterLoading,
            "model-value": _ctx.filterState[_ctx.FilterNames.PLACE_FILTER],
            disabled: _ctx.loadDisabled,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.PLACE_FILTER], $event, _ctx.FilterNames.PLACE_FILTER)))
          }, null, 8, ["items", "loading", "model-value", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.filterOptions.monthFilter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_v_select, {
              label: "Месяц",
              class: "mt-0",
              items: _ctx.monthItems,
              "item-title": "name",
              "item-value": "id",
              "model-value": _ctx.filterState[_ctx.FilterNames.MONTH_FILTER],
              disabled: _ctx.loadDisabled,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.MONTH_FILTER], $event, _ctx.FilterNames.MONTH_FILTER)))
            }, null, 8, ["items", "model-value", "disabled"]),
            (_ctx.filterOptions.periodFilter)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_v_menu, {
                    ref: "menu",
                    modelValue: _ctx.dayPeriodOpen,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dayPeriodOpen) = $event)),
                    "return-value": _ctx.dayPeriod,
                    "onUpdate:returnValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dayPeriod) = $event)),
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                    "min-height": "auto"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_text_field, _mergeProps({
                        modelValue: _ctx.getPeriodText,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getPeriodText) = $event)),
                        label: "Период",
                        "prepend-icon": "mdi-calendar",
                        readonly: ""
                      }, props), null, 16, ["modelValue"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_date_picker, {
                        title: "Период",
                        modelValue: _ctx.filterState[_ctx.FilterNames.PERIOD_FILTER],
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterState[_ctx.FilterNames.PERIOD_FILTER]) = $event)),
                        multiple: "range",
                        "hide-weekdays": "",
                        "hide-header": "",
                        min: _ctx.minDayFrom,
                        max: _ctx.maxDayTo
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_btn, {
                            variant: "text",
                            color: "primary",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dayPeriodOpen = false))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Отмена ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            variant: "text",
                            color: "primary",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.PERIOD_FILTER], _ctx.filterState[_ctx.FilterNames.PERIOD_FILTER], _ctx.FilterNames.PERIOD_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" ОК ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "min", "max"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "return-value"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.filterOptions.yearFilter)
        ? (_openBlock(), _createBlock(_component_v_select, {
            key: 2,
            label: "Год",
            class: "filters-panel__filter",
            items: _ctx.yearItems,
            "item-title": "name",
            "item-value": "id",
            "model-value": _ctx.filterState[_ctx.FilterNames.YEAR_FILTER],
            disabled: _ctx.loadDisabled,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.YEAR_FILTER], $event, _ctx.FilterNames.YEAR_FILTER)))
          }, null, 8, ["items", "model-value", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.filterOptions.classFilter && _ctx.filterOptions.classItems.length > 1)
        ? (_openBlock(), _createBlock(_component_v_select, {
            key: 3,
            label: "Класс",
            class: "filters-panel__filter",
            items: _ctx.filterOptions.classItems,
            "item-title": "name",
            "item-value": "id",
            "model-value": _ctx.filterState[_ctx.FilterNames.CLASS_FILTER],
            disabled: _ctx.loadDisabled,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.CLASS_FILTER], $event, _ctx.FilterNames.CLASS_FILTER)))
          }, null, 8, ["items", "model-value", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.filterOptions.productTypeFilter && _ctx.filterOptions.productTypeItems.length > 1)
        ? (_openBlock(), _createBlock(_component_v_select, {
            key: 4,
            label: "Вид питания",
            class: "filters-panel__filter",
            items: _ctx.filterOptions.productTypeItems,
            "item-title": "name",
            "item-value": "id",
            "model-value": _ctx.filterState[_ctx.FilterNames.PRODUCT_TYPE_FILTER],
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.PRODUCT_TYPE_FILTER], $event, _ctx.FilterNames.PRODUCT_TYPE_FILTER)))
          }, null, 8, ["items", "model-value"]))
        : _createCommentVNode("", true),
      (_ctx.filterOptions.ratePlanFilter && _ctx.filterOptions.ratePlanItems.length > 1)
        ? (_openBlock(), _createBlock(_component_v_select, {
            key: 5,
            label: "Тип льготы",
            class: "filters-panel__filter",
            items: _ctx.filterOptions.ratePlanItems,
            "item-title": "name",
            "item-value": "id",
            "model-value": _ctx.filterState[_ctx.FilterNames.RATE_PLAN_FILTER],
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.changeHandler(_ctx.filterState[_ctx.FilterNames.RATE_PLAN_FILTER], $event, _ctx.FilterNames.RATE_PLAN_FILTER)))
          }, null, 8, ["items", "model-value"]))
        : _createCommentVNode("", true)
    ])
  ]))
}