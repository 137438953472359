<template>
  <div>
    <filters-panel
      :filter-options="filterOptions"
      @filter-changed="filterChangedHandler"
    />
    <string-editor-button
      :prop-string="title"
      @update-string="updateReportTitle"
    />
    <v-btn
      class="button-preorder"
      elevation="2"
      @click="print"
    >
      ПЕЧАТЬ
    </v-btn>
    <div id="printable-area">
      <h3
        :style="{textAlign: 'center', margin: '15px'}"
        v-html="title"
      />
      <base-simple-table :table-data="tableData" />
      <p>{{ `__________________/${rawApiData[0].teacherFullName}` }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import { getMockConsolidatedPerMonthTable } from '@/shared/lib/mockDataGetters'
import { defineComponent } from 'vue'
import StringEditorButton from '@/widgets/modals/StringEditorButton.vue'
import FiltersPanel, { FilterNames, FilterOptions } from '@/shared/uikit/FiltersPanel.vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'

export default defineComponent({
  name: 'ConsolidatedPerMonthTable',
  components: { BaseSimpleTable, StringEditorButton, FiltersPanel },
  data () {
    return {
      filterOptions: {
        placeFilter: true,
        classFilter: true,
        productTypeFilter: true,
        ratePlanFilter: true,
        monthFilter: true,
        yearFilter: true,
        classItems: [],
        productTypeItems: [],
        ratePlanItems: [],
        placeItems: []
      } as FilterOptions,
      filterState: {
        placeFilter: 0,
        classFilter: 0,
        productTypeFilter: 0,
        ratePlanFilter: 0,
        monthFilter: moment().month(),
        yearFilter: moment().year(),
        initialState: false
      } as any,
      title: 'Сводный табель за месяц',
      consolidatedReport: {
        columnsCount: 16,
        rowsCount: 5,
        items: [
          {
            text: '№ п/п',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 1,
            gridColumnEnd: 2
          },
          {
            text: 'Фио',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 2,
            gridColumnEnd: 3
          },
          {
            text: 'Класс',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 3,
            gridColumnEnd: 4
          },
          {
            text: 'Категория',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 4,
            gridColumnEnd: 5
          },
          {
            text: 'Вид питания',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 5,
            gridColumnEnd: 6
          },
          {
            text: 'Дата',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 6,
            gridColumnEnd: 16
          },
          {
            text: '1',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 6,
            gridColumnEnd: 7
          },
          {
            text: '2',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 7,
            gridColumnEnd: 8
          },
          {
            text: '3',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 8,
            gridColumnEnd: 9
          },
          {
            text: '4',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 9,
            gridColumnEnd: 10
          },
          {
            text: '5',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 10,
            gridColumnEnd: 11
          },
          {
            text: '6',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 11,
            gridColumnEnd: 12
          },
          {
            text: '7',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 12,
            gridColumnEnd: 13
          },
          {
            text: '8',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 13,
            gridColumnEnd: 14
          },
          {
            text: '9',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 14,
            gridColumnEnd: 15
          },
          {
            text: '10',
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 15,
            gridColumnEnd: 16
          },
          {
            text: 'Всего пропусков',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 16,
            gridColumnEnd: 17
          },
          {
            text: '1',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 1,
            gridColumnEnd: 2
          },
          {
            text: '2',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 1,
            gridColumnEnd: 2
          },
          {
            text: '3',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 1,
            gridColumnEnd: 2
          },
          {
            text: 'Борисов Борис',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 2,
            gridColumnEnd: 3
          },
          {
            text: 'Васильев Василий',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 2,
            gridColumnEnd: 3
          },
          {
            text: 'Иванов Иван',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 2,
            gridColumnEnd: 3
          },
          {
            text: '10а',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 3,
            gridColumnEnd: 4
          },
          {
            text: '10а',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 3,
            gridColumnEnd: 4
          },
          {
            text: '10а',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 3,
            gridColumnEnd: 4
          },
          {
            text: '4',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 4,
            gridColumnEnd: 5
          },
          {
            text: '4',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 4,
            gridColumnEnd: 5
          },
          {
            text: '4',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 4,
            gridColumnEnd: 5
          },
          {
            text: 'Обед ст',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 5,
            gridColumnEnd: 6
          },
          {
            text: 'Обед ст',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 5,
            gridColumnEnd: 6
          },
          {
            text: 'Обед ст',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 5,
            gridColumnEnd: 6
          },
          {
            text: 'Н',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 6,
            gridColumnEnd: 7
          },
          {
            text: 'Н',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 6,
            gridColumnEnd: 7
          },
          {
            text: 'Н',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 6,
            gridColumnEnd: 7
          },
          {
            text: 'В',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 7,
            gridColumnEnd: 8
          },
          {
            text: 'В',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 7,
            gridColumnEnd: 8
          },
          {
            text: 'В',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 7,
            gridColumnEnd: 8
          },
          {
            text: 'В',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 8,
            gridColumnEnd: 9
          },
          {
            text: 'В',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 8,
            gridColumnEnd: 9
          },
          {
            text: 'В',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 8,
            gridColumnEnd: 9
          },
          {
            text: 'Н',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 9,
            gridColumnEnd: 10
          },
          {
            text: 'Н',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 9,
            gridColumnEnd: 10
          },
          {
            text: 'Н',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 9,
            gridColumnEnd: 10
          },
          {
            text: ' ',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 10,
            gridColumnEnd: 11
          },
          {
            text: ' ',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 10,
            gridColumnEnd: 11
          },
          {
            text: ' ',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 10,
            gridColumnEnd: 11
          },
          {
            text: ' ',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 11,
            gridColumnEnd: 12
          },
          {
            text: ' ',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 11,
            gridColumnEnd: 12
          },
          {
            text: ' ',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 11,
            gridColumnEnd: 12
          },
          {
            text: ' ',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 12,
            gridColumnEnd: 13
          },
          {
            text: ' ',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 12,
            gridColumnEnd: 13
          },
          {
            text: ' ',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 12,
            gridColumnEnd: 13
          },
          {
            text: ' ',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 13,
            gridColumnEnd: 14
          },
          {
            text: ' ',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 13,
            gridColumnEnd: 14
          },
          {
            text: ' ',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 13,
            gridColumnEnd: 14
          },
          {
            text: 'Н',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 14,
            gridColumnEnd: 15
          },
          {
            text: ' ',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 14,
            gridColumnEnd: 15
          },
          {
            text: 'Н',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 14,
            gridColumnEnd: 15
          },
          {
            text: ' ',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 15,
            gridColumnEnd: 16
          },
          {
            text: 'Н',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 15,
            gridColumnEnd: 16
          },
          {
            text: ' ',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 15,
            gridColumnEnd: 16
          },
          {
            text: '5',
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 16,
            gridColumnEnd: 17
          },
          {
            text: '5',
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 16,
            gridColumnEnd: 17
          },
          {
            text: '5',
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 16,
            gridColumnEnd: 17
          }
        ]
      } as TableData,
      tableData: {} as TableData,
      placeInfo: {} as any,
      isLoading: false,
      renderFilterUpdated: false, // флаг, отвечающий за перерисовку таблицы при изменении фильтров, не требующих подгрузки данных с API (классы, льготные категории и т.д.)
      apiFilterUpdated: true, // флаг, отвечающий за перерисовку таблицы при изменении фильтров, требующих подгрузки данных с API (школа, дата и т.п.)
      rawApiData: [] as any
    }
  },
  async beforeMount () {
    const mockApiData = getMockConsolidatedPerMonthTable()
    this.rawApiData = mockApiData
    this.filterChangedHandler({
      selectedClass: 0,
      selectedProductType: 0,
      selectedRatePlan: 0,
      selectedMonth: 0,
      selectedYear: 0
    })
    this.isLoading = true
    const lastDayOfSelectedMonth = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
    const userAllowedPlaces = await this.userAllowedPlaceAll()
    this.filterOptions.placeItems = userAllowedPlaces
    this.filterState[FilterNames.PLACE_FILTER] = userAllowedPlaces[0].placeId
    this.placeInfo = await this.loadCustomerPlaceInfo(this.filterState[FilterNames.PLACE_FILTER], lastDayOfSelectedMonth)
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    filterChangedHandler (options: any) {
      const classObj = this.filterOptions.classItems.find((el: any) => el.id === options.selectedClass) ?? {
        id: 0,
        name: 'Все'
      }
      const filteredByClass = options.selectedClass === 0 ? this.rawApiData : this.rawApiData.filter((el: any) => el.className === classObj.name)
      const filteredByRatePlan = options.selectedRatePlan === 0 ? filteredByClass : filteredByClass.filter((el: any) => el.studentRatePlan === options.selectedRatePlan)
      const filterByProductType = options.selectedProductType === 0 ? filteredByRatePlan : filteredByRatePlan.filter((el: any) => el.productType === options.selectedProductType)
      const convertedData = this.convertToTableData(filterByProductType)
      this.tableData = convertedData
    },
    convertToTableData (mockData: any) {
      // Перевод сырых табличных данных, полученных с API, в формат, который понимает GRID-таблиица
      console.log('Конвертация')
      if (mockData.length > 0) {
        const res = {
          columnsCount: mockData[0].absenceMarks.length + 6,
          rowsCount: mockData.length + 2,
          items: []
        } as TableData
        res.items.push(
          {
            text: '№ п.п.',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 1,
            gridColumnEnd: 2
          },
          {
            text: 'ФИО',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 2,
            gridColumnEnd: 3
          },
          {
            text: 'Класс',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 3,
            gridColumnEnd: 4
          },
          {
            text: 'Категория льготы',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 4,
            gridColumnEnd: 5
          },
          {
            text: 'Вид питания',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 5,
            gridColumnEnd: 6
          },
          {
            text: 'Дата',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 6,
            gridColumnEnd: mockData[0].absenceMarks.length + 6
          },
          {
            text: 'Всего получено льгот',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: mockData[0].absenceMarks.length + 6,
            gridColumnEnd: mockData[0].absenceMarks.length + 7
          }
        )
        mockData.forEach((row: any, rowIdx: number) => {
          let totalCount = 0
          res.items.push(
            {
              text: `${rowIdx + 1}`,
              gridRowStart: rowIdx + 3,
              gridRowEnd: rowIdx + 4,
              gridColumnStart: 1,
              gridColumnEnd: 2
            },
            {
              text: `${row.studentFullName}`,
              gridRowStart: rowIdx + 3,
              gridRowEnd: rowIdx + 4,
              gridColumnStart: 2,
              gridColumnEnd: 3
            },
            {
              text: `${row.className}`,
              gridRowStart: rowIdx + 3,
              gridRowEnd: rowIdx + 4,
              gridColumnStart: 3,
              gridColumnEnd: 4
            },
            {
              text: `${row.studentRatePlan}`,
              gridRowStart: rowIdx + 3,
              gridRowEnd: rowIdx + 4,
              gridColumnStart: 4,
              gridColumnEnd: 5
            },
            {
              text: `${row.productType}`,
              gridRowStart: rowIdx + 3,
              gridRowEnd: rowIdx + 4,
              gridColumnStart: 5,
              gridColumnEnd: 6
            }
          )
          row.absenceMarks.forEach((absenceMark: any, absenceMarkIdx: number) => {
            if (rowIdx === 0) {
              res.items.push({
                text: absenceMark.day,
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: absenceMarkIdx + 6,
                gridColumnEnd: absenceMarkIdx + 7
              })
            }
            if (absenceMark.mark !== ' ') totalCount += 1
            res.items.push({
              text: absenceMark.mark,
              gridRowStart: rowIdx + 3,
              gridRowEnd: rowIdx + 4,
              gridColumnStart: absenceMarkIdx + 6,
              gridColumnEnd: absenceMarkIdx + 7
            })
          })
          res.items.push({
            text: `${totalCount}`,
            gridRowStart: rowIdx + 3,
            gridRowEnd: rowIdx + 4,
            gridColumnStart: row.absenceMarks.length + 6,
            gridColumnEnd: row.absenceMarks.length + 7
          })
        })
        return res
      } else {
        console.log('Невозможно конвертировать пустые данные!')
        return {} as TableData
      }
    },
    updateReportTitle (newTitle: string) {
      this.title = newTitle
    },
    print () {
      const printContent = document.getElementById('printable-area')
      const WinPrint = window.open('')
      if (WinPrint && printContent) {
        WinPrint.document.write(`
          <html>
            <head>
              <title>Печать</title>
            </head>
            <body>
            <div class="bodyprint">
            <input class="print-button" type="button" value="Печать" onclick="print()">
              ${printContent.innerHTML}
            </div>
            </body>
          </html>
        `)
        const link = WinPrint.document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        link.setAttribute('href', '/css/print.css')
        WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
        WinPrint.focus()
      }
    },
    async loadCustomerPlaceInfo (placeId: any, date: any) {
      try {
        if (placeId !== 0) {
          const result = await find(
            {
              placeId: placeId,
              date: date
            }
          )
          return result
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    }
  }
})
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  padding: 0 5px;

  & div {
    margin: 0 5px;
  }
}
</style>
