<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <place-selector
          v-model="selectedUserAllowedPlace"
          :selected-item-props="propPlaceId"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <month-selector
          v-model="selectedMonth"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <year-selector
          v-model="selectedYear"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <class-selector
          v-model="parallels"
          :place-id-props="selectedUserAllowedPlace.id"
          @parallel-select="createReport"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="goToSettings()"
        >
          Настроить
        </v-btn>
      </v-col>
      <v-col
        v-if="parallels.parallels.length > 0"
        class="d-flex"
      >
        <v-btn
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <v-col
        v-else
        class="d-flex"
      >
        Необходимо настроить параллели
      </v-col>
      <v-col
        v-if="!isLoading && isPost"
        class="d-flex"
        cols="1"
        sm="1"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <div
        v-if="resultProducts.length > 0 && resultOrder.length > 0"
        id="printableArea"
      >
        <v-row class="justify-content-center">
          <v-col class="document-header">
            <v-col class="">
              <div class="document-header__organization">
                <p>СОГЛАСОВАНО</p>
                <!--              {{  selectedUserAllowedPlace }}-->
                <p>Директор {{ customerPlaceInfo.fullName }}</p>
                <p>_______________ /{{ customerPlaceInfo.directorName }} /</p>
                <p class="after-sign">
                  (подпись, расшифровка подписи)
                </p>
              </div>
            </v-col>
            <v-col>
              <div class="document-header__title">
                <h3 class="text-center print-header">
                  ОТЧЕТ<br>
                </h3>
                <p class="text-center  print-header">
                  <b>о предоставлении питания учащимся Санкт-Петербурга</b> <br>
                  итого за {{ selectedMonthName.toLowerCase() }} месяц {{ selectedYearName }} года
                </p>
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="justify-content-center">
          <v-col
            v-if="!isLoading"
          >
            <v-table class="report-rateplan-day">
              <template #default>
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      colspan="2"
                    >
                      По категориям учащихся
                    </th>
                    <template
                      v-for="(product, idx) in resultProducts"
                      :key="`product-name-${idx}`"
                    >
                      <th
                        class="table-head__title"
                      >
                        {{ `Количество ${ product.name }ов` }}
                      </th>
                      <th>
                        {{ `Количество ${ product.name }ов/день` }}
                      </th>
                      <th>
                        Cумма, руб
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="orderItem in resultOrder"
                    :key="`rate-plan-${orderItem.ratePlanId}`"
                  >
                    <tr
                      :class="`rate-plan-${orderItem.ratePlanId}`"
                    >
                      <td :rowspan="orderItem.ratePlanItems.length+2">
                        {{ orderItem.ratePlanName }}
                      </td>
                    </tr>
                    <template
                      v-for="(classType, rowIdx) in orderItem.ratePlanItems"
                      :key="`${orderItem.ratePlanId}-${classType.classTypeId}`"
                    >
                      <tr
                        :class="`${orderItem.ratePlanId}-${classType.classTypeId}`"
                      >
                        <td>{{ `${ classType.classTypeName } классы` }}</td>
                        <template
                          v-for="(product, columnIdx) in resultProducts"
                          :key="`${product.name}-count-${columnIdx}`"
                        >
                          <td
                            :class="`${product.name}-count-${columnIdx}`"
                          >
                            {{ getCount(classType, product, rowIdx, columnIdx) }}
                          </td>
                          <td
                            :class="`${product.name}-avg-${columnIdx}`"
                          >
                            {{ getAvgCount(classType, product, rowIdx, columnIdx) }}
                          </td>
                          <td
                            :class="`${product.name}-price-${columnIdx}`"
                          >
                            {{ getPrice(classType, product, rowIdx, columnIdx) }}
                          </td>
                        </template>
                      </tr>
                    </template>
                    <tr
                      :class="`${orderItem.ratePlanId}-total`"
                    >
                      <td>Всего</td>
                      <template
                        v-for="(product, idx) in resultProducts"
                        :key="`all-${product.name}-count-${idx}`"
                      >
                        <td
                          :class="`total-${product.name}-count-${idx}`"
                        >
                          {{ getTotalCount(orderItem, product) }}
                        </td>
                        <td
                          :class="`total-${product.name}-avg-${idx}`"
                        >
                          {{ getTotalAvgCount(orderItem, product) }}
                        </td>
                        <td
                          :class="`total-${product.name}-price-${idx}`"
                        >
                          {{ getTotalPrice(orderItem, product) }}
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <template
                    v-for="parallel in parallels.parallels"
                    :key="parallel.id"
                  >
                    <tr>
                      <th colspan="2">
                        {{ `${ parallel.name } классы` }}
                      </th>
                      <template
                        v-for="(product, columnIdx) in resultProducts"
                        :key="`${product.name}-count-${columnIdx}`"
                      >
                        <td>
                          {{ getTotalParallelCount(parallel, product, columnIdx) }}
                        </td>
                        <td>
                          {{ getTotalParallelAvgCount(parallel, product, columnIdx) }}
                        </td>
                        <td>
                          {{ getTotalParallelPrice(parallel, product, columnIdx) }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr>
                    <th colspan="2">
                      ИТОГО
                    </th>
                    <template
                      v-for="(product, columnIdx) in resultProducts"
                      :key="`${product.name}-count-${columnIdx}`"
                    >
                      <td>
                        {{ getTotalTableCount(product, columnIdx) }}
                      </td>
                      <td>
                        {{ getTotalTableAvgCount(product, columnIdx) }}
                      </td>
                      <td>
                        {{ getTotalTablePrice(product, columnIdx) }}
                      </td>
                    </template>
                  </tr>
                </tfoot>
              </template>
            </v-table>
          </v-col>
        </v-row>
        <template v-if="!isLoading">
          <v-row class="document-footer">
            <v-col>
              <div class="document-footer__summary">
                <p>Всего получено питания на сумму</p>
                <p class="document-footer__price">
                  {{ getSummaryPrice() }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!isLoading">
            <v-col class="print-sign">
              Организатор питания _____________________________/{{ customerPlaceInfo.ladminName }}/
            </v-col>
          </v-row>
          <v-row v-if="!isLoading">
            <v-col class="print-sign">
              Заведующий производством _____________________________/{{ customerPlaceInfo.zavprName }}/
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
    <template v-else>
      <v-row>
        <v-col
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import PlaceSelector from '@/shared/uikit/selectors/PlaceSelector.vue'
import MonthSelector from '@/shared/uikit/selectors/MonthSelector.vue'
import YearSelector from '@/shared/uikit/selectors/YearSelector.vue'
import ClassSelector from '@/shared/uikit/selectors/ClassSelector.vue'
import { mapActions, mapGetters } from 'vuex'
import { PlaceElectronTalonFindWithItemsAndRatePlansPost } from '@/shared/api/placeElectronTalon/api-types'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { findWithItemsAndRatePlans } from '@/shared/api/placeElectronTalon/api-requests'
import { find } from '@/shared/api/customerPlace/api-requests'
import axios, { AxiosError, AxiosResponse } from 'axios'

type BaseData = {
  // TODO: убрать лишние поля, проработать типизацию
  // TODO: почистить компонент от лишних методов
  selectedUserAllowedPlaceId: number;
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlace: any;
  selectedMonth: any;
  selectedMonthName: string;
  selectedYearName: string;
  selectedYear: number;
  parallels: any;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsAndRatePlansPost;
  customerPlacePost: CustomerPlaceFindPost;
  customerPlaceInfo: any;
  placeElectronTalons: any;
  places: any;
  products: any;
  ratePlans: any;
  notAcceptDates: any;
  errorDates: any;
  header: any;
  resultOrder: any;
  resultProducts: any;
  workingDays: any;
}

export default defineComponent({
  components: {
    MonthSelector,
    YearSelector,
    PlaceSelector,
    ClassSelector
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  data (): BaseData {
    return {
      selectedUserAllowedPlaceId: 0,
      isLoading: false,
      isPost: false,
      selectedUserAllowedPlace: [],
      selectedMonth: { id: 1, name: 'Неизвестно' },
      selectedMonthName: 'Неизвестно',
      selectedYearName: 'Неизвестно',
      selectedYear: 2021,
      parallels: {
        parallels: [], selectedParallel: 0
      },
      placeElectronTalonGetByIdPost: {
        parentPlaceId: 0,
        dateFrom: '',
        dateTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      customerPlaceInfo: null,
      placeElectronTalons: [],
      places: [],
      errorDates: [],
      notAcceptDates: [],
      products: [],
      ratePlans: [],
      header: [],
      resultOrder: [],
      resultProducts: [],
      workingDays: []
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    loadDataFromFile: async (name: string): Promise<any> => {
      const result: AxiosResponse<{
        data: any;
      }> = await axios.get(`/${name}`)
      return result.data.data
    },
    async loadPlaceElectronTalons () {
      try {
        // this.isLoading = true
        if (this.selectedUserAllowedPlace !== 0) {
          // console.log(this.selectedUserAllowedPlace)

          this.placeElectronTalonGetByIdPost.parentPlaceId = this.selectedUserAllowedPlace.id
          this.placeElectronTalonGetByIdPost.dateFrom = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('YYYY-MM-DD')
          this.placeElectronTalonGetByIdPost.dateTo = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).format('YYYY-MM-DD')

          // Подгрузка даных
          // const result = await this.loadDataFromFile('example1.json') // с локального файла с папки public
          const result = await findWithItemsAndRatePlans(this.placeElectronTalonGetByIdPost) // с API
          const placeElectronTalonsAnswer = JSON.parse(JSON.stringify(result))
          // Заполнение модели данными с API
          // (  placeElectronTalons - сами талоны,
          //    products - рационы,
          //    ratePlans - льготные категории,
          //    places - классы

          if (Array.isArray(placeElectronTalonsAnswer.placeEletronTalons)) {
            console.log('Проверка 1')
            this.placeElectronTalons = placeElectronTalonsAnswer.placeEletronTalons
          }
          if (Array.isArray(placeElectronTalonsAnswer.products)) {
            console.log('Проверка 2')
            this.products = placeElectronTalonsAnswer.products
          }
          if (Array.isArray(placeElectronTalonsAnswer.places)) {
            console.log('Проверка 3')
            this.places = placeElectronTalonsAnswer.places
          }
          if (Array.isArray(placeElectronTalonsAnswer.ratePlans)) {
            console.log('Проверка 4')
            this.ratePlans = placeElectronTalonsAnswer.ratePlans
          }
          if (this.places.length > 0 && this.products.length > 0 && this.ratePlans.length > 0 && this.placeElectronTalons.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        console.log(error)
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        // this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.selectedUserAllowedPlace !== 0) {
          this.customerPlacePost.placeId = this.selectedUserAllowedPlace.id
          this.customerPlacePost.date = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).format('YYYY-MM-DD')

          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    createReport () {
      this.clearData()
      // Сопоставляем, в каждый класс из базы, диапазон (classType), который указан в настройках построения таблицы
      this.places.forEach((element: any) => {
        const parallel = this.parallels.parallels.find((parallel: any) => {
          if (element.name.match(/^\d+/)) {
            if (parseInt(element.name) >= parallel.startValue && parseInt(element.name) <= parallel.endValue) {
              return true
            }
          }
        })
        element.classType = parallel.classType
      })

      // console.log(this.parallels.selectedParallel)
      const workingDays: any[] = []
      const resultOrder: any = []
      const resultProducts: any = []
      try {
        this.products.forEach((product: any) => {
          const name = product.name.split(' ')[0].toLowerCase()
          const mealTypeIndex = resultProducts.findIndex((resProd: any) => resProd.name === name)
          if (mealTypeIndex !== -1) {
            const productItemIndex = resultProducts[mealTypeIndex].productItems.findIndex((productItem: any) => productItem.id === product.id)
            if (productItemIndex === -1) {
              resultProducts[mealTypeIndex].productItems.push({
                id: product.id,
                content: { product }
              })
            }
          } else {
            resultProducts.push({
              name: name,
              productItems: [{
                id: product.id,
                content: product
              }]
            })
          }
        })
        this.resultProducts = resultProducts
      } catch (e) {
        console.log(e)
      }
      this.placeElectronTalons.forEach((placeElectronTalon: any) => {
        // Заполняем массив рабочих дней
        if (!workingDays.includes(placeElectronTalon.date)) {
          workingDays.push(placeElectronTalon.date)
        }
        // Заполняем объект resultOrder в нужном нам формате для отрисовки таблицы
        placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
          if (!placeElectronTalonItem.removed) {
            placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
              const ratePlanId = placeElectronTalonItem.userRatePlan
              const placeId = placeElectronTalon.placeId
              const productId = placeElectronTalonItemSale.productId
              const classType = this.places.find((place: any) => place.id === placeId).classType
              const price = placeElectronTalon.placeElectronTalonProducts.find((talonProduct: any) => talonProduct.productId === productId).price
              if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId != null) {
                try {
                  const ratePlanIndex = resultOrder.findIndex((order: any) => order.ratePlanId === ratePlanId)
                  if (ratePlanIndex !== -1) {
                    const classTypeIndex = resultOrder[ratePlanIndex].ratePlanItems.findIndex((el: any) => el.classTypeId === classType)
                    if (classTypeIndex !== -1) {
                      const productIndex = resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems.findIndex((product: any) => product.productId === productId)
                      if (productIndex !== -1) {
                        resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems[productIndex].count += 1
                      } else {
                        resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems.push({
                          productId: productId,
                          count: 1,
                          productItem: this.products.find((product: any) => product.id === productId),
                          price: price
                        })
                      }
                    } else {
                      resultOrder[ratePlanIndex].ratePlanItems.push({
                        classTypeId: classType,
                        classTypeName: this.parallels.parallels.find((parallel: any) => parallel.classType === classType).name,
                        classTypeItems: [
                          {
                            productId: productId,
                            count: 1,
                            productItem: this.products.find((product: any) => product.id === productId),
                            price: price
                          }
                        ]
                      })
                    }
                  } else {
                    resultOrder.push({
                      ratePlanId: ratePlanId,
                      ratePlanName: this.ratePlans.find((ratePlan: any) => ratePlan.id === ratePlanId).name,
                      ratePlanItems: [{
                        classTypeId: classType,
                        classTypeName: this.parallels.parallels.find((parallel: any) => parallel.classType === classType).name,
                        classTypeItems: [
                          {
                            productId: productId,
                            count: 1,
                            productItem: this.products.find((product: any) => product.id === productId),
                            price: price
                          }
                        ]
                      }]
                    })
                  }
                } catch (e) {
                  console.log(e)
                }
              } else {
                // TODO что делать, когда removed == null?
                if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                  if (!this.notAcceptDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notAcceptDates.push(moment(placeElectronTalon.date).format('DD'))
                }
              }
            })
          }
          if (placeElectronTalonItem.error != null) {
            if (String(placeElectronTalonItem.error) !== '15') {
              if (!this.errorDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.errorDates.push(moment(placeElectronTalon.date).format('DD'))
            }
          }
        })
      })
      this.resultOrder = resultOrder
      this.workingDays = workingDays
    },
    getCount (classType: any, product: any, rowIdx: number, columnIdx: number) {
      if (classType.classTypeItems.length <= 1) {
        const flag = product.productItems.find((productItem: any) => productItem.id === classType.classTypeItems[0].productId)
        return flag ? classType.classTypeItems[0].count : 0
      } else {
        // Сопоставляем запись в classType и productItems, чтобы вывести значения в нужно порядке в таблице
        return classType.classTypeItems.find((classTypeItem: any) => product.productItems.find((productItem: any) => productItem.id === classTypeItem.productId))?.count
      }
    },
    getPrice (classType: any, product: any, rowIdx: number, columnIdx: number) {
      if (classType.classTypeItems.length <= 1) {
        const flag = product.productItems.find((productItem: any) => productItem.id === classType.classTypeItems[0].productId)
        const res = classType.classTypeItems[0]?.count * classType.classTypeItems[0]?.price
        return flag ? res.toFixed(2) : 0
      } else {
        // Сопоставляем запись в classType и productItems, чтобы вывести значения в нужно порядке в таблице
        const item = classType.classTypeItems.find((classTypeItem: any) => product.productItems.find((productItem: any) => productItem.id === classTypeItem.productId))
        const res = item?.count * item?.price
        return res.toFixed(2)
      }
    },
    getAvgCount (classType: any, product: any, rowIdx: number, columnIdx: number) {
      if (classType.classTypeItems.length <= 1) {
        const flag = product.productItems.find((productItem: any) => productItem.id === classType.classTypeItems[0].productId)
        const res = classType.classTypeItems[0]?.count / this.workingDays.length
        return flag ? res.toFixed(2) : 0
      } else {
        // Сопоставляем запись в classType и productItems, чтобы вывести значения в нужно порядке в таблице
        const item = classType.classTypeItems.find((classTypeItem: any) => product.productItems.find((productItem: any) => productItem.id === classTypeItem.productId))
        const res = item?.count / this.workingDays.length
        return res.toFixed(2)
      }
    },
    getTotalCount (orderItem: any, product: any) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) => ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
        // console.log(classTypeItem.productId, product)
        product.productItems.forEach((productItem: any) => {
          if (productItem.id === classTypeItem.productId) {
            res += classTypeItem.count
          }
        })
      }))
      return res === 0 ? 0 : res
    },
    getTotalPrice (orderItem: any, product: any) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) => ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
        product.productItems.forEach((productItem: any) => {
          if (productItem.id === classTypeItem.productId) {
            res += classTypeItem.count * classTypeItem.price
          }
        })
      }))
      return res === 0 ? 0 : res.toFixed(2)
    },
    getTotalAvgCount (orderItem: any, product: any) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) => ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
        product.productItems.forEach((productItem: any) => {
          if (productItem.id === classTypeItem.productId) {
            res += classTypeItem.count
          }
        })
      }))
      return res === 0 ? 0 : (res / this.workingDays.length).toFixed(2)
    },
    getTotalParallelCount (parallel: any, product: any, columnIndex: any) {
      let res = 0
      const items = this.resultOrder.map((orderItem: any) => orderItem.ratePlanItems.find((ratePlanItem: any) => ratePlanItem.classTypeName === parallel.name)).filter((item: any) => item)
      items.forEach((item: any) => item.classTypeItems.forEach((classTypeItem: any) => {
        product.productItems.forEach((productItem: any) => {
          if (classTypeItem.productId === productItem.id) {
            res += classTypeItem.count
          }
        })
      }))
      return res
    },
    getTotalParallelAvgCount (parallel: any, product: any, columnIndex: any) {
      let res = 0
      const items = this.resultOrder.map((orderItem: any) => orderItem.ratePlanItems.find((ratePlanItem: any) => ratePlanItem.classTypeName === parallel.name)).filter((item: any) => item)
      items.forEach((item: any) => item.classTypeItems.forEach((classTypeItem: any) => {
        product.productItems.forEach((productItem: any) => {
          if (classTypeItem.productId === productItem.id) {
            res += classTypeItem.count
          }
        })
      }))
      return (res / this.workingDays.length).toFixed(2)
    },
    getTotalParallelPrice (parallel: any, product: any, columnIndex: any) {
      let res = 0
      const items = this.resultOrder.map((orderItem: any) => orderItem.ratePlanItems.find((ratePlanItem: any) => ratePlanItem.classTypeName === parallel.name)).filter((item: any) => item)
      items.forEach((item: any) => item.classTypeItems.forEach((classTypeItem: any) => {
        product.productItems.forEach((productItem: any) => {
          if (classTypeItem.productId === productItem.id) {
            res += classTypeItem.count * classTypeItem.price
          }
        })
      }))
      return res.toFixed(2)
    },
    getTotalTableCount (product: any, columnIndex: any) {
      let res = 0
      this.resultOrder.forEach((item: any) => {
        item.ratePlanItems.forEach((ratePlanItem: any) => {
          ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
            product.productItems.forEach((productItem: any) => {
              if (classTypeItem.productId === productItem.id) {
                res += classTypeItem.count
              }
            })
          })
        })
      })
      return res
    },
    getTotalTableAvgCount (product: any, columnIndex: any) {
      let res = 0
      this.resultOrder.forEach((item: any) => {
        item.ratePlanItems.forEach((ratePlanItem: any) => {
          ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
            product.productItems.forEach((productItem: any) => {
              if (classTypeItem.productId === productItem.id) {
                res += classTypeItem.count
              }
            })
          })
        })
      })
      return (res / this.workingDays.length).toFixed(2)
    },
    getTotalTablePrice (product: any, columnIndex: any) {
      let res = 0
      this.resultOrder.forEach((item: any) => {
        item.ratePlanItems.forEach((ratePlanItem: any) => {
          ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
            product.productItems.forEach((productItem: any) => {
              if (classTypeItem.productId === productItem.id) {
                res += classTypeItem.count * classTypeItem.price
              }
            })
          })
        })
      })
      return res.toFixed(2)
    },
    getSummaryPrice () {
      let res = 0
      this.resultOrder.forEach((item: any) => {
        item.ratePlanItems.forEach((ratePlanItem: any) => {
          ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
            res += classTypeItem.count * classTypeItem.price
          })
        })
      })
      return res.toFixed(2)
    },
    goToSettings () {
      this.$router.push({
        name: 'ReportSettings',
        params: {
          placeId: this.selectedUserAllowedPlace.id,
          report: 'ReportRatePlanDayRatePlan'
        }
      })
    },
    clearData () {
      this.header = [] // никак не используется
      this.resultOrder = []
      this.resultProducts = []
      this.workingDays = []
    },
    async load () {
      this.isLoading = true
      this.placeElectronTalons = []
      this.places = []
      this.products = []
      this.ratePlans = []
      this.errorDates = []
      this.notAcceptDates = []
      this.clearData()
      this.customerPlaceInfo = null
      await this.loadCustomerPlaceInfo()
      await this.loadPlaceElectronTalons()
      this.isLoading = false
      this.isPost = true
      this.selectedMonthName = this.selectedMonth.name
      this.selectedYearName = this.selectedYear.toString()
    },
    print () {
      const printContent = document.getElementById('printableArea')
      const WinPrint = window.open('')
      if (WinPrint && printContent) {
        WinPrint.document.write(`
          <html>
            <head>
              <title></title>
            </head>
            <body>
            <div class="">
            <input class="print-button" type="button" value="Печать" onclick="print()">
              ${printContent.innerHTML}
            </div>
            </body>
          </html>
        `)
        const link = WinPrint.document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        link.setAttribute('href', '/css/print.css')
        WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
        WinPrint.focus()
      }
      // await (this as any).$htmlToPaper('printableArea')
    }
  }
})
</script>

<style lang="scss">
.table-head__title {
  //text-transform: capitalize;
}

.document-header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {

  }

  .document-header__organization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    width: 450px;
    text-align: center;
    margin-bottom: 24px;

    p {
      font-size: 12px;
      margin: 4px;
    }
  }
}

.document-footer {
  font-weight: bold;

  .document-footer__summary {
    display: flex;
    justify-content: space-between;

    .document-footer__price {
      border: 2px solid black;
      padding: 0 60px;
    }
  }
}

.after-sign {
  font-size: 10px;
}

</style>
