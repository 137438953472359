<template>
  <v-container>
    <h1 class="text-center">
      Отчет находиться в разработке
    </h1>
    <!--    <v-row class="justify-content-center">-->
    <!--      <v-col-->
    <!--        class="d-flex"-->
    <!--        cols="3"-->
    <!--        sm="3"-->
    <!--      >-->
    <!--        <place-selector-->
    <!--          v-model='selectedUserAllowedPlace'-->
    <!--          :selectedItemProps="propPlaceId">-->
    <!--        </place-selector>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        class="d-flex"-->
    <!--        cols="2"-->
    <!--        sm="2"-->
    <!--      >-->
    <!--        <month-selector-->
    <!--          v-model='selectedMonth'-->
    <!--        ></month-selector>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        class="d-flex"-->
    <!--        cols="2"-->
    <!--        sm="2"-->
    <!--      >-->
    <!--        <year-selector-->
    <!--          v-model='selectedYear'-->
    <!--        ></year-selector>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        class="d-flex"-->
    <!--      >-->
    <!--        <v-btn-->
    <!--          :isLoading="isLoading"-->
    <!--          :disabled="isLoading || selectedUserAllowedPlace === 0"-->
    <!--          block-->
    <!--          @click="goToSettings()"-->
    <!--          >-->
    <!--          Настроить-->
    <!--        </v-btn>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        class="d-flex"-->
    <!--      >-->
    <!--      <v-btn-->
    <!--        :isLoading="isLoading"-->
    <!--        :disabled="isLoading || selectedUserAllowedPlace === 0"-->
    <!--        block-->
    <!--        @click="load()"-->
    <!--        >-->
    <!--        Показать-->
    <!--      </v-btn>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row class="justify-content-center" v-if="!isLoading && isPost">-->
    <!--      <v-col>-->
    <!--          <h3 class="text-center print-header">Охват питания УСП<br>-->
    <!--          за {{ selectedMonthName }} {{ selectedYearName }} года<br>-->
    <!--          {{ selectedUserAllowedPlaceName }}</h3>-->
    <!--      </v-col>-->
    <!--      <v-col  cols="2" v-if="!isNoSaveReport">-->
    <!--          <v-btn-->
    <!--          disabled-->
    <!--          block-->
    <!--          @click="saveReport()"-->
    <!--          >-->
    <!--          Подписать-->
    <!--        </v-btn>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row class="justify-content-center">-->
    <!--        <v-col-->
    <!--            class="text-center"-->
    <!--            v-if="isLoading">-->
    <!--            <v-row>-->
    <!--              <v-col-->
    <!--                class="text-center">-->
    <!--              {{ loadMessage }}-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--            <v-row>-->
    <!--              <v-col-->
    <!--                class="text-center">-->
    <!--                <v-progress-circular-->
    <!--                  :size="100"-->
    <!--                  :width="7"-->
    <!--                  color="green"-->
    <!--                  indeterminate-->
    <!--                ></v-progress-circular>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--        </v-col>-->
    <!--        <v-col-->
    <!--            v-if="!isLoading && foodWarehouseReport.length > 0" cols="8"-->
    <!--        >-->
    <!--          <v-simple-table dense-->
    <!--            class="foodwarehouse-bymonth"-->
    <!--            v-for="foodWarehouse in foodWarehouseReport"-->
    <!--            v-bind:key="foodWarehouse.foodWarehouseId">-->
    <!--            <template v-slot:default>-->
    <!--              <thead>-->
    <!--                <tr>-->
    <!--                  <th class="text-left"  colspan="100%">-->
    <!--                    <strong>{{ foodWarehouse.foodWarehouseName }}</strong>-->
    <!--                  </th>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-left">-->
    <!--                  </th>-->
    <!--                  <th class="text-left"-->
    <!--                    v-for="item in foodWarehouse.reportItems"-->
    <!--                    :key="item.name">-->
    <!--                    {{ item.parallel }}-->
    <!--                  </th>-->
    <!--                  <th class="text-left">-->
    <!--                    Итого-->
    <!--                  </th>-->
    <!--                </tr>-->
    <!--              </thead>-->
    <!--              <tbody>-->
    <!--                <tr>-->
    <!--                  <td>Обед льготный</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.hotDinnerFreeSop }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumHotDinnerFreeSop }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Обед платный</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.hotDinnerPaidSop }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumHotDinnerPaidSop }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Завтрак льготный</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.hotBreakfastFreeSop }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumHotBreakfastFreeSop }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Завтрак платный</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.hotBreakfastPaidSop }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumHotBreakfastPaidSop }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Обед+Завтрак льготный</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.hotFullFreeSop }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumHotFullFreeSop }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Обед+Завтрак платный</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.hotFullPaidSop }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumHotFullPaidSop }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Кадетов</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.cadetsCount }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumCadetsCount }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Спортсменов</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.sportsmanCount }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumSportsmanCount }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Льготников</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.beneficiariesCount }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumBeneficiariesCount }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Нуждающихся в лечебном диетическом питании</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.sportsmanCount }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.sumDietaryCount }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <td>Детей всего</td>-->
    <!--                  <td v-for="item in foodWarehouse.reportItems" :key="item.name">-->
    <!--                    {{ item.childrenCount }}-->
    <!--                  </td>-->
    <!--                  <td>-->
    <!--                    {{ foodWarehouse.childrenCount }}-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--                &lt;!&ndash;<tr>-->
    <!--                  <td><strong>Детей всего</strong></td>-->
    <!--                  <td colspan="100%" class="text-center" >-->
    <!--                    <strong>{{ foodWarehouse.childrenCount }}</strong>-->
    <!--                  </td>-->
    <!--                </tr>&ndash;&gt;-->
    <!--              </tbody>-->
    <!--            </template>-->
    <!--          </v-simple-table>-->
    <!--        </v-col>-->
    <!--    </v-row>-->
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PlaceElectronTalonFindWithItemsPost } from '@/shared/api/placeElectronTalon/api-types'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'

type BaseData = {
  foodWarehouseReport: any;
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlaceName: string;
  selectedUserAllowedPlace: any;
  selectedMonth: any;
  selectedMonthName: string;
  selectedYearName: string;
  selectedYear: number;
  separateClasses: boolean;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsPost;
  customerPlacePost: CustomerPlaceFindPost;
  placeElectronTalonsAnswere: any;
  customerPlaceInfo: any;
  placeElectronTalons: any;
  places: any;
  products: any;
  settings: any;
  notAcceptDates: any;
  errorDates: any;
  header: any;
  placeFoodWarehouses: any;
  headerPlaces: any;
  headerPlaceProducts: any;
  comparePlaceProducts: any;
  selectedProductPrice: any;
  selectedHeaderPlaceProduct: any;
  bodyPlaceProducts: any;
  bodyByDatePlaceProducts: any;
  bodyDates: any;
  productDatePrices: any;
  datePrices: any;
  reportArray: any;
  ratePlans: any;
  sales: any;
  problemSales: any;
  loadMessage: any;
  isNoSaveReport: boolean;
  statusReport: number;
}

export default defineComponent({
//   components: {
//     MonthSelector,
//     YearSelector,
//     PlaceSelector
//   },
//   data (): BaseData {
//     return {
//       isLoading: false,
//       isPost: false,
//       selectedUserAllowedPlace: [],
//       selectedUserAllowedPlaceName: '',
//       selectedMonth: { id: 1, name: 'Неизвестно' },
//       selectedMonthName: 'Неизвестно',
//       selectedYearName: 'Неизвестно',
//       selectedYear: 2021,
//       separateClasses: false,
//       placeElectronTalonGetByIdPost: {
//         parentPlaceId: 0,
//         dateFrom: '',
//         dateTo: ''
//       },
//       customerPlacePost: {
//         placeId: 0,
//         date: ''
//       },
//       placeElectronTalonsAnswere: [],
//       customerPlaceInfo: null,
//       placeElectronTalons: [],
//       places: [],
//       errorDates: [],
//       notAcceptDates: [],
//       products: [],
//       settings: [],
//       header: [],
//       placeFoodWarehouses: [],
//       headerPlaces: [],
//       headerPlaceProducts: [],
//       comparePlaceProducts: [],
//       selectedHeaderPlaceProduct: null,
//       selectedProductPrice: null,
//       bodyPlaceProducts: [],
//       bodyByDatePlaceProducts: [],
//       bodyDates: [],
//       productDatePrices: [],
//       datePrices: [],
//       reportArray: [],
//       sales: [],
//       problemSales: [],
//       ratePlans: [],
//       foodWarehouseReport: [],
//       loadMessage: '',
//       statusReport: 0,
//       isNoSaveReport: true
//     }
//   },
//   computed: {
//     ...mapGetters({ user: 'user/user' }),
//     changes: {
//       get: function (): string {
//         return this.loadMessage
//       },
//       set: function (v: string) {
//         this.loadMessage = v
//       }
//     }
//   },
//   props: {
//     propPlaceId: {
//       type: String,
//       required: false
//     }
//   },
//   methods: {
//     ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
//     async loadCustomerPlaceInfo () {
//       try {
//         if (this.selectedUserAllowedPlace !== 0) {
//           this.isLoading = true
//           this.changes = 'Загружаем данные с сервера этап 1'
//           this.customerPlacePost.placeId = this.selectedUserAllowedPlace.id
//           this.customerPlacePost.date = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).format('YYYY-MM-DD')
//           const result = await find(this.customerPlacePost)
//           this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
//           this.loadPlaceFoodWarehouses()
//         }
//       } catch (error: any) {
//         let errorText = 'Ошибка. Повторите попытку.'
//         const err = error as AxiosError
//         if (err.response) {
//           errorText = err.response.data.errorMessage
//         }
//         this.setSnackbar({
//           showing: true,
//           text: errorText,
//           timeout: 5000
//         })
//         this.isLoading = false
//         this.changes = ''
//       }
//     },
//     async loadPlaceFoodWarehouses () {
//       try {
//         if (this.selectedUserAllowedPlace !== 0) {
//           this.changes = 'Загружаем данные с сервера этап 2'
//           const placeFoodWarehousesFindPost: PlaceFoodWarehousesFindPost = {
//             id: null,
//             placeId: Number(this.selectedUserAllowedPlace.id),
//             foodWarehouseId: null,
//             status: 1,
//             includePmpkLevels: 1
//           }
//           const result = await placeFoodWarehousesFind(placeFoodWarehousesFindPost)
//           this.placeFoodWarehouses = JSON.parse(JSON.stringify(result))
//           if (this.placeFoodWarehouses.length > 0) {
//             this.loadCustomerRatePlans()
//           }
//         }
//       } catch (error: any) {
//         let errorText = 'Ошибка. Повторите попытку.'
//         const err = error as AxiosError
//         if (err.response) {
//           errorText = err.response.data.errorMessage
//         }
//         this.setSnackbar({
//           showing: true,
//           text: errorText,
//           timeout: 5000
//         })
//         this.isLoading = false
//         this.changes = ''
//       }
//     },
//     async loadCustomerRatePlans () {
//       try {
//         this.changes = 'Загружаем данные с сервера этап 3'
//         if (this.selectedUserAllowedPlace !== 0) {
//           const ratePlanFindPost: RatePlanFindPost = {
//             customerId: this.selectedUserAllowedPlace
//           }
//           const result = await findRatePlans(ratePlanFindPost)
//           this.ratePlans = JSON.parse(JSON.stringify(result))
//           this.loadSales()
//         }
//       } catch (error: any) {
//         let errorText = 'Ошибка. Повторите попытку.'
//         const err = error as AxiosError
//         if (err.response) {
//           errorText = err.response.data.errorMessage
//         }
//         this.setSnackbar({
//           showing: true,
//           text: errorText,
//           timeout: 5000
//         })
//         this.isLoading = false
//         this.changes = ''
//       }
//     },
//     async loadSales () {
//       try {
//         if (this.selectedUserAllowedPlace !== 0) {
//           this.foodWarehouseReport = []
//           this.changes = 'Загружаем данные с сервера этап 4'
//           const salesForReportPost: SalesForReportPost = {
//             placeId: this.selectedUserAllowedPlace.id,
//             timestampFrom: moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('YYYY-MM-DD'),
//             timestampTo: moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').endOf('month').format('YYYY-MM-DD')
//           }
//           /* const salesForReportPost: SalesForReportPost = {
//             placeId: this.selectedUserAllowedPlace.id,
//             timestampFrom: moment(this.selectedYear + '-09-02').format('YYYY-MM-DD'),
//             timestampTo: moment(this.selectedYear + '-09-02').format('YYYY-MM-DD')
//           } */
//           await salesForReport(salesForReportPost)
//             .then((result: any) => {
//               this.sales = JSON.parse(JSON.stringify(result))
//             })
//           this.changes = 'Обрабатываем данные'
//           await this.delay()
//           console.log(this.sales)
//           this.createReport2()
//         }
//       } catch (error: any) {
//         let errorText = 'Ошибка. Повторите попытку.'
//         const err = error as AxiosError
//         if (err.response) {
//           errorText = err.response.data.errorMessage
//         }
//         this.setSnackbar({
//           showing: true,
//           text: errorText,
//           timeout: 3000
//         })
//       } finally {
//         this.isLoading = false
//         this.changes = ''
//       }
//     },
//     async saveReport () {
//       try {
//         this.isLoading = true
//         const foodWarehousesSaveReportPost: FoodWarehousesSaveReportPost = {
//           foodWarehouses: this.foodWarehouseReport
//         }
//         const result = await foodWarehousesSaveReport(foodWarehousesSaveReportPost)
//         console.log(result)
//       } catch (error: any) {
//         let errorText = 'Ошибка. Повторите попытку.'
//         const err = error as AxiosError
//         if (err.response) {
//           errorText = err.response.data.errorMessage
//         }
//         this.setSnackbar({
//           showing: true,
//           text: errorText,
//           timeout: 3000
//         })
//       } finally {
//         this.isLoading = false
//         this.changes = ''
//       }
//     },
//     delay () {
//       return new Promise(resolve => setTimeout(resolve, 1))
//     },
//     createReport2 () {
//       console.log('createReport2')
//       this.sales.map((sale: any) => {
//         let foodWarehouse: any = null
//         if (sale.ownerUserId != null) {
//           this.placeFoodWarehouses.forEach((placeFoodWarehouse: any) => {
//             const temp = placeFoodWarehouse.foodWarehouse.foodWarehouseDevices.find((el: any) => el.deviceId === sale.ownerUserId)
//             if (temp) {
//               foodWarehouse = placeFoodWarehouse
//             }
//           })
//         }
//         if (!foodWarehouse) {
//           this.placeFoodWarehouses.forEach((placeFoodWarehouse: any) => {
//             let temp: any = null
//             temp = placeFoodWarehouse.foodWarehouse.foodWarehousePlaces.find((el: any) => el.placeId === sale.placeId)
//             if (temp) {
//               foodWarehouse = placeFoodWarehouse
//             }
//           })
//         }
//         if (foodWarehouse) {
//           let foodWarehouseRep = this.foodWarehouseReport.find((el: any) => el.foodWarehouseId === foodWarehouse.foodWarehouseId)
//           if (!foodWarehouseRep) {
//             const foodWarehouseItem: foodWarehouseItem = {
//               year: this.selectedYear,
//               month: this.selectedMonth.id,
//               childrenCount: 0,
//               foodWarehouseId: foodWarehouse.foodWarehouseId,
//               reportItems: [],
//               foodWarehouseName: foodWarehouse.foodWarehouse.name,
//               sumChildrenCount: 0,
//               sumCadetsCount: 0,
//               sumSportsmanCount: 0,
//               sumBeneficiariesCount: 0,
//               sumDietaryCount: 0,
//               sumHotBreakfastFreeSop: 0,
//               sumHotBreakfastPaidSop: 0,
//               sumHotDinnerFreeSop: 0,
//               sumHotDinnerPaidSop: 0,
//               sumHotFullFreeSop: 0,
//               sumHotFullPaidSop: 0
//             }
//             this.foodWarehouseReport.push(foodWarehouseItem)
//             foodWarehouseRep = foodWarehouseItem
//             this.isNoSaveReport = false
//           }
//
//           let placeParallel: any = 'error'
//           placeParallel = this.getParallel(sale.placeName)
//           let parallel = foodWarehouse.foodWarehouse.foodWarehousePmpkLevels.find((el: any) => el.name === placeParallel + ' класс')
//           if (!parallel) {
//             console.log(sale.placeName)
//             placeParallel = 'Иное'
//             parallel = foodWarehouse.foodWarehouse.foodWarehousePmpkLevels.find((el: any) => el.name === placeParallel)
//           }
//           let reportItem: any = null
//           if (foodWarehouseRep.reportItems.length > 0) {
//             reportItem = foodWarehouseRep.reportItems.find((el: any) => el.levelId === parallel.levelId)
//           }
//
//           if (!reportItem) {
//             const reportItemNew: reportItem = {
//               parallel: placeParallel,
//               parallelId: parallel.id,
//               levelId: parallel.levelId,
//               childrenCount: 0,
//               cadetsCount: 0,
//               sportsmanCount: 0,
//               beneficiariesCount: 0,
//               dietaryCount: 0,
//               hotBreakfastFreeSop: 0,
//               hotBreakfastPaidSop: 0,
//               hotDinnerFreeSop: 0,
//               hotDinnerPaidSop: 0,
//               hotFullFreeSop: 0,
//               hotFullPaidSop: 0
//             }
//             foodWarehouseRep.reportItems.push(reportItemNew)
//             reportItem = foodWarehouseRep.reportItems.find((el: any) => el.levelId === parallel.levelId)
//           }
//
//           if (placeParallel === '1') {
//             console.log(sale.userRatePlanId)
//           }
//
//           if (sale.productCode === 'ОБЕД') {
//             if (sale.discount > 0) {
//               if (sale.productFull > 0) {
//                 reportItem.hotFullFreeSop++
//                 foodWarehouseRep.sumHotFullFreeSop++
//               } else {
//                 reportItem.hotDinnerFreeSop++
//                 foodWarehouseRep.sumHotDinnerFreeSop++
//               }
//             } else {
//               if (sale.productFull > 0) {
//                 reportItem.hotFullPaidSop++
//                 foodWarehouseRep.sumHotFullPaidSop++
//               } else {
//                 reportItem.hotDinnerPaidSop++
//                 foodWarehouseRep.sumHotDinnerPaidSop++
//               }
//             }
//             reportItem.childrenCount++
//             foodWarehouseRep.childrenCount++
//
//             if (sale.userRatePlanId != null) {
//               const saleUserRatePlanId = sale.userRatePlanId.toString()
//               const ratePlane = this.ratePlans.find((el: any) => el.id === saleUserRatePlanId)
//               if (ratePlane) {
//                 if (ratePlane.code !== 'Категория 0') {
//                   reportItem.beneficiariesCount++
//                   foodWarehouseRep.sumBeneficiariesCount++
//                   if (ratePlane.code === '10' || ratePlane.code === '9') {
//                     reportItem.dietaryCount++
//                     foodWarehouseRep.sumDietaryCount++
//                   } else if (ratePlane.code === '11') {
//                     reportItem.sportsmanCount++
//                     foodWarehouseRep.sumSportsmanCount++
//                   }
//                 }
//               }
//             }
//           } else if (sale.productCode === 'ЗАВТРАК') {
//             if (sale.discount > 0) {
//               if (sale.productFull > 0) {
//                 reportItem.hotFullFreeSop++
//                 foodWarehouseRep.sumHotFullFreeSop++
//               } else {
//                 reportItem.hotBreakfastFreeSop++
//                 foodWarehouseRep.sumHotBreakfastFreeSop++
//               }
//             } else {
//               if (sale.productFull > 0) {
//                 reportItem.hotFullPaidSop++
//                 foodWarehouseRep.sumHotFullPaidSop++
//               } else {
//                 reportItem.hotBreakfastPaidSop++
//                 foodWarehouseRep.sumHotBreakfastPaidSop++
//               }
//             }
//             reportItem.childrenCount++
//             foodWarehouseRep.childrenCount++
//
//             if (sale.userRatePlanId != null) {
//               const saleUserRatePlanId = sale.userRatePlanId.toString()
//               const ratePlane = this.ratePlans.find((el: any) => el.id === saleUserRatePlanId)
//               if (ratePlane) {
//                 if (ratePlane.code !== 'Категория 0') {
//                   reportItem.beneficiariesCount++
//                   foodWarehouseRep.sumBeneficiariesCount++
//                   if (ratePlane.code === '10' || ratePlane.code === '9') {
//                     reportItem.dietaryCount++
//                     foodWarehouseRep.sumDietaryCount++
//                   } else if (ratePlane.code === '11') {
//                     reportItem.sportsmanCount++
//                     foodWarehouseRep.sumSportsmanCount++
//                   }
//                 }
//               }
//             }
//           }
//         }
//       })
//       this.foodWarehouseReport.forEach((element: any) => {
//         if (element.reportItems) {
//           element.reportItems = element.reportItems.sort((a: any, b: any) => (a.levelId > b.levelId) ? 1 : ((a.levelId < b.levelId) ? -1 : 0))
//         }
//       })
//     },
//     createReport () {
//       console.log('createReport')
//       const outArray: any = []
//       this.sales.map((sale: any) => {
//         if (outArray.find((el: any) => el.id === sale.id)) {
//           // console.log('out: ' + sale.id)
//         } else {
//           let foodWarehouse: any = null
//           let userPlace: any = null
//           let userRatePlan: any = null
//           if (sale.user.userPlaces.length > 0) {
//             sale.user.userPlaces.forEach((element: any) => {
//               if (element.actionUserPlace === 1) {
//                 userPlace = element
//               }
//             })
//           } else {
//             console.log('userPlace error!!!!')
//           }
//           if (sale.ownerUserId != null) {
//             this.placeFoodWarehouses.forEach((placeFoodWarehouse: any) => {
//               const temp = placeFoodWarehouse.foodWarehouse.foodWarehouseDevices.find((el: any) => el.deviceId === sale.ownerUserId)
//               if (temp) {
//                 foodWarehouse = placeFoodWarehouse
//               }
//             })
//           }
//           if (!foodWarehouse) {
//             this.placeFoodWarehouses.forEach((placeFoodWarehouse: any) => {
//               let temp: any = null
//               if (userPlace) {
//                 temp = placeFoodWarehouse.foodWarehouse.foodWarehousePlaces.find((el: any) => el.placeId === userPlace.placeId)
//               }
//               if (temp) {
//                 foodWarehouse = placeFoodWarehouse
//               }
//             })
//           }
//           if (foodWarehouse) {
//             let foodWarehouseRep = this.foodWarehouseReport.find((el: any) => el.foodWarehouseId === foodWarehouse.foodWarehouseId)
//             if (!foodWarehouseRep) {
//               const foodWarehouseItem: foodWarehouseItem = {
//                 year: this.selectedYear,
//                 month: this.selectedMonth.id,
//                 childrenCount: 0,
//                 foodWarehouseId: foodWarehouse.foodWarehouseId,
//                 reportItems: [],
//                 foodWarehouseName: foodWarehouse.foodWarehouse.name,
//                 sumChildrenCount: 0,
//                 sumCadetsCount: 0,
//                 sumSportsmanCount: 0,
//                 sumBeneficiariesCount: 0,
//                 sumDietaryCount: 0,
//                 sumHotBreakfastFreeSop: 0,
//                 sumHotBreakfastPaidSop: 0,
//                 sumHotDinnerFreeSop: 0,
//                 sumHotDinnerPaidSop: 0,
//                 sumHotFullFreeSop: 0,
//                 sumHotFullPaidSop: 0
//               }
//               this.foodWarehouseReport.push(foodWarehouseItem)
//               foodWarehouseRep = foodWarehouseItem
//               this.isNoSaveReport = false
//             }
//
//             /* Определяем параллель */
//             let placeParallel: any = 'error'
//             placeParallel = this.getParallel(userPlace.place.name)
//             let parallel = foodWarehouse.foodWarehouse.foodWarehousePmpkLevels.find((el: any) => el.name === placeParallel + ' класс')
//             if (!parallel) {
//               console.log(userPlace.place.name)
//               placeParallel = 'Иное'
//               parallel = foodWarehouse.foodWarehouse.foodWarehousePmpkLevels.find((el: any) => el.name === placeParallel)
//             }
//             let reportItem: any = null
//             if (foodWarehouseRep.reportItems.length > 0) {
//               reportItem = foodWarehouseRep.reportItems.find((el: any) => el.levelId === parallel.levelId)
//             }
//             if (!reportItem) {
//               const reportItemNew: reportItem = {
//                 parallel: placeParallel,
//                 parallelId: parallel.id,
//                 levelId: parallel.levelId,
//                 childrenCount: 0,
//                 cadetsCount: 0,
//                 sportsmanCount: 0,
//                 beneficiariesCount: 0,
//                 dietaryCount: 0,
//                 hotBreakfastFreeSop: 0,
//                 hotBreakfastPaidSop: 0,
//                 hotDinnerFreeSop: 0,
//                 hotDinnerPaidSop: 0,
//                 hotFullFreeSop: 0,
//                 hotFullPaidSop: 0
//               }
//               foodWarehouseRep.reportItems.push(reportItemNew)
//               reportItem = foodWarehouseRep.reportItems.find((el: any) => el.levelId === parallel.levelId)
//             }
//
//             if (sale.product.code === 'ОБЕД') {
//               const searchFull = this.sales.filter((el: any) => (el.userId === sale.userId && (el.product.code === 'ЗАВТРАК') && moment(el.creationDate).date() === moment(sale.creationDate).date()))
//               if (searchFull.length === 1) {
//                 if (sale.discount > 0) {
//                   if (searchFull[0].discount > 0) {
//                     reportItem.hotFullFreeSop++
//                     foodWarehouseRep.sumHotFullFreeSop++
//                     outArray.push(searchFull[0])
//                   } else {
//                     reportItem.hotDinnerFreeSop++
//                     foodWarehouseRep.sumHotDinnerFreeSop++
//                   }
//                 } else {
//                   if (searchFull[0].discount === 0) {
//                     reportItem.hotFullPaidSop++
//                     foodWarehouseRep.sumHotFullPaidSop++
//                     outArray.push(searchFull[0])
//                   } else {
//                     reportItem.hotDinnerPaidSop++
//                     foodWarehouseRep.sumHotDinnerPaidSop++
//                   }
//                 }
//               } else if (searchFull.length > 1) {
//                 if (sale.discount > 0) {
//                   let search = null
//                   searchFull.forEach((element: any) => {
//                     if (element.discont > 0) {
//                       search = element
//                     }
//                   })
//                   if (search) {
//                     reportItem.hotFullFreeSop++
//                     foodWarehouseRep.sumHotFullFreeSop++
//                     outArray.push(search)
//                   } else {
//                     console.log(sale)
//                     reportItem.hotDinnerFreeSop++
//                     foodWarehouseRep.sumHotDinnerFreeSop++
//                   }
//                 } else {
//                   let search = null
//                   searchFull.forEach((element: any) => {
//                     if (element.discont === 0) {
//                       search = element
//                     }
//                   })
//                   if (search) {
//                     reportItem.hotFullPaidSop++
//                     foodWarehouseRep.sumHotPaidFreeSop++
//                     outArray.push(search)
//                   } else {
//                     reportItem.hotDinnerPaidSop++
//                     foodWarehouseRep.sumHotDinnerPaidSop++
//                   }
//                 }
//               } else {
//                 if (sale.discount > 0) {
//                   reportItem.hotDinnerFreeSop++
//                   foodWarehouseRep.sumHotDinnerFreeSop++
//                 } else {
//                   reportItem.hotDinnerPaidSop++
//                   foodWarehouseRep.sumHotDinnerPaidSop++
//                 }
//               }
//               reportItem.childrenCount++
//               foodWarehouseRep.childrenCount++
//               outArray.push(sale)
//
//               /* Определяем льготу */
//               if (sale.user.userRatePlans.length > 0) {
//                 sale.user.userRatePlans.forEach((element: any) => {
//                   if (element.actionRatePlan === null) {
//                     userRatePlan = element
//                   }
//                 })
//                 if (userRatePlan) {
//                   const ratePlane = this.ratePlans.find((el: any) => el.id === userRatePlan.ratePlanId)
//                   if (ratePlane) {
//                     if (ratePlane.code !== 'Категория 0') {
//                       reportItem.beneficiariesCount++
//                       foodWarehouseRep.sumBeneficiariesCount++
//                       if (ratePlane.code === '10' || ratePlane.code === '9') {
//                         reportItem.dietaryCount++
//                         foodWarehouseRep.sumDietaryCount++
//                       } else if (ratePlane.code === '11') {
//                         reportItem.sportsmanCount++
//                         foodWarehouseRep.sumSportsmanCount++
//                       }
//                     }
//                   }
//                 }
//               }
//             } else if (sale.product.code === 'ЗАВТРАК') {
//               const searchFull = this.sales.filter((el: any) => (el.userId === sale.userId && (el.product.code === 'ОБЕД') && moment(el.creationDate).date() === moment(sale.creationDate).date()))
//               if (searchFull.length === 1) {
//                 if (sale.discount > 0) {
//                   if (searchFull[0].discount > 0) {
//                     reportItem.hotFullFreeSop++
//                     foodWarehouseRep.sumHotFullFreeSop++
//                     outArray.push(searchFull[0])
//                   } else {
//                     reportItem.hotBreakfastFreeSop++
//                     foodWarehouseRep.sumHotBreakfastFreeSop++
//                   }
//                 } else {
//                   if (searchFull[0].discount === 0) {
//                     reportItem.hotFullPaidSop++
//                     foodWarehouseRep.sumHotFullPaidSop++
//                     outArray.push(searchFull[0])
//                   } else {
//                     reportItem.hotBreakfastPaidSop++
//                     foodWarehouseRep.sumHotBreakfastPaidSop++
//                   }
//                 }
//               } else if (searchFull.length > 1) {
//                 if (sale.discount > 0) {
//                   let search = null
//                   searchFull.forEach((element: any) => {
//                     if (element.discont > 0) {
//                       search = element
//                     }
//                   })
//                   if (search) {
//                     reportItem.hotFullFreeSop++
//                     foodWarehouseRep.sumHotFullFreeSop++
//                     outArray.push(search)
//                   } else {
//                     reportItem.hotBreakfastFreeSop++
//                     foodWarehouseRep.sumHotBreakfastFreeSop++
//                   }
//                 } else {
//                   let search = null
//                   searchFull.forEach((element: any) => {
//                     if (element.discont === 0) {
//                       search = element
//                     }
//                   })
//                   if (search) {
//                     reportItem.hotFullPaidSop++
//                     foodWarehouseRep.sumHotPaidFreeSop++
//                     outArray.push(search)
//                   } else {
//                     reportItem.hotBreakfastPaidSop++
//                     foodWarehouseRep.sumHotBreakfastPaidSop++
//                   }
//                 }
//               } else {
//                 if (sale.discount > 0) {
//                   reportItem.hotBreakfastFreeSop++
//                   foodWarehouseRep.sumHotBreakfastFreeSop++
//                 } else {
//                   reportItem.hotBreakfastPaidSop++
//                   foodWarehouseRep.sumHotBreakfastPaidSop++
//                 }
//               }
//               outArray.push(sale)
//               reportItem.childrenCount++
//               foodWarehouseRep.childrenCount++
//
//               /* Определяем льготу */
//               if (sale.user.userRatePlans.length > 0) {
//                 sale.user.userRatePlans.forEach((element: any) => {
//                   if (element.actionRatePlan === null) {
//                     userRatePlan = element
//                   }
//                 })
//                 if (userRatePlan) {
//                   const ratePlane = this.ratePlans.find((el: any) => el.id === userRatePlan.ratePlanId)
//                   if (ratePlane) {
//                     if (ratePlane.code !== 'Категория 0') {
//                       reportItem.beneficiariesCount++
//                       foodWarehouseRep.sumBeneficiariesCount++
//                       if (ratePlane.code === '10' || ratePlane.code === '9') {
//                         reportItem.dietaryCount++
//                         foodWarehouseRep.sumDietaryCount++
//                       } else if (ratePlane.code === '11') {
//                         reportItem.sportsmanCount++
//                         foodWarehouseRep.sumSportsmanCount++
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           } else {
//             if (!userPlace) {
//               this.problemSales.push(sale)
//             }
//           }
//         }
//       })
//       this.foodWarehouseReport.forEach((element: any) => {
//         if (element.reportItems) {
//           element.reportItems = element.reportItems.sort((a: any, b: any) => (a.levelId > b.levelId) ? 1 : ((a.levelId < b.levelId) ? -1 : 0))
//         }
//       })
//     },
//     getParallel (name: string) {
//       let flagBreak = false
//       let parallel = ''
//       name.split('').forEach((el: any) => {
//         if (el.match('[0-9]')) {
//           if (!flagBreak) {
//             parallel = parallel + el
//           }
//         } else {
//           flagBreak = true
//         }
//       })
//       return parallel
//     },
//     getParallels (type: any) {
//       let parallels = ''
//       if (!this.separateClasses) {
//         if (type === 1) {
//           parallels = '1-4 классы'
//         } else {
//           parallels = '5-11 классы'
//         }
//       } else {
//         this.settings.forEach((element: any) => {
//           if (element.classType === type) {
//             parallels = element.startValue + '-' + element.endValue + ' классы'
//           }
//         })
//       }
//       return parallels
//     },
//     getSettings (settingsResponse: any) {
//       this.settings = []
//       if (Array.isArray(settingsResponse)) {
//         settingsResponse.forEach((element: any) => {
//           const elementCopy = element
//           const values = elementCopy.value.split(';')
//           const setting = {
//             id: element.id,
//             startValue: values[0],
//             endValue: values[1]
//           }
//           this.settings.push(setting)
//         })
//       }
//       this.sortSettings()
//       let i = 1
//       this.settings.forEach((element: any) => {
//         element.classType = i
//         i = i + 1
//       })
//     },
//     sortSettings () {
//       this.settings.sort((a: any, b: any) => (a.startValue > b.startValue) ? 1 : ((b.startValue > a.startValue) ? -1 : 0))
//     },
//     clearData () {
//       this.header = []
//       this.headerPlaces = []
//       this.headerPlaceProducts = []
//       this.comparePlaceProducts = []
//       this.bodyPlaceProducts = []
//       this.bodyByDatePlaceProducts = []
//       this.bodyDates = []
//       this.productDatePrices = []
//       this.datePrices = []
//     },
//     load () {
//       this.placeElectronTalons = []
//       this.places = []
//       this.products = []
//       this.errorDates = []
//       this.notAcceptDates = []
//       this.placeElectronTalonsAnswere = []
//       this.settings = []
//       this.comparePlaceProducts = []
//       this.selectedHeaderPlaceProduct = null
//       this.selectedProductPrice = null
//       this.clearData()
//       this.customerPlaceInfo = null
//       this.loadCustomerPlaceInfo()
//       this.isPost = true
//       this.selectedMonthName = this.selectedMonth.name
//       this.selectedYearName = this.selectedYear.toString()
//     },
//     goToSettings () {
//       this.$router.push({
//         name: 'FoodWarehousesSettings',
//         params: {
//           placeId: this.selectedUserAllowedPlace.id,
//           report: 'ReportByFoodWarehouses'
//         }
//       })
//     }
//   }
})
</script>

<style lang="scss"></style>
