<template>
  <div style="position: relative">
    <base-simple-table
      :table-data="getTableDataFromAcceptedRation(acceptedRation)"
      :style="{padding: '10px'}"
    />
    <v-checkbox
      v-if="withCheckbox"
      class="rations_cards__checkbox"
      :model-value="checked"
      @update:model-value="selectRationToSave(acceptedRation)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BaseSimpleTable, { TableData, TableDataCell } from '@/shared/uikit/BaseSimpleTable.vue'
import { ConvertedApiTypeItem } from '@/views/parents/preorders/PreorderForTommorow.vue'

export default defineComponent({
  name: 'RationCard',
  components: { BaseSimpleTable },
  props: {
    withCheckbox: {
      default: false,
      type: Boolean,
      required: false
    },
    checked: {
      default: false,
      type: Boolean,
      required: false
    },
    acceptedRation: {
      type: Object as PropType<ConvertedApiTypeItem>,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    getTableDataFromAcceptedRation (rationItem: ConvertedApiTypeItem): TableData {
      // console.log('getTableDataFromAcceptedRation rationItem: ', rationItem)
      // console.log(this.withCheckbox)
      const res = {
        rowsCount: 2,
        columnsCount: 1,
        items: [] as TableDataCell[]
      }
      res.items.push(
        {
          text: `${rationItem.name}`,
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 1,
          gridColumnEnd: 2,
          background: 'lightgreen',
          fontWeight: 'bold',
          padding: '5px',
          fontSize: '12px',
          minWidth: '250px',
          slotAfter: {
            name: 'action',
            classNames: 'slot-after-action rations_cards__checkbox'
          }
        }
      )
      let currentRow = 1
      if (rationItem.accountTypeId === 4) {
        res.items.push({
          text: 'Льготное',
          gridRowStart: 2,
          gridRowEnd: 3,
          gridColumnStart: 1,
          gridColumnEnd: 2,
          background: 'lightgreen',
          fontWeight: 'normal',
          padding: '5px',
          minWidth: '250px',
          fontSize: '12px'
        })
        currentRow += 1
      } else if (rationItem.accountTypeId === 1) {
        res.items.push({
          text: 'Платное',
          gridRowStart: 2,
          gridRowEnd: 3,
          gridColumnStart: 1,
          gridColumnEnd: 2,
          background: 'lightgreen',
          fontWeight: 'normal',
          padding: '5px',
          minWidth: '250px',
          fontSize: '12px'
        })
        currentRow += 1
      }
      rationItem.products.forEach(product => {
        res.items.push({
          text: `${product.name}`,
          gridRowStart: currentRow + 1,
          gridRowEnd: currentRow + 2,
          gridColumnStart: 1,
          gridColumnEnd: 2,
          fontWeight: 'normal',
          minWidth: '250px',
          padding: '5px',
          fontSize: '12px'
        })
        currentRow += 1
      })
      res.rowsCount = currentRow
      return res
    },
    selectRationToSave (rationItem: ConvertedApiTypeItem) {
      this.$emit('selectedRationToSave', rationItem)
    }
  }
})
</script>

<style scoped lang="scss">
.rations_cards__checkbox {
  position: absolute;
  top: -5px;
  left: 25px;
}
</style>
