<template>
  <v-container class="convertedRawApiData">
    <v-row class="justify-content-center">
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="isLoading"
            cols="10"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
        <v-row v-if="!isLoading">
          <v-col>
            <a :href="'/users/edit/' + 35">Иванов Иван Иванович</a>
            <v-btn
              variant="text"
              :to="{ name: 'UserEdit', params: { id: 891188 } }"
              action
            >
              Иванов Иван Иванович
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

type BaseData = {
  selectedUserType: any;
  snack: boolean;
  snackColor: string;
  snackText: string;
  isLoading: boolean;
  user: any;
}
export default defineComponent({
  components: {

  },
  data (): BaseData {
    return {
      selectedUserType: null,
      snack: false,
      snackColor: '',
      snackText: '',
      isLoading: false,
      user: null
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' })
  }
})
</script>

<style lang='scss'>
</style>
