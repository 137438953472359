import axios, { AxiosInstance } from 'axios'

const defaultOptions = {
  baseURL: `${process.env.VUE_APP_CARD_READER_URL}/api`
}

const instance = axios.create(defaultOptions)

export default instance

export const setAuthorizationHeader = (a: AxiosInstance) => {
  // functional/immutable-data
  // eslint-disable-next-line
  a.defaults.headers.common.Authorization = `Bearer ${
    localStorage.getItem('accessToken') || ''
  }`
}

export const setCardReaderUrl = (a: AxiosInstance) => {
  // functional/immutable-data
  // eslint-disable-next-line
  if (localStorage.getItem('cardReaderUrl') === null || localStorage.getItem('cardReaderUrl') === undefined) {
    localStorage.setItem('cardReaderUrl', `${process.env.VUE_APP_CARD_READER_URL}`)
  }
  a.defaults.baseURL = `${localStorage.getItem('cardReaderUrl')}/api`
}
