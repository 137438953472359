import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0acbb9f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "whole_page" }
const _hoisted_2 = { style: {"justify-content":"center","display":"grid"} }
const _hoisted_3 = { class: "news_block" }
const _hoisted_4 = { class: "title-h4-roboto-medium" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsList, (newsItem, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "news-list-item"
          }, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.moment(newsItem.publishedDate).format('DD.MM.YYYY')), 1),
            _createElementVNode("div", {
              innerHTML: newsItem.text
            }, null, 8, _hoisted_5)
          ]))
        }), 128))
      ])
    ])
  ]))
}