import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { UserPlaceHolidaySettingsFindPost } from './api-types'

const userPlaceHolidaySettingsFind = '/userplaceholidaysettings/find'
const userPlaceHolidaySettingsSave = '/userplaceholidaysettings/create'

export const find = async (data: UserPlaceHolidaySettingsFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(userPlaceHolidaySettingsFind, data)
  return result.data.data
}

export const save = async (data: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(userPlaceHolidaySettingsSave, data)
  return result.data.data
}
