<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col v-if="loading">
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
      </v-col>
      <v-col v-else>
        <img src="https://school.glolime.ru/new_site/img/icons/icons.svg#logo-glolime">
        <!--<img src="data:image/png;base64,">-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['t'],
  data () {
    return {
      loading: true,
      chatId: 0,
      WebApp: null,
      error: false
    }
  },
  beforeCreate () {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-web-app.js'
    script.defer = true
    document.head.appendChild(script)
  },
  mounted () {
    console.log(this.$props)
    let timerCount = 0
    const timer = setInterval(() => {
      timerCount++
      if (window.Telegram !== undefined) {
        const WebApp = window.Telegram.WebApp
        if (WebApp.initDataUnsafe !== undefined) {
          if (WebApp.initDataUnsafe.user !== undefined) {
            this.chatId = WebApp.initDataUnsafe.user.id
            this.WebApp = WebApp
          }
        }
        if (this.chatId !== 0) {
          this.loading = false
          clearInterval(timer)
        }
      }
      if (timerCount > 50) {
        this.error = true
        clearInterval(timer)
      }
    }, 1000)
  }
})
</script>
