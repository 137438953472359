<template>
  <div>
    <div>
      <h1 class="title-h1-roboto-medium">
        text
      </h1>
      <h2 class="title-h2-roboto-medium">
        text
      </h2>
      <h3 class="title-h3-roboto-medium">
        text
      </h3>
      <h4 class="title-h4-roboto-medium">
        text
      </h4>
      <p class="body-text-roboto-medium">
        text
      </p>
      <p class="button-text-roboto-medium">
        text
      </p>
      <p class="quote-text-roboto-medium">
        text
      </p>
      <p class="quote-text-roboto-regular">
        text
      </p>
      <p class="small-text-roboto-medium">
        text
      </p>
    </div>
    <div style="gap: 10px; display: flex">
      <button
        class="primary-green-button"
        height="100"
        width="100"
        disabled
      >
        Button
      </button>
      <button
        class="primary-green-button"
        height="100"
        width="100"
      >
        <img
          src="~@/assets/images/add_circle.png"
          height="19"
          width="19"
        >Button
      </button>
      <button
        class="primary-green-button"
        height="100"
        width="100"
      >
        Button<img
          src="~@/assets/images/add_circle.png"
          height="19"
          width="19"
        >
      </button>
      <button
        class="secondary-white-button"
        height="100"
        width="100"
        disabled
      >
        Button
      </button>
      <button
        class="secondary-white-button"
        height="100"
        width="100"
      >
        <img
          src="~@/assets/images/add_circle.png"
          height="19"
          width="19"
        >Button
      </button>
      <button
        class="secondary-white-button"
        height="100"
        width="100"
      >
        Button<img
          src="~@/assets/images/add_circle.png"
          height="19"
          width="19"
        >
      </button>
    </div>
    <br>
    <div>
      <div
        class="good_notification"
        style="height: 100px; width: 200px"
      >
        <div class="good-notification-text-box">
          <h4 class="body-text-roboto-medium">
            sdrftgyhujikfsddsj
          </h4>
        </div>
      </div>
    </div>
    <div>
      <div
        class="attention_notification"
        style="height: 100px; width: 200px"
      >
        <div class="attention-notification-text-box">
          <h4 class="body-text-roboto-medium">
            sdrftgyhujikfsddsj
          </h4>
        </div>
      </div>
    </div>
    <div>
      <div
        class="alert_notification"
        style="height: 100px; width: 200px"
      >
        <div class="alert-notification-text-box">
          <h4 class="body-text-roboto-medium">
            sdrftgyhujikfsddsj
          </h4>
        </div>
      </div>
    </div>
    <br>
    <div style="gap: 10px; display: flex; flex-direction: column">
      <label class="checkbox-round-noborders">go
        <input type="checkbox">
        <span class="checkbox-round-noborders-checkmark" />
      </label>
      <label class="checkbox-round-borders">swag
        <input type="checkbox">
        <span class="checkbox-round-borders-checkmark" />
      </label>
      <label class="checkbox-square-noborders">q
        <input type="checkbox">
        <span class="checkbox-square-noborders-checkmark" />
      </label>
      <label class="checkbox-square-borders">s
        <input type="checkbox">
        <span class="checkbox-square-borders-checkmark" />
      </label>
    </div>
    <br>
    <div>
      <input
        type="radio"
        class="radio-button"
      >
    </div>
    <br>
    <div>
      <label class="toggle-switch">
        <input type="checkbox">
        <div class="slider round" />
      </label>
    </div>
    <br>
    <v-select
      items="1,2,3"
      variant="solo"
      density="compact"
      :style="{width: '200px', color: '#332C45', border: '1px solid #EBEAEC'}"
      :menu-props="{ bottom: true, offsetY: true }"
    />
    <div
      class="custom-select"
      style="width: 120px"
    >
      <select id="select1">
        <option disabled>
          Выбери
        </option>
        <option>2</option>
        <option>sda</option>
        <option>sadegdrgdgdgd</option>
      </select>
      <!--      <label for="select1">Choose</label>-->
    </div>
    <br>
    <div
      class="custom-select-multiple"
      style="width: 180px"
    >
      <select multiple>
        <option disabled>
          Выбери день
        </option>
        <option>Вторник</option>
        <option>Среда</option>
        <option>Четверг</option>
      </select>
    </div>
    <br>
    <div
      class="multiselect-block"
      style="width: 200px"
    >
      <label
        for="select-1"
        class="field-multiselect"
      >Day</label>
      <input
        id="checkbox-1"
        class="multiselect-checkbox"
        type="checkbox"
      >
      <label
        for="checkbox-1"
        class="multiselect-label"
      />
      <select
        id="select-1"
        class="field-select"
        multiple
      >
        <option>Понедельник</option>
        <option>Вторник</option>
        <option>Среда</option>
        <option>Четверг</option>
      </select>
    </div>
    <br>
    <div class="textField">
      <input
        id="textfield"
        type="text"
        placeholder="Placeholder"
      >
      <h4 class="small-text-roboto-medium">
        Podskazka
      </h4>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent( {
 name: 'UITest'
})
</script>

<style scoped lang="scss">
@import "uikit-variables";
</style>
