/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  SET_INCREMENTGROUPS, ADD_INCREMENTGROUPS,
  ADD_INCREMENTGROUP_TO_STACK,
  DELETE_INCREMENTGROUP_FROM_STACK,
  EDIT_INCREMENTGROUP_IN_STACK,
  CLEAR_INCREMENTGROUPS
} from '@/store/incrementGroup/mutations'
import { RootState } from '@/store/state'
import {
  IncrementGroup, IncrementGroupListState
} from '@/store/incrementGroup/types'
import { IncrementGroupCreatePost, IncrementGroupEditPut } from '@/shared/api/incrementGroup/api-types'
import {
  all as incrementGroupAll,
  find as incrementGroupFind,
  create as incrementGroupCreate,
  update as incrementGroupUpdate,
  del as incrementGroupDel
} from '@/shared/api/incrementGroup/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type IncrementGroupListContext = ActionContext<IncrementGroupListState, RootState>

const initialState: IncrementGroupListState = {
  incrementGroups: [{
    id: 0,
    name: ''
  }]
}

const getters: GetterTree<IncrementGroupListState, RootState> = {
  incrementGroups (state: IncrementGroupListState): IncrementGroup[] {
    return state.incrementGroups
  }
}

const mutations: MutationTree<IncrementGroupListState> = {
  [SET_INCREMENTGROUPS] (state: IncrementGroupListState, incrementGroups: IncrementGroup[]) {
    state.incrementGroups = incrementGroups
  },
  [ADD_INCREMENTGROUPS] (state: IncrementGroupListState, incrementGroups: IncrementGroup[]) {
    state.incrementGroups = [...state.incrementGroups, ...incrementGroups]
  },
  [ADD_INCREMENTGROUP_TO_STACK] (state: IncrementGroupListState, incrementGroup: IncrementGroup) {
    state.incrementGroups = [incrementGroup, ...state.incrementGroups]
  },
  [DELETE_INCREMENTGROUP_FROM_STACK] (state: IncrementGroupListState, incrementGroup: IncrementGroup) {
    const index = state.incrementGroups.map(incrementGroup => incrementGroup.id).indexOf(incrementGroup.id)
    state.incrementGroups = [
      ...state.incrementGroups.slice(0, index),
      ...state.incrementGroups.slice(index + 1)
    ]
  },
  [EDIT_INCREMENTGROUP_IN_STACK] (state: IncrementGroupListState, incrementGroup: IncrementGroup) {
    const index = state.incrementGroups.map(incrementGroup => incrementGroup.id).indexOf(incrementGroup.id)
    if (index === -1) return
    state.incrementGroups = [
      ...state.incrementGroups.slice(0, index),
      incrementGroup,
      ...state.incrementGroups.slice(index + 1)
    ]
  },
  [CLEAR_INCREMENTGROUPS] (state: IncrementGroupListState) {
    state.incrementGroups = []
  }
}

const actions: ActionTree<IncrementGroupListState, RootState> = {
  async all (
    { commit, dispatch, state }: IncrementGroupListContext
  ): Promise<IncrementGroup[]> {
    try {
      const result = await incrementGroupAll()
      commit(SET_INCREMENTGROUPS, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async create (
    { commit, dispatch, state }: IncrementGroupListContext,
    data: IncrementGroupCreatePost
  ): Promise<IncrementGroup> {
    try {
      const result = await incrementGroupCreate(data)
      commit(ADD_INCREMENTGROUP_TO_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async find (
    { commit, dispatch, state }: IncrementGroupListContext,
    data: number
  ): Promise<IncrementGroup> {
    try {
      const result = await incrementGroupFind(data)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async update (
    { commit, dispatch, state }: IncrementGroupListContext,
    data: IncrementGroup
  ): Promise<IncrementGroup> {
    try {
      const result = await incrementGroupUpdate(data)
      commit(EDIT_INCREMENTGROUP_IN_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async del (
    { commit, dispatch, state }: IncrementGroupListContext,
    data: IncrementGroup
  ): Promise<IncrementGroup> {
    try {
      const result = await incrementGroupDel(data)
      commit(DELETE_INCREMENTGROUP_FROM_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const incrementGroups = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
