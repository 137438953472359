<template>
  <div class="filters-panel">
    <div class="filters-panel__row">
      <template v-if="filterOptions.placeFilter">
        <v-select
          label="Школа"
          class="filters-panel__filter"
          :items="filterOptions.placeItems"
          item-title="place.name"
          item-value="placeId"
          :loading="placeFilterLoading"
          :model-value="filterState[FilterNames.PLACE_FILTER]"
          :disabled="loadDisabled"
          @update:model-value="changeHandler(filterState[FilterNames.PLACE_FILTER], $event, FilterNames.PLACE_FILTER)"
        />
      </template>
      <template v-if="filterOptions.monthFilter">
        <div class="filters-panel__filter">
          <v-select
            label="Месяц"
            class="mt-0"
            :items="monthItems"
            item-title="name"
            item-value="id"
            :model-value="filterState[FilterNames.MONTH_FILTER]"
            :disabled="loadDisabled"
            @update:model-value="changeHandler(filterState[FilterNames.MONTH_FILTER], $event, FilterNames.MONTH_FILTER)"
          />
          <template v-if="filterOptions.periodFilter">
            <div style="display: flex; position: relative; max-width: 160px; top: -5px;">
              <v-menu
                ref="menu"
                v-model="dayPeriodOpen"
                v-model:return-value="dayPeriod"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                min-height="auto"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="getPeriodText"
                    label="Период"
                    prepend-icon="mdi-calendar"
                    readonly

                    v-bind="props"
                  />
                </template>
                <v-date-picker
                  title="Период"
                  v-model="filterState[FilterNames.PERIOD_FILTER]"
                  multiple="range"
                  hide-weekdays
                  hide-header
                  :min="minDayFrom"
                  :max="maxDayTo"
                >
                  <v-spacer />
                  <v-btn
                    variant="text"
                    color="primary"
                    @click="dayPeriodOpen = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                    variant="text"
                    color="primary"
                    @click="changeHandler(filterState[FilterNames.PERIOD_FILTER], filterState[FilterNames.PERIOD_FILTER], FilterNames.PERIOD_FILTER)"
                  >
                    ОК
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </template>
        </div>
      </template>
      <template v-if="filterOptions.yearFilter">
        <v-select
          label="Год"
          class="filters-panel__filter"
          :items="yearItems"
          item-title="name"
          item-value="id"
          :model-value="filterState[FilterNames.YEAR_FILTER]"
          :disabled="loadDisabled"
          @update:model-value="changeHandler(filterState[FilterNames.YEAR_FILTER], $event, FilterNames.YEAR_FILTER)"
        />
      </template>
      <template v-if="filterOptions.classFilter && filterOptions.classItems.length > 1">
        <v-select
          label="Класс"
          class="filters-panel__filter"
          :items="filterOptions.classItems"
          item-title="name"
          item-value="id"
          :model-value="filterState[FilterNames.CLASS_FILTER]"
          :disabled="loadDisabled"
          @update:model-value="changeHandler(filterState[FilterNames.CLASS_FILTER], $event, FilterNames.CLASS_FILTER)"
        />
      </template>
      <template v-if="filterOptions.productTypeFilter && filterOptions.productTypeItems.length > 1">
        <v-select
          label="Вид питания"
          class="filters-panel__filter"
          :items="filterOptions.productTypeItems"
          item-title="name"
          item-value="id"
          :model-value="filterState[FilterNames.PRODUCT_TYPE_FILTER]"
          @update:model-value="changeHandler(filterState[FilterNames.PRODUCT_TYPE_FILTER], $event, FilterNames.PRODUCT_TYPE_FILTER)"
        />
      </template>
      <template v-if="filterOptions.ratePlanFilter && filterOptions.ratePlanItems.length > 1">
        <v-select
          label="Тип льготы"
          class="filters-panel__filter"
          :items="filterOptions.ratePlanItems"
          item-title="name"
          item-value="id"
          :model-value="filterState[FilterNames.RATE_PLAN_FILTER]"
          @update:model-value="changeHandler(filterState[FilterNames.RATE_PLAN_FILTER], $event, FilterNames.RATE_PLAN_FILTER)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { mapActions } from 'vuex'
import moment from 'moment'

export enum FilterNames {
  PLACE_FILTER = 'placeFilter',
  CLASS_FILTER = 'classFilter',
  PRODUCT_TYPE_FILTER = 'productTypeFilter',
  RATE_PLAN_FILTER = 'ratePlanFilter',
  MONTH_FILTER = 'monthFilter',
  YEAR_FILTER = 'yearFilter',
  PERIOD_FILTER = 'periodFilter',
  INITIAL_STATE = 'initialState'
}

export interface FilterOptions {
  placeFilter: boolean;
  monthFilter: boolean;
  yearFilter: boolean;
  classFilter: boolean;
  ratePlanFilter: boolean;
  periodFilter?: boolean;
  productTypeFilter: boolean;
  placeItems: any[];
  classItems: any[];
  productTypeItems: any[];
  ratePlanItems: any[];
}

export interface FilterState {
  placeFilter: number;
  classFilter: number;
  productTypeFilter: number;
  ratePlanFilter: number;
  monthFilter: number;
  yearFilter: number;
  periodFilter: any[] | undefined;
  initialState: boolean;
}

export default defineComponent({
  name: 'FiltersPanel',
  components: {},
  props: {
    filterOptions: {
      type: Object as PropType<FilterOptions>,
      required: true
    },
    filterState: {
      type: Object as PropType<FilterState>,
      required: true
    }
  },
  data () {
    return {
      dayPeriod: [] as any,
      dayPeriodOpen: false,
      dayFrom: '',
      dayTo: '',
      minDayFrom: 0,
      maxDayTo: 0,
      FilterNames: FilterNames,
      monthItems: [
        { id: 0, name: 'Январь' },
        { id: 1, name: 'Февраль' },
        { id: 2, name: 'Март' },
        { id: 3, name: 'Апрель' },
        { id: 4, name: 'Май' },
        { id: 5, name: 'Июнь' },
        { id: 6, name: 'Июль' },
        { id: 7, name: 'Август' },
        { id: 8, name: 'Сентябрь' },
        { id: 9, name: 'Октябрь' },
        { id: 10, name: 'Ноябрь' },
        { id: 11, name: 'Декабрь' }
      ],
      yearItems: [
        { id: 2019, name: '2019' },
        { id: 2020, name: '2020' },
        { id: 2021, name: '2021' },
        { id: 2022, name: '2022' },
        { id: 2023, name: '2023' },
        { id: 2024, name: '2024' }
      ],
      placeFilterLoading: false,
      loadDisabled: false,
      baseDayPeriodRules: [
        // 'Проверка является ли числом'
        (value: any) => !isNaN(+`${value}`.replace(/,/g, '.')) || 'Должно быть число'
      ]
    }
  },
  computed: {
    getPeriodText () {
      let formatedPeriodText
      if (this.filterState[FilterNames.PERIOD_FILTER] !==  undefined) {
        if (this.filterState[FilterNames.PERIOD_FILTER]?.length >= 1) {
          formatedPeriodText = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('D') + '-' + moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).format('D')
        } else {
          formatedPeriodText = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).startOf('month').format('D') + '-' + moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).endOf('month').format('D')
        }

      }
      return formatedPeriodText
    },
    dayPeriodRule (): string | boolean {
      // if (isNaN(+`${+this.dayFrom}`.replace(/,/g, '.')) || isNaN(+`${+this.dayTo}`.replace(/,/g, '.'))) {
      //   return 'Должно быть число'
      // }
      if (+this.dayFrom > +this.dayTo) {
        return 'Некорректный промежуток'
      }
      return true
    },
    minDayLengthRule (): string | boolean {
      // Дополнительная валидация нижней границы
      if (+this.dayFrom < 1 || +this.dayFrom > +this.maxDayTo) {
        return `Допустимо с ${1} по ${this.maxDayTo}`
      }
      return true
    },
    maxDayLengthRule (): string | boolean {
      // Дополнительная валидация верхней границы
      if (+this.dayTo < 1 || +this.dayTo > +this.maxDayTo) {
        return `Допустимо с ${1} по ${this.maxDayTo}`
      }
      return true
    }
  },
  beforeMount () {
    this.minDayFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).startOf('month').format('YYYY-MM-DD')
    this.maxDayTo = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).endOf('month').format('YYYY-MM-DD')
    this.filterState[FilterNames.PERIOD_FILTER] = [this.minDayFrom, this.maxDayTo]
    // this.dayPeriod = [this.minDayFrom, this.maxDayTo]
    this.dayFrom = '1'
    this.dayTo = `${this.maxDayTo}`
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    handleInvalid () {
      console.log('invalid')
    },
    changeHandler (oldValue: any, newValue: any, filterType: string) {
      if (filterType === FilterNames.MONTH_FILTER && this.filterOptions.periodFilter) {
        this.updateDefaultPeriod(newValue)
      } else if (filterType === FilterNames.YEAR_FILTER) {
        this.$emit('filterChangedState', filterType, oldValue, newValue)
        this.updateDefaultPeriodByYear()
      }
      if (filterType === FilterNames.PERIOD_FILTER) {
        this.dayPeriodOpen = false
      }
      this.$emit('filterChangedState', filterType, oldValue, newValue)
    },
    updateDefaultPeriod (monthFilter: number) {
      this.minDayFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${monthFilter + 1}-1`).startOf('month').format('YYYY-MM-DD')
      this.maxDayTo = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${monthFilter + 1}-1`).endOf('month').format('YYYY-MM-DD')
      this.$emit('filterChangedState', FilterNames.PERIOD_FILTER, this.filterState.periodFilter, [this.minDayFrom, this.maxDayTo])
    },
    updateDefaultPeriodByYear () {
      this.minDayFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).startOf('month').format('YYYY-MM-DD')
      this.maxDayTo = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).endOf('month').format('YYYY-MM-DD')
      this.$emit('filterChangedState', FilterNames.PERIOD_FILTER, this.filterState.periodFilter, [this.minDayFrom, this.maxDayTo])
    }
  }
})
</script>

<style scoped lang="scss">
.filters-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;;

  .filters-panel__row {
    display: flex;

    .filters-panel__filter {
      flex: 1 1 0;
      width: 0;
    }

    .filters-panel__filter:not(:first-child):not(:last-child) {
      margin: 4px 4px 0 4px;
    }
  }
}
</style>
