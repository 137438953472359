import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceFoodWarehousesFindPost, PlaceFoodWarehouseRemovePost, PlaceFoodWarehouseCreatePost } from './api-types'

const routePlaceFoodWarehousesFind = '/placeFoodWarehouses/findWithFoodWarehousesAndDevices'
const routePlaceFoodWarehouseRemove = '/placeFoodWarehouses/update'
const routePlaceFoodWarehouseCreate = '/placeFoodWarehouses/create'

export const placeFoodWarehousesFind = async (data: PlaceFoodWarehousesFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceFoodWarehousesFind, data)
  return result.data.data
}

export const placeFoodWarehouseRemove = async (data: PlaceFoodWarehouseRemovePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceFoodWarehouseRemove, data)
  return result.data.data
}

export const placeFoodWarehouseCreate = async (data: PlaceFoodWarehouseCreatePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceFoodWarehouseCreate, data)
  return result.data.data
}
