<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-select
              v-model="selectedTown"
              label="Регион"
              :items="towns"
              item-value="id"
              item-title="name"
              density="compact"
            />
          </v-col>
          <v-col>
            <v-btn
              name="increment-group-edit-button"
              class="btn"
              block
              @click="changeProduct()"
            >
              Выбрать
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="isLoading"
        cols="10"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
      <v-col v-if="!isLoading && products.length > 0">
        <v-row>
          <v-col cols="4">
            <table class="report-rateplan-day">
              <tr
                v-for="product in products"
                :key="product.id"
              >
                <td style="padding-left: 4px; padding-right: 4px;">
                  <input
                    v-model="product.selected"
                    type="checkbox"
                  >
                  {{ product.name }}
                </td>
                <td style="padding-left: 4px; padding-right: 4px;">
                  <v-text-field
                    v-model="product.price"
                    label="Цена"
                    style="width: 100px"
                    @update:model-value="product.selected = true"
                  />
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="3">
            <table>
              <tr>
                <td>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :offset="40"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        :model-value="formatDate(priceDate)"
                        label="Дата"
                        prepend-icon="mdi-calendar"
                        readonly
                        style="width: 170px"
                        v-bind="props"
                      />
                    </template>
                    <v-date-picker
                      hide-weekdays
                      title="Дата"
                      v-model="priceDate"
                      @input="menu1 = false"
                    />
                  </v-menu>
                  <div>
                    <v-text-field
                      v-model="time"
                      label="Время"
                      style="float: left; width: 100px"
                    />
                    <v-btn @click="setTimeNow()">
                      <v-icon
                        size="medium"
                        color="red-darken-2"
                      >
                        mdi-clock
                      </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    name="increment-group-edit-button"
                    class="btn"
                    block
                    @click="saveProductPrices"
                  >
                    Установить
                  </v-btn>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <table class="report-rateplan-day">
              <thead>
                <tr>
                  <th rowspan="2" />
                  <th
                    rowspan="2"
                    style="padding-left: 4px !important; padding-right: 4px !important;"
                  >
                    <v-text-field
                      v-model="placeSearchTerm"
                      placeholder="Школа"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th
                    rowspan="2"
                    style="padding-left: 4px !important; padding-right: 4px !important;"
                  >
                    Район
                    <v-select
                      v-model="searchDistrict"
                      :items="districts"
                      item-value="id"
                      item-title="name"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th
                    rowspan="2"
                    style="padding-left: 4px !important; padding-right: 4px !important;"
                  >
                    Комбинат
                    <v-select
                      v-model="searchCustomer"
                      :items="customers"
                      item-value="id"
                      item-title="name"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th
                    :colspan="products.length"
                    style="padding-left: 4px !important; padding-right: 4px !important;"
                  >
                    Цена
                  </th>
                  <th
                    rowspan="2"
                    style="padding-left: 4px !important; padding-right: 4px !important;"
                  />
                </tr>
                <tr>
                  <th
                    v-for="(product, i) in products"
                    :key="i"
                    style="padding-left: 4px !important; padding-right: 4px !important;"
                  >
                    {{ product.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(placeProduct, i) in placeProducts">
                  <tr
                    v-if="placeProduct.visible == 1"
                    :key="i"
                  >
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      <input
                        v-model="placeProduct.checked"
                        type="checkbox"
                      >
                    </td>
                    <td
                      :class="getPlaceColor(placeProduct)"
                      style="max-width: 300px; padding-left: 4px !important; padding-right: 4px !important;"
                    >
                      {{ placeProduct.placeName }}
                    </td>
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      {{ placeProduct.placeDistrict }}
                    </td>
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      {{ placeProduct.placeCustomer }}
                    </td>
                    <td
                      v-for="(product, i) in products"
                      :key="i"
                      :class="getPriceColor(placeProduct, product)"
                      style="padding-left: 4px !important; padding-right: 4px !important;"
                    >
                      {{ getPlaceProductPrice(placeProduct, product) }}
                    </td>
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      {{ getPlaceProductType(placeProduct) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import moment from 'moment'
import { AxiosError } from 'axios'
import { findbyplaceswithprices } from '@/shared/api/placeCustomerProductList/api-requests'
import { savePrices } from '@/shared/api/placeProductPrices/api-requests'
import { placeFindByTypeId } from '@/shared/api/places/api-requests'
import Swal from 'sweetalert2'

type BaseData = {
  towns: any;
  time: any;
  selectedTown: any;
  searchDistrict: any;
  searchCustomer: any;
  districts: any;
  customers: any;
  placeSearchTerm: any;
  price: any;
  products: any;
  placeProducts: any;
  loading: boolean;
  selectedPlaceCustomerProduct: any;
  selected: any;
  snack: boolean;
  snackColor: string;
  snackText: string;
  disabled: any;
  delButLoading: any;
  addProductPrice: number;
  addProductButtonDisabled: boolean;
  addProductColor: string;
  addProductLoading: boolean;
  isLoading: boolean;
  menu1: boolean;
  priceDate: any;
}
export default defineComponent({
  components: {

  },
  data (): BaseData {
    return {
      towns: [],
      time: '00:00:00',
      selectedTown: '1023',
      searchDistrict: null,
      searchCustomer: null,
      districts: [],
      customers: [],
      placeSearchTerm: '',
      price: 0,
      products: [],
      placeProducts: [],
      snack: false,
      snackColor: '',
      snackText: '',
      loading: true,
      selectedPlaceCustomerProduct: null,
      selected: [],
      disabled: [],
      delButLoading: [],
      addProductButtonDisabled: true,
      addProductPrice: 0.00,
      addProductColor: 'normal',
      addProductLoading: false,
      isLoading: false,
      menu1: false,
      priceDate: moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async getTowns () {
      const placeFindPost = {
        typeId: 3
      }
      const result = await placeFindByTypeId(placeFindPost)
      this.towns = JSON.parse(JSON.stringify(result))
    },
    async changeProduct () {
      try {
        this.isLoading = true
        this.searchDistrict = null
        this.searchCustomer = null
        this.placeSearchTerm = ''
        this.districts = []
        this.customers = []
        let idistrict = 0
        let icustomer = 0
        this.districts.push({
          id: null,
          name: 'Все'
        })
        this.customers.push({
          id: null,
          name: 'Все'
        })
        this.products = []
        this.placeProducts = []
        const customerPlaceFindPost = {
          parentPlaceId: this.selectedTown,
          date: moment().format('YYYY-MM-DD hh:mm:ss')
        }
        const result: any = await findbyplaceswithprices(customerPlaceFindPost)
        const ps = JSON.parse(JSON.stringify(result.products))
        this.products = []
        ps.forEach((element: any) => {
          this.products.push({
            id: element.id,
            name: element.name,
            selected: false,
            price: 0
          })
        })
        const pps = JSON.parse(JSON.stringify(result.placeProducts))
        pps.forEach((element: any) => {
          element.checked = false
          if (element.placeDistrict === null) {
            element.placeDistrict = 'Не указан'
          }
          const district = this.districts.find((desc: any) => desc.name === element.placeDistrict)
          if (district === undefined) {
            idistrict = idistrict + 1
            this.districts.push({
              id: idistrict,
              name: element.placeDistrict
            })
          }
          const customer = this.customers.find((desc: any) => desc.name === element.placeCustomer)
          if (customer === undefined) {
            icustomer = icustomer + 1
            this.customers.push({
              id: icustomer,
              name: element.placeCustomer
            })
          }
          element.visible = 1
          this.placeProducts.push(JSON.parse(JSON.stringify(element)))
        })
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getPlaceColor (place: any) {
      let color = ''
      if (Number(place.placeMajorFoodTypeSetting) === 2) {
        color = 'text-red'
      }
      return color
    },
    getPlaceProductType (place: any) {
      let color = 'Комплексное'
      if (Number(place.placeMajorFoodTypeSetting) === 2) {
        color = 'Двухразовое'
      }
      return color
    },
    setTimeNow () {
      this.time = moment().format('HH:mm:ss')
    },
    getPriceColor (place: any, product: any) {
      let color = ''
      place.products.forEach((el: any) => {
        if (Number(el.id) === Number(product.id)) {
          if (Number(el.priceType) === 2) {
            color = 'text-red'
          }
        }
      })
      return color
    },
    getPlaceProductPrice (placeProduct: any, product: any) {
      let price = null
      placeProduct.products.forEach((el: any) => {
        if (Number(el.id) === Number(product.id)) {
          price = el.price
        }
      })
      return price
    },
    getProductSelected (id: number) {
      let selected = ''
      if (id === this.selectedPlaceCustomerProduct) {
        selected = 'primary'
      }
      return selected
    },
    async save (places: any) {
      const result2 = await savePrices({ placeProductPrices: places })
      let errorMessage = ''
      const resultPlaceProducts = JSON.parse(JSON.stringify(result2))
      resultPlaceProducts.forEach((element: any) => {
        const i = this.placeProducts.findIndex((el: any) => {
          return Number(el.place) === Number(element.place)
        })
        if (element.placeProductId !== null) {
          if (i > -1) {
            const j = this.placeProducts[i].products.findIndex((el: any) => {
              return Number(el.productId) === Number(element.productId)
            })
            this.placeProducts[i].products[j].price = element.price
            this.placeProducts[i].products[j].priceType = element.priceType
          }
        } else {
          errorMessage = errorMessage + 'Не удалось обновить цену в ' + this.placeProducts[i].placeName + '\n'
        }
      })
      this.placeProducts.forEach((element: any) => {
        element.checked = false
      })
      if (errorMessage.length > 0) {
        alert(errorMessage)
      }
    },
    async saveProductPrices () {
      try {
        this.isLoading = true
        const placeProducts = this.placeProducts.filter((element: any) => { return element.checked === true })
        const places: any = []
        placeProducts.forEach((element: any) => {
          this.products.forEach((product: any) => {
            if (product.selected === true) {
              const productId = element.products.find((pr: any) => { return Number(pr.id) === Number(product.id) })
              if (productId.productId !== null) {
                places.push({
                  placeId: element.place,
                  productId: productId.productId,
                  price: product.price,
                  timestamp: moment(this.priceDate).format('YYYY-MM-DD') + ' ' + this.time
                })
              }
            }
          })
        })
        let table = ''
        table = table + '<thead><tr><td>Школа</td>'
        this.products.forEach((product: any) => {
          if (product.selected === true) {
            table = table + '<td>' + product.name + ' - ' + product.price + '</td>'
          }
        })
        table = table + '</tr></thead>'
        table = table + '<tbody>'
        placeProducts.forEach((place: any) => {
          table = table + '<tr><td>' + place.placeName + '</td>'
          this.products.forEach((product: any) => {
            if (product.selected === true) {
              const productId = place.products.find((pr: any) => { return Number(pr.id) === Number(product.id) })
              if (productId.productId !== null) {
                table = table + '<td style="background-color:#A5D6A7;">+</td>'
              } else {
                table = table + '<td style="background-color:#EF9A9A;">-</td>'
              }
            }
          })
          table = table + '</tr>'
        })
        table = table + '</tr></tbody>'
        Swal.fire({
          title: '',
          html: '<h2>Будут установлены цены:</h2><br><table class="report-ratePlan-day" style="border: 1px solid black; width: 100%;">' + table + '</table><br>Продолжить?',
          showDenyButton: true,
          confirmButtonText: 'Да',
          denyButtonText: 'Нет'
        }).then((result) => {
          if (result.isConfirmed) {
            this.save(places)
          } else if (result.isDenied) {

          }
        })
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    savePrice (id: number) {
      Swal.fire({
        title: '',
        html: '<h2>Изменить цену во всех талонах <br> классов?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет, только в этом талоне'
      }).then((result) => {
        if (result.isConfirmed) {

        } else if (result.isDenied) {

        }
      })
    },
    sortByDate (a: any, b: any) {
      if (a.timestampFrom < b.timestampFrom) return 1
      else return -1
    },
    sortById (a: any, b: any) {
      if (a.productId < b.productId) return -1
      else return 1
    },
    savePriceProductAdd () {
      if (!isNaN(this.addProductPrice) && this.selectedPlaceCustomerProduct) {
        this.addProductButtonDisabled = false
      }
    },
    changeProductSelection (product: any) {
      const i = this.products.findIndex((el: any) => {
        return Number(el.id) === Number(product.id)
      })
      this.products[i].selected = true
    },
    getProductSelection (product: any) {
      const i = this.products.findIndex((el: any) => {
        return Number(el.id) === Number(product.id)
      })
      return this.products[i].selected
    },
    tableChange () {
      this.placeProducts.forEach((element: any) => {
        let searchDistrict = ''
        if (this.searchDistrict !== null) {
          searchDistrict = this.districts.find((desc: any) => desc.id === this.searchDistrict).name
        }
        let searchCustomer = ''
        if (this.searchCustomer !== null) {
          searchCustomer = this.customers.find((desc: any) => desc.id === this.searchCustomer).name
        }
        let visible = 0
        let districtVisible = 0
        if (searchDistrict === '') {
          districtVisible = 1
        } else {
          if (searchDistrict === element.placeDistrict) {
            districtVisible = 1
          }
        }
        let customerVisible = 0
        if (searchCustomer === '') {
          customerVisible = 1
        } else {
          if (searchCustomer === element.placeCustomer) {
            customerVisible = 1
          }
        }
        let placeVisible = 0
        if (this.placeSearchTerm === '') {
          placeVisible = 1
        } else {
          if (element.placeName.toLowerCase().indexOf(this.placeSearchTerm.toLowerCase()) >= 0) {
            placeVisible = 1
          }
        }
        if (placeVisible === 1 && districtVisible === 1 && customerVisible === 1) {
          visible = 1
        }
        element.visible = visible
      })
    }
  },
  mounted () {
    this.getTowns()
  }
})
</script>

<style lang='scss'>
    .text-red {
        color: red;
    }
</style>
