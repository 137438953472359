<template>
  <transition name="back-to-top-fade">
    <div
      v-show="visible"
      class="vue-back-to-top"
      :style="`bottom:${bottom}px;right:${right}px;`"
      @click="backToTop"
    >
      <slot>
        <div class="default">
          <span>&#x2191;</span>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    text: {
      type: String,
      default: 'Back To Top'
    },
    visibleOffset: {
      type: [String, Number],
      default: 600
    },
    right: {
      type: Number,
      default: 30
    },
    bottom: {
      type: Number,
      default: 50
    }
  },
  data () {
    return {
      visible: false
    }
  },
  /**
   * Catch window scroll event
   * @return {void}
   */
  created () {
    const catchScroll = () => {
      this.visible = window.pageYOffset > parseInt(this.visibleOffset)
    }
    window.smoothscroll = () => {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll)
        window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5))
      }
    }
    window.onscroll = catchScroll
  },
  methods: {
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop () {
      window.smoothscroll()
    }
  }
}
</script>
