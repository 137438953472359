<template>
  <v-container>
    <div>
      <v-row class="justify-content-center">
        <v-col
          class="d-flex align-center"
          cols="3"
          sm="3"
        >
          <v-btn
            class="button-wrap"
            elevation="2"
            :to="{ name: 'ReportSalesByProduts', params: { propDateFrom: dateFrom, propPlaceId: placeId } }"
          >
            НАЗАД
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && productSales.length > 0"
        >
          <v-table class="report-rateplan-day">
            <template #default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    colspan="100%"
                  >
                    Отчет по блюду {{ productSales[0].productName }}
                  </th>
                </tr>
                <tr>
                  <th>Школа</th>
                  <th>Количество</th>
                  <th>Цена</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(productSale, i) in productSales"
                  :key="i"
                >
                  <td>{{ productSale.placeName }}</td>
                  <td>{{ productSale.quantity }}</td>
                  <td>{{ productSale.price }}</td>
                  <td>{{ productSale.sum }}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>
                    Итого:
                  </th>
                  <th
                    colspan="4"
                    style="text-align: right"
                  >
                    {{ saleSum.toFixed(2) }}
                  </th>
                </tr>
              </thead>
            </template>
          </v-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { findGroupByPlaces } from '@/shared/api/salelist/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  isLoading: boolean;
  places: any;
  products: any;
  sales: any;
  saleSum: number;
  productSales: any;
  saleGroupByPlacesPost: any;
  saleGroupByPlaces: any;
}

export default defineComponent({
  components: {
  },
  props: {
    placeId: {
      type: String,
      required: true
    },
    productId: {
      type: String,
      required: true
    },
    dateFrom: {
      type: String,
      required: true
    },
    dateTo: {
      type: String,
      required: true
    },
    accountTypeId: {
      type: String,
      required: true
    },
    discount: {
      type: String,
      required: true
    },
    cash: {
      type: String,
      required: true
    },
    vending: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      isLoading: false,
      places: [],
      products: [],
      sales: [],
      saleSum: 0,
      productSales: [],
      saleGroupByPlacesPost: {
        productId: null,
        discountId: null,
        timestampFrom: null,
        timestampTo: null
      },
      saleGroupByPlaces: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadSales () {
      try {
        this.isLoading = true
        this.saleGroupByPlacesPost.placeId = this.placeId
        this.saleGroupByPlacesPost.productId = this.productId
        if (this.cash === 'true') {
          this.saleGroupByPlacesPost.discountId = 28
        }
        this.saleGroupByPlacesPost.timestampFrom = moment(this.dateFrom).format('YYYY-MM-DD')
        this.saleGroupByPlacesPost.timestampTo = moment(this.dateTo).add('days', 1).format('YYYY-MM-DD')
        const result = await findGroupByPlaces(this.saleGroupByPlacesPost)
        this.saleGroupByPlaces = JSON.parse(JSON.stringify(result))
        if (Array.isArray(this.saleGroupByPlaces.sales)) {
          this.sales = this.saleGroupByPlaces.sales
        }
        if (Array.isArray(this.saleGroupByPlaces.products)) {
          this.products = this.saleGroupByPlaces.products
        }
        if (Array.isArray(this.saleGroupByPlaces.places)) {
          this.places = this.saleGroupByPlaces.places
        }
        if (this.sales.length > 0) {
          this.sales.forEach((element: any) => {
            const product = this.products.find((el: any) => el.id === element.productId)
            const place = this.places.find((el: any) => el.id === element.placeId)
            const sale = { productName: product.name, productId: element.productId, placeName: place.name, price: Number(element.price).toFixed(2), quantity: Number(element.quantity).toFixed(2), discount: Number(element.discount).toFixed(2), sum: Number(element.sum).toFixed(2) }
            if (this.cash === 'true') {
              if (this.vending === 'true' && product.flags === '1') {
                this.productSales.push(sale)
                this.saleSum = this.saleSum + Number(sale.sum)
              } else if (this.vending === 'false' && product.flags !== '1') {
                this.productSales.push(sale)
                this.saleSum = this.saleSum + Number(sale.sum)
              }
            } else {
              if (this.discount === 'true' && Number(sale.discount) !== 0) {
                if (this.vending === 'true' && product.flags === '1') {
                  this.productSales.push(sale)
                  this.saleSum = this.saleSum + Number(sale.sum)
                } else if (this.vending === 'false' && product.flags !== '1') {
                  this.productSales.push(sale)
                  this.saleSum = this.saleSum + Number(sale.sum)
                }
              } else if (this.discount === 'false' && Number(sale.discount) === 0) {
                if (this.vending === 'true' && product.flags === '1') {
                  this.productSales.push(sale)
                  this.saleSum = this.saleSum + Number(sale.sum)
                } else if (this.vending === 'false' && product.flags !== '1') {
                  this.productSales.push(sale)
                  this.saleSum = this.saleSum + Number(sale.sum)
                }
              }
            }
          })
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    clearData () {
      this.places = []
      this.products = []
      this.sales = []
      this.saleSum = 0
      this.productSales = []
    },
    load () {
      this.clearData()
      this.loadSales()
    }
  },
  mounted () {
    this.isLoading = false
    this.load()
  }
})
</script>

<style lang="scss"></style>
