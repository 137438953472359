export const printer = (elementId: string, styleFileName: string) => {
  const printContent = document.getElementById(elementId)
  const WinPrint = window.open('')
  if (WinPrint && printContent) {
    WinPrint.document.write(`
      <html>
        <head>
          <title></title>
        </head>
        <body>
        <div class="">
        <input class="print-button" type="button" value="Печать" onclick="print()">
          ${printContent.innerHTML}
        </div>
        </body>
        </html>
     `)
    const link = WinPrint.document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('type', 'text/css')
    link.setAttribute('href', `/css/${styleFileName}`)
    WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
    WinPrint.document.close()
  }
}
