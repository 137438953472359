<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row
      justify="center"
      align="center"
      style="margin-top: 0px;"
    >
      <v-col v-if="loading">
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
      </v-col>
      <v-col v-else>
        <v-row>
          <v-col style="position: relative; top: -10px; left: 10px;">
            <v-text-field
              v-model="ticketId"
              :rules="ticketRules"
              :counter="10"
              label="Номер заявки"
              required
              hide-details
            />
          </v-col>
          <v-col>
            <v-btn
              color="blue-darken-1"
              variant="text"
              :disabled="isInvalidTicketRules"
              @click="getHistory()"
            >
              Получить
            </v-btn>
          </v-col>
          <v-col v-if="canUnbind">
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="unbindTicket()"
            >
              Забрать
            </v-btn>
          </v-col>
        </v-row>
        <v-table class="green-header-table">
          <tbody>
            <template v-for="(item, index) in telegramSupportHistoryResponse.historyItems">
              <tr
                v-if="true"
                :key="index"
                :style="item.type == 2 ? 'background-color: antiquewhite !important;' : ''"
              >
                <td>
                  <b>Номер заявки</b>: {{ item.ticketId }}<br>
                  <b>Дата, время:</b>: {{ dateText(item.date) }}<br>
                  <b>Имя</b>: {{ item.senderName }}<br>
                  <template v-if="item.messageType == 4 && item.fileId != null && item.messageType != null">
                    <b>Файл</b>: <a @click="sendFile(item.fileId, item.messageType)">Посмотреть</a>
                  </template>
                  <template v-else-if="item.messageType == 5 && item.fileId != null && item.messageType != null">
                    <b>Фото</b>: <a @click="sendFile(item.fileId, item.messageType)">Посмотреть</a>
                  </template>
                  <template v-else>
                    <b>Сообщение</b>: {{ item.message }}
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import {
  telegramSupportGetFileHistoryByTicket,
  telegramSupportGetHistoryByTicket,
  telegramSupportServiceUnbindTicket
} from '@/shared/api/telegram/api-requests'
import {
  TelegramSupportGetFileHistoryByTicketPost,
  TelegramSupportGetHistoryByTicketPost,
  TelegramSupportServiceUnbindTicketPost
} from '@/shared/api/telegram/api-types'
import { TelegramSupportHistoryItem, TelegramSupportHistoryResponse } from '@/store/telegram/types'
import moment from 'moment'
import { AxiosError } from 'axios'

type BaseData = {
  token: string;
  loading: boolean;
  error: boolean;
  ticketId: number;
  ticketStatus: number;
  errorMessage: string;
  errorMessageUnknown: string;
  chatId: number;
  ticketRules: Array<any>;
  WebApp: any;
  telegramSupportHistoryResponse: TelegramSupportHistoryResponse;
  telegramSupportGetHistoryByTicketPost: TelegramSupportGetHistoryByTicketPost;
  telegramSupportGetFileHistoryByTicketPost: TelegramSupportGetFileHistoryByTicketPost;
  telegramSupportServiceUnbindTicketPost: TelegramSupportServiceUnbindTicketPost;
}

export default defineComponent({
  props: {
    tokenProp: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      token: '',
      loading: true,
      error: false,
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Произошла ошибка, попробуйте снова позже',
      chatId: 0,
      ticketId: 0,
      ticketStatus: 0,
      WebApp: null,
      ticketRules: [
        (value: any) => {
          if (value) return true
          return 'Пустое поле.'
        },
        (value: any) => {
          if (value?.length <= 10) return true
          return 'Максимум 10 символов.'
        },
        (value: any) => {
          if (!isNaN(value)) return true
          return 'Должно быть число.'
        }
      ],
      telegramSupportGetHistoryByTicketPost: {
        token: '',
        chatId: 0,
        ticketId: 0
      },
      telegramSupportGetFileHistoryByTicketPost: {
        token: '',
        chatId: 0,
        fileId: '',
        type: 0
      },
      telegramSupportServiceUnbindTicketPost: {
        token: '',
        chatId: 0,
        ticketId: 0
      },
      telegramSupportHistoryResponse: {
        historyItems: []
      }
    }
  },
  computed: {
    isInvalidTicketRules () {
      let result = false
      this.ticketRules.map((fn: any) => {
        if (fn(this.ticketId) !== true) {
          result = true
        }
      })
      return result
    },
    canUnbind () {
      if (this.ticketStatus === 1) {
        return true
      }
      return false
    }
  },
  beforeCreate () {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-web-app.js'
    script.defer = true
    document.head.appendChild(script)
  },
  mounted () {
    if (this.tokenProp) {
      this.token = this.tokenProp
      let timerCount = 0
      const timer = setInterval(() => {
        timerCount++
        if (window.Telegram !== undefined) {
          const WebApp = window.Telegram.WebApp
          if (WebApp.initDataUnsafe !== undefined) {
            if (WebApp.initDataUnsafe.user !== undefined) {
              this.chatId = WebApp.initDataUnsafe.user.id
              this.WebApp = WebApp
            }
          }
          if (this.chatId !== 0) {
            this.loading = false
            clearInterval(timer)
          }
        }
        if (timerCount > 50) {
          this.error = true
          clearInterval(timer)
        }
      }, 1000)
    }
  },
  methods: {
    async telegramSupportGetDetailsByTicket () {
      try {
        this.loading = true
        this.ticketStatus = 0
        this.telegramSupportGetHistoryByTicketPost.ticketId = this.ticketId
        this.telegramSupportGetHistoryByTicketPost.token = this.token
        this.telegramSupportGetHistoryByTicketPost.chatId = this.chatId

        this.telegramSupportServiceUnbindTicketPost.ticketId = this.telegramSupportGetHistoryByTicketPost.ticketId

        await telegramSupportGetHistoryByTicket(this.telegramSupportGetHistoryByTicketPost)
          .then((result: TelegramSupportHistoryResponse) => {
            this.telegramSupportHistoryResponse = result
            this.telegramSupportHistoryResponse.historyItems.forEach((element: TelegramSupportHistoryItem) => {
              if (element.status !== null) {
                this.ticketStatus = element.status
              }
            })
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    async sendFile (fileId: any, type: any) {
      try {
        this.loading = true
        this.telegramSupportGetFileHistoryByTicketPost.token = this.token
        this.telegramSupportGetFileHistoryByTicketPost.chatId = this.chatId
        this.telegramSupportGetFileHistoryByTicketPost.fileId = fileId
        this.telegramSupportGetFileHistoryByTicketPost.type = type
        await telegramSupportGetFileHistoryByTicket(this.telegramSupportGetFileHistoryByTicketPost)
          .then((result: boolean) => {
            console.log(result)
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    async unbindTicket () {
      try {
        this.loading = true
        this.telegramSupportServiceUnbindTicketPost.token = this.token
        this.telegramSupportServiceUnbindTicketPost.chatId = this.chatId
        await telegramSupportServiceUnbindTicket(this.telegramSupportServiceUnbindTicketPost)
          .then((result: any) => {
            this.ticketStatus = 0
            this.WebApp.close()
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    getHistory () {
      this.telegramSupportGetDetailsByTicket()
    },
    dateText (date: any) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    catchError (error: any) {
      const err = error as AxiosError
      this.errorMessage = this.errorMessageUnknown
      if (err.response && err.response.data.errorMessage !== '') {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    }
  }
})
</script>
