<template>
  <v-container fluid>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedMonth"
          :items="monthes"
          item-title="name"
          item-value="id"
          label="Месяц"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedYear"
          :items="years"
          item-title="name"
          item-value="id"
          label="Год"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="1"
        sm="1"
      >
        <v-btn
          :is-loading="isLoading"
          class="mx-2"
          size="large"
          color="teal"
          @click="openCalendarDialog()"
        >
          <v-icon>
            mdi-calendar
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="loadPlaceCounters()"
        >
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="places.length > 0">
      <v-col
        cols="2"
      >
        <v-select
          v-model="selectedReportType"
          class="ma-2"
          :items="reportTypes"
          item-title="name"
          item-value="id"
          label="Параметры отображения"
          @update:model-value="placeCountersUpdate()"
        />
      </v-col>
      <v-col
        v-if="selectedReportType == 3"
        cols="5"
      >
        <v-chip
          color="red-lighten-3"
          class="ma-2"
          label
        >
          &lt;<input
            v-model="redAllCountPerc"
            type="text"
          >%
        </v-chip>
        <v-chip
          class="ma-2"
          color="lime-lighten-3"
          label
        >
          &lt;<input
            v-model="yellowAllCountPerc"
            type="text"
          >%
        </v-chip>
        <v-chip
          class="ma-2"
          color="green-lighten-3"
          label
        >
          &gt;=<input
            v-model="yellowAllCountPerc"
            type="text"
          >%
        </v-chip>
      </v-col>
      <v-col
        v-if="selectedReportType == 1"
        cols="5"
      >
        <v-chip
          color="red-lighten-3"
          class="ma-2"
          label
        >
          &lt;<input
            v-model="redUserRfIdSaleCountPerc"
            type="text"
          >%
        </v-chip>
        <v-chip
          class="ma-2"
          color="lime-lighten-3"
          label
        >
          &lt;<input
            v-model="yellowUserRfIdSaleCountPerc"
            type="text"
          >%
        </v-chip>
        <v-chip
          class="ma-2"
          color="green-lighten-3"
          label
        >
          &gt;=<input
            v-model="yellowUserRfIdSaleCountPerc"
            type="text"
          >%
        </v-chip>
      </v-col>
      <v-col
        v-if="selectedReportType == 2"
        cols="5"
      >
        <v-chip
          color="red-lighten-3"
          class="ma-2"
          label
        >
          &lt;<input
            v-model="redUserSaleCountPerc"
            type="text"
          >%
        </v-chip>
        <v-chip
          class="ma-2"
          color="lime-lighten-3"
          label
        >
          &lt;<input
            v-model="yellowUserSaleCountPerc"
            type="text"
          >%
        </v-chip>
        <v-chip
          class="ma-2"
          color="green-lighten-3"
          label
        >
          &gt;=<input
            v-model="yellowUserSaleCountPerc"
            type="text"
          >%
        </v-chip>
      </v-col>
      <v-col
        cols="2"
      >
        <v-select
          v-model="selectedSortPercent"
          class="ma-2"
          :items="sortPercents"
          item-title="name"
          item-value="id"
          label="Сортировать по"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <v-btn
          class="ma-2"
          color="primary"
          :is-loading="isLoading"
          @click="sortPlaceCounters()"
        >
          Применить
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="isLoading"
      class="justify-content-center"
    >
      <v-col
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col
        v-if="!isLoading && placeCounters.length > 0"
        cols="12"
      >
        <v-table
          class="report-rateplan-day"
          dense
        >
          <template #default>
            <thead>
              <tr>
                <th
                  class="bg-green-darken-4 text-center text-white"
                  colspan="100%"
                >
                  % использования карт
                </th>
              </tr>
              <tr>
                <th style="text-align: center; font-size: 12px !important; max-width: 200px !important;">
                  Район
                  <v-select
                    v-model="searchDistrict"
                    :items="districts"
                    item-value="id"
                    item-title="name"
                    density="compact"
                    @update:model-value="tableChange"
                  />
                </th>
                <th style="text-align: center; font-size: 12px !important; max-width: 200px !important;">
                  ОУ
                </th>
                <th style="text-align: center; font-size: 12px !important; max-width: 200px !important;" />
                <template
                  v-for="(day, j) in days"
                  :key="j"
                >
                  <th
                    style="text-align: center; font-size: 12px !important; padding: 0 4px !important;"
                  >
                    {{ day.id }}
                  </th>
                </template>
                <th style="text-align: center; font-size: 12px !important; padding: 0 4px !important;">
                  Всего
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(placeCounter, i) in placeCounters">
                <tr
                  v-if="placeCounter.visible == 1"
                  :key="i"
                >
                  <td
                    class="dashboard_td"
                    style="max-width: 200px !important;"
                  >
                    {{ placeCounter.districtName }}
                  </td>
                  <td
                    :class="placeCounter.color"
                    class="dashboard_td"
                    style="max-width: 200px !important;"
                  >
                    {{ placeCounter.name }}
                  </td>
                  <td
                    class="dashboard_td"
                    @click="getWorkDayInfo(placeCounter)"
                  >
                    {{ placeCounter.weekType }} дн.
                  </td>
                  <template v-for="(day, j) in days">
                    <template v-if="selectedReportType == 1">
                      <td
                        v-if="getUserRfIdSaleCount(placeCounter, day.date) == 'В'"
                        :key="i + '_' + j + '_4_1'"
                        class="dashboard_td grey_td"
                      />
                      <td
                        v-else
                        :key="i + '_' + j + '_4_2'"
                        class="dashboard_td"
                        @click="getDayInfo(placeCounter, day.date)"
                      >
                        {{ getUserRfIdSaleCount(placeCounter, day.date) }}
                      </td>
                    </template>
                    <template v-if="selectedReportType == 2">
                      <td
                        v-if="getUserSaleCount(placeCounter, day.date) == 'В'"
                        :key="i + '_' + j + '_4_1'"
                        class="dashboard_td grey_td"
                      />
                      <td
                        v-else
                        :key="i + '_' + j + '_4_2'"
                        class="dashboard_td"
                        @click="getDayInfo(placeCounter, day.date)"
                      >
                        {{ getUserSaleCount(placeCounter, day.date) }}
                      </td>
                    </template>
                    <template v-if="selectedReportType == 3">
                      <td
                        v-if="getAllCount(placeCounter, day.date) == 'В'"
                        :key="i + '_' + j + '_4_1'"
                        class="dashboard_td grey_td"
                      />
                      <td
                        v-else
                        :key="i + '_' + j + '_4_2'"
                        class="dashboard_td"
                        @click="getDayInfo(placeCounter, day.date)"
                      >
                        {{ getAllCount(placeCounter, day.date) }}
                      </td>
                    </template>
                    <template v-if="selectedReportType == 4">
                      <td
                        v-if="getUserCount(placeCounter, day.date) == 'В'"
                        :key="i + '_' + j + '_4_1'"
                        class="dashboard_td grey_td"
                      />
                      <td
                        v-else
                        :key="i + '_' + j + '_4_2'"
                        class="dashboard_td"
                        @click="getDayInfo(placeCounter, day.date)"
                      >
                        {{ getUserCount(placeCounter, day.date) }}
                      </td>
                    </template>
                  </template>
                  <!--
                  <td :class="placeCounter.color" class="dashboard_td">{{ placeCounter.percent.toFixed(2) }}</td>
                  -->
                  <td
                    v-if="selectedReportType == 1"
                    :class="placeCounter.color"
                    class="dashboard_td"
                    @click="getItogoInfo(placeCounter)"
                  >
                    {{ placeCounter.userRfIdSaleCountPercent.toFixed(2) }}
                  </td>
                  <td
                    v-if="selectedReportType == 2"
                    :class="placeCounter.color"
                    class="dashboard_td"
                    @click="getItogoInfo(placeCounter)"
                  >
                    {{ placeCounter.userSaleCountPercent.toFixed(2) }}
                  </td>
                  <td
                    v-if="selectedReportType == 3"
                    :class="placeCounter.color"
                    class="dashboard_td"
                    @click="getItogoInfo(placeCounter)"
                  >
                    {{ placeCounter.allUserPercent.toFixed(2) }}
                  </td>
                  <td
                    v-if="selectedReportType == 4"
                    :class="placeCounter.color"
                    class="dashboard_td"
                    @click="getItogoInfo(placeCounter)"
                  >
                    {{ placeCounter.userCountPercent }}
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-table>
        <v-dialog
          v-model="workdialog"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 bg-grey-lighten-2">
              Настройка рабочей недели:
            </v-card-title>
            <v-card-text>
              <v-select
                v-model="selectedWorkDaySetting"
                :items="workDaySettings"
                item-value="id"
                item-title="name"
                density="compact"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="ma-2"
                color="primary"
                @click="saveWorkDaySettings()"
              >
                Сохранить
              </v-btn>
              <v-btn
                class="ma-2"
                color="error"
                @click="workdialog = false"
              >
                Отмена
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog"
          :scrim="false"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 bg-grey-lighten-2">
              Подробно
            </v-card-title>
            <v-card-text>
              <v-table class="report-rateplan-day">
                <tr>
                  <td>
                    Количество учащихся:
                  </td>
                  <td>
                    {{ dialogUserCount }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Продажа по карте:
                  </td>
                  <td>
                    {{ dialogUserRfIdSaleCount }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Только электронная заявка:
                  </td>
                  <td>
                    {{ dialogUserSaleCount }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Всего:
                  </td>
                  <td>
                    {{ dialogAllCount }}
                  </td>
                </tr>
              </v-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog
        v-model="calendarDialog"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            theme="dark"
            color="green-darken-4"
          >
            <v-btn
              icon
              @click="calendarDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Настройка выходных дней</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                variant="text"
                @click="saveCalendarDialog()"
              >
                Сохранить
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
          <br>
          <v-row
            v-if="isCalendarDialogLoading"
            class="justify-content-center"
          >
            <v-col
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </v-row>
          <v-row v-if="!isCalendarDialogLoading">
            <v-col
              cols="1"
            />
            <v-col>
              Выходные для пятидневных школ
              <v-date-picker
                hide-weekdays
                title="Дата"
                v-model="fiveDates"
                v-model:picker-date="pickerDate"
                multiple
                class="mt-4"
                color="green-darken-4"
                :min="getMinDate()"
                :max="getMaxDate()"
              />
            </v-col>
            <v-col
              cols="1"
            />
            <v-col>
              Выходные для шестидневных школ
              <v-date-picker
                hide-weekdays
                title="Дата"
                v-model="sixDates"
                v-model:picker-date="pickerDate"
                multiple
                class="mt-4"
                color="green-darken-4"
                :min="getMinDate()"
                :max="getMaxDate()"
              />
            </v-col>
            <v-col
              cols="1"
            />
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { UserPlaceHolidaySettingsFindPost } from '@/shared/api/userPlaceHolidaySettings/api-types'
import {
  find as userPlaceHolidaySettingsFind,
  save as userPlaceHolidaySettingsSave
} from '@/shared/api/userPlaceHolidaySettings/api-requests'
import { placeCountersFind } from '@/shared/api/placeCounters/api-requests'
import { placeWorkTypeSet } from '@/shared/api/places/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  tempPlaceCounters: any;
  isCalendarDialogLoading: boolean;
  placeFiveDates: any;
  placeSixDates: any;
  pickerDate: any;
  fiveDates: any;
  sixDates: any;
  calendarDialog: any;
  selectedWorkDayPlaceId: any;
  selectedWorkDaySetting: any;
  workDaySettings: any;
  workdialog: boolean;
  dialog: boolean;
  dialogUserCount: any;
  dialogUserRfIdSaleCount: any;
  dialogUserSaleCount: any;
  dialogAllCount: any;
  reportTypes: any;
  selectedReportType: any;
  sortPercents: any;
  selectedSortPercent: any;
  yellowAllCountPerc: any;
  redAllCountPerc: any;
  yellowUserRfIdSaleCountPerc: any;
  redUserRfIdSaleCountPerc: any;
  yellowUserSaleCountPerc: any;
  redUserSaleCountPerc: any;
  monthes: any;
  years: any;
  selectedMonth: any;
  selectedYear: any;
  searchDistrict: any;
  districts: any;
  days: any;
  searchTerm: string;
  isLoading: boolean;
  isPost: boolean;
  places: any;
  placeCounters: any;
  dateFrom: any;
  dateTo: any;
  menu1: boolean;
  menu2: boolean;
  placeCountersPost: any;
  workDaySettingsPost: any;
}

export default defineComponent({
  name: 'ReportTable',
  data (): BaseData {
    return {
      tempPlaceCounters: [],
      isCalendarDialogLoading: false,
      pickerDate: null,
      placeFiveDates: [],
      placeSixDates: [],
      fiveDates: [],
      sixDates: [],
      calendarDialog: false,
      selectedWorkDayPlaceId: null,
      selectedWorkDaySetting: 2,
      workDaySettings: [
        { id: 1, name: '5-ти дневная' },
        { id: 2, name: '6-ти дневная' }
      ],
      workdialog: false,
      dialog: false,
      dialogUserCount: 0,
      dialogUserRfIdSaleCount: 0,
      dialogUserSaleCount: 0,
      dialogAllCount: 0,
      selectedReportType: 3,
      reportTypes: [
        { id: 1, name: 'Оплата картой' },
        { id: 2, name: 'Только электронная заявка' },
        { id: 3, name: 'Всего' },
        { id: 4, name: 'Количество детей' }
      ],
      sortPercents: [
        { id: 1, name: 'Убыванию' },
        { id: 2, name: 'Возрастанию' },
        { id: 3, name: 'Району' }
      ],
      selectedSortPercent: 3,
      yellowAllCountPerc: 75,
      redAllCountPerc: 50,
      yellowUserRfIdSaleCountPerc: 70,
      redUserRfIdSaleCountPerc: 40,
      yellowUserSaleCountPerc: 30,
      redUserSaleCountPerc: 20,
      monthes: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' }
      ],
      selectedMonth: moment().month() + 1,
      years: [
      ],
      selectedYear: moment().year(),
      searchDistrict: null,
      districts: [],
      days: [],
      searchTerm: '',
      isLoading: false,
      isPost: false,
      places: [],
      placeCounters: [],
      dateFrom: null,
      dateTo: null,
      menu1: false,
      menu2: false,
      placeCountersPost: {
        dateFrom: null,
        dateTo: null
      },
      workDaySettingsPost: {
        placeId: null,
        weekType: 6
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadPlaceCounters () {
      try {
        this.dateFrom = moment(this.selectedYear + '-' + this.selectedMonth + '-01').format('YYYY-MM-DD')
        this.dateTo = moment(this.selectedYear + '-' + this.selectedMonth + '-01').add('months', 1).add(-1, 'days').format('YYYY-MM-DD')
        this.isLoading = true
        this.days = []
        this.places = []
        this.placeCounters = []
        this.districts = []
        this.districts.push({
          id: null,
          name: 'Все'
        })
        const endDay = moment(this.dateTo).diff(moment(this.dateFrom), 'days')
        for (let i = 0; i <= endDay; i++) {
          this.days.push(
            {
              id: moment(this.dateFrom).add(i, 'days').format('D'),
              date: moment(this.dateFrom).add(i, 'days').format('YYYY-MM-DD')
            }
          )
        }
        let userPlaceHolidaySettings = []
        this.placeCountersPost.dateFrom = moment(this.dateFrom).format('YYYY-MM-DD')
        this.placeCountersPost.dateTo = moment(this.dateTo).add(1, 'days').format('YYYY-MM-DD')
        const result = await placeCountersFind(this.placeCountersPost)
        const reportPlaceCounters = JSON.parse(JSON.stringify(result))
        if (Array.isArray(reportPlaceCounters.places)) {
          this.places = JSON.parse(JSON.stringify(reportPlaceCounters.places))
        }
        if (Array.isArray(reportPlaceCounters.placeCounters)) {
          this.tempPlaceCounters = JSON.parse(JSON.stringify(reportPlaceCounters.placeCounters))
        }
        if (Array.isArray(reportPlaceCounters.userPlaceHolidaySettings)) {
          userPlaceHolidaySettings = JSON.parse(JSON.stringify(reportPlaceCounters.userPlaceHolidaySettings))
        }
        this.places.forEach((place: any) => {
          if (place.weekType === null) {
            place.weekType = 6
          }
          let districtId = 0
          let districtName = 'Не указан'
          place.districtPlaces.forEach((districtPlace: any) => {
            if (Number(districtPlace.district.typeId) === 1) {
              districtName = districtPlace.district.name
              districtId = districtPlace.district.id
            }
          })
          const ind = this.districts.findIndex((el: any) => {
            return el.id === districtId
          })
          if (ind <= -1) {
            this.districts.push({
              id: districtId,
              name: districtName
            })
          }
        })
        this.placeFiveDates = []
        this.placeSixDates = []
        if (userPlaceHolidaySettings.length > 0) {
          userPlaceHolidaySettings.forEach((userPlaceHolidaySetting: any) => {
            if (userPlaceHolidaySetting.weekType === 5) {
              if (userPlaceHolidaySetting.value.length > 0) {
                const tempPlaceFiveDates = userPlaceHolidaySetting.value.split(';')
                tempPlaceFiveDates.forEach((tempPlaceFiveDate: any) => {
                  this.placeFiveDates.push(moment(moment(this.dateFrom).format('YYYY') + '-' + moment(this.dateFrom).format('MM') + '-' + tempPlaceFiveDate).format('YYYY-MM-DD'))
                })
              }
            }
            if (userPlaceHolidaySetting.weekType === 6) {
              if (userPlaceHolidaySetting.value.length > 0) {
                const tempPlaceSixDates = userPlaceHolidaySetting.value.split(';')
                tempPlaceSixDates.forEach((tempPlaceSixDate: any) => {
                  this.placeSixDates.push(moment(moment(this.dateFrom).format('YYYY') + '-' + moment(this.dateFrom).format('MM') + '-' + tempPlaceSixDate).format('YYYY-MM-DD'))
                })
              }
            }
          })
        }
        this.places.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.placeCountersUpdate()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    placeCountersUpdate () {
      this.isLoading = true
      this.placeCounters = []
      const endDay = moment(this.dateTo).diff(moment(this.dateFrom), 'days')
      const placeCounters = JSON.parse(JSON.stringify(this.tempPlaceCounters))
      const places = JSON.parse(JSON.stringify(this.places))
      const newTempCounters: any = []
      places.forEach((place: any) => {
        let districtName = 'Не указан'
        place.districtPlaces.forEach((districtPlace: any) => {
          if (Number(districtPlace.district.typeId) === 1) {
            districtName = districtPlace.district.name
          }
        })
        let allUserPercent = 0
        let userCountPercent = 0
        let userSaleCountPercent = 0
        let userRfIdSaleCountPercent = 0
        let allPercent = 0
        let percentColor = 'percent-red'
        let maxUserCount = 0
        let allUserCount = 0
        let allUserSaleCount = 0
        let allUserRfIdSaleCount = 0
        let allUserSaleRfIdSaleCount = 0
        const tempPlaceId = Number(place.id)
        const tempPlaceCounters = []
        const tempCounters = placeCounters.filter((el: any) => el.placeId === tempPlaceId)
        for (let i = 0; i <= endDay; i++) {
          const tempDate = moment(moment(this.dateFrom).add(i, 'days'), 'YYYY-MM-DD').format('YYYY-MM-DD')
          const placeCounter = tempCounters.find((el: any) => el.date === tempDate)
          let dayExists = false
          if (placeCounter !== undefined) {
            if (moment(tempDate).isBefore(moment(moment().format('YYYY-MM-DD')))) {
              if (place.weekType === 5) {
                const placeFiveDate = this.placeFiveDates.find((el2: any) => {
                  return el2 === tempDate
                })
                if (placeFiveDate === undefined) {
                  dayExists = true
                }
              } else {
                const placeSixDate = this.placeSixDates.find((el2: any) => {
                  return el2 === tempDate
                })
                if (placeSixDate === undefined) {
                  dayExists = true
                }
              }
            }
            if (dayExists === true) {
              if (placeCounter.userCount !== undefined) {
                if (maxUserCount < placeCounter.userCount) {
                  maxUserCount = placeCounter.userCount
                }
                const dow = moment(tempDate).day()
                if (dow < 6 && dow > 0) {
                  allUserCount = allUserCount + placeCounter.userCount
                  allUserSaleCount = allUserSaleCount + placeCounter.userSaleCount
                  allUserRfIdSaleCount = allUserRfIdSaleCount + placeCounter.userRfIdSaleCount
                  allUserSaleRfIdSaleCount = allUserSaleRfIdSaleCount + placeCounter.userSaleRfIdSaleCount
                }
              }
            }
            if (placeCounter.userCount !== undefined) {
              if (Number(placeCounter.userCount) > 0) {
                if (Number(allPercent) < Number(Number(((placeCounter.userRfIdSaleCount + placeCounter.userSaleCount - placeCounter.userSaleRfIdSaleCount) / placeCounter.userCount) * 100).toFixed(2))) {
                  allPercent = Number(Number(((placeCounter.userRfIdSaleCount + placeCounter.userSaleCount - placeCounter.userSaleRfIdSaleCount) / placeCounter.userCount) * 100).toFixed(2))
                }
              }
            }

            tempPlaceCounters.push({
              date: tempDate,
              userCount: placeCounter.userCount,
              userSaleCount: placeCounter.userSaleCount,
              userRfIdSaleCount: placeCounter.userRfIdSaleCount,
              userSaleRfIdSaleCount: placeCounter.userSaleRfIdSaleCount
            })
          } else {
            tempPlaceCounters.push({
              date: tempDate,
              userCount: 0,
              userSaleCount: 0,
              userRfIdSaleCount: 0,
              userSaleRfIdSaleCount: 0
            })
          }
        }

        if (allUserCount === 0) {
          allUserPercent = 0
          userCountPercent = 0
          userSaleCountPercent = 0
          userRfIdSaleCountPercent = 0
        } else {
          allUserPercent = Number(((allUserRfIdSaleCount + allUserSaleCount - allUserSaleRfIdSaleCount) / allUserCount) * 100)
          userCountPercent = maxUserCount
          userSaleCountPercent = Number(((allUserSaleCount - allUserSaleRfIdSaleCount) / allUserCount) * 100)
          userRfIdSaleCountPercent = Number(((allUserRfIdSaleCount) / allUserCount) * 100)
        }

        if (Number(allPercent) < Number(this.redAllCountPerc)) {
          percentColor = 'percent-red'
        } else {
          if (Number(allPercent) < Number(this.yellowAllCountPerc)) {
            percentColor = 'percent-yellow'
          } else {
            percentColor = 'percent-green'
          }
        }
        newTempCounters.push({
          placeId: place.id,
          name: place.name,
          districtName: districtName,
          weekType: place.weekType,
          placeCounters: tempPlaceCounters,
          visible: 1,
          percent: allPercent,
          color: percentColor,
          userCountPercent: userCountPercent,
          userSaleCountPercent: userSaleCountPercent,
          userRfIdSaleCountPercent: userRfIdSaleCountPercent,
          allUserPercent: allUserPercent
        })
      })
      this.placeCounters = JSON.parse(JSON.stringify(newTempCounters))
      this.sortPlaceCounters()
      this.tableChange()
      this.isLoading = false
    },
    async saveWorkDaySettings () {
      try {
        this.isLoading = true
        this.workDaySettingsPost.placeId = Number(this.selectedWorkDayPlaceId)
        if (this.selectedWorkDaySetting === 1) {
          this.workDaySettingsPost.weekType = 5
        } else {
          this.workDaySettingsPost.weekType = 6
        }
        const result = await placeWorkTypeSet(this.workDaySettingsPost)
        const placeWeekTypeResult = JSON.parse(JSON.stringify(result))
        if (placeWeekTypeResult !== true) {
          throw new Error('Ошибка сохранения')
        }
        const i = this.places.findIndex((el: any) => {
          return Number(el.id) === this.workDaySettingsPost.placeId
        })
        if (i > -1) {
          this.places[i].weekType = this.workDaySettingsPost.weekType
        }
        this.placeCountersUpdate()
        this.workdialog = false
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getWorkDayInfo (placeCounter: any) {
      this.selectedWorkDayPlaceId = placeCounter.placeId
      this.selectedWorkDaySetting = 2
      if (placeCounter.weekType === 5) {
        this.selectedWorkDaySetting = 1
      }
      this.workdialog = true
    },
    getDayInfo (placeCounter: any, date: any) {
      this.dialogUserCount = this.getUserCount(placeCounter, date)
      this.dialogUserRfIdSaleCount = this.getUserRfIdSaleCount(placeCounter, date)
      this.dialogUserSaleCount = this.getUserSaleCount(placeCounter, date)
      this.dialogAllCount = this.getAllCount(placeCounter, date)
      this.dialog = true
    },
    getItogoInfo (placeCounter: any) {
      this.dialogUserCount = placeCounter.userCountPercent
      this.dialogUserRfIdSaleCount = placeCounter.userRfIdSaleCountPercent.toFixed(2)
      this.dialogUserSaleCount = placeCounter.userSaleCountPercent.toFixed(2)
      this.dialogAllCount = placeCounter.allUserPercent.toFixed(2)
      this.dialog = true
    },
    getUserCount (placeCounter: any, date: any) {
      if (moment(date).isSameOrAfter(moment(moment().format('YYYY-MM-DD')))) {
        return '-'
      }
      const tempDate = moment(moment(date), 'YYYY-MM-DD').format('YYYY-MM-DD')
      const searhDate = moment(date).format('YYYY-MM-DD')
      if (placeCounter.weekType === 5) {
        const placeFiveDate = this.placeFiveDates.find((el: any) => {
          return el === searhDate
        })
        if (placeFiveDate !== undefined) {
          return 'В'
        }
      } else {
        const placeSixDate = this.placeSixDates.find((el: any) => {
          return el === searhDate
        })
        if (placeSixDate !== undefined) {
          return 'В'
        }
      }
      const resultPlaceCounter = placeCounter.placeCounters.find((el: any) => tempDate === el.date)
      if (resultPlaceCounter === undefined) {
        return 0
      }
      return resultPlaceCounter.userCount
    },
    getUserRfIdSaleCount (placeCounter: any, date: any) {
      if (moment(date).isSameOrAfter(moment(moment().format('YYYY-MM-DD')))) {
        return '-'
      }
      const tempDate = moment(moment(date), 'YYYY-MM-DD').format('YYYY-MM-DD')
      const searhDate = moment(date).format('YYYY-MM-DD')
      if (placeCounter.weekType === 5) {
        const placeFiveDate = this.placeFiveDates.find((el: any) => {
          return el === searhDate
        })
        if (placeFiveDate !== undefined) {
          return 'В'
        }
      } else {
        const placeSixDate = this.placeSixDates.find((el: any) => {
          return el === searhDate
        })
        if (placeSixDate !== undefined) {
          return 'В'
        }
      }
      const resultPlaceCounter = placeCounter.placeCounters.find((el: any) => tempDate === el.date)
      if (resultPlaceCounter === undefined) {
        return Number(0).toFixed(2)
      }
      if (Number(resultPlaceCounter.userCount) === 0) {
        return Number(0).toFixed(2)
      }
      return Number(((resultPlaceCounter.userRfIdSaleCount) / resultPlaceCounter.userCount) * 100).toFixed(2)
    },
    getUserSaleCount (placeCounter: any, date: any) {
      if (moment(date).isSameOrAfter(moment(moment().format('YYYY-MM-DD')))) {
        return '-'
      }
      const tempDate = moment(moment(date), 'YYYY-MM-DD').format('YYYY-MM-DD')
      const searhDate = moment(date).format('YYYY-MM-DD')
      if (placeCounter.weekType === 5) {
        const placeFiveDate = this.placeFiveDates.find((el: any) => {
          return el === searhDate
        })
        if (placeFiveDate !== undefined) {
          return 'В'
        }
      } else {
        const placeSixDate = this.placeSixDates.find((el: any) => {
          return el === searhDate
        })
        if (placeSixDate !== undefined) {
          return 'В'
        }
      }
      const resultPlaceCounter = placeCounter.placeCounters.find((el: any) => tempDate === el.date)
      if (resultPlaceCounter === undefined) {
        return Number(0).toFixed(2)
      }
      if (Number(resultPlaceCounter.userCount) === 0) {
        return Number(0).toFixed(2)
      }
      return Number(((resultPlaceCounter.userSaleCount - resultPlaceCounter.userSaleRfIdSaleCount) / resultPlaceCounter.userCount) * 100).toFixed(2)
    },
    getAllCount (placeCounter: any, date: any) {
      if (moment(date).isSameOrAfter(moment(moment().format('YYYY-MM-DD')))) {
        return '-'
      }
      const tempDate = moment(moment(date), 'YYYY-MM-DD').format('YYYY-MM-DD')
      const searhDate = moment(date).format('YYYY-MM-DD')
      if (placeCounter.weekType === 5) {
        const placeFiveDate = this.placeFiveDates.find((el: any) => {
          return el === searhDate
        })
        if (placeFiveDate !== undefined) {
          return 'В'
        }
      } else {
        const placeSixDate = this.placeSixDates.find((el: any) => {
          return el === searhDate
        })
        if (placeSixDate !== undefined) {
          return 'В'
        }
      }
      const resultPlaceCounter = placeCounter.placeCounters.find((el: any) => tempDate === el.date)
      if (resultPlaceCounter === undefined) {
        return Number(0).toFixed(2)
      }
      if (Number(resultPlaceCounter.userCount) === 0) {
        return Number(0).toFixed(2)
      }
      return Number(((resultPlaceCounter.userRfIdSaleCount + resultPlaceCounter.userSaleCount - resultPlaceCounter.userSaleRfIdSaleCount) / resultPlaceCounter.userCount) * 100).toFixed(2)
    },
    async openCalendarDialog () {
      try {
        this.calendarDialog = true
        this.isCalendarDialogLoading = true
        this.fiveDates = []
        this.sixDates = []
        this.pickerDate = moment(this.selectedYear + '-' + this.selectedMonth + '-01').toISOString().split('T')[0]
        const userPlaceHolidaySettingsPost: UserPlaceHolidaySettingsFindPost = {
          year: Number(this.selectedYear),
          month: Number(this.selectedMonth)
        }
        const result = await userPlaceHolidaySettingsFind(userPlaceHolidaySettingsPost)
        const userPlaceHolidaySettingsResults = JSON.parse(JSON.stringify(result))
        userPlaceHolidaySettingsResults.forEach((el: any) => {
          if (el.weekType === 5) {
            if (el.value.length > 0) {
              const days = el.value.split(';')
              days.forEach((day: any) => {
                if (day.length > 0) {
                  this.fiveDates.push(moment(this.selectedYear + '-' + this.selectedMonth + '-' + day).format('YYYY-MM-DD'))
                }
              })
            }
          } else if (el.weekType === 6) {
            if (el.value.length > 0) {
              const days = el.value.split(';')
              days.forEach((day: any) => {
                if (day.length > 0) {
                  this.sixDates.push(moment(this.selectedYear + '-' + this.selectedMonth + '-' + day).format('YYYY-MM-DD'))
                }
              })
            }
          }
        })
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isCalendarDialogLoading = false
      }
    },
    async saveCalendarDialog () {
      try {
        this.isCalendarDialogLoading = true
        let fiveDates = ''
        let sixDates = ''
        this.fiveDates.forEach((el: any) => {
          if (fiveDates.length > 0) {
            fiveDates = fiveDates + ';'
          }
          fiveDates = fiveDates + moment(el).format('D')
        })
        this.sixDates.forEach((el: any) => {
          if (sixDates.length > 0) {
            sixDates = sixDates + ';'
          }
          sixDates = sixDates + moment(el).format('D')
        })
        const userPlaceHolidaySettingsSavePost = []
        userPlaceHolidaySettingsSavePost.push({
          year: this.selectedYear,
          month: this.selectedMonth,
          weekType: 5,
          value: fiveDates
        })
        userPlaceHolidaySettingsSavePost.push({
          year: this.selectedYear,
          month: this.selectedMonth,
          weekType: 6,
          value: sixDates
        })
        const result = await userPlaceHolidaySettingsSave({ userPlaceHolidaySettings: userPlaceHolidaySettingsSavePost })
        const userPlaceHolidaySettingsResults = JSON.parse(JSON.stringify(result))
        if (this.places.length > 0) {
          if (Number(moment(this.dateFrom).format('MM')) === Number(this.selectedMonth) && Number(moment(this.dateFrom).format('YYYY')) === Number(this.selectedYear)) {
            this.placeFiveDates = JSON.parse(JSON.stringify(this.fiveDates))
            this.placeSixDates = JSON.parse(JSON.stringify(this.sixDates))
            this.placeCountersUpdate()
          }
        }
        this.calendarDialog = false
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isCalendarDialogLoading = false
      }
    },
    tableChange () {
      this.placeCounters.forEach((element: any) => {
        let searchDistrict = ''
        if (this.searchDistrict !== null) {
          searchDistrict = this.districts.find((desc: any) => desc.id === this.searchDistrict).name
        }
        let visible = 0
        let districtVisible = 0
        if (searchDistrict === '') {
          districtVisible = 1
        } else {
          if (searchDistrict === element.districtName) {
            districtVisible = 1
          }
        }
        if (districtVisible === 1) {
          visible = 1
        }
        element.visible = visible
      })
    },
    getMinDate () {
      return moment(this.selectedYear + '-' + this.selectedMonth + '-01').toISOString()
    },
    getMaxDate () {
      return moment(this.selectedYear + '-' + this.selectedMonth + '-01').add('months', 1).add(-1, 'days').toISOString()
    },
    sortPlaceCounters () {
      this.placeCounters.forEach((el: any) => {
        if (this.selectedReportType === 3) {
          if (Number(el.allUserPercent) < Number(this.redAllCountPerc)) {
            el.color = 'percent-red'
          } else {
            if (Number(el.allUserPercent) < Number(this.yellowAllCountPerc)) {
              el.color = 'percent-yellow'
            } else {
              el.color = 'percent-green'
            }
          }
        } else if (this.selectedReportType === 1) {
          if (Number(el.userRfIdSaleCountPercent) < Number(this.redUserRfIdSaleCountPerc)) {
            el.color = 'percent-red'
          } else {
            if (Number(el.userRfIdSaleCountPercent) < Number(this.yellowUserRfIdSaleCountPerc)) {
              el.color = 'percent-yellow'
            } else {
              el.color = 'percent-green'
            }
          }
        } else if (this.selectedReportType === 2) {
          if (Number(el.userSaleCountPercent) < Number(this.redUserSaleCountPerc)) {
            el.color = 'percent-red'
          } else {
            if (Number(el.userSaleCountPercent) < Number(this.yellowUserSaleCountPerc)) {
              el.color = 'percent-yellow'
            } else {
              el.color = 'percent-green'
            }
          }
        } else {
          el.color = ''
        }
      })

      if (this.selectedSortPercent === 2) {
        if (this.selectedReportType === 3) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.allUserPercent) > Number(b.allUserPercent)) ? 1 : ((Number(b.allUserPercent) > Number(a.allUserPercent)) ? -1 : 0))
        } else if (this.selectedReportType === 1) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.userRfIdSaleCountPercent) > Number(b.userRfIdSaleCountPercent)) ? 1 : ((Number(b.userRfIdSaleCountPercent) > Number(a.userRfIdSaleCountPercent)) ? -1 : 0))
        } else if (this.selectedReportType === 2) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.userSaleCountPercent) > Number(b.userSaleCountPercent)) ? 1 : ((Number(b.userSaleCountPercent) > Number(a.userSaleCountPercent)) ? -1 : 0))
        } else if (this.selectedReportType === 4) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.userCountPercent) > Number(b.userCountPercent)) ? 1 : ((Number(b.userCountPercent) > Number(a.userCountPercent)) ? -1 : 0))
        }
      } else if (this.selectedSortPercent === 1) {
        if (this.selectedReportType === 3) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.allUserPercent) < Number(b.allUserPercent)) ? 1 : ((Number(b.allUserPercent) < Number(a.allUserPercent)) ? -1 : 0))
        } else if (this.selectedReportType === 1) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.userRfIdSaleCountPercent) < Number(b.userRfIdSaleCountPercent)) ? 1 : ((Number(b.userRfIdSaleCountPercent) < Number(a.userRfIdSaleCountPercent)) ? -1 : 0))
        } else if (this.selectedReportType === 2) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.userSaleCountPercent) < Number(b.userSaleCountPercent)) ? 1 : ((Number(b.userSaleCountPercent) < Number(a.userSaleCountPercent)) ? -1 : 0))
        } else if (this.selectedReportType === 4) {
          this.placeCounters.sort((a: any, b: any) => (Number(a.userCountPercent) < Number(b.userCountPercent)) ? 1 : ((Number(b.userCountPercent) < Number(a.userCountPercent)) ? -1 : 0))
        }
      } else {
        this.placeCounters.sort((a: any, b: any) => (a.districtName > b.districtName) ? 1 : ((b.districtName > a.districtName) ? -1 : ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))))
      }
    }
  },
  mounted () {
    for (let i = 5; i >= 0; i--) {
      this.years.push({ id: Number(moment().year()) - i, name: String(Number(moment().year()) - i) })
    }
    this.selectedMonth = moment().month() + 1
    this.selectedYear = moment().year()
    this.dateFrom = moment(this.selectedYear + '-' + this.selectedMonth + '-01').format('YYYY-MM-DD')
    this.dateTo = moment(this.selectedYear + '-' + this.selectedMonth + '-01').add('months', 1).add(-1, 'days').format('YYYY-MM-DD')
  }
})
</script>

<style lang="scss">
  .percent-red {
    background-color: #EF9A9A;
  }
  .percent-yellow {
    background-color: #E6EE9C;
  }
  .percent-green {
    background-color: #A5D6A7;
  }
  .dashboard_td {
    text-align: center;
    font-size: 12px !important;
    padding: 0 4px !important;
  }
  .grey_td {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgb(158, 158, 158);
  }
</style>
