import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { NewsFindByUserTypeRequest, NewsFindByUserTypeResponse } from '@/shared/api/parents/news/api-types'

enum NewsRoutes {
  NewsFindUnread = '/news/findUnread',
  NewsMarkAsRead = '/news/markAsRead',
  NewsFindByUserType = '/news/findByUserType'
}

const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const newsFindUnread = async (data: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result = await axios.post(NewsRoutes.NewsFindUnread, data)
  return result.data
}

export const findByUserType = async (data: NewsFindByUserTypeRequest, telegram: boolean): Promise<NewsFindByUserTypeResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<NewsFindByUserTypeResponse>(NewsRoutes.NewsFindByUserType, data, { headers })
    return result.data
  } else {
    const result = await axios.post<NewsFindByUserTypeResponse>(NewsRoutes.NewsFindByUserType, data)
    return result.data
  }
}
