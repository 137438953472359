<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    item-title="code"
    item-value="id"
    label="Категория"
    :loading="isLoading"
    :disabled="isDisabled"
    @update:model-value="update"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { find as findRatePlans } from '@/shared/api/rateplan/api-requests'

export default defineComponent({
  model: {
    prop: 'itemsProps',
    event: 'on-change'
  },
  props: ['customerIdProps', 'selectedItemProps'],
emits: ['on-change', 'category-select'],
  data () {
    return {
      isLoading: false,
      isDisabled: true,
      selectedItem: 0,
      items: [{ id: 0, name: 'Все', code: 'Все', customerId: 0, groupId: null, ratePlanDiscountTypeId: null, timestampTo: null, unlimited: null, updateTimestamp: null }]
    }
  },
  watch: {
    customerIdProps (value) {
      if (value !== 0) this.loadCustomerRatePlans()
    }
  },
  methods: {
    async loadCustomerRatePlans () {
      try {
        this.isLoading = true
        if (this.customerIdProps !== 0) {
          this.items = []
          const ratePlanFindPost = {
            customerId: this.customerIdProps
          }
          const result = await findRatePlans(ratePlanFindPost)
          this.items = JSON.parse(JSON.stringify(result))
          if (this.items.length > 0) {
            this.items.sort((a: any, b: any) => (Number(a.code) > Number(b.code)) ? 1 : ((Number(b.code) > Number(a.code)) ? -1 : 0))
          }
          this.items.unshift({ id: 0, name: 'Все', code: 'Все', customerId: 0, groupId: null, ratePlanDiscountTypeId: null, timestampTo: null, unlimited: null, updateTimestamp: null })
          this.selectedItem = 0
        }
        this.isDisabled = false
        this.update()
      } catch (error: any) {
        this.items = [{ id: 0, name: 'Ошибка загрузки', code: 'Ошибка загрузки', customerId: 0, groupId: null, ratePlanDiscountTypeId: null, timestampTo: null, unlimited: null, updateTimestamp: null }]
      } finally {
        this.isLoading = false
      }
    },
    update () {
      this.$emit('on-change', {
        selectedCategory: this.items.find((obj: any) => obj.id === this.selectedItem)?.id,
        categories: this.items
      })
      this.$emit('category-select')
    }
  }
})
</script>

<style lang="scss"></style>
