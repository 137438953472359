<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Список устройств</strong>
        </h1>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="deviceList"
          item-key="userId"
          class="elevation-1"
          :search="search"
          search-text="Поиск"
          :items-per-page="100"
          :footer-props="footerProps"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
        >
          <template #top>
            <v-text-field
              v-model="search"
              label="Поиск"
              append-icon="search"
              class="mx-4"
            />
          </template>
          <template #item="{ item }">
            <tr :data-id="item.id">
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ item.login }}
              </td>
              <td>
                {{ item.userId }}
              </td>
              <td>
                <v-btn
                  v-if="item.updateTime == undefined"
                  @click="getUpdateTime(item)"
                >
                  <v-icon
                    size="medium"
                    color="red-darken-2"
                  >
                    mdi-eye
                  </v-icon>
                </v-btn>
                <span v-else>
                  {{ item.updateTime }}
                </span>
              </td>
            </tr>
          </template>
          <template #no-data>
            <v-alert
              v-if="loading==false && devices.length==0"
              :value="true"
              icon="warning"
            >
              Нет данных
            </v-alert>
            <v-alert
              v-else
              :value="false"
              icon="warning"
            >
              Нет данных
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { configurationInfoFind } from '@/shared/api/deviceList/api-requests'
import { AxiosError } from 'axios'
import moment from 'moment'

type BaseData = {
  isLoading: boolean;
  footerProps: any;
  search: string;
  searchTerm: string;
  loading: boolean;
  singleSelect: boolean;
  selected: any;
  valid: boolean;
  deviceList: any;
}
export default defineComponent({
  data (): BaseData {
    return {
      footerProps: {
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Количество на странице',
        itemsPerPageOptions: [10, 20, 30, 100, 1000, -1]
      },
      search: '',
      searchTerm: '',
      loading: false,
      singleSelect: false,
      selected: [],
      valid: true,
      isLoading: false,
      deviceList: []
    }
  },
  computed: {
    ...mapGetters({ devices: 'devices/devices' }),
    headers () {
      return [
        {
          text: 'Описание',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        { text: 'Логин', value: 'login' },
        { text: 'Id', value: 'userId', filterable: false },
        { text: 'Время обновления', value: '', filterable: false }
      ]
    }
  },
  methods: {
    ...mapActions('devices', { deviceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadDevices () {
      try {
        await this.deviceAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.devices.forEach((element: any) => {
          this.deviceList.push({
            id: element.id,
            userId: element.userId,
            login: element.login,
            description: element.description,
            updateTime: undefined
          })
        })
        this.loading = false
      }
    },
    async getUpdateTime (item: any) {
      try {
        let updateTime = null
        const searchUpdateTime = {
          userId: item.userId,
          updateTypeId: 1,
          updateStatus: 0
        }
        const result = await configurationInfoFind(searchUpdateTime)
        updateTime = JSON.parse(JSON.stringify(result))
        if (updateTime != null) {
          item.updateTime = moment(updateTime.lastUpdateTimestamp).format('DD.MM.YYYY HH:mm:ss')
        } else {
          item.updateTime = 'Информация не найдена'
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    }
  },
  mounted () {
    this.loading = true
    this.loadDevices()
  }
})
</script>

<style lang='scss'></style>
