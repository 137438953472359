import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, { onClick: _ctx.exportXlsx }, {
    default: _withCtx(() => [
      _createTextVNode(" Скачать Excel ")
    ]),
    _: 1
  }, 8, ["onClick"]))
}