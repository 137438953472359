<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong>Горячая линия</strong>
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="errors.length">
      <v-col>
        <strong>Пожалуйста исправьте указанные ошибки:</strong>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <strong>Укажите Номер телефона</strong>
      </v-col>
      <v-col>
        <v-text-field
          v-model="phone"
          :counter="11"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <label> {{ text }}</label>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="district"
          :items="districts"
          required
        />
      </v-col>
      <v-col>
        <label> района.</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <strong>Дополнительное сообщение</strong>
      </v-col>
      <v-col>
        <v-text-field
          v-model="message"
          :counter="100"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="sendForm()">
          Отправить
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          :to="{ path: '/oldsite/' }"
          action
        >
          Назад
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

type BaseData = {
  errors: string[];
  phone: null;
  text: string;
  message: string;
  district: string;
  districts: string[];
}

export default defineComponent({
  data (): BaseData {
    return {
      errors: [],
      phone: null,
      text: 'Прошу вас зарегистрировать меня на телеграмм-канале ответственных за питание ',
      message: '',
      district: '',
      districts: [
        'Адмиралтейского',
        'Василеостровского',
        'Выборгского',
        'Калининского',
        'Кировского',
        'Колпинского',
        'Красногвардейского',
        'Красносельского',
        'Кронштадтского',
        'Курортного',
        'Московского',
        'Невского',
        'Петроградского',
        'Петродворцового',
        'Приморского',
        'Пушкинского',
        'Сосновый Бор',
        'Фрунзенского',
        'Центрального',
        'Школы городского подчинения'
      ]
    }
  },
  mounted () {
    //
  },
  methods: {
    checkForm: function () {
      this.errors = []

      if (this.phone && this.district) {
        return true
      }

      if (!this.phone) {
        this.errors.push('Укажите телефон')
      }
      if (!this.district) {
        this.errors.push('Выберите район')
      }
    },
    sendForm: function () {
      this.checkForm()
      if (this.errors.length === 0) {
        let text = 'https://mobile.glolime.ru/notify/glolimetelegram/sendschool?text=' + this.text + this.district + ' района. Мой номер телефона: ' + this.phone
        text = text + '. Id: ' + this.user.person.id + '. Логин: ' + this.user.person.login
        text = text + '. Сообщение: ' + this.message
        window.location.href = text
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>
