<template>
  <div class="whole_page">
    <div style="display: grid; justify-content: center">
      <div class="content_container">
        <div class="control_panel">
          <button class="secondary-white-button body-text-roboto-medium">
            Детский сад
          </button>
          <button
            class="primary-green-button body-text-roboto-medium"
            style="padding: 9px 21px"
          >
            Платные услуги
          </button>
        </div>
        <v-select
          variant="solo"
          density="compact"
          hide-details="true"
          label="Художественная гимнастика"
        />
        <div class="month_element">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <h4
              class="body-text-roboto-medium"
              style="align-self: center"
            >
              Август 2024
            </h4>
            <button
              class="secondary-white-button"
              style="width: 36px; height: 36px; padding: 8px"
            >
              <img
                src="@/assets/images/arrow_down.png"
                height="20"
                width="20"
              >
            </button>
          </div>
          <table
            class="small-text-roboto-medium custom-table"
            style="border-radius: 8px; border: 1px solid #C0BEC5"
          >
            <thead>
              <tr style="background: #ECF2F6">
                <td>Дата</td>
                <td>Отметка о присутствии</td>
                <td>Причина отсутствия</td>
              </tr>
            </thead>
            <tr>
              <td>1</td>
              <td style="align-items: center">
                <div style="background: #63d08f; border-radius: 50%; height: 12px; width: 12px; margin-left: 50%" />
              </td>
              <td />
            </tr>
            <tr>
              <td>2</td>
              <td style="align-items: center">
                <div style="background: #F27575; border-radius: 50%; height: 12px; width: 12px; margin-left: 50%" />
              </td>
              <td>Пропуск без причины</td>
            </tr>
            <tr>
              <td>3</td>
              <td style="align-items: center">
                <div style="background: #f39614; border-radius: 50%; height: 12px; width: 12px; margin-left: 50%" />
              </td>
              <td>Семейные обстоятельства</td>
            </tr>
            <tr>
              <td>4</td>
              <td style="align-items: center">
                <div style="background: #F27575; border-radius: 50%; height: 12px; width: 12px; margin-left: 50%" />
              </td>
              <td>Пропуск без причины</td>
            </tr>
            <tr>
              <td>5</td>
              <td style="align-items: center">
                <div style="background: #63d08f; border-radius: 50%; height: 12px; width: 12px; margin-left: 50%" />
              </td>
              <td />
            </tr>
          </table>
          <h4
            class="body-text-roboto-medium"
            style="align-self: end"
          >
            Итого дней присутствия: 8
          </h4>
        </div>
        <div class="month_element">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <h4
              class="body-text-roboto-medium"
              style="align-self: center"
            >
              Июль 2024
            </h4>
            <button
              class="secondary-white-button"
              style="width: 36px; height: 36px; padding: 8px"
            >
              <img
                src="@/assets/images/arrow_right.png"
                height="20"
                width="20"
              >
            </button>
          </div>
        </div>
        <div class="month_element">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <h4
              class="body-text-roboto-medium"
              style="align-self: center"
            >
              Июнь 2024
            </h4>
            <button
              class="secondary-white-button"
              style="width: 36px; height: 36px; padding: 8px"
            >
              <img
                src="@/assets/images/arrow_right.png"
                height="20"
                width="20"
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import {
  AbsenceReasonsFindItem,
  AbsenceReasonsFindRequest,
  ReservationSalesCancelRequest,
  ReservationSalesGetByUserRequest
} from '@/shared/api/parents/attendanceReport/api-types'
import {
  absenceReasonsFind,
  reservationSalesCancel,
  reservationSalesGetByUserId
} from '@/shared/api/parents/attendanceReport/api-requests'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsAttendanceReport',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      isTelegram: false,
      currentUser: {} as Person,
      showTabel: [] as any[],
      absenceReasons: [] as AbsenceReasonsFindItem[]
    }
  },
  async beforeMount () {
    this.currentUser = this.user.person
    await this.absenceReasonsFind()
    await this.getReservationSalesByUserId()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async absenceReasonsFind () {
      try {
        const absenceReasonsFindReqObj: AbsenceReasonsFindRequest = {
          id: null,
          priority: null
        }
        const absenceReasonsFindRes = await absenceReasonsFind(absenceReasonsFindReqObj, this.isTelegram)
        this.absenceReasons = absenceReasonsFindRes.data
        console.log(absenceReasonsFindRes)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить причины осутствия!',
          timeout: 3000
        })
      }
    },
    async getReservationSalesByUserId () {
      try {
        const getReservationSalesByUserIdReqObj: ReservationSalesGetByUserRequest = {
          userId: this.currentUser.id,
          productId: 4,
          timestampFrom: '2024-08-01',
          timestampTo: '2024-08-31'
        }
        const getReservationSalesByUserIdRes = await reservationSalesGetByUserId(getReservationSalesByUserIdReqObj, this.isTelegram)
        console.log(getReservationSalesByUserIdRes)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить табель посещения занятий!',
          timeout: 3000
        })
      }
    },
    async cancelReservationSales () {
      try {
        const cancelReservationSalesReqObj: ReservationSalesCancelRequest = {
          userId: this.currentUser.id,
          productId: 4,
          placeId: 0,
          dates: [],
          absenceReasonId: 1,
          absenceReasonDescription: ''
        }
        const cancelReservationSalesRes = await reservationSalesCancel(cancelReservationSalesReqObj, this.isTelegram)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось тменить посещение занятий!',
          timeout: 3000
        })
      }
    },
    goToHome () {
      this.$router.push('./home')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  margin: 20px 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 700px;
}
.control_panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}
.month_element {
  padding: 12px;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  gap: 12px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 10px 10px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
@media (max-aspect-ratio: 6/5) {
.content_container {
  width: calc(100% - 32px);
}
}
</style>
