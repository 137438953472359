/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_PLACEELECTRONTALONS, CLEAR_PLACEELECTRONTALONS } from '@/store/placeElectronTalonList/mutations'
import { RootState } from '@/store/state'
import {
  PlaceElectronTalonListState, PlaceElectronTalon
} from '@/store/placeElectronTalonList/types'
import {
  find as placeElectronTalonFind
} from '@/shared/api/placeElectronTalonList/api-requests'
import { parseAxiosError } from '@/shared/lib/api'
import { PlaceElectronTalonSearchPost } from '../../shared/api/placeElectronTalonList/api-types'

type PlaceElectronTalonListContext = ActionContext<PlaceElectronTalonListState, RootState>

const initialState: PlaceElectronTalonListState = {
  placeElectronTalons: []
}

const getters: GetterTree<PlaceElectronTalonListState, RootState> = {
  placeElectronTalons (state: PlaceElectronTalonListState): PlaceElectronTalon[] {
    return state.placeElectronTalons
  }
}

const mutations: MutationTree<PlaceElectronTalonListState> = {
  [SET_PLACEELECTRONTALONS] (state: PlaceElectronTalonListState, placeElectronTalons: PlaceElectronTalon[]) {
    state.placeElectronTalons = placeElectronTalons
  },
  [CLEAR_PLACEELECTRONTALONS] (state: PlaceElectronTalonListState) {
    state.placeElectronTalons = []
  }
}

const actions: ActionTree<PlaceElectronTalonListState, RootState> = {
  async find (
    { commit, dispatch, state }: PlaceElectronTalonListContext,
    data: PlaceElectronTalonSearchPost
  ): Promise<PlaceElectronTalon[]> {
    try {
      commit(CLEAR_PLACEELECTRONTALONS)
      const result = await placeElectronTalonFind(data)
      commit(SET_PLACEELECTRONTALONS, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const placeElectronTalons = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
