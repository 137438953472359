import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceReportTypesFindReqBody, PlaceReportTypesSaveReqBody } from '@/shared/api/placeReportParams/api-types'
// import { PlaceReportSettingsFindPost, PlaceReportSettingsSavePost, PlaceReportSettingsRemovePost } from './api-types'

const enum PlaceReportParamsRoutes {
  PlaceReportParamsFind = '/placereportparams/find',
  PlaceReportParamsSave = '/placereportparams/save',
}

export const findPlaceReportParams = async (data: PlaceReportTypesFindReqBody): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceReportParamsRoutes.PlaceReportParamsFind, data)
  return result.data.data
}

export const savePlaceReportParams = async (data: PlaceReportTypesSaveReqBody): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(PlaceReportParamsRoutes.PlaceReportParamsSave, data)
  return result.data.data
}
