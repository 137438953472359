<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    item-title="name"
    item-value="id"
    label="Классы"
    :loading="isLoading"
    :disabled="isDisabled"
    @update:model-value="update"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { find as findPlaceReportSettings } from '@/shared/api/placeReportSettings/api-requests'

export default defineComponent({
  model: {
    prop: 'itemsProps',
    event: 'on-change'
  },
  props: ['placeIdProps', 'selectedItemProps'],
  emits: ['on-change', 'parallel-select'],
  data () {
    return {
      isLoading: false,
      isDisabled: true,
      selectedItem: 0,
      items: [{ id: 0, name: 'Загружаем', startValue: 0, endValue: 0, classType: 0 }],
      settings: [{ }],
      searchTerm: ''
    }
  },
  watch: {
    placeIdProps (value) {
      if (value !== 0) this.loadPlaceSettings()
    }
  },
  methods: {
    async loadPlaceSettings () {
      try {
        this.isLoading = true
        this.isDisabled = true
        this.settings = []
        const reportSettingsFindPost = {
          placeId: this.placeIdProps,
          reportType: 1
        }
        const result = await findPlaceReportSettings(reportSettingsFindPost)
        const settingsResponse = JSON.parse(JSON.stringify(result))
        if (Array.isArray(settingsResponse)) {
          settingsResponse.forEach((element: any) => {
            const elementCopy = element
            const values = elementCopy.value.split(';')
            const setting = {
              id: element.id,
              startValue: values[0],
              endValue: values[1]
            }
            this.settings.push(setting)
          })
        }
        this.settings.sort((a: any, b: any) => (a.startValue > b.startValue) ? 1 : ((b.startValue > a.startValue) ? -1 : 0))
        let i = 1
        this.items = []
        this.settings.forEach((element: any) => {
          element.classType = i
          this.items.push({ id: element.classType, name: element.startValue + '-' + element.endValue, startValue: element.startValue, endValue: element.endValue, classType: i })
          i = i + 1
        })
        if (this.selectedItemProps) this.selectedItem = this.selectedItemProps
        if (this.selectedItem === 0) this.selectedItem = this.items[0].id
        this.isDisabled = false
        this.update()
      } catch (error: any) {
        this.items = [{ id: 0, name: 'Ошибка загрузки', startValue: 0, endValue: 0, classType: 0 }]
      } finally {
        this.isLoading = false
      }
    },
    update () {
      if (this.selectedItem !== 0) {
        this.$emit('on-change', {
          selectedParallel: this.items.find((obj: any) => obj.id === this.selectedItem),
          parallels: this.items
        })
        this.$emit('parallel-select')
      }
    }
  }
})
</script>

<style lang="scss"></style>
