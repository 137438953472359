import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { FoodWarehouseDevicesFindPost, FoodWarehouseDevicesAddPost, FoodWarehouseDevicesRemovePost, FindFreeDevicesByPlaceFindPost } from './api-types'

const routeFoodWarehouseDevicesFind = '/foodWarehouseDevices/find'
const routeFoodWarehouseDevicesFindFree = '/foodWarehouseDevices/findfree'
const routeFoodWarehouseDevicesAdd = '/foodWarehouseDevices/create'
const routeFoodWarehouseDevicesRemove = '/foodWarehouseDevices/update'

export const foodWarehouseDevicesFind = async (data: FoodWarehouseDevicesFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehouseDevicesFind, data)
  return result.data.data
}

export const foodWarehouseDevicesFindFree = async (data: FindFreeDevicesByPlaceFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehouseDevicesFindFree, data)
  return result.data.data
}

export const foodWarehouseDevicesAdd = async (data: FoodWarehouseDevicesAddPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehouseDevicesAdd, data)
  return result.data.data
}

export const foodWarehouseDevicesRemove = async (data: FoodWarehouseDevicesRemovePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFoodWarehouseDevicesRemove, data)
  return result.data.data
}
