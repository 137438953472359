import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    id: `${_ctx.name}-${_ctx.id}`,
    disabled: _ctx.isLoading || _ctx.disabled,
    block: "",
    class: "btn",
    type: "submit",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('submit-clicked')), ["prevent"]))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_v_progress_circular, { key: 0 }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.isLoading ? _ctx.loadingText : _ctx.text), 1)
      ])
    ]),
    _: 1
  }, 8, ["id", "disabled"]))
}