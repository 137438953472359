<template>
  <v-container>
    <v-row>
      <v-col>
        <create-increment-group-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CreateIncrementGroupForm from '@/widgets/forms/CreateIncrementGroupForm.vue'

export default defineComponent({
  components: {
    CreateIncrementGroupForm
  }
})
</script>

<style lang='scss'></style>
