import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceReportSettingsFindPost, PlaceReportSettingsSavePost, PlaceReportSettingsRemovePost } from './api-types'

const enum PlaceReportSettingsRoutes {
  PlaceReportSettingsFind = '/placereportsettings/find',
  PlaceReportSettingsSave = '/placereportsettings/create',
  PlaceReportSettingsRemove = '/placereportsettings/delete',
}

export const find = async (data: PlaceReportSettingsFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(PlaceReportSettingsRoutes.PlaceReportSettingsFind, data)
  return result.data.data
}

export const save = async (data: PlaceReportSettingsSavePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(PlaceReportSettingsRoutes.PlaceReportSettingsSave, data)
  return result.data.data
}

export const remove = async (data: PlaceReportSettingsRemovePost): Promise<boolean> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: boolean;
  }> = await axios.post(PlaceReportSettingsRoutes.PlaceReportSettingsRemove, data)
  return result.data.data
}
