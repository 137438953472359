/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_DEVICES, CLEAR_DEVICES } from '@/store/deviceList/mutations'
import { RootState } from '@/store/state'
import {
  DeviceListState, Device
} from '@/store/deviceList/types'
import {
  all as deviceAll
} from '@/shared/api/deviceList/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type DeviceListContext = ActionContext<DeviceListState, RootState>

const initialState: DeviceListState = {
  devices: []
}

const getters: GetterTree<DeviceListState, RootState> = {
  devices (state: DeviceListState): Device[] {
    return state.devices
  }
}

const mutations: MutationTree<DeviceListState> = {
  [SET_DEVICES] (state: DeviceListState, devices: Device[]) {
    state.devices = devices
  },
  [CLEAR_DEVICES] (state: DeviceListState) {
    state.devices = []
  }
}

const actions: ActionTree<DeviceListState, RootState> = {
  async all (
    { commit, dispatch, state }: DeviceListContext
  ): Promise<Device[]> {
    try {
      commit(CLEAR_DEVICES)
      const result = await deviceAll()
      commit(SET_DEVICES, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const devices = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
