import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { IncrementGroupDevice } from '@/store/incrementGroupDevice/types'
import { IncrementGroupDeviceCreatePost } from './api-types'

const routeIncrementGroupDeviceList = '/incrementgroupdevices/all'
const routeIncrementGroupDeviceCreate = '/incrementgroupdevices/create'

export const all = async (): Promise<IncrementGroupDevice[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: IncrementGroupDevice[];
  }> = await axios.get(routeIncrementGroupDeviceList)
  return result.data.data
}

export const create = async (data: IncrementGroupDeviceCreatePost): Promise<IncrementGroupDevice[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: IncrementGroupDevice[];
  }> = await axios.post(routeIncrementGroupDeviceCreate, data)

  return result.data.data
}
