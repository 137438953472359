<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <place-selector
          v-model="selectedUserAllowedPlace"
          :selected-item-props="propPlaceId"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <month-selector
          v-model="selectedMonth"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <year-selector
          v-model="selectedYear"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <class-selector
          @on-change="classSelectorChangeHandler"
          :place-id-props="selectedUserAllowedPlace.id"
          @parallel-select="createReport"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="goToSettings()"
        >
          Настроить
        </v-btn>
      </v-col>
      <v-col
        v-if="parallels?.parallels?.length > 0"
        class="d-flex"
      >
        <v-btn
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <v-col
        v-else
        class="d-flex"
      >
        Необходимо настроить параллели
      </v-col>
      <v-col
        v-if="!isLoading && isPost"
        class="d-flex"
        cols="1"
        sm="1"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <div
        v-if="resultProducts.length > 0 && resultOrder.length > 0"
        id="printableArea"
      >
        <v-row class="justify-content-center">
          <v-col class="document-header">
            <v-col>
              <div class="document-header__title">
                <p class="text-center print-header">
                  Отчет <br>
                  о предоставлении питания за счет средств бюджета Санкт-Петербурга<br>
                  за {{ selectedMonthName.toLowerCase() }} {{ selectedYearName }} года
                </p>
              </div>
            </v-col>
            <v-col class="">
              <div class="document-header__organization">
                <p>"УТВЕРЖДАЮ"_____________</p>
                <!--              {{  selectedUserAllowedPlace }}-->
                <p>
                  Директор школы №_
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  _{{ customerPlaceInfo.directorName }}
                </p>
                <!--                <p>{{ customerPlaceInfo.directorPosition }} {{ customerPlaceInfo.fullName }}/{{ customerPlaceInfo.directorName }} /</p>-->
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="justify-content-center">
          <v-col
            v-if="!isLoading"
          >
            <v-table class="report-rateplan-day">
              <template #default>
                <thead>
                  <tr>
                    <th
                      class="text-center bold"
                      rowspan="4"
                    >
                      По категориям учащихся
                    </th>
                    <th
                      class="text-center bold"
                      :colspan="computeTotalColumnsCount() * 2"
                    >
                      Количество по всем приемам пищи
                    </th>
                    <th
                      class="text-center bold"
                      :colspan="computeTotalColumnsCount()"
                    >
                      Сумма (руб)
                    </th>
                    <th
                      class="text-center bold"
                      rowspan="4"
                    >
                      Сумма (руб)
                    </th>
                  </tr>
                  <tr>
                    <template
                      v-for="(mealType, idx) in resultProducts"
                      :key="`product-name-count-${idx}`"
                    >
                      <th
                        class="table-head__title text-center"
                        :class="`product-name-count-${idx}`"
                        :colspan="computeProductItemColumnsCount(mealType) * 2"
                      >
                        {{ `${mealType.name}` }}
                      </th>
                    </template>
                    <template
                      v-for="(mealType, idx) in resultProducts"
                      :key="`product-name-price-${idx}`"
                    >
                      <th
                        class="table-head__title text-center"
                        :class="`product-name-price-${idx}`"
                        :colspan="computeProductItemColumnsCount(mealType)"
                      >
                        {{ `${mealType.name}` }}
                      </th>
                    </template>
                  </tr>
                  <tr>
                    <template v-for="(mealType, productIdx) in resultProducts">
                      <template v-for="(productItem, productItemIdx) in mealType.productItems">
                        <template
                          v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                          :key="`${mealType.name}-count-${productIdx}-${productItemIdx}-${parallelIdx}`"
                        >
                          <th
                            class="table-head__title text-center"
                            :class="`${mealType.name}-count-${productIdx}-${productItemIdx}-${parallelIdx}`"
                            colspan="2"
                          >
                            {{ `${parallelName}` }}
                          </th>
                        </template>
                      </template>
                    </template>
                    <template v-for="(mealType, productIdx) in resultProducts">
                      <template v-for="(productItem, productItemIdx) in mealType.productItems">
                        <template
                          v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                          :key="`${mealType.name}-price-${productIdx}-${productItemIdx}-${parallelIdx}`"
                        >
                          <th
                            class="table-head__title text-center"
                            :class="`${mealType.name}-price-${productIdx}-${productItemIdx}-${parallelIdx}`"
                          >
                            {{ `${parallelName}` }}
                          </th>
                        </template>
                      </template>
                    </template>
                  </tr>
                  <tr>
                    <template v-for="(mealType, productIdx) in resultProducts">
                      <template v-for="(productItem, productItemIdx) in mealType.productItems">
                        <template
                          v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                          :key="`count-${mealType}-${productIdx}-${productItem}-${productItemIdx}-${parallelName}-${parallelIdx}`"
                        >
                          <th
                            class="text-center"
                            :class="`count-${mealType}-${productIdx}-${productItem}-${productItemIdx}-${parallelName}-${parallelIdx}`"
                          >
                            дето-дни
                          </th>
                          <th
                            class="text-center"
                            :class="`avg-count-${mealType}-${productIdx}-${productItem}-${productItemIdx}-${parallelName}-${parallelIdx}`"
                          >
                            кол-во детей
                          </th>
                        </template>
                      </template>
                    </template>
                    <template v-for="(mealType, productIdx) in resultProducts">
                      <template v-for="(productItem, productItemIdx) in mealType.productItems">
                        <template
                          v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                          :key="`total-${mealType}-${productIdx}-${productItem}-${productItemIdx}-${parallelName}-${parallelIdx}`"
                        >
                          <th
                            class="text-center"
                            :class="`total-${mealType}-${productIdx}-${productItem}-${productItemIdx}-${parallelName}-${parallelIdx}`"
                          >
                            сумма (руб)
                          </th>
                        </template>
                      </template>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="orderItem in resultOrder"
                    :key="`rate-plan-${orderItem.ratePlanId}`"
                  >
                    <tr
                      :class="`rate-plan-${orderItem.ratePlanId}`"
                    >
                      <td class="text-center">
                        {{ orderItem.ratePlanName }}
                      </td>
                      <template v-for="(mealType, productIdx) in resultProducts">
                        <template v-for="(productItem, productItemIdx) in mealType.productItems">
                          <template
                            v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                            :key="`${mealType.name}-${orderItem.ratePlanId}-${productIdx}-${productItemIdx}-${parallelIdx}-count`"
                          >
                            <td
                              :class="`${mealType.name}-${orderItem.ratePlanId}-${productIdx}-${productItemIdx}-${parallelIdx}-count`"
                            >
                              {{ getCount(productItem, orderItem, parallelName) }}
                            </td>
                            <td
                              :class="`${mealType.name}-${orderItem.ratePlanId}-${productIdx}-${productItemIdx}-${parallelIdx}-avg-count`"
                            >
                              {{ getAvgCount(productItem, orderItem, parallelName) }}
                            </td>
                          </template>
                        </template>
                      </template>
                      <template v-for="(mealType, productIdx) in resultProducts">
                        <template v-for="(productItem, productItemIdx) in mealType.productItems">
                          <template
                            v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                            :key="`${mealType.name}-${orderItem.ratePlanId}-${productIdx}-${productItemIdx}-${parallelIdx}-price`"
                          >
                            <td
                              :class="`${mealType.name}-${orderItem.ratePlanId}-${productIdx}-${productItemIdx}-${parallelIdx}-price`"
                            >
                              {{ getPrice(productItem, orderItem, parallelName) }}
                            </td>
                          </template>
                        </template>
                      </template>
                      <td>{{ getTotalPriceByRatePlan(orderItem) }}</td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-end bold">
                      итого
                    </th>
                    <template v-for="(mealType, productIdx) in resultProducts">
                      <template v-for="(productItem, productItemIdx) in mealType.productItems">
                        <template
                          v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                          :key="`${mealType.name}-${productIdx}-${productItemIdx}-${parallelIdx}-total-count`"
                        >
                          <th
                            :class="`${mealType.name}-${productIdx}-${productItemIdx}-${parallelIdx}-total-count`"
                          >
                            {{
                              getTotalByParallelParameter(productItem, parallelName, productIdx, productItemIdx, 'count')
                            }}
                          </th>
                          <th
                            :class="`${mealType.name}-${productIdx}-${productItemIdx}-${parallelIdx}-total-avg-count`"
                          >
                            {{
                              getTotalByParallelParameter(productItem, parallelName, productIdx, productItemIdx, 'avg-count')
                            }}
                          </th>
                        </template>
                      </template>
                    </template>
                    <template v-for="(mealType, productIdx) in resultProducts">
                      <template v-for="(productItem, productItemIdx) in mealType.productItems">
                        <template
                          v-for="(parallelName, parallelIdx) in productItem.parallelNames"
                          :key="`${mealType.name}-${productIdx}-${productItemIdx}-${parallelIdx}-total-price`"
                        >
                          <th
                            :class="`${mealType.name}-${productIdx}-${productItemIdx}-${parallelIdx}-total-price`"
                          >
                            {{
                              getTotalByParallelParameter(productItem, parallelName, productIdx, productItemIdx, 'price')
                            }}
                          </th>
                        </template>
                      </template>
                    </template>
                    <th class="bold">
                      {{ getSummaryPrice() }}
                    </th>
                  </tr>
                </tfoot>
              </template>
            </v-table>
          </v-col>
        </v-row>
        <template v-if="!isLoading">
          <!--          <v-row class="document-footer">-->
          <!--            <v-col>-->
          <!--              <div class="document-footer__summary">-->
          <!--                <p>Всего получено питания на сумму</p>-->
          <!--                <p class="document-footer__price">{{ getSummaryPrice() }}</p>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-row v-if="!isLoading">
            <v-col class="print-sign">
              Организатор питания _____________________________/{{ customerPlaceInfo.ladminName }}/
            </v-col>
          </v-row>
          <v-row v-if="!isLoading">
            <v-col class="print-sign">
              Заведующий производством _____________________________/{{ customerPlaceInfo.zavprName }}/
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
    <template v-else>
      <v-row>
        <v-col
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import PlaceSelector from '@/shared/uikit/selectors/PlaceSelector.vue'
import MonthSelector from '@/shared/uikit/selectors/MonthSelector.vue'
import YearSelector from '@/shared/uikit/selectors/YearSelector.vue'
import ClassSelector from '@/shared/uikit/selectors/ClassSelector.vue'
import { mapActions, mapGetters } from 'vuex'
import { PlaceElectronTalonFindWithItemsAndRatePlansPost } from '@/shared/api/placeElectronTalon/api-types'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { findWithItemsAndRatePlans } from '@/shared/api/placeElectronTalon/api-requests'
import { find } from '@/shared/api/customerPlace/api-requests'
import axios, { AxiosError, AxiosResponse } from 'axios'

type BaseData = {
  selectedUserAllowedPlaceId: number;
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlace: any;
  selectedMonth: any;
  selectedMonthName: string;
  selectedYearName: string;
  selectedYear: number;
  parallels: any;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsAndRatePlansPost;
  customerPlacePost: CustomerPlaceFindPost;
  customerPlaceInfo: any;
  placeElectronTalons: any;
  places: any;
  products: any;
  ratePlans: any;
  notAcceptDates: any;
  errorDates: any;
  resultOrder: any;
  resultProducts: any;
  workingDays: any;
}

export default defineComponent({
  components: {
    MonthSelector,
    YearSelector,
    PlaceSelector,
    ClassSelector
  },
  data (): BaseData {
    return {
      selectedUserAllowedPlaceId: 0,
      isLoading: false,
      isPost: false,
      selectedUserAllowedPlace: [],
      selectedMonth: { id: 1, name: 'Неизвестно' },
      selectedMonthName: 'Неизвестно',
      selectedYearName: 'Неизвестно',
      selectedYear: 0,
      parallels: {
        parallels: [], selectedParallel: 0
      },
      placeElectronTalonGetByIdPost: {
        parentPlaceId: 0,
        dateFrom: '',
        dateTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      customerPlaceInfo: null,
      placeElectronTalons: [],
      places: [],
      errorDates: [],
      notAcceptDates: [],
      products: [],
      ratePlans: [],
      resultOrder: [],
      resultProducts: [],
      workingDays: {}
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  beforeMount () {
    this.selectedYear = moment().format('YYYY')
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    computeTotalColumnsCount () {
      let res = 0
      this.resultProducts.forEach((product: any) => {
        product.productItems.forEach((productItem: any) => {
          res += productItem.parallelNames.length
        })
      })
      return res
    },
    computeProductItemColumnsCount (product: any) {
      let res = 0
      product.productItems.forEach((productItem: any) => {
        res += productItem.parallelNames.length
      })
      return res
    },
    loadDataFromFile: async (name: string): Promise<any> => {
      const result: AxiosResponse<{
        data: any;
      }> = await axios.get(`/${name}`)
      return result.data.data
    },
    async loadPlaceElectronTalons () {
      try {
        if (this.selectedUserAllowedPlace !== 0) {
          this.placeElectronTalonGetByIdPost.parentPlaceId = this.selectedUserAllowedPlace.id
          this.placeElectronTalonGetByIdPost.dateFrom = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('YYYY-MM-DD')
          this.placeElectronTalonGetByIdPost.dateTo = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).format('YYYY-MM-DD')

          // Подгрузка даных
          // const result = await this.loadDataFromFile('example1.json') // с локального файла с папки public
          const result = await findWithItemsAndRatePlans(this.placeElectronTalonGetByIdPost) // с API
          const placeElectronTalonsAnswer = JSON.parse(JSON.stringify(result))
          // Заполнение модели данными с API
          // (  placeElectronTalons - сами талоны,
          //    products - рационы,
          //    ratePlans - льготные категории,
          //    places - классы

          if (Array.isArray(placeElectronTalonsAnswer.placeEletronTalons)) {
            this.placeElectronTalons = placeElectronTalonsAnswer.placeEletronTalons
          }
          if (Array.isArray(placeElectronTalonsAnswer.products)) {
            this.products = placeElectronTalonsAnswer.products
          }
          if (Array.isArray(placeElectronTalonsAnswer.places)) {
            this.places = placeElectronTalonsAnswer.places
          }
          if (Array.isArray(placeElectronTalonsAnswer.ratePlans)) {
            this.ratePlans = placeElectronTalonsAnswer.ratePlans
          }
          if (this.places.length > 0 && this.products.length > 0 && this.ratePlans.length > 0 && this.placeElectronTalons.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        console.log('error', error)
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        // this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.selectedUserAllowedPlace !== 0) {
          this.customerPlacePost.placeId = this.selectedUserAllowedPlace.id
          this.customerPlacePost.date = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).format('YYYY-MM-DD')

          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        console.log('error', error)
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    classSelectorChangeHandler (event: any) {
      this.parallels = event
    },
    createReport () {
      this.clearData()
      // Сопоставляем, в каждый класс из базы, диапазон (classType), который указан в настройках построения таблицы
      this.places.forEach((element: any) => {
        const parallel = this.parallels.parallels.find((parallel: any) => {
          if (element.name.match(/^\d+/)) {
            if (parseInt(element.name) >= parallel.startValue && parseInt(element.name) <= parallel.endValue) {
              return true
            }
          }
        })
        if (parallel) {
          element.classType = parallel.classType
        }
        // else {
        //   element.classType = 'Параллель не создана'
        // }
      })

      const workingDays: any = {}
      const resultOrder: any = []
      const resultProducts: any = []
      this.placeElectronTalons.forEach((placeElectronTalon: any) => {
        // Заполняем массив рабочих дней

        // Заполняем объект resultOrder в нужном нам формате для отрисовки таблицы
        placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
          if (!placeElectronTalonItem.removed) {
            placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
              const ratePlanId = placeElectronTalonItem.userRatePlan
              const placeId = placeElectronTalon.placeId
              const productId = placeElectronTalonItemSale.productId
              const classType = this.places.find((place: any) => place.id === placeId).classType

              // TODO: сделать подсчет рабочих для каждой паралелли отдельно?
              if (!workingDays[classType]) {
                workingDays[classType] = [placeElectronTalon.date]
              } else {
                if (!workingDays[classType].includes(placeElectronTalon.date)) {
                  workingDays[classType].push(placeElectronTalon.date)
                }
              }

              const price = +placeElectronTalon.placeElectronTalonProducts.find((talonProduct: any) => talonProduct.productId === productId).price
              if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId != null) {
                try {
                  const ratePlanIndex = resultOrder.findIndex((order: any) => order.ratePlanId === ratePlanId)
                  if (ratePlanIndex !== -1) {
                    const classTypeIndex = resultOrder[ratePlanIndex].ratePlanItems.findIndex((el: any) => el.classTypeId === classType)
                    if (classTypeIndex !== -1) {
                      const productIndex = resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems.findIndex((product: any) => product.productId === productId)
                      if (productIndex !== -1) {
                        const priceIndex = resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems[productIndex].prices.findIndex((priceItem: any) => priceItem.price === price)
                        if (priceIndex !== -1) {
                          resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems[productIndex].prices[priceIndex].count += 1
                        } else {
                          resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems[productIndex].prices.push({
                            price: price,
                            count: 1
                          })
                        }
                        resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems[productIndex].count += 1
                      } else {
                        resultOrder[ratePlanIndex].ratePlanItems[classTypeIndex].classTypeItems.push({
                          productId: productId,
                          count: 1,
                          productItem: this.products.find((product: any) => product.id === productId),
                          price: price,
                          prices: [
                            {
                              price: price,
                              count: 1
                            }
                          ]
                        })
                      }
                    } else {
                      if (classType) {
                        resultOrder[ratePlanIndex].ratePlanItems.push({
                          classTypeId: classType,
                          classTypeName: this.parallels.parallels.find((parallel: any) => parallel.classType === classType).name,
                          classTypeItems: [
                            {
                              productId: productId,
                              count: 1,
                              productItem: this.products.find((product: any) => product.id === productId),
                              price: price,
                              prices: [
                                {
                                  price: price,
                                  count: 1
                                }
                              ]
                            }
                          ]
                        })
                      }
                    }
                  } else {
                    if (classType) {
                      resultOrder.push({
                        ratePlanId: ratePlanId,
                        ratePlanName: this.ratePlans.find((ratePlan: any) => ratePlan.id === ratePlanId).name,
                        ratePlanItems: [{
                          classTypeId: classType,
                          classTypeName: this.parallels.parallels.find((parallel: any) => parallel.classType === classType).name,
                          classTypeItems: [
                            {
                              productId: productId,
                              count: 1,
                              productItem: this.products.find((product: any) => product.id === productId),
                              price: price,
                              prices: [
                                {
                                  price: price,
                                  count: 1
                                }
                              ]
                            }
                          ]
                        }]
                      })
                    }
                  }
                } catch (e) {
                  console.log(e)
                }
              } else {
                // TODO что делать, когда removed == null?
                if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                  if (!this.notAcceptDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notAcceptDates.push(moment(placeElectronTalon.date).format('DD'))
                }
              }
            })
          }
          if (placeElectronTalonItem.error != null) {
            if (String(placeElectronTalonItem.error) !== '15') {
              if (!this.errorDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.errorDates.push(moment(placeElectronTalon.date).format('DD'))
            }
          }
        })
      })
      try {
        this.products.forEach((product: any) => {
          const name = product.name.split(' ')[0].toLowerCase()
          const mealType = product.name.split(' ')[0].toLowerCase()
          const mealTypeIndex = resultProducts.findIndex((resProd: any) => resProd.name === mealType)
          const classTypes: any = []
          const parallelNames: any = []
          resultOrder.forEach((orderItem: any) => {
            orderItem.ratePlanItems.forEach((ratePlanItem: any) => {
              const classTypeId = ratePlanItem.classTypeId
              const parallelName = ratePlanItem.classTypeName
              ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
                if (classTypeItem.productId === product.id && classTypes.findIndex((id: any) => id === classTypeId) === -1) {
                  classTypes.push(classTypeId)
                  parallelNames.push(parallelName)
                }
              })
            })
          })
          if (classTypes.length !== 0 && parallelNames.length !== 0) {
            if (mealTypeIndex !== -1) {
              const productItemIndex = resultProducts[mealTypeIndex].productItems.findIndex((productItem: any) => productItem.id === product.id)
              if (productItemIndex === -1) {
                resultProducts[mealTypeIndex].productItems.push({
                  id: product.id,
                  classTypes: classTypes,
                  parallelNames: parallelNames,
                  content: { product }
                })
              }
            } else {
              resultProducts.push({
                name: name,
                productItems: [{
                  id: product.id,
                  classTypes: classTypes,
                  parallelNames: parallelNames,
                  content: product
                }]
              })
            }
          }
        })
      } catch (e) {
        console.log(e)
      }

      this.resultProducts = resultProducts
      this.resultOrder = resultOrder
      this.workingDays = workingDays
      // console.log(this.resultProducts)
      // console.log(this.resultOrder)
    },
    getCount (productItem: any, orderItem: any, parallelName: string) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) => {
        ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
          if (classTypeItem.productId === productItem.id && ratePlanItem.classTypeName === parallelName) {
            classTypeItem.prices.forEach((priceItem: any) => {
              res += priceItem.count
            })
          }
        })
      })
      return res
    },
    getPrice (productItem: any, orderItem: any, parallelName: string) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) => {
        ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
          if (classTypeItem.productId === productItem.id && ratePlanItem.classTypeName === parallelName) {
            classTypeItem.prices.forEach((priceItem: any) => {
              res += priceItem.count * priceItem.price
            })
          }
        })
      })
      return Number.isInteger(res) ? res : res.toFixed(2)
    },
    getAvgCount (productItem: any, orderItem: any, parallelName: string) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) => {
        ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
          if (classTypeItem.productId === productItem.id && ratePlanItem.classTypeName === parallelName) {
            res = classTypeItem.count / this.workingDays[ratePlanItem.classTypeId].length
          }
        })
      })
      return Number.isInteger(res) ? res : res.toFixed(2)
    },
    getTotalByParallelParameter (productItem: any, parallelName: string, productIdx: number, productItemIdx: number, category: string) {
      let res = 0
      productItem.classTypes.forEach((classType: any) => {
        this.resultOrder.forEach((orderItem: any) => {
          orderItem.ratePlanItems.forEach((ratePlanItem: any) =>
            ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
              if (classTypeItem.productId === productItem.id && ratePlanItem.classTypeId === classType && ratePlanItem.classTypeName === parallelName) {
                switch (category) {
                  case 'count':
                    classTypeItem.prices.forEach((priceItem: any) => {
                      res += priceItem.count
                    })
                    break
                  case 'avg-count':
                    classTypeItem.prices.forEach((priceItem: any) => {
                      res += priceItem.count / this.workingDays[ratePlanItem.classTypeId].length
                    })
                    break
                  case 'price':
                    classTypeItem.prices.forEach((priceItem: any) => {
                      res += priceItem.count * priceItem.price
                    })
                    break
                }
              }
            }))
        })
      })
      return Number.isInteger(res) ? res : res.toFixed(2)
    },
    getTotalPriceByRatePlan (orderItem: any) {
      let res = 0
      orderItem.ratePlanItems.forEach((ratePlanItem: any) =>
        ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
          classTypeItem.prices.forEach((priceItem: any) => {
            res += priceItem.count * priceItem.price
          })
        }))
      return Number.isInteger(res) ? res : res.toFixed(2)
    },
    getSummaryPrice () {
      let res = 0
      this.resultOrder.forEach((item: any) => {
        item.ratePlanItems.forEach((ratePlanItem: any) => {
          ratePlanItem.classTypeItems.forEach((classTypeItem: any) => {
            classTypeItem.prices.forEach((priceItem: any) => {
              res += priceItem.count * priceItem.price
            })
          })
        })
      })
      return Number.isInteger(res) ? res : res.toFixed(2)
    },
    goToSettings () {
      this.$router.push({
        name: 'ReportSettings',
        query: {
          placeId: this.selectedUserAllowedPlace.id,
          report: 'ReportRatePlanDayRatePlanByAgeV2'
        }
      })
    },
    clearData () {
      this.resultOrder = []
      this.resultProducts = []
      this.workingDays = {}
    },
    async load () {
      this.isLoading = true
      this.placeElectronTalons = []
      this.places = []
      this.products = []
      this.ratePlans = []
      this.errorDates = []
      this.notAcceptDates = []
      this.clearData()
      this.customerPlaceInfo = null
      await this.loadCustomerPlaceInfo()
      await this.loadPlaceElectronTalons()
      this.isLoading = false
      this.isPost = true
      this.selectedMonthName = this.selectedMonth.name
      this.selectedYearName = this.selectedYear.toString()
    },
    print () {
      const printContent = document.getElementById('printableArea')
      const WinPrint = window.open('')
      if (WinPrint && printContent) {
        WinPrint.document.write(`
          <html>
            <head>
              <title></title>
            </head>
            <body>
            <div class="">
            <input class="print-button" type="button" value="Печать" onclick="print()">
              ${printContent.innerHTML}
            </div>
            </body>
          </html>
        `)
        const link = WinPrint.document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        link.setAttribute('href', '/css/print.css')
        WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
        WinPrint.focus()
      }
      // await (this as any).$htmlToPaper('printableArea')
    }
  }
})
</script>

<style lang="scss">

.report-rateplan-day {
  .bold {
    font-weight: bold !important;
  }

  .table-head__title {
    //text-transform: capitalize;
  }

  th {
    font-weight: normal !important;
    border: 1px solid grey;
  }
}

.document-header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {

  }

  .document-header__organization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    width: max-content;
    text-align: start;
    margin-bottom: 16px;

    p {
      //font-size: 12px;
      margin: 4px;
    }
  }
}

.document-footer {
  font-weight: bold;

  .document-footer__summary {
    display: flex;
    justify-content: space-between;

    .document-footer__price {
      border: 2px solid black;
      padding: 0 60px;
    }
  }
}

.after-sign {
  font-size: 10px;
}

.print-sign {
  margin: 16px 0;
}
</style>
