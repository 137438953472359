<template>
  <v-container class="convertedRawApiData">
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Настройка инкрементального обновления</strong>
        </h1>
        <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
            <v-select
              v-model="selectedIncrementGroups"
              :items="selectIncrementGroups"
              item-title="name"
              item-value="id"
              label="Группы обновлений"
            >
              <template #prepend-item>
                <v-list-item>
                  <v-text-field
                    v-model="searchTerm"
                    placeholder="Поиск"
                    @update:model-value="searchIncrementGroups"
                  />
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
            </v-select>
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3"
          >
            <submit-button
              color="success"
              name="device-group-submit-button"
              text="Назначить"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Подождите..."
              @submit-clicked="handleSubmit"
            />
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3"
          >
            <v-btn
              name="increment-group-edit-button"
              class="btn"
              type="submit"
              variant="green"
              block
              :to="{ name: 'IncrementGroupDashboard' }"
            >
              Настроить группы
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="deviceList"
          :single-select="singleSelect"
          item-key="userId"
          show-select
          class="elevation-1"
          :search="search"
          search-text="Поиск"
          :items-per-page="10"
          :footer-props="footerProps"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
        >
          <template #top>
            <v-switch
              v-model="singleSelect"
              label="Single select"
              class="pa-3"
            />
            <v-text-field
              v-model="search"
              label="Поиск"
              append-icon="search"
              class="mx-4"
            />
          </template>
<!--          <template #top>-->
<!--            -->
<!--          </template>-->
          <template #no-data>
            <v-alert
              v-if="loading==false&&deviceList.length==0"
              :value="true"
              icon="warning"
            >
              Нет данных
            </v-alert>
            <v-alert
              v-else
              :value="false"
              icon="warning"
            >
              Нет данных
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ServerError } from '@/shared/lib/api'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import { IncrementGroupDeviceCreatePost } from '@/shared/api/incrementGroupDevice/api-types'

type BaseData = {
  incrementGroupDeviceCreatePost: IncrementGroupDeviceCreatePost;
  isLoading: boolean;
  result: any;
  footerProps: any;
  search: string;
  searchTerm: string;
  loading: boolean;
  singleSelect: boolean;
  selected: any;
  incrementGroupsCopy: any;
  selectIncrementGroups: any;
  selectedIncrementGroups: any;
  valid: boolean;
}
export default defineComponent({
  components: {
    SubmitButton
  },
  data (): BaseData {
    return {
      footerProps: {
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Количество на странице',
        itemsPerPageOptions: [10, 20, 30, 100, 1000, -1]
      },
      search: '',
      searchTerm: '',
      loading: false,
      singleSelect: false,
      selected: [],
      incrementGroupsCopy: [''],
      selectIncrementGroups: [''],
      selectedIncrementGroups: [],
      valid: true,
      isLoading: false,
      incrementGroupDeviceCreatePost: {
        incrementGroupId: 0,
        devices: []
      },
      result: []
    }
  },
  computed: {
    ...mapGetters({ devices: 'devices/devices' }),
    ...mapGetters({ incrementGroups: 'incrementGroups/incrementGroups' }),
    ...mapGetters({ incrementGroupDevices: 'incrementGroupDevices/incrementGroupDevices' }),
    deviceList (): any {
      return (this as any).incrementGroupDeviceGet()
    },
    headers () {
      return [
        {
          title: 'Описание',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        { title: 'Логин', value: 'login' },
        { title: 'Id', value: 'userId', filterable: false },
        { title: 'Группа', value: 'incrementGroupDevice.incrementDeviceGroupName', filterable: false }
      ]
    }
  },
  watch: {

  },
  methods: {
    ...mapActions('devices', { deviceAll: 'all' }),
    ...mapActions('incrementGroups', { incrementGroupAll: 'all' }),
    ...mapActions('incrementGroupDevices', { incrementGroupDeviceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('incrementGroupDevices', { incrementGroupDeviceCreate: 'create' }),
    incrementGroupDeviceGet () {
      const temp: any = this.devices.map((device: any) => {
        const thisIncrementGroupDevices = this.incrementGroupDevices.find((incrementGroupDevice: any) => incrementGroupDevice.deviceId === device.userId)
        if (thisIncrementGroupDevices != null) {
          const incrementDeviceGroupName = this.incrementGroups.find((incrementGroup: any) => incrementGroup.id === thisIncrementGroupDevices.incrementGroupId)
          thisIncrementGroupDevices.incrementDeviceGroupName = incrementDeviceGroupName.name
        }
        device.incrementGroupDevice = thisIncrementGroupDevices
        this.loading = false
        return device
      })
      return temp
    },
    async loadDevices () {
      try {
        await this.deviceAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    async loadIncrementGroups () {
      try {
        await this.incrementGroupAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.selectIncrementGroups = [...this.incrementGroups]
        this.incrementGroupsCopy = [...this.incrementGroups]
      }
    },
    async loadIncrementGroupDevices () {
      try {
        await this.incrementGroupDeviceAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    searchIncrementGroups () {
      if (!this.searchTerm) {
        this.selectIncrementGroups = this.incrementGroupsCopy
      }

      this.selectIncrementGroups = this.incrementGroupsCopy.filter((incrementGroupCopy: any) => {
        return incrementGroupCopy.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    async handleSubmit () {
      this.isLoading = true
      try {
        this.incrementGroupDeviceCreatePost = {
          incrementGroupId: this.selectedIncrementGroups,
          devices: this.selected
        }
        this.result = await this.incrementGroupDeviceCreate(this.incrementGroupDeviceCreatePost)
        this.incrementGroupDeviceCreatePost = {
          incrementGroupId: 0,
          devices: []
        }
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.loading = true
    this.loadDevices()
    this.loadIncrementGroups()
    this.loadIncrementGroupDevices()
  }
})
</script>

<style lang='scss'></style>
