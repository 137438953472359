// Mutation Observer
// https://bootstrap-vue.js.org/docs#migrating-a-project-already-using-bootstrap
import 'mutationobserver-shim'

// Older browser fix github.com/johndatserakis/koa-vue-notes-api/issues/1
// import "es6-promise/auto";
// Intersection Observer polyfill
import 'intersection-observer'

// Date.toISOString() polyfill
import '@/shared/lib/toISOStringPolyfill'

import 'material-design-icons-iconfont/dist/material-design-icons.css'

import { App as AppType, createApp } from 'vue'
import { router, store } from '@/app/providers'
// import VueProgressBar from 'vue-progressbar'
import VueHtmlToPaper from 'vue-html-to-paper'
import { runAxiosAuthInterceptor } from '@/shared/lib/axiosAuthInterceptor'
import App from '@/app/App.vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import MomentAdapter from '@date-io/moment'
import { ru } from 'vuetify/locale'


export interface AppCustom<Element> extends AppType<Element> {
  router?: any
}

const app: AppCustom<Element> = createApp(App)

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    '/css/print.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}

runAxiosAuthInterceptor()

// app.config.productionTip = false

// app.use(VueProgressBar, {
//   color: '#ADE027',
//   failedColor: '#F43D41',
//   thickness: '3px',
//   transition: {
//     speed: '0.2s',
//     opacity: '0.6s',
//     termination: 300
//   },
//   autoRevert: true,
//   location: 'top',
//   inverse: false
// })
const momentAdapter = new MomentAdapter({ locale: 'ru-RU' })
const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: { ru }
  },
  date: {
    adapter: momentAdapter,
    locale: {
      ru: 'ru'
    }
  }
})

app.router = router
app.use(VueHtmlToPaper, options)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
