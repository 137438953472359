<template>
  <div class="bank-transaction-detail">
    <div class="filters-panel">
      <v-select
        v-model="detailValue"
        class="mr-5"
        style="max-width:200px;"
        :items="detailOptions"
        item-title="name"
        item-value="id"
        label="Вид детализации"
        @update:model-value="detailChangeHandler"
      />
      <div style="width: 100px; margin-left: auto;">
        <v-btn
          :is-loading="isLoading"
          block
          @click="back()"
        >
          Назад
        </v-btn>
      </div>
    </div>
    <p>* Школа определена на дату построения отчета</p>
    <h1 class="bank-transaction-detail__title">
      Детальный отчет по реестру № {{ number }}
    </h1>
    <h3 class="bank-transaction-detail__title">
      {{ date }}
    </h3>
    <div
      class="wrapper"
      :style="{display: 'flex', justifyContent: 'center', width: '100%'}"
    >
      <template v-if="!isLoading">
        <template v-if="tableData.items.length > 0">
          <base-simple-table :table-data="tableData" />
        </template>
        <template v-else>
          <h3>Данных не найдено, повторите попытку позже...</h3>
        </template>
      </template>
      <template v-else>
        <v-row>
          <v-col
            v-if="isLoading"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import moment from 'moment'
import { findAcquiringOrderRegisterById } from '@/shared/api/acquiring/api-requests'
import { AxiosError } from 'axios'
import { mapActions } from 'vuex'
import { findAcquiringOrderRegisterByIdResponseData } from '@/shared/api/acquiring/api-types'

export default defineComponent({
  name: 'BankTransactionsDetail',
  components: { BaseSimpleTable },
  props: {
    id: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    number: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      tableData: {} as TableData,
      menu1: false,
      menu2: false,
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().add('days', 1).format('YYYY-MM-DD'),
      rawApiData: [] as findAcquiringOrderRegisterByIdResponseData[],
      rawApiDataBeforeFilter: [] as findAcquiringOrderRegisterByIdResponseData[],
      detailOptions: [
        { id: 0, name: 'Полный' },
        { id: 1, name: 'Только продажи' },
        { id: 2, name: 'Только поступления' }
      ],
      detailValue: 0
    }
  },
  async beforeMount () {
    await this.findOrderRegisterById()
    // await this.loadTable()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async findOrderRegisterById () {
      this.tableData.columnsCount = 8
      this.tableData.items = []
      this.isLoading = true
      try {
        const findAcquiringOrderRegisterByIdReqObj = {
          id: +this.id
        }
        this.rawApiData = this.rawApiDataBeforeFilter = await findAcquiringOrderRegisterById(findAcquiringOrderRegisterByIdReqObj)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        await this.detailChangeHandler()
        this.isLoading = false
      }
    },
    detailChangeHandler () {
      if (this.rawApiDataBeforeFilter?.length > 0) {
        if (this.detailValue === 0) {
          this.rawApiData = this.rawApiDataBeforeFilter
        } else if (this.detailValue === 1) {
          this.rawApiData = this.rawApiDataBeforeFilter.filter(rawItem => {
            return rawItem.isSale === 1
          })
        } else if (this.detailValue === 2) {
          this.rawApiData = this.rawApiDataBeforeFilter.filter(rawItem => {
            return rawItem.isSale !== 1
          })
        }
      }
      this.loadTable(this.rawApiData)
    },
    async loadTable (rawApiData: findAcquiringOrderRegisterByIdResponseData[]) {
      const res: TableData = {
        rowsCount: 0,
        columnsCount: 0,
        items: []
      }
      if (rawApiData.length > 0) {
        res.items.push(
          {
            text: '№ ЛС',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 1,
            gridColumnEnd: 2,
            background: '#faebd7',
            fontWeight: 'bold',
            padding: '5px',
            fontSize: '16px'
          },
          {
            text: 'ФИО',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            background: '#faebd7',
            fontWeight: 'bold',
            padding: '5px',
            fontSize: '16px'
          },
          {
            text: 'Школа',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 3,
            gridColumnEnd: 4,
            background: '#faebd7',
            fontWeight: 'bold',
            padding: '5px',
            fontSize: '16px'
          },
          {
            text: 'Сумма, руб',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 4,
            gridColumnEnd: 5,
            background: '#faebd7',
            fontWeight: 'bold',
            padding: '5px',
            fontSize: '16px'
          },
          {
            text: 'Дата и время',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 5,
            gridColumnEnd: 6,
            background: '#faebd7',
            fontWeight: 'bold',
            padding: '5px',
            fontSize: '16px'
          }
        )
        let currentRow = 2
        rawApiData.forEach(resItem => {
          let $school = null
          if (resItem.isSale === 1) {
            if (resItem?.acquiringSaleOrders?.[0]?.saleBatch?.saleBatchSales?.[0]?.sale?.place?.name) {
              $school = `${resItem?.acquiringSaleOrders?.[0]?.saleBatch?.saleBatchSales?.[0]?.sale?.place?.name} (${resItem?.acquiringSaleOrders?.[0]?.deviceId})`
            } else {
              $school = `Продажа не сформирована (${resItem?.acquiringSaleOrders?.[0]?.deviceId})`
            }
          } else {
            resItem.account.user.userPlaces.forEach(function (item: any) {
              if (item.timestampFrom != null) {
                if (moment(item.timestampFrom).isAfter(moment(moment().format('YYYY-MM-DD')))) {
                  return
                }
              }
              if (item.timestampTo != null) {
                if (moment(item.timestampTo).isSameOrBefore(moment(moment().format('YYYY-MM-DD')))) {
                  return
                }
              }
              if (item.place == null) {
                return
              }
              if (item.place.typeId === 4) {
                $school = item.place.name
              }
              if (item.place.parentPlace == null) {
                return
              }
              if (item.place.parentPlace.typeId === 4) {
                $school = item.place.parentPlace.name
              }
            })
          }
          res.items.push(
            {
              text: `${resItem.paymentKey}`,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 1,
              gridColumnEnd: 2,
              background: 'null',
              fontWeight: 'bold',
              padding: '5px',
              fontSize: '14px'
            },
            {
              text: `${resItem.account.user.lastName} ${resItem.account.user.firstName} ${resItem.account.user.middleName}`,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 2,
              gridColumnEnd: 3,
              background: 'null',
              fontWeight: 'bold',
              padding: '5px',
              fontSize: '14px'
            },
            {
              text: `${$school} *`,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 3,
              gridColumnEnd: 4,
              background: 'null',
              fontWeight: 'bold',
              padding: '5px',
              fontSize: '14px'
            },
            {
              text: `${resItem.sum}`,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 4,
              gridColumnEnd: 5,
              background: 'null',
              fontWeight: 'bold',
              padding: '5px',
              fontSize: '14px'
            },
            {
              text: `${resItem.creationDate}`,
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 5,
              gridColumnEnd: 6,
              background: 'null',
              fontWeight: 'bold',
              padding: '5px',
              fontSize: '14px'
            }
          )
          currentRow += 1
        })
        res.rowsCount = currentRow
      } else {
        res.columnsCount = 0
        res.rowsCount = 0
      }
      this.tableData = res
    },
    back () {
      this.$router.push({ name: 'BankTransactionsSummary' })
    }
  }
})
</script>

<style scoped lang="scss">
.filters-panel {
  display: flex;
  width: 100%;
  justify-content: center;
}

.bank-transaction-detail {
  padding: 20px;
  margin-top: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;

  .bank-transaction-detail__title {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
}
</style>
