<template>
  <v-container class="ma-4">
    <v-row>
      <v-col>
        <login-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoginForm from '@/widgets/forms/LoginForm.vue'

export default defineComponent({
  name: 'LoginView',
  components: {
    LoginForm
  }
})
</script>

<style lang="scss"></style>
