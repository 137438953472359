import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    modelValue: _ctx.selectedUserAllowedPlace,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUserAllowedPlace) = $event)),
    multiple: "",
    items: _ctx.userAllowedPlacesList,
    "item-title": "place.name",
    "item-value": "place.id",
    label: "Школа",
    change: _ctx.update()
  }, {
    "prepend-item": _withCtx(() => [
      _createVNode(_component_v_list_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.searchTerm,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
              _ctx.searchUserAllowedPlaces
            ],
            placeholder: 'Поиск'
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "items", "change"]))
}