import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { FreeInterimCardsFindPost } from './api-types'

const routeFreeInterimCardsFind = 'interimcards/findfree'

export const freeInterimCardsFind = async (data: FreeInterimCardsFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeFreeInterimCardsFind, data)
  return result.data.data
}
