<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row class="justify-content-center">
      <v-col>
        <v-row
          justify="space-around"
          style="margin: 0px; padding: 0px;"
        >
          <v-col style="padding: 0px; margin-top: 7px;">
            <v-menu
              :model-value="menuState()"
              :close-on-content-click="false"
              :nudge-left="60"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ props }">
                <v-text-field
                  style="padding: 0px; width: 95%;"
                  :model-value="dateRangeText()"
                  prepend-icon="mdi-calendar"
                  readonly

                  v-bind="props"
                  @click="menuChangeState()"
                />
              </template>
              <v-date-picker
                hide-weekdays
                v-model="dateRange"
                multiple="range"
                @input="menuChangeState()"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="4"
            class="align-right justify-right"
            style="padding: 0px 5px 0px 0px;"
          >
            <v-btn
              class="ma-2 bg-primary"
              variant="outlined"
              width="100%"
              @click="loadStatistic()"
            >
              Загрузить
            </v-btn>
          </v-col>
        </v-row>
        <!--<v-row justify="space-around" style="margin: 0px; padding: 0px;">
          <v-col style="padding: 0px;">
            <v-select :items=telegramSupportOperators item-text="name" item-value="id" v-model="selectedOperator"></v-select>
          </v-col>
        </v-row>-->
        <v-row
          justify="center"
          align="center"
          style="margin-top: 0px;"
        >
          <v-col v-if="loadingStatistic">
            <p
              class="text-center"
              style="padding-top: 30px;  padding-bottom: 30px;"
            >
              <v-progress-circular
                class="text-center"
                :size="150"
                :width="15"
                color="blue"
                indeterminate
              />
            </p>
          </v-col>
          <v-col v-else>
            <v-table class="green-header-table">
              <thead>
                <tr>
                  <th style="background: antiquewhite; color: black !important">
                    Оператор
                  </th>
                  <th style="background: antiquewhite; color: black !important; padding: 3px;">
                    Раб.
                  </th>
                  <th style="background: antiquewhite; color: black !important; padding: 3px;">
                    Нераб.
                  </th>
                  <th style="background: antiquewhite; color: black !important; padding: 3px;">
                    Контр.
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in telegramSupportOperators">
                  <tr
                    v-if="item.id"
                    :key="index"
                  >
                    <td> {{ item.name }}</td>
                    <td @click="openDialog(item.work)">
                      {{ item.work.length }}
                    </td>
                    <td @click="openDialog(item.notwork)">
                      {{ item.notwork.length }}
                    </td>
                    <td @click="openDialog(item.control)">
                      {{ item.control.length }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-table>
          </v-col>
        </v-row>

        <v-row
          v-if="!loadingStatistic && (countPeriodActiveAccounts != null)"
          justify="space-around"
          style="margin: 0px; padding-top: 10px;"
        >
          <v-col style="padding: 0px;">
            <h4>Воспользовались: {{ countActivePaymentUsers }}</h4>
          </v-col>
        </v-row>

        <v-row
          v-if="!loadingStatistic && (countPeriodActiveAccounts != null)"
          justify="space-around"
          style="margin: 0px; padding-top: 10px;"
        >
          <v-col style="padding: 0px;">
            <h4>Оплатили за период: {{ countPeriodActiveAccounts }}</h4>
          </v-col>
        </v-row>

        <v-row
          v-if="!loadingStatistic && (countActiveAccounts != null)"
          justify="space-around"
          style="margin: 0px;"
        >
          <v-col style="padding: 0px;">
            <h4>Платных аккаунтов на сегодня: {{ countActiveAccounts }}</h4>
          </v-col>
        </v-row>

        <v-row
          v-if="!loadingStatistic && (countActiveAutopayments != null)"
          justify="space-around"
          style="margin: 0px; padding: 0px;"
        >
          <v-col style="padding: 0px;">
            <h4>Из них включены автоплатежи у {{ countActiveAutopayments }}</h4>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <TelegramBotSupportTicketsModal
      v-model:opened="showTicketsModal"
      :data="telegramSupportOperatorTickets"
      @close-modal="closeModal()"
    />
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { telegramSupportGetOperatorList, telegramSupportGetOperatorStatistic } from '@/shared/api/telegram/api-requests'
import {
  TelegramSupportGetOperatorListPost,
  TelegramSupportGetOperatorStatisticPost
} from '@/shared/api/telegram/api-types'
import {
  TelegramSupportOperator,
  TelegramSupportTicket,
  TelegramSupportTicketAnswere,
  TelegramSupportTicketResponse,
  TelegramWeekend
} from '@/store/telegram/types'
import moment from 'moment'
import { AxiosError } from 'axios'
import TelegramBotSupportTicketsModal from '../../widgets/modals/telegrambot/support/TelegramBotSupportTicketsModal.vue'

type BaseData = {
  token: string;
  loading: boolean;
  loadingStatistic: boolean;
  error: boolean;
  errorMessage: string;
  errorMessageUnknown: string;
  dateRange: string[];
  menu: number;
  selectedOperator: number;
  countPeriodActiveAccounts: number | null;
  countActiveAccounts: number | null;
  countActiveAutopayments: number | null;
  countActivePaymentUsers: number | null;
  telegramSupportOperators: Array<any>;
  telegramSupportOperatorTickets: Array<any>;
  showTicketsModal: boolean;
  tickets: TelegramSupportTicket[];
  weekends: TelegramWeekend[];
  telegramSupportGetOperatorListParams: TelegramSupportGetOperatorListPost;
  telegramSupportGetOperatorStatisticGetParams: TelegramSupportGetOperatorStatisticPost;
}

export default defineComponent({
  components: {
    TelegramBotSupportTicketsModal
  },
  props: {
    tokenProp: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      token: '',
      loading: true,
      loadingStatistic: true,
      error: false,
      menu: 0,
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Неизвестная',
      dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      telegramSupportOperators: [
        {
          id: 0,
          name: 'Все',
          status: 1,
          work: [],
          notwork: [],
          control: []
        }
      ],
      telegramSupportOperatorTickets: [],
      showTicketsModal: false,
      selectedOperator: 0,
      countPeriodActiveAccounts: null,
      countActiveAccounts: null,
      countActiveAutopayments: null,
      countActivePaymentUsers: null,
      tickets: [],
      weekends: [],
      telegramSupportGetOperatorListParams: {
        token: ''
      },
      telegramSupportGetOperatorStatisticGetParams: {
        token: '',
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD')
      }
    }
  },
  mounted () {
    if (this.tokenProp) {
      this.token = this.tokenProp
      this.telegramSupportGetOperatorList()
    }
  },
  methods: {
    menuState () {
      if (this.menu === 0) {
        return false
      }
      return true
    },
    menuChangeState () {
      if (this.menu === 2) {
        this.menu = 0
      } else {
        this.menu++
      }
    },
    dateRangeText () {
      if (this.dateRange.length === 2) {
        if (moment(this.dateRange[0]) > moment(this.dateRange[1])) {
          const temp = this.dateRange[0]
          this.dateRange[0] = this.dateRange[1]
          this.dateRange[1] = temp
        }
        return moment(this.dateRange[0]).format('DD.MM.YYYY') + ' - ' + moment(this.dateRange[1]).format('DD.MM.YYYY')
      }
      return 'Заполнить'
    },
    async telegramSupportGetOperatorList () {
      try {
        this.loading = true
        this.telegramSupportGetOperatorListParams.token = this.token
        await telegramSupportGetOperatorList(this.telegramSupportGetOperatorListParams)
          .then((result: TelegramSupportOperator[]) => {
            if (result.length > 0) {
              result.forEach((element: TelegramSupportOperator) => {
                this.telegramSupportOperators.push({
                  id: element.id,
                  name: element.name,
                  status: element.status,
                  work: [],
                  notwork: [],
                  control: []
                })
              })
              this.loadStatistic()
            } else {
              this.error = true
              this.errorMessage = 'Нет операторов'
            }
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    async loadStatistic () {
      try {
        this.clearStatistic()
        this.loadingStatistic = true
        this.telegramSupportGetOperatorStatisticGetParams.token = this.token
        this.telegramSupportGetOperatorStatisticGetParams.dateFrom = moment(this.dateRange[0]).format('YYYY-MM-DD')
        this.telegramSupportGetOperatorStatisticGetParams.dateTo = moment(this.dateRange[1]).format('YYYY-MM-DD')
        await telegramSupportGetOperatorStatistic(this.telegramSupportGetOperatorStatisticGetParams)
          .then((result: TelegramSupportTicketResponse) => {
            this.weekends = result.telegramWeekends
            this.countPeriodActiveAccounts = result.countPeriodTelegramChatAccounts
            this.countActiveAccounts = result.countActiveTelegramChatAccounts
            this.countActiveAutopayments = result.countActiveAutopayments
            this.countActivePaymentUsers = result.countActivePaymentUsers
            if (result.telegramSupportTickets.length > 0) {
              this.tickets = result.telegramSupportTickets
              this.tickets.forEach((ticket: TelegramSupportTicket) => {
                let notWorkAnswerId = 0
                // 1. Проверяем, был ли ответ в нерабочее время
                // Нерабочим временем считаем диапазон с 17 до 8:45
                ticket.telegramSupportTicketAnswers.forEach((answereItem: TelegramSupportTicketAnswere) => {
                  if (answereItem.telegramSupportOperatorId !== null) {
                    if (moment(answereItem.answereTimestamp).hours() >= 17 && notWorkAnswerId === 0) {
                      notWorkAnswerId = answereItem.id
                    }
                    if (moment(answereItem.answereTimestamp).hours() < 8 && notWorkAnswerId === 0) {
                      notWorkAnswerId = answereItem.id
                    }
                    if (moment(answereItem.answereTimestamp).hours() === 8 && moment(answereItem.answereTimestamp).minutes() < 45 && notWorkAnswerId === 0) {
                      notWorkAnswerId = answereItem.id
                    }
                    if (notWorkAnswerId === 0) {
                      const weekEndFind = this.weekends.find((el: any) => el.date === moment(answereItem.answereTimestamp).format('YYYY-MM-DD'))
                      if (weekEndFind) {
                        notWorkAnswerId = answereItem.id
                      }
                    }
                  }
                })

                if (notWorkAnswerId !== 0) {
                  // Нашли ответ в нерабочее время
                  // Проверяем когда был задан вопрос и время контроля
                  let timeToQuestion = null
                  let timeToAnswere = null
                  let stopFind = false
                  timeToQuestion = ticket.requestTimestamp
                  let operatorId = 0
                  ticket.telegramSupportTicketAnswers.forEach((answereItem: TelegramSupportTicketAnswere) => {
                    if (!stopFind) {
                      if (answereItem.telegramSupportOperatorId === null) {
                        timeToQuestion = answereItem.answereTimestamp
                      }
                      if (answereItem.id === notWorkAnswerId) {
                        if (answereItem.telegramSupportOperatorId) {
                          operatorId = answereItem.telegramSupportOperatorId
                        } else {
                          console.log('answereItem.id ', answereItem.id)
                        }
                        timeToAnswere = answereItem.answereTimestamp
                        stopFind = true
                      }
                    }
                  })
                  if (timeToQuestion && timeToAnswere) {
                    if (operatorId !== 0) {
                      const operator = this.telegramSupportOperators.find((el: any) => el.id === operatorId)
                      if (operator) {
                        if (moment(timeToQuestion).hours() >= 17) {
                          operator.notwork.push(ticket)
                        } else {
                          if ((moment(timeToAnswere) - moment(timeToQuestion)) > 3600000) {
                            // Время задержки ответа больше часа
                            operator.control.push(ticket)
                          } else {
                            operator.notwork.push(ticket)
                          }
                        }
                      } else {
                        // Непонятно по какой причине не удалось получить ID оператора, отправляем в контроль закрывшего заявку
                        console.log('Непонятно по какой причине не удалось получить ID оператора, отправляем в контроль закрывшего заявку', ticket.id)
                        const operatorId = ticket.telegramSupportOperatorId
                        if (operatorId) {
                          const operator = this.telegramSupportOperators.find((el: any) => el.id === operatorId)
                          if (operator) {
                            operator.control.push(ticket)
                          }
                        } else {
                          console.log('Не нашли оператора 1: ', ticket.id)
                        }
                      }
                    } else {
                      console.log('Не нашли оператора 2: ', ticket.id)
                    }
                  } else {
                    // Непонятная ситуация отправляем в контроль оператору, кто заявку закрыл
                    console.log('Непонятная ситуация отправляем в контроль оператору, кто заявку закрыл', ticket.id)
                    const operatorId = ticket.telegramSupportOperatorId
                    if (operatorId) {
                      const operator = this.telegramSupportOperators.find((el: any) => el.id === operatorId)
                      if (operator) {
                        operator.control.push(ticket)
                      }
                    } else {
                      console.log('Не нашли оператора 2: ', ticket.id)
                    }
                  }
                } else {
                  // Нет ответов в нерабочее время, заявка рабочая, записываем на того, кто закрыл заявку
                  const operatorId = ticket.telegramSupportOperatorId
                  if (operatorId) {
                    const operator = this.telegramSupportOperators.find((el: any) => el.id === operatorId)
                    if (operator) {
                      operator.work.push(ticket)
                    }
                  } else {
                    console.log('Не нашли оператора 3: ', ticket.id)
                  }
                }
              })
            }
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loadingStatistic = false
      }
    },
    clearStatistic () {
      this.telegramSupportOperators.forEach((supportOperator: any) => {
        supportOperator.work = []
        supportOperator.notwork = []
        supportOperator.control = []
      })
    },
    openDialog (item: Array<TelegramSupportTicket>) {
      if (item.length > 0) {
        item.sort((a: TelegramSupportTicket, b: TelegramSupportTicket) => +a.id > +b.id ? 1 : -1)
        this.telegramSupportOperatorTickets = item
        this.showTicketsModal = true
      }
    },
    closeModal () {
      this.telegramSupportOperatorTickets = []
      this.showTicketsModal = false
    },
    getDateFormat (date: any) {
      moment.lang('ru')
      return moment(date).format('DD MMMM YYYY, h:mm')
    },
    catchError (error: any) {
      const err = error as AxiosError
      this.errorMessage = this.errorMessageUnknown
      if (err.response) {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    }
  }
})
</script>
