<template>
  <div id="TelegramLogin">
    <template v-if="!isLoading">
      <div class="page-content">
        <h1 style="margin-bottom: 10px; text-align: center">
          Вход через телеграм
        </h1>
        <template v-if="!showLoginForm">
          <h4
            v-if="showWarning()"
            style="margin-bottom: 10px; color: red; text-align: center"
          >
            Данный функционал
            доступен только в школах, где предусмотрен предварительный заказ питания школьника.
          </h4>
          <v-btn
            style="margin-bottom: 20px;"
            @click="openLoginForm"
          >
            Добавить учетную запись
          </v-btn>
          <h3 style="margin-bottom: 10px; text-align: center">
            Выбери учетную запись пользователей:
          </h3>
          <template v-if="authUsers">
            <template
              v-for="userItem in authUsers"
              :key="userItem.user.id"
            >
              <v-btn
                class="user-item"
                @click="authUser(userItem)"
              >
                {{ userItem.user.firstName }} {{ userItem.user.lastName.substring(0, 1) }}.
              </v-btn>
            </template>
          </template>
          <template v-else>
            <p style="text-align: center">
              Привязанные учетные записи не найдены!
            </p>
          </template>
        </template>
        <template v-else>
          <v-text-field
            v-model="login"
            type="text"
            placeholder="Логин"
            style="width: 200px"
          />
          <v-text-field
            v-model="password"
            type="password"
            placeholder="Пароль"
            style="width: 200px"
          />
          <div class="login-buttons">
            <v-btn
              class="login-btn"
              :disabled="!login || !password"
              @click="loginAuthUser"
            >
              Войти
            </v-btn>
            <v-btn
              class="cancel-login-btn"
              @click="closeLoginForm"
            >
              Отмена
            </v-btn>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div
        style="margin-top: 60px;"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import {
  TelegramAuthUserLoginApiRequest,
  TelegramAuthUsersApiRequest,
  TelegramAuthUsersItem
} from '@/shared/api/preorders/api-types'
import { telegramAuthUserLogin, telegramAuthUsers } from '@/shared/api/preorders/api-requests'
import { SET_USER } from '@/store/user/mutations'
import { UserShort } from '@/store/user/types'
import { store } from '@/app/providers'
import { RouteParamValue, useRoute } from 'vue-router'

export default defineComponent({
  name: 'TelegramLogin',
  data () {
    return {
      tgToken: '',
      chatId: '',
      isLoading: false,
      authUsers: null as null | TelegramAuthUsersItem[],
      showLoginForm: false,
      login: '',
      password: '',
      route: useRoute()
    }
  },
  async beforeMount () {
    const [chatId, tgToken]: any = this.splitByChar(this.route.params.tgParams)
    this.chatId = chatId
    this.tgToken = tgToken
    this.isLoading = true
    await this.getAuthUsers()
    this.isLoading = false
  },
  methods: {
    splitByChar (str: string | RouteParamValue[]): string[] {
      console.log(str)
      if (typeof str !== 'string') {
        str = str.join('')
      }
      if (str.includes('&')) {
        console.log('1cond')
        return str.split('&')
      } else {
        console.log('2cond')
        return str.split('%7C')
      }
      // 909068271%7C0D6975FA195C4EB8B5BB946C00DF9ADE
      // 909068271%7C0D6975FA195C4EB8B5BB946C00DF9ADE
    },
    showWarning (): boolean {
      const regionIdsWithoutWarning = [1016]
      if (this.authUsers && this.authUsers?.length > 0) {
        const userInRegionWithoutWarning = this.authUsers.find(user => regionIdsWithoutWarning.includes(user.placeInfo?.id))
        return !userInRegionWithoutWarning
      }
      return true
    },
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapMutations('user', { setUser: SET_USER }),
    async getAuthUsers () {
      console.log('getAuthUsers')
      try {
        const telegramAuthUsersReqObj: TelegramAuthUsersApiRequest = {
          chatId: this.chatId,
          token: this.tgToken
        }
        const res = await telegramAuthUsers(telegramAuthUsersReqObj)
        if (res.data?.length > 0) {
          this.authUsers = res.data
        }
        console.log('telegramAuthUsers', res)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список авторизованных пользователей!',
          timeout: 3000
        })
      }
    },
    openLoginForm () {
      console.log('openLoginForm')
      this.showLoginForm = true
    },
    closeLoginForm () {
      console.log('closeLoginForm')
      this.showLoginForm = false
      this.login = ''
      this.password = ''
    },
    async loginAuthUser () {
      console.log('loginAuthUser')
      try {
        this.isLoading = true
        const telegramAuthUserLoginReqObj: TelegramAuthUserLoginApiRequest = {
          chatId: this.chatId,
          login: this.login,
          password: this.password
        }
        console.log(telegramAuthUserLoginReqObj)
        const res = await telegramAuthUserLogin(telegramAuthUserLoginReqObj)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось выполнить вход!',
          timeout: 3000
        })
      } finally {
        await this.refreshData()
        this.isLoading = false
      }
    },
    async authUser (userItem: TelegramAuthUsersItem) {
      console.log('authUser')
      console.log(userItem.placeSettings?.findIndex(value => value.placeSettingTypeId === 32 && value.value === 'on'))
      console.log()
      if (userItem.placeSettings !== null) {
        const existParentIdx = userItem.placeSettings.findIndex(value => value.placeSettingTypeId === 32 && value.value === 'on')
        if (existParentIdx !== -1) {
          console.log('zahodi')
          const userStoreObj: UserShort = {
            id: userItem.user.id,
            // eslint-disable-next-line
            tokens: { access_token: userItem.accessToken, refresh_token: '' },
            username: userItem.user.firstName
          }
          this.setUser(userStoreObj)
          localStorage.setItem('accessToken', userItem.accessToken)
          console.log(this.chatId, this.tgToken)
          localStorage.setItem('chatId', this.chatId)
          localStorage.setItem('tgToken', this.tgToken)
          await store.dispatch('user/getPerson')
          await this.$router.push('/parents/preorders/5')
        } else {
          this.setSnackbar({
            showing: true,
            text: 'Доступ временно ограничен',
            timeout: 3000
          })
        }
      } else {
        this.setSnackbar({
          showing: true,
          text: 'Доступ временно ограничен',
          timeout: 3000
        })
      }
    },
    async refreshData () {
      this.isLoading = true
      this.closeLoginForm()
      await this.getAuthUsers()
      this.isLoading = false
    }
  }
})
</script>

<style scoped lang="scss">
.page-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-item {
    width: 200px;
    cursor: pointer;
    padding: 5px;

    &:not(:first-child) {
      margin-top: 5px;
    }

    &:hover {
      cursor: pointer;
      background-color: lightgreen;
    }
  }

  .login-buttons {
    display: flex;
    flex-direction: row;

    .login-btn {

    }

    .cancel-login-btn {
      margin-left: 5px;
    }
  }
}

@media (max-aspect-ratio: 6/5) {
  .page-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  //.login-buttons {
  //  display: flex;
  //  flex-direction: column;
  //  .login-btn {
  //
  //  }
  //  .cancel-login-btn {
  //    margin-left: 0;
  //  }
  //}
}
</style>
