/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_PLACECUSTOMERPRODUCTS, CLEAR_PLACECUSTOMERPRODUCTS } from '@/store/placeCustomerProductList/mutations'
import { RootState } from '@/store/state'
import {
  PlaceCustomerProductListState, PlaceCustomerProduct
} from '@/store/placeCustomerProductList/types'
import {
  find as placeCustomerProductFind
} from '@/shared/api/placeCustomerProductList/api-requests'
import { parseAxiosError } from '@/shared/lib/api'
import { PlaceCustomerProductSearchPost } from '../../shared/api/placeCustomerProductList/api-types'

type PlaceCustomerProductListContext = ActionContext<PlaceCustomerProductListState, RootState>

const initialState: PlaceCustomerProductListState = {
  placeCustomerProducts: []
}

const getters: GetterTree<PlaceCustomerProductListState, RootState> = {
  placeCustomerProducts (state: PlaceCustomerProductListState): PlaceCustomerProduct[] {
    return state.placeCustomerProducts
  }
}

const mutations: MutationTree<PlaceCustomerProductListState> = {
  [SET_PLACECUSTOMERPRODUCTS] (state: PlaceCustomerProductListState, placeCustomerProducts: PlaceCustomerProduct[]) {
    state.placeCustomerProducts = placeCustomerProducts
  },
  [CLEAR_PLACECUSTOMERPRODUCTS] (state: PlaceCustomerProductListState) {
    state.placeCustomerProducts = []
  }
}

const actions: ActionTree<PlaceCustomerProductListState, RootState> = {
  async find (
    { commit, dispatch, state }: PlaceCustomerProductListContext,
    data: PlaceCustomerProductSearchPost
  ): Promise<PlaceCustomerProduct[]> {
    try {
      commit(CLEAR_PLACECUSTOMERPRODUCTS)
      const result = await placeCustomerProductFind(data)
      commit(SET_PLACECUSTOMERPRODUCTS, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const placeCustomerProducts = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
