<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="3"
        sm="2"
      >
        <v-select
          v-model="selectedUserAllowedPlace"
          :items="userAllowedPlacesList"
          item-title="place.name"
          item-value="place.id"
          label="Школа"
          @update:model-value="placeChange()"
        >
          <template #prepend-item>
            <v-list-item>
              <v-text-field
                v-model="searchTerm"
                :placeholder="'Поиск'"
                @update:model-value="searchUserAllowedPlaces"
              />
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateFrom)"
              label="с:"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата С"
            v-model="dateFrom"
            @input="menu1 = false"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          offset="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateTo)"
              label="по:"
              prepend-icon="mdi-calendar"
              readonly

              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата По"
            v-model="dateTo"
            @input="menu2 = false"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-checkbox
          v-model="separateClasses"
          label="Использовать настройки"
          @click="createReport"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-select
          v-model="selectedVariant"
          :items="variants"
          item-title="name"
          item-value="id"
          label="Вариант"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="goToSettings()"
        >
          Настроить
        </v-btn>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <v-col
        v-if="!isLoading && isPost"
        class="d-flex"
      >
        <v-checkbox
          v-model="splitReport"
          label="Разбить на 2 листа при печати"
        />
      </v-col>
      <v-col v-else></v-col>
      <v-col
        v-if="!isLoading && isPost"
        class="d-flex"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
      <v-col v-else></v-col>
    </v-row>
    <div id="printableArea">
      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center"
      >
        <v-col>
          <h3 class="text-center print-header">
            Акт реализации талонов на бесплатное питание учащихся {{ customerPlaceInfo.fullName }}<br>
            с "{{ getTextDateFrom() }}" года по "{{ getTextDateTo() }}" года<br>
          </h3>
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && sales.length > 0"
        >
          <v-table class="reports-acts-day">
            <template #default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    rowspan="2"
                  >
                    Категории
                  </th>
                  <th
                    v-for="(ratePlan, i) in ratePlans"
                    :key="i"
                    class="text-center"
                    colspan="2"
                  >
                    {{ ratePlan.name }}
                  </th>
                  <th
                    rowspan="2"
                    colspan="2"
                  >
                    Итого
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(ratePlan, i) in ratePlans"
                    :key="i"
                    class="text-center"
                    colspan="2"
                  >
                    {{ ratePlan.code }} кат.
                  </th>
                </tr>
                <tr>
                  <th />
                  <template
                    v-for="(ratePlan, i) in ratePlans"
                    :key="i + '_' + '1'"
                  >
                    <th
                      class="text-center"
                    >
                      Кол-во
                    </th>
                    <th
                      class="text-center"
                    >
                      Сумма
                    </th>
                  </template>
                  <th class="text-center">
                    Кол-во
                  </th>
                  <th class="text-center">
                    Сумма
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(setting, i) in headerParallels"
                  :key="i"
                >
                  <tr>
                    <td
                      colspan="100%"
                      class="text-center"
                    >
                      {{ getParallels(setting.classType) }}
                    </td>
                  </tr>
                  <template v-if="i > 0">
                    <tr :key="i + '_i1'">
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Категории
                      </th>
                      <th
                        v-for="(ratePlan, l) in ratePlans"
                        :key="l"
                        class="text-center"
                        colspan="2"
                      >
                        {{ ratePlan.name }}
                      </th>
                      <th
                        rowspan="2"
                        colspan="2"
                      >
                        Итого
                      </th>
                    </tr>
                    <tr :key="i + '_i2'">
                      <th
                        v-for="(ratePlan, l) in ratePlans"
                        :key="l"
                        class="text-center"
                        colspan="2"
                      >
                        {{ ratePlan.code }} кат.
                      </th>
                    </tr>
                    <tr :key="i + '_i3'">
                      <th />
                      <template
                        v-for="(ratePlan, l) in ratePlans"
                        :key="l + '_' + 'l1'"
                      >
                        <th
                          class="text-center"
                        >
                          Кол-во
                        </th>
                        <th
                          class="text-center"
                        >
                          Сумма
                        </th>
                      </template>
                      <th class="text-center">
                        Кол-во
                      </th>
                      <th class="text-center">
                        Сумма
                      </th>
                    </tr>
                  </template>
                  <template v-for="(product, j) in products">
                    <template v-if="getProductCount(product, setting.classType) > 0">
                      <tr :key="i + 'i_' + j">
                        <td>
                          {{ product.name }}
                        </td>
                        <template
                          v-for="(ratePlan, k) in ratePlans"
                          :key="i + 'i_' + j + 'j_' + k + '_k1'"
                        >
                          <td
                            class="text-center"
                          >
                            {{ getProductCountByRatePlan(product, setting.classType, ratePlan) }}
                          </td>
                          <td
                            class="text-center"
                          >
                            {{ getProductSumByRatePlan(product, setting.classType, ratePlan) }}
                          </td>
                        </template>
                        <td>
                          {{ getProductAllCountByProduct(product, setting.classType) }}
                        </td>
                        <td>
                          {{ getProductAllSumByProduct(product, setting.classType) }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <th
                    :colspan="ratePlans.length * 2 + 2"
                    class="text-right"
                  >
                    ИТОГО
                  </th>
                  <th>
                    {{ getAllSum() }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <center>
            <v-table
              v-if="!isLoading && sales.length > 0"
              class="sign-table"
              style="border:none !important"
            >
              <tr>
                <td>
                  Директор школы _____________________________/{{ customerPlaceInfo.directorName }}/
                </td>
              </tr>
              <tr>
                <td>
                  Ответственный по питанию _____________________________/{{ customerPlaceInfo.ladminName }}/
                </td>
              </tr>
              <tr>
                <td>
                  Зав производством _____________________________/{{ customerPlaceInfo.zavprName }}/
                </td>
              </tr>
            </v-table>
          </center>
        </v-col>
      </v-row>

      <v-row
        v-if="!isLoading && isPost"
        class="non-visible"
      >
        <hr v-if="!splitReport">
      </v-row>

      <div
        v-if="splitReport"
        class="page"
      />

      <v-row
        v-if="!isLoading && isPost"
        class="justify-content-center non-visible"
      >
        <v-col>
          <h3 class="text-center print-header">
            Акт реализации талонов на бесплатное питание учащихся {{ customerPlaceInfo.fullName }}<br>
            с "{{ getTextDateFrom() }}" года по "{{ getTextDateTo() }}" года<br>
          </h3>
        </v-col>
      </v-row>
      <v-row class="justify-content-center non-visible">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && sales.length > 0"
        >
          <v-table class="reports-acts-day">
            <template #default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    rowspan="2"
                  >
                    Категории
                  </th>
                  <th
                    v-for="(ratePlan, i) in ratePlans"
                    :key="i"
                    class="text-center"
                    colspan="2"
                  >
                    {{ ratePlan.name }}
                  </th>
                  <th
                    rowspan="2"
                    colspan="2"
                  >
                    Итого
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(ratePlan, i) in ratePlans"
                    :key="i"
                    class="text-center"
                    colspan="2"
                  >
                    {{ ratePlan.code }} кат.
                  </th>
                </tr>
                <tr>
                  <th />
                  <template
                    v-for="(ratePlan, i) in ratePlans"
                    :key="i + '_' + '1'"
                  >
                    <th
                      class="text-center"
                    >
                      Кол-во
                    </th>
                    <th
                      class="text-center"
                    >
                      Сумма
                    </th>
                  </template>
                  <th class="text-center">
                    Кол-во
                  </th>
                  <th class="text-center">
                    Сумма
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(setting, i) in headerParallels"
                  :key="i"
                >
                  <tr>
                    <td
                      colspan="100%"
                      class="text-center"
                    >
                      {{ getParallels(setting.classType) }}
                    </td>
                  </tr>
                  <template v-if="i > 0">
                    <tr :key="i + '_i1'">
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Категории
                      </th>
                      <th
                        v-for="(ratePlan, l) in ratePlans"
                        :key="l"
                        class="text-center"
                        colspan="2"
                      >
                        {{ ratePlan.name }}
                      </th>
                      <th
                        rowspan="2"
                        colspan="2"
                      >
                        Итого
                      </th>
                    </tr>
                    <tr :key="i + '_i2'">
                      <th
                        v-for="(ratePlan, l) in ratePlans"
                        :key="l"
                        class="text-center"
                        colspan="2"
                      >
                        {{ ratePlan.code }} кат.
                      </th>
                    </tr>
                    <tr :key="i + '_i3'">
                      <th />
                      <template
                        v-for="(ratePlan, l) in ratePlans"
                        :key="l + '_' + 'l1'"
                      >
                        <th
                          class="text-center"
                        >
                          Кол-во
                        </th>
                        <th
                          class="text-center"
                        >
                          Сумма
                        </th>
                      </template>
                      <th class="text-center">
                        Кол-во
                      </th>
                      <th class="text-center">
                        Сумма
                      </th>
                    </tr>
                  </template>
                  <template v-for="(product, j) in products">
                    <template v-if="getProductCount(product, setting.classType) > 0">
                      <tr :key="i + 'i_' + j">
                        <td>
                          {{ product.name }}
                        </td>
                        <template
                          v-for="(ratePlan, k) in ratePlans"
                          :key="i + 'i_' + j + 'j_' + k + '_k1'"
                        >
                          <td
                            class="text-center"
                          >
                            {{ getProductCountByRatePlan(product, setting.classType, ratePlan) }}
                          </td>
                          <td
                            class="text-center"
                          >
                            {{ getProductSumByRatePlan(product, setting.classType, ratePlan) }}
                          </td>
                        </template>
                        <td>
                          {{ getProductAllCountByProduct(product, setting.classType) }}
                        </td>
                        <td>
                          {{ getProductAllSumByProduct(product, setting.classType) }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <th
                    :colspan="ratePlans.length * 2 + 2"
                    class="text-right"
                  >
                    ИТОГО
                  </th>
                  <th>
                    {{ getAllSum() }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-table>
        </v-col>
      </v-row>
      <v-row class="non-visible">
        <v-col class="text-center">
          <center>
            <v-table
              v-if="!isLoading && sales.length > 0"
              class="sign-table"
              style="border:none !important"
            >
              <tr>
                <td>
                  Директор школы _____________________________/{{ customerPlaceInfo.directorName }}/
                </td>
              </tr>
              <tr>
                <td>
                  Ответственный по питанию _____________________________/{{ customerPlaceInfo.ladminName }}/
                </td>
              </tr>
              <tr>
                <td>
                  Зав производством _____________________________/{{ customerPlaceInfo.zavprName }}/
                </td>
              </tr>
            </v-table>
          </center>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost } from '@/shared/api/salelist/api-types'
import { findByPeriodAndPlaceCustomerProductsWithRatePlans } from '@/shared/api/salelist/api-requests'
import { find as findPlaceReportSettings } from '@/shared/api/placeReportSettings/api-requests'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  menu1: boolean;
  menu2: boolean;
  dateFrom: any;
  dateTo: any;
  variants: any;
  selectedVariant: any;
  sales: any;
  monthes: any;
  ratePlans: any;
  headerParallels: any;
  productRatePlanSales: any;
  isLoading: boolean;
  isPost: boolean;
  userAllowedPlacesList: any;
  selectedUserAllowedPlaceName: string;
  selectedUserAllowedPlace: any;
  userAllowedPlacesCopy: any;
  searchTerm: string;
  separateClasses: boolean;
  splitReport: boolean;
  salesGetPost: SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost;
  customerPlacePost: CustomerPlaceFindPost;
  customerPlaceInfo: any;
  places: any;
  products: any;
  settings: any;
  notAcceptDates: any;
  errorDates: any;
}

export default defineComponent({
  name: 'MonthReport',
  data (): BaseData {
    return {
      menu1: false,
      menu2: false,
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().add('days', 1).format('YYYY-MM-DD'),
      variants: [
        { id: 1, name: '1' },
        { id: 2, name: '2' }
      ],
      selectedVariant: 1,
      sales: [],
      monthes: [
        { id: 1, name: 'января' },
        { id: 2, name: 'февраля' },
        { id: 3, name: 'марта' },
        { id: 4, name: 'апреля' },
        { id: 5, name: 'мая' },
        { id: 6, name: 'июня' },
        { id: 7, name: 'июля' },
        { id: 8, name: 'августа' },
        { id: 9, name: 'сентября' },
        { id: 10, name: 'октября' },
        { id: 11, name: 'ноября' },
        { id: 12, name: 'декабря' }
      ],
      ratePlans: [],
      headerParallels: [],
      productRatePlanSales: [],
      isLoading: false,
      isPost: false,
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      selectedUserAllowedPlaceName: '',
      userAllowedPlacesCopy: [],
      searchTerm: '',
      separateClasses: false,
      splitReport: false,
      salesGetPost: {
        placeId: 0,
        timestampFrom: '',
        timestampTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      customerPlaceInfo: null,
      places: [],
      errorDates: [],
      notAcceptDates: [],
      products: [],
      settings: []
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async loadUserAllowedPlaces () {
      try {
        await this.userAllowedPlaceAll()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        if (this.userAllowedPlacesList.length > 0) {
          if (this.propPlaceId) {
            this.selectedUserAllowedPlace = this.propPlaceId
          } else {
            this.selectedUserAllowedPlace = this.userAllowedPlacesList[0].place.id
          }
        }
        this.selectedUserAllowedPlaceName = this.userAllowedPlacesList.find((el: any) => el.place.id === this.selectedUserAllowedPlace).place.name2
        this.isLoading = false
      }
    },
    getAllSum () {
      let sum = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.ratePlanId !== null && x.productId !== null && x.classType >= 1) {
          sum = sum + Number(x.sum)
        }
      })
      return sum.toFixed(2)
    },
    getProductCount (product: any, classType: any) {
      let count = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.productId === product.id && x.classType === classType) {
          count = count + x.count
        }
      })
      return count
    },
    getProductCountByRatePlan (product: any, classType: any, ratePlan: any) {
      let count = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.ratePlanId === ratePlan.id && x.productId === product.id && x.classType === classType) {
          count = count + x.count
        }
      })
      return count
    },
    getProductSumByRatePlan (product: any, classType: any, ratePlan: any) {
      let sum = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.ratePlanId === ratePlan.id && x.productId === product.id && x.classType === classType) {
          sum = sum + Number(x.sum)
        }
      })
      return sum.toFixed(2)
    },
    getProductAllCountByProduct (product: any, classType: any) {
      let count = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.productId === product.id && x.classType === classType) {
          count = count + x.count
        }
      })
      return count
    },
    getProductAllSumByProduct (product: any, classType: any) {
      let sum = 0
      this.productRatePlanSales.forEach((x: any) => {
        if (x.productId === product.id && x.classType === classType) {
          sum = sum + Number(x.sum)
        }
      })
      return sum.toFixed(2)
    },
    async loadSales () {
      try {
        this.isLoading = true
        if (this.selectedUserAllowedPlace !== 0) {
          const reportSettingsFindPost = {
            placeId: this.selectedUserAllowedPlace,
            reportType: 1
          }
          const result1 = await findPlaceReportSettings(reportSettingsFindPost)
          const settingsResponse = JSON.parse(JSON.stringify(result1))
          this.getSettings(settingsResponse)

          this.salesGetPost.placeId = this.selectedUserAllowedPlace
          this.salesGetPost.timestampFrom = moment(this.dateFrom).format('YYYY-MM-DD')
          this.salesGetPost.timestampTo = moment(this.dateTo).add('days', 1).format('YYYY-MM-DD')
          const result = await findByPeriodAndPlaceCustomerProductsWithRatePlans(this.salesGetPost)
          const sales = JSON.parse(JSON.stringify(result))
          if (Array.isArray(sales)) {
            sales.forEach((element: any) => {
              const i = this.ratePlans.findIndex((x: any) => x.id === element.currentUserRatePlan.ratePlan.id)
              if (i <= -1) {
                this.ratePlans.push(element.currentUserRatePlan.ratePlan)
              }
              if (element.product.currentPlaceCustomerProduct.id === '1' && Number(element.currentUserPlace) === 5) {
                console.log(element.userId)
              }
              if (element.currentUserPlace !== null) {
                const j = this.productRatePlanSales.findIndex((x: any) => x.ratePlanId === element.currentUserRatePlan.ratePlan.id &&
                  x.productId === element.product.currentPlaceCustomerProduct.id && x.className === element.currentUserPlace)
                if (j <= -1) {
                  const productRatePlanSale = {
                    ratePlanId: element.currentUserRatePlan.ratePlan.id,
                    productId: element.product.currentPlaceCustomerProduct.id,
                    className: element.currentUserPlace,
                    count: 1,
                    sum: Number(element.price)
                  }
                  this.productRatePlanSales.push(productRatePlanSale)
                } else {
                  this.productRatePlanSales[j].count = this.productRatePlanSales[j].count + 1
                  this.productRatePlanSales[j].sum = this.productRatePlanSales[j].sum + Number(element.price)
                }
              }

              const k = this.products.findIndex((x: any) => x.id === element.product.currentPlaceCustomerProduct.id)
              if (k <= -1) {
                this.products.push(element.product.currentPlaceCustomerProduct)
              }
            })
            this.sales = sales
          }
          this.ratePlans.sort((a: any, b: any) => (a.ratePlanDiscountTypeId > b.ratePlanDiscountTypeId) ? 1 : ((b.ratePlanDiscountTypeId > a.ratePlanDiscountTypeId) ? -1 : (Number(a.code) > Number(b.code)) ? 1 : ((Number(b.code) > Number(a.code)) ? -1 : 0)))

          this.products.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

          if (this.sales.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.selectedUserAllowedPlace !== 0) {
          this.customerPlacePost.placeId = this.selectedUserAllowedPlace
          this.customerPlacePost.date = moment(this.dateFrom).format('YYYY-MM-DD')

          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    getParallels (type: any) {
      let parallels = ''
      if (!this.separateClasses) {
        if (type === 1) {
          parallels = 'Младшие классы'
        } else {
          parallels = 'Старшие классы'
        }
      } else {
        this.settings.forEach((element: any) => {
          if (element.classType === type) {
            parallels = element.startValue + '-' + element.endValue + ' классы'
          }
        })
      }
      return parallels
    },
    getSettingParallels () {
      if (this.separateClasses) {
        return JSON.parse(JSON.stringify(this.settings))
      }
      return [{
        id: 1,
        startValue: '1',
        endValue: '4',
        classType: 1
      },
        {
          id: 2,
          startValue: '5',
          endValue: '11',
          classType: 2
        }
      ]
    },
    getSettings (settingsResponse: any) {
      this.settings = []
      if (Array.isArray(settingsResponse)) {
        settingsResponse.forEach((element: any) => {
          const elementCopy = element
          const values = elementCopy.value.split(';')
          const setting = {
            id: element.id,
            startValue: values[0],
            endValue: values[1]
          }
          this.settings.push(setting)
        })
      }
      this.sortSettings()
      let i = 1
      this.settings.forEach((element: any) => {
        element.classType = i
        i = i + 1
      })
    },
    sortSettings () {
      this.settings.sort((a: any, b: any) => (a.startValue > b.startValue) ? 1 : ((b.startValue > a.startValue) ? -1 : 0))
    },
    createReport () {
      this.headerParallels = this.getSettingParallels()
      this.productRatePlanSales.forEach((element: any) => {
        let classType = 0
        if (!this.separateClasses) {
          if (element.className.match(/^[1-4][^0-9].*|^[1-4]$/)) classType = 1
          if (element.className.match(/^[5-9].*|^[5-9]$/)) classType = 2
          if (element.className.match(/^[1-4][0-3].*$|^[1-4][0-3]$/)) classType = 2
        } else {
          this.settings.forEach((el: any) => {
            if (element.className.match(/^[1-4][^0-9].*|^[1-4]|[5-9].*|^[5-9]|[1-4][0-3].*$|^[1-4][0-3]$/)) {
              if (parseInt(element.className) >= el.startValue && parseInt(element.className) <= el.endValue) {
                classType = el.classType
              }
            }
          })
        }
        element.classType = classType
      })
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }
      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    placeChange () {
      if (this.userAllowedPlacesList.lenght > 0) {
        this.selectedUserAllowedPlaceName = this.userAllowedPlacesList.find((el: any) => el.place.id === this.selectedUserAllowedPlace).place.name2
      }
    },
    load () {
      this.productRatePlanSales = []
      this.sales = []
      this.places = []
      this.products = []
      this.errorDates = []
      this.notAcceptDates = []
      this.settings = []
      this.customerPlaceInfo = null
      this.loadSales()
      this.loadCustomerPlaceInfo()
      this.isPost = true
    },
    print () {
      const printContent = document.getElementById('printableArea')
      const WinPrint = window.open('')
      if (WinPrint && printContent) {
        WinPrint.document.write(`
          <html>
            <head>
              <title></title>
            </head>
            <body>
            <div class="bodyprint">
            <input class="print-button" type="button" value="Печать" onclick="print()">
              ${printContent.innerHTML}
            </div>
            </body>
          </html>
        `)
        const link = WinPrint.document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        link.setAttribute('href', '/css/table-print.css')
        WinPrint.document.getElementsByTagName('head')[0].appendChild(link)
        WinPrint.focus()
      }
    },
    goToSettings () {
      this.$router.push({
        name: 'ReportSettings',
        query: {
          placeId: this.selectedUserAllowedPlace,
          report: 'ReportActsDay'
        }
      })
    },
    getTextDateFrom () {
      const monthName = this.monthes.find((x: any) => x.id === (Number(moment(this.dateFrom).format('M')))).name
      return moment(this.dateFrom).format('D') + ' ' + monthName + ' ' + moment(this.dateFrom).format('Y')
    },
    getTextDateTo () {
      const monthName = this.monthes.find((x: any) => x.id === (Number(moment(this.dateTo).format('M')))).name
      return moment(this.dateTo).format('D') + ' ' + monthName + ' ' + moment(this.dateTo).format('Y')
    }
  },
  mounted () {
    this.isLoading = true
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss">
.non-visible {
  display: none;
}
</style>
