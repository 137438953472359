<template>
  <v-container class=" mt-2 convertedRawApiData pt-2">
    <v-container class="py-4 pt-2">
      <v-row class="d-flex justify-space-between">
        <v-btn
          class="block"
          :to="{ name: 'NutritionClassHeads' }"
        >
          Классные <br>руководители
        </v-btn>
        <v-btn
          class="block"
          :to="{ name: 'NutritionAltClassHeads' }"
        >
          Заместители<br>без права подписи
        </v-btn>
        <v-btn
          class="block"
          :to="{ name: 'Home' }"
        >
          Воспитатели ГПД
        </v-btn>
      </v-row>
    </v-container>
    <router-view />
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  data () {
    return {
      //
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>

<style scoped lang="scss">
  .block{
    background: #e4e4e4!important;
    text-transform: uppercase!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    width: 33%!important;
    font-size: 16px;
    height:80px!important;
    text-align:center;
    @media (max-width: 980px) {
      font-size: 16px;
      height:80px!important;
    }
    @media (max-width: 680px) {
      font-size: 12px;
      height:60px!important;
    }
    @media (max-width: 610px) {
      font-size: 14px;
      height:40px!important;
    }
  }
</style>
