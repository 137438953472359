<template>
  <v-select
    v-model="selectedUserAllowedPlace"
    multiple
    :items="userAllowedPlacesList"
    item-title="place.name"
    item-value="place.id"
    label="Школа"
    :change="update()"
  >
    <template #prepend-item>
      <v-list-item>
        <v-text-field
          v-model="searchTerm"
          :placeholder="'Поиск'"
          @update:model-value="searchUserAllowedPlaces"
        />
      </v-list-item>
    </template>
<!--    TODO: добавить chip'сы-->
<!--    <template #selection="{ item, index }">-->
<!--      <v-chip v-if="index === 0">-->
<!--        <span>{{ item.title }}</span>-->
<!--      </v-chip>-->
<!--      <span-->
<!--        v-if="index === 1"-->
<!--        class="text-grey text-caption"-->
<!--      >-->
<!--            (+{{ selectedUserAllowedPlace.length - 1 }} ещё)-->
<!--          </span>-->
<!--    </template>-->
  </v-select>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { th } from 'vuetify/locale'

export default defineComponent({
  model: {
    prop: 'itemsProps',
    event: 'on-change'
  },
  props: ['selectedItemsProps'],
  data () {
    return {
      selects: [] as any,
      isLoading: false,
      isDisabled: true,
      selectedItems: [],
      items: [{
        place: {
          id: 0,
          name: ''
        }
      }],
      itemsFiltered: [{
        place: {
          id: 0,
          name: ''
        }
      }],
      userAllowedPlacesList: [{
        place: {
          id: 0,
          name: ''
        }
      }],
      selectedUserAllowedPlace: [0] as any,
      searchTerm: ''
    }
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    updateModel (currentModel: any) {
      const res = [] as any
      currentModel.forEach((item: any, itemIdx: number) => {
        if (!item?.id) {
          console.log('Нет id')
        } else {
          res.push(item)
        }
      })
      console.log(res)
      this.selectedUserAllowedPlace = res
      console.log('selectedUserAllowedPlace', this.selectedUserAllowedPlace)
    },
    async loadUserAllowedPlaces () {
      try {
        this.selectedUserAllowedPlace = []
        const result = await this.userAllowedPlaceAll()
        this.items = JSON.parse(JSON.stringify(result))

        // this.items = this.items.filter((obj: any) => {
        //   if (obj.removed == null) {
        //     if ((obj.place.name).search(/\d+$/) >= 0) {
        //       obj.place.name = obj.place.name.substring((obj.place.name).search(/\d+$/))
        //     }
        //     return obj
        //   }
        // })
        console.log(this.items, 'it')
        this.itemsFiltered = this.items
        this.isLoading = false
        let findFirst = null
        if (this.items.length > 0) findFirst = this.items[0].place.id
        if (this.selectedItemsProps.length > 0) {
          this.selectedUserAllowedPlace = this.selectedItemsProps
        } else {
          if (findFirst) this.selectedUserAllowedPlace = [findFirst]
        }
        this.searchUserAllowedPlaces()
        this.update()
      } catch (error: any) {
        this.items = []
      }
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.itemsFiltered
      }
      this.userAllowedPlacesList = this.itemsFiltered.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    update () {
      console.log('change', this.selects)
      this.$emit('on-change', this.selectedUserAllowedPlace)
    },
    searchItem () {
      if (!this.searchTerm) {
        this.itemsFiltered = this.items
      }
      if (Array.isArray(this.items)) {
        this.itemsFiltered = this.items.filter((obj: any) => {
          return obj.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        })
      }
    }
  },
  mounted () {
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss"></style>
