<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <template v-if="loading">
      <v-col>
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
      </v-col>
    </template>
    <template v-else>
      <v-row
        v-if="!showPaymentResults"
        class="first-row align-center "
      >
        <h3>Итог: {{ totalAmount }}</h3>
        <div>
          <v-btn
            class="ma-2 bg-primary"
            color="blue-darken-1"
            variant="text"
            :disabled="isInvalidValuesFromPaymentInputs || hasPaymentTools"
            @click="pay()"
          >
            Оплата
          </v-btn>
          <v-btn
            class="ma-1 square-btn"
            variant="text"
            :style="{width: '36px', height: '36px'}"
            @click="showSetting"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-dialog
            v-model="showSettings"
            width="320px"
            light:true
            :retain-focus="false"
          >
            <v-card>
              <h4 style="padding: 20px">
                Здесь можно корректировать суммы шаблона пополнения баланса
              </h4>
              <div style="padding-left: 10px; padding-right: 10px; display: flex; justify-content: center">
                <v-text-field
                  v-model="settingInputs[0]"
                  variant="solo"
                  clearable
                  label="100"
                  :rules="settingRules"
                  style="width: 60px; border-radius: 3px;"
                  @update:model-value="checkSettingsInputValue(0)"
                />
                <v-text-field
                  v-model="settingInputs[1]"
                  variant="solo"
                  clearable
                  label="200"
                  :rules="settingRules"
                  style="width: 60px; border-radius: 3px; margin-left: 13px;"
                  @update:model-value="checkSettingsInputValue(1)"
                />
                <v-text-field
                  v-model="settingInputs[2]"
                  variant="solo"
                  clearable
                  label="300"
                  :rules="settingRules"
                  style="width: 60px; border-radius: 3px; margin-left: 13px"
                  @update:model-value="checkSettingsInputValue(2)"
                />
              </div>
              <div style="justify-content: center; display: flex">
                <v-btn
                  class="ma-2 bg-primary"
                  color="blue-darken-1"
                  variant="text"
                  :disabled="IsInvalidValuesFromSettingInputs"
                  @click="saveSettings"
                >
                  Сохранить
                </v-btn>
                <v-btn
                  class="ma-2 "
                  variant="text"
                  color="red-accent-1"
                  :style="{background: '#EF9A9A'}"
                  @click="closeSetting"
                >
                  Отмена
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-row>
      <v-row
        v-if="!showPaymentResults"
        justify="center"
        align="center"
        style="margin-top: 0px;"
      >
        <v-table
          v-if="!loading"
          class="green-header-table"
          style="margin-top: 20px; box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12)"
        >
          <tbody>
            <tr
              v-for="(item, mainIndex) in telegramPaymentKeyForSinglePayment"
              :key="mainIndex"
            >
              <td style="display: flex; flex-direction: column; height: fit-content; padding: 5px 12px;  color: rgba(0,0,0,0.6)">
                <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
                  <h3 style="color: #000000; text-align: center">
                    {{ item.paymentKeyName }}
                  </h3>
                  <div
                    v-if="!(item.paymentType === 0 && !item.sbpSubscriptions.length && !item.bankBindings.length)"
                    style="display: flex; gap: 10px"
                  >
                    <v-menu
                      v-model="menu[mainIndex]"
                    >
                      <template #activator="{ props }">
                        <v-btn
                          v-model="selectedItem[mainIndex]"
                          class="ma-0 bg-primary"
                          color="blue-darken-1"
                          v-bind="props"
                          @click="openMenu(mainIndex)"
                        >
                          <img
                            v-if="selectedItem[mainIndex].paymentType === 2"
                            src="@/assets/images/logosbp.svg"
                            height="20"
                            width="20"
                          >
                          {{ selectedItem[mainIndex].name }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(sbpBanks, index) in item.sbpSubscriptions"
                          :key="index"
                          class="list-item"
                          @click="selectItem(sbpBanks,2, mainIndex)"
                        >
                          <v-list-item-title>
                            <img
                              v-if=" item.sbpSubscriptions[index].memberPictExist == 1"
                              :src="require('@/assets/images/banklogo/'+item.sbpSubscriptions[index].memberId+'.svg')"
                              height="20"
                              width="20"
                            >
                            {{ sbpBanks.memberRusName }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="(bankcard, index) in item.bankBindings"
                          :key="index"
                          class="list-item"
                          @click="selectItem(bankcard, 1, mainIndex)"
                        >
                          <v-list-item-title>{{ bankcard.maskedPan }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <h3
                    v-else
                    style="margin-left: 30px"
                  >
                    Нет привязанных ссылок
                  </h3>
                </div>
                <div
                  v-if="!(item.paymentType === 0 && !item.sbpSubscriptions.length && !item.bankBindings.length)"
                  style="display: flex; justify-content: space-between; gap: 10px; margin-top: 5px"
                >
                  <div style="gap:5px; display: flex; flex-direction: row; ">
                    <div style="width: 100px; gap: 10px; display: flex; flex-direction: column">
                      <h4>Горячее</h4>
                      <h5>Баланс: {{ item.accounts[0].sum }}</h5>
                    </div>
                    <v-text-field
                      :key="mainIndex"
                      v-model="paymentInputs[`main-${mainIndex}`]"
                      variant="solo"
                      clearable
                      label="0"
                      :rules="paymentRules"
                      class="telegram-input"
                      @click:clear="clearPaymentInputMain(mainIndex)"
                      @update:model-value="calculateSum"
                    />
                  </div>
                  <div style="display: flex; flex-direction: row; gap: 5px">
                    <div
                      v-for="(button, buttonIndex) in buttonValueSetting"
                      :key="buttonIndex"
                    >
                      <button
                        class="add_sum_btn"
                        @click="addSumForMain(mainIndex, buttonIndex)"
                      >
                        {{ buttonValueSetting[buttonIndex] }}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!(item.paymentType === 0 && !item.sbpSubscriptions.length && !item.bankBindings.length)"
                  style="display: flex; justify-content: space-between; gap: 10px;"
                >
                  <div style="gap: 5px; display: flex; flex-direction: row; ">
                    <div style="width: 100px; gap: 10px; display: flex; flex-direction: column">
                      <h4>Буфет</h4>
                      <h5>Баланс: {{ item.accounts[1].sum }}</h5>
                    </div>
                    <v-text-field
                      ref="paymentInput"
                      v-model="paymentInputs[`buffet-${mainIndex}`]"
                      class="telegram-input"
                      variant="solo"
                      clearable
                      label="0"
                      :rules="paymentRules"
                      @click:clear="clearPaymentInputBuffet(mainIndex)"
                      @update:model-value="calculateSum"
                    />
                  </div>
                  <div style="display: flex; flex-direction: row; gap: 5px">
                    <div
                      v-for="(button, buttonIndex) in buttonValueSetting"
                      :key="buttonIndex"
                    >
                      <button
                        class="add_sum_btn"
                        @click="addSumForBuffet(mainIndex, buttonIndex)"
                      >
                        {{ buttonValueSetting[buttonIndex] }}
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-row>
    </template>
    <v-row
      v-if="showPaymentResults"
      style="margin-top: 50px"
    >
      <div style="margin: 5px; padding: 10px; padding-right: 0px; border: 2px solid #f5f5f5; width: 100%; border-radius: 20px; gap: 10px; display: flex; flex-direction: column; box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12)">
        <div
          v-for="(paymentKey, paymentKeyIndex) in paymentResult.data.paymentKeys"
          :key="paymentKeyIndex"
          style=" display: flex; flex-direction: column; "
        >
          <h3 v-if="paymentKey.accounts[0].error !== 5 || paymentKey.accounts[1].error !== 5">
            {{ paymentKeyNames[paymentKeyIndex] }}
          </h3>
          <div
            v-for="(account, accountIndex) in paymentKey.accounts"
            :key="accountIndex"
          >
            <h4
              v-if="account.error === 0 && account.type === '1'"
              style="border-bottom: 2px solid #f5f5f5; padding-bottom: 7px; margin-top: 7px; font-size: 15px"
            >
              На баланс горячего питания зачислено: {{ parseInt(account.sum) }}₽
            </h4>
            <h4
              v-if="account.error === 0 && account.type === '2'"
              style="border-bottom: 2px solid #f5f5f5; padding-bottom: 7px; margin-top: 7px; font-size: 15px"
            >
              На баланс буфета зачислено: {{ parseInt(account.sum) }}₽
            </h4>
            <h4
              v-if="account.error !== 5 && account.error"
              style="border-bottom: 2px solid #f5f5f5; padding-bottom: 7px; margin-top: 7px; font-size: 15px"
            >
              При оплате произошла ошибка: {{ account.errorMessage }}
            </h4>
          </div>
        </div>
        <v-btn
          class="bg-primary"
          color="blue-darken-1"
          style="width: fit-content; align-self: center"
        >
          <a
            target="_blank"
            href="https://t.me/GlolimeBot"
            style="color: white"
          >Назад</a>
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import {
  telegramDoPaymentForSinglePayment,
  telegramGetPaymentKeysForSinglePayment,
  telegramSaveSettingForSinglePayment
} from '@/shared/api/telegram/api-requests'
import {
  Account,
  PaymentKey,
  TelegramDoPaymentForSinglePaymentPost,
  TelegramDoPaymentForSinglePaymentResponse,
  TelegramGetPaymentKeysForSinglePaymentPost,
  TelegramSaveSettingForSinglePaymentPost
} from '@/shared/api/telegram/api-types'
import { TelegramPaymentKeyForSinglePayment } from '@/store/telegram/types'
import moment from 'moment'
import { AxiosError } from 'axios'
import { mapActions } from 'vuex'

interface PaymentInputs {
  [key: string]: string;
}
export type selectedItem = {
  name: string,
  paymentType: number,
  details: string
}

type BaseData = {
  totalAmount: number;
  token: string;
  chatId: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  errorMessageUnknown: string;
  settingRules: Array<any>;
  paymentRules: Array<any>;
  paymentResult: TelegramDoPaymentForSinglePaymentResponse;
  telegramPaymentKeyForSinglePayment: Array<TelegramPaymentKeyForSinglePayment>;
  buttonValueSetting: string[];
  showSettings: boolean;
  menu: boolean[];
  paymentKeyNames: any[];
  selectedItem: selectedItem[];
  settingInputs: string[];
  paymentInputs: PaymentInputs;
  maxInputValue: number;
  showPaymentResults: boolean;
}

export default defineComponent({
  props: {
    tokenProp: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      totalAmount: 0,
      token: '',
      loading: false,
      error: false,
      buttonValueSetting: ['100', '200', '500'],
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Неизвестная',
      chatId: '0',
      showSettings: false,
      settingInputs: ['100', '200', '500'],
      menu: [],
      paymentKeyNames: [],
      selectedItem: [],
      paymentInputs: { } as PaymentInputs,
      maxInputValue: 2000,
      paymentResult: {} as TelegramDoPaymentForSinglePaymentResponse,
      showPaymentResults: false,
      paymentRules: [
        (value: any) => {
          if (value || value === undefined) return true
          return 'Пустое поле.'
        },
        (value: any) => {
          if (value?.length <= 4 || value === undefined) return true
          return 'Максимум 4 цифры.'
        },
        (value: any) => {
          if (!isNaN(value) || value === undefined) return true
          return 'Должно быть число.'
        },
        (value: any) => {
          if (value <= this.maxInputValue || value === undefined) return true
          return 'Максимальная сумма оплаты 2000 руб.'
        },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) || value === undefined) return true
          return 'Введите целое число'
        },
        (value: any) => {
          if (parseFloat(value) >= 0 || value === undefined) return true
          return 'Введите натуральное число'
        }
      ],
      settingRules: [
        (value: any) => {
          if (value) return true
          return 'Пустое поле.'
        },
        (value: any) => {
          if (value?.length <= 4) return true
          return 'Максимум 4 цифры.'
        },
        (value: any) => {
          if (!isNaN(value)) return true
          return 'Должно быть число.'
        },
        (value: any) => {
          if (value <= this.maxInputValue) return true
          return 'Максимальная сумма оплаты 2000 руб.'
        },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) && parseFloat(value) > 0) return true
          return 'Введите натуральное число'
        }
      ],
      telegramPaymentKeyForSinglePayment: []
    }
  },
  computed: {
    IsInvalidValuesFromSettingInputs () {
      for (let i = 0; i < this.$data.settingInputs.length; i++) {
        const value = this.$data.settingInputs[i]
        if (value?.length > 4) {
          return true
        }
        if (!Number.isInteger(parseFloat(value))) {
          return true
        }
        if (+value > this.maxInputValue) {
          return true
        }
        if (isNaN(+value)) {
          return true
        }
        if (+value < 0) {
          return true
        }
      }
      return false
    },
    isInvalidValuesFromPaymentInputs () {
      for (const key in this.$data.paymentInputs) {
        if (Object.prototype.hasOwnProperty.call(this.$data.paymentInputs, key)) {
          const value = this.$data.paymentInputs[key]
          if (value?.length > 4) {
            return true
          }
          if (isNaN(+value)) {
            return true
          }
          if (+value > this.maxInputValue) {
            return true
          }
          if (!Number.isInteger(parseFloat(value))) {
            return true
          }
          if (+value < 0) {
            return true
          }
        }
      }
      return false
    },
    hasPaymentTools () {
      for (let i = 0; i < this.$data.telegramPaymentKeyForSinglePayment.length; i++) {
        const item = this.$data.telegramPaymentKeyForSinglePayment[i]
        if (item.bankBindings.length > 0 || item.sbpSubscriptions.length > 0) {
          return false
        }
      }
      return true
    }
  },

  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async telegramSupportGetDetailsByTicket () {
      try {
        this.loading = true
        const telegramGetPaymentKeysForSinglePaymentPost: TelegramGetPaymentKeysForSinglePaymentPost = {
          chatId: this.chatId,
          token: this.token
        }
        if (this.token !== '5') {
          const result = await telegramGetPaymentKeysForSinglePayment(telegramGetPaymentKeysForSinglePaymentPost)
          this.telegramPaymentKeyForSinglePayment = result.data.paymentKeys
          if (result.data.settings.length > 0) {
            this.buttonValueSetting = result.data.settings[0].value.split(',')
            this.settingInputs = result.data.settings[0].value.split(',')
          } else {
            this.buttonValueSetting = ['100', '200', '500']
            this.settingInputs = ['100', '200', '500']
          }
          this.setDefaultSelectedItems()
        }
      } catch (e) {
        this.catchError(e)
      } finally {
        this.loading = false
      }
    },
    async saveSettings () {
      try {
        this.loading = true
        this.buttonValueSetting[0] = this.settingInputs[0]
        this.buttonValueSetting[1] = this.settingInputs[1]
        this.buttonValueSetting[2] = this.settingInputs[2]
        const telegramSaveSettingForSinglePaymentPost: TelegramSaveSettingForSinglePaymentPost = {
          chatId: this.chatId,
          token: this.token,
          type: 1,
          value: this.buttonValueSetting.toString()
        }
        if (this.token !== '5') {
          await telegramSaveSettingForSinglePayment(telegramSaveSettingForSinglePaymentPost)
        }
        this.showSettings = false
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    checkSettingsInputValue (index: number) {
      if (parseFloat(this.settingInputs[index]) > 2000) {
        this.settingInputs[index] = '2000'
      }
    },
    calculateSum () {
      let sum = 0
      for (const key in this.paymentInputs) {
        if (Object.prototype.hasOwnProperty.call(this.paymentInputs, key)) {
          sum += parseInt(this.paymentInputs[key]) || 0
        }
      }
      this.totalAmount = sum
    },
    async pay () {
      try {
        this.loading = true
        const paymentKeys = [] as PaymentKey[]
        this.telegramPaymentKeyForSinglePayment.forEach((paymentKeyItem, paymentKeyIndex) => {
          this.paymentKeyNames.push(paymentKeyItem.paymentKeyName)
          const accounts = [] as Account[]
          let accountSum = '0'
          paymentKeyItem.accounts.forEach((accountItem) => {
            for (const key in this.paymentInputs) {
              const keyparts = key.split('-')
              if (keyparts[1] === paymentKeyIndex.toString()) {
                if (keyparts[0] === 'main' && accountItem.type === '1') {
                  accountSum = this.paymentInputs[key]
                }
                if (keyparts[0] === 'buffet' && accountItem.type === '2') {
                  accountSum = this.paymentInputs[key]
                }
              }
            }
            const account: Account = {
              type: accountItem.type.toString(),
              sum: accountSum
            }
            accountSum = '0'
            if (account.type && account.sum) {
              accounts.push(account)
            }
          })
          let paymentType = 0
          let paymentTypeDetails = null
          if (paymentKeyItem.paymentType === 0 && paymentKeyItem.sbpSubscriptions.length < 1 && paymentKeyItem.bankBindings.length < 1) {
            paymentType = 0
            paymentTypeDetails = null
          } else {
            if (this.selectedItem[paymentKeyIndex].paymentType === 2) {
              paymentType = this.selectedItem[paymentKeyIndex].paymentType
              paymentTypeDetails = this.selectedItem[paymentKeyIndex].details
            }
            if (this.selectedItem[paymentKeyIndex].paymentType === 1) {
              paymentType = this.selectedItem[paymentKeyIndex].paymentType
              paymentTypeDetails = this.selectedItem[paymentKeyIndex].details
            }
          }
          const paymentkey: PaymentKey = {
            paymentKeyId: paymentKeyItem.paymentKeyId,
            paymentType: paymentType,
            paymentTypeDetails: paymentTypeDetails,
            accounts: accounts
          }
          paymentKeys.push(paymentkey)
        }
        )
        const telegramDoPaymentForSinglePaymentPost: TelegramDoPaymentForSinglePaymentPost = {
          token: this.token,
          chatId: this.chatId,
          paymentKeys: paymentKeys
        }
        if (this.token !== '5') {
          this.paymentResult = await telegramDoPaymentForSinglePayment(telegramDoPaymentForSinglePaymentPost)
          if (this.paymentResult.errorCode !== 0) {
            this.setSnackbar({
              showing: true,
              text: this.paymentResult.errorMessage,
              timeout: 3000
            })
          } else {
            this.showPaymentResults = true
          }
        } else {
          this.paymentResult = {
            errorCode: 0,
            errorMessage: '',
            data: {
              token: 'f719ac3599590567ac5bbf10e01720e72a2d6c59cbf716a1ccb72ad0e61bba62df9a2f9e76227ab46a2c1d8c202ab3a62c66bc340a72b54b6f29f8b9866bae5e',
              chatId: 521226114,
              paymentKeys: [
                {
                  paymentKeyId: 1,
                  paymentType: 2,
                  paymentTypeDetails: '74138',
                  accounts: [
                    {
                      type: '1',
                      sum: '2000',
                      error: 0,
                      errorMessage: 'Заявка успешно сформирована'
                    },
                    {
                      type: '2',
                      sum: '10',
                      error: 0,
                      errorMessage: 'Нулевая сумма'
                    }
                  ]
                },
                {
                  paymentKeyId: 19156,
                  paymentType: 2,
                  paymentTypeDetails: '74138',
                  accounts: [
                    {
                      type: '1',
                      sum: '0',
                      error: 5,
                      errorMessage: 'Нулевая сумма'
                    },
                    {
                      type: '2',
                      sum: '10',
                      error: 0,
                      errorMessage: 'Нулевая сумма'
                    }
                  ]
                },
                {
                  paymentKeyId: 19954,
                  paymentType: 2,
                  paymentTypeDetails: '74138',
                  accounts: [
                    {
                      type: '1',
                      sum: '20',
                      error: 0,
                      errorMessage: 'Нулевая сумма'
                    },
                    {
                      type: '2',
                      sum: '30',
                      error: 5,
                      errorMessage: 'Нулевая сумма'
                    }
                  ]
                },
                {
                  paymentKeyId: 22135,
                  paymentType: 2,
                  paymentTypeDetails: '74138',
                  accounts: [
                    {
                      type: '1',
                      sum: '0',
                      error: 5,
                      errorMessage: 'Нулевая сумма'
                    },
                    {
                      type: '2',
                      sum: '0',
                      error: 5,
                      errorMessage: 'Нулевая сумма'
                    }
                  ]
                }
              ]
            }
          }
          this.showPaymentResults = true
        }
        for (const key in this.paymentInputs) {
          this.paymentInputs[key] = '0'
        }
        this.totalAmount = 0
        // this.loading = false
      } catch (e) {
        this.catchError(e)
      } finally {
        this.loading = false
      }
    },
    clearPaymentInputMain (index: number) {
      const currIndex = index
      let key = 'main-'
      key = key.concat(currIndex.toString())
      setTimeout(() => {
        this.paymentInputs[key] = '0'
        console.log('tut', this.paymentInputs[key])
        this.calculateSum()
      }, 1)
    },
    clearPaymentInputBuffet (index: number) {
      const currIndex = index
      let key = 'buffet-'
      key = key.concat(currIndex.toString())
      setTimeout(() => {
        this.paymentInputs[key] = '0'
        console.log('tut', this.paymentInputs[key])
        this.calculateSum()
      }, 1)
    },
    setDefaultSelectedItems () {
      this.telegramPaymentKeyForSinglePayment.forEach((item, index) => {
        // if (item.sbpSubscriptions.length > 1 || item.bankBindings.length > 1) {
        if (item.paymentType === 2) {
          this.selectedItem[index] = {
            name: item.sbpSubscriptions[item.sbpSubscriptions.length - 1].memberRusName,
            paymentType: item.paymentType,
            details: item.sbpSubscriptions[item.sbpSubscriptions.length - 1].id.toString()
          }
        }
        if (item.paymentType === 1) {
          this.selectedItem[index] = {
            name: item.bankBindings[item.bankBindings.length - 1].maskedPan,
            paymentType: item.paymentType,
            details: item.bankBindings[item.bankBindings.length - 1].maskedPan
          }
        }
        if (item.paymentType === 0 && item.sbpSubscriptions.length > 0) {
          this.selectedItem[index] = {
            name: item.sbpSubscriptions[item.sbpSubscriptions.length - 1].memberRusName,
            paymentType: 2,
            details: item.sbpSubscriptions[item.sbpSubscriptions.length - 1].id.toString()
          }
        } else if (item.paymentType === 0 && item.bankBindings.length > 0) {
          this.selectedItem[index] = {
            name: item.bankBindings[item.bankBindings.length - 1].maskedPan,
            paymentType: 1,
            details: item.bankBindings[item.bankBindings.length - 1].maskedPan
          }
        }
      })
    },
    addSumForMain (index: number, buttonIndex: number) {
      const curIndex = index
      let key = 'main-'
      key = key.concat(curIndex.toString())
      this.paymentInputs[key] = this.buttonValueSetting[buttonIndex]
      this.calculateSum()
    },
    addSumForBuffet (index: number, buttonIndex: number) {
      const curIndex = index
      let key = 'buffet-'
      key = key.concat(curIndex.toString())
      this.paymentInputs[key] = this.buttonValueSetting[buttonIndex]
      this.calculateSum()
    },
    openMenu (index: number) {
      this.menu[index] = true
    },
    selectItem (item: any, type: number, index: number|string) {
      if (typeof index === 'string') {
        const indexx = parseInt(index)
        if (type === 2) {
          this.selectedItem[indexx] = {
            name: item.memberRusName,
            paymentType: type,
            details: item.id.toString()
          }
        }
        if (type === 1) {
          this.selectedItem[indexx] = {
            name: item.maskedPan,
            paymentType: type,
            details: item.maskedPan
          }
        }
        this.menu[indexx] = false
      } else {
        if (type === 2) {
          this.selectedItem[index] = {
            name: item.memberRusName,
            paymentType: type,
            details: item.id.toString()
          }
        }
        if (type === 1) {
          this.selectedItem[index] = {
            name: item.maskedPan,
            paymentType: type,
            details: item.maskedPan
          }
        }
        this.menu[index] = false
      }
      // console.log(this.selectedItem, index)
    },
    showSetting () {
      this.showSettings = true
    },
    closeSetting () {
      this.showSettings = false
    },
    dateText (date: any) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    catchError (error: any) {
      const err = error as AxiosError
      console.log(err)
      this.errorMessage = this.errorMessageUnknown
      if (err.response) {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    }
  },
  beforeCreate () {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-web-app.js'
    script.defer = true
    document.head.appendChild(script)
  },
  beforeMount () {
    if (this.tokenProp) {
      this.loading = true
      this.token = this.tokenProp
      const tokenArray = this.tokenProp.split('&')
      if (tokenArray.length > 1) {
        this.chatId = tokenArray[0]
        this.token = tokenArray[1]
        // this.telegramSupportGetDetailsByTicket()
      } else {
        const timer = setInterval(() => {
          if (window.Telegram !== undefined) {
            const WebApp = window.Telegram.WebApp
            if (WebApp.initDataUnsafe !== undefined) {
              if (WebApp.initDataUnsafe.user !== undefined) {
                this.chatId = WebApp.initDataUnsafe.user.id.toString()
              }
            }
            clearInterval(timer)
            this.telegramSupportGetDetailsByTicket()
          } else {
            console.log('нет')
          }
        }, 1000)
      }
      if (this.token === '5') {
        this.loading = false
        this.telegramPaymentKeyForSinglePayment = [
          {
            paymentKeyId: 1,
            paymentKey: '4681234',
            paymentKeyName: 'Константин',
            paymentType: 2,
            paymentTypeDetails: '757575',
            acquiringCustomerSetting: null,
            userId: null,
            accounts: [
              {
                type: '1',
                title: 'Горячее питание',
                sum: 150.0000
              },
              {
                type: '2',
                title: 'Буфет',
                sum: 99999.99
              }
            ],
            bankBindings: [
              {
                bankCode: '0',
                bankMessage: 'Успешно',
                id: 0,
                maskedPan: '546955XXXXXX7777',
                displayLabel: '7777',
                paymentWay: 'CARD'
              }
            ],
            sbpSubscriptions: [
              {
                id: 74138,
                memberId: '100000000111',
                memberRusName: 'Сбербанк',
                memberPictExist: 1
              }
            ]
          },
          {
            paymentKeyId: 1,
            paymentKey: '4681234',
            paymentKeyName: 'Евгений',
            paymentType: 1,
            paymentTypeDetails: '757575',
            acquiringCustomerSetting: null,
            userId: null,
            accounts: [
              {
                type: '1',
                title: 'Горячее питание',
                sum: 150.0000
              },
              {
                type: '2',
                title: 'Буфет',
                sum: 50.0000
              }
            ],
            bankBindings: [
              {
                bankCode: '0',
                bankMessage: 'Успешно',
                id: 0,
                maskedPan: '546955XXXXXX7777',
                displayLabel: '7777',
                paymentWay: 'CARD'
              },
              {
                bankCode: '0',
                bankMessage: 'Успешно',
                id: 0,
                maskedPan: '546955XXXXXX1111',
                displayLabel: '7777',
                paymentWay: 'CARD'
              }
            ],
            sbpSubscriptions: [
              {
                id: 74138,
                memberId: '100000000111',
                memberRusName: 'Сбербанк',
                memberPictExist: 1
              },
              {
                id: 74139,
                memberId: '100000000004',
                memberRusName: 'Тинькоф',
                memberPictExist: 1
              }
            ]
          },
          {
            paymentKeyId: 0,
            paymentKey: '4681234',
            paymentKeyName: 'Тарас',
            paymentType: 0,
            paymentTypeDetails: '73214',
            acquiringCustomerSetting: null,
            userId: null,
            accounts: [
              {
                type: '1',
                title: 'Горячее питание',
                sum: 150.0000
              },
              {
                type: '2',
                title: 'Буфет',
                sum: 50.0000
              }
            ],
            bankBindings: [
              {
                bankCode: '0',
                bankMessage: 'Успешно',
                id: 0,
                maskedPan: '546955XXXXXX7777',
                displayLabel: '7777',
                paymentWay: 'CARD'
              }
            ],
            sbpSubscriptions: [
              {
                id: 74138,
                memberId: '100000000111',
                memberRusName: 'Сбербанк',
                memberPictExist: 1
              }
            ]
          },
          {
            paymentKeyId: 0,
            paymentKey: '4681234',
            paymentKeyName: 'Марат',
            paymentType: 0,
            paymentTypeDetails: '666666',
            acquiringCustomerSetting: null,
            userId: null,
            accounts: [
              {
                type: '1',
                title: 'Горячее питание',
                sum: 150.0000
              },
              {
                type: '2',
                title: 'Буфет',
                sum: 50.0000
              }
            ],
            bankBindings: [
              {
                bankCode: '0',
                bankMessage: 'Успешно',
                id: 0,
                maskedPan: '546955XXXXXX7777',
                displayLabel: '7777',
                paymentWay: 'CARD'
              }
            ],
            sbpSubscriptions: [
            ]
          },
          {
            paymentKeyId: 1,
            paymentKey: '4681234',
            paymentKeyName: 'Федор',
            paymentType: 0,
            paymentTypeDetails: null,
            acquiringCustomerSetting: null,
            userId: null,
            accounts: [
              {
                type: '1',
                title: 'Горячее питание',
                sum: 150.0000
              },
              {
                type: '2',
                title: 'Буфет',
                sum: 50.0000
              }
            ],
            bankBindings: [
            ],
            sbpSubscriptions: [
            ]
          }
        ]
        this.setDefaultSelectedItems()
      }
    }
  }
})
</script>
<style>
.add_sum_btn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #a19ea9;
  background: #FDEBA3;
  font-weight: 500;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
}
.telegram-input {
  width: 90px;
  margin-left: 15px;
  background: #ffffff;
  border-radius: 3px;
}
::placeholder {
  padding-left: 5px;
}
.list-item {
  border: 1px solid #f5f5f5;
  text-align: center;
  gap: 5px;
}
.first-row {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 15px;
  background: #f5f5f5;
}
.square-btn {
  min-width: unset !important;
}
</style>
