<template>
  <v-container>
    <v-row class="justify-content-center mb-3">
      <v-col sm="12">
        <v-btn
          variant="blue"
          size="sm"
          :to="{ name: 'DeviceList' }"
          class="mb-4"
        >
          <v-icon
            theme="dark"
            start
          >
            mdi-arrow-left
          </v-icon>
          Устройства
        </v-btn>
        <v-btn
          variant="blue"
          block
          :to="{ name: 'CreateIncrementGroup' }"
          class="mb-4"
        >
          Создать группу
        </v-btn>
        <v-card
          v-if="incrementGroups.length > 0"
          class="mx-auto"
        >
          <v-row no-gutters>
            <v-col
              v-for="incrementGroup in incrementGroupList"
              :key="incrementGroup.id"
              cols="12"
              sm="4"
            >
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-card
                      color="#1B5E20"
                      theme="dark"
                    >
                      <v-card-title>
                        <v-row>
                          <v-col style="text-align: left;">
                            {{ incrementGroup.name }}
                          </v-col>
                          <v-col style="text-align: right;">
                            <v-btn
                              class="mx-2"
                              fab
                              theme="dark"
                              size="small"
                              color="primary"
                              @click="incrementGroupToggle(incrementGroup)"
                            >
                              <v-icon
                                v-if="!incrementGroup.visible"
                                theme="dark"
                              >
                                mdi-arrow-down
                              </v-icon>
                              <v-icon
                                v-else
                                theme="dark"
                              >
                                mdi-arrow-up
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-title>

                      <v-card-actions>
                        <v-btn
                          color="orange dark"
                          variant="text"
                          :to="{ name: 'EditIncrementGroup', params: { id: incrementGroup.id } }"
                          action
                        >
                          Редактировать
                        </v-btn>

                        <v-spacer />
                      </v-card-actions>

                      <v-expand-transition v-if="incrementGroup.visible">
                        <div>
                          <v-divider />
                          <v-card-text>
                            <v-list lg="6">
                              <v-list-item-group color="primary">
                                <v-list-item
                                  v-for="incrementGroupTime in incrementGroup.incrementGroupTimes"
                                  :key="incrementGroupTime.id"
                                >
                                  <div>
                                    {{ incrementGroupTime.time }}
                                  </div>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card-text>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
        <div
          v-else
          class="mt-2 text-center"
        >
          <v-progress-circular label="Spinning" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

type IncrementGroupList = {
  incrementGroupTimes: any;
}
type BaseData = {
  incrementGroupList: any;
}
export default defineComponent({
  components: {
  },
  data (): BaseData {
    return {
      incrementGroupList: []
    }
  },
  computed: {
    ...mapGetters({
      incrementGroups: 'incrementGroups/incrementGroups',
      incrementGroupTimes: 'incrementGroupTimes/incrementGroupTimes'
    })
  },
  methods: {
    ...mapActions('incrementGroups', { incrementGroupAll: 'all' }),
    ...mapActions('incrementGroupTimes', { incrementGroupTimeAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadProgramData () {
      try {
        this.$store.commit('incrementGroups/CLEAR_INCREMENTGROUPS')
        const result = await this.incrementGroupAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    incrementGroupListGet () {
      const incrementGroups = (this as any).incrementGroupTimeGet()
      incrementGroups.forEach((element: any) => {
        element.visible = false
      })
      this.incrementGroupList = JSON.parse(JSON.stringify(incrementGroups))
    },
    async loadIncrementGroupTimes () {
      try {
        this.$store.commit('incrementGroupTimes/CLEAR_INCREMENTGROUPTIMES')
        const result = await this.incrementGroupTimeAll()
        this.incrementGroupListGet()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    incrementGroupTimeGet () {
      const temp: any = this.incrementGroups.map((incrementGroup: any) => {
        incrementGroup.incrementGroupTimes = []
        const thisIncrementGroupTimes = this.incrementGroupTimes.filter((incrementGroupTime: any) => incrementGroupTime.incrementGroupId === incrementGroup.id)
        if (thisIncrementGroupTimes != null) {
          incrementGroup.incrementGroupTimes = thisIncrementGroupTimes
        }
        return incrementGroup
      })
      return temp
    },
    incrementGroupToggle (incrementGroup: any) {
      if (incrementGroup.visible === true) {
        incrementGroup.visible = false
      } else {
        incrementGroup.visible = true
      }
    }
  },
  mounted () {
    this.loadProgramData()
    this.loadIncrementGroupTimes()
  }
})
</script>

<style lang='scss'></style>
