import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import {
  SaleForChangeToPaymentPost,
  SaleGroupByProductsFindPost,
  SaleGroupByPlacesFindPost,
  SalesBySaleBatchFindPost,
  SaleByPlaceFindPost,
  SaleByProductFindPost,
  SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost,
  SaleForTableFindPost,
  SaleByPlaceAndProductFindPost,
  SaleByPlaceAndProductAndUserFindPost,
  SalesForReportPost,
  ReportSaleByTalonsPost, findForTableByOcudAndPlacesRequest
} from './api-types'

const salesGroupByProductsFind = 'sales/findgroupbyproducts'
const salesGroupByPlacesFind = 'sales/findgroupbyplaces'
const salesByPlaceFind = 'sales/findbyplace'
const salesByPlaceAndProductFind = 'sales/findbyplaceandproduct'
const salesByPlaceAndProductAndUserFind = 'sales/getchecks'
const salesByPlaceIdFind = 'sales/findbyplaceid'
const salesByProductFind = 'sales/findbyproduct'
const salesByDateAndPlaceCustomerProductsWithRatePlansFind = 'sales/findbydateandplacecustomerproducts'
const salesByPeriodAndPlaceCustomerProductsWithRatePlansFind = 'sales/findbyperiodandplacecustomerproducts'
const routeSalesBySaleBatchFind = 'sales/findbysalebatch'
const salesForTableFind = 'reports/tables/byocud'
const salesByOcudAndPlaces = 'reports/tables/byOcudAndPlaces'
const salesForReportRoute = 'sales/salesforreport'
const saleChangeToPaymentRoute = 'sales/changeToPayment'
const salesByTalons = 'reports/table'

export const findReportSalesByTalons = async (data: ReportSaleByTalonsPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(salesByTalons, data)
  return result.data.data
}

export const findGroupByProducts = async (data: SaleGroupByProductsFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesGroupByProductsFind, data)
  return result.data.data
}

export const findGroupByPlaces = async (data: SaleGroupByPlacesFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesGroupByPlacesFind, data)
  return result.data.data
}

export const findByPlace = async (data: SaleByPlaceFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByPlaceFind, data)
  return result.data.data
}

export const findByPlaceAndProduct = async (data: SaleByPlaceAndProductFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByPlaceAndProductFind, data)
  return result.data.data
}

export const findByPlaceAndProductAndUser = async (data: SaleByPlaceAndProductAndUserFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByPlaceAndProductAndUserFind, data)
  return result.data.data
}

export const findByPlaceId = async (data: SaleByPlaceFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByPlaceIdFind, data)
  return result.data.data
}

export const findByProduct = async (data: SaleByProductFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByProductFind, data)
  return result.data.data
}

export const findByDateAndPlaceCustomerProductsWithRatePlans = async (data: SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByDateAndPlaceCustomerProductsWithRatePlansFind, data)
  return result.data.data
}

export const findByPeriodAndPlaceCustomerProductsWithRatePlans = async (data: SaleByDateAndPlaceCustomerProductsWithRatePlansFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByPeriodAndPlaceCustomerProductsWithRatePlansFind, data)
  return result.data.data
}

export const findForTableByOkud = async (data: SaleForTableFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesForTableFind, data)
  return result.data.data
}

export const findForTableByOcudAndPlaces = async (data: findForTableByOcudAndPlacesRequest): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesByOcudAndPlaces, data)
  return result.data.data
}

export const findBySaleBatch = async (data: SalesBySaleBatchFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(routeSalesBySaleBatchFind, data)
  return result.data.data
}

export const salesForReport = async (data: SalesForReportPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(salesForReportRoute, data)
  return result.data.data
}

export const changeToPayment = async (data: SaleForChangeToPaymentPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(saleChangeToPaymentRoute, data)
  return result.data.data
}
