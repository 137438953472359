<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
      >
        <v-select
          v-model="selectedUserAllowedPlace"
          :items="userAllowedPlacesList"
          item-title="place.name"
          item-value="place.id"
          label="Школа"
          @update:model-value="loadDevices()"
        >
          <template #prepend-item>
            <v-list-item>
              <v-text-field
                v-model="searchTerm"
                :placeholder="'Поиск'"
                @update:model-value="searchUserAllowedPlaces"
              />
            </v-list-item>
            <v-divider class="mt-2"/>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="d-flex">
        <v-select
          v-model="selectedDevice"
          :items="devices"
          item-title="lastName"
          item-value="id"
          label="Устройство"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
<!--        <v-date-input-->
<!--          label="Дата С"-->
<!--        />-->
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateFrom)"
              label="Дата с"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            v-model="dateFrom"
            title="Дата С"
            @input="menu1 = false"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateTo)"
              label="Дата по"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата По"
            v-model="dateTo"
            @input="menu2 = false"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <v-col
        v-if="isPost"
        class="d-flex"
      >
        <v-checkbox
          v-model="showCashSales"
          label="Показывать наличные"
        />
      </v-col>
      <v-col
        v-if="isPost"
        class="d-flex"
      >
        <v-checkbox
          v-model="showSumByFlags"
          label="выводить подытоги"
        />
      </v-col>
      <v-col
        v-if="isPost"
        class="d-flex"
      >
        <v-checkbox
          v-model="showSumByGroups"
          label="итоги по группам"
        />
      </v-col>
    </v-row>
    <div>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
        <v-col
          v-if="!isLoading && (sales.length > 0 || cashSales.length > 0)"
        >
          <v-table class="report-rateplan-day">
            <template #default>
              <thead>
              <tr>
                <th
                  class="bg-green-darken-4 text-center text-white"
                  colspan="100%"
                >
                  Отчет по продажам
                </th>
              </tr>
              <tr>
                <th>Безналичные</th>
                <th v-if="showCashSales">
                  Наличные
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="vertical-align: top; padding: 0;">
                  <table
                    class="report-rateplan-day"
                    style="width:100%;"
                  >
                    <thead>
                    <tr>
                      <th colspan="4">
                        Буфет
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(buffetSale, i) in buffetSales"
                      :key="i"
                      class="gogoToDetailRow"
                      @click="showProductDialog(buffetSale.productId, buffetSale.productName, false, buffetSale.discountAvg)"
                    >
                      <td class="pa-1">
                        {{ buffetSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ buffetSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ buffetSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ buffetSale.sum }}
                      </td>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого собственное производство:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(buffetSales, 0) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого покупное:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(buffetSales, 32768) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого сотр:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 3, false, true) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого остальные:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceSales, 2, 2, false, true) }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ buffetSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Абонемент
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(hotfoodSale, i) in hotfoodSales"
                      :key="i"
                      class="gogoToDetailRow"
                      @click="showProductDialog(hotfoodSale.productId, hotfoodSale.productName, false, hotfoodSale.discountAvg)"
                    >
                      <td class="pa-1">
                        {{ hotfoodSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ hotfoodSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ hotfoodSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ hotfoodSale.sum }}
                      </td>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого абонементы:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(hotfoodSales, 0) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого свободный выбор:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(hotfoodSales, 32768) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого сотр:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceSales, 1, 3, false, true) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого остальные:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceSales, 1, 2, false, true) }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ hotfoodSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Льготное питание
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(discountSale, i) in discountSales"
                      :key="i"
                      class="gogoToDetailRow"
                      @click="showProductDialog(discountSale.productId, discountSale.productName, false, discountSale.discountAvg)"
                    >
                      <td class="pa-1">
                        {{ discountSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ discountSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ discountSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ discountSale.sum }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ discountSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Вендинг
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(vendingSale, i) in vendingSales"
                      :key="i"
                    >
                      <td class="pa-1">
                        {{ vendingSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ vendingSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ vendingSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ vendingSale.sum }}
                      </td>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого сотр:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceSales, 0, 3, true, true) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого остальные:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceSales, 0, 2, true, true) }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ vendingSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Количество блюд в рационах
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th colspan="2">
                        Количество
                      </th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(rationProduct, i) in rationProducts"
                      :key="i"
                    >
                      <td class="pa-1">
                        {{ rationProduct.name }}
                      </td>
                      <td
                        class="pa-1"
                        colspan="2"
                      >
                        {{ Number(rationProduct.quantity).toFixed() }}
                      </td>
                      <td class="pa-1">
                        {{
                          Number(Number(rationProduct.quantity).toFixed() * Number(rationProduct.price).toFixed(2)).toFixed(2)
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  v-if="showCashSales"
                  style="vertical-align: top; padding: 0;"
                >
                  <table
                    class="report-rateplan-day"
                    style="width:100%;"
                  >
                    <thead>
                    <tr>
                      <th colspan="4">
                        Буфет
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(buffetCashSale, i) in buffetCashSales"
                      :key="i"
                      class="gogoToDetailRow"
                      @click="showProductDialog(buffetCashSale.productId, buffetCashSale.productName, true, buffetCashSale.discountAvg)"
                    >
                      <td class="pa-1">
                        {{ buffetCashSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ buffetCashSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ buffetCashSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ buffetCashSale.sum }}
                      </td>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого собственное производство:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(buffetCashSales, 0) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого покупное:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(buffetCashSales, 32768) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого сотр:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceCashSales, 2, 3, false, false) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого остальные:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceCashSales, 2, 2, false, false) }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ buffetCashSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Абонемент
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(hotfoodCashSale, i) in hotfoodCashSales"
                      :key="i"
                      class="gogoToDetailRow"
                      @click="showProductDialog(hotfoodCashSale.productId, hotfoodCashSale.productName, true, hotfoodCashSale.discountAvg)"
                    >
                      <td class="pa-1">
                        {{ hotfoodCashSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ hotfoodCashSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ hotfoodCashSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ hotfoodCashSale.sum }}
                      </td>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого абонементы:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(hotfoodCashSales, 0) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByFlags">
                      <th>
                        Итого свободный выбор:
                      </th>
                      <th colspan="3">
                        {{ getSumByProductFlag(hotfoodCashSales, 32768) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого сотр:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceCashSales, 1, 3, false, false) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого остальные:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceCashSales, 1, 2, false, false) }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ hotfoodCashSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Льготное питание
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(discountCashSale, i) in discountCashSales"
                      :key="i"
                      class="gogoToDetailRow"
                      @click="showProductDialog(discountCashSale.productId, discountCashSale.productName, true, discountCashSale.discountAvg)"
                    >
                      <td class="pa-1">
                        {{ discountCashSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ discountCashSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ discountCashSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ discountCashSale.sum }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ discountCashSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                      <th colspan="4">
                        Вендинг
                      </th>
                    </tr>
                    <tr>
                      <th>Наименование</th>
                      <th>Количество</th>
                      <th>Цена</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(vendingCashSale, i) in vendingCashSales"
                      :key="i"
                    >
                      <td class="pa-1">
                        {{ vendingCashSale.productName }}
                      </td>
                      <td class="pa-1">
                        {{ vendingCashSale.quantity }}
                      </td>
                      <td class="pa-1">
                        {{ vendingCashSale.price }}
                      </td>
                      <td class="pa-1">
                        {{ vendingCashSale.sum }}
                      </td>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого сотр:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceCashSales, 0, 3, true, false) }}
                      </th>
                    </tr>
                    <tr v-if="showSumByGroups">
                      <th>
                        Итого остальные:
                      </th>
                      <th colspan="3">
                        {{ getSumByPlaceName(reportByUserPlaceCashSales, 0, 2, true, false) }}
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Итого:
                      </th>
                      <th colspan="3">
                        {{ vendingCashSalesSum.toFixed(2) }}
                      </th>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </template>
          </v-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="saleByProductDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="saleByProductDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Продажи по продукту {{ saleProductName }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <v-table
                class="report-rateplan-day"
                style="width:100%;"
              >
                <thead>
                <tr>
                  <th
                    colspan="8"
                    class="bg-green-darken-4 text-center text-white"
                  >
                    Продажи по продукту {{ saleProductName }}
                  </th>
                </tr>
                <tr>
                  <th>№</th>
                  <th>Пользователь</th>
                  <th>Количество</th>
                  <th>Цена</th>
                  <th>Скидка</th>
                  <th>Сумма</th>
                  <th>Чек</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(productSale, i) in productSales"
                  :key="i"
                >
                  <td class="pa-1">
                    {{ i + 1 }}
                  </td>
                  <td class="pa-1 text-left">
                    {{ productSale.lastName }} {{ productSale.firstName }} {{ productSale.middleName }}
                  </td>
                  <td class="pa-1">
                    {{ Number(productSale.quantity) }}
                  </td>
                  <td class="pa-1">
                    {{ Number(productSale.price).toFixed(2) }}
                  </td>
                  <td class="pa-1">
                    {{ Number(productSale.discount).toFixed(2) }}
                  </td>
                  <td class="pa-1">
                    {{
                      ((Number(productSale.price) * Number(productSale.quantity)) - Number(productSale.discount)).toFixed(2)
                    }}
                  </td>
                  <td class="pa-1">
                    <a
                      @click="showChecksDialog(productSale.productId, productSale.userId, productSale.lastName + ' ' + productSale.firstName + ' ' + productSale.middleName, checkCache)"><u>Просмотр</u></a>
                  </td>
                  <td class="pa-1">
                    <router-link :to="{ name: 'UserTransactions', params: { userId: productSale.userId } }">
                      Выписка
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </v-table>
              <v-progress-circular
                v-if="productSaleDialogLoading"
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checksDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          theme="dark"
          color="green-darken-4"
        >
          <v-btn
            icon
            theme="dark"
            @click="checksDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Чеки</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-divider/>
        <br>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
            />
            <v-col
              class="text-center"
              cols="8"
            >
              <h2 style="margin-bottom:20px;">
                {{ checkForUser }}
              </h2>
              <v-row
                v-for="(check, i) in checks"
                :key="i"
              >
                <v-table
                  class="report-rateplan-day"
                  style="width:100%;margin-bottom:20px;"
                >
                  <thead>
                  <tr>
                    <th
                      colspan="8"
                      class="bg-green-darken-4 text-center text-white"
                    >
                      Чек {{ check.id }}
                    </th>
                  </tr>
                  <tr>
                    <th>Дата продажи</th>
                    <th>Дата обработки</th>
                    <th>Продукт</th>
                    <th>Кол-во</th>
                    <th>Цена</th>
                    <th>Скидка</th>
                    <th>Дата отмены</th>
                    <th/>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(saleBatchSale, j) in check.saleBatchSales"
                    :key="j"
                    :style="getStyleForSale(saleBatchSale.sale.productId)"
                  >
                    <td class="pa-1">
                      {{ saleBatchSale.sale.timestamp }}
                    </td>
                    <td class="pa-1">
                      {{ saleBatchSale.sale.creationDate }}
                    </td>
                    <td class="pa-1">
                      {{ saleBatchSale.sale.product.name }}
                    </td>
                    <td class="pa-1">
                      {{ Number(saleBatchSale.sale.quantity) }}
                    </td>
                    <td class="pa-1">
                      {{ Number(saleBatchSale.sale.price).toFixed(2) }}
                    </td>
                    <td class="pa-1">
                      {{ Number(saleBatchSale.sale.discount).toFixed(2) }}
                    </td>
                    <td class="pa-1">
                      {{ saleBatchSale.sale.removed }}
                    </td>
                    <td class="pa-1">
                      <v-btn
                        v-if="saleBatchSale.sale.removed == null && Number(saleBatchSale.sale.discount).toFixed(2) > 0"
                        @click="changeSaleToPayment(saleBatchSale.sale)"
                      >
                        Изменить на платное
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                  <thead>
                  <tr>
                    <th>№ транзакции</th>
                    <th>Дата транзакции</th>
                    <th>Тип транзакции</th>
                    <th>Тип счета</th>
                    <th colspan="4">
                      Сумма
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(saleBatchTransaction, j) in check.saleBatchTransactions"
                    :key="j"
                  >
                    <td class="pa-1">
                      {{ saleBatchTransaction.transaction.id }}
                    </td>
                    <td class="pa-1">
                      {{ saleBatchTransaction.transaction.creationDate }}
                    </td>
                    <td class="pa-1">
                      {{ getTransactionTypeName(saleBatchTransaction.transaction.transactionTypeId) }}
                    </td>
                    <td class="pa-1">
                      {{ saleBatchTransaction.transaction.account.accountType.name }}
                    </td>
                    <td
                      class="pa-1"
                      colspan="4"
                    >
                      {{ Number(saleBatchTransaction.transaction.sum).toFixed(2) }}
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </v-row>
              <v-progress-circular
                v-if="checksDialogLoading"
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import {
  changeToPayment,
  findByPlace,
  findByPlaceAndProduct,
  findByPlaceAndProductAndUser
} from '@/shared/api/salelist/api-requests'
import { findDeviceByPlace } from '@/shared/api/deviceList/api-requests'
import { AxiosError } from 'axios'
import { DeviceByPlaceFindPost } from '@/shared/api/deviceList/api-types'
import {
  SaleByPlaceAndProductAndUserFindPost,
  SaleByPlaceAndProductFindPost,
  SaleForChangeToPaymentPost
} from '@/shared/api/salelist/api-types'
import { transactionTypes } from '@/app/types/vueEnums'
import { VDateInput } from 'vuetify/labs/VDateInput'

type BaseData = {
  reportByUserPlaceSales: any;
  reportByUserPlaceCashSales: any;
  checkCache: boolean;
  checkProductId: any;
  checkForUser: any;
  saleGroupByUsers: any;
  productSaleDialogLoading: boolean;
  checksDialogLoading: boolean;
  saleProductName: any;
  productSales: any;
  checks: any;
  saleByProductDialog: boolean;
  checksDialog: boolean;
  selectedDevice: any;
  devices: any;
  showCashSales: boolean;
  showSumByFlags: boolean;
  showSumByGroups: boolean;
  searchTerm: string;
  userAllowedPlacesCopy: any;
  selectedUserAllowedPlace: any;
  userAllowedPlacesList: any;
  isLoading: boolean;
  isPost: boolean;
  places: any;
  products: any;
  sales: any;
  buffetSalesSum: number;
  hotfoodSalesSum: number;
  discountSalesSum: number;
  vendingSalesSum: number;
  buffetCashSalesSum: number;
  hotfoodCashSalesSum: number;
  discountCashSalesSum: number;
  vendingCashSalesSum: number;
  buffetSales: any;
  hotfoodSales: any;
  discountSales: any;
  vendingSales: any;
  rationProducts: any;
  buffetCashSales: any;
  hotfoodCashSales: any;
  discountCashSales: any;
  vendingCashSales: any;
  cashSales: any;
  dateFrom: any;
  dateTo: any;
  menu1: boolean;
  menu2: boolean;
  saleByPlacePost: any;
  saleGroupByProducts: any;
}

export default defineComponent({
  components: {
    VDateInput
  },
  props: {
    propDateFrom: {
      type: String,
      required: false
    },
    propPlaceId: {
      type: String,
      required: false
    }
  },
  data (): BaseData {
    return {
      reportByUserPlaceSales: [],
      reportByUserPlaceCashSales: [],
      checkCache: false,
      checkProductId: null,
      checkForUser: null,
      saleGroupByUsers: [],
      productSaleDialogLoading: false,
      checksDialogLoading: false,
      saleProductName: null,
      productSales: [],
      checks: [],
      saleByProductDialog: false,
      checksDialog: false,
      selectedDevice: null,
      devices: [],
      showCashSales: true,
      showSumByFlags: false,
      showSumByGroups: false,
      searchTerm: '',
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: null,
      isLoading: false,
      isPost: false,
      places: [],
      products: [],
      sales: [],
      buffetSalesSum: 0,
      hotfoodSalesSum: 0,
      discountSalesSum: 0,
      vendingSalesSum: 0,
      buffetCashSalesSum: 0,
      hotfoodCashSalesSum: 0,
      discountCashSalesSum: 0,
      vendingCashSalesSum: 0,
      buffetSales: [],
      hotfoodSales: [],
      discountSales: [],
      vendingSales: [],
      rationProducts: [],
      buffetCashSales: [],
      hotfoodCashSales: [],
      discountCashSales: [],
      vendingCashSales: [],
      cashSales: [],
      dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      saleByPlacePost: {
        placeId: null,
        timestampFrom: null,
        timestampTo: null
      },
      saleGroupByProducts: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' }),
    // formatDate () {
    //   if (!this.dateFrom) return null
    //   return moment(this.dateFrom).format('DD.MM.YYYY')
    // }
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async loadUserAllowedPlaces () {
      try {
        await this.userAllowedPlaceAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        if (this.propPlaceId !== undefined && this.propPlaceId !== null) {
          this.selectedUserAllowedPlace = this.propPlaceId
        } else {
          this.selectedUserAllowedPlace = this.userAllowedPlacesList[0].place.id
        }
        if (this.selectedUserAllowedPlace !== null) {
          this.loadDevices()
        }
      }
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }

      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    async loadSales () {
      try {
        this.hotfoodSales = []
        this.buffetSales = []
        this.vendingSales = []
        this.rationProducts = []
        this.hotfoodCashSales = []
        this.buffetCashSales = []
        this.vendingCashSales = []
        if (this.selectedUserAllowedPlace != null) {
          this.isLoading = true
          this.dateFrom = moment(this.dateFrom).format('YYYY-MM-DD')
          this.dateTo = moment(this.dateTo).format('YYYY-MM-DD')
          this.saleByPlacePost.placeId = this.selectedUserAllowedPlace
          this.saleByPlacePost.timestampFrom = this.dateFrom
          this.saleByPlacePost.timestampTo = moment(this.dateTo).add('days', 1).format('YYYY-MM-DD')
          if (this.selectedDevice !== 0) {
            this.saleByPlacePost.ownerId = this.selectedDevice
          } else {
            this.saleByPlacePost.ownerId = null
          }
          const result = await findByPlace(this.saleByPlacePost)
          this.saleGroupByProducts = JSON.parse(JSON.stringify(result))
          if (Array.isArray(this.saleGroupByProducts.sales)) {
            this.sales = this.saleGroupByProducts.sales
          }
          if (Array.isArray(this.saleGroupByProducts.cashSales)) {
            this.cashSales = this.saleGroupByProducts.cashSales
          }
          if (Array.isArray(this.saleGroupByProducts.rationProducts)) {
            this.rationProducts = this.saleGroupByProducts.rationProducts
          }
          if (Array.isArray(this.saleGroupByProducts.products)) {
            this.products = this.saleGroupByProducts.products
          }
          if (Array.isArray(this.saleGroupByProducts.reportByUserPlaceSales)) {
            this.reportByUserPlaceSales = JSON.parse(JSON.stringify(this.saleGroupByProducts.reportByUserPlaceSales))
          }
          if (Array.isArray(this.saleGroupByProducts.reportByUserPlaceCashSales)) {
            this.reportByUserPlaceCashSales = JSON.parse(JSON.stringify(this.saleGroupByProducts.reportByUserPlaceCashSales))
          }
          if (this.sales.length > 0) {
            let tempSales = this.sales.filter((el: any) => Number(el.discount) === 0)
            tempSales.forEach((element: any) => {
              const product = this.products.find((el: any) => el.id === element.productId)
              const sale = {
                productName: product.name,
                productId: element.productId,
                price: Number(element.price).toFixed(2),
                quantity: Number(element.quantity).toFixed(0),
                discount: Number(element.discount).toFixed(2),
                sum: Number(element.sum).toFixed(2),
                discountAvg: Number(element.discountAvg)
              }
              if (product.flags !== '1') {
                if (product.accountTypeId === '2') {
                  this.buffetSales.push(sale)
                  this.buffetSalesSum = this.buffetSalesSum + Number(sale.sum)
                } else {
                  this.hotfoodSales.push(sale)
                  this.hotfoodSalesSum = this.hotfoodSalesSum + Number(sale.sum)
                }
              } else {
                this.vendingSales.push(sale)
                this.vendingSalesSum = this.vendingSalesSum + Number(sale.sum)
              }
            })
            tempSales = this.sales.filter((el: any) => Number(el.discount) !== 0)
            tempSales.forEach((element: any) => {
              const product = this.products.find((product: any) => product.id === element.productId)
              const sale = {
                productName: product.name,
                productId: element.productId,
                price: Number(element.price).toFixed(2),
                quantity: Number(element.quantity).toFixed(0),
                discount: Number(element.discount).toFixed(2),
                sum: Number(element.sum).toFixed(2),
                discountAvg: Number(element.discountAvg)
              }
              if (product.flags !== '1') {
                this.discountSales.push(sale)
                this.discountSalesSum = this.discountSalesSum + Number(sale.sum)
              } else {
                this.vendingSales.push(sale)
                this.vendingSalesSum = this.vendingSalesSum + Number(sale.sum)
              }
            })
          }
          if (this.cashSales.length > 0) {
            const tempSales = this.cashSales
            tempSales.forEach((element: any) => {
              const product = this.products.find((product: any) => product.id === element.productId)
              const sale = {
                productName: product.name,
                productId: element.productId,
                price: Number(element.price).toFixed(2),
                quantity: Number(element.quantity).toFixed(0),
                discount: Number(element.discount).toFixed(2),
                sum: (Number(element.price) * Number(element.quantity)).toFixed(2),
                discountAvg: Number(element.discountAvg)
              }
              if (product.flags !== '1') {
                if (product.accountTypeId === '2') {
                  this.buffetCashSales.push(sale)
                  this.buffetCashSalesSum = this.buffetCashSalesSum + (Number(sale.price) * Number(sale.quantity))
                } else {
                  this.hotfoodCashSales.push(sale)
                  this.hotfoodCashSalesSum = this.hotfoodCashSalesSum + (Number(sale.price) * Number(sale.quantity))
                }
              } else {
                this.vendingCashSales.push(sale)
                this.vendingCashSalesSum = this.vendingCashSalesSum + (Number(sale.price) * Number(sale.quantity))
              }
            })
          }
          this.hotfoodSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          this.buffetSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          this.vendingSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          this.hotfoodCashSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          this.buffetCashSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          this.vendingCashSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          this.rationProducts.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        } else {
          alert('Необходимо выбрать школу.')
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadDevices () {
      try {
        const deviceByPlaceFindPost: DeviceByPlaceFindPost = {
          placeId: this.selectedUserAllowedPlace
        }
        const result = await findDeviceByPlace(deviceByPlaceFindPost)
        const loadedDevices = JSON.parse(JSON.stringify(result))
        this.devices = [
          {
            id: 0,
            lastName: 'Все'
          }
        ]
        this.selectedDevice = 0
        if (loadedDevices !== undefined) {
          loadedDevices.forEach((device: any) => {
            this.devices.push({
              id: Number(device.id),
              lastName: device.lastName
            })
          })
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    byProductDetail (productId: string, accountTypeId: number, discount: boolean, cash: boolean, vending: boolean) {
      const dateFrom = this.dateFrom
      const dateTo = this.dateTo
      const placeId = this.selectedUserAllowedPlace
      let sendDiscount = 'false'
      if (discount) {
        sendDiscount = 'true'
      }
      let sendCash = 'false'
      if (cash) {
        sendCash = 'true'
      }
      let sendVending = 'false'
      if (vending) {
        sendVending = 'true'
      }
      this.$router.push({
        name: 'ReportSalesByProdutsDetail',
        params: {
          productId: productId,
          accountTypeId: accountTypeId.toString(),
          discount: sendDiscount,
          cash: sendCash,
          vending: sendVending,
          dateFrom: dateFrom,
          dateTo: dateTo,
          placeId: placeId
        }
      })
    },
    async showProductDialog (productId: number, productName: string | null, isCash: boolean, discountAvg: any) {
      this.productSales = []
      this.saleGroupByUsers = []
      this.checkCache = isCash
      this.saleProductName = productName
      this.saleByProductDialog = true
      this.productSaleDialogLoading = true
      try {
        let ownerId = null
        if (this.selectedDevice !== 0) {
          ownerId = this.selectedDevice
        }
        const saleByPlaceAndProductPost: SaleByPlaceAndProductFindPost = {
          placeId: this.selectedUserAllowedPlace,
          productId: productId,
          timestampFrom: this.dateFrom,
          timestampTo: moment(this.dateTo).add('days', 1).format('YYYY-MM-DD'),
          ownerId: ownerId,
          cash: isCash
        }

        const result = await findByPlaceAndProduct(saleByPlaceAndProductPost)
        this.saleGroupByUsers = JSON.parse(JSON.stringify(result))
        if (Array.isArray(this.saleGroupByUsers.sales)) {
          this.saleGroupByUsers.sales.forEach((element: any) => {
            if (Number(element.discountAvg) === Number(discountAvg)) {
              this.productSales.push(element)
            }
          })
        }
        if (this.productSales.length > 0) {
          this.productSales.sort((a: any, b: any) => (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : ((a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : ((a.middleName > b.middleName) ? 1 : ((b.middleName > a.middleName) ? -1 : 0))))))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.productSaleDialogLoading = false
      }
    },
    async showChecksDialog (productId: number, userId: number, userName: string | null, isCash: boolean) {
      this.checkProductId = productId
      this.checkForUser = userName
      this.checks = []
      this.checksDialog = true
      this.checksDialogLoading = true
      try {
        let ownerId = null
        if (this.selectedDevice !== 0) {
          ownerId = this.selectedDevice
        }
        const saleByPlaceAndProductPost: SaleByPlaceAndProductAndUserFindPost = {
          placeId: this.selectedUserAllowedPlace,
          productId: productId,
          userId: userId,
          timestampFrom: this.dateFrom,
          timestampTo: moment(this.dateTo).add('days', 1).format('YYYY-MM-DD'),
          ownerId: ownerId,
          cash: isCash
        }

        const result = await findByPlaceAndProductAndUser(saleByPlaceAndProductPost)
        const checks = JSON.parse(JSON.stringify(result))
        if (Array.isArray(checks)) {
          checks.forEach((element: any) => {
            this.checks.push(element)
          })
        }
        if (this.checks.length > 0) {
          this.checks.sort((a: any, b: any) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.checksDialogLoading = false
      }
    },
    async changeSaleToPayment (sale: any) {
      try {
        this.checks.forEach((check: any) => {
          check.saleBatchSales.forEach((saleBatchSale: any) => {
            if (saleBatchSale.sale.id === sale.id) {
              saleBatchSale.sale.removed = moment().format('YYYY-MM-DD')
            }
          })
        })
        const saleChangeToPaymentPost: SaleForChangeToPaymentPost = {
          saleId: sale.id
        }
        const result = await changeToPayment(saleChangeToPaymentPost)
        if (result === null) {
          this.checks.forEach((check: any) => {
            check.saleBatchSales.forEach((saleBatchSale: any) => {
              if (saleBatchSale.sale.id === sale.id) {
                saleBatchSale.sale.removed = null
              }
            })
          })
        }
      } catch (error: any) {
        this.checks.forEach((check: any) => {
          check.saleBatchSales.forEach((saleBatchSale: any) => {
            if (saleBatchSale.sale.id === sale.id) {
              saleBatchSale.sale.removed = null
            }
          })
        })
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.checksDialogLoading = false
      }
    },
    getTransactionTypeName (transactionTypeId: any) {
      const transactionType = transactionTypes.find((element: any) => {
        return Number(element.id) === Number(transactionTypeId)
      })
      if (transactionType === undefined) {
        return null
      }
      return transactionType.name
    },
    getStyleForSale (productId: any) {
      if (Number(this.checkProductId) !== Number(productId)) {
        return ''
      }
      return 'font-weight:bold;'
    },
    getSumByProductFlag (sales: any, productFlag: any) {
      let sum = 0
      sales.forEach((sale: any) => {
        const product = this.products.find((el: any) => el.id === sale.productId)
        if (product !== undefined) {
          let flag = product.flags
          if (flag === null) {
            flag = 0
          }
          if (Number(productFlag) === Number(flag)) {
            sum = sum + Number(sale.sum)
          }
        }
      })
      return sum.toFixed(2)
    },
    getSumByPlaceName (sales: any, accountType: number, placeNameType: number, isVending: boolean, checkDiscount: boolean) {
      let sum = 0
      sales.forEach((element: any) => {
        if (element.placeName === null) {
          if (placeNameType === 3) {
            return
          }
        } else {
          if (placeNameType === 2 && element.placeName.toLowerCase() === 'сотр') {
            return
          }
        }
        if (placeNameType === 3 && !element.placeName.toLowerCase().match(/сотр.*/)) {
          return
        }
        const product = this.products.find((product: any) => product.id === element.productId)
        // const sale = { productName: product.name, productId: element.productId, price: Number(element.price).toFixed(2), quantity: Number(element.quantity).toFixed(0), discount: Number(element.discount).toFixed(2), sum: (Number(element.price) * Number(element.quantity)).toFixed(2), discountAvg: Number(element.discountAvg) }

        if (isVending === true && product.flags !== '1') {
          return
        }

        if (Number(element.discountSum) > 0 && isVending !== true) {
          if (checkDiscount) {
            return
          }
        }

        let productAccountType = product.accountTypeId
        if (Number(productAccountType) > 2) {
          productAccountType = 1
        }
        if (Number(accountType) !== Number(productAccountType)) {
          return
        }
        if (checkDiscount) {
          sum = sum + (Number(element.sum))
        } else {
          sum = sum + (Number(element.price) * Number(element.quantity))
        }
      })
      return sum.toFixed(2)
    },
    clearData () {
      this.products = []
      this.sales = []
      this.buffetSalesSum = 0
      this.hotfoodSalesSum = 0
      this.discountSalesSum = 0
      this.vendingSalesSum = 0
      this.buffetCashSalesSum = 0
      this.hotfoodCashSalesSum = 0
      this.discountCashSalesSum = 0
      this.vendingCashSalesSum = 0
      this.buffetSales = []
      this.hotfoodSales = []
      this.discountSales = []
      this.vendingSales = []
      this.buffetCashSales = []
      this.hotfoodCashSales = []
      this.discountCashSales = []
      this.vendingCashSales = []
      this.cashSales = []
    },
    load () {
      this.places = []
      this.products = []
      this.clearData()
      this.loadSales()
      this.isPost = true
    }
  },
  mounted () {
    this.loadUserAllowedPlaces()
    if (this.propPlaceId !== undefined && this.propPlaceId !== null) {
      this.selectedUserAllowedPlace = this.propPlaceId
    }
    if (this.propDateFrom !== undefined && this.propDateFrom !== null && this.selectedUserAllowedPlace !== null) {
      this.dateFrom = this.propDateFrom
      this.load()
    }
  }
})
</script>

<style lang="scss">
.gogoToDetailRow {

  &:hover {
    background-color: lightgray;
  }
}
</style>
