<template>
  <div>
    <v-container>
      <form-container>
        <v-row sm="12">
          <v-col lg="6">
            <v-btn
              variant="blue"
              size="sm"
              :to="{ name: 'IncrementGroupDashboard' }"
              class="mb-4"
            >
              <v-icon
                theme="dark"
                start
              >
                mdi-arrow-left
              </v-icon>
              Группы обновлений
            </v-btn>
            <h1>
              <strong>Редактировать группу</strong>
            </h1>
          </v-col>

          <div class="w-100" />
        </v-row>
        <v-row sm="12">
          <v-col lg="6">
            <v-text-field
              v-model="incrementGroupEditPut.name"
              :has-error="v$.incrementGroupEditPut.name.$error"
              error-message="Введите название."
              label="Название"
              name="name"
              type="text"
              variant="outlined"
              clearable
            />
          </v-col>

          <div class="w-100" />
        </v-row>
        <v-row sm="12">
          <v-col lg="3">
            <submit-button
              name="save-increment-group-submit-button"
              text="Сохранить"
              :is-loading="isLoading"
              :disabled="false"
              loading-text="Сохранение..."
              @submit-clicked="handleSubmit"
            />
          </v-col>

          <div class="w-100" />

          <v-col lg="3">
            <v-btn
              block
              variant="red"
              @click="del"
            >
              Удалить
            </v-btn>
          </v-col>

          <div class="w-100" />
        </v-row>
        <v-row sm="12">
          <v-col cols="6">
            <v-card
              lg="12"
            >
              <v-list>
                <v-subheader class="layout justify-center">
                  ВРЕМЯ ОБНОВЛЕНИЯ
                </v-subheader>
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in incrementGroupTimes"
                    :key="i"
                  >
                    <v-row>
                      <v-col cols="6">
                        {{ item.time }}
                      </v-col>
                      <v-col class="text-right">
                        <v-btn @click="deleteTime(item)">
                          <v-icon
                            size="medium"
                            color="red-darken-2"
                          >
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            sm="6"
          >
            <v-menu
              ref="menu"
              v-model="menu2"
              v-model:return-value="time"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ props }">
                <v-text-field
                  v-model="incrementGroupTimeCreatePost.time"
                  label="Добавить время"
                  prepend-icon="mdi-clock-time-four-outline"

                  v-bind="props"
                />
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="incrementGroupTimeCreatePost.time"
                full-width
                use-seconds
                format="24hr"
                @click:second="$refs.menu.save(incrementGroupTimeCreatePost.time)"
              />
            </v-menu>
            <v-btn
              block
              variant="red"
              @click="addTime"
            >
              Добавить
            </v-btn>
          </v-col>
        </v-row>
      </form-container>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ServerError } from '@/shared/lib/api'
import FormContainer from '@/shared/uikit/inputs/FormContainer.vue'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import { IncrementGroup } from '@/store/incrementGroup/types'
import { IncrementGroupEditPut } from '@/shared/api/incrementGroup/api-types'
import { IncrementGroupTimeCreatePost } from '@/shared/api/incrementGroupTime/api-types'
import { mapActions } from 'vuex'
import { IncrementGroupTime } from '@/store/incrementGroupTime/types'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

type BaseData = {
  incrementGroupEditPut: IncrementGroupEditPut;
  incrementGroupTimeCreatePost: IncrementGroupTimeCreatePost;
  isLoading: boolean;
  time: any;
  menu2: boolean;
  modal2: boolean;
  result: any;
  incrementGroupTimes?: IncrementGroupTime | null;
}


export default defineComponent({
  components: {
    FormContainer,
    SubmitButton
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data (): BaseData {
    return {
      incrementGroupEditPut: {
        name: this.incrementGroup.name
      },
      incrementGroupTimeCreatePost: {
        incrementGroupId: this.incrementGroup.id,
        time: '12:00:00'
      },
      isLoading: false,
      time: '12:00:00',
      menu2: false,
      modal2: false,
      result: null,
      incrementGroupTimes: null
    }
  },
  methods: {
    ...mapActions('incrementGroups', { incrementGroupUpdate: 'update', incrementGroupDel: 'del' }),
    ...mapActions('incrementGroupTimes', { incrementGroupTimeCreate: 'create', incrementGroupTimeDel: 'del' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('incrementGroupTimes', { find: 'find' }),
    async loadProgramData () {
      try {
        this.$store.commit('incrementGroupTimes/CLEAR_INCREMENTGROUPTIMES')
        this.incrementGroupTimes = await this.find(this.incrementGroup.id)
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    async deleteTime (incrementGroupTime: IncrementGroupTime) {
      const result = window.confirm(
        'Уверены, что хотите удалить это время синхронизации?'
      )
      if (!result) {
        return
      }
      try {
        await this.incrementGroupTimeDel(incrementGroupTime)
        this.loadProgramData()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    async handleSubmit () {
      if (this.v$.$invalid) {
        this.v$.$touch()
        return
      }
      this.isLoading = true
      try {
        const editedIncrementGroup = {
          ...this.incrementGroup,
          name: this.incrementGroupEditPut.name
        }
        await this.incrementGroupUpdate(editedIncrementGroup)
        this.$router.push({ name: 'IncrementGroupDashboard' })
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async del () {
      const result = window.confirm(
        'Уверены, что хотите удалить эту группу?'
      )
      if (!result) {
        return
      }
      try {
        await this.incrementGroupDel(this.incrementGroup)
        this.$router.push({ name: 'IncrementGroupDashboard' })
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    async addTime () {
      try {
        this.result = await this.incrementGroupTimeCreate(this.incrementGroupTimeCreatePost)
        this.incrementGroupTimeCreatePost = {
          incrementGroupId: this.incrementGroup.id,
          time: ''
        }
        this.loadProgramData()
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.loadProgramData()
  },
  props: {
    incrementGroup: {
      type: Object as PropType<IncrementGroup>,
      required: true
    }
  },
  validations: {
    incrementGroupEditPut: {
      name: {
        required
      }
    }
  }
})
</script>

<style lang='scss'></style>
