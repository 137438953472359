<template>
  <div>
    <v-container>
      <form-container>
        <v-row class="justify-content-center">
          <v-col lg="6">
            <v-btn
              variant="blue"
              size="sm"
              :to="{ name: 'IncrementGroupDashboard' }"
              class="mb-4"
            >
              <v-icon
                theme="dark"
                start
              >
                mdi-arrow-left
              </v-icon>
              Группы
            </v-btn>
            <h1>
              <strong>Создание группы</strong>
            </h1>
          </v-col>
        </v-row>
        <v-row lg="12">
          <div class="w-100"/>

          <v-col lg="6">
            <v-text-field
              v-model="incrementGroupCreatePost.name"
              :has-error="v$.incrementGroupCreatePost.name.$error"
              error-message="Введите название"
              label="Название"
              name="name"
              type="text"
              clearable
              variant="outlined"
            />
          </v-col>

          <div class="w-100"/>
        </v-row>
        <v-row lg="12">
          <v-col lg="6">
            <submit-button
              name="create-increment-group-submit-button"
              text="Создать"
              :is-loading="isLoading"
              loading-text="Создание..."
              :disabled="!valid"
              @submit-clicked="handleSubmit"
            />
          </v-col>
        </v-row>
      </form-container>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ServerError } from '@/shared/lib/api'
import FormContainer from '@/shared/uikit/inputs/FormContainer.vue'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import { IncrementGroupCreatePost } from '@/shared/api/incrementGroup/api-types'
import { mapActions } from 'vuex'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'


type BaseData = {
  incrementGroupCreatePost: IncrementGroupCreatePost;
  isLoading: boolean;
  valid: boolean;
  result: any;
}
export default defineComponent({
  components: {
    FormContainer,
    SubmitButton
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data (): BaseData {
    return {
      incrementGroupCreatePost: {
        name: ''
      },
      isLoading: false,
      valid: true,
      result: null
    }
  },
  methods: {
    ...mapActions('incrementGroups', { incrementGroupCreate: 'create' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async handleSubmit () {
      if (this.v$.$invalid) {
        this.v$.$touch()
        return
      }
      this.isLoading = true
      try {
        this.result = await this.incrementGroupCreate(this.incrementGroupCreatePost)
        this.incrementGroupCreatePost = {
          name: ''
        }
        this.$router.push({ name: 'EditIncrementGroup', params: { id: this.result.id } })
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  validations: {
    incrementGroupCreatePost: {
      name: {
        required
      }
    }
  }
})
</script>

<style lang='scss'></style>
