<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-card-text>
          <v-container
            v-if="selectedItem !== null"
            fluid
          >
            <v-row>
              <v-col
                align="center"
                style="top: 10px; position: relative; left: -30px;"
              >
                <h1>{{ selectedItem.id }}</h1>
              </v-col>
              <v-col align="center">
                <v-btn @click="back()">
                  Назад
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  ref="ordersTable"
                  dense
                  :items="selectedItemAnswers"
                  item-key="id"
                  class="elevation-1"
                  :footer-props="{
                    'items-per-page-options':[10],
                    'disable-items-per-page': true,
                  }"
                  hide-default-header
                >
                  <template #[`item`]="{ item }">
                    <tr :style="item.type == 2 ? 'background-color: antiquewhite !important;' : ''">
                      <td>
                        <b>Дата, время:</b>: {{ item.date }}<br>
                        <b>Имя</b>: {{ item.name }}<br>
                        <b>Сообщение</b>: {{ item.message }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-else
            fluid
          >
            <v-row>
              <v-col align="center">
                <v-btn @click="closeModal()">
                  Закрыть
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  ref="ordersTable"
                  dense
                  :items="tableData"
                  item-key="id"
                  class="elevation-1"
                  :footer-props="{
                    'items-per-page-options':[10],
                    'disable-items-per-page': true,
                  }"
                  hide-default-header
                >
                  <template #[`item`]="{ item }">
                    <tr @click="selectItem(item)">
                      <td>{{ item.id }}</td>
                      <td>{{ dateText(item.requestTimestamp) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import moment from 'moment'

export default {
  props: {
    data: {
      type: Array
    },
    opened: {
      type: Boolean
    }
  },
  data: () => ({
    tableData: [],
    selectedItemAnswers: [],
    dialog: false,
    selectedItem: null
  }),
  watch: {
    data: function (val) {
      this.tableData = val
    },
    opened: function (val) {
      this.dialog = val
    },
    dialog: function (val) {
      if (!val) {
        this.$emit('update:opened', val)
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    selectItem (item) {
      this.selectedItem = item
      const nameClient = item.telegramChatAccount.firstName
      this.selectedItemAnswers.push({
        date: this.dateText(item.requestTimestamp),
        message: item.request,
        name: nameClient,
        type: 1
      })
      item.telegramSupportTicketAnswers.forEach(element => {
        let type = 1
        let name = nameClient
        if (element.telegramSupportOperatorId !== null) {
          type = 2
          name = element.telegramSupportOperator.name
        }
        this.selectedItemAnswers.push({
          date: this.dateText(element.answereTimestamp),
          message: element.answere,
          name: name,
          type: type
        })
      })
    },
    back () {
      this.selectedItem = null
      this.selectedItemAnswers = []
    },
    dateText (date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    }
  }
}
</script>
<style lang="scss">
</style>
