<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
      >
        <v-select
          v-model="selectedUserAllowedPlace"
          :items="userAllowedPlacesList"
          item-title="place.name"
          item-value="place.id"
          label="Школа"
        >
          <template #prepend-item>
            <v-list-item>
              <v-text-field
                v-model="searchTerm"
                :placeholder="'Поиск'"
                @update:model-value="searchUserAllowedPlaces"
              />
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedMonth"
          :items="months"
          item-title="name"
          item-value="id"
          label="Месяц"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedYear"
          :items="years"
          item-title="name"
          item-value="id"
          label="Год"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
      <v-row class="justify-content-center">
        <v-col
          cols="2"
        />
        <v-col
          v-if="!isLoading && arrSales.length > 0"
          cols="8"
        >
          <v-table class="report-rateplan-day">
            <template #default>
              <thead>
                <tr>
                  <th
                    class="bg-green-darken-4 text-center text-white"
                    colspan="100%"
                  >
                    Отчет по продажам
                  </th>
                </tr>
                <tr>
                  <th>
                    Группа
                    <v-select
                      v-model="searchDescriptionTerm"
                      :items="descriptions"
                      item-value="id"
                      item-title="name"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th>
                    Наименование
                    <v-text-field
                      v-model="productSearchTerm"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th>Количество</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(arrSale, i) in arrSales">
                  <tr
                    v-if="arrSale.visible == 1"
                    :key="i"
                  >
                    <td>{{ arrSale.productGroup }}</td>
                    <td>{{ arrSale.productName }}</td>
                    <td>{{ arrSale.quantity }}</td>
                    <td>{{ arrSale.total }}</td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2">
                    Итого:
                  </th>
                  <th style="text-align: right">
                    {{ arrSalesTotalQuantity.toFixed(0) }}
                  </th>
                  <th style="text-align: right">
                    {{ arrSalesTotalSum.toFixed(2) }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { findByPlaceId } from '@/shared/api/salelist/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  searchDescriptionTerm: any;
  productSearchTerm: string;
  searchTerm: string;
  userAllowedPlacesCopy: any;
  selectedUserAllowedPlace: any;
  userAllowedPlacesList: any;
  isLoading: boolean;
  isPost: boolean;
  places: any;
  products: any;
  sales: any;
  arrSales: any;
  arrSalesSum: any;
  arrSalesDiscountSum: any;
  arrSalesTotalSum: any;
  arrSalesTotalQuantity: any;
  months: any;
  selectedMonth: any;
  years: any;
  selectedYear: any;
  saleByPlacePost: any;
  saleGroupByProducts: any;
  descriptions: any;
}

export default defineComponent({
  components: {
  },
  data (): BaseData {
    return {
      searchDescriptionTerm: null,
      productSearchTerm: '',
      searchTerm: '',
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: null,
      isLoading: false,
      isPost: false,
      places: [],
      products: [],
      sales: [],
      arrSales: [],
      arrSalesSum: 0,
      arrSalesDiscountSum: 0,
      arrSalesTotalSum: 0,
      arrSalesTotalQuantity: 0,
      months: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' }
      ],
      selectedMonth: moment().month() + 1,
      years: [
      ],
      selectedYear: moment().year(),
      saleByPlacePost: {
        placeId: null,
        timestampFrom: null,
        timestampTo: null
      },
      saleGroupByProducts: null,
      descriptions: []
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadUserAllowedPlaces () {
      try {
        const result = await this.userAllowedPlaceAll()
        this.userAllowedPlacesList = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlacesList.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlacesList.filter((obj: any) => {
          return obj.removed == null
        })

        this.selectedUserAllowedPlace = this.userAllowedPlacesList[0].place.id
      }
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }

      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    async loadSales () {
      try {
        this.searchDescriptionTerm = null
        this.descriptions = []
        let idescription = 0
        this.descriptions.push({
          id: null,
          name: 'Все'
        })
        this.arrSalesSum = 0
        this.arrSalesDiscountSum = 0
        this.arrSalesTotalQuantity = 0
        this.arrSalesTotalSum = 0
        if (this.selectedUserAllowedPlace != null) {
          this.isLoading = true
          this.saleByPlacePost.timestampFrom = moment(this.selectedYear + '-' + this.selectedMonth + '-01').format('YYYY-MM-DD')
          this.saleByPlacePost.timestampTo = moment(this.selectedYear + '-' + this.selectedMonth + '-01').add('months', 1).format('YYYY-MM-DD')
          this.saleByPlacePost.placeId = this.selectedUserAllowedPlace
          const result = await findByPlaceId(this.saleByPlacePost)
          this.saleGroupByProducts = JSON.parse(JSON.stringify(result))
          if (Array.isArray(this.saleGroupByProducts.sales)) {
            this.sales = this.saleGroupByProducts.sales
          }
          if (Array.isArray(this.saleGroupByProducts.products)) {
            this.products = this.saleGroupByProducts.products
          }
          if (this.sales.length > 0) {
            this.sales.forEach((element: any) => {
              const product = this.products.find((el: any) => el.id === element.productId)
              const sale = {
                productGroup: product.description,
                productName: product.name,
                productId: element.productId,
                quantity: Number(element.quantity).toFixed(0),
                discount: Number(element.discount).toFixed(2),
                sum: Number(element.sum).toFixed(2),
                total: Number(element.total).toFixed(2),
                visible: 1
              }
              this.arrSales.push(sale)
              this.arrSalesSum = this.arrSalesSum + Number(sale.sum)
              this.arrSalesDiscountSum = this.arrSalesDiscountSum + Number(sale.discount)
              this.arrSalesTotalQuantity = this.arrSalesTotalQuantity + Number(sale.quantity)
              this.arrSalesTotalSum = this.arrSalesTotalSum + Number(sale.total)
              const description = this.descriptions.find((desc: any) => desc.name === product.description)
              if (description === undefined) {
                idescription = idescription + 1
                this.descriptions.push({
                  id: idescription,
                  name: product.description
                })
              }
            })
            this.arrSales.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))
          }
        } else {
          alert('Необходимо выбрать школу.')
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    clearData () {
      this.places = []
      this.products = []
      this.sales = []
      this.arrSales = []
    },
    load () {
      this.places = []
      this.products = []
      this.clearData()
      this.loadSales()
      this.isPost = true
    },
    tableChange () {
      let searchDescription = ''
      if (this.searchDescriptionTerm !== null) {
        searchDescription = this.descriptions.find((desc: any) => desc.id === this.searchDescriptionTerm).name
      }

      this.arrSales.forEach((element: any) => {
        let visible = 0
        if (this.productSearchTerm === '' && searchDescription === '') {
          visible = 1
        } else {
          if (this.productSearchTerm === '' && element.productGroup === searchDescription) {
            visible = 1
          } else {
            if (element.productName.toLowerCase().indexOf(this.productSearchTerm.toLowerCase()) >= 0 && searchDescription === '') {
              visible = 1
            } else if (element.productName.toLowerCase().indexOf(this.productSearchTerm.toLowerCase()) >= 0 && element.productGroup === searchDescription) {
              visible = 1
            }
          }
        }

        element.visible = visible
      })
    }
  },
  mounted () {
    for (let i = 5; i >= 0; i--) {
      this.years.push({ id: Number(moment().year()) - i, name: String(Number(moment().year()) - i) })
    }
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss"></style>
