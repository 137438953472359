<template>
  <div>
    <div class="filters-panel__row">
      <v-select
        label="Раздел"
        class="filters-panel__filter"
        :items="filterOptions.sectionItems"
        item-title="name"
        item-value="id"
        :model-value="filterState[FilterNames.SECTION_FILTER]"
        :disabled="loadDisabled"
        @update:model-value="filterChangedHandler(FilterNames.SECTION_FILTER, filterState[FilterNames.SECTION_FILTER], $event)"
      />
      <v-select
        label="Организатор питания"
        class="filters-panel__filter"
        :items="filterOptions.ladminItems"
        item-title="name"
        item-value="id"
        :model-value="filterState[FilterNames.LADMIN_FILTER]"
        :disabled="loadDisabled"
        @update:model-value="filterChangedHandler(FilterNames.LADMIN_FILTER, filterState[FilterNames.LADMIN_FILTER], $event)"
      />
      <v-select
        label="ОУ"
        class="filters-panel__filter"
        :items="filterOptions.ouItems"
        item-title="name"
        item-value="id"
        :model-value="filterState[FilterNames.OU_FILTER]"
        :disabled="loadDisabled"
        @update:model-value="filterChangedHandler(FilterNames.OU_FILTER, filterState[FilterNames.OU_FILTER], $event)"
      />
    </div>
    <v-btn
      class="button-load"
      elevation="2"
      :disabled="isLoading"
      @click="loadTable"
    >
      Показать
    </v-btn>
    <template v-if="!isLoading">
      <template v-if="tableData.items">
        <v-btn
          class="button-print"
          elevation="2"
          @click="print"
        >
          ПЕЧАТЬ
        </v-btn>
        <export-excel
          :title="'Отчет по остаткам'"
          :table-data="tableData"
        />
        <div
          id="printable-area"
          :style="{marginTop: '40px'}"
        >
          <div :style="{ display: 'flex', justifyContent: 'center'}">
            <div
              :style="{display:'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', width: 'max-content'}"
            >
              <div
                :style="{textAlign: 'center'}"
                class="only-for-print"
                v-html="title"
              />
              <h3
                :style="{textAlign: 'center'}"
                class="only-for-screen"
              >
                Отчет по остаткам на счетах "Услуги
                ГЛОЛАЙМ"
              </h3>
              <base-simple-table :table-data="tableData" />
            </div>
          </div>
        </div>
      </template>
      <template
        v-else-if="convertedRawApiData.length > 0 || notFirstRender"
      >
        <h3
          :style="{textAlign: 'center'}"
          class="only-for-screen"
        >
          По заданным фильтрам не найдено ни одной записи!
        </h3>
      </template>
    </template>
    <template v-else>
      <v-row>
        <v-col
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import ExportExcel from '@/shared/uikit/ExportExcel.vue'
import { printer } from '@/shared/lib/print'
import { AxiosError } from 'axios'
import { sleep } from '@/shared/lib/sleep'
import { mapActions } from 'vuex'

export enum FilterNames {
  SECTION_FILTER = 'sectionFilter',
  LADMIN_FILTER = 'ladminFilter',
  OU_FILTER = 'ouFilter',
}

export default defineComponent({
  name: 'BalanceOfServices',
  components: { ExportExcel, BaseSimpleTable },
  data () {
    return {
      isLoading: false,
      renderFilterUpdated: false,
      FilterNames: FilterNames,
      notFirstRender: false,
      loadDisabled: false,
      title: '',
      filterOptions: {
        sectionItems: [
          { id: 0, name: 'Все' },
          { id: 1, name: 'Перевыпуск карты' },
          { id: 2, name: 'Выпуск браслета' },
          { id: 3, name: 'Мобильное приложение' },
          { id: 4, name: 'СПС-оповещения' }
        ],
        ladminItems: [],
        ouItems: []
      } as any,
      filterState: {
        sectionFilter: 0,
        ladminFilter: 0,
        ouFilter: 0
      } as any,
      mockApiData:
        [
          {
            name: 'Перевыпуск карты',
            items: [
              {
                id: 10137925,
                firstName: 'Леонид',
                lastName: 'Анкопов',
                surName: 'Алексеевич',
                sum: 100,
                ladminId: 1,
                ouId: 1
              }
            ]
          },
          {
            name: 'Выпуск браслета',
            items: [
              {
                id: 10137925,
                firstName: 'Леонид',
                lastName: 'Анкопов',
                surName: 'Алексеевич',
                sum: 200,
                ladminId: 1,
                ouId: 1
              }
            ]
          },
          {
            name: 'Мобильное приложение',
            items: [
              {
                id: 10137925,
                firstName: 'Леонид',
                lastName: 'Анкопов',
                surName: 'Алексеевич',
                sum: 300,
                ladminId: 1,
                ouId: 1
              }
            ]
          },
          {
            name: 'СМС-Оповещения',
            items: [
              {
                id: 10137925,
                firstName: 'Леонид',
                lastName: 'Анкопов',
                surName: 'Алексеевич',
                sum: 400,
                ladminId: 1,
                ouId: 1
              }
            ]
          }] as any,
      tableData: {} as TableData,
      convertedRawApiData: {} as any
    }
  },
  beforeMount () {
    try {
      this.isLoading = true
      // const filterItems = await ... Получения данных для фильтров
      this.filterOptions.ladminItems = [
        { id: 0, name: 'Все' },
        { id: 1, name: 'Организатор питания 1' },
        { id: 2, name: 'Организатор питания 2' },
        { id: 3, name: 'Организатор питания 3' }
      ]
      this.filterOptions.ouItems = [
        { id: 0, name: 'Все' },
        { id: 1, name: 'ОУ 1' },
        { id: 2, name: 'ОУ 2' },
        { id: 3, name: 'ОУ 3' }
      ]
      this.isLoading = false
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    filterChangedHandler (filterName: any, oldValue: any, newValue: any) {
      // Подгрузка обновленного стейта фильтров и дальнейшая установка флагов о неободимости получения данных с API/перерендоре таблицы
      this.filterState[filterName] = newValue
      this.renderFilterUpdated = true
      // if ([FilterNames.YEAR_FILTER, FilterNames.MONTH_FILTER, FilterNames.PLACE_FILTER].includes(filterName)) {
      //   this.apiFilterUpdated = true
      // } else if ([FilterNames.PRODUCT_TYPE_FILTER].includes(filterName)) {
      //   this.renderFilterUpdated = true
      // }
    },
    async loadTable () {
      try {
        if (!this.notFirstRender) {
          // запускаем подгрузку данных по остаткам
          // const dataFetched = await ...
          await sleep(1000)
          this.convertedRawApiData = this.mockApiData
        }

        // // Фильтрация данных
        const filteredData = this.filterRawApiDate(this.convertedRawApiData)

        // Отрисовка таблицы
        if (this.renderFilterUpdated || !this.notFirstRender) {
          this.notFirstRender = true
          this.isLoading = true
          this.renderTableData(filteredData)
          this.renderFilterUpdated = false
          this.isLoading = false
        }
      } catch (error: any) {
        console.log(error)
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    filterRawApiDate (rawApiDate: any) {
      let tempFilteredData = JSON.parse(JSON.stringify(rawApiDate))
      // console.log('Фильтрация по разделу!')
      try {
        if (this.renderFilterUpdated) {
          switch (this.filterState[FilterNames.SECTION_FILTER]) {
            // case 0:
            //   tempFilteredData = tempFilteredData
            //   break
            case 1:
              tempFilteredData = tempFilteredData.filter((section: any) => section.name === 'Перевыпуск карты')
              break
            case 2:
              tempFilteredData = tempFilteredData.filter((section: any) => section.name === 'Выпуск браслета')
              break
            case 3:
              tempFilteredData = tempFilteredData.filter((section: any) => section.name === 'Мобильное приложение')
              break
            case 4:
              tempFilteredData = tempFilteredData.filter((section: any) => section.name === 'СМС-Оповещения')
              break
          }
          // console.log('Фильтрация по типу питания!')
          if (this.filterState[FilterNames.LADMIN_FILTER] !== 0) {
            tempFilteredData = tempFilteredData.map((section: any) => {
              const sectionItems = section.items.filter((item: any) => {
                return item.ladminId === this.filterState[FilterNames.LADMIN_FILTER]
              })
              if (sectionItems.length > 0) {
                section.items = sectionItems
                return section
              }
            }).filter((section: any) => section !== undefined)
          }
          // console.log('Фильтрация по льготной категории!')
          if (this.filterState[FilterNames.OU_FILTER] !== 0) {
            tempFilteredData = tempFilteredData.map((section: any) => {
              const sectionItems = section.items.filter((item: any) => {
                return item.ouId === this.filterState[FilterNames.OU_FILTER]
              })
              if (sectionItems.length > 0) {
                section.items = sectionItems
                return section
              }
            }).filter((section: any) => section !== undefined)
          }
        }
      } catch (e) {
        console.log(e)
      }

      return tempFilteredData
    },
    renderTableData (filteredData: any) {
      // Конвертация данных в ячейки таблицы
      this.tableData = this.convertToTableData(filteredData)
      this.title =
        `
            <div style="width: 100%">
            <h3>Отчет по остаткам на счетах "Услуги ГЛОЛАЙМ"</h3>
            </div>
       `
    },
    convertToTableData (rawData: any) {
      // Перевод сырых табличных данных, полученных с API, в формат, который понимает GRID-таблиица
      if (rawData.length > 0) {
        const resTable = {
          columnsCount: 4,
          rowsCount: 0,
          items: []
        } as TableData
        let currentRow = 1
        rawData.forEach((section: any, sectionIdx: number) => {
          resTable.items.push(
            {
              text: `${section.name}`,
              background: '#faebd7',
              fontWeight: 'bold',
              fontSize: '16px',
              gridRowStart: currentRow,
              gridRowEnd: currentRow + 1,
              gridColumnStart: 1,
              gridColumnEnd: 4
            },
            {
              text: '№ ЛС',
              background: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              gridRowStart: currentRow + 1,
              gridRowEnd: currentRow + 2,
              gridColumnStart: 1,
              gridColumnEnd: 2
            },
            {
              text: 'ФИО',
              background: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              gridRowStart: currentRow + 1,
              gridRowEnd: currentRow + 2,
              gridColumnStart: 2,
              gridColumnEnd: 3
            },
            {
              text: 'Сумма',
              background: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              gridRowStart: currentRow + 1,
              gridRowEnd: currentRow + 2,
              gridColumnStart: 3,
              gridColumnEnd: 4
            }
          )
          currentRow += 2
          section.items.forEach((item: any) => {
            resTable.items.push(
              {
                text: `${item.id}`,
                background: 'none',
                fontWeight: 'normal',
                fontSize: '16px',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 1,
                gridColumnEnd: 2
              },
              {
                text: `${item.lastName} ${item.firstName} ${item.surName}`,
                background: 'none',
                fontWeight: 'normal',
                fontSize: '16px',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 2,
                gridColumnEnd: 3
              },
              {
                text: `${item.sum}`,
                background: 'none',
                fontWeight: 'normal',
                fontSize: '16px',
                gridRowStart: currentRow,
                gridRowEnd: currentRow + 1,
                gridColumnStart: 3,
                gridColumnEnd: 4
              }
            )
            currentRow += 1
          })
        })
        resTable.rowsCount = currentRow
        return resTable
      } else {
        return {} as TableData
      }
    },
    print () {
      printer('printable-area', 'print.css')
    }
  }
})
</script>

<style scoped lang="scss">
.filters-panel__row {
  display: flex;

  .filters-panel__filter {
    flex: 1 1 0;
    width: 0;
  }

  .filters-panel__filter:not(:first-child):not(:last-child) {
    margin: 4px 4px 0 4px;
  }
}
</style>
