import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0794a843"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_simple_table = _resolveComponent("base-simple-table")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_simple_table, {
      "table-data": _ctx.getTableDataFromAcceptedRation(_ctx.acceptedRation),
      style: {padding: '10px'}
    }, null, 8, ["table-data"]),
    (_ctx.withCheckbox)
      ? (_openBlock(), _createBlock(_component_v_checkbox, {
          key: 0,
          class: "rations_cards__checkbox",
          "model-value": _ctx.checked,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectRationToSave(_ctx.acceptedRation)))
        }, null, 8, ["model-value"]))
      : _createCommentVNode("", true)
  ]))
}