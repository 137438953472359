<template>
  <div>
    <filters-panel
      :filter-options="filterOptions"
      :filter-state="filterState"
      @filter-changed-state="filterChangedHandler"
    />
    <template v-if="tableData.items">
      <v-select
        v-model="selectedHeaderDate"
        label="Дата печати"
        class="filters-panel__filter"
        :items="headerDates"
        item-title="name"
        item-value="value"
      />
    </template>
    <v-btn
      class="button-load"
      elevation="2"
      :disabled="isLoading"
      @click="loadTable"
    >
      Показать
    </v-btn>
    <template v-if="!isLoading">
      <template v-if="tableData.items">
        <v-btn
          class="button-print"
          elevation="2"
          @click="print"
        >
          ПЕЧАТЬ
        </v-btn>
        <export-excel
          :table-data="tableData"
          :title="'По продукту за месяц'"
        />
        <div id="printable-area">
          <div :style="{ display: 'flex', justifyContent: 'center'}">
            <div
              :style="{display:'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', width: 'max-content', maxWidth: getTableWidth}"
            >
              <div
                class="only-for-print"
                :style="{textAlign: 'center', alignSelf: 'start', width: '100%'}"
                v-html="title"
              />
              <h3
                :style="{textAlign: 'center'}"
                class="only-for-screen"
              >
                Табель по продукту за месяц
              </h3>
              <base-simple-table
                :table-data="tableData"
                @mount-grid-table="updateWidth"
              >
                <template #signs>
                  <table :style="{fontSize: '12px', width: '100%', border: 'none', pageBreakInside: 'avoid'}">
                    <tbody>
                      <tr style="border:none;">
                        <td style="font-size:12px; max-width: 200px;border:none;">
                          Руководитель учреждения
                        </td>
                        <td
                          style="font-size:12px;border:none;"
                          align="center"
                        >
                          <u>{{ placeInfo.directorPosition }}</u>
                        </td>
                        <td
                          style="border:none;"
                          align="center"
                        >
                          ______________
                        </td>
                        <td
                          style="font-size:12px;border:none;"
                          align="center"
                        >
                          <u>{{ placeInfo.directorName }}</u>
                        </td>
                        <td style="font-size:12px;border:none;" />
                        <td style="font-size:12px;border:none;">
                          Ответственный
                        </td>
                        <td
                          style="font-size:12px;border:none;"
                          align="center"
                        >
                          <u>Ответственный по питанию</u>
                        </td>
                        <td
                          style="border:none;"
                          align="center"
                        >
                          ______________
                        </td>
                        <td
                          style="font-size:12px;border:none;"
                          align="center"
                        >
                          <u>{{ placeInfo.ladminName }}</u>
                        </td>
                      </tr>
                      <tr style="border:none;">
                        <td style="font-size:12px;border:none;">
                          (ответственный исполнитель)
                        </td>
                        <td
                          style="font-size:9px;border:none;"
                          valign="top"
                          align="center"
                        >
                          (должность)
                        </td>
                        <td
                          style="font-size:9px;border:none;"
                          valign="top"
                          align="center"
                        >
                          (подпись)
                        </td>
                        <td
                          style="font-size:9px;border:none;"
                          valign="top"
                          align="center"
                        >
                          (расшифровка подписи)
                        </td>
                        <td style="font-size:12px;border:none;" />
                        <td style="font-size:12px;border:none;">
                          исполнитель
                        </td>
                        <td
                          style="font-size:9px;border:none;"
                          valign="top"
                          align="center"
                        >
                          (должность)
                        </td>
                        <td
                          style="font-size:9px;border:none;"
                          valign="top"
                          align="center"
                        >
                          (подпись)
                        </td>
                        <td
                          style="font-size:9px;border:none;"
                          valign="top"
                          align="center"
                        >
                          (расшифровка подписи)
                        </td>
                      </tr>
                      <tr style="border:none;">
                        <td
                          colspan="8"
                          style="border:none;"
                        />
                      </tr>
                    </tbody>
                  </table>
                </template>
              </base-simple-table>
            </div>
          </div>
        </div>
      </template>
      <template
        v-else-if="rawApiData.length > 0 || (rawApiData.length === 0 && dataIsLoaded) || notFirstRender"
      >
        <h3
          :style="{textAlign: 'center'}"
          class="only-for-screen"
        >
          По заданным фильтрам не найдено ни одной записи!
        </h3>
      </template>
    </template>
    <template v-else>
      <v-row>
        <v-col
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import { defineComponent } from 'vue'
import FiltersPanel, { FilterNames, FilterOptions } from '@/shared/uikit/FiltersPanel.vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'
import { findReportSalesByTalons } from '@/shared/api/salelist/api-requests'
import { printer } from '@/shared/lib/print'
import compareClasses from '@/shared/lib/compareClasses'
import ExportExcel from '@/shared/uikit/ExportExcel.vue'

export default defineComponent({
  name: 'ByProductPerMonthTable',
  components: { ExportExcel, BaseSimpleTable, FiltersPanel },
  data (): any {
    return {
      tableWidth: '',
      isLoading: false,
      placeInfo: {} as any,
      filterOptions: {
        placeFilter: true,
        classFilter: false,
        productTypeFilter: true,
        ratePlanFilter: false,
        monthFilter: true,
        yearFilter: true,
        classItems: [],
        productTypeItems: [],
        placeItems: [],
        ratePlanItems: []
      } as FilterOptions,
      filterState: {
        placeFilter: 0,
        classFilter: 0,
        productTypeFilter: 0,
        ratePlanFilter: 0,
        monthFilter: moment().month(),
        yearFilter: moment().year(),
        initialState: false
      } as any,
      renderFilterUpdated: false, // флаг, отвечающий за перерисовку таблицы при изменении фильтров, не требующих подгрузки данных с API (классы, льготные категории и т.д.)
      apiFilterUpdated: true, // флаг, отвечающий за перерисовку таблицы при изменении фильтров, требующих подгрузки данных с API (школа, дата и т.п.)
      title: 'Табль по продукту за месяц',
      rawApiData: [] as any,
      tableData: {} as TableData,
      notFirstRender: false,
      selectedHeaderDate: moment().locale('ru').format('DD.MM.YYYY')
    }
  },
  computed: {
    dataIsLoaded (): boolean {
      return Boolean(this.filterOptions.productTypeItems.length > 1)
    },
    getTableWidth () {
      const res = `${this.tableWidth}px` as any
      return res
    },
    headerDates (): any[] {
      return [
        {
          name: 'Текущее число',
          value: moment().locale('ru').format('DD.MM.YYYY')
        },
        {
          name: 'Первое число',
          value: moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).startOf('month').locale('ru').format('DD.MM.YYYY')
        },
        {
          name: 'Последнее число',
          value: moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').locale('ru').format('DD.MM.YYYY')
        }
      ]
    }
  },
  async beforeMount () {
    this.isLoading = true
    const lastDayOfSelectedMonth = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
    const userAllowedPlaces = await this.userAllowedPlaceAll()
    this.filterOptions.placeItems = userAllowedPlaces
    this.filterState[FilterNames.PLACE_FILTER] = userAllowedPlaces[0].placeId
    this.placeInfo = await this.loadCustomerPlaceInfo(this.filterState[FilterNames.PLACE_FILTER], lastDayOfSelectedMonth)
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    updateWidth (tableGridEl: any) {
      this.tableWidth = tableGridEl.offsetWidth ? tableGridEl.offsetWidth : '100%'
    },
    filterChangedHandler (filterName: FilterNames, oldValue: any, newValue: any) {
      // Подгрузка обновленного стейта фильтров и дальнейшая установка флагов о неободимости получения данных с API/перерендоре таблицы
      this.filterState[filterName] = newValue
      if ([FilterNames.YEAR_FILTER, FilterNames.MONTH_FILTER, FilterNames.PLACE_FILTER].includes(filterName)) {
        this.apiFilterUpdated = true
      } else if ([FilterNames.PRODUCT_TYPE_FILTER].includes(filterName)) {
        this.renderFilterUpdated = true
      }
    },
    async loadTable () {
      try {
        this.isLoading = true
        if (this.apiFilterUpdated) {
          // Если поменялись фильтры типа даты, школы и т.д., перезагружаем данные
          const lastDayOfSelectedMonth = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
          const userAllowedPlaces = await this.userAllowedPlaceAll()
          this.placeInfo = await this.loadCustomerPlaceInfo(this.filterState[FilterNames.PLACE_FILTER], lastDayOfSelectedMonth)
          const dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}-1`).startOf('month').format('YYYY-MM-DD')
          const dateTo = moment(`${dateFrom}`).add(1, 'M').startOf('month').format('YYYY-MM-DD')
          const rawDataFromApi = await findReportSalesByTalons({
            placeId: this.placeInfo.placeId.toString(),
            dateFrom: `${dateFrom}`,
            dateTo: `${dateTo}`
          })
          this.rawApiData = this.convertFromRawApi(rawDataFromApi, this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER])
        }
        let tempFilteredData = JSON.parse(JSON.stringify(this.rawApiData))
        if (this.renderFilterUpdated) {
          tempFilteredData = tempFilteredData.map((placeItem: any) => {
            const item = JSON.parse(JSON.stringify(placeItem))
            item.productTypes = placeItem.productTypes.map((productType: any) => {
              if (productType.productTypeId === this.filterState.productTypeFilter) {
                return productType
              }
            }).filter((productType: any) => productType !== undefined)
            return item
          })
        }
        if (this.apiFilterUpdated || this.renderFilterUpdated) {
          // Прерисовка таблицы
          this.isLoading = true
          this.notFirstRender = true
          const convertedData = this.convertToTableData(tempFilteredData)
          this.tableData = convertedData
          this.apiFilterUpdated = false
          this.renderFilterUpdated = false
          this.isLoading = false
        }
      } catch (error: any) {
        console.log(error)
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    convertFromRawApi (rawApiData: any, selectedYear: any, selectedMonth: any) {
      const res: any[] = []
      const days: any[] = []
      const places: any[] = []
      const ratePlans: any[] = []
      const studentsFullNames: any[] = []
      const productTypes: any[] = []
      const monthDate = moment(`${selectedYear}-${selectedMonth + 1}`, 'YYYY-MM')
      const daysInMonth = monthDate.daysInMonth()
      const arrDays: any[] = []
      for (let day = 1; day <= daysInMonth; day++) {
        const current = monthDate.date(day)
        arrDays.push(current.format('YYYY-MM-DD'))
      }
      rawApiData.placeElectronTalons.forEach((placeElectronTalon: any) => {
        if (!days.includes(placeElectronTalon.talonDate)) {
          days.push(placeElectronTalon.talonDate)
        }
        const fullName = `${placeElectronTalon.lastName} ${placeElectronTalon.firstName} ${placeElectronTalon.userId}`
        if (studentsFullNames.findIndex((student: any) => student.fullName === fullName) === -1) {
          const ratePlans = rawApiData.userRatePlans.filter((userRatePlan: any) => {
            return userRatePlan.userId.toString() === placeElectronTalon.userId.toString()
          })
          // Иногда прилетают дети без записи по своему userId в userRatePlans
          if (ratePlans.length > 0) {
            studentsFullNames.push({
              fullName: fullName,
              userId: placeElectronTalon.userId,
              ratePlan: ratePlans[ratePlans.length - 1].ratePlanId,
              placeId: placeElectronTalon.talonPlaceId
            })
          }
        }
        // Делаем список всех видов питания, при этом сразу даляем те виды питания, по которым нет записей в placeElectronTalon (productType.id === placeElectronTalon.talonProductId)
        if (productTypes.findIndex((productType: any) => productType.id === placeElectronTalon.talonProductId) === -1) {
          const productItem = rawApiData.placeElectronTalonProducts.find((placeElectronTalonProduct: any) => placeElectronTalonProduct.productId === placeElectronTalon.talonProductId)
          productTypes.push({
            id: placeElectronTalon.talonProductId,
            name: productItem.product.name,
            code: productItem.product.code
          })
        }
      })
      // Заполним итоговый массив по классам
      rawApiData.places.forEach((place: any) => {
        let studentCount = 0
        studentsFullNames.forEach((student: any) => {
          if (student.placeId === place.id) {
            studentCount += 1
          }
        })
        // Сформируем объект для очерендного класса
        const classItem = {
          id: place.id,
          className: place.name,
          studentCount: studentCount,
          productTypes: [] as any
        }
        // Заполним массив по типам питания для очередного класса
        const classProductTypes: any[] = []
        productTypes.forEach((productType: any) => {
          const classProductType = {
            productTypeId: productType.id,
            productTypeName: productType.name,
            productTypeCode: productType.code,
            productItems: [] as any
          }
          arrDays.forEach((day: string) => {
            const productItem = {
              day: day.split('-')[2],
              count: 0
            }
            rawApiData.placeElectronTalons.forEach((placeElectronTalon: any) => {
              if (placeElectronTalon.talonProductId === productType.id && placeElectronTalon.talonPlaceId === place.id && placeElectronTalon.talonDate === day) {
                productItem.count += 1
              }
            })
            classProductType.productItems.push(productItem)
          })
          classProductTypes.push(classProductType)
        })
        classItem.productTypes = classProductTypes
        places.push(classItem)
      })
      if (productTypes.length > 0) {
        const sortedProductTypes = productTypes.sort((a: any, b: any) => +a.id - +b.id)
        this.filterOptions.productTypeItems = sortedProductTypes
        // Если первая подгрузка, то сбрасываем состояние фильтра на первое из списка,
        // если же просто мы перезагрузили данные API и предыдущее состояние (id) есть в новом sortedProductTypes,
        // то оставляем его
        if (this.filterState.productTypeFilter === 0 || this.apiFilterUpdated) {
          let containPreviousProductTypeId = false
          sortedProductTypes.forEach((productType: any) => {
            if (productType.id === this.filterState.productTypeFilter) {
              containPreviousProductTypeId = true
            }
          })
          this.filterState.productTypeFilter = containPreviousProductTypeId ? this.filterState.productTypeFilter : sortedProductTypes[0].id
          this.renderFilterUpdated = true
        }
      }
      places.sort((a: any, b: any) => compareClasses(a.className, b.className))
      return places
    },
    convertToTableData (rawData: any) {
      if (rawData.length > 0) {
        const res = {
          columnsCount: rawData[0].productTypes[0].productItems.length + 3,
          rowsCount: rawData.length + 2,
          items: []
        } as TableData
        const totalColumnStart = rawData[0].productTypes[0].productItems.length + 3
        res.items.push(
          {
            text: 'Классы',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 1,
            gridColumnEnd: 2,
            padding: '0 5px'
          },
          {
            text: 'Кол-во',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            padding: '0 5px'
          },
          {
            text: 'Дата',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 3,
            gridColumnEnd: totalColumnStart,
            padding: '0 5px'
          },
          {
            text: 'Всего получено льгот',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: 1,
            gridRowEnd: 3,
            gridColumnStart: totalColumnStart,
            gridColumnEnd: totalColumnStart + 1,
            padding: '0 5px'
          }
        )
        let curRow = 3
        const totalTableCount = {} as any
        rawData.forEach((classRow: any, rowIdx: number) => {
          let totalCount = 0
          res.items.push(
            {
              text: classRow.className,
              background: '',
              fontWeight: 'normal',
              gridRowStart: curRow,
              gridRowEnd: curRow + 1,
              gridColumnStart: 1,
              gridColumnEnd: 2,
              padding: '0 5px'
            },
            {
              text: classRow.studentCount,
              background: '',
              fontWeight: 'normal',
              gridRowStart: curRow,
              gridRowEnd: curRow + 1,
              gridColumnStart: 2,
              gridColumnEnd: 3,
              padding: '0 5px'
            }
          )
          classRow.productTypes[0].productItems.forEach((dayInfo: any, dayInfoIdx: number) => {
            if (rowIdx === 0) {
              res.items.push({
                text: dayInfo.day,
                background: '',
                fontWeight: 'normal',
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: dayInfoIdx + 3,
                gridColumnEnd: dayInfoIdx + 4,
                padding: '0 5px'
              })
            }
            totalCount += dayInfo.count
            res.items.push({
              text: dayInfo.count,
              background: '',
              fontWeight: 'normal',
              gridRowStart: curRow,
              gridRowEnd: curRow + 1,
              gridColumnStart: dayInfoIdx + 3,
              gridColumnEnd: dayInfoIdx + 4,
              padding: '0 5px'
            })
            if (totalTableCount[dayInfo.day]) {
              totalTableCount[dayInfo.day] += dayInfo.count
            } else {
              totalTableCount[dayInfo.day] = dayInfo.count
            }
          })
          res.items.push({
            text: `${totalCount}`,
            gridRowStart: curRow,
            gridRowEnd: curRow + 1,
            background: '',
            fontWeight: 'normal',
            gridColumnStart: totalColumnStart,
            gridColumnEnd: totalColumnStart + 1,
            padding: '0 5px'
          })
          if (totalTableCount.total) {
            totalTableCount.total += totalCount
          } else {
            totalTableCount.total = totalCount
          }
          curRow += 1
        })
        res.items.push(
          {
            text: 'Итого',
            background: '#faebd7',
            fontWeight: 'bold',
            gridRowStart: curRow,
            gridRowEnd: curRow + 1,
            gridColumnStart: 1,
            gridColumnEnd: 3,
            padding: '0 5px'
          }
        )
        const totalTableCountDays = Object.keys(totalTableCount).sort((a: any, b: any) => a - b)
        totalTableCountDays.forEach((dayCount: any, dayCountIdx: number) => {
          res.items.push(
            {
              text: totalTableCount[dayCount],
              background: '#faebd7',
              fontWeight: 'bold',
              gridRowStart: curRow,
              gridRowEnd: curRow + 1,
              gridColumnStart: dayCountIdx + 3,
              gridColumnEnd: dayCountIdx + 4,
              padding: '0 5px'
            }
          )
        })
        return res
      } else {
        console.log('Невозможно конвертировать пустые данные!')
        return {} as TableData
      }
    },
    updateReportTitle (newTitle: string) {
      this.title = newTitle
    },
    print () {
      this.title =
        `
          <div style="display: flex; margin-bottom: 4px;">
            <div style="width: 100%;
              /*border: 1px solid red;*/
              ">
              <div>
              <h3 style="margin: 0;">Табель питания</h3>
              <p style="font-size: 12px; margin: 0;"> за ${moment().locale('ru').month(this.filterState[FilterNames.MONTH_FILTER]).format('MMMM')} ${this.filterState[FilterNames.YEAR_FILTER]} г.</p>
              </div>
              <div style="width: 100%; display: flex; margin-bottom: 0;">
                <div style="width: fit-content">
                  <p style="font-size: 12px; margin: 0;">
                    Учреждение
                  </p>
                </div>
                <div style="width: 100%; border-bottom: 1px solid black; margin-left: 5px;">
                  <p style="font-size: 12px; margin: 0; width: max-content;/*! width: 100px; */word-wrap: break-word;/*text-decoration: underline;text-underline-offset: 5px;*/max-width: 750px;text-align: left;min-width: 600px;  line-height: 18px;}">
                    ${this.placeInfo.fullName ? this.placeInfo.fullName : ''}
                  </p>
                </div>
              </div>
              <div style="width: 100%; display: flex; margin-bottom: 0;">
                <div style="width: 175px">
                  <p style="font-size: 12px; margin: 0;">
                    Структурное подразделение
                  </p>
                </div>
                  <div style="width: 100%; border-bottom: 1px solid black; margin-left: 5px;">
                    <p style="font-size: 12px; margin: 0; width: max-content;/*! width: 100px; */word-wrap: break-word; max-width: 750px;text-align: left;min-width: 600px;  line-height: 18px;}">
                      Табель по продукту за месяц
                    </p>
                  </div>
              </div>
              <div style="width: 100%; display: flex; margin-bottom: 0;">
                <div style="width: 70px">
                  <p style="font-size: 12px; margin: 0;">
                  Вид расчета
                  </p>
                </div>
                <div style="width: 100%; border-bottom: 1px solid black; margin-left: 5px;">
                  <p style="font-size: 12px; margin: 0; width: max-content;/*! width: 100px; */word-wrap: break-word;max-width: 750px;text-align: left;min-width: 600px;  line-height: 18px;}">
                    ${this.filterState[FilterNames.PRODUCT_TYPE_FILTER] !== 0 ? this.filterOptions.productTypeItems.find((productTypeItem: any) => productTypeItem.id === this.filterState[FilterNames.PRODUCT_TYPE_FILTER]).name.toLowerCase() : ''}
                  </p>
                </div>
              </div>
              <div style="width: 100%; display: flex; margin-bottom: 20px;">
                <div style="width: 85px">
                  <p style="font-size: 12px; margin: 0;">
                    Режим работы
                  </p>
                </div>
                <div style="width: 100%; border-bottom: 1px solid black; margin-left: 5px;">
                  <p style="font-size: 12px; margin: 0; width: max-content;/*! width: 100px; */word-wrap: break-word;max-width: 750px;text-align: left;min-width: 600px;  line-height: 18px;}">
                  </p>
                </div>
              </div>
            </div>
            <div style="display: grid; grid-template-rows: repeat(7, max-content); grid-template-columns: repeat(2, max-content); column-gap: 10px;">
              <div style="grid-area: 1/1/2/2; font-size: 12px; height: 18px; text-align: right;">

              </div>
              <div style="grid-area: 1/2/2/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              КОДЫ
              </div>
              <div style="grid-area: 2/1/3/2; font-size: 12px; height: 18px; text-align: right;">
              Форма по ОКУД
              </div>
              <div style="grid-area: 2/2/3/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              0504608
              </div>
              <div style="grid-area: 3/1/4/2; font-size: 12px; height: 18px; text-align: right;">
              Дата
              </div>
              <div style="grid-area: 3/2/4/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              ${this.selectedHeaderDate}
              </div>
              <div style="grid-area: 5/1/6/2; font-size: 12px; height: 18px; text-align: right;">
              по ОКПО
              </div>
              <div style="grid-area: 5/2/6/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              ${this.placeInfo.okpo ? this.placeInfo.okpo : 52131272}
              </div>
              <div style="grid-area: 6/1/7/2; font-size: 12px; height: 18px; text-align: right;">
              </div>
              <div style="grid-area: 6/2/7/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              </div>
              <div style="grid-area: 7/1/8/2; font-size: 12px; height: 18px; text-align: right;">
              </div>
              <div style="grid-area: 7/2/8/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              </div>
              <div style="grid-area: 8/1/9/2; font-size: 12px; height: 18px; text-align: right;">
              </div>
              <div style="grid-area: 8/2/9/3; font-size: 12px; height: 18px; border: 1.5px solid black; text-align: center; padding: 0 5px;">
              </div>
            </div>
          </div>
       `
      printer('printable-area', 'print.css')
    },
    async loadCustomerPlaceInfo (placeId: any, date: any) {
      try {
        if (placeId !== 0) {
          const result = await find(
            {
              placeId: placeId,
              date: date
            }
          )
          return result
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    }
  }
})
</script>

<style scoped lang="scss">
.only-for-print {
  display: none;
}

.filters {
  display: flex;
  padding: 0 5px;

  & div {
    margin: 0 5px;
  }
}
</style>
