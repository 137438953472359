import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { GetByUserIdRequest, GetByUserIdResponse } from '@/shared/api/parents/digitalReceipts/api-types'

enum DigitalReceiptsRoutes {
  GetByUser = '/person/userReceipts/getByUser'
}
const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const getByUserId = async (data: GetByUserIdRequest, telegram: boolean): Promise<GetByUserIdResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<GetByUserIdResponse>(DigitalReceiptsRoutes.GetByUser, data, { headers })
    return result.data
  } else {
    const result = await axios.post<GetByUserIdResponse>(DigitalReceiptsRoutes.GetByUser, data)
    return result.data
  }
}
