import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { CustomerProductsFindPost, CustomerProductChangeSettingPost } from './api-types'

const customerProductsFind = 'products/findbycustomer'
const customerProductSettingsChange = 'products/settings/update'

export const findCustomerProducts = async (data: CustomerProductsFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(customerProductsFind, data)
  return result.data.data
}

export const changeCustomerProductSettings = async (data: CustomerProductChangeSettingPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(customerProductSettingsChange, data)
  return result.data.data
}
