/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  SET_INCREMENTGROUPTIMES, ADD_INCREMENTGROUPTIMES,
  ADD_INCREMENTGROUPTIME_TO_STACK,
  DELETE_INCREMENTGROUPTIME_FROM_STACK,
  EDIT_INCREMENTGROUPTIME_IN_STACK,
  CLEAR_INCREMENTGROUPTIMES
} from '@/store/incrementGroupTime/mutations'
import { RootState } from '@/store/state'
import {
  IncrementGroupTime, IncrementGroupTimeListState
} from '@/store/incrementGroupTime/types'
import { IncrementGroupTimeCreatePost } from '@/shared/api/incrementGroupTime/api-types'
import {
  all as incrementGroupTimeAll,
  find as incrementGroupTimeFind,
  create as incrementGroupTimeCreate,
  del as incrementGroupTimeDel
} from '@/shared/api/incrementGroupTime/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type IncrementGroupTimeListContext = ActionContext<IncrementGroupTimeListState, RootState>

const initialState: IncrementGroupTimeListState = {
  incrementGroupTimes: [{
    id: 0,
    incrementGroupId: 0,
    time: ''
  }]
}

const getters: GetterTree<IncrementGroupTimeListState, RootState> = {
  incrementGroupTimes (state: IncrementGroupTimeListState): IncrementGroupTime[] {
    return state.incrementGroupTimes
  }
}

const mutations: MutationTree<IncrementGroupTimeListState> = {
  [SET_INCREMENTGROUPTIMES] (state: IncrementGroupTimeListState, incrementGroupTimes: IncrementGroupTime[]) {
    state.incrementGroupTimes = incrementGroupTimes
  },
  [ADD_INCREMENTGROUPTIMES] (state: IncrementGroupTimeListState, incrementGroupTimes: IncrementGroupTime[]) {
    state.incrementGroupTimes = [...state.incrementGroupTimes, ...incrementGroupTimes]
  },
  [ADD_INCREMENTGROUPTIME_TO_STACK] (state: IncrementGroupTimeListState, incrementGroupTime: IncrementGroupTime) {
    state.incrementGroupTimes = [incrementGroupTime, ...state.incrementGroupTimes]
  },
  [DELETE_INCREMENTGROUPTIME_FROM_STACK] (state: IncrementGroupTimeListState, incrementGroupTime: IncrementGroupTime) {
    const index = state.incrementGroupTimes.map(incrementGroupTimes => incrementGroupTime.id).indexOf(incrementGroupTime.id)
    state.incrementGroupTimes = [
      ...state.incrementGroupTimes.slice(0, index),
      ...state.incrementGroupTimes.slice(index + 1)
    ]
  },
  [EDIT_INCREMENTGROUPTIME_IN_STACK] (state: IncrementGroupTimeListState, incrementGroupTime: IncrementGroupTime) {
    const index = state.incrementGroupTimes.map(incrementGroupTime => incrementGroupTime.id).indexOf(incrementGroupTime.id)
    if (index === -1) return
    state.incrementGroupTimes = [
      ...state.incrementGroupTimes.slice(0, index),
      incrementGroupTime,
      ...state.incrementGroupTimes.slice(index + 1)
    ]
  },
  [CLEAR_INCREMENTGROUPTIMES] (state: IncrementGroupTimeListState) {
    state.incrementGroupTimes = []
  }
}

const actions: ActionTree<IncrementGroupTimeListState, RootState> = {
  async all (
    { commit, dispatch, state }: IncrementGroupTimeListContext
  ): Promise<IncrementGroupTime[]> {
    try {
      const result = await incrementGroupTimeAll()
      commit(SET_INCREMENTGROUPTIMES, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async create (
    { commit, dispatch, state }: IncrementGroupTimeListContext,
    data: IncrementGroupTimeCreatePost
  ): Promise<IncrementGroupTime> {
    try {
      const result = await incrementGroupTimeCreate(data)
      commit(ADD_INCREMENTGROUPTIME_TO_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async find (
    { commit, dispatch, state }: IncrementGroupTimeListContext,
    data: number
  ): Promise<IncrementGroupTime> {
    try {
      const result = await incrementGroupTimeFind(data)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async del (
    { commit, dispatch, state }: IncrementGroupTimeListContext,
    data: IncrementGroupTime
  ): Promise<IncrementGroupTime> {
    try {
      const result = await incrementGroupTimeDel(data)
      commit(DELETE_INCREMENTGROUPTIME_FROM_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const incrementGroupTimes = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
