import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import {
  AbsenceReasonsFindRequest, AbsenceReasonsFindResponse,
  ReservationSalesCancelRequest,
  ReservationSalesCancelResponse, ReservationSalesGetByUserRequest, ReservationSalesGetByUserResponse
} from '@/shared/api/parents/attendanceReport/api-types'

enum AttendanceReportRoutes {
  ReservationSalesCancel = '/person/reservationSales/cancel',
  ReservationSalesGetByUser = '/person/reservationSales/getByUser',
  AbsenceReasonsFind = '/absenceReasons/find'
}

const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}
export const reservationSalesCancel = async (data: ReservationSalesCancelRequest, telegram: boolean): Promise<ReservationSalesCancelResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<ReservationSalesCancelResponse>(AttendanceReportRoutes.ReservationSalesCancel, data, { headers })
    return result.data
  } else {
    const result = await axios.post<ReservationSalesCancelResponse>(AttendanceReportRoutes.ReservationSalesCancel, data)
    return result.data
  }
}

export const reservationSalesGetByUserId = async (data: ReservationSalesGetByUserRequest, telegram: boolean): Promise<ReservationSalesGetByUserResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<ReservationSalesGetByUserResponse>(AttendanceReportRoutes.ReservationSalesGetByUser, data, { headers })
    return result.data
  } else {
    const result = await axios.post<ReservationSalesGetByUserResponse>(AttendanceReportRoutes.ReservationSalesGetByUser, data)
    return result.data
  }
}

export const absenceReasonsFind = async (data: AbsenceReasonsFindRequest, telegram: boolean): Promise<AbsenceReasonsFindResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AbsenceReasonsFindResponse>(AttendanceReportRoutes.AbsenceReasonsFind, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AbsenceReasonsFindResponse>(AttendanceReportRoutes.AbsenceReasonsFind, data)
    return result.data
  }
}
