<template>
  <div class="school-nutrition-settings">
    <h1 class="school-nutrition-settings__title">
      Настройки
    </h1>
    <div
      class="wrapper"
      :style="{display: 'flex'}"
    >
      <v-select
        v-model="selectedAllowedPlace"
        :items="placeItems"
        item-title="name"
        item-value="id"
        label="Школа"
        :loading="isPlaceLoading"
        :disabled="isDisabled"
        @update:model-value="placeChangeHandler"
      />
    </div>
    <template v-if="selectedAllowedPlace !== 0">
      <template v-if="!isLoading && settingsData.length > 0">
        <div class="school-nutrition-settings__table text-center">
          <div class="school-nutrition-settings__table-header">
            Настройки питания по школе
          </div>
          <div class="school-nutrition-settings__header-cell">
            <p :style="{margin: 0}">
              Название
            </p>
          </div>
          <div class="school-nutrition-settings__header-cell">
            <p :style="{margin: 0}">
              Тип питания
            </p>
          </div>
          <div class="school-nutrition-settings__header-cell">
            <p :style="{margin: 0}">
              Дата
            </p>
          </div>
          <div class="school-nutrition-settings__header-cell">
            <p :style="{margin: 0}">
              Настройка
            </p>
          </div>
          <template
            v-for="(row, rowIdx) in settingsData"
            :key="`name-${rowIdx}`"
          >
            <div
              class="school-nutrition-settings__cell"
            >
              <p :style="{margin: 0}">
                {{ row.name }}
              </p>
            </div>
            <div
              class="school-nutrition-settings__cell"
            >
              <p :style="{margin: 0}">
                {{ row.nutritionType }}
              </p>
            </div>
            <div
              class="school-nutrition-settings__cell"
            >
              <p :style="{margin: 0}">
                c {{ row.dateFrom }}
              </p>
            </div>
            <div
              class="school-nutrition-settings__cell"
            >
              <v-btn @click="openHistoryModal(row)">
                Настроить
              </v-btn>
            </div>
          </template>
        </div>
        <v-dialog
          v-model="isHistoryOpen"
          width="700"
        >
          <v-card>
            <v-card-title>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center'}"
              >
                <span class="text-h5 align-center">Настройка группы "{{ modalData.title }}"</span>
              </div>
            </v-card-title>
            <template v-if="!isHistoryLoading">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      v-model:return-value="modalData.selectedDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template #activator="{ props }">
                        <v-text-field
                          :model-value="formatDate(modalData.selectedDate)"
                          label="Дата с"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="props"
                        />
                      </template>
                      <v-date-picker
                        v-model="modalData.selectedDate"
                        no-title
                        scrollable
                        hide-weekdays
                      >
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="menu = false"
                        >
                          Отмена
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="($refs.menu as any).save(modalData.selectedDate)"
                        >
                          ОК
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <v-select
                      v-model="modalData.selectedNutritionType"
                      label="Тип питания"
                      :items="modalData.mockNutritionTypes"
                      item-title="name"
                      item-value="id"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    align-self="center"
                  >
                    <v-btn
                      :style="{textAlign: 'end'}"
                      color="primary"
                      @click="save()"
                    >
                      Сохранить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <template v-if="modalData.historyData.length > 0">
                <v-card-text class="text-center">
                  <div
                    class="wrapper"
                    :style="{display: 'flex', justifyContent: 'center'}"
                  >
                    <div
                      class="text--primary"
                      :style="{display: 'grid', gridTemplateColumns: 'repeat(3, max-content)', gridGap: '1px'}"
                    >
                      <div
                        :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
                      >
                        <p :style="{margin: 0}">
                          Дата с
                        </p>
                      </div>
                      <div
                        :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
                      >
                        <p :style="{margin: 0}">
                          Тип питания
                        </p>
                      </div>
                      <div
                        :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
                      >
                        <p :style="{margin: 0}">
                          Дата создания
                        </p>
                      </div>
                      <template
                        v-for="(historyItem, historyItemIdx) in modalData.historyData"
                        :key="`name-${historyItemIdx}`"
                      >
                        <div
                          :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: historyItem.active ? 'bold' : 'normal'}"
                        >
                          <p :style="{margin: 0}">
                            c {{ historyItem.dateFrom }}
                          </p>
                        </div>
                        <div
                          :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: historyItem.active ? 'bold' : 'normal'}"
                        >
                          <p :style="{margin: 0}">
                            {{ historyItem.nutritionType }}
                          </p>
                        </div>
                        <div
                          :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: historyItem.active ? 'bold' : 'normal'}"
                        >
                          <p :style="{margin: 0}">
                            {{ historyItem.dateCreated }}
                          </p>
                        </div>
                      </template>
                      <div
                        class="student-nutrition-settings__cell"
                        :style="{ boxShadow: '0 0 0 1px #808080', gridColumnStart: '1', gridColumnEnd: '4', padding: '5px', fontWeight: 'bold', background: '#faebd7'}"
                      >
                        <p :style="{margin: 0}">
                          Активный выделен жирным шрифтом
                        </p>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </template>
            </template>
            <template v-else>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}"
              >
                <v-col class="text-center">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="green"
                    indeterminate
                  />
                </v-col>
              </div>
            </template>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="isConfirmedOpen"
          width="max-content"
        >
          <v-card>
            <template v-if="!isConfirmLoading">
              <v-card-title>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
                >
                  <span class="text-h6 align-center"><strong>ВНИМАНИЕ!!!</strong><br>Следующие талоны будут отмечены как ошибочные!</span>
                </div>
              </v-card-title>
              <v-card-text>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
                >
                  <div
                    class="text--primary"
                    :style="{display: 'grid', gridTemplateColumns: 'repeat(2, max-content)', gridGap: '1px'}"
                  >
                    <div
                      class="student-nutrition-settings__cell"
                      :style="{ boxShadow: '0 0 0 1px #808080', gridColumnStart: '1', gridColumnEnd: '3', padding: '5px', fontWeight: 'bold', background: '#faebd7'}"
                    >
                      <p :style="{margin: 0}">
                        Список талонов
                      </p>
                    </div>
                    <div
                      :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
                    >
                      <p :style="{margin: 0}">
                        Номер талона
                      </p>
                    </div>
                    <div
                      :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'bold', background: '#faebd7'}"
                    >
                      <p :style="{margin: 0}">
                        Дата
                      </p>
                    </div>
                    <template
                      v-for="(talon, talonIdx) in modalConfirmData.talons"
                      :key="`talon-number-${talonIdx}`"
                    >
                      <div
                        :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'normal'}"
                      >
                        <p :style="{margin: 0}">
                          {{ talon.number }}
                        </p>
                      </div>
                      <div
                        :style="{boxShadow: '0 0 0 1px black', padding: '8px', fontSize: '16px', fontWeight: 'normal'}"
                      >
                        <p :style="{margin: 0}">
                          c {{ talon.date }}
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
                >
                  <v-btn
                    color="blue"
                    @click="confirmNewRecord()"
                  >
                    Продолжить изменения
                  </v-btn>
                  <v-btn
                    color="lighten-2"
                    @click="closeConfirmModal"
                  >
                    Отказаться
                  </v-btn>
                </div>
              </v-card-actions>
            </template>
            <template v-else>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '700px'}"
              >
                <v-col class="text-center">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="green"
                    indeterminate
                  />
                </v-col>
              </div>
            </template>
          </v-card>
        </v-dialog>
      </template>
      <template v-else>
        <v-row>
          <v-col
            v-if="isLoading"
            cols="10"
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import { createPlaceFoodSettings, findPlaceFoodSettings } from '@/shared/api/nutritionSettings/api-requests'

export interface MockNutritionTypeItem {
  id: number;
  name: string;
}

export interface MockHistoricalDataItem {
  id: number;
  name: string;
  data: {
    placeFoodSettingType: string;
    dateFrom: string;
    dateCreated: string;
    active: boolean;
  }[];
}

export default defineComponent({
  name: 'SchoolNutritionSettings',
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isPlaceLoading: false,
      isDisabled: true,
      isHistoryLoading: false,
      isConfirmedOpen: false,
      isConfirmLoading: false,
      selectedAllowedPlace: 0,
      selectedPlaceFoodSettingTypeId: 0,
      placeItems: [{ id: 0, name: 'Ошибка загрузки' }],
      searchTerm: '',
      menu: false,
      isHistoryOpen: false,
      isLoading: false,
      modalData: {
        title: 'Настройки группы',
        mockNutritionTypes: [
          {
            id: 1,
            name: 'Комлексный обед'
          },
          {
            id: 2,
            name: 'Двухразовое'
          }
        ] as MockNutritionTypeItem[],
        selectedNutritionTypeError: false,
        selectedNutritionType: 1,
        selectedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        historyData: [] as any,
        mockHistory: {} as any
      },
      modalConfirmData: {
        title: '',
        talons: [] as any
      },
      settingsData: [] as any,
      placeFoodSettingsTypes: [
        {
          placeFoodSettingTypeId: 1,
          name: 'Питание 1-4 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено'
        },
        {
          placeFoodSettingTypeId: 2,
          name: 'Питание 5-11 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено'
        },
        {
          placeFoodSettingTypeId: 3,
          name: 'Питание кадеты 5-8 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено'
        },
        {
          placeFoodSettingTypeId: 4,
          name: 'Питание кадеты 9-11 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено'
        }
      ],
      mockHistoricalData: [
        {
          id: 1,
          name: 'Питание 1-4 классы',
          data: [
            {
              placeFoodSettingType: 'Комплексный обед',
              dateFrom: '01.01.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Двухразовое',
              dateFrom: '01.06.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Комплексный обед',
              dateFrom: '01.10.2022',
              dateCreated: '01.01.2022',
              active: true
            }
          ]
        },
        {
          id: 2,
          name: 'Питание 5-11 классы',
          data: [
            {
              placeFoodSettingType: 'Двухразовое',
              dateFrom: '01.01.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Комплексный обед',
              dateFrom: '01.06.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Двухразовое',
              dateFrom: '01.10.2022',
              dateCreated: '01.01.2022',
              active: true
            }
          ]
        },
        {
          id: 3,
          name: 'Питание кадеты 5-8 классы',
          data: [
            {
              placeFoodSettingType: 'Комплексный обед',
              dateFrom: '01.01.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Двухразовое',
              dateFrom: '01.06.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Комплексный обед',
              dateFrom: '01.10.2022',
              dateCreated: '01.01.2022',
              active: true
            }
          ]
        },
        {
          id: 4,
          name: 'Питание кадеты 9-11 классы',
          data: [
            {
              placeFoodSettingType: 'Двухразовое',
              dateFrom: '01.01.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Комплексный обед',
              dateFrom: '01.06.2022',
              dateCreated: '01.01.2022',
              active: false
            },
            {
              placeFoodSettingType: 'Двухразовое',
              dateFrom: '01.10.2022',
              dateCreated: '01.01.2022',
              active: true
            }
          ]
        }
      ] as MockHistoricalDataItem[]
    }
  },
  async beforeMount () {
    this.isLoading = true
    await this.loadUserAllowedPlaces()
    const res = await findPlaceFoodSettings({
      placeId: this.selectedAllowedPlace,
      current: true
    })
    this.settingsData = []
    if (res.length > 0) {
      this.placeFoodSettingsTypes.forEach((placeFoodSettingsType: any) => {
        const placeFoodSettingFromApi = res.find((placeFoodSetting: any) => {
          return placeFoodSettingsType.placeFoodSettingTypeId === placeFoodSetting.placeFoodSettingTypeId && placeFoodSetting.active === 1
        })
        if (placeFoodSettingFromApi) {
          this.settingsData.push({
            placeFoodSettingTypeId: placeFoodSettingFromApi.placeFoodSettingTypeId,
            name: placeFoodSettingsType.name,
            nutritionType: placeFoodSettingFromApi.value === 1 ? 'Комлексный обед' : 'Двухразовое',
            nutritionTypeId: placeFoodSettingFromApi.value,
            dateFrom: moment(placeFoodSettingFromApi.timestamp).format('DD.MM.YYYY')
          })
        } else {
          this.settingsData.push({
            name: placeFoodSettingsType.name,
            nutritionType: 'Не настроено',
            dateFrom: 'Не настроено',
            // placeFoodSettingTypeId: 0,
            placeFoodSettingTypeId: placeFoodSettingsType.placeFoodSettingTypeId,
            nutritionTypeId: 0
          })
        }
      })
    } else {
      this.settingsData = [
        {
          name: 'Питание 1-4 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено',
          placeFoodSettingTypeId: 1,
          nutritionTypeId: 0
        },
        {
          name: 'Питание 5-11 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено',
          placeFoodSettingTypeId: 2,
          nutritionTypeId: 0
        },
        {
          name: 'Питание кадеты 5-8 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено',
          placeFoodSettingTypeId: 3,
          nutritionTypeId: 0
        },
        {
          name: 'Питание кадеты 9-11 классы',
          nutritionType: 'Не настроено',
          dateFrom: 'Не настроено',
          placeFoodSettingTypeId: 4,
          nutritionTypeId: 0
        }
      ]
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async loadUserAllowedPlaces () {
      try {
        this.isPlaceLoading = true
        const userAllowedPlaces = await this.userAllowedPlaceAll()
        this.placeItems = []
        userAllowedPlaces.forEach((obj: any) => {
          if (obj.removed == null) {
            if (this.placeItems.length > 0) {
              this.placeItems.push({ id: obj.place.id, name: obj.place.name })
            } else {
              this.placeItems = [{ id: obj.place.id, name: obj.place.name }]
            }
          }
        })
        if (this.selectedAllowedPlace === 0) this.selectedAllowedPlace = this.placeItems[0].id
        this.isDisabled = false
      } catch (error: any) {
        this.placeItems = [{ id: 0, name: 'Ошибка загрузки' }]
      } finally {
        this.isPlaceLoading = false
      }
    },
    async placeChangeHandler () {
      await this.refreshCurrentSettingsData()
    },
    async openHistoryModal (row: any) {
      this.selectedPlaceFoodSettingTypeId = row.placeFoodSettingTypeId
      this.modalData.selectedNutritionType = row.nutritionTypeId === 1 ? 2 : 1
      this.isHistoryOpen = true
      this.isHistoryLoading = true
      await this.loadHistory(this.selectedAllowedPlace, row.placeFoodSettingTypeId)

      this.isHistoryLoading = false
    },
    async loadHistory (placeId: number, placeFoodSettingTypeId: number) {
      this.modalData.historyData = []
      const res = await findPlaceFoodSettings({
        placeId: placeId,
        placeFoodSettingTypeId: placeFoodSettingTypeId
      })
      if (res.length > 0) {
        res.forEach((placeFoodSettingsHistoryItem: any) => {
          const placeFoodSettingsType = (this.placeFoodSettingsTypes as any).find((placeFoodSettingsType: any) => placeFoodSettingsHistoryItem.placeFoodSettingTypeId === placeFoodSettingsType.placeFoodSettingTypeId)
          this.modalData.historyData.push({
            placeFoodSettingTypeId: placeFoodSettingsHistoryItem.placeFoodSettingTypeId,
            name: placeFoodSettingsType.name,
            active: placeFoodSettingsHistoryItem.active === 1,
            nutritionType: placeFoodSettingsHistoryItem.value === 1 ? 'Комлексный обед' : 'Двухразовое',
            nutritionTypeId: placeFoodSettingsHistoryItem.value,
            dateFrom: moment(placeFoodSettingsHistoryItem.timestamp).format('DD.MM.YYYY'),
            dateCreated: moment(placeFoodSettingsHistoryItem.creationDate).format('DD.MM.YYYY')
          })
        })
        this.modalData.historyData.reverse().sort((prev: any, curr: any) => {
          return prev.dateFrom < curr.dateFrom ? 1 : -1
        })
      }
    },
    openConfirmModal (talons: any[]) {
      this.modalConfirmData.talons = talons
      this.isConfirmedOpen = true
    },
    async closeConfirmModal () {
      this.isConfirmedOpen = false
    },
    showSnackbarMessage (message: string) {
      this.setSnackbar({
        showing: true,
        text: message,
        timeout: 3000
      })
    },
    async confirmNewRecord () {
      this.isConfirmLoading = true
      try {
        const res = await createPlaceFoodSettings(
          {
            placeId: this.selectedAllowedPlace,
            placeFoodSettingTypeId: this.selectedPlaceFoodSettingTypeId,
            timestamp: this.modalData.selectedDate,
            value: this.modalData.selectedNutritionType,
            confirm: true
          }
        )
        if (res.errorCode === 0) {
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.isConfirmLoading = false
          this.isConfirmedOpen = false
          this.showSnackbarMessage('Запись успешно добавлена!')
          await this.refreshCurrentSettingsData()
        } else if (res.errorCode === 409) {
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.isConfirmLoading = false
          this.isConfirmedOpen = false
          this.showSnackbarMessage('Произошла ошибка! Не удалось сохранить изменения!')
          await this.refreshCurrentSettingsData()
        } else {
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.isConfirmLoading = false
          this.isConfirmedOpen = false
          this.showSnackbarMessage('Произошла ошибка! Не удалось сохранить изменения!')
          await this.refreshCurrentSettingsData()
        }
      } catch (e: any) {
        this.isHistoryLoading = false
        this.isHistoryOpen = false
        this.isConfirmLoading = false
        this.isConfirmedOpen = false
        this.showSnackbarMessage(`Произошла ошибка! Код ответа ${e.response.status} - ${e.response.statusText}`)
      }
    },
    async refreshCurrentSettingsData () {
      this.isLoading = true
      const res = await findPlaceFoodSettings({
        placeId: this.selectedAllowedPlace,
        current: true
      })
      this.settingsData = []
      if (res.length > 0) {
        this.placeFoodSettingsTypes.forEach((placeFoodSettingsType: any) => {
          const placeFoodSettingFromApi = res.find((placeFoodSetting: any) => {
            return placeFoodSettingsType.placeFoodSettingTypeId === placeFoodSetting.placeFoodSettingTypeId && placeFoodSetting.active === 1
          })
          if (placeFoodSettingFromApi) {
            this.settingsData.push({
              placeFoodSettingTypeId: placeFoodSettingFromApi.placeFoodSettingTypeId,
              name: placeFoodSettingsType.name,
              nutritionType: placeFoodSettingFromApi.value === 1 ? 'Комлексный обед' : 'Двухразовое',
              nutritionTypeId: placeFoodSettingFromApi.value,
              dateFrom: moment(placeFoodSettingFromApi.timestamp).format('DD.MM.YYYY')
            })
          } else {
            this.settingsData.push({
              name: placeFoodSettingsType.name,
              nutritionType: 'Не настроено',
              dateFrom: 'Не настроено',
              placeFoodSettingTypeId: placeFoodSettingsType.placeFoodSettingTypeId,
              nutritionTypeId: 0
            })
          }
        })
      } else {
        this.settingsData = [
          {
            name: 'Питание 1-4 классы',
            nutritionType: 'Не настроено',
            dateFrom: 'Не настроено',
            placeFoodSettingTypeId: 1,
            nutritionTypeId: 0
          },
          {
            name: 'Питание 5-11 классы',
            nutritionType: 'Не настроено',
            dateFrom: 'Не настроено',
            placeFoodSettingTypeId: 2,
            nutritionTypeId: 0
          },
          {
            name: 'Питание кадеты 5-8 классы',
            nutritionType: 'Не настроено',
            dateFrom: 'Не настроено',
            placeFoodSettingTypeId: 3,
            nutritionTypeId: 0
          },
          {
            name: 'Питание кадеты 9-11 классы',
            nutritionType: 'Не настроено',
            dateFrom: 'Не настроено',
            placeFoodSettingTypeId: 4,
            nutritionTypeId: 0
          }
        ]
      }

      this.isLoading = false
    },
    async save () {
      this.isHistoryLoading = true
      try {
        const res = await createPlaceFoodSettings(
          {
            placeId: this.selectedAllowedPlace,
            placeFoodSettingTypeId: this.selectedPlaceFoodSettingTypeId,
            timestamp: moment(this.modalData.selectedDate).format('YYYY-MM-DD'),
            value: this.modalData.selectedNutritionType
          }
        )
        if (res.errorCode === 0) {
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.showSnackbarMessage('Запись успешно добавлена!')
          await this.refreshCurrentSettingsData()
        } else if (res.errorCode === 409) {
          this.isHistoryLoading = false
          this.openConfirmModal(res.data.talons)
        } else {
          this.isHistoryLoading = false
          this.isHistoryOpen = false
          this.isConfirmLoading = false
          this.isConfirmedOpen = false
          this.showSnackbarMessage('Произошла ошибка! Не удалось сохранить изменения!')
          await this.refreshCurrentSettingsData()
        }
      } catch (e: any) {
        this.isHistoryLoading = false
        this.isHistoryOpen = false
        this.isConfirmLoading = false
        this.isConfirmedOpen = false
        this.showSnackbarMessage(`Произошла ошибка! Код ответа ${e.response.status} - ${e.response.statusText}`)
      }
    }
  }
})
</script>

<style scoped lang="scss">
.school-nutrition-settings {
  padding: 20px;
  margin-top: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;

  .school-nutrition-settings__title {
    width: 100%;
    text-align: center;
  }

  .school-nutrition-settings__table {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(4, max-content);
    //grid-template-rows: repeat(5, max-content);
    grid-gap: 1px;

    .school-nutrition-settings__table-header {
      background: #1b5e20;
      color: white;
      font-size: 16px;
      grid-column-start: 1 !important;
      grid-column-end: 5 !important;
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
    }

    .school-nutrition-settings__header-cell {
      box-shadow: 0 0 0 1px #808080;
      padding: 5px;
      background: #faebd7;
      font-weight: bold;
      font-size: 16px;
    }

    .school-nutrition-settings__cell {
      box-shadow: 0 0 0 1px black;
      padding: 5px;
      font-weight: normal;
    }
  }
}
</style>
