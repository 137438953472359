<template>
  <v-snackbar
    v-model="snackbar.showing"
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiline === true"
    location="top"
  >
    {{ snackbar.text }}
    <v-btn
      variant="text"
      theme="dark"
      @click="closeSnackbar"
    >
      Закрыть
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/snackbar'
    })
  },
  methods: {
    ...mapActions('snackbar', { closeSnackbar: 'closeSnackbar' })
  }
}
</script>
