<template>
  <v-select
    v-model="selectedMonth"
    :items="months"
    item-title="name"
    item-value="id"
    label="Месяц"
    return-object
    @update:model-value="update"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  model: {
    event: 'on-change'
  },
  props: ['currentProp'],
  data () {
    return {
      selectedMonth: { id: 1, name: 'Январь' },
      months: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' }
      ]
    }
  },
  mounted () {
    if (this.currentProp) this.selectedMonth.id = new Date().getMonth() + 1
    else this.selectedMonth.id = new Date().getMonth()
    if (this.selectedMonth.id === 0) this.selectedMonth.id = 12
    this.update(this.selectedMonth)
  },
  methods: {
    update (value: any) {
      this.$emit('on-change', { id: value, name: this.months[value - 1]?.name })
    }
  }
})
</script>

<style lang="scss"></style>
