<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row
      justify="center"
      align="center"
    >
      <v-col>
        <h1
          v-if="!loading && checkBankBindingsAndSbp"
          class="d-flex text-center"
        >
          <strong style="left: 6%;  position: relative;"> {{ title }}</strong>
          <a href="https://school.glolime.ru/new_site/telegram/instructions/autopay/"><img
            class="d-flex align-left justify-left"
            :src="require('@/assets/images/question.png')"
            style="max-height: 40px; margin-top: 5px; margin-right: 10px;"
          ></a>
        </h1>
        <h1
          v-else
          class="d-flex text-center"
        >
          <strong> {{ title }}</strong>
        </h1>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
    >
      <v-col v-if="loading">
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
      </v-col>

      <v-col v-if="!loading && !checkBankBindingsAndSbp">
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          Автоплатежи возможны, если у вас сохранена подписка СБП или карта. <br>
          При следующей оплате питания выполните сохранение способа оплаты.
        </p>
        <p
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <a href="https://school.glolime.ru/new_site/telegram/instructions/autopay/"><v-btn
            class="ma-2 bg-primary"
            variant="outlined"
            size="x-large"
            width="300"
          >Узнать подробности</v-btn></a>
        </p>
      </v-col>

      <v-col v-if="!loading && checkBankBindingsAndSbp">
        <v-row
          justify="center"
          align="center"
          style="margin-top: -20px;"
        >
          <v-col
            cols="6"
            class="align-left justify-left"
            style="padding-left: 25px;"
          >
            Активная подписка
          </v-col>
          <v-col
            cols="6"
            class="align-right justify-right"
            style="padding-right: 25px;"
          >
            <v-btn
              class="ma-2 bg-primary"
              variant="outlined"
              size="x-large"
              width="100%"
              @click="telegramAutoPaymentServiceSettingSave()"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="settings.paymentType == 2"
          justify="center"
          align="center"
          style="margin-top: 0;"
        >
          <v-col
            cols="11"
            sm="4"
            class="d-flex align-left justify-left"
            style="background-color: lightgray; border-radius: 10px;"
          >
            <v-row>
              <v-col cols="10">
                <template v-if="activeCard !== null">
                  <img
                    v-if="activeCard.memberPictExist == 1"
                    :src="require('@/assets/images/banklogo/'+activeCard.memberId+'.svg')"
                    style="max-height: 40px; margin-top: 5px; margin-right: 10px;"
                  >
                  <img
                    v-else
                    :src="require('@/assets/images/banklogo/empty.svg')"
                    style="max-height: 40px; margin-top: 5px; margin-right: 10px;"
                  >
                  <span style="text-align: left; top: -12px; position: relative; left: 10px;">{{ activeCard.memberRusName }}</span>
                </template>
                <template v-else>
                  <span style="text-align: left; top: 12px; position: relative; left: 10px;">Не назначено</span>
                </template>
              </v-col>
              <v-col
                v-if="cardCount > 1"
                cols="2"
              >
                <a href="#subs"><v-icon
                  size="medium"
                  style="font-size: 48px; left: -10px;"
                  color="grey-darken-2"
                >mdi-arrow-down-bold</v-icon></a>
              </v-col>
              <v-col
                v-else
                cols="2"
              >
                <v-icon
                  size="medium"
                  style="font-size: 40px; left: -10px;"
                  color="grey-darken-2"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row
          v-if="settings.paymentType == 1"
          justify="center"
          align="center"
          style="margin-top: 0;"
        >
          <v-col
            cols="11"
            sm="4"
            class="d-flex align-left justify-left"
            style="background-color: lightgray; border-radius: 10px;"
          >
            <v-row>
              <v-col cols="10">
                <template v-if="activeCard !== null">
                  <span style="text-align: left; top: 10px; position: relative; left: 10px;">{{ activeCard.maskedPan }}</span>
                </template>
              </v-col>
              <v-col
                v-if="cardCount > 1"
                cols="2"
              >
                <a href="#subs"><v-icon
                  size="medium"
                  style="font-size: 48px; left: -10px;"
                  color="grey-darken-2"
                >mdi-arrow-down-bold</v-icon></a>
              </v-col>
              <v-col
                v-else
                cols="2"
              >
                <v-icon
                  size="medium"
                  style="font-size: 40px; left: -10px;"
                  color="grey-darken-2"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="11"
            sm="4"
          >
            <v-card>
              <v-tabs
                v-model="tabAccounts"
                bg-color="primary"
              >
                <template v-for="(account, index) of settings.accounts">
                  <v-tab
                    v-if="true"
                    :key="index"
                    :value="account.type "
                  >
                    {{ account.title }}
                  </v-tab>
                </template>
              </v-tabs>
              <v-window v-model="tabAccounts">
                <template v-for="(account, index) of settings.accounts">
                  <v-window-item
                    v-if="true"
                    :key="index"
                    :value="account.type"
                    style="height: fit-content;"
                  >
                    <v-row
                      justify="center"
                      align="center"
                    >
                      <v-col
                        style="justify-content: space-between; display: flex; flex-direction: row; margin: 5px 10px; align-items: center">
                        <h2
                          v-if="!loading && account.on"
                          class="text-center"
                          style="color: rgb(25, 118, 210)  !important;"
                        >
                          <strong>Автоплатёж включен</strong>
                        </h2>
                        <h2
                          v-if="!loading && !account.on"
                          class="text-center"
                          style="color: rgb(130, 135, 140) !important;"
                        >
                          <strong>Автоплатёж выключен</strong>
                        </h2>
                        <v-switch
                          v-model="account.on"
                          color="primary"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="!loading && account.on"
                      justify="center"
                      align="center"
                      style="margin-top: 0;"
                    >
                      <v-col>
                        <p
                          class="text-center"
                          style="margin: 0; left: 30px;  position: relative;"
                        >
                          <strong>Периодичность</strong>
                        </p>
                        <p
                          v-if="!loading"
                          class="text-center"
                          style="margin: 0; left: 30px;  position: relative; display: block ruby;"
                        >
                          <v-radio-group
                            v-model="account.periodicity"
                            style="margin: 0;"
                            class="text-center"
                            :disabled="disabled"
                            @update:model-value="changePeriodicity(account)"
                          >
                            <v-radio
                              class="text-center"
                              label="День"
                              :value="1"
                            />
                            <v-radio
                              class="text-center"
                              label="Неделя"
                              :value="2"
                            />
                            <v-radio
                              class="text-center"
                              label="Месяц"
                              :value="3"
                            />
                          </v-radio-group>
                        </p>
                      </v-col>
                      <v-col
                        v-if="account.periodicity == 1"
                        cols="3"
                        sm="2"
                        style="padding-left: 0px;"
                      />
                      <v-col
                        v-if="account.periodicity == 2"
                        cols="3"
                        sm="2"
                        style="padding-left: 0px;"
                      >
                        <v-select
                          v-model="account.day"
                          :items="weekDays"
                          item-title="name"
                          item-value="id"
                          style="position: relative;  left: -30px; top: 5px;"
                        />
                      </v-col>
                      <v-col
                        v-if="account.periodicity == 3"
                        cols="3"
                        sm="2"
                      >
                        <v-text-field
                          v-model="account.day"
                          style="margin: 0; top: 40px; position: relative;  left: -40px;"
                          label="День"
                          @update:model-value="checkDayOfMonth(account)"
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        sm="1"
                      />
                    </v-row>

                    <v-row
                      v-if="!loading && account.on"
                      justify="center"
                      align="center"
                      style="margin-top: 0;"
                    >
                      <v-col>
                        <p
                          class="text-center"
                          style="margin: 0; left: 30px;  position: relative;"
                        >
                          <strong>Варианты автоплатежа</strong>
                        </p>
                        <p
                          v-if="!loading"
                          class="text-center"
                          style="margin: 0; left: 20px;  position: relative;"
                        >
                          <v-radio-group
                            v-model="account.autoPayType"
                            style="margin: 0;"
                            class="text-center"
                            :disabled="disabled"
                          >
                            <v-radio
                              class="text-center"
                              label="Сумма пополнения"
                              :value="1"
                            />
                            <span style="display: flex; position: relative; top: -4px; color: rgba(0, 0, 0, 0.6);">Пополнять если баланс не выше</span>
                            <v-radio
                              class="text-center"
                              style=" position: relative; top: 5px;"
                              label="Дополнять баланс до"
                              :value="2"
                            />
                          </v-radio-group>
                        </p>
                      </v-col>
                      <v-col
                        v-if="account.autoPayType == 1"
                        cols="4"
                        sm="2"
                      >
                        <v-text-field
                          v-model="account.autoPaySum"
                          style="margin: 0;"
                          label=""
                        />
                        <v-text-field
                          v-model="account.limit"
                          style="margin: 0; padding: 0; position: relative; top: -20px;"
                          label=""
                        />
                      </v-col>
                      <v-col
                        v-if="account.autoPayType == 2"
                        cols="4"
                        sm="2"
                      >
                        <v-text-field
                          v-model="account.limit"
                          style="margin: 0; top: 40px; position: relative;"
                          label=""
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        sm="1"
                      />
                    </v-row>

                    <v-row
                      v-if="!loading && account.on"
                      justify="center"
                      align="center"
                      style="margin-top: 0;"
                    >
                      <v-col>
                        <p
                          v-if="!loading"
                          class="text-center"
                          style="margin: 0; left: 20px;  position: relative;"
                        >
                          <v-checkbox
                            v-model="account.offer"
                            label="Предлагать автоплатёж если баланс ниже"
                          />
                        </p>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="2"
                      >
                        <v-text-field
                          v-model="account.offerSum"
                          style="margin: 0; position: relative;"
                          label=""
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        sm="1"
                      />
                    </v-row>
                  </v-window-item>
                </template>
              </v-window>
            </v-card>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="11"
            sm="4"
          >
            <v-card>
              <v-tabs
                v-model="tabDates"
                bg-color="primary"
              >
                <v-tab value="0">
                  Выходные дни
                </v-tab>
                <v-tab value="1">
                  Каникулы
                </v-tab>
              </v-tabs>
              <v-window v-model="tabDates">
                <v-window-item
                  :key="1"
                  style="height: 330px;"
                >
                  <v-row justify="space-around">
                    <v-date-picker
                      v-model="settings.weekends"
                      style="top:20px"
                      multiple
                      hide-weekdays
                      hide-header
                      :min="settings.weekendsMin"
                      :max="settings.weekendsMax"
                    />
                  </v-row>
                </v-window-item>
                <v-window-item
                  :key="2"
                  style="height: 380px;"
                >
                  <v-row
                    v-for="(item, index) in settings.holidays"
                    :key="index"
                    justify="space-around"
                    style="margin: 0px;"
                  >
                    <v-col
                      cols="4"
                      style="padding-top: 10px;"
                    >
                      {{ item.title }}
                    </v-col>
                    <v-col style="padding: 0px;">
                      <v-menu
                        :model-value="menuState(item)"
                        :close-on-content-click="false"
                        :offset="60"
                        transition="scale-transition"
                        min-width="auto"
                      >
                        <template #activator="{ props }">
                          <v-text-field
                            style="padding: 0px; width: 95%;"
                            :model-value="dateRangeText(item.dateRange)"
                            prepend-icon="mdi-calendar"
                            readonly

                            v-bind="props"
                            @click="menuChangeState(item)"
                          />
                        </template>
                        <v-date-picker
                          v-model="item.dateRange"
                          hide-weekdays
                          hide-header
                          multiple="range"
                          @input="menuChangeState(item)"
                          @update:model-value="closeMenu(item)"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <a name="subs" />
        <v-row
          v-if="cardCount > 1"
          justify="center"
          align="center"
          style="margin-bottom: -20px;"
        >
          <v-col
            cols="10"
            sm="10"
            class="d-flex align-center justify-center"
          >
            <template v-if="settings.sbpSubscriptions.length > 0">
              Назначьте активную подписку
              <span v-if="settings.bankBindings.length > 0">или карту</span>
            </template>
            <template v-else>
              Назначьте активную карту
            </template>
          </v-col>
        </v-row>

        <v-radio-group
          v-if="cardCount > 1"
          v-model="activeCardType"
          style="margin: 0;"
          class="text-center"
          :disabled="disabled"
          @update:model-value="changeActiveCard($event)"
        >
          <v-row
            v-for="sbpSubscription of settings.sbpSubscriptions"
            :key="sbpSubscription.id"
            justify="center"
            align="center"
            style="margin-top: 20px;"
          >
            <v-col
              cols="11"
              sm="4"
              class="d-flex align-left justify-left"
              style="background-color: lightgray; border-radius: 10px;"
            >
              <v-row>
                <v-col
                  cols="1"
                  class="d-flex align-left justify-left"
                >
                  <v-radio
                    class="text-center"
                    :value="String(sbpSubscription.id)"
                  />
                </v-col>
                <v-col
                  cols="9"
                  class="d-flex align-left justify-left"
                >
                  <img
                    v-if="sbpSubscription.memberPictExist == 1"
                    :src="require('@/assets/images/banklogo/'+sbpSubscription.memberId+'.svg')"
                    style="max-height: 40px;  margin-left: 10px"
                  >
                  <img
                    v-else
                    :src="require('@/assets/images/banklogo/empty.svg')"
                    style="max-height: 40px; margin-top: 5px; margin-right: 10px;"
                  >
                  <span style="text-align: left; top: 10px; position: relative; left: 10px;">{{ sbpSubscription.memberRusName }}</span>
                </v-col>
                <v-col cols="2">
                  <v-icon
                    size="medium"
                    style="font-size: 40px; left: -10px;"
                    color="grey-darken-2"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            v-for="bankBinding of settings.bankBindings"
            :key="bankBinding.maskedPan"
            justify="center"
            align="center"
            style="margin-top: 20px;"
          >
            <v-col
              cols="11"
              sm="4"
              class="d-flex align-left justify-left"
              style="background-color: lightgray; border-radius: 10px;"
            >
              <v-row>
                <v-col
                  cols="1"
                  class="d-flex align-left justify-left"
                >
                  <v-radio
                    class="text-center"
                    :value="bankBinding.maskedPan"
                  />
                </v-col>
                <v-col
                  cols="9"
                  class="d-flex align-left justify-left"
                >
                  <span style="text-align: left; top: 10px; position: relative; left: 10px;">{{ bankBinding.maskedPan }}</span>
                </v-col>
                <v-col cols="2">
                  <v-icon
                    size="medium"
                    style="font-size: 40px; left: -10px;"
                    color="grey-darken-2"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-radio-group>

        <v-row
          justify="center"
          align="center"
        >
          <v-col
            justify="center"
            align="center"
          >
            <v-btn
              class="ma-2 bg-primary"
              variant="outlined"
              size="x-large"
              width="300"
              @click="telegramAutoPaymentServiceSettingSave()"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          align="center"
        >
          <v-col
            justify="center"
            align="center"
          >
            <v-btn
              class="ma-2 bg-error"
              variant="outlined"
              size="x-large"
              width="300"
              height="50"
              @click="dialogError = true"
            >
              Отправить сообщение <br> разработчикам.
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="dialogError"
        persistent
        width="1024"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Опишите проблему</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="dialogErrorMessage"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="dialogError = false; dialogErrorMessage=null"
            >
              Отмена
            </v-btn>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="sendError()"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script lang='ts'>

import { defineComponent } from 'vue'
import moment from 'moment'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import {
  AccountAutoPaymentSetting,
  TelegramAutoPaymentError,
  TelegramAutoPaymentServiceSettings
} from '@/store/telegram/types'
import { TelegramAutoPaymentServiceSettingsGetPost } from '@/shared/api/telegram/api-types'
import {
  telegramAutoPaymentSendError,
  telegramAutoPaymentServiceGetSettings,
  telegramAutoPaymentServiceSaveSettings,
  telegramTest
} from '@/shared/api/telegram/api-requests'

type BaseData = {
  token: string;
  loading: boolean;
  activeCard: any;
  activeCardType: any;
  cardCount: number;
  checkBankBindingsAndSbp: boolean;
  tabDates: any;
  tabAccounts: any;
  disabled: boolean;
  error: boolean;
  dialogError: boolean;
  dialogErrorMessage: string | null;
  errorMessage: string;
  errorMessageUnknown: string;
  title: string;
  settings: TelegramAutoPaymentServiceSettings;
  weekDays: any;
  telegramAutoPaymentServiceSettingsGetPost: TelegramAutoPaymentServiceSettingsGetPost;
}

export default defineComponent({
  props: {
    tokenProp: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      token: '',
      activeCard: null,
      activeCardType: null,
      cardCount: 0,
      loading: true,
      checkBankBindingsAndSbp: false,
      tabDates: 0,
      tabAccounts: 0,
      disabled: true,
      error: false,
      dialogError: false,
      dialogErrorMessage: null,
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Неизвестная',
      title: 'Настройка автоплатежей',
      telegramAutoPaymentServiceSettingsGetPost: {
        token: ''
      },
      weekDays: [
        {
          id: '1',
          name: 'Пн'
        },
        {
          id: '2',
          name: 'Вт'
        },
        {
          id: '3',
          name: 'Ср'
        },
        {
          id: '4',
          name: 'Чт'
        },
        {
          id: '5',
          name: 'Пт'
        },
        {
          id: '6',
          name: 'Сб'
        },
        {
          id: '7',
          name: 'Вс'
        }
      ],
      settings: {
        token: '',
        bankBindings: [],
        sbpSubscriptions: [],
        weekends: [],
        weekendsMin: '2024-01-01',
        weekendsMax: '2024-12-31',
        holidays: [],
        accounts: [],
        paymentType: 0,
        paymentTypeDetails: null
      }
    }
  },
  /* beforeCreate () {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-web-app.js'
    script.defer = true
    document.head.appendChild(script)
  }, */
  mounted () {
    if (this.tokenProp) {
      this.token = this.tokenProp
      if (this.token === '5') {

        this.loading = false
        this.settings = {
          token: '5',
          bankBindings: [
            {
              id: 0,
              maskedPan: '546955XXXXXX8972',
              paymentWay: 'CARD',
              bankCode: null,
              bankMessage: null,
              displayLabel: null
            }
          ],
          sbpSubscriptions: [
            {
              id: 1,
              memberId: '100000000111',
              memberRusName: 'Сбербанк',
              memberPictExist: 1
            },
            {
              id: 2,
              memberId: '100000000004',
              memberRusName: 'Тинькофф Банк',
              memberPictExist: 1
            }
          ],
          weekends: [moment('2023-11-05'), moment('2023-11-06'), moment('2023-11-11'), moment('2023-11-12'), moment('2023-11-18'), moment('2023-11-19'), moment('2023-11-25'), moment('2023-11-26'),
            moment('2023-12-02'), moment('2023-12-03'), moment('2023-12-09'), moment('2023-12-10'), moment('2023-12-16'), moment('2023-12-17'), moment('2023-12-23'), moment('2023-12-24'), moment('2023-12-30'), moment('2023-12-31'), moment('2023-11-04')],
          weekendsMin: '2023-11-01',
          weekendsMax: '2023-12-31',
          paymentType: 2,
          paymentTypeDetails: '2',
          holidays: [
            {
              type: 1,
              title: 'Осенние',
              menu: 0,
              dateRange: []
            },
            {
              type: 2,
              title: 'Зимние',
              menu: 0,
              dateRange: ['2024-01-01', '2024-01-14']
            },
            {
              type: 3,
              title: 'Весенние',
              menu: 0,
              dateRange: []
            },
            {
              type: 4,
              title: 'Летние',
              menu: 0,
              dateRange: ['2024-06-01', '2024-08-31']
            },
            {
              type: 5,
              title: '1-е классы',
              menu: 0,
              dateRange: []
            }
          ],
          accounts: [
            {
              type: 1,
              title: 'Горячее',
              on: true,
              periodicity: 2,
              day: '4',
              autoPayType: 2,
              autoPaySum: 100,
              limit: 500,
              offer: false,
              offerSum: 100
            },
            {
              type: 2,
              title: 'Буфет',
              on: false,
              periodicity: 1,
              day: '1',
              autoPayType: 1,
              autoPaySum: 100,
              limit: 500,
              offer: false,
              offerSum: 100
            }
          ]
        }
        this.checkBankCardsAndSbp()
        this.disabled = false
      } else {
        this.telegramAutoPaymentServiceSettingLoad()
      }
    }

    /* setTimeout(() => {
      const WebApp = window.Telegram.WebApp
      this.telegramTest(WebApp)
    }, 1) */
  },
  methods: {
    changePeriodicity (account: AccountAutoPaymentSetting) {
      if (account.periodicity === 2) {
        if (Number(account.day) > 7) {
          account.day = '1'
        }
      }
    },
    checkDayOfMonth (account: AccountAutoPaymentSetting) {
      if (Number(account.day) > 25) {
        account.day = '25'
        Swal.fire({
          title: 'Не рекомендуем устанавливать день позже 25 числа',
          width: '100%',
          confirmButtonText: 'Понятно'
        })
      } else if (Number(account.day) < 1) {
        account.day = '1'
      }
    },
    dateRangeText (dateRange: any) {
      if (dateRange.length >= 2) {
        if (moment(dateRange[0]) > moment(dateRange[dateRange.length - 1])) {
          const temp = dateRange[0]
          dateRange[0] = dateRange[dateRange.length - 1]
          dateRange[dateRange.length - 1] = temp
        }
        return moment(dateRange[0]).format('DD.MM.YYYY') + ' - ' + moment(dateRange[dateRange.length - 1]).format('DD.MM.YYYY')
      }
      return 'Заполнить'
    },
    menuState (item: any) {
      if (item.menu === 0) {
        return false
      }
      return true
    },
    menuChangeState (item: any) {
      if (item.menu === 1) {
        item.menu = 0
      } else {
        item.menu++
      }
    },
    closeMenu(item: any) {
      if (item.dateRange.length >= 2) {
        item.menu = 0
      }
    },
    async telegramAutoPaymentServiceSettingLoad () {
      try {
        this.loading = true
        this.telegramAutoPaymentServiceSettingsGetPost.token = this.token
        await telegramAutoPaymentServiceGetSettings(this.telegramAutoPaymentServiceSettingsGetPost)
          .then((telegramAutoPaymentServiceSettings: TelegramAutoPaymentServiceSettings) => {
            this.token = telegramAutoPaymentServiceSettings.token
            telegramAutoPaymentServiceSettings.weekends.sort((a, b) => {
              return moment(a, 'YYYY-MM-DD').toDate() - moment(b, 'YYYY-MM-DD').toDate();
            })
            const momentWeekends = telegramAutoPaymentServiceSettings.weekends.map((date: any) => moment(date, 'YYYY-MM-DD'))
            telegramAutoPaymentServiceSettings.holidays.map((holiday) => {
              const diapazonHoliday = this.getDiapazonDates(holiday.dateRange)
              holiday.dateRange = diapazonHoliday
            })
            this.settings = telegramAutoPaymentServiceSettings
            this.settings.weekends = []
            this.settings.weekends = momentWeekends
            this.checkBankCardsAndSbp()
            this.disabled = false
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    getDiapazonDates (holiday: any) {
      const holidayArr = []
      const startDate = moment(holiday[0])
      const endDate = moment(holiday[1])
        while (startDate.isSameOrBefore(endDate)) {
          holidayArr.push(startDate.format('YYYY-MM-DD'))
          startDate.add(1, 'days').format('YYYY-MM-DD')
        }
      return holidayArr
    },
    async telegramAutoPaymentServiceSettingSave () {
      try {
        this.loading = true
        const weekendsFormatted = this.settings.weekends
        this.settings.weekends = weekendsFormatted.map((date: any) => moment(date).format('YYYY-MM-DD'))
        const formattedHolidays = this.settings.holidays.map((holiday : any) => {
          let borderDates: (string | undefined)[] = []
          if (holiday.dateRange.length > 1) {
            borderDates = [holiday.dateRange[0], holiday.dateRange[holiday.dateRange.length - 1]]
          } else {
            borderDates = []
          }
            return {
              ...holiday,
              dateRange: borderDates.map((date: string | undefined) => (date !== undefined) ? moment(date).format('YYYY-MM-DD') : date)
            }
        })
        this.settings.holidays = formattedHolidays
        console.log('loading', this.loading)
        if (this.token === '5') {
          setTimeout(() => {
            this.loading = false
            this.checkBankCardsAndSbp()
          }, 1000)
        } else {
          await telegramAutoPaymentServiceSaveSettings(this.settings)
            .then((telegramAutoPaymentServiceSettings: TelegramAutoPaymentServiceSettings) => {
              this.token = telegramAutoPaymentServiceSettings.token
              telegramAutoPaymentServiceSettings.weekends.sort((a, b) => {
                return moment(a, 'YYYY-MM-DD').toDate() - moment(b, 'YYYY-MM-DD').toDate();
              })
              const momentWeekends = telegramAutoPaymentServiceSettings.weekends.map((date: any) => moment(date, 'YYYY-MM-DD'))
              this.settings = telegramAutoPaymentServiceSettings
              this.settings.weekends = []
              this.settings.weekends = momentWeekends
              this.checkBankCardsAndSbp()
              this.disabled = false
            })
        }
      } catch (error: any) {
        this.catchError(error)
      } finally {
        if (this.token !== '5') {
          this.loading = false
        }
        this.disabled = false
      }
    },
    async sendError () {
      try {
        this.loading = true
        this.dialogError = false
        const error: TelegramAutoPaymentError = {
          error: this.dialogErrorMessage,
          settings: this.settings
        }
        await telegramAutoPaymentSendError(error)
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
        this.disabled = false
      }
    },
    async telegramTest (obj: any) {
      try {
        await telegramTest(obj)
          .then((result: any) => {
            console.log(result)
          })
      } catch (error: any) {
        console.log(error)
      }
    },
    changeActiveCard (event: any) {
      console.log(event)
      this.settings.bankBindings.forEach(bankElement => {
        if (bankElement.maskedPan === event) {
          this.settings.paymentTypeDetails = bankElement.maskedPan
          this.settings.paymentType = 1
          this.activeCard = bankElement
          this.activeCardType = bankElement.maskedPan
        }
      })
      this.settings.sbpSubscriptions.forEach(sbpElement => {
        if (String(sbpElement.id) === event) {
          this.settings.paymentTypeDetails = String(sbpElement.id)
          this.settings.paymentType = 2
          this.activeCard = sbpElement
          this.activeCardType = String(sbpElement.id)
        }
      })
    },
    checkBankCardsAndSbp () {
      if (this.settings.bankBindings.length === 0 && this.settings.sbpSubscriptions.length === 0) {
        this.checkBankBindingsAndSbp = false
      } else {
        this.checkBankBindingsAndSbp = true
        this.cardCount = this.cardCount + this.settings.bankBindings.length + this.settings.sbpSubscriptions.length
        if (this.settings.paymentType === 0) {
          if (this.settings.sbpSubscriptions.length > 0) {
            this.settings.paymentType = 2
            this.settings.paymentTypeDetails = String(this.settings.sbpSubscriptions[0].id)
            this.activeCard = this.settings.sbpSubscriptions[0]
          } else {
            this.settings.paymentType = 1
            this.settings.paymentTypeDetails = String(this.settings.bankBindings[0].maskedPan)
            this.activeCard = this.settings.bankBindings[0]
          }
        }
        this.changeActiveCard(this.settings.paymentTypeDetails)
      }
    },
    getDateFormat (date: any) {
      moment.lang('ru')
      return moment(date).format('DD MMMM YYYY, h:mm')
    },
    catchError (error: any) {
      const err = error as AxiosError
      this.errorMessage = this.errorMessageUnknown
      if (err.response) {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    },
  }
})
</script>

<style>
.v-tab--active {
  background-color: rgb(209, 214, 225);
}

.v-input--switch {
  padding: 0;
  margin: 0;
  left: 45%;
  position: relative;
}

.v-picker__title {
  display: none;
}

.v-select-list {
  min-width: 70px;
}

.v-select-list {
  min-width: 70px;
}

</style>
