<template>
  <div class="whole_page">
    <div style="display: grid; justify-content: center">
      <div class="page_container">
        <template v-if="!isLoading">
          <div class="content-container">
            <v-row style="padding: 12px; display: flex; flex-direction: column; justify-content: space-between; border-bottom: 2px solid #ebeaec; ">
              <div style="display: flex; justify-content: space-between; align-items: center">
                <h3
                  class="title-h3-roboto-medium"
                  style="margin-left: 10px"
                >
                  Номер счета
                </h3>
                <div style="width: 162px; padding: 10px 28px; background: #FFFFFF; border-radius: 7px; text-align: center">
                  <h3
                    class="button-text-roboto-medium"
                    style="align-self: center"
                  >
                    {{ paymentKey }}
                  </h3>
                <!--       todo узнать зачем ставятся 0, почему должно быть 8 знаков 00000{{this.paymentKey}}-->
                </div>
              </div>
              <h3
                class="title-h4-roboto-medium"
                style="margin-left: 10px; margin-top: 7px"
              >
                {{ customerName }}
              </h3>
            </v-row>
            <v-row style="padding: 12px; display: flex; flex-direction: column; gap: 12px; border-bottom: 2px solid #ebeaec;">
              <h3
                class="title-h3-roboto-medium"
                style="margin-left: 10px"
              >
                Учреждение
              </h3>
              <div style="justify-content: space-between; display: flex; flex-direction: row">
                <div style="width: fit-content; padding: 10px; background: #FFFFFF; border-radius: 7px">
                  <h4 class="button-text-roboto-medium">
                    {{ currentSchool.place?.parentPlace?.name }} - {{ currentSchool.place?.name }}
                  </h4>
                </div>
                <div
                  style="display: flex; flex-direction: row;"
                  class=" pointed"
                  @click="showKindergartenHistory"
                >
                  <h4
                    class="body-text-roboto-medium"
                    style="text-decoration: underline; margin-left: 10px; align-self: center"
                  >
                    История
                  </h4>
                  <img
                    src="@/assets/images/arrow_dropdown.png"
                    height="16"
                    width="16"
                    style="margin-left: 1px; align-self: center"
                  >
                </div>
              </div>
              <template v-if="showingKindergartenHistoryTable">
                <table
                  class="small-text-roboto-medium"
                  style="border-radius: 8px; border: 1px solid #C0BEC5"
                >
                  <tr
                    v-for="place in userPlaces"
                    :key="place.id"
                  >
                    <td style="padding: 10px 12px; border-bottom: 1px solid #C0BEC5">
                      {{ place.place?.parentPlace.name }} - {{ place.place.name }} ( {{ place.timestampFrom }} - {{ place.timestampTo }} )
                    </td>
                  </tr>
                </table>
              </template>
            </v-row>
            <v-row style="padding: 12px; display: flex; flex-direction: column; gap: 12px; border-bottom: 2px solid #ebeaec;">
              <h3
                class="title-h3-roboto-medium"
                style="margin-left: 10px"
              >
                Карты
              </h3>
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                <div style="width: fit-content; padding: 10px; background: #FFFFFF; border-radius: 7px; text-align: start">
                  <h4
                    v-for="activeCard in activeCards"
                    :key="activeCard.id"
                    class="button-text-roboto-medium"
                  >
                    Код активной карты -
                    {{ activeCard.rfidHex }}
                  </h4>
                </div>
                <div
                  style="display: flex; flex-direction: row;"
                  class=" pointed"
                  @click="showCardHistory"
                >
                  <h4
                    class="body-text-roboto-medium"
                    style="text-decoration: underline; margin-left: 10px; align-self: center"
                  >
                    История
                  </h4>
                  <img
                    src="@/assets/images/arrow_dropdown.png"
                    height="16"
                    width="16"
                    style="margin-left: 1px; align-self: center"
                  >
                </div>
              </div>
              <template v-if="showingCardHistoryTable">
                <table class="custom-table small-text-roboto-medium">
                  <thead>
                    <tr style="background: #ECF2F6">
                      <td>Номер</td>
                      <td>Код</td>
                      <td>Статус</td>
                    </tr>
                  </thead>
                  <tr
                    v-for="(card, index) in cards"
                    :key="card.id"
                  >
                    <td v-if="card.cardTypeId !==3">
                      {{ index + 1 }}
                    </td>
                    <td v-if="card.cardTypeId !==3">
                      {{ card.rfidHex }}
                    </td>
                    <td v-if="card.flags === 40 && card.cardTypeId !==3">
                      Заблокирована
                    </td>
                    <td v-else-if="card.flags !== 40 && card.cardTypeId !==3">
                      Активна
                    </td>
                  </tr>
                </table>
              </template>
            </v-row>
            <v-row style="padding: 12px; display: flex; flex-direction: column; gap: 12px; border-bottom: 2px solid #ebeaec;">
              <h3
                class="title-h3-roboto-medium"
                style="margin-left: 10px"
              >
                Льготы
              </h3>
              <div style="width: fit-content; padding: 10px; background: #FFFFFF; border-radius: 7px">
                <h4
                  class="button-text-roboto-medium"
                  style="text-align: start"
                >
                  Льгота 1 категории действует с 19.11.2023 по 01.07.2024 назначена 18.11.2023
                </h4>
              </div>
              <div
                style="display: flex; flex-direction: row"
                class=" pointed"
                @click="showPrivilegesHistory"
              >
                <h4
                  class="body-text-roboto-medium"
                  style="text-decoration: underline; margin-left: 10px"
                >
                  История льгот
                </h4>
                <img
                  src="@/assets/images/arrow_dropdown.png"
                  height="16"
                  width="16"
                  style="margin-left: 1px; align-self: center"
                >
              </div>
              <template v-if="showingPrivilegesHistoryTable">
                <table class="custom-table small-text-roboto-medium">
                  <thead>
                    <tr style="background: #ECF2F6">
                      <td>Категория льгот</td>
                      <td>Действие с</td>
                      <td> До</td>
                      <td>Назначение</td>
                    </tr>
                  </thead>
                  <tr
                    v-for="ratePlan in userRatePlans"
                    :key="ratePlan.id"
                  >
                    <td>{{ ratePlan.ratePlan.name }}</td>
                    <td>{{ ratePlan.startTime }}</td>
                    <td>{{ ratePlan.endTime }}</td>
                    <td>{{ ratePlan.creationDate }}</td>
                  </tr>
                </table>
              </template>
            </v-row>
            <v-row style="padding: 12px; display: flex; flex-direction: column; align-items: center; gap: 12px; justify-content: center">
              <div style="width: 337px; height: 202px; border-radius: 15px; background: #C0BEC5" />
              <div style="display: flex; flex-direction: row; gap: 12px">
                <button
                  class="primary-green-button"
                  style="width: 178px; padding: 8.5px 8px"
                >
                  Распечатать визитку
                </button>
                <button
                  class="primary-green-button"
                  style="width: 136px; padding: 8.5px 14px"
                >
                  Сохранить
                </button>
              </div>
            </v-row>
          </div>
        </template>
        <template v-else>
          <div
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { getPersonInfo } from '@/shared/api/parents/myChildren/api-requests'
import { cardsItem, userPlacesItem, userRatePlansItem } from '@/shared/api/parents/myChildren/api-types'
import { defineComponent } from 'vue'

// todo fix dates with moment
export default defineComponent({
  name: 'ParentsMyChildren',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      showingKindergartenHistoryTable: false,
      showingCardHistoryTable: false,
      showingPrivilegesHistoryTable: false,
      paymentKey: '',
      userPlaces: [] as userPlacesItem[],
      cards: [] as cardsItem[],
      userRatePlans: [] as userRatePlansItem[],
      currentSchool: {} as userPlacesItem,
      activeCards: [] as cardsItem[],
      activeRatePlan: {},
      isTelegram: false,
      isLoading: false,
      customerName: ''
    }
  },
  async beforeMount () {
    if (window.Telegram !== undefined) {
      this.isTelegram = true
    }
    this.isLoading = true
    this.currentUser = this.user.person
    await this.getPersonInfo()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getPersonInfo () {
      try {
        const getPersonInfoReqObj = {
          userId: this.currentUser.id
        }
        const getPersonInfoRes = await getPersonInfo(getPersonInfoReqObj, this.isTelegram)
        if (getPersonInfoRes.data.userPaymentKeys) {
          this.paymentKey = getPersonInfoRes.data.userPaymentKeys[0]?.paymentKey
          console.log('paymentKey', this.paymentKey)
        }
        if (getPersonInfoRes.data.currentCustomerDescription) {
          this.customerName = getPersonInfoRes.data.currentCustomerDescription.shortName
        }
        if (getPersonInfoRes.data.userPlaces) {
          this.userPlaces = getPersonInfoRes.data.userPlaces
          const currentSchoolIdx = this.userPlaces.findIndex(place => place.timestampTo === null)
          if (currentSchoolIdx !== -1) {
            this.currentSchool = this.userPlaces[currentSchoolIdx]
            this.userPlaces.splice(currentSchoolIdx, 1)
          }
          console.log('userPlaces', this.userPlaces)
          console.log('curr_school', this.currentSchool)
        }
        if (getPersonInfoRes.data.cards) {
          this.cards = getPersonInfoRes.data.cards
          this.cards.forEach(card => {
            if (card.flags === 0) {
              this.activeCards.push(card)
            }
          })
          console.log('active', this.activeCards)
          console.log('cards', this.cards)
        }
        if (getPersonInfoRes.data.userRatePlans) {
          this.userRatePlans = getPersonInfoRes.data.userRatePlans
          console.log('userRatePlans', this.userRatePlans)
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить информацию о ребенке!',
          timeout: 3000
        })
      }
    },
    goToHome () {
      this.$router.push('./home')
    },
    showKindergartenHistory () {
      this.showingKindergartenHistoryTable = !this.showingKindergartenHistoryTable
    },
    closeKindergartenHistory () {
      this.showingKindergartenHistoryTable = false
    },
    showCardHistory () {
      this.showingCardHistoryTable = !this.showingCardHistoryTable
    },
    closeCardHistory () {
      this.showingCardHistoryTable = false
    },
    showPrivilegesHistory () {
      this.showingPrivilegesHistoryTable = !this.showingPrivilegesHistoryTable
    },
    closePrivilegesHistory () {
      this.showingPrivilegesHistoryTable = true
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
.content-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 40px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 10px 10px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
.page_container {
  width: 900px;
  margin: 20px 16px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-aspect-ratio: 6/5) {
  .page_container {
    width: calc(100% - 32px);
  }
}
</style>
