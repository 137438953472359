import { createStore } from 'vuex'
import { user } from '@/store/user'
import { snackbar } from '@/store/snackbar'
import { devices } from '@/store/deviceList'
import { incrementGroups } from '@/store/incrementGroup'
import { incrementGroupDevices } from '@/store/incrementGroupDevice'
import { incrementGroupTimes } from '@/store/incrementGroupTime'
import { userAllowedPlaces } from '@/store/userAllowedPlaceList'
import { userAllowedDistricts } from '@/store/userAllowedDistrictList'
import { placeElectronTalons } from '@/store/placeElectronTalonList'
import { placeElectronTalon } from '@/store/placeElectronTalon'
import { placeCustomerProducts } from '@/store/placeCustomerProductList'
import { userRatePlans } from '@/store/userRatePlan'

// Vue.use(Vuex)

const store = createStore({
  modules: {
    user,
    snackbar,
    devices,
    incrementGroups,
    incrementGroupDevices,
    incrementGroupTimes,
    userAllowedPlaces,
    userAllowedDistricts,
    placeElectronTalons,
    placeElectronTalon,
    placeCustomerProducts,
    userRatePlans
  },
  strict: process.env.NODE_ENV !== 'production'
})

export default store
