import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceElectronTalon, Id } from '@/store/placeElectronTalon/types'
import { PlaceElectronTalonGetByIdPost, PlaceElectronTalonSavePost, PlaceElectronTalonUsersAddPost, PlaceElectronTalonUserRemovePost, PlaceElectronTalonFindWithItemsPost, PlaceElectronTalonFindWithItemsAndRatePlansPost } from './api-types'

const routePlaceElectronTalonGetById = '/placeelectrontalon/find'
const routePlaceElectronTalonFindWithItems = '/placeelectrontalons/findwithitems'
const routePlaceElectronTalonFindWithItemsAndHistory = '/placeelectrontalons/findwithitemsandhistory'
const routePlaceElectronTalonFindWithItemsAndRatePlans = '/placeelectrontalons/findwithitemsandrateplans'
const routePlaceElectronTalonSave = '/placeelectrontalon/save'
const routePlaceElectronTalonUsersAdd = '/placeelectrontalon/users/add'
const routePlaceElectronTalonUserRemove = '/placeelectrontalonitem/delete'
const routePlaceProductsFind = '/placeproducts/find'
const routePlaceProductSave = '/placeproduct/save'

export const findPlaceProducts = async (data: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routePlaceProductsFind, data)
  return result.data.data
}

export const savePlaceProduct = async (data: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routePlaceProductSave, data)
  return result.data.data
}

export const getById = async (data: PlaceElectronTalonGetByIdPost): Promise<PlaceElectronTalon> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: PlaceElectronTalon[];
  }> = await axios.post(routePlaceElectronTalonGetById, data)
  return result.data.data[0]
}

export const findWithItems = async (data: PlaceElectronTalonFindWithItemsPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceElectronTalonFindWithItems, data)
  return result.data.data
}

export const findWithItemsAndHistory = async (data: PlaceElectronTalonFindWithItemsPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceElectronTalonFindWithItemsAndHistory, data)
  return result.data.data
}

export const findWithItemsAndRatePlans = async (data: PlaceElectronTalonFindWithItemsAndRatePlansPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceElectronTalonFindWithItemsAndRatePlans, data)
  return result.data.data
}

export const save = async (data: PlaceElectronTalonSavePost): Promise<Id> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: Id;
  }> = await axios.post(routePlaceElectronTalonSave, data)
  return result.data.data
}

export const usersadd = async (data: PlaceElectronTalonUsersAddPost): Promise<boolean> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: boolean;
  }> = await axios.post(routePlaceElectronTalonUsersAdd, data)
  return result.data.data
}

export const userRemove = async (data: PlaceElectronTalonUserRemovePost): Promise<boolean> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: boolean;
  }> = await axios.post(routePlaceElectronTalonUserRemove, data)
  return result.data.data
}
