import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fdaa6168"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "printable-area" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filters_panel = _resolveComponent("filters-panel")!
  const _component_string_editor_button = _resolveComponent("string-editor-button")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_base_simple_table = _resolveComponent("base-simple-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_filters_panel, {
      "filter-options": _ctx.filterOptions,
      onFilterChanged: _ctx.filterChangedHandler
    }, null, 8, ["filter-options", "onFilterChanged"]),
    _createVNode(_component_string_editor_button, {
      "prop-string": _ctx.title,
      onUpdateString: _ctx.updateReportTitle
    }, null, 8, ["prop-string", "onUpdateString"]),
    _createVNode(_component_v_btn, {
      class: "button-preorder",
      elevation: "2",
      onClick: _ctx.print
    }, {
      default: _withCtx(() => [
        _createTextVNode(" ПЕЧАТЬ ")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", {
        style: {textAlign: 'center', margin: '15px'},
        innerHTML: _ctx.title
      }, null, 8, _hoisted_2),
      _createVNode(_component_base_simple_table, { "table-data": _ctx.tableData }, null, 8, ["table-data"]),
      _createElementVNode("p", null, _toDisplayString(`__________________/${_ctx.rawApiData[0].teacherFullName}`), 1)
    ])
  ]))
}