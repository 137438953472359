<template>
  <v-container class="convertedRawApiData">
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Список детей</strong>
        </h1>
        <a @click="back">Назад</a>
        <v-row align="center">
          <v-col/>
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Добавить в талон"
              loading-text="Подождите..."
              :is-loading="loading"
              :disabled="disabled"
              @submit-clicked="handleSubmit"
            />
          </v-col>
        </v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="userRatePlansData"
          :single-select="singleSelect"
          item-key="id"
          show-select
          :loading="loading"
          class="elevation-1"
          :items-per-page="-1"
          loading-text="Загрузка... Пожалуйста подождите"
          :return-object="true"
        >
          <template #[`item.lastName`]="props">
            {{ props.item.lastName + ' ' + props.item.firstName + ' ' + props.item.middleName }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { parseAxiosError } from '@/shared/lib/api'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import {
  PlaceElectronTalonGetByIdPost,
  PlaceElectronTalonUsersAddPost
} from '@/shared/api/placeElectronTalon/api-types'
import { PlaceElectronTalon } from '@/store/placeElectronTalonList/types'
import { UserRatePlanFindPost } from '@/shared/api/userRateplan/api-types'
import { User } from '@/store/userRatePlan/types'
import { usersadd as PlaceElectronTalonUsersAdd } from '@/shared/api/placeElectronTalon/api-requests'

type BaseData = {
  loading: boolean;
  disabled: boolean;
  placeElectronTalon: PlaceElectronTalon;
  placeElectronTalonGetByIdPost: PlaceElectronTalonGetByIdPost;
  placeElectronTalonUsersAddPost: PlaceElectronTalonUsersAddPost;
  userRatePlanFindPost: UserRatePlanFindPost;
  userRatePlansData: any;
  headers: any;
  singleSelect: boolean;
  selected: any;
}
export default defineComponent({
  components: {
    SubmitButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    placeId: {
      type: String,
      requred: true
    }
  },
  data (): BaseData {
    return {
      loading: false,
      disabled: false,
      singleSelect: false,
      selected: [],
      headers: [
        { title: 'Фамилия Имя Отчество', align: 'start', sortable: false, value: 'lastName' }
      ],
      placeElectronTalonGetByIdPost: {
        id: 0,
        itemsInclude: false,
        salesInclude: false,
        historyInclude: false,
        productsInclude: false
      },
      placeElectronTalonUsersAddPost: {
        placeElectronTalonId: 0,
        date: '',
        userIds: []
      },
      userRatePlanFindPost: {
        placeId: 0,
        typeId: 2,
        placeIncludeDesc: 1,
        timestampFrom: '',
        timestampTo: ''
      },
      userRatePlansData: [],
      placeElectronTalon: {
        id: 0,
        placeId: 0,
        parentPlaceId: 0,
        talonYear: 0,
        talonNumber: 0,
        creationDate: null,
        ownerUserId: 0,
        updateUserId: 0,
        updateTimestamp: null,
        date: null,
        guid: null,
        status: 0,
        controlUserId: 0,
        controlTimestamp: 0,
        ownerTimestamp: null,
        placeElectronTalonItems: undefined,
        placeElectronTalonSales: [],
        placeElectronTalonHistoryItems: [],
        placeElectronTalonProducts: [],
        place: {
          id: 0,
          parentId: 0,
          name: '',
          typeId: 0,
          latitude: null,
          longitude: null,
          creationDate: null,
          name2: '',
          updateTimestamp: null,
          removed: null,
          shortName: '',
          ownerUserId: 0,
          placeTempAttributes: []
        }
      }
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('placeElectronTalon', { placeElectronTalonFind: 'getById' }),
    ...mapActions('userRatePlans', { userRatePlans: 'find' }),
    async getUserRatePlans (userRatePlanFindPost: UserRatePlanFindPost) {
      this.loading = true
      const n: User[] = await this.userRatePlans(userRatePlanFindPost)
      this.userRatePlansData = JSON.parse(JSON.stringify(n))
      this.loading = false
    },
    formatDate (userDate: any) {
      // format to DDMMYYYY
      return (new Date(userDate).toJSON().slice(0, 10).split('-').join('-'))
    },
    addDays (date: any, days: number) {
      const result = new Date(date)
      result.setDate(result.getDate() + days)
      return this.formatDate(result.toString())
    },
    async getTalonForEdit (placeElectronTalonGetByIdPost: PlaceElectronTalonGetByIdPost) {
      this.loading = true
      const n: PlaceElectronTalon = await this.placeElectronTalonFind(placeElectronTalonGetByIdPost)
      if (!n) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Группа не найдена.',
          timeout: 3000
        })
        this.$router.push({ name: 'TalonRegister', query: { back: 'true', date: this.date, placeId: this.placeId } })
        return
      }
      this.placeElectronTalon = JSON.parse(JSON.stringify(n))
      this.userRatePlanFindPost.placeId = this.placeElectronTalon.placeId
      this.userRatePlanFindPost.timestampFrom = this.placeElectronTalon.date
      this.userRatePlanFindPost.timestampTo = this.addDays(this.placeElectronTalon.date, 1)
      this.placeElectronTalonUsersAddPost.date = this.placeElectronTalon.date
      await this.getUserRatePlans(this.userRatePlanFindPost)
      this.loading = false
    },
    async handleSubmit () {
      if (this.selected.length > 0) {
        this.loading = true
        this.selected.forEach((element: any) => {
          this.placeElectronTalonUsersAddPost.userIds.push(element.id)
        })
        try {
          const result = await PlaceElectronTalonUsersAdd(this.placeElectronTalonUsersAddPost)
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка обновления списка детей.',
            timeout: 3000
          })
          return Promise.reject(parseAxiosError(error))
        } finally {
          this.loading = false
          this.$router.push({ name: 'TalonEdit', params: { id: this.id, date: this.date, placeId: this.placeId } })
        }
      }
    },
    back () {
      console.log({ name: 'TalonEdit', params: { id: this.id, date: this.date, placeId: this.placeId } })
      this.$router.push({ name: 'TalonEdit', params: { id: this.id, date: this.date, placeId: this.placeId } })
    }
  },
  async mounted () {
    if (!this.id) {
      this.$router.push({ name: 'dashboard' })
    }
    this.placeElectronTalonGetByIdPost.id = Number(this.id)
    this.placeElectronTalonUsersAddPost.placeElectronTalonId = Number(this.id)
    await this.getTalonForEdit(this.placeElectronTalonGetByIdPost)
  }
})
</script>

<style lang='scss'></style>
