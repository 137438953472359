import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import {
  AllergenGroupsFindRequest,
  AllergenGroupsGetTypesResponse, AllergensFindRequest, AllergensFindResponse,
  UserAllergensGetByUserIdRequest,
  UserAllergensGetByUserIdResponse,
  UserAllergensSaveManyRequest, UserAllergensSaveManyResponse,
  UserAllergensSaveRequest,
  UserAllergensSaveResponse
} from '@/shared/api/parents/notAllowed/api-types'

enum NotAllowedRoutes {
  AllergenGroupsGetTypes = '/allergenGroups/getTypes',
  UserAllergensGetByUserId = '/person/userAllergens/getByUserId',
  UserAllergensSave = '/person/userAllergens/save',
  UserAllergensSaveMany = '/person/userAllergens/saveMany',
  AllergenGroupsFind = '/allergenGroups/find',
  AllergensFind = '/allergens/find'
}
const headers = {
  'X-Client': 'telegram',
  'X-Telegram-Token': localStorage.getItem('tgToken'),
  'X-Telegram-ChatId': localStorage.getItem('chatId')
}

export const allergenGroupsGetTypes = async (telegram: boolean): Promise<AllergenGroupsGetTypesResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<AllergenGroupsGetTypesResponse>(NotAllowedRoutes.AllergenGroupsGetTypes, { headers })
    return result.data
  } else {
    const result = await axios.post<AllergenGroupsGetTypesResponse>(NotAllowedRoutes.AllergenGroupsGetTypes)
    return result.data
  }
}

export const userAllergensGetByUserId = async (data: UserAllergensGetByUserIdRequest, telegram: boolean): Promise<UserAllergensGetByUserIdResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<UserAllergensGetByUserIdResponse>(NotAllowedRoutes.UserAllergensGetByUserId, data, { headers })
    return result.data
  } else {
    const result = await axios.post<UserAllergensGetByUserIdResponse>(NotAllowedRoutes.UserAllergensGetByUserId, data)
    return result.data
  }
}

export const userAllergensSave = async (data: UserAllergensSaveRequest, telegram: boolean): Promise<UserAllergensSaveResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post<UserAllergensSaveResponse>(NotAllowedRoutes.UserAllergensSave, data, { headers })
    return result.data
  } else {
    const result = await axios.post<UserAllergensSaveResponse>(NotAllowedRoutes.UserAllergensSave, data)
    return result.data
  }
}

export const userAllergensSaveMany = async (data: UserAllergensSaveManyRequest, telegram: boolean): Promise<UserAllergensSaveManyResponse> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post(NotAllowedRoutes.UserAllergensSaveMany, data, { headers })
    return result.data
  } else {
    const result = await axios.post(NotAllowedRoutes.UserAllergensSaveMany, data)
    return result.data
  }
}

export const allergenGroupsFind = async (data: AllergenGroupsFindRequest, telegram: boolean): Promise<any> => {
  setAuthorizationHeader(axios)
  if (telegram) {
    const result = await axios.post(NotAllowedRoutes.AllergenGroupsFind, data, { headers })
    return result.data
  } else {
    const result = await axios.post(NotAllowedRoutes.AllergenGroupsFind, data)
    return result.data
  }
}

export const allergensFind = async (data: AllergensFindRequest, telegram: boolean): Promise<AllergensFindResponse> => {
  if (telegram) {
    const result = await axios.post<AllergensFindResponse>(NotAllowedRoutes.AllergensFind, data, { headers })
    return result.data
  } else {
    const result = await axios.post<AllergensFindResponse>(NotAllowedRoutes.AllergensFind, data)
    return result.data
  }
}
