import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { UserAllowedDistrict } from '@/store/userAllowedDistrictList/types'
import { UserAllowedDistrictCreatePost, UserAllowedDistrictEditPut } from './api-types'

const routeUserAllowedDistrictList = '/useralloweddistricts/all'
const routeUserAllowedDistrictFind = '/useralloweddistricts/find'
const routeUserAllowedDistrictCreate = '/useralloweddistricts/create'
const routeUserAllowedDistrictUpdate = '/useralloweddistricts/update'
const routeUserAllowedDistrictDelete = '/useralloweddistricts/delete'

export const all = async (): Promise<UserAllowedDistrict[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: UserAllowedDistrict[];
  }> = await axios.get(routeUserAllowedDistrictList)
  return result.data.data
}

export const find = async (data: number): Promise<UserAllowedDistrict> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserAllowedDistrict ;
  }> = await axios.get(`${routeUserAllowedDistrictFind}/${data}`)

  return result.data.data
}

export const create = async (data: UserAllowedDistrictCreatePost): Promise<UserAllowedDistrict> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserAllowedDistrict ;
  }> = await axios.post(routeUserAllowedDistrictCreate, data)

  return result.data.data
}

export const update = async (data: UserAllowedDistrict): Promise<UserAllowedDistrict> => {
  const putRequest: UserAllowedDistrictEditPut = {
    district: data.district
  }

  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserAllowedDistrict;
  }> = await axios.put(`${routeUserAllowedDistrictUpdate}/${data.id}`, putRequest)

  return result.data.data
}

export const del = async (data: UserAllowedDistrict): Promise<UserAllowedDistrict> => {
  setAuthorizationHeader(axios)
  await axios.delete(`${routeUserAllowedDistrictDelete}/${data.id}`)
  return data
}
