import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { TransactionsByUserFindPost } from './api-types'

const routeTransactionsByUserFind = 'transactions/findbyuser'

export const findByUser = async (data: TransactionsByUserFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(routeTransactionsByUserFind, data)
  return result.data.data
}
