<template>
  <div class="class-item">
    <div class="class-item__header">
      <p class="class-name">
        {{ classItem.name }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClassBidCard',
  props: {
    classItem: {
      type: Object as any,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.class-item {
  width: 615px;
  height: 400px;
  border: 1px solid grey;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  //
  //.class-name {
  //
  //}
}
</style>
