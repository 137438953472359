import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { PlaceElectronTalon, PlaceElectronTalonReport, Id } from '@/store/placeElectronTalonList/types'
import {
  PlaceElectronTalonSearchPost,
  PlaceElectronTalonReportPost,
  PlaceCustomerProductSavePost,
  PlaceCustomerProductRemovePost,
  PlaceElectronTalonCreatePost,
  PlaceElectronTalonSignPost,
  salesPost,
  saleInfo
} from './api-types'

const routePlaceElectronTalonFind = '/placeelectrontalons/find'
const routePlaceElectronTalonCreate = '/placeelectrontalons/gettalons'
const routePlaceElectronTalonSign = '/placeelectrontalons/signtalons'
const routeElectronTalonProductSave = '/placeelectrontalonproducts/create'
const routeElectronTalonProductDelete = '/placeelectrontalonproducts/delete'
const routeElectronTalonValidation = '/placeelectrontalons/validationtalons'
const routeElectronTalonReport = '/placeelectrontalons/report'
const routeSales = '/sales/findbyproducts'
const routeUserPlaceInfoBySaleId = '/sales/getUserPlaceInfoById'

export const find = async (data: PlaceElectronTalonSearchPost): Promise<PlaceElectronTalon[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: PlaceElectronTalon[];
  }> = await axios.post(routePlaceElectronTalonFind, data)
  return result.data.data
}

export const report = async (data: PlaceElectronTalonReportPost): Promise<PlaceElectronTalonReport[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: PlaceElectronTalonReport[];
  }> = await axios.post(routeElectronTalonReport, data)
  return result.data.data
}

export const create = async (data: PlaceElectronTalonCreatePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceElectronTalonCreate, data)
  return result.data.data
}

export const validation = async (data: PlaceElectronTalonSignPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeElectronTalonValidation, data)
  return result.data.data
}

export const sign = async (data: PlaceElectronTalonSignPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routePlaceElectronTalonSign, data)
  return result.data.data
}

export const save = async (data: PlaceCustomerProductSavePost): Promise<Id> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: Id;
  }> = await axios.post(routeElectronTalonProductSave, data)
  return result.data.data
}

export const remove = async (data: PlaceCustomerProductRemovePost): Promise<boolean> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: boolean;
  }> = await axios.post(routeElectronTalonProductDelete, data)
  return result.data.data
}

export const sales = async (data: salesPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: any;
  }> = await axios.post(routeSales, data)
  return result.data.data
}

export const userPlaceInfoBySaleId = async (data: saleInfo): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeUserPlaceInfoBySaleId, data)
  return result.data.data[0]
}
