/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  SET_USERALLOWEDPLACES, ADD_USERALLOWEDPLACES,
  ADD_USERALLOWEDPLACE_TO_STACK,
  DELETE_USERALLOWEDPLACE_FROM_STACK,
  EDIT_USERALLOWEDPLACE_IN_STACK,
  CLEAR_USERALLOWEDPLACES
} from '@/store/userAllowedPlaceList/mutations'
import { RootState } from '@/store/state'
import {
  UserAllowedPlace, UserAllowedPlaceListState
} from '@/store/userAllowedPlaceList/types'
import { UserAllowedPlaceCreatePost, UserAllowedPlaceEditPut } from '../../shared/api/userAllowedPlaceList/api-types'
import {
  all as userAllowedPlaceAll,
  find as userAllowedPlaceFind,
  create as userAllowedPlaceCreate,
  update as userAllowedPlaceUpdate,
  del as userAllowedPlaceDel
} from '@/shared/api/userAllowedPlaceList/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type UserAllowedPlaceListContext = ActionContext<UserAllowedPlaceListState, RootState>

const initialState: UserAllowedPlaceListState = {
  userAllowedPlaces: [{
    id: 0,
    userId: 0,
    placeId: 0,
    allowedTypeId: 0
  }]
}

const getters: GetterTree<UserAllowedPlaceListState, RootState> = {
  userAllowedPlaces (state: UserAllowedPlaceListState): UserAllowedPlace[] {
    return state.userAllowedPlaces
  }
}

const mutations: MutationTree<UserAllowedPlaceListState> = {
  [SET_USERALLOWEDPLACES] (state: UserAllowedPlaceListState, userAllowedPlaces: UserAllowedPlace[]) {
    state.userAllowedPlaces = userAllowedPlaces
  },
  [ADD_USERALLOWEDPLACES] (state: UserAllowedPlaceListState, userAllowedPlaces: UserAllowedPlace[]) {
    state.userAllowedPlaces = [...state.userAllowedPlaces, ...userAllowedPlaces]
  },
  [ADD_USERALLOWEDPLACE_TO_STACK] (state: UserAllowedPlaceListState, userAllowedPlace: UserAllowedPlace) {
    state.userAllowedPlaces = [userAllowedPlace, ...state.userAllowedPlaces]
  },
  [DELETE_USERALLOWEDPLACE_FROM_STACK] (state: UserAllowedPlaceListState, userAllowedPlace: UserAllowedPlace) {
    const index = state.userAllowedPlaces.map(userAllowedPlace => userAllowedPlace.id).indexOf(userAllowedPlace.id)
    state.userAllowedPlaces = [
      ...state.userAllowedPlaces.slice(0, index),
      ...state.userAllowedPlaces.slice(index + 1)
    ]
  },
  [EDIT_USERALLOWEDPLACE_IN_STACK] (state: UserAllowedPlaceListState, userAllowedPlace: UserAllowedPlace) {
    const index = state.userAllowedPlaces.map(userAllowedPlace => userAllowedPlace.id).indexOf(userAllowedPlace.id)
    if (index === -1) return
    state.userAllowedPlaces = [
      ...state.userAllowedPlaces.slice(0, index),
      userAllowedPlace,
      ...state.userAllowedPlaces.slice(index + 1)
    ]
  },
  [CLEAR_USERALLOWEDPLACES] (state: UserAllowedPlaceListState) {
    state.userAllowedPlaces = []
  }
}

const actions: ActionTree<UserAllowedPlaceListState, RootState> = {
  async all (
    { commit, dispatch, state }: UserAllowedPlaceListContext
  ): Promise<UserAllowedPlace[]> {
    try {
      const result = await userAllowedPlaceAll()
      commit(SET_USERALLOWEDPLACES, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async create (
    { commit, dispatch, state }: UserAllowedPlaceListContext,
    data: UserAllowedPlaceCreatePost
  ): Promise<UserAllowedPlace> {
    try {
      const result = await userAllowedPlaceCreate(data)
      commit(ADD_USERALLOWEDPLACE_TO_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async find (
    { commit, dispatch, state }: UserAllowedPlaceListContext,
    data: number
  ): Promise<UserAllowedPlace> {
    try {
      const result = await userAllowedPlaceFind(data)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async update (
    { commit, dispatch, state }: UserAllowedPlaceListContext,
    data: UserAllowedPlace
  ): Promise<UserAllowedPlace> {
    try {
      const result = await userAllowedPlaceUpdate(data)
      commit(EDIT_USERALLOWEDPLACE_IN_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async del (
    { commit, dispatch, state }: UserAllowedPlaceListContext,
    data: UserAllowedPlace
  ): Promise<UserAllowedPlace> {
    try {
      const result = await userAllowedPlaceDel(data)
      commit(DELETE_USERALLOWEDPLACE_FROM_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const userAllowedPlaces = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
