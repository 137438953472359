export enum UserType {
  ForAll = 0, // Для всех
  Parent = 2, // Родитель
  Admin = 4, // Администратор
  Cook = 6, // Зав. производством (повар)
  LAdmin = 7, // Ответственный по питанию
  Operator = 10, // Оператор
  Admin90 = 16, // Бухгалтер
  Rosohrana = 26, // Росохрана
  RosohranaManager = 68, // Росохрана менджер
  RosohranaAdmin = 66, // Старший оператор Росохрана
  Control = 30, // Брысова Виктория Анатольевна
  Cshtadmin = 33, // Администратор ЦШТ
  Admin110 = 59, // Коммитет образования - отдел питания
  Cobradmin = 60 // Администратор коммитета образования
}

export enum Errors {
  privilegeGranted1 = 1,
  privilegeGranted2 = 2,
  rationMarkError1 = 3,
  rationMarkError2 = 4,
  recalculationRequired = 5,
  notTakenIntoAccount = 6,
  cancellationRequired = 7,
  accountingIsPaidByCard = 8,
  notAPupil1 = 9,
  notAPupil2 = 10,
  isNotABeneficiary1 = 11,
  isNotABeneficiary2 = 12,
  noRegid1 = 13,
  noRegid2 = 14,
  noSNILS1 = 15,
  noSNILS2 = 16,
  restored = 17,
  ignore = 18,
  waitingForReturn = 19,
  waitingForCancellation = 20,
  doubleSale = 21,
  talonNoEmptyWithoutKAISKRO = 22,
  notTakenIntoAccountByCard = 23,
  cancellationRequiredByCard = 24,
  recalculationProductRequired = 25,
  cancelationByAccountant = 26
}

export enum ErrorGroups {
  'privilegeGranted1' = 1,
  'privilegeGranted2' = 2,
  'rationMarkError1' = 1,
  'rationMarkError2' = 2,
  'recalculationRequired' = 4,
  'notTakenIntoAccount' = 4,
  'cancellationRequired' = 4,
  'accountingIsPaidByCard' = 4,
  'notAPupil1' = 1,
  'notAPupil2' = 2,
  'isNotABeneficiary1' = 1,
  'isNotABeneficiary2' = 2,
  'noRegid1' = 1,
  'noRegid2' = 2,
  'noSNILS1' = 5,
  'noSNILS2' = 5,
  'restored' = 4,
  'ignore' = 3,
  'waitingForReturn' = 4,
  'waitingForCancellation' = 4,
  'doubleSale' = 4,
  'talonNoEmptyWithoutKAISKRO' = 1,
  'notTakenIntoAccountByCard' = 4,
  'cancellationRequiredByCard' = 4,
  'recalculationProductRequired' = 4,
  'cancelationByAccountant' = 4
}

export enum ErrorMessages {
  'Льгота предоставлена ранее' = 1,
  'Льгота предоставлена ранее.' = 2,
  'Ошибка отметки рациона' = 3,
  'Ошибка отметки рациона.' = 4,
  'Требуется перерасчет' = 5,
  'Не учтено' = 6,
  'Требуется отмена' = 7,
  'Учет платно по карте' = 8,
  'Не является учеником' = 9,
  'Не является учеником.' = 10,
  'Не является льготником' = 11,
  'Не является льготником.' = 12,
  'Нет регистрационного идентификатора' = 13,
  'Нет регистрационного идентификатора.' = 14,
  'Отсутствует СНИЛС' = 15,
  'Отсутствует СНИЛС.' = 16,
  'Восстановлено' = 17,
  'Не учитывать' = 18,
  'Ожидание возврата' = 19,
  'Ожидание отмены' = 20,
  'Количество больше 1' = 21,
  'Талон не сформирован в КАИС Кро' = 22,
  'Не учтено замена по карте' = 23,
  'Требуется отмена по карте' = 24,
  'Требуется перерасчет. Смена рациона' = 25,
  'Продажа отменена бухгалтером' = 26
}

export enum ErrorMessages2 {
  privilegeGranted1 = 'Льгота предоставлена ранее',
  privilegeGranted2 = 'Льгота предоставлена ранее',
  rationMarkError1 = 'Ошибка отметки рациона',
  rationMarkError2 = 'Ошибка отметки рациона',
  recalculationRequired = 'Требуется перерасчет',
  notTakenIntoAccount = 'Не учтено',
  cancellationRequired = 'Требуется отмена',
  accountingIsPaidByCard = 'Учет платно по карте',
  notAPupil1 = 'Не является учеником',
  notAPupil2 = 'Не является учеником',
  isNotABeneficiary1 = 'Не является льготником',
  isNotABeneficiary2 = 'Не является льготником',
  noRegid1 = 'Нет регистрационного идентификатора',
  noRegid2 = 'Нет регистрационного идентификатора',
  noSNILS1 = 'Отсутствует СНИЛС',
  noSNILS2 = 'Отсутствует СНИЛС',
  restored = 'Восстановлено',
  ignore = 'Не учитывать',
  waitingForReturn = 'Ожидание возврата',
  waitingForCancellation = 'Ожидание отмены',
  doubleSale = 'Количество больше 1',
  talonNoEmptyWithoutKAISKRO = 'Талон не сформирован в КАИС Кро',
  notTakenIntoAccountByCard = 'Не учтено замена по карте',
  cancellationRequiredByCard = 'Требуется отмена по карте',
  recalculationProductRequired = 'Требуется перерасчет. Смена рациона',
  cancelationByAccountant = 'Продажа отменена бухгалтером'
}

export const userTypes = [
  { id: 1, name: 'Неизвестный' },
  { id: 2, name: 'Ученик' },
  { id: 3, name: 'Учитель' },
  { id: 4, name: 'Администратор' },
  { id: 5, name: 'Устройство' },
  { id: 6, name: 'Повар' },
  { id: 7, name: 'Ответственный за питание' },
  { id: 8, name: 'Инкассатор' },
  { id: 9, name: 'Инспектор РОНО' },
  { id: 10, name: 'Оператор Глолайм' },
  { id: 11, name: 'Управление социального питания' },
  { id: 12, name: 'Сбербанк' },
  { id: 13, name: 'Директор школы' },
  { id: 14, name: 'Директор комбината' },
  { id: 15, name: 'Учитель группы продленного дня' },
  { id: 16, name: 'Бухгалтер комбината' },
  { id: 17, name: 'Безлимитный' },
  { id: 18, name: 'Сотрудник школы' },
  { id: 19, name: 'Контролирующий учитель' },
  { id: 20, name: 'Бухгалтер школы' },
  { id: 21, name: 'Медсестра детского сада' },
  { id: 22, name: 'Типография' },
  { id: 23, name: 'Единый пользователь' },
  { id: 24, name: 'Мониторинг параграфа' },
  { id: 25, name: 'Компенсация' },
  { id: 26, name: 'Росохрана' },
  { id: 27, name: 'Вендинг' },
  { id: 28, name: 'Родитель' },
  { id: 29, name: 'Администрация Района' },
  { id: 30, name: 'Брысова Виктория Анатольевна' },
  { id: 31, name: 'Завуч школы' },
  { id: 32, name: 'ИМЦ' },
  { id: 33, name: 'ЦШТ' },
  { id: 34, name: 'Централизованная бухгалтерия' },
  { id: 35, name: 'Печать карт' },
  { id: 36, name: 'Секретарь' },
  { id: 37, name: 'Отправка СМС' },
  { id: 38, name: 'Обновление' },
  { id: 39, name: 'Параграф' },
  { id: 40, name: 'Клиент 1С' },
  { id: 41, name: 'Тестирование Допуслуг' },
  { id: 42, name: 'Фейк' },
  { id: 43, name: 'Автообновление' },
  { id: 44, name: 'Администратор Сбербанк' },
  { id: 45, name: 'Демо-стенд' },
  { id: 46, name: 'Получение отчетов' },
  { id: 47, name: 'Демо-стенд Новгород' },
  { id: 48, name: 'Стенд СПб 21 школа' },
  { id: 49, name: 'Библиотекарь' },
  { id: 50, name: 'Управление ЕГИСО' },
  { id: 51, name: 'Администратор Управление соц питания' },
  { id: 52, name: 'Учитель интернат' },
  { id: 53, name: 'Коммитет ЕГИСО' },
  { id: 54, name: 'Оператор комбината питания' },
  { id: 55, name: 'Отчеты управления соцпитания' },
  { id: 56, name: 'Администратор для заказа продуктов' },
  { id: 57, name: 'Системный администратор школы' },
  { id: 58, name: 'Администратор авторизации' },
  { id: 59, name: 'Комитет образования - отдел питания' },
  { id: 60, name: 'Администратор коммитета образования' },
  { id: 61, name: 'Сотрудник Комбината' },
  { id: 62, name: 'Сервер СК' },
  { id: 63, name: 'Пользователь мобильного приложения СК' },
  { id: 64, name: 'Российское ПО' },
  { id: 65, name: 'Облачный Сбер' },
  { id: 66, name: 'Старший оператор Росохрана' }
]

export const transactionTypes = [
  { id: 1, name: 'Проверка баланса' },
  { id: 2, name: 'Пополнение' },
  { id: 3, name: 'Списание' },
  { id: 4, name: 'Отмена' },
  { id: 5, name: 'Инкассация' }
]

export const accountTypes = [
  { id: 1, name: 'Горячее питание' },
  { id: 2, name: 'Буфет' },
  { id: 3, name: 'Платные услуги' },
  { id: 4, name: 'Льготное питание' },
  { id: 5, name: 'Перевыпуск карты' },
  { id: 6, name: 'SMS-оповещения' },
  { id: 7, name: 'Бронирование за карту' },
  { id: 8, name: 'Пожертвование' },
  { id: 9, name: 'Выпуск браслета' },
  { id: 10, name: 'Мобильное приложение' },
  { id: 11, name: 'Детский сад' },
  { id: 12, name: 'Охрана' },
  { id: 13, name: 'Бассейн' },
  { id: 14, name: 'Депонент' },
  { id: 15, name: 'Доп образование' }
]

export const documentTypes = [
  { id: 1, name: 'Приказ по кодам питания' },
  { id: 2, name: 'Групповое списание средств' },
  { id: 3, name: 'Коррекция остатка' },
  { id: 4, name: 'Продажа' },
  { id: 5, name: 'Закрытие дня' },
  { id: 6, name: 'Услуги школы' },
  { id: 7, name: 'Русский язык' },
  { id: 8, name: 'Компенсация за питание' },
  { id: 9, name: 'Закрытие месяца' },
  { id: 10, name: 'Групповой талон' },
  { id: 11, name: 'Списание Глолайм' },
  { id: 12, name: 'Установка тарифного плана' },
  { id: 13, name: 'Приказ на компенсацию' },
  { id: 14, name: 'Замена на завтрак и обед' },
  { id: 15, name: 'Интернат' },
  { id: 16, name: 'Списание за карту' },
  { id: 17, name: 'Кошелек Глолайм' },
  { id: 18, name: 'Приказ на платные услуги' },
  { id: 19, name: 'Коррекция депонента' },
  { id: 20, name: 'Списание депонента' },
  { id: 21, name: 'Зачисление депонента' },
  { id: 22, name: 'Коррекция остатка Глолайм' },
  { id: 23, name: 'Дистанционное обучение' },
  { id: 24, name: 'Оформление прихода' },
  { id: 25, name: 'Перевод между счетами' },
  { id: 26, name: 'Зачисление остатков Росохраной' }
]
