<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex align-center"
        cols="3"
        sm="3"
      >
        <v-btn
          class="button-wrap"
          elevation="2"
          :to="{ name: 'PreorderAdd', params: { } }"
        >
          НАЗАД
        </v-btn>
      </v-col>
      <v-col
        class="d-flex align-center justify-center"
      >
        <h3> РАЦИОНЫ </h3>
      </v-col>
      <v-col
        class="d-flex align-center"
        cols="3"
        sm="3"
      />
    </v-row>
    <v-row class="justify-content-center">
      <v-col
        class="align-center justify-end"
        cols="3"
        sm="3"
      />
      <v-col
        v-if="isLoading || !isGet"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
      <v-col
        v-if="!isLoading && isGet"
        class="text-center d-flex  justify-center"
      >
        <v-table class="table-preorder">
          <template #default>
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Активность</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, indexp) in allProducts"
                :key="indexp"
              >
                <td>{{ product.product.name }}</td>
                <td class="text-center">
                  <v-switch
                    v-model="product.checked"
                    @click="productChecked(product)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
      <v-col
        class="align-center justify-end"
        cols="3"
        sm="3"
      />
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import {
  PreorderProductsCreatePost,
  PreorderProductsFindPost,
  PreorderProductsRemovePost
} from '@/shared/api/preorderProducts/api-types'
import {
  create as preorderProductsCreate,
  find as preorderProductsFind,
  remove as preorderProductsRemove
} from '@/shared/api/preorderProducts/api-requests'
import { PlaceCustomerProductsAllPost } from '@/shared/api/placeCustomerProductList/api-types'
import { all as placeCustomerProductsAll } from '@/shared/api/placeCustomerProductList/api-requests'
import { AxiosError } from 'axios'

type BaseData = {
  radioGroup: any;
  isLoading: boolean;
  isGet: boolean;
  placeId: any;
  userAllowedPlacesCopy: any;
  userAllowedPlacesList: any;
  selectedUserAllowedPlace: any;
  preorder: any;
  preorderNumber: string;
  preorderDate: string;
  preorderProductsFindPost: PreorderProductsFindPost;
  preorderProductsCreatePost: PreorderProductsCreatePost;
  preorderProductsRemovePost: PreorderProductsRemovePost;
  placeCustomerProductsAllPost: PlaceCustomerProductsAllPost;
  preorderProducts: any;
  allProducts: any;
  classes: any;
}

export default defineComponent({
  components: {
  },
  props: {
    propPlaceId: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      radioGroup: '1',
      isLoading: false,
      isGet: false,
      placeId: null,
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      preorder: null,
      preorderNumber: '',
      preorderDate: '',
      preorderProductsFindPost: {
        placeId: 0
      },
      preorderProductsCreatePost: {
        placeId: 0,
        productId: 0,
        status: 1
      },
      preorderProductsRemovePost: {
        id: 0,
        status: 2
      },
      placeCustomerProductsAllPost: {
        placeId: 0,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      preorderProducts: [],
      allProducts: [],
      classes: [
        { id: '1a' },
        { id: '1б' },
        { id: '1в' },
        { id: '2a' },
        { id: '2б' },
        { id: '2в' }
      ]
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedPlaces: 'userAllowedPlaces/userAllowedPlaces' })
  },
  methods: {
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadUserAllowedPlaces () {
      try {
        await this.userAllowedPlaceAll()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.userAllowedPlacesList = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedPlacesCopy = this.userAllowedPlaces.filter((obj: any) => {
          return obj.removed == null
        })
        if (this.userAllowedPlacesList.length > 0) this.selectedUserAllowedPlace = this.userAllowedPlacesList[0].place.id
        if (this.selectedUserAllowedPlace) {
          this.changePlace()
        }
        this.isLoading = false
      }
    },
    async loadPreorderProduct (preorderProductsFindPost: PreorderProductsFindPost) {
      this.isLoading = true
      try {
        const result = await preorderProductsFind(preorderProductsFindPost)
        console.log(result)
        this.preorderProducts = JSON.parse(JSON.stringify(result))
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.loadAllProduct(this.placeCustomerProductsAllPost)
      }
    },
    async loadAllProduct (placeCustomerProductsAllPost: PlaceCustomerProductsAllPost) {
      this.isLoading = true
      try {
        const result = await placeCustomerProductsAll(placeCustomerProductsAllPost)
        this.allProducts = JSON.parse(JSON.stringify(result))
        this.allProducts.forEach((element: any) => {
          element.checked = false
          element.preorderProductsId = null
          const tempPreorderProducts = this.preorderProducts.find((f: any) => (f.productId === element.product.id) && f.status === 1)
          if (tempPreorderProducts) {
            element.checked = true
            element.preorderProductsId = tempPreorderProducts.id
          }
        })
        console.log(this.allProducts)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.isGet = true
      }
    },
    async createPreorderProduct (preorderProductsCreatePost: PreorderProductsCreatePost) {
      this.isGet = false
      this.isLoading = true
      try {
        const result = await preorderProductsCreate(preorderProductsCreatePost)
        console.log(result)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.changePlace()
      }
    },
    async removePreorderProduct (preorderProductsRemovePost: PreorderProductsRemovePost) {
      this.isGet = false
      this.isLoading = true
      try {
        const result = await preorderProductsRemove(preorderProductsRemovePost)
        console.log(result)
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
        this.changePlace()
      }
    },
    productChecked (product: any) {
      if (product.checked) {
        console.log('Создаём')
        this.preorderProductsCreatePost.productId = product.product.id
        this.createPreorderProduct(this.preorderProductsCreatePost)
      } else {
        console.log('Удаляем')
        this.preorderProductsRemovePost.id = product.preorderProductsId
        this.removePreorderProduct(this.preorderProductsRemovePost)
      }
    },
    changePlace () {
      this.preorderProductsFindPost.placeId = parseInt(this.propPlaceId)
      this.placeCustomerProductsAllPost.placeId = parseInt(this.propPlaceId)
      this.preorderProductsCreatePost.placeId = parseInt(this.propPlaceId)
      this.loadPreorderProduct(this.preorderProductsFindPost)
    }
  },
  mounted () {
    if (!this.propPlaceId) {
      this.$router.push({ name: 'PreorderAdd', params: { } })
    }
    this.isLoading = true
    this.loadUserAllowedPlaces()
  }
})
</script>

<style lang="scss"></style>
