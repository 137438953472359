import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { UserAllowedPlace } from '@/store/userAllowedPlaceList/types'
import { UserAllowedPlaceCreatePost, UserAllowedPlaceEditPut } from './api-types'

const routeUserAllowedPlaceList = '/userallowedplaces/all'
const routeUserAllowedPlaceFind = '/userallowedplaces/find'
const routeUserAllowedPlaceCreate = '/userallowedplaces/create'
const routeUserAllowedPlaceUpdate = '/userallowedplaces/update'
const routeUserAllowedPlaceDelete = '/userallowedplaces/delete'

export const all = async (): Promise<UserAllowedPlace[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: UserAllowedPlace[];
  }> = await axios.get(routeUserAllowedPlaceList)
  return result.data.data
}

export const find = async (data: number): Promise<UserAllowedPlace> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserAllowedPlace ;
  }> = await axios.get(`${routeUserAllowedPlaceFind}/${data}`)

  return result.data.data
}

export const create = async (data: UserAllowedPlaceCreatePost): Promise<UserAllowedPlace> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserAllowedPlace ;
  }> = await axios.post(routeUserAllowedPlaceCreate, data)

  return result.data.data
}

export const update = async (data: UserAllowedPlace): Promise<UserAllowedPlace> => {
  const putRequest: UserAllowedPlaceEditPut = {
    place: data.place
  }

  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: UserAllowedPlace;
  }> = await axios.put(`${routeUserAllowedPlaceUpdate}/${data.id}`, putRequest)

  return result.data.data
}

export const del = async (data: UserAllowedPlace): Promise<UserAllowedPlace> => {
  setAuthorizationHeader(axios)
  await axios.delete(`${routeUserAllowedPlaceDelete}/${data.id}`)
  return data
}
