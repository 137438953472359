import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { CardErrorLogFindByCardNumberPost, CardErrorLogFindPost } from './api-types'

const cardErrorLogsFind = 'carderrorlogs/find'
const cardErrorLogsByCardNumberFind = 'carderrorlogs/findbycardnumber'

export const find = async (data: CardErrorLogFindPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(cardErrorLogsFind, data)
  return result.data.data
}

export const findByCardNumber = async (data: CardErrorLogFindByCardNumberPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any ;
  }> = await axios.post(cardErrorLogsByCardNumberFind, data)
  return result.data.data
}
