/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  SET_USERALLOWEDDISTRICTS, ADD_USERALLOWEDDISTRICTS,
  ADD_USERALLOWEDDISTRICT_TO_STACK,
  DELETE_USERALLOWEDDISTRICT_FROM_STACK,
  EDIT_USERALLOWEDDISTRICT_IN_STACK,
  CLEAR_USERALLOWEDDISTRICTS
} from '@/store/userAllowedDistrictList/mutations'
import { RootState } from '@/store/state'
import {
  UserAllowedDistrict, UserAllowedDistrictListState
} from '@/store/userAllowedDistrictList/types'
import { UserAllowedDistrictCreatePost, UserAllowedDistrictEditPut } from '../../shared/api/userAllowedDistrictList/api-types'
import {
  all as userAllowedDistrictAll,
  find as userAllowedDistrictFind,
  create as userAllowedDistrictCreate,
  update as userAllowedDistrictUpdate,
  del as userAllowedDistrictDel
} from '@/shared/api/userAllowedDistrictList/api-requests'
import { parseAxiosError } from '@/shared/lib/api'

type UserAllowedDistrictListContext = ActionContext<UserAllowedDistrictListState, RootState>

const initialState: UserAllowedDistrictListState = {
  userAllowedDistricts: [{
    id: 0,
    userId: 0,
    districtId: 0
  }]
}

const getters: GetterTree<UserAllowedDistrictListState, RootState> = {
  userAllowedDistricts (state: UserAllowedDistrictListState): UserAllowedDistrict[] {
    return state.userAllowedDistricts
  }
}

const mutations: MutationTree<UserAllowedDistrictListState> = {
  [SET_USERALLOWEDDISTRICTS] (state: UserAllowedDistrictListState, userAllowedDistricts: UserAllowedDistrict[]) {
    state.userAllowedDistricts = userAllowedDistricts
  },
  [ADD_USERALLOWEDDISTRICTS] (state: UserAllowedDistrictListState, userAllowedDistricts: UserAllowedDistrict[]) {
    state.userAllowedDistricts = [...state.userAllowedDistricts, ...userAllowedDistricts]
  },
  [ADD_USERALLOWEDDISTRICT_TO_STACK] (state: UserAllowedDistrictListState, userAllowedDistrict: UserAllowedDistrict) {
    state.userAllowedDistricts = [userAllowedDistrict, ...state.userAllowedDistricts]
  },
  [DELETE_USERALLOWEDDISTRICT_FROM_STACK] (state: UserAllowedDistrictListState, userAllowedDistrict: UserAllowedDistrict) {
    const index = state.userAllowedDistricts.map(userAllowedDistrict => userAllowedDistrict.id).indexOf(userAllowedDistrict.id)
    state.userAllowedDistricts = [
      ...state.userAllowedDistricts.slice(0, index),
      ...state.userAllowedDistricts.slice(index + 1)
    ]
  },
  [EDIT_USERALLOWEDDISTRICT_IN_STACK] (state: UserAllowedDistrictListState, userAllowedDistrict: UserAllowedDistrict) {
    const index = state.userAllowedDistricts.map(userAllowedDistrict => userAllowedDistrict.id).indexOf(userAllowedDistrict.id)
    if (index === -1) return
    state.userAllowedDistricts = [
      ...state.userAllowedDistricts.slice(0, index),
      userAllowedDistrict,
      ...state.userAllowedDistricts.slice(index + 1)
    ]
  },
  [CLEAR_USERALLOWEDDISTRICTS] (state: UserAllowedDistrictListState) {
    state.userAllowedDistricts = []
  }
}

const actions: ActionTree<UserAllowedDistrictListState, RootState> = {
  async all (
    { commit, dispatch, state }: UserAllowedDistrictListContext
  ): Promise<UserAllowedDistrict[]> {
    try {
      const result = await userAllowedDistrictAll()
      commit(SET_USERALLOWEDDISTRICTS, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async create (
    { commit, dispatch, state }: UserAllowedDistrictListContext,
    data: UserAllowedDistrictCreatePost
  ): Promise<UserAllowedDistrict> {
    try {
      const result = await userAllowedDistrictCreate(data)
      commit(ADD_USERALLOWEDDISTRICT_TO_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async find (
    { commit, dispatch, state }: UserAllowedDistrictListContext,
    data: number
  ): Promise<UserAllowedDistrict> {
    try {
      const result = await userAllowedDistrictFind(data)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async update (
    { commit, dispatch, state }: UserAllowedDistrictListContext,
    data: UserAllowedDistrict
  ): Promise<UserAllowedDistrict> {
    try {
      const result = await userAllowedDistrictUpdate(data)
      commit(EDIT_USERALLOWEDDISTRICT_IN_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async del (
    { commit, dispatch, state }: UserAllowedDistrictListContext,
    data: UserAllowedDistrict
  ): Promise<UserAllowedDistrict> {
    try {
      const result = await userAllowedDistrictDel(data)
      commit(DELETE_USERALLOWEDDISTRICT_FROM_STACK, result)
      return result
    } catch (error: any) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const userAllowedDistricts = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
