<template>
  <v-footer
    absolute
    app
    inset
    class="font-weight-medium"
    heigth="200"
    width="auto"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Глолайм</strong>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  }
})
</script>

<style lang="scss"></style>
