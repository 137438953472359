<template>
  <section>
    <v-container>
      <v-row>
        <v-col>
          <img
            src="@/assets/images/utilities/404.png"
            alt="404 - Страница не найдена"
            class="img-fluid d-block mx-auto image-callout"
          >
          <h2>Страница не найдена</h2>
          <p>Невозможно найти запрашиваемый ресурс.</p>
          <v-btn
            variant="blue"
            :to="{ name: 'Home' }"
          >
            На главную
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  }
})
</script>

<style lang="scss">
section {
  padding: 1rem 0;
  text-align: left;
  @media (min-width: 992px) {
    text-align: center;
  }
}
.image-callout {
  max-height: 350px;
}
</style>
