import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import { IncrementGroupTime } from '@/store/incrementGroupTime/types'
import { IncrementGroupTimeCreatePost } from './api-types'

const routeIncrementGroupTimeList = '/incrementgrouptimes/all'
const routeIncrementGroupTimeFind = '/incrementgrouptimes/find'
const routeIncrementGroupTimeCreate = '/incrementgrouptimes/create'
const routeIncrementGroupTimeDelete = '/incrementgrouptimes/delete'

export const all = async (): Promise<IncrementGroupTime[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      data: IncrementGroupTime[];
  }> = await axios.get(routeIncrementGroupTimeList)
  return result.data.data
}

export const find = async (data: number): Promise<IncrementGroupTime> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: IncrementGroupTime ;
  }> = await axios.get(`${routeIncrementGroupTimeFind}/${data}`)

  return result.data.data
}

export const create = async (data: IncrementGroupTimeCreatePost): Promise<IncrementGroupTime> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: IncrementGroupTime ;
  }> = await axios.post(routeIncrementGroupTimeCreate, data)

  return result.data.data
}

export const del = async (data: IncrementGroupTime): Promise<IncrementGroupTime> => {
  setAuthorizationHeader(axios)
  await axios.delete(`${routeIncrementGroupTimeDelete}/${data.id}`)
  return data
}
