import axios, { setAuthorizationHeader } from '@/shared/lib/axios'
import { AxiosResponse } from 'axios'
import {
  PlaceSettingsFindByParentIdWithPlacesApiRequest,
  PlaceSettingsFindByParentIdWithPlacesApiResponse, PlaceSettingsSaveApiRequest, PlaceSettingsSaveApiResponse
} from '@/shared/api/placeSettings/api-types'

export enum PlaceSettingsRoutes {
  routePlaceSettingsFindByUser = '/placesettings/findbyuser',
  routePlaceSettingsFindByParentIdWithPlaces = 'placeSettings/findByParentIdWithPlaces',
  routePlaceSettingsSave = 'placeSettings/save'
}

export const findByUser = async (): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.get(PlaceSettingsRoutes.routePlaceSettingsFindByUser)
  return result.data.data
}

/**
 * Метод настроек для классов и самого списка классов организации (placeSettingTypeId: 32)
 * @param data
 * @returns {Promise<BaseApiResponse & {places: Place[], placeSettings: PlaceSettingsItem[]}>}
 */
export const placeSettingsFindByParentIdWithPlaces = async (data: PlaceSettingsFindByParentIdWithPlacesApiRequest): Promise<PlaceSettingsFindByParentIdWithPlacesApiResponse> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<PlaceSettingsFindByParentIdWithPlacesApiResponse> = await axios.post(PlaceSettingsRoutes.routePlaceSettingsFindByParentIdWithPlaces, data)
  return result.data
}

/**
 * Метод сохранения настроек классов
 * @param data
 * @returns {Promise<BaseApiResponse & {data: any}>}
 */
export const placeSettingsSave = async (data: PlaceSettingsSaveApiRequest): Promise<PlaceSettingsSaveApiResponse> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<PlaceSettingsSaveApiResponse> = await axios.post(PlaceSettingsRoutes.routePlaceSettingsSave, data)
  return result.data
}
