<template>
  <v-btn
    :id="`${name}-${id}`"
    :disabled="isLoading || disabled"
    block
    class="btn"
    type="submit"
    @click.prevent="$emit('submit-clicked')"
  >
    <span>
      <v-progress-circular v-if="isLoading" />
      {{ isLoading ? loadingText : text }}
    </span>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'

type BaseData = {
  id: string;
}
export default defineComponent({
  components: {
  },
  props: {
    isLoading: {
      required: true,
      type: Boolean
    },
    loadingText: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    },
    disabled: {
      required: true,
      type: Boolean
    }
  },
  data (): BaseData {
    return {
      id: uuidv4()
    }
  },
  methods: {
    update (event: Event) {
      const target = event.target as HTMLTextAreaElement
      this.$emit('input', target.value)
    }
  }
})
</script>

<style lang="scss"></style>
