<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <place-selector-multiple
          v-model="selectedUserAllowedPlace"
          :selected-items-props="selectedUserAllowedPlace"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateFrom)"
              label="С"
              prepend-icon="mdi-calendar"
              readonly

              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата С"
            v-model="dateFrom"
            @input="menu1 = false;dateChange()"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :offset="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              :model-value="formatDate(dateTo)"
              label="По"
              prepend-icon="mdi-calendar"
              readonly

              v-bind="props"
            />
          </template>
          <v-date-picker
            hide-weekdays
            title="Дата По"
            v-model="dateTo"
            @input="menu2 = false;dateChange()"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="loadPreordersButton"
        >
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col
        v-if="isLoading"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
      <v-col
        v-if="!isLoading && isPost"
        class="text-center"
      >
        <v-table class="report-rateplan-day">
          <template #default>
            <thead>
              <tr>
                <th class="text-center">
                  Школа
                </th>
                <th class="text-center">
                  Дата
                </th>
                <th class="text-center preorder-filter-column">
                  Рацион
                  <v-select
                    v-model="selectedProduct"
                    class="preorder-select-filter"
                    :items="products"
                    item-value="id"
                    item-title="name"
                    density="compact"
                    variant="outlined"
                    @update:model-value="changeSelectedProduct()"
                  />
                </th>
                <th class="text-center">
                  Вечер
                </th>
                <th class="text-center">
                  Корректировка
                </th>
                <th class="text-center">
                  Факт
                </th>
                <th class="text-center">
                  Расхождение
                </th>
                <th class="text-center" />
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(preorder, index) in preordersTable"
                :key="index"
              >
                <tr>
                  <td :rowspan="(preorder.rowspan)">
                    {{ preorder.placeName }}
                  </td>
                  <template v-if="preorder.preordersTableDates[0].items.length > 0">
                    <td :rowspan="preorder.preordersTableDates[0].items.length">
                      {{ preorder.preordersTableDates[0].date }}
                    </td>
                    <td> {{ preorder.preordersTableDates[0].items[0].productName }} </td>
                    <td> {{ preorder.preordersTableDates[0].items[0].value }} </td>
                    <td> {{ preorder.preordersTableDates[0].items[0].correction }} </td>
                    <td> {{ preorder.preordersTableDates[0].items[0].fact }} </td>
                    <td :class="((preorder.preordersTableDates[0].items[0].fact - preorder.preordersTableDates[0].items[0].value - preorder.preordersTableDates[0].items[0].correction) < 0) ? 'td-red' : '' ">
                      {{ (preorder.preordersTableDates[0].items[0].fact - preorder.preordersTableDates[0].items[0].value - preorder.preordersTableDates[0].items[0].correction) }}
                    </td>
                    <td>
                      <v-btn
                        variant="text"
                        action
                        @click="preorderDetail(`${preorder.preordersTableDates[0].items[0].productId}`, `${preorder.placeId}`, `${preorder.preordersTableDates[0].date}`)"
                      >
                        <v-icon>mdi-folder</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <template v-else>
                    <td> {{ preorder.preordersTableDates[0].date }} </td>
                    <td colspan="6">
                      Нет данных
                    </td>
                  </template>
                </tr>
                <template v-for="(date, indexDate) in preorder.preordersTableDates">
                  <tr
                    v-if="indexDate > 0"
                    :key="date.id"
                  >
                    <template v-if="date.items.length > 0">
                      <td :rowspan="date.items.length">
                        {{ date.date }}
                      </td>
                      <td> {{ date.items[0].productName }} </td>
                      <td> {{ date.items[0].value }} </td>
                      <td> {{ date.items[0].correction }} </td>
                      <td> {{ date.items[0].fact }} </td>
                      <td :class="((date.items[0].fact - date.items[0].value - date.items[0].correction) < 0) ? 'td-red' : '' ">
                        {{ (date.items[0].fact - date.items[0].value - date.items[0].correction) }}
                      </td>
                      <td>
                        <v-btn
                          variant="text"
                          action
                          @click="preorderDetail(`${date.items[0].productId}`, `${preorder.placeId}`, date.date)"
                        >
                          <v-icon>mdi-folder</v-icon>
                        </v-btn>
                      </td>
                    </template>
                    <template v-else>
                      <td> {{ date.date }} </td>
                      <td colspan="6">
                        Нет данных
                      </td>
                    </template>
                  </tr>
                  <template v-if="date.items.length > 0">
                    <template v-for="(item, indexItem) in date.items">
                      <tr
                        v-if="indexItem > 0"
                        :key="item.id"
                      >
                        <td> {{ item.productName }} </td>
                        <td> {{ item.value }} </td>
                        <td> {{ item.correction }} </td>
                        <td> {{ item.fact }} </td>
                        <td :class="((item.fact - item.value - item.correction) < 0) ? 'td-red' : '' ">
                          {{ (item.fact - item.value - item.correction) }}
                        </td>
                        <td>
                          <v-btn
                            variant="text"
                            action
                            @click="preorderDetail(`${item.productId}`, `${preorder.placeId}`, `${date.date}`)"
                          >
                            <v-icon>mdi-folder</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </template>
            </tbody>
            <tfoot v-if="productItog.length > 0">
              <template v-for="(itog, indexItog) in productItog">
                <template v-if="indexItog == 0">
                  <tr :key="itog.id">
                    <td
                      colspan="2"
                      :rowspan="productItog.length"
                    >
                      ИТОГО
                    </td>
                    <td> {{ itog.name }} </td>
                    <td> {{ itog.value }} </td>
                    <td> {{ itog.correction }} </td>
                    <td> {{ itog.fact }} </td>
                    <td :class="((itog.fact - itog.value - itog.correction) < 0) ? 'td-red' : '' ">
                      {{ (itog.fact - itog.value - itog.correction) }}
                    </td>
                    <td />
                  </tr>
                </template>
                <template v-else>
                  <tr :key="itog.id">
                    <td> {{ itog.name }} </td>
                    <td> {{ itog.value }} </td>
                    <td> {{ itog.correction }} </td>
                    <td> {{ itog.fact }} </td>
                    <td :class="((itog.fact - itog.value - itog.correction) < 0) ? 'td-red' : '' ">
                      {{ (itog.fact - itog.value - itog.correction) }}
                    </td>
                    <td />
                  </tr>
                </template>
              </template>
            </tfoot>
          </template>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'
import { PreordersFindPost } from '@/shared/api/preorders/api-types'
import { find as preordersFind } from '@/shared/api/preorders/api-requests'
import { AxiosError } from 'axios'
import PlaceSelectorMultiple from '@/shared/uikit/selectors/PlaceSelectorMultiple.vue'
import moment from 'moment'

type PreordersTableItem = {
  id: number;
  productId: number;
  productName: string;
  value: number;
  correction: number;
  fact: number;
}

type PreordersTableDate = {
  id: number;
  date: string;
  items: PreordersTableItem[];
}

type PreordersTable = {
  placeId: number;
  placeName: string;
  rowspan: number;
  preordersTableDates: PreordersTableDate[];
}

type Product = {
  id: number;
  name: string;
}

type ProductItog = {
  id: number;
  productId: number;
  name: string;
  value: number;
  correction: number;
  fact: number;
}

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  userAllowedPlacesList: any;
  selectedUserAllowedPlace: any;
  selectedUserAllowedPlace2: any;
  userAllowedPlacesCopy: any;
  searchTerm: string;
  dateFrom: string;
  dateTo: string;
  dateFromOld: string;
  dateToOld: string;
  menu1: boolean;
  menu2: boolean;
  preordersFindPost: PreordersFindPost;
  preorders: any;
  preordersFiltered: any;
  preordersTable: PreordersTable[];
  products: Product[];
  selectedProduct: any;
  productItog: ProductItog[];
}

export default defineComponent({
  components: {
    PlaceSelectorMultiple
  },
  props: {
    propPlaces: {
      type: String
    },
    propDateFrom: {
      type: String
    },
    propDateTo: {
      type: String
    }
  },
  data (): BaseData {
    return {
      isLoading: false,
      isPost: false,
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      selectedUserAllowedPlace2: [],
      userAllowedPlacesCopy: [],
      searchTerm: '',
      dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFromOld: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateToOld: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      preordersFindPost: {
        placeIds: [],
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      preorders: [],
      preordersFiltered: [],
      preordersTable: [],
      products: [{ id: 0, name: 'Все' }],
      productItog: [],
      selectedProduct: 0
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async loadPreorders (preordersFindPost: PreordersFindPost) {
      this.isLoading = true
      this.isPost = false
      try {
        const result = await preordersFind(preordersFindPost)
        this.preorders = JSON.parse(JSON.stringify(result))
        this.preordersFiltered = this.preorders
        this.tableProcessing()
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    loadPreordersButton () {
      this.preordersFindPost.placeIds = []
      if (this.selectedUserAllowedPlace.length < 1) {
        Swal.fire({
          title: 'Выберите школу',
          confirmButtonText: 'Понятно'
        })
      } else {
        console.log('loadPreordersButton', this.selectedUserAllowedPlace)
        this.selectedUserAllowedPlace.forEach((element: number) => {
          this.preordersFindPost.placeIds.push(element)
        })
        this.preordersFindPost.dateFrom = this.dateFrom
        this.preordersFindPost.dateTo = this.dateTo
        this.loadPreorders(this.preordersFindPost)
      }
    },
    changeSelectedProduct () {
      if (this.selectedProduct !== 0) {
        this.tableProcessing(this.selectedProduct)
      } else {
        this.tableProcessing()
      }
    },
    tableProcessing (productId?: any) {
      this.preordersTable = []
      this.productItog = []
      let tempId = 1
      this.preordersFiltered.forEach((element: any) => {
        const preordersTableDate: PreordersTableDate[] = []
        let tempRowspan = 1
        let dateFirst = true
        element.data.forEach((eldata: any) => {
          const tempItems: PreordersTableItem[] = []
          let rationFirst = true
          if (eldata.preorder !== null) {
            eldata.preorder.preorderItems.forEach((elpreorderItems: any) => {
              if (!this.products.find((f: any) => f.id === elpreorderItems.productId)) {
                this.products.push({
                  id: elpreorderItems.productId,
                  name: elpreorderItems.product.name
                })
              }
              if (!productId) {
                if (tempItems.find((f: any) => f.productId === elpreorderItems.productId)) {
                  const tmp = tempItems.find((f: any) => f.productId === elpreorderItems.productId)
                  if (tmp) {
                    tmp.value = tmp.value + elpreorderItems.count
                    tmp.correction = tmp.correction + elpreorderItems.correctionCount
                    tmp.fact = tmp.fact + elpreorderItems.placeElectronTalonCount
                  }
                } else {
                  const tmp: PreordersTableItem = {
                    id: tempId,
                    productId: elpreorderItems.productId,
                    productName: elpreorderItems.product.name,
                    value: elpreorderItems.count,
                    correction: elpreorderItems.correctionCount,
                    fact: elpreorderItems.placeElectronTalonCount
                  }
                  tempId = tempId + 1
                  if (!rationFirst) {
                    tempRowspan = tempRowspan + 1
                  }
                  tempItems.push(tmp)
                }
                const tempProductItog = this.productItog.find((f: any) => f.productId === elpreorderItems.productId)
                if (!tempProductItog) {
                  this.productItog.push({
                    id: tempId,
                    productId: elpreorderItems.productId,
                    name: elpreorderItems.product.name,
                    value: elpreorderItems.count,
                    correction: elpreorderItems.correctionCount,
                    fact: elpreorderItems.placeElectronTalonCount
                  })
                  tempId = tempId + 1
                } else {
                  tempProductItog.value = tempProductItog.value + elpreorderItems.count
                  tempProductItog.correction = tempProductItog.correction + elpreorderItems.correctionCount
                  tempProductItog.fact = tempProductItog.fact + elpreorderItems.placeElectronTalonCount
                }
                rationFirst = false
              } else {
                if (elpreorderItems.productId === productId) {
                  if (tempItems.find((f: any) => f.productId === elpreorderItems.productId)) {
                    const tmp = tempItems.find((f: any) => f.productId === elpreorderItems.productId)
                    if (tmp) {
                      tmp.value = tmp.value + elpreorderItems.count
                      tmp.correction = tmp.correction + elpreorderItems.correctionCount
                      tmp.fact = tmp.fact + elpreorderItems.placeElectronTalonCount
                    }
                  } else {
                    const tmp: PreordersTableItem = {
                      id: tempId,
                      productId: elpreorderItems.productId,
                      productName: elpreorderItems.product.name,
                      value: elpreorderItems.count,
                      correction: elpreorderItems.correctionCount,
                      fact: elpreorderItems.placeElectronTalonCount
                    }
                    tempId = tempId + 1
                    if (!rationFirst) {
                      tempRowspan = tempRowspan + 1
                    }
                    tempItems.push(tmp)
                  }
                  const tempProductItog = this.productItog.find((f: any) => f.productId === elpreorderItems.productId)
                  if (!tempProductItog) {
                    this.productItog.push({
                      id: tempId,
                      productId: elpreorderItems.productId,
                      name: elpreorderItems.product.name,
                      value: elpreorderItems.count,
                      correction: elpreorderItems.correctionCount,
                      fact: elpreorderItems.placeElectronTalonCount
                    })
                    tempId = tempId + 1
                  } else {
                    tempProductItog.value = tempProductItog.value + elpreorderItems.count
                    tempProductItog.correction = tempProductItog.correction + elpreorderItems.correctionCount
                    tempProductItog.fact = tempProductItog.fact + elpreorderItems.placeElectronTalonCount
                  }
                  rationFirst = false
                }
              }
            })
          }
          const tempPreordersTableDate: PreordersTableDate = {
            id: tempId,
            date: eldata.date,
            items: tempItems
          }
          tempId = tempId + 1
          preordersTableDate.push(tempPreordersTableDate)
          if (!dateFirst) {
            tempRowspan = tempRowspan + 1
          }
          dateFirst = false
        })
        const tempPreordersTable: PreordersTable = {
          placeId: element.place.id,
          placeName: element.place.name,
          rowspan: tempRowspan,
          preordersTableDates: preordersTableDate
        }
        this.preordersTable.push(tempPreordersTable)
      })
      this.isPost = true
      console.log(this.products)
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }
      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    dateChange () {
      const dateFrom = new Date(this.dateFrom)
      const dateTo = new Date(this.dateTo)
      let error1 = true
      let error2 = true
      if (dateTo < dateFrom) {
        Swal.fire({
          title: 'Дата "С" не должна превышать дату "По"',
          confirmButtonText: 'Понятно'
        }).then(() => {
          this.dateFrom = this.dateFromOld
          this.dateTo = this.dateToOld
        })
      } else {
        error1 = false
      }
      if (((+dateTo - +dateFrom) / 86400000) > 35) {
        Swal.fire({
          title: 'Диапазон не должен превышать 35 дней',
          confirmButtonText: 'Понятно'
        }).then(() => {
          this.dateFrom = this.dateFromOld
          this.dateTo = this.dateToOld
        })
      } else {
        error2 = false
      }
      if (!error1 && !error2) {
        this.dateToOld = this.dateTo
        this.dateFromOld = this.dateFrom
      }
    },
    preorderDetail (productId: string, placeId: string, date: string) {
      this.$router.push({ name: 'PreorderDetail', params: { propPlaceId: placeId, propDate: date, propProductId: productId, propPlaces: JSON.stringify(this.selectedUserAllowedPlace), propDateFrom: this.dateFrom, propDateTo: this.dateTo } })
    }
  },
  mounted () {
    this.selectedUserAllowedPlace = []
    if (this.propPlaces && this.propDateFrom && this.propDateTo) {
      this.selectedUserAllowedPlace = JSON.parse(this.propPlaces)
      this.dateFrom = this.propDateFrom
      this.dateTo = this.propDateTo
      this.dateFromOld = this.propDateFrom
      this.dateToOld = this.propDateTo
      this.loadPreordersButton()
    }
  }
})
</script>

<style lang="scss"></style>
