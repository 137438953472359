<template>
  <div class="whole_page">
    <!--  <button @click="goToHome" class="goBackButton"><img src="~@/assets/images/undo.png" height="20" width="20">Назад</button>-->
    <div style="padding: 20px 16px">
      <div class="attention_notification">
        <div class="attention-notification-text-box">
          <h4 class="body-text-roboto-medium">
            Внимание! В настоящее время услуга учёта посещения для Вашей школы не подключена.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsSchoolAttendance',
  methods: {
    goToHome () {
      this.$router.push('./home')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
}
.attention_notification {
  margin: 41px 400px;
}
@media (max-aspect-ratio: 6/5) {
  .attention_notification {
    margin: 20px 16px;
  }
}
</style>
