<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="12"
        sm="6"
      >
        <v-select
          v-model="selectedUserAllowedDistrict"
          :items="userAllowedDistrictsList"
          item-title="district.name"
          item-value="district.id"
          label="Район"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedMonth"
          :items="months"
          item-title="name"
          item-value="id"
          label="Месяц"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-select
          v-model="selectedYear"
          :items="years"
          item-title="name"
          item-value="id"
          label="Год"
        />
      </v-col>
      <v-col
        class="d-flex"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="handleSubmit()"
        >
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!isLoading && reportItemsCopy.length > 0"
        align="center"
      >
        <v-col>
          <v-table class="report-electrontalons">
            <template #default>
              <thead>
                <tr>
                  <th colspan="100%">
                    &nbsp;<br>
                    <v-select
                      v-model="tableColor"
                      :items="colors"
                      item-title="name"
                      item-value="id"
                      density="compact"
                      label="Отображать строки"
                      @update:model-value="tableChange"
                    />
                  </th>
                </tr>
                <tr>
                  <th
                    class="text-center"
                    rowspan="2"
                  >
                    Район
                    <v-select
                      v-model="tableDistrict"
                      :items="districtNames"
                      item-title="name"
                      item-value="id"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th rowspan="2">
                    Школа
                    <v-select
                      v-model="tablePlace"
                      :items="placeNames"
                      item-title="name"
                      item-value="id"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th rowspan="2" />
                  <th rowspan="2">
                    Кол-во льготников
                  </th>
                  <th rowspan="2">
                    Кол-во классов
                  </th>
                  <th rowspan="2">
                    Кол-во дней
                  </th>
                  <th rowspan="2">
                    План
                  </th>
                  <th colspan="2">
                    Сформировано талонов
                  </th>
                  <th colspan="2">
                    Подписано талонов
                  </th>
                  <th colspan="2">
                    Подписано классным руководителем
                  </th>
                </tr>
                <tr>
                  <th>КаисКро</th>
                  <th>Пустые</th>
                  <th>КаисКро</th>
                  <th>Пустые</th>
                  <th>КаисКро</th>
                  <th>Пустые</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(reportItem, i) in reportItemsCopy">
                  <tr
                    v-if="reportItem.visible == 1"
                    :key="i + '_1'"
                    :class="reportItem.class"
                  >
                    <td rowspan="2">
                      {{ reportItem.districtName }}
                    </td>
                    <td rowspan="2">
                      {{ reportItem.placeName }}
                    </td>
                    <td>
                      Начальные классы
                    </td>
                    <td>
                      {{ reportItem.minorClassUserCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonDayCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonDayCount * reportItem.minorClassCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonKaisKroCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonEmptyCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonKaisKroAcceptCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonEmptyAcceptCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonKaisKroTeacherAcceptCount }}
                    </td>
                    <td>
                      {{ reportItem.minorClassTalonEmptyTeacherAcceptCount }}
                    </td>
                  </tr>
                  <tr
                    v-if="reportItem.visible == 1"
                    :key="i + '_2'"
                    :class="reportItem.class"
                  >
                    <td>
                      Старшие классы
                    </td>
                    <td>
                      {{ reportItem.majorClassUserCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonDayCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonDayCount * reportItem.majorClassCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonKaisKroCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonEmptyCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonKaisKroAcceptCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonEmptyAcceptCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonKaisKroTeacherAcceptCount }}
                    </td>
                    <td>
                      {{ reportItem.majorClassTalonEmptyTeacherAcceptCount }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { report } from '@/shared/api/placeElectronTalonList/api-requests'
import { PlaceElectronTalonReportPost } from '@/shared/api/placeElectronTalonList/api-types'
import { AxiosError } from 'axios'

type BaseData = {
  tableColor: any;
  tableDistrict: any;
  tablePlace: any;
  placeElectronTalonReportPost: PlaceElectronTalonReportPost;
  months: any;
  headers: any;
  selectedMonth: number;
  years: any;
  selectedYear: number;
  searchTerm: string;
  userAllowedDistrictsCopy: any;
  selectedUserAllowedDistrict: any;
  userAllowedDistrictsList: any;
  isLoading: boolean;
  isPost: boolean;
  districts: any;
  reportItems: any;
  reportItemsCopy: any;
  districtNames: any;
  placeNames: any;
  colors: any;
}

export default defineComponent({
  components: {
  },
  data (): BaseData {
    return {
      tableColor: null,
      tableDistrict: null,
      tablePlace: null,
      placeElectronTalonReportPost: {
        districtId: 0,
        dateFrom: moment(moment().year() + '-' + moment().month() + '-01').format('YYYY-MM-DD'),
        dateTo: moment(moment().year() + '-' + moment().month() + '-01').add('months', 1).format('YYYY-MM-DD')
      },
      colors: [
        { id: null, name: 'Все' },
        { id: 0, name: 'Белый' },
        { id: 1, name: 'Красный' },
        { id: 2, name: 'Желтый' }
      ],
      months: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' }
      ],
      headers: [
        { text: 'Школа', align: 'start', value: 'placeName' },
        { text: 'Количество льготников', align: 'start', value: 'minorClassUserCount' },
        { text: 'Количество классов', align: 'start', value: 'minorClassCount' }
      ],
      selectedMonth: moment().month(),
      years: [
      ],
      selectedYear: moment().year(),
      searchTerm: '',
      userAllowedDistrictsCopy: [],
      userAllowedDistrictsList: [],
      selectedUserAllowedDistrict: null,
      isLoading: false,
      isPost: false,
      districts: [],
      reportItems: [],
      reportItemsCopy: [],
      districtNames: [],
      placeNames: []
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ userAllowedDistricts: 'userAllowedDistricts/userAllowedDistricts' })
  },
  methods: {
    ...mapActions('userAllowedDistricts', { userAllowedDistrictAll: 'all' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadPlaceElectronReport (placeElectronTalonReportPost: PlaceElectronTalonReportPost) {
      try {
        return await report(placeElectronTalonReportPost)
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {

      }
    },
    async handleSubmit () {
      this.reportItems = []
      this.reportItemsCopy = []
      this.isLoading = true
      try {
        this.placeElectronTalonReportPost = {
          districtId: this.selectedUserAllowedDistrict,
          dateFrom: moment(this.selectedYear + '-' + this.selectedMonth + '-01').format('YYYY-MM-DD'),
          dateTo: moment(this.selectedYear + '-' + this.selectedMonth + '-01').add('months', 1).format('YYYY-MM-DD')
        }
        this.reportItems = await this.loadPlaceElectronReport(this.placeElectronTalonReportPost)
        this.reportItems.sort((a: any, b: any) => (a.districtName > b.districtName) ? 1 : ((b.districtName > a.districtName) ? -1 : ((a.placeName > b.placeName) ? 1 : ((b.placeName > a.placeName) ? -1 : 0))))
        if (this.reportItems.length > 0) {
          this.reportItemsCopy = JSON.parse(JSON.stringify(this.reportItems))
          const tempDistrictNames: any = []
          const tempPlaceNames: any = []
          tempDistrictNames.push({ id: null, name: 'Все' })
          tempPlaceNames.push({ id: null, name: 'Все' })
          this.reportItemsCopy.forEach((element: any) => {
            element.minorType = 0
            element.majorType = 0
            element.visible = 1
            if (element.minorClassTalonCount === 0) {
              element.minorType = 1
            } else if ((element.minorClassCount * element.minorClassTalonDayCount) > 0) {
              if (((element.minorClassTalonCount / (element.minorClassCount * element.minorClassTalonDayCount)) * 100) < 30) {
                element.minorType = 1
              } else if (((element.minorClassTalonCount / (element.minorClassCount * element.minorClassTalonDayCount)) * 100) < 50) {
                element.minorType = 2
              }
            }
            if (element.majorClassTalonCount === 0) {
              element.majorType = 1
            } else if ((element.majorClassCount * element.majorClassTalonDayCount) > 0) {
              if (((element.majorClassTalonCount / (element.majorClassCount * element.majorClassTalonDayCount)) * 100) < 30) {
                element.majorType = 1
              } else if (((element.majorClassTalonCount / (element.majorClassCount * element.majorClassTalonDayCount)) * 100) < 50) {
                element.majorType = 2
              }
            }
            if (element.minorType === 1 || element.majorType === 1) {
              element.classType = 1
              element.class = 'error-row-1'
            } else if (element.minorType === 2 || element.majorType === 2) {
              element.classType = 2
              element.class = 'error-row-2'
            } else {
              element.classType = 0
              element.class = ''
            }
            tempDistrictNames.push({ id: element.districtId, name: element.districtName })
            tempPlaceNames.push({ id: element.placeId, name: element.placeName })
          })
          this.districtNames = tempDistrictNames.filter((val: any, ind: any, arr: any) => arr.indexOf(val) === ind)
          this.placeNames = tempPlaceNames.filter((val: any, ind: any, arr: any) => arr.indexOf(val) === ind)
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadUserAllowedDistricts () {
      try {
        console.log()
        await this.userAllowedDistrictAll()
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.userAllowedDistrictsList = this.userAllowedDistricts.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedDistrictsCopy = this.userAllowedDistricts.filter((obj: any) => {
          return obj.removed == null
        })
        this.userAllowedDistrictsList.sort((a: any, b: any) => (a.district.name > b.district.name) ? 1 : ((b.district.name > a.district.name) ? -1 : 0))
        this.userAllowedDistrictsCopy.sort((a: any, b: any) => (a.district.name > b.district.name) ? 1 : ((b.district.name > a.district.name) ? -1 : 0))
        if (Number(this.user.person.userType) === 30) {
          this.userAllowedDistrictsList.unshift({ id: null, district: { id: null, name: 'Все' } })
          this.userAllowedDistrictsCopy.unshift({ id: null, district: { id: null, name: 'Все' } })
        }
        if (this.userAllowedDistrictsList.length > 0) this.selectedUserAllowedDistrict = this.userAllowedDistrictsList[0].district.id
      }
    },
    searchUserAllowedDistricts () {
      if (!this.searchTerm) {
        this.userAllowedDistrictsList = this.userAllowedDistrictsCopy
      }

      this.userAllowedDistrictsList = this.userAllowedDistrictsCopy.filter((userDistrictCopy: any) => {
        return userDistrictCopy.district.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    tableChange () {
      this.reportItemsCopy.forEach((element: any) => {
        let visible = 0
        if (this.tablePlace === null && this.tableDistrict === null && this.tableColor === null) {
          visible = 1
        }

        if (this.tablePlace === null && this.tableDistrict === null && this.tableColor === element.classType) {
          visible = 1
        }

        if (this.tablePlace === element.placeId && this.tableDistrict === null && this.tableColor === null) {
          visible = 1
        }

        if (this.tableDistrict === element.districtId && this.tablePlace === null && this.tableColor === null) {
          visible = 1
        }

        if (this.tablePlace === element.placeId && this.tableDistrict === element.districtId && this.tableColor === null) {
          visible = 1
        }

        if (this.tablePlace === element.placeId && this.tableDistrict === null && this.tableColor === element.classType) {
          visible = 1
        }

        if (this.tablePlace === null && this.tableDistrict === element.districtId && this.tableColor === element.classType) {
          visible = 1
        }

        if (this.tablePlace === element.placeId && this.tableDistrict === element.districtId && this.tableColor === element.classType) {
          visible = 1
        }

        element.visible = visible
      })
    }
  },
  mounted () {
    for (let i = 5; i >= 0; i--) {
      this.years.push({ id: Number(moment().year()) - i, name: String(Number(moment().year()) - i) })
    }
    this.loadUserAllowedDistricts()
  }
})
</script>

<style lang="scss">
</style>
