<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <place-selector-multiple
          v-model="selectedUserAllowedPlace"
          :selected-items-props="userAllowedPlacesList"
          @on-change="placeChange()"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <month-selector
          v-model="selectedMonth"
          :current-prop="true"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <year-selector
          v-model="selectedYear"
          :add-deep-years-prop="4"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <category-selector
          :customer-id-props="selectedCustomer"
          @onChange="categorySelectorChangeHandler"
          @category-select="createReport"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <simple-selector
          v-model="selectedAgeType"
          :items-prop="ageTypes"
          label-prop="Возрастная категория"
          value-prop="1"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <simple-selector
          v-model="selectedProductType"
          :items-prop="productTypes"
          label-prop="Вид питания"
          value-prop="1"
        />
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <div>
          <v-checkbox
            v-model="onlyHundred"
            label="Только 100%"
            hide-details
          />
          <v-checkbox
            v-model="splitByClasses"
            label="Разбить по классам"
            hide-details
          />
          <v-checkbox
            v-model="disableFoodless"
            label="Исключить непитавшихся"
            hide-details
          />
        </div>
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <simple-selector
          v-model="selectedRateType"
          :items-prop="rateTypes"
          label-prop="Ставка"
          value-prop="1"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-btn
          v-if="!isLoading"
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <template v-if="!isLoading && users.length > 0">
        <v-col
          v-if="!isLoading && isPost"
          class="d-flex"
        >
          <v-radio-group
            v-model="printDateType"
            label="Выводить на печать: "
          >
            <v-radio
              name="printDateType"
              label="Последнее число месяца"
              :value="2"
            />
            <v-radio
              name="printDateType"
              label="Первое число месяца"
              :value="1"
            />
            <v-radio
              name="printDateType"
              label="Фактическая дата"
              :value="3"
            />
          </v-radio-group>
        </v-col>
        <v-col
          v-if="!isLoading && isPost"
          class="d-flex"
        >
          <v-btn
            block
            @click="print()"
          >
            Печать
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <v-row class="justify-content-center">
      <v-col
        v-if="isLoading"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
    </v-row>
    <div
      v-if="!isLoading && users.length > 0"
      id="printableArea"
    >
      <br>
      <center>
        <div style="width: 90%;">
          <div v-if="reportSplitByClasses == false">
            <table style="width:100%;font-family:Arial;">
              <tbody>
                <tr>
                  <td style="width:20%">
&nbsp;
                  </td>
                  <td style="width:80%">
                    <table
                      style="border-collapse: collapse; width:100%; border:none;"
                      class="tav"
                    >
                      <tbody>
                        <tr>
                          <td
                            style="text-align: center;font-weight:bolder;letter-spacing:3px !important; font-size:12px;"
                          >
                            ТАБЕЛЬ
                          </td>
                          <td />
                          <td style="border:1px solid black;text-align:center;font-size:12px;">
                            КОДЫ
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-if="selectedAgeType !== 3"
                            style="text-align: center;font-size:12px;"
                          >
                            УЧЕТА ПОСЕЩАЕМОСТИ
                            ДЕТЕЙ
                          </td>
                          <td
                            v-else
                            style="text-align: center;font-size:12px;"
                          >
                            УЧЕТА ПОСЕЩАЕМОСТИ ОБУЧАЮЩИХСЯ ОРГАНИЗАЦИИ
                          </td>
                          <td style="padding-right:10px;text-align:right;font-size:12px;">
                            Форма по ОКУД
                          </td>
                          <td style="border:2px solid black;text-align:center;font-size:12px;">
                            0504608
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: center;font-size:12px;">
                            за <u>{{ printMontheName }}</u>
                            {{ getYearFirstName() }}<u>{{ getYearLastName() }}</u> г.
                          </td>
                          <td style="padding-right:10px;text-align:right;font-size:12px;">
                            Дата
                          </td>
                          <td style="border:2px solid black;text-align:center;font-size:12px;">
                            {{ getCodeDate() }}
                          </td>
                        </tr>
                        <tr>
                          <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                            <div class="lable">
                              Учреждение
                            </div>
                            <div class="underlined-container">
                              <span
                                class="underlined-content"
                                style="bottom:0;"
                              >&nbsp;&nbsp;&nbsp;&nbsp;{{ customerPlaceInfo.fullName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </div>
                          </td>
                          <td style="padding-right:10px;text-align:right;font-size:12px;">
                            по ОКПО
                          </td>
                          <td style="border:2px solid black;border-collapse: collapse;text-align:center;font-size:12px;">
                            {{ customerPlaceInfo.okpo }}
                          </td>
                        </tr>
                        <tr>
                          <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                            <nobr>
                              <div class="lable">
                                Структурное подразделение
                              </div>
                              <div class="underlined-container">
                                <span
                                  class="underlined-content"
                                  style="bottom:0;"
                                >&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  getStructure()
                                }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </div>
                            </nobr>
                          </td>
                          <td />
                          <td style="border:2px solid black;border-collapse: collapse;text-align:center;" />
                        </tr>
                        <tr>
                          <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                            <nobr>
                              <div class="lable">
                                Вид расчета
                              </div>
                              <div class="underlined-container">
                                <span
                                  class="underlined-content"
                                  style="bottom:0;"
                                >&nbsp;&nbsp;&nbsp;&nbsp;{{ getRatePlanFoodType() }}</span>
                              </div>
                            </nobr>
                          </td>
                          <td />
                          <td style="border:2px solid black;border-collapse: collapse;text-align:center;" />
                        </tr>
                        <tr>
                          <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                            <nobr>
                              <div class="lable">
                                Режим работы
                              </div>
                              <div class="underlined-container">
                                <span
                                  class="underlined-content"
                                  style="bottom:0;"
                                >&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  getWorkType()
                                }}</span>
                              </div>
                            </nobr>
                          </td>
                          <td />
                          <td style="border:2px solid black;border-collapse: collapse;text-align:center;" />
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="reports-tables-byocud"
              style="width:100% !important;"
            >
              <thead>
                <tr class="hidden-print">
                  <th
                    class="bg-green-darken-4 text-center text-white"
                    colspan="100%"
                  >
                    Предварительный просмотр табеля
                  </th>
                </tr>
                <tr>
                  <th
                    class="text-center"
                    rowspan="3"
                  >
                    Фамилия, имя<br>ребенка
                  </th>
                  <th
                    class="text-center"
                    rowspan="3"
                  >
                    Номер<br>счета
                  </th>
                  <th
                    class="text-center"
                    rowspan="3"
                  >
                    Плата<br>по<br>ставке
                  </th>
                  <th
                    class="text-center"
                    :colspan="days.length"
                  >
                    Дни посещения
                  </th>
                  <th
                    class="text-center"
                    colspan="2"
                    rowspan="2"
                  >
                    Пропущено<br>дней
                  </th>
                  <th
                    class="text-center"
                    rowspan="3"
                  >
                    Дни<br>посеще-<br>ния, под-<br>лежащие<br>оплате
                  </th>
                  <th
                    class="text-center"
                    rowspan="3"
                  >
                    Причины<br>непосещения<br>(основание)
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(day, i) in days"
                    :key="i"
                    class="text-center"
                    rowspan="2"
                  >
                    {{ day.id }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center">
                    всего
                  </th>
                  <th class="text-center">
                    в том<br>числе<br>засчи-<br>тыва-<br>емых
                  </th>
                </tr>
                <tr>
                  <th class="text-center">
                    1
                  </th>
                  <th class="text-center border-solid-bottom">
                    2
                  </th>
                  <th class="text-center border-solid-bottom">
                    3
                  </th>
                  <th
                    v-for="(day, i) in days"
                    :key="i"
                    class="text-center"
                  >
                    {{ 3 + Number(day.id) }}
                  </th>
                  <th class="text-center border-solid-bottom">
                    {{ days.length + 4 }}
                  </th>
                  <th class="text-center border-solid-bottom">
                    {{ days.length + 5 }}
                  </th>
                  <th class="text-center border-solid-bottom">
                    {{ days.length + 6 }}
                  </th>
                  <th class="text-center">
                    {{ days.length + 7 }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(user, i) in users"
                  :key="i"
                >
                  <tr>
                    <td
                      style="font-size: 12px !important; padding-left: 2px !important; padding-right: 2px !important;"
                    >
                      <nobr>
                        {{ i + 1 }}. {{ getCredentials(user) }} {{ user.userDescription }}
                        {{ getPlaceName(user.userPlace) }}
                      </nobr>
                    </td>
                    <td class="text-center border-solid-left">
                      {{ getPersonalAccount(user) }}
                    </td>
                    <td class="text-center border-solid-right">
                      {{ user.userPercent }}
                    </td>
                    <td
                      v-for="(value, j) in user.values"
                      :key="j"
                      class="text-center"
                      style="min-width: 20px !important; width: 30px !important;"
                    >
                      {{ value.value }}
                    </td>
                    <td
                      class="text-center border-solid-left"
                      style="font-size: 12px !important; min-width: 50px !important;"
                    >
                      {{ getUserDayCount(user, 'Н') }}
                    </td>
                    <td
                      class="text-center border-solid-right"
                      style="font-size: 12px !important; min-width: 60px !important;"
                    >
                      {{ getUserDayCount(user, 'Н') }}
                    </td>
                    <td
                      class="text-center border-solid-right"
                      style="font-size: 12px !important; min-width: 130px !important;"
                    >
                      {{ getUserDayCount(user, '') }}
                    </td>
                    <td
                      class="text-center"
                      style="min-width: 30px !important;"
                    >
                      -
                    </td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <td class="bottom-table">
&nbsp;
                  </td>
                  <td class="bottom-table border-solid-top">
&nbsp;
                  </td>
                  <td class="bottom-table border-solid-top border-thin-right">
&nbsp;
                  </td>
                  <td
                    v-for="(day, i) in days"
                    :key="i"
                    class="bottom-table"
                  >
                  &nbsp;
                  </td>
                  <td class="bottom-table">
&nbsp;
                  </td>
                  <td class="bottom-table">
&nbsp;
                  </td>
                  <td class="bottom-table border-solid-right">
&nbsp;
                  </td>
                  <td class="bottom-table">
&nbsp;
                  </td>
                </tr>
                <tr>
                  <th
                    class="text-right border-none"
                    style="padding-right: 2px !important"
                    colspan="3"
                  >
                    Всего присутствует<br>детей
                  </th>
                  <th
                    v-for="(day, i) in days"
                    :key="i"
                    class="text-center"
                  >
                    {{ getDaySales(day.id) }}
                  </th>
                  <th class="border-solid-bottom border-solid-left">
                    {{ getAllUserDayCount('Н') }}
                  </th>
                  <th class="border-solid-bottom">
                    {{ getAllUserDayCount('Н') }}
                  </th>
                  <th class="border-solid-bottom border-solid-right">
                    {{ getAllUserDayCount('') }}
                  </th>
                </tr>
              </tfoot>
            </table>
            <br>
            <table style="font-size: 12px; width: 100%; border:none;font-family:Arial; page-break-inside: avoid;">
              <tbody>
                <tr>
                  <td style="font-size:12px; max-width: 200px;">
                    Руководитель учреждения
                  </td>
                  <td
                    style="font-size:12px;"
                    align="center"
                  >
                    <u>{{ customerPlaceInfo.directorPosition }}</u>
                  </td>
                  <td align="center">
                    ______________
                  </td>
                  <td
                    style="font-size:12px;"
                    align="center"
                  >
                    <u>{{ customerPlaceInfo.directorName }}</u>
                  </td>
                  <td style="font-size:12px;" />
                  <td style="font-size:12px;">
                    Ответственный
                  </td>
                  <td
                    style="font-size:12px;"
                    align="center"
                  >
                    <u>Ответственный по питанию</u>
                  </td>
                  <td align="center">
                    ______________
                  </td>
                  <td
                    style="font-size:12px;"
                    align="center"
                  >
                    <u>{{ customerPlaceInfo.ladminName }}</u>
                  </td>
                </tr>
                <tr>
                  <td style="font-size:12px;">
                    (ответственный исполнитель)
                  </td>
                  <td
                    style="font-size:9px;"
                    valign="top"
                    align="center"
                  >
                    (должность)
                  </td>
                  <td
                    style="font-size:9px;"
                    valign="top"
                    align="center"
                  >
                    (подпись)
                  </td>
                  <td
                    style="font-size:9px;"
                    valign="top"
                    align="center"
                  >
                    (расшифровка подписи)
                  </td>
                  <td style="font-size:12px;" />
                  <td style="font-size:12px;">
                    исполнитель
                  </td>
                  <td
                    style="font-size:9px;"
                    valign="top"
                    align="center"
                  >
                    (должность)
                  </td>
                  <td
                    style="font-size:9px;"
                    valign="top"
                    align="center"
                  >
                    (подпись)
                  </td>
                  <td
                    style="font-size:9px;"
                    valign="top"
                    align="center"
                  >
                    (расшифровка подписи)
                  </td>
                </tr>
                <tr>
                  <td colspan="100%">
                    <br>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size:12px;"
                    valign="top"
                    align="center"
                  >
                    <br>"<u>{{
                      getDay()
                    }}</u>"______<u>{{ getMonth() }}</u>_____{{ getYearFirstName() }}<u>{{ getYearLastName() }}</u>г.
                  </td>
                  <td colspan="8" />
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <template
              v-for="(schoolclass, sti) in classes"
              :key="sti + '_5'"
            >
              <center
                style="page-break-after: always;"
              >
                <table
                  :key="sti + '_1'"
                  style="width:100%;font-family:Arial;"
                >
                  <tbody>
                    <tr>
                      <td style="width:20%">
&nbsp;
                      </td>
                      <td style="width:80%">
                        <table
                          style="border-collapse: collapse; width:100%; border:none;"
                          class="tav"
                        >
                          <tbody>
                            <tr>
                              <td
                                style="text-align: center;font-weight:bolder;letter-spacing:3px !important; font-size:12px;"
                              >
                                ТАБЕЛЬ
                              </td>
                              <td />
                              <td style="border:1px solid black;text-align:center;font-size:12px;">
                                КОДЫ
                              </td>
                            </tr>
                            <tr>
                              <td
                                v-if="selectedAgeType !== 3"
                                style="text-align: center;font-size:12px;"
                              >
                                УЧЕТА ПОСЕЩАЕМОСТИ
                                ДЕТЕЙ
                              </td>
                              <td
                                v-else
                                style="text-align: center;font-size:12px;"
                              >
                                УЧЕТА ПОСЕЩАЕМОСТИ ОБУЧАЮЩИХСЯ
                                ОРГАНИЗАЦИИ
                              </td>
                              <td style="padding-right:10px;text-align:right;font-size:12px;">
                                Форма по ОКУД
                              </td>
                              <td style="border:2px solid black;text-align:center;font-size:12px;">
                                0504608
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: center;font-size:12px;">
                                за <u>{{ printMontheName }}</u>
                                {{ getYearFirstName() }}<u>{{ getYearLastName() }}</u> г.
                              </td>
                              <td style="padding-right:10px;text-align:right;font-size:12px;">
                                Дата
                              </td>
                              <td style="border:2px solid black;text-align:center;font-size:12px;">
                                {{
                                  getCodeDate()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                                <div class="lable">
                                  Учреждение
                                </div>
                                <div class="underlined-container">
                                  <span
                                    class="underlined-content"
                                    style="bottom:0;"
                                  >&nbsp;&nbsp;&nbsp;&nbsp;{{ customerPlaceInfo.fullName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                </div>
                              </td>
                              <td style="padding-right:10px;text-align:right;font-size:12px;">
                                по ОКПО
                              </td>
                              <td
                                style="border:2px solid black;border-collapse: collapse;text-align:center;font-size:12px;"
                              >
                                {{ customerPlaceInfo.okpo }}
                              </td>
                            </tr>
                            <tr>
                              <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                                <nobr>
                                  <div class="lable">
                                    Структурное подразделение
                                  </div>
                                  <div class="underlined-container">
                                    <span
                                      class="underlined-content"
                                      style="bottom:0;"
                                    >&nbsp;&nbsp;&nbsp;&nbsp;{{ schoolclass.name }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </div>
                                </nobr>
                              </td>
                              <td />
                              <td style="border:2px solid black;border-collapse: collapse;text-align:center;" />
                            </tr>
                            <tr>
                              <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                                <nobr>
                                  <div class="lable">
                                    Вид расчета
                                  </div>
                                  <div class="underlined-container">
                                    <span
                                      class="underlined-content"
                                      style="bottom:0;"
                                    >&nbsp;&nbsp;&nbsp;&nbsp;{{ getRatePlanFoodType() }}</span>
                                  </div>
                                </nobr>
                              </td>
                              <td />
                              <td style="border:2px solid black;border-collapse: collapse;text-align:center;" />
                            </tr>
                            <tr>
                              <td style="overflow:hidden;white-space: nowrap;font-size:12px;">
                                <nobr>
                                  <div class="lable">
                                    Режим работы
                                  </div>
                                  <div class="underlined-container">
                                    <span
                                      class="underlined-content"
                                      style="bottom:0;"
                                    >&nbsp;&nbsp;&nbsp;&nbsp;{{ schoolclass.weekendType }}</span>
                                  </div>
                                </nobr>
                              </td>
                              <td />
                              <td style="border:2px solid black;border-collapse: collapse;text-align:center;" />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  :key="sti + '_2'"
                  class="reports-tables-byocud"
                  style="width:100% !important;"
                >
                  <thead>
                    <tr class="hidden-print">
                      <th
                        class="bg-green-darken-4 text-center text-white"
                        colspan="100%"
                      >
                        Предварительный просмотр табеля
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-center"
                        rowspan="3"
                      >
                        Фамилия, имя<br>ребенка
                      </th>
                      <th
                        class="text-center"
                        rowspan="3"
                      >
                        Номер<br>счета
                      </th>
                      <th
                        class="text-center"
                        rowspan="3"
                      >
                        Плата<br>по<br>ставке
                      </th>
                      <th
                        class="text-center"
                        :colspan="days.length"
                      >
                        Дни посещения
                      </th>
                      <th
                        class="text-center"
                        colspan="2"
                        rowspan="2"
                      >
                        Пропущено<br>дней
                      </th>
                      <th
                        class="text-center"
                        rowspan="3"
                      >
                        Дни<br>посеще-<br>ния, под-<br>лежащие<br>оплате
                      </th>
                      <th
                        class="text-center"
                        rowspan="3"
                      >
                        Причины<br>непосещения<br>(основание)
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="(day, i) in days"
                        :key="i"
                        class="text-center"
                        rowspan="2"
                      >
                        {{ day.id }}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center">
                        всего
                      </th>
                      <th class="text-center">
                        в том<br>числе<br>засчи-<br>тыва-<br>емых
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center">
                        1
                      </th>
                      <th class="text-center border-solid-bottom">
                        2
                      </th>
                      <th class="text-center border-solid-bottom">
                        3
                      </th>
                      <th
                        v-for="(day, i) in days"
                        :key="i"
                        class="text-center"
                      >
                        {{ 3 + Number(day.id) }}
                      </th>
                      <th class="text-center border-solid-bottom">
                        {{ days.length + 4 }}
                      </th>
                      <th class="text-center border-solid-bottom">
                        {{ days.length + 5 }}
                      </th>
                      <th class="text-center border-solid-bottom">
                        {{ days.length + 6 }}
                      </th>
                      <th class="text-center">
                        {{ days.length + 7 }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(user, i) in users.filter((user: any) => user.userPlace.id === schoolclass.id)"
                      :key="i"
                    >
                      <tr>
                        <td
                          style="font-size: 12px !important; padding-left: 2px !important; padding-right: 2px !important;"
                        >
                          <nobr>{{ i + 1 }}. {{ getCredentials(user) }} {{ user.userDescription }}</nobr>
                        </td>
                        <td class="text-center border-solid-left">
                          {{ getPersonalAccount(user) }}
                        </td>
                        <td class="text-center border-solid-right">
                          {{ user.userPercent }}
                        </td>
                        <td
                          v-for="(value, j) in user.values"
                          :key="j"
                          class="text-center"
                          style="min-width: 20px !important; width: 30px !important;"
                        >
                          {{ value.value }}
                        </td>
                        <td
                          class="text-center border-solid-left"
                          style="font-size: 12px !important; min-width: 50px !important;"
                        >
                          {{
                            getUserDayCount(user, 'Н')
                          }}
                        </td>
                        <td
                          class="text-center border-solid-right"
                          style="font-size: 12px !important; min-width: 60px !important;"
                        >
                          {{
                            getUserDayCount(user, 'Н')
                          }}
                        </td>
                        <td
                          class="text-center border-solid-right"
                          style="font-size: 12px !important; min-width: 130px !important;"
                        >
                          {{
                            getUserDayCount(user, '')
                          }}
                        </td>
                        <td
                          class="text-center"
                          style="min-width: 30px !important;"
                        >
                          -
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="bottom-table">
&nbsp;
                      </td>
                      <td class="bottom-table border-solid-top">
&nbsp;
                      </td>
                      <td class="bottom-table border-solid-top border-thin-right">
&nbsp;
                      </td>
                      <td
                        v-for="(day, i) in days"
                        :key="i"
                        class="bottom-table"
                      >
                      &nbsp;
                      </td>
                      <td class="bottom-table">
&nbsp;
                      </td>
                      <td class="bottom-table">
&nbsp;
                      </td>
                      <td class="bottom-table border-solid-right">
&nbsp;
                      </td>
                      <td class="bottom-table">
&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-right border-none"
                        style="padding-right: 2px !important"
                        colspan="3"
                      >
                        Всего присутствует<br>детей
                      </th>
                      <th
                        v-for="(day, i) in days"
                        :key="i"
                        class="text-center"
                      >
                        {{ getDaySales(day.id, schoolclass.id) }}
                      </th>
                      <th class="border-solid-bottom border-solid-left">
                        {{ getAllUserDayCount('Н', schoolclass.id) }}
                      </th>
                      <th class="border-solid-bottom">
                        {{ getAllUserDayCount('Н', schoolclass.id) }}
                      </th>
                      <th class="border-solid-bottom border-solid-right">
                        {{ getAllUserDayCount('', schoolclass.id) }}
                      </th>
                    </tr>
                  </tfoot>
                </table>
                <br :key="sti + '_4'">
                <table
                  :key="sti + '_3'"
                  style="font-size: 12px; width: 100%; border:none;font-family:Arial; page-break-inside: avoid;"
                >
                  <tbody>
                    <tr>
                      <td style="font-size:12px; max-width: 200px;">
                        Руководитель учреждения
                      </td>
                      <td
                        style="font-size:12px;"
                        align="center"
                      >
                        <u>{{ customerPlaceInfo.directorPosition }}</u>
                      </td>
                      <td align="center">
                        ______________
                      </td>
                      <td
                        style="font-size:12px;"
                        align="center"
                      >
                        <u>{{ customerPlaceInfo.directorName }}</u>
                      </td>
                      <td style="font-size:12px;" />
                      <td style="font-size:12px;">
                        Ответственный
                      </td>
                      <td
                        style="font-size:12px;"
                        align="center"
                      >
                        <u>Ответственный по питанию</u>
                      </td>
                      <td align="center">
                        ______________
                      </td>
                      <td
                        style="font-size:12px;"
                        align="center"
                      >
                        <u>{{ customerPlaceInfo.ladminName }}</u>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size:12px;">
                        (ответственный исполнитель)
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (должность)
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (подпись)
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (расшифровка подписи)
                      </td>
                      <td style="font-size:12px;" />
                      <td style="font-size:12px;">
                        исполнитель
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (должность)
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (подпись)
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (расшифровка подписи)
                      </td>
                    </tr>
                    <tr>
                      <td colspan="100%">
                        <br>
                      </td>
                    </tr>
                    <tr style="font-size:12px;">
                      <td style="font-size:12px;">
                        Классный<br>руководитель
                      </td>
                      <td align="center">
                        ______________
                      </td>
                      <td
                        v-if="getTeacher(schoolclass.id) != null"
                        align="center"
                      >
                        <u>{{ getTeacher(schoolclass.id) }}</u>
                      </td>
                      <td
                        v-else
                        align="center"
                      >
                        ______________
                      </td>
                      <td />
                      <td style="font-size:12px;" />
                      <td
                        style="font-size:12px;"
                        colspan="2"
                      >
                        "<u>{{ getDay() }}</u>"______<u>{{
                          getMonth()
                        }}</u>_____{{ getYearFirstName() }}<u>{{ getYearLastName() }}</u>г.
                      </td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (подпись)
                      </td>
                      <td
                        style="font-size:9px;"
                        valign="top"
                        align="center"
                      >
                        (расшифровка подписи)
                      </td>
                      <td />
                      <td style="font-size:12px;" />
                      <td colspan="2" />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td
                        style="font-size:12px;"
                        valign="top"
                        align="center"
                      >
                        <br>"<u>{{
                          getDay()
                        }}</u>"______<u>{{ getMonth() }}</u>_____{{ getYearFirstName() }}<u>{{ getYearLastName() }}</u>г.
                      </td>
                      <td colspan="8" />
                    </tr>
                  </tbody>
                </table>
              </center>
            </template>
          </div>
        </div>
      </center>
    </div>
    <template v-else-if="days.length > 0 && !isLoading">
      <div
        class="wrapper"
        :style="{display: 'flex', justifyContent: 'center'}"
      >
        <h3>По заданным фильтрам записей не найдено</h3>
      </div>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import SimpleSelector from '@/shared/uikit/selectors/SimpleSelector.vue'
import CategorySelector from '@/shared/uikit/selectors/CategorySelector.vue'
import MonthSelector from '@/shared/uikit/selectors/MonthSelector.vue'
import YearSelector from '@/shared/uikit/selectors/YearSelector.vue'
import { mapActions, mapGetters } from 'vuex'
import { findForTableByOcudAndPlacesRequest } from '@/shared/api/salelist/api-types'
import { findForTableByOcudAndPlaces } from '@/shared/api/salelist/api-requests'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { find, findByPlace } from '@/shared/api/customerPlace/api-requests'
import { PlaceCustomerProductSearchPost } from '@/shared/api/placeCustomerProductList/api-types'
import { AxiosError } from 'axios'
import { printer } from '@/shared/lib/print'
import PlaceSelectorMultiple from '@/shared/uikit/selectors/PlaceSelectorMultiple.vue'

type BaseData = {
  reportAgeType: any;
  productAgeGroups: any;
  userAllowedGroups: any;
  classes: any;
  ageTypes: any;
  selectedAgeType: any;
  reportProductId: any;
  reportRatePlanId: any;
  weekType: any;
  selectedRateType: any;
  rateTypes: any;
  reportSplitByClasses: boolean;
  splitByClasses: boolean;
  disableFoodless: boolean;
  onlyHundred: boolean;
  productIdArray: any;
  placeCustomerProductSearchPost: PlaceCustomerProductSearchPost;
  placeCustomerProducts: any;
  daySales: any;
  classDaySales: any;
  placeWeekends: any;
  printDateType: number;
  days: any;
  searchTerm: any;
  users: any;
  customers: any;
  ratePlans: any;
  productTypes: any;
  selectedCustomer: any;
  selectedProductType: any;
  sales: any;
  selectedMonth: any;
  selectedYear: any;
  reportMonth: any;
  reportYear: any;
  printMontheName: string;
  isLoading: boolean;
  isPost: boolean;
  userAllowedPlacesList: any;
  selectedUserAllowedPlace: any;
  userAllowedPlacesCopy: any;
  separateClasses: boolean;
  splitReport: boolean;
  salesGetPost: findForTableByOcudAndPlacesRequest;
  customerPlacePost: CustomerPlaceFindPost;
  customerPlaceInfo: any;
  places: any;
  products: any;
  productRatePlanSales: any;
}

export default defineComponent({
  name: 'MinorTable',
  components: {
    PlaceSelectorMultiple,
    SimpleSelector,
    CategorySelector,
    YearSelector,
    MonthSelector
  },
  data (): BaseData {
    return {
      productAgeGroups: [
        { id: 1, ageGroup: 0, ratePlanDiscountTypeId: 0 },
        { id: 2, ageGroup: 0, ratePlanDiscountTypeId: 1 },
        { id: 3, ageGroup: 1052, ratePlanDiscountTypeId: 1 },
        { id: 4, ageGroup: 1052, ratePlanDiscountTypeId: 1 },
        { id: 130, ageGroup: 0, ratePlanDiscountTypeId: 1 },
        { id: 9, ageGroup: 1052, ratePlanDiscountTypeId: 1 },
        { id: 461, ageGroup: 0, ratePlanDiscountTypeId: 1 },
        { id: 462, ageGroup: 1052, ratePlanDiscountTypeId: 1 },
        { id: 463, ageGroup: 0, ratePlanDiscountTypeId: 1 },
        { id: 464, ageGroup: 1052, ratePlanDiscountTypeId: 1 },
        { id: 465, ageGroup: 0, ratePlanDiscountTypeId: 1 },
        { id: 466, ageGroup: 1052, ratePlanDiscountTypeId: 1 }
      ],
      userAllowedGroups: [],
      classes: [],
      ageTypes: [
        { id: 1, name: 'Младшие' },
        { id: 2, name: 'Старшие' },
        { id: 3, name: 'Все' }
      ],
      selectedAgeType: 1,
      reportAgeType: 1,
      reportProductId: null,
      reportRatePlanId: null,
      weekType: 'шестидневка',
      selectedRateType: 1,
      rateTypes: [
        { id: 1, name: '%' },
        { id: 2, name: 'Сумма' }
      ],
      reportSplitByClasses: false,
      splitByClasses: false,
      disableFoodless: false,
      onlyHundred: false,
      productIdArray: [],
      placeCustomerProductSearchPost: {
        placeId: 0,
        date: new Date().toISOString().substr(0, 10)
      },
      placeCustomerProducts: [],
      daySales: [],
      classDaySales: [],
      placeWeekends: [],
      printDateType: 1,
      days: [],
      searchTerm: '',
      users: [],
      customers: [],
      ratePlans: {
        categories: [], selectedCategory: 0
      },
      productTypes: [
        { id: 1, name: 'Завтрак' },
        { id: 2, name: 'Обед' },
        { id: 3, name: 'Обед старший' },
        { id: 4, name: 'Завтрак ст' },
        { id: 130, name: 'Обед младший' },
        { id: 9, name: 'Обед ст' },
        { id: 461, name: 'Завтрак К мл' },
        { id: 462, name: 'Завтрак К ст' },
        { id: 463, name: 'Обед К мл' },
        { id: 464, name: 'Обед К ст' },
        { id: 465, name: 'Обед К мл компл.' },
        { id: 466, name: 'Обед К ст компл.' }
      ],
      selectedCustomer: null,
      selectedProductType: 1,
      sales: [],
      isLoading: false,
      isPost: false,
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      userAllowedPlacesCopy: [],
      separateClasses: false,
      splitReport: false,
      salesGetPost: {
        placeIds: [],
        dateFrom: '',
        dateTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      customerPlaceInfo: null,
      places: [],
      products: [],
      selectedMonth: { id: 1, name: 'Январь'},
      selectedYear: 0,
      productRatePlanSales: [],
      reportMonth: null,
      printMontheName: '',
      reportYear: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  beforeMount () {
    this.selectedYear = moment().format('YYYY')
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async loadSales () {
      try {
        this.isLoading = true
        this.days = []
        this.daySales = []
        this.classDaySales = []
        this.placeWeekends = []
        this.placeCustomerProducts = []
        this.classes = []
        this.userAllowedGroups = []
        this.reportAgeType = this.selectedAgeType
        this.reportSplitByClasses = this.splitByClasses
        if (this.selectedUserAllowedPlace?.length > 0) {
          this.reportProductId = this.selectedProductType
          this.reportRatePlanId = this.ratePlans.selectedCategory
          this.salesGetPost.placeIds = this.selectedUserAllowedPlace
          this.salesGetPost.dateFrom = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('YYYY-MM-DD')
          this.salesGetPost.dateTo = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).format('YYYY-MM-DD')
          const startDay = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('D')
          const endDay = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).add('days', -1).format('D')
          for (let i = startDay; i <= endDay; i++) {
            this.days.push(
              {
                id: i
              }
            )
            this.daySales.push({
              id: i,
              value: 0
            })
          }
          this.reportMonth = this.selectedMonth.id
          this.reportYear = this.selectedYear
          const result = await findForTableByOcudAndPlaces(this.salesGetPost)
          const userSales = JSON.parse(JSON.stringify(result))
          if (userSales !== null) {
            const users = JSON.parse(JSON.stringify(userSales.users))
            const sales = JSON.parse(JSON.stringify(userSales.sales))
            const userPersonalAccounts = userSales.userPersonalAccounts
            const userDescriptions = userSales.userDescriptions
            const userRatePlans = userSales.userRatePlans
            const userDonations = userSales.userDonations
            const products = userSales.products
            const places = userSales.places
            this.placeWeekends = userSales.placeWeekends
            this.placeCustomerProducts = JSON.parse(JSON.stringify(userSales.products))
            this.userAllowedGroups = JSON.parse(JSON.stringify(userSales.userAllowedGroups))
            const classWeekends: any = []

            places.forEach((place: any) => {
              const weekends: any = []
              for (let i = startDay; i <= endDay; i++) {
                const weekend = this.getWeekendValue(place, i)
                weekends.push(
                  {
                    id: i,
                    value: weekend
                  }
                )
              }
              classWeekends.push(
                {
                  placeId: place.id,
                  weekends: weekends
                }
              )
            })
            places.forEach((place: any) => {
              for (let i = startDay; i <= endDay; i++) {
                if (moment(this.reportYear + '-' + this.reportMonth + '-' + i).day() === 6) {
                  const weekendType = this.getWeekend(place, i)
                  if (this.reportAgeType === 1) {
                    if (place.name.match(/^[1-4][^0-9].*|^[1-4]$/)) {
                      if (weekendType === 1 || weekendType === 2) {
                        this.weekType = 'пятидневка'
                      }
                    }
                  } else if (this.reportAgeType === 2) {
                    if (place.name.match(/^[5-9].*|^[5-9]$/)) {
                      if (weekendType === 1 || weekendType === 2) {
                        this.weekType = 'пятидневка'
                      }
                    }
                    if (place.name.match(/^[1-4][0-3].*$|^[1-4][0-3]$/)) {
                      if (weekendType === 1 || weekendType === 2) {
                        this.weekType = 'пятидневка'
                      }
                    }
                  }
                }
              }
            })
            users.forEach((user: any) => {
              user.tempSales = []
            })
            sales.forEach((sale: any) => {
              const userIndex = users.findIndex((user: any) => user.id === sale.userId)
              if (userIndex > -1) {
                users[userIndex].tempSales.push(sale)
              }
            })
            users.forEach((user: any) => {
              const tempUserRatePlans = userRatePlans.filter((userRatePlan: any) => userRatePlan.userId === user.id)
              const tempUserDonations = userDonations.filter((userDonation: any) => userDonation.userId === user.id)
              const dateRatePlans: any = []
              const currentRatePlans: any = []
              let currentUserRatePlans: any = []
              let currentUserDonations: any = []
              for (let i = startDay; i <= endDay; i++) {
                currentUserDonations = tempUserDonations.filter((userDonation: any) =>
                  moment(this.selectedYear + '-' + this.selectedMonth.id + '-' + i).isSameOrAfter(moment(userDonation.startTime)) &&
                  moment(this.selectedYear + '-' + this.selectedMonth.id + '-' + i).isBefore(moment(userDonation.endTime))
                )
                if (this.getUserDonation(currentUserDonations)) {
                  continue
                }

                currentUserRatePlans = tempUserRatePlans.filter((userRatePlan: any) =>
                  moment(this.selectedYear + '-' + this.selectedMonth.id + '-' + i).isSameOrAfter(moment(userRatePlan.startTime)) &&
                  moment(this.selectedYear + '-' + this.selectedMonth.id + '-' + i).isBefore(moment(userRatePlan.endTime))
                )
                if (currentUserRatePlans.length > 0) {
                  currentUserRatePlans.sort((a: any, b: any) => {
                    if (moment(a.creationDate).isSameOrAfter(moment(b.creationDate))) {
                      return -1
                    }
                    return 1
                  })
                  const curRatePlan = currentRatePlans.find((element: any) => Number(element.id) === Number(currentUserRatePlans[0].id))
                  if (curRatePlan === undefined) {
                    currentRatePlans.push(currentUserRatePlans[0])
                  }
                  dateRatePlans.push({
                    i: i,
                    ratePlanId: currentUserRatePlans[0].ratePlanId
                  })
                }
              }
              let userRatePlanId: any = null
              if (currentRatePlans.length > 0) {
                userRatePlanId = currentRatePlans[0].ratePlanId
              }
              if (this.ratePlans.selectedCategory !== 0) {
                const curRatePlan = currentRatePlans.find((element: any) => Number(element.ratePlanId) === Number(this.ratePlans.selectedCategory))
                if (curRatePlan !== undefined) {
                  userRatePlanId = curRatePlan.ratePlanId
                }
              }
              if ((userRatePlanId !== null) && (Number(user.typeId) === 2)) {
                if ((this.ratePlans.selectedCategory === 0) || (Number(this.ratePlans.selectedCategory) === Number(userRatePlanId))) {
                  let userPercent = null
                  if (this.selectedRateType === 1) {
                    userPercent = '100%'
                  }
                  let visible = 1
                  let currentUserPlace: any = null
                  const tempUserPersonalAccount = userPersonalAccounts.find((userPersonalAccount: any) => userPersonalAccount.userId === user.id)
                  const tempUserDescription = userDescriptions.find((userDescription: any) => userDescription.userId === user.id)
                  const tempUserPlaces = user.userPlaces
                  tempUserPlaces.forEach((userPlace: any) => {
                    const cps = places.filter((place: any) => {
                      return this.selectedUserAllowedPlace.includes(place.parentId) && userPlace.placeId === place.id
                    })
                    cps.forEach((element: any) => {
                      if (element.name !== null) {
                        if (element.name.match(/^[1-9].*|^[1-9]$/)) {
                          currentUserPlace = element
                        } else if (this.selectedAgeType === 3) {
                          currentUserPlace = element
                        }
                      }
                    })
                  })
                  if (currentUserPlace === null) {
                    visible = 0
                  }
                  let ageGroup = 0
                  if (currentUserPlace !== null) {
                    let tempCurrentUserPlace: any = {}
                    tempCurrentUserPlace = currentUserPlace
                    let nextStep = false
                    if (this.reportAgeType === 1) {
                      if (tempCurrentUserPlace.name.match(/^[1-4][^0-9].*|^[1-4]$/)) {
                        nextStep = true
                        ageGroup = 1051
                      }
                    } else if (this.reportAgeType === 2) {
                      if (tempCurrentUserPlace.name.match(/^[5-9].*|^[5-9]$/)) {
                        nextStep = true
                        ageGroup = 1052
                      }
                      if (tempCurrentUserPlace.name.match(/^[1-4][0-3].*$|^[1-4][0-3]$/)) {
                        nextStep = true
                        ageGroup = 1052
                      }
                    } else if (this.reportAgeType === 3) {
                      nextStep = true
                    }
                    if (nextStep) {
                      const productAgeGroups = this.productAgeGroups.filter((productAgeGroup: any) => {
                        return productAgeGroup.id === this.reportProductId && ((productAgeGroup.ageGroup === 0) || (productAgeGroup.ageGroup === ageGroup))
                      })
                      let productAgeGroup = null
                      productAgeGroups.forEach((element: any) => {
                        if (element.ratePlanDiscountTypeId === 0) {
                          productAgeGroup = element
                        } else {
                          currentRatePlans.forEach((userRatePlan: any) => {
                            if (element.ratePlanDiscountTypeId === userRatePlan.ratePlan.ratePlanDiscountTypeId) {
                              productAgeGroup = element
                            }
                          })
                        }
                      })
                      if (productAgeGroup === null && this.reportAgeType !== 3) {
                        visible = 0
                      }
                      let values: any = []
                      const userValues = classWeekends.filter((classWeekend: any) =>
                        classWeekend.placeId === tempCurrentUserPlace.id
                      )[0].weekends

                      values = JSON.parse(JSON.stringify(userValues))
                      let selectedCustProductId: any = null
                      let saleExists = false
                      for (let i = startDay; i <= endDay; i++) {
                        let nextUserStep = true
                        if (this.ratePlans.selectedCategory !== 0) {
                          const curTempUserRatePlan = dateRatePlans.find((element: any) => element.i === i)
                          if (curTempUserRatePlan !== undefined) {
                            if (this.ratePlans.selectedCategory !== curTempUserRatePlan.ratePlanId) {
                              nextUserStep = false
                              const index = values.findIndex((x: any) => x.id === i)
                              if (index > -1) {
                                values[index].value = '-'
                              }
                            }
                          } else {
                            nextUserStep = false
                            const index = values.findIndex((x: any) => x.id === i)
                            if (index > -1) {
                              values[index].value = '-'
                            }
                          }
                        }
                        if (nextUserStep === true) {
                          products.forEach((product: any) => {
                            selectedCustProductId = null
                            const pp = product.placeCustomerCustomerProducts.find((placeCustomerCustomerProduct: any) => {
                              return Number(placeCustomerCustomerProduct.productId) === Number(this.reportProductId)
                            })
                            if (pp !== undefined) {
                              selectedCustProductId = Number(pp.customerProductId)
                            }
                            if (selectedCustProductId !== null) {
                              const selectedElTimestamp = moment(this.reportYear + '-' + this.reportMonth + '-' + i).format('YYYY-MM-DD')
                              const sale = user.tempSales.find((el: any) => Number(el.productId) === selectedCustProductId && selectedElTimestamp === moment(el.timestamp).format('YYYY-MM-DD')
                              )
                              // if (Number(user.id) === 352098) {
                              //   console.log(visible)
                              // }
                              if (sale !== undefined && visible === 1) {
                                saleExists = true
                                const index = values.findIndex((x: any) => x.id === i)
                                if (index > -1) {
                                  values[index].value = ''
                                  const daySaleIndex = this.daySales.findIndex((el: any) => el.id === i)
                                  if (daySaleIndex > -1) {
                                    this.daySales[daySaleIndex].value = this.daySales[daySaleIndex].value + 1
                                  }
                                  const classDaySaleIndex = this.classDaySales.findIndex((el: any) => el.id === i && el.classId === currentUserPlace.id)
                                  if (classDaySaleIndex > -1) {
                                    this.classDaySales[classDaySaleIndex].value = this.classDaySales[classDaySaleIndex].value + 1
                                  } else {
                                    this.classDaySales.push({
                                      id: i,
                                      classId: currentUserPlace.id,
                                      value: 1
                                    })
                                  }
                                }
                              }
                            }
                          })
                        }
                      }
                      if (this.disableFoodless === true) {
                        if (saleExists === false) {
                          visible = 0
                        }
                      }
                      if (visible === 1) {
                        const newUser = {
                          id: user.id,
                          lastName: user.lastName,
                          firstName: user.firstName,
                          middleName: user.middleName,
                          typeId: user.typeId,
                          suid: user.suid,
                          userPersonalAccount: tempUserPersonalAccount,
                          userDescription: tempUserDescription,
                          userRatePlans: tempUserRatePlans,
                          userDonations: tempUserDonations,
                          userPlaces: tempUserPlaces,
                          userPercent: userPercent,
                          visible: visible,
                          userPlace: currentUserPlace,
                          values: values
                        }

                        const index = this.classes.findIndex((x: any) => x.id === currentUserPlace.id)
                        if (index <= -1) {
                          this.classes.push({
                            id: currentUserPlace.id,
                            name: currentUserPlace.name,
                            weekendType: this.getPlaceWeekendType(currentUserPlace)
                          })
                        }
                        this.users.push(newUser)
                      }
                    }
                  }
                }
              }
            })
          }
          // this.ratePlans.sort((a: any, b: any) => (a.ratePlanDiscountTypeId > b.ratePlanDiscountTypeId) ? 1 : ((b.ratePlanDiscountTypeId > a.ratePlanDiscountTypeId) ? -1 : (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)))
          this.users.sort((a: any, b: any) => {
            if (a.userPlace.name === b.userPlace.name) {
              if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                  return (a.middleName.toLowerCase() > b.middleName.toLowerCase()) ? 1 : -1
                }
                return (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? 1 : -1
              }
              return (a.lastName.toLowerCase() > b.lastName.toLowerCase()) ? 1 : -1
            }
            return (a.userPlace.name > b.userPlace.name) ? 1 : -1
          })
          this.classes.sort((a: any, b: any) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)
          this.classes.forEach((scclass: any) => {
            for (let i = startDay; i <= endDay; i++) {
              const classDaySaleIndex = this.classDaySales.findIndex((el: any) => el.id === i && el.classId === scclass.id)
              if (classDaySaleIndex <= -1) {
                this.classDaySales.push({
                  id: i,
                  classId: scclass.id,
                  value: 0
                })
              }
            }
          })
          if (this.users.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.selectedUserAllowedPlace[0]) {
          this.customerPlacePost.placeId = this.selectedUserAllowedPlace[0]
          this.customerPlacePost.date = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('YYYY-MM-DD')

          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async loadCustomerPlaceInfoByPlaceId () {
      try {
        if (this.selectedUserAllowedPlace?.length > 0) {
          this.customers = []
          const customerPlaceFindPost = {
            placeId: this.selectedUserAllowedPlace[0]
          }
          const result = await findByPlace(customerPlaceFindPost)
          const customerPlaces = JSON.parse(JSON.stringify(result))
          customerPlaces.forEach((customerPlace: any) => {
            const customers = this.customers.filter((customer: any) => customer.id === customerPlace.customerId)
            if (customers.length === 0) {
              this.customers.push(
                {
                  id: customerPlace.customer.id,
                  name: customerPlace.customer.name
                }
              )
              this.selectedCustomer = customerPlace.customer.id
            }
          })
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    categorySelectorChangeHandler(event: any) {
      this.ratePlans = event
    },
    createReport () {
      this.productRatePlanSales.forEach((element: any) => {
        let classType = 0
        if (element.className.match(/^[1-4][^0-9].*|^[1-4]$/)) classType = 1
        if (element.className.match(/^[5-9].*|^[5-9]$/)) classType = 2
        if (element.className.match(/^[1-4][0-3].*$|^[1-4][0-3]$/)) classType = 2
        element.classType = classType
      })
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }
      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    placeChange () {
      this.loadCustomerPlaceInfoByPlaceId()
    },
    getYearName () {
      return this.reportYear
    },
    getYearFirstName () {
      return String(this.reportYear).substring(0, 2)
    },
    getYearLastName () {
      return String(this.reportYear).substring(2, 4)
    },
    getDay () {
      return moment(this.getCodeDate(), 'DD.MM.YYYY').format('D')
    },
    getMonth () {
      return moment(this.getCodeDate(), 'DD.MM.YYYY').format('M')
    },
    getCredentials (user: any) {
      if (this.selectedAgeType === 3) {
        return `${user.lastName} ${user.firstName} ${user.middleName}`
      }
      return user.lastName + ' ' + user.firstName
    },
    getPersonalAccount (user: any) {
      let personalAccount = '-'
      if (user.userPersonalAccount !== undefined) {
        personalAccount = user.userPersonalAccount
      }
      return personalAccount
    },
    getPlaceName (place: any) {
      return place.name
    },
    getWeekend (place: any, currentDay: any) {
      const selectedReportDate = moment(this.reportYear + '-' + this.reportMonth + '-' + currentDay).format('YYYY-MM-DD')
      const schoolWeekend = this.placeWeekends.find((el: any) => {
        return selectedReportDate === moment(el.date).format('YYYY-MM-DD') && this.selectedUserAllowedPlace.includes(`${el.placeId}`)
      }
      )
      if (schoolWeekend === undefined) {
        return 0
      }
      if (schoolWeekend.typeId !== 2) {
        return schoolWeekend.typeId
      }
      const placeWeekend = this.placeWeekends.find((el: any) =>
        selectedReportDate === moment(el.date).format('YYYY-MM-DD') &&
        Number(el.placeId) === Number(place.id)
      )
      if (placeWeekend === undefined) {
        return 0
      }
      return placeWeekend.typeId
    },
    getWeekendValue (place: any, currentDay: any) {
      const weekendType = this.getWeekend(place, currentDay)
      if (weekendType === 0) {
        if (moment(this.reportYear + '-' + this.reportMonth + '-' + currentDay).isAfter(moment())) {
          return '-'
        }
        return 'Н'
      }
      if (weekendType === 1) {
        return 'В'
      }
      if (weekendType === 2) {
        return 'В'
      }
      if (weekendType === 4) {
        return 'К'
      }
      if (weekendType === 3) {
        if (moment(this.reportYear + '-' + this.reportMonth + '-' + currentDay).isAfter(moment())) {
          return '-'
        }
        return 'Н'
      }
      return '-'
    },
    getUserDonation (currentUserDonations: any) {
      if (currentUserDonations.length === 0) {
        return false
      }
      currentUserDonations.sort((a: any, b: any) => {
        if (moment(a.creationDate).isSameOrAfter(moment(b.creationDate))) {
          return -1
        }
        return 1
      })

      if ((Number(currentUserDonations[0].donationTypeId) === 3) || (Number(currentUserDonations[0].donationTypeId) === 5) || (Number(currentUserDonations[0].donationTypeId) === 10)) {
        return false
      }
      return true
    },
    getUserDayCount (user: any, value: any) {
      return user.values.filter((el: any) => el.value === value).length
    },
    getAllUserDayCount (value: any, classId: any = undefined) {
      let count = 0
      if (classId !== undefined) {
        this.users.forEach((user: any) => {
          if (user.userPlace.id === classId) {
            count = count + user.values.filter((el: any) => el.value === value).length
          }
        })
      } else {
        this.users.forEach((user: any) => {
          count = count + user.values.filter((el: any) => el.value === value).length
        })
      }
      return count
    },
    getStructure () {
      let structure = ''
      if (this.reportAgeType === 1) {
        structure = 'табель сводный по младшим классам'
      } else if (this.reportAgeType === 2) {
        structure = 'табель сводный по старшим классам'
      } else if (this.reportAgeType === 3) {
        structure = 'табель сводный по обучающимся учреждения'
      }
      return structure
    },
    getRatePlanFoodType () {
      let ratePlanFoodType: any = ''
      const selectedProduct = this.productTypes.find((product: any) => {
        return Number(product.id) === Number(this.reportProductId)
      })
      if (this.reportAgeType === 1) {
        if (this.reportRatePlanId === 0) {
          ratePlanFoodType = 'все льготные категории (' + selectedProduct.name.toLowerCase() + ')'
        } else {
          const reportRatePlan = this.ratePlans.categories.find((el: any) => {
            return el.id === this.reportRatePlanId
          })
          ratePlanFoodType = reportRatePlan.name + ' (' + selectedProduct.name.toLowerCase() + ')'
        }
      } else if (this.reportAgeType === 2) {
        if (this.reportRatePlanId === 0) {
          ratePlanFoodType = 'учащиеся 5-11 классов'
        } else {
          const reportRatePlan = this.ratePlans.categories.find((el: any) => {
            return el.id === this.reportRatePlanId
          })
          ratePlanFoodType = reportRatePlan.name
        }
      } else if (this.reportAgeType === 3) {
        ratePlanFoodType = 'обучающиеся льготных категорий'
      }
      return ratePlanFoodType
    },
    getWorkType () {
      return this.weekType
    },
    getCodeDate () {
      let codeDate = moment(this.reportYear + '-' + this.reportMonth + '-01').format('DD.MM.YYYY')
      if (this.printDateType === 2) {
        codeDate = moment(this.reportYear + '-' + this.reportMonth + '-01').add('months', 1).add('days', -1).format('DD.MM.YYYY')
      } else if (this.printDateType === 3) {
        codeDate = moment().format('DD.MM.YYYY')
      }
      return codeDate
    },
    getDaySales (i: any, classId: any = null) {
      let count = 0
      if (this.reportSplitByClasses) {
        count = this.classDaySales.find((el: any) => el.id === i && el.classId === classId).value
      } else {
        count = this.daySales.find((el: any) => el.id === i).value
      }
      if (count === 0) {
        return '-'
      }
      return count
    },
    getTeacher (classId: any) {
      const searchClassId = Number(classId)
      const classTeacher = this.userAllowedGroups.find((el: any) => {
        return Number(el.allowedGroupId) === searchClassId
      })
      if (classTeacher === undefined) {
        return null
      }
      const teacher = classTeacher.user
      return teacher.firstName.charAt(0) + '. ' + teacher.middleName.charAt(0) + '. ' + teacher.lastName
    },

    /**
     * Находит значение типа рабочей недели ('пятидневка' | 'шестидневка') для переданного класса (в зависимости от id)
     * @param place
     * @returns {string} - возвращает строку 'пятидневка' или 'шестидневка'
     */
    getPlaceWeekendType (place: any): string {
      let weekendTypeName = 'шестидневка'
      const startDay = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').format('D')
      const endDay = moment(this.selectedYear + '-' + this.selectedMonth.id + '-01').add('months', 1).add('days', -1).format('D')

      for (let i = startDay; i <= endDay; i++) {
        if (moment(this.reportYear + '-' + this.reportMonth + '-' + i).day() === 6) {
          const weekendType = this.getWeekend(place, i)
          if (this.reportAgeType === 1) {
            if (place.name.match(/^[1-4][^0-9].*|^[1-4]$/)) {
              if (weekendType === 1 || weekendType === 2) {
                weekendTypeName = 'пятидневка'
              }
            }
          } else if (this.reportAgeType === 2) {
            if (place.name.match(/^[5-9].*|^[5-9]$/)) {
              if (weekendType === 1 || weekendType === 2) {
                weekendTypeName = 'пятидневка'
              }
            }
            if (place.name.match(/^[1-4][0-3].*$|^[1-4][0-3]$/)) {
              if (weekendType === 1 || weekendType === 2) {
                weekendTypeName = 'пятидневка'
              }
            }
          }
        }
      }
      return weekendTypeName
    },
    load () {
      this.printMontheName = this.selectedMonth.name
      this.users = []
      this.sales = []
      this.places = []
      this.products = []
      this.customerPlaceInfo = null
      this.loadSales()
      this.loadCustomerPlaceInfo()
      this.isPost = true
    },
    print () {
      printer('printableArea', 'table-byocud-print.css')
    }
  }
})
</script>

<style lang="scss">

.non-visible {
  display: none;
}

.header-left-template {
  float: left;
  padding: 0 10px 0 0;
  position: relative;
  bottom: -0.5pt;
  background-color: white;
  white-space: nowrap;
  font-size: 12px;
}

.header-right-template {
  white-space: nowrap;
  font-size: 12px;
  border-bottom: 1px solid black;
  margin-right: 100px;
}

.header-span-template {
  padding-left: 16px;
}

.header-template {
  text-align: center;
  font-size: 12px;
}

.header-code-left-template {
  padding-right: 10px;
  text-align: right;
  font-size: 12px;
  white-space: nowrap;
}

.header-code-right-template {
  border: 2px solid black;
  text-align: center;
  font-size: 12px;
  padding-left: 50px;
  padding-right: 50px;
}

.header-code-template {
  border: 1px solid black;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
}

.header-code-collapse {
  border-collapse: collapse;
}

.header-code-white {
  color: white;
}

.underlined-container {
  border-bottom: 1px solid black; /* */
}

.underlined-content {
  position: relative;
  bottom: -1px;
  padding-left: 10px;
}

.lable {
  float: left;
  padding: 0 10px 0 0;
  position: relative;
  bottom: -1px !important;
  background-color: white;
}

.lable_arial {
  font-family: Arial;
  font-size: 11px;
}

.reports-tables-byocud {
  width: 100%;
}
</style>
