<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col cols="2">
        <v-row>
          <v-col>
            <v-btn
              :class="getProductSelected(1)"
              @click="changeProduct(1)"
            >
              Завтрак
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :class="getProductSelected(4)"
              @click="changeProduct(4)"
            >
              Завтрак ст
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :class="getProductSelected(2)"
              @click="changeProduct(2)"
            >
              Обед
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :class="getProductSelected(3)"
              @click="changeProduct(3)"
            >
              Обед комплексный
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="isLoading"
        cols="10"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </v-col>
      <v-col v-if="!isLoading && placeProducts.length > 0">
        <v-row>
          <v-col>
            <v-text-field
              v-model="price"
              placeholder="Новая цена"
            />
          </v-col>
          <v-col
            class="d-flex"
          >
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ props }">
                <v-text-field
                  :model-value="formatDate(priceDate)"
                  label="Дата"
                  prepend-icon="mdi-calendar"
                  readonly

                  v-bind="props"
                />
              </template>
              <v-date-picker
                hide-weekdays
                title="Дата"
                v-model="priceDate"
                @input="menu1 = false"
              />
            </v-menu>
          </v-col>
          <v-col>
            <v-btn
              name="increment-group-edit-button"
              class="btn"
              block
              @click="saveProductPrices"
            >
              Установить
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <table class="report-rateplan-day">
              <thead>
                <tr>
                  <th />
                  <th style="padding-left: 4px !important; padding-right: 4px !important;">
                    <v-text-field
                      v-model="placeSearchTerm"
                      placeholder="Школа"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th style="padding-left: 4px !important; padding-right: 4px !important;">
                    Район
                    <v-select
                      v-model="searchDistrict"
                      :items="districts"
                      item-value="id"
                      item-title="name"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th style="padding-left: 4px !important; padding-right: 4px !important;">
                    Комбинат
                    <v-select
                      v-model="searchCustomer"
                      :items="customers"
                      item-value="id"
                      item-title="name"
                      density="compact"
                      @update:model-value="tableChange"
                    />
                  </th>
                  <th>Цена</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(placeProduct, i) in placeProducts">
                  <tr
                    v-if="placeProduct.productId != null && placeProduct.visible == 1"
                    :key="i"
                  >
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      <input
                        v-model="placeProduct.checked"
                        type="checkbox"
                      >
                    </td>
                    <td
                      :class="getPlaceColor(placeProduct)"
                      style="max-width: 300px; padding-left: 4px !important; padding-right: 4px !important;"
                    >
                      {{ placeProduct.placeName }}
                    </td>
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      {{ placeProduct.placeDistrict }}
                    </td>
                    <td style="padding-left: 4px !important; padding-right: 4px !important;">
                      {{ placeProduct.placeCustomer }}
                    </td>
                    <td
                      :class="getPriceColor(placeProduct)"
                      style="padding-left: 4px !important; padding-right: 4px !important;"
                    >
                      {{ placeProduct.price }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import moment from 'moment'
import { AxiosError } from 'axios'
import { findbyplaces } from '@/shared/api/placeCustomerProductList/api-requests'
import { savePrices } from '@/shared/api/placeProductPrices/api-requests'
import Swal from 'sweetalert2'

type BaseData = {
  searchDistrict: any;
  searchCustomer: any;
  districts: any;
  customers: any;
  placeSearchTerm: any;
  price: any;
  placeProducts: any;
  loading: boolean;
  selectedPlaceCustomerProduct: any;
  selected: any;
  snack: boolean;
  snackColor: string;
  snackText: string;
  disabled: any;
  delButLoading: any;
  addProductPrice: number;
  addProductButtonDisabled: boolean;
  addProductColor: string;
  addProductLoading: boolean;
  productIdArray: any;
  isLoading: boolean;
  menu1: boolean;
  priceDate: any;
}
export default defineComponent({
  components: {

  },
  data (): BaseData {
    return {
      searchDistrict: null,
      searchCustomer: null,
      districts: [],
      customers: [],
      placeSearchTerm: '',
      price: 0,
      placeProducts: [],
      snack: false,
      snackColor: '',
      snackText: '',
      loading: true,
      selectedPlaceCustomerProduct: null,
      selected: [],
      disabled: [],
      delButLoading: [],
      productIdArray: [],
      addProductButtonDisabled: true,
      addProductPrice: 0.00,
      addProductColor: 'normal',
      addProductLoading: false,
      isLoading: false,
      menu1: false,
      priceDate: moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async changeProduct (id: number) {
      try {
        this.isLoading = true
        this.searchDistrict = null
        this.searchCustomer = null
        this.placeSearchTerm = ''
        this.districts = []
        this.customers = []
        let idistrict = 0
        let icustomer = 0
        this.districts.push({
          id: null,
          name: 'Все'
        })
        this.customers.push({
          id: null,
          name: 'Все'
        })
        this.placeProducts = []
        this.selectedPlaceCustomerProduct = id
        if (this.selectedPlaceCustomerProduct !== 0) {
          const customerPlaceFindPost = {
            customerProductId: this.selectedPlaceCustomerProduct,
            date: moment().format('YYYY-MM-DD hh:mm:ss')
          }
          const result = await findbyplaces(customerPlaceFindPost)
          this.placeProducts = JSON.parse(JSON.stringify(result))
          this.placeProducts.forEach((element: any) => {
            element.checked = false
            if (element.placeDistrict === null) {
              element.placeDistrict = 'Не указан'
            }
            const district = this.districts.find((desc: any) => desc.name === element.placeDistrict)
            if (district === undefined) {
              idistrict = idistrict + 1
              this.districts.push({
                id: idistrict,
                name: element.placeDistrict
              })
            }
            const customer = this.customers.find((desc: any) => desc.name === element.placeCustomer)
            if (customer === undefined) {
              icustomer = icustomer + 1
              this.customers.push({
                id: icustomer,
                name: element.placeCustomer
              })
            }
            element.visible = 1
          })
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    getPlaceColor (place: any) {
      let color = ''
      if (Number(place.placeMajorFoodTypeSetting) === 2) {
        color = 'text-red'
      }
      return color
    },
    getPriceColor (place: any) {
      let color = ''
      if (Number(place.priceType) === 2) {
        color = 'text-red'
      }
      return color
    },
    getProductSelected (id: number) {
      let selected = ''
      if (id === this.selectedPlaceCustomerProduct) {
        selected = 'primary'
      }
      return selected
    },
    async saveProductPrices () {
      try {
        this.isLoading = true
        const placeProducts = this.placeProducts.filter((element: any) => { return element.checked === true })
        const places: any = []
        placeProducts.forEach((element: any) => {
          places.push({
            placeId: element.place,
            productId: element.productId,
            price: this.price,
            timestamp: this.priceDate
          })
        })

        const result = await savePrices({ placeProductPrices: places })
        let errorMessage = ''
        const resultPlaceProducts = JSON.parse(JSON.stringify(result))
        resultPlaceProducts.forEach((element: any) => {
          const i = this.placeProducts.findIndex((el: any) => {
            return Number(el.place) === Number(element.place)
          })
          if (element.placeProductId !== null) {
            if (i > -1) {
              this.placeProducts[i].price = element.price
              this.placeProducts[i].priceType = element.priceType
            }
          } else {
            errorMessage = errorMessage + 'Не удалось обновить цену в ' + this.placeProducts[i].placeName + '\n'
          }
        })
        this.placeProducts.forEach((element: any) => {
          element.checked = false
        })
        if (errorMessage.length > 0) {
          alert(errorMessage)
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    savePrice (id: number) {
      Swal.fire({
        title: '',
        html: '<h2>Изменить цену во всех талонах <br> классов?</h2>',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет, только в этом талоне'
      }).then((result) => {
        if (result.isConfirmed) {

        } else if (result.isDenied) {

        }
      })
    },
    sortByDate (a: any, b: any) {
      if (a.timestampFrom < b.timestampFrom) return 1
      else return -1
    },
    sortById (a: any, b: any) {
      if (a.productId < b.productId) return -1
      else return 1
    },
    savePriceProductAdd () {
      if (!isNaN(this.addProductPrice) && this.selectedPlaceCustomerProduct) {
        this.addProductButtonDisabled = false
      }
    },
    tableChange () {
      this.placeProducts.forEach((element: any) => {
        let searchDistrict = ''
        if (this.searchDistrict !== null) {
          searchDistrict = this.districts.find((desc: any) => desc.id === this.searchDistrict).name
        }
        let searchCustomer = ''
        if (this.searchCustomer !== null) {
          searchCustomer = this.customers.find((desc: any) => desc.id === this.searchCustomer).name
        }
        let visible = 0
        let districtVisible = 0
        if (searchDistrict === '') {
          districtVisible = 1
        } else {
          if (searchDistrict === element.placeDistrict) {
            districtVisible = 1
          }
        }
        let customerVisible = 0
        if (searchCustomer === '') {
          customerVisible = 1
        } else {
          if (searchCustomer === element.placeCustomer) {
            customerVisible = 1
          }
        }
        let placeVisible = 0
        if (this.placeSearchTerm === '') {
          placeVisible = 1
        } else {
          if (element.placeName.toLowerCase().indexOf(this.placeSearchTerm.toLowerCase()) >= 0) {
            placeVisible = 1
          }
        }
        if (placeVisible === 1 && districtVisible === 1 && customerVisible === 1) {
          visible = 1
        }
        element.visible = visible
      })
    }
  }
})
</script>

<style lang='scss'>
    .text-red {
        color: red;
    }
</style>
